import React from 'react';
import Model from "reactjs-popup"
import Popup from './Popup';
export default function PurePopup({ open, customOpenFunction, onOpen, children, footer, onClose }) {
    return <Model modal open={open} onOpen={() => {
        customOpenFunction()
    }} onClose={() => {
        onClose()
        onOpen(false)
    }}>
        {close => (<Popup close={close} haveButton={true} footer={footer}>
            {children}
        </Popup>)}
    </Model>;
}
