import { MoveSeekTo } from "../../utils";
import { SIZES } from "../config";

document.smallScene = {
  s: {},
  make: async function (index, noSeek = true, seekMove = "yes") {
    if (noSeek) {
      await MoveSeekTo({ position: "center", updateVideo: true, index, seekMove });
    }
    await document.cxx[index].renderAll();

    let myCan = document.cxx[index].getElement();

    let newCan = document.createElement("canvas");
    let newCanCtx = newCan.getContext("2d");

    newCan.height = myCan.height;
    newCan.width = myCan.width;

    newCanCtx.drawImage(myCan, 0, 0);
    this.s[index] = newCan;
  },
  update: async function (index) {
    try {
      let W = 86,
        H = W / SIZES[document._size || 0].ratio;
      if (this.s[index]) {
        let ctx = document.getElementById(`scene${index}`).getContext("2d");
        let cxv = this.s[index];
        // console.log(cxv.width, cxv.height, "small");
        await ctx.drawImage(cxv, 0, 0, cxv.width, cxv.height, 0, 0, W, H);
        // console.log("Making");
      }
    } catch (err) {
      console.log("err is smallCanvas");
    }
  },
  draw: async function (index, update, seekMove = 'yes') {
    await this.make(index, update, seekMove);
    await this.update(index);
    // console.log(index, "make");
  },
};
