import React from "react";
import Button from "./Button";

export default function Popup({
  name,
  UpperId = "",
  close,
  children,
  centerHeader = null,
  rightHeader = null,
  haveButton = true,
  footer,
}) {
  return (
    <div id={UpperId} className="Popup flex column">
      <div className="Popup_header flex">
        <div className="Popup_header_name">{name}</div>
        {centerHeader}
        <div className="Popup_header_close">
          {rightHeader}
          <img
            className="Popup_header_close_close"
            src={`${document.PUBLIC_URL}/app/closePopup.svg`}
            alt="close"
            onClick={close}
          />
        </div>
      </div>
      <div className="Popup_content">{children}</div>
      {haveButton && (
        <div className="Popup_footer flex">
          {haveButton && (footer || <Button value="Use This Template" />)}
        </div>
      )}
    </div>
  );
}
