import React, { memo, useEffect, useState } from "react";
import TitleWrapper from "../Atom/TitleWrapper";
import { InputRangeWithTitle } from "../../LeftPanel/components/Settings/Components/Background/BackgroundSettings";
import { getById } from "../../../utils";
// import { useLocation } from "react-router";

import { hexToRgb } from "../../../utils";

import Vr from "../Atom/Vr";
import EffectList from "../../LeftPanel/components/Text/EffectList";
import { findNewPoint } from "../../../utils";
import NewColorWrapper from "../One/NewColorWrapper";



const handlerAll = ({ id, ef }) => {
  return (k) => {

    return (e) => {
      const obj = getById(id);

      if (k === "opacity") {
        let c = hexToRgb(obj.shadow.color);
        let col = `rgba(${c.r},${c.g},${c.b}, ${e / 100})`;
        obj.set({
          shadow: { ...obj.shadow, color: col },
        });
        obj._Effect = {
          ...obj._Effect,
          opacity: e
        }
      } else if (k === "blur") {
        obj.set({
          shadow: { ...obj.shadow, blur: e },
        });
        obj._Effect = {
          ...obj._Effect,
          blur: e
        }
      } else if (k === "borderWidth") {
        obj.set({
          strokeWidth: e,
        });
        obj._Effect = {
          ...obj._Effect,
          strokeWidth: e
        }
      } else if (k === "depth") {
        let list = obj.effectLayer.map((k) => ({ ...k, objectBlur: e }));
        obj.set({ effectLayer: list });
        obj._Effect = {
          ...obj._Effect,
          depth: e
        }
      } else if (k === "distance" && ef === 4) {
        let list = obj.effectLayer.map((k, i) => {
          let effectLayer1 = findNewPoint(document.exx.angle[4], (i + 1) * e);
          let o = {
            ...k,
            leftOffset: effectLayer1.x,
            topOffset: effectLayer1.y,
          };
          return o;
        });
        obj.set({ effectLayer: list });
        obj._Effect = {
          ...obj._Effect,
          distance: e
        }
        document.exx["distance"][4] = Number(e);
      } else if (k === "distance" && ef === 5) {
        let list = obj.effectLayer.map((k, i) => {
          let effectLayer1 = findNewPoint(document.exx["angle"][5], e);
          let o = {
            ...k,
            leftOffset: (!!i ? 1 : -1) * effectLayer1.x,
            topOffset: (!!i ? 1 : -1) * effectLayer1.y,
          };
          return o;
        });

        obj.set({ effectLayer: list });
        obj._Effect = {
          ...obj._Effect,
          distance: e
        }
        document.exx["distance"][5] = e;
        // ////console.log("distance",distance)
      } else if (k === "angle" && ef === 5) {
        let list = obj.effectLayer.map((k, i) => {
          let effectLayer1 = findNewPoint(e, document.exx["distance"][5]);
          let o = {
            ...k,
            leftOffset: (!!i ? 1 : -1) * effectLayer1.x,
            topOffset: (!!i ? 1 : -1) * effectLayer1.y,
          };
          return o;
        });
        obj._Effect = {
          ...obj._Effect,
          angle: e
        }
        obj.set({ effectLayer: list });
        document.exx["angle"][5] = e;
      } else if (k === "angle" && ef === 4) {
        let list = obj.effectLayer.map((k, i) => {
          let effectLayer1 = findNewPoint(
            e,
            document.exx.distance[4] * (i + 1)
          );
          let o = {
            ...k,
            leftOffset: effectLayer1.x,
            topOffset: effectLayer1.y,
          };
          return o;
        });
        obj.set({ effectLayer: list });
        obj._Effect = {
          ...obj._Effect,
          angle: e
        }
        document.exx["angle"][4] = Number(e);
      } else if (k === "emboss") {
        let list = obj.effectLayer.map((k, i) => {
          return {
            ...k,
            objectBlur: i === 2 ? null : i === 3 ? e * (5 / 7) : e,
          };
        });
        obj.set({ effectLayer: list });
        obj._Effect = {
          ...obj._Effect,
          emboss: e
        }
        // document.exx["angle"][4] = Number(e);
      } else if (k === "intensity") {
        let list = obj.effectLayer.map((k, i) => {
          switch (i) {
            case 1:
              return { ...k, objectBlur: e, opacity: (e / 100) * (35 / 30) };

            default:
              return k;
          }
        });
        obj.set({
          effectLayer: list,
          shadow: { ...obj.shadow, blur: e * (18 / 30) },
          strokeWidth: e / 30,
        });
        obj._Effect = {
          ...obj._Effect,
          intensity: e
        }
        // document.exx["angle"][4] = Number(e);
      } else if (k === "angle" && ef === 1) {
        let effectLayer1 = findNewPoint(e, 5);

        obj.set({
          shadow: {
            ...obj.shadow,
            offsetX: effectLayer1.x,
            offsetY: effectLayer1.y,
          },
        });
        obj._Effect = {
          ...obj._Effect,
          angle: e
        }
      } else if (k === "color" && document.exx.ef === 1) {
        let c = hexToRgb(e);
        let col = `rgba(${c.r},${c.g},${c.b}, ${hexToRgb(obj.shadow.color).a})`;
        obj.set({
          shadow: { ...obj.shadow, color: col },
        });
      } else if (k === "color" && document.exx.ef === 2) {
        obj.set({
          stroke: e,
        });
      } else if (k === "color" && document.exx.ef === 2) {
        obj.set({
          stroke: e,
        });
      } else if (
        k === "color" &&
        (document.exx.ef === 4 || document.exx.ef === 6)
      ) {
        let list = obj.effectLayer.map((k, i) => {
          return {
            ...k,
            fill: e,
          };
        });
        obj.set({ effectLayer: list });
      }
      obj.canvas.fire("object:modified");

      obj.canvas.renderAll();
      ////console.log(ef);
    };
  }

};

export default (function EffectCompound({ id, ef }) {
  useEffect(() => {
    const obj = getById(id);
    document.exx = { angle: {}, distance: {} };
    document.exx["angle"] = { 5: 7, 4: 45 };
    document.exx["distance"] = { 5: 20, 4: 45 };
  }, []);
  let [effect] = useState(getById(id)._Effect || {
    opacity: 0,
    depth: 0,
    distance: 0,
    emboss: 0,
    intensity: 0,
    blur: 0,
    borderWidth: 0,
    angle: 0,
  })

  // const handlerAll = handlerAllWrapper.bind(this, [{ id, ef }])

  useEffect(() => {
    const obj = getById(id);
    obj.set({ ...EffectList[ef] });
    obj._Data = { ...obj._Data, id: ef };
    obj.canvas.fire("object:modified");

    obj.canvas.renderAll();
    document.exx["ef"] = ef;
  }, [ef]);

  return (
    <div className="EffectCompound">
      <Vr m="15px 0 20px" />
      {(ef === 8 ||
        ef === 1 ||
        ef === 2 ||
        ef === 4 ||
        ef === 5 ||
        ef === 6) && (
          <>
            {/* {(() => getById(id).shadow?.color)()} */}
            {/* <Color onLastChange={handlerAll({id,ef})({id,if})("color")} /> */}
            <NewColorWrapper onLastChange={handlerAll({ id, ef })("color")} />
            <Vr m="10px" />
          </>
        )}
      {(ef === 8 || ef === 1) && (
        <TitleWrapper title="Opacity">
          <InputRangeWithTitle
            className="Opacity"
            value={effect.opacity}
            onChange={handlerAll({ id, ef })("opacity")}
          />
        </TitleWrapper>
      )}

      {(ef === 8 || ef === 1) && (
        <TitleWrapper title="Blur">
          <InputRangeWithTitle
            className="Opacity"
            value={effect.blur}
            onChange={handlerAll({ id, ef })("blur")}
          />
        </TitleWrapper>
      )}

      {(ef === 8 || ef === 2 || ef === 3) && (
        <TitleWrapper title="Border Width">
          <InputRangeWithTitle
            className="Opacity"
            value={effect.borderWidth}
            max={10}
            min={0}
            step={1}
            postfix={"px"}
            onChange={handlerAll({ id, ef })("borderWidth")}
          />
        </TitleWrapper>
      )}

      {(ef === 8 || ef === 3) && (
        <TitleWrapper title="Depth">
          <InputRangeWithTitle
            className="Opacity"
            value={effect.depth}
            max={10}
            min={0}
            step={1}
            postfix={"px"}
            onChange={handlerAll({ id, ef })("depth")}
          />
        </TitleWrapper>
      )}
      {(ef === 8 || ef === 4 || ef === 5) && (
        <TitleWrapper title="Distance">
          <InputRangeWithTitle
            className="Opacity"
            value={effect.distance}
            max={50}
            min={0}
            step={1}
            postfix={"px"}
            onChange={handlerAll({ id, ef })("distance")}
          />
        </TitleWrapper>
      )}

      {(ef === 8 || ef === 6) && (
        <TitleWrapper title="Emboss">
          <InputRangeWithTitle
            className="Opacity"
            value={effect.emboss}
            max={50}
            min={0}
            step={1}
            postfix={"%"}
            onChange={handlerAll({ id, ef })("emboss")}
          />
        </TitleWrapper>
      )}

      {(ef === 8 || ef === 7) && (
        <TitleWrapper title="Intensity">
          <InputRangeWithTitle
            className="Opacity"
            value={effect.intensity}
            max={100}
            min={1}
            step={1}
            postfix={"%"}
            onChange={handlerAll({ id, ef })("intensity")}
          />
        </TitleWrapper>
      )}

      {(ef === 8 || ef === 5 || ef === 4 || ef === 1) && (
        <TitleWrapper title="Angle">
          <InputRangeWithTitle
            className="Opacity"
            value={effect.angle}
            max={359}
            min={0}
            step={1}
            postfix={"deg"}
            onChange={handlerAll({ id, ef })("angle")}
          />
        </TitleWrapper>
      )}
    </div>
  );
});
