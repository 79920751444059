import { handleCancelOnAvatarFrame } from "../../components/LeftPanel/components/Avatar/functions/handleCancelOnAvatarFrame";
import { Jump } from "../../utils";
import { onCancelClick } from "../cropyThings";
import { resizeAll } from "./resizeCanvas";


export const discardCurrentWork = (_data) => {
    if (document.Player?.status !== 1 && document.Player?.status !== 2) {
        if (document._TabName === 'avatar') {
            let _goB = false;
            if (_data?._goB) {
                _goB = true;
            }
            handleCancelOnAvatarFrame({ _goB: _goB });

        } else {
            onCancelClick(null, null, null)
        }
    }
}


export const addCanvasResizeEvent = () => {
    window.addEventListener("resize", resizeAll);
}

export const removeCanvasResizeEvent = () => {
    window.removeEventListener("resize", resizeAll);
}