/* eslint-disable */
import React, { useState, useEffect } from "react";
import { login } from "./actions/auth";
import { connect } from "react-redux";
import { Link, Redirect, useParams, useRouteMatch } from "react-router-dom";
import { ReactComponent as VerifyEmailIcon } from "../App/extras/misc/mailverify.svg";

import LoadingComp from "../App/JohnDoe/common/loading";
import { fetcher, url } from "../utils";
import mystore from "../App/appStore";
function Login(props) {
	let { id } = useParams();
	const [Details, setDetails] = useState({
		email: props.location && props.location.email ? props.location.email : "",
		code: id,
	});
	const [Step, setStep] = useState(-1);
	const [Errors, setErrors] = useState({ isError: false });
	const [PasswordChange, setPasswordChange] = useState({ isChanged: false });
	const [Redirectt, setRedirectt] = useState(false);
	console.log(props);

	useEffect(async () => {
		fetcher({
			header: {},
			url: `${url}/api/accounts/signup/verify/?code=${id}`,
			res: (res) => {
				console.log(res);
				if (res.data.isError) {
					setStep(0);
					setErrors({
						isError: true,
						message: res.data.message,
					});
				} else {
					setStep(-1);
					setDetails({ email: res.data.email });
					setRedirectt(true);
				}
			},
			err: (err) => {
				console.log(err);
				close();
			},
		});
	}, []);

	useEffect(() => {
		if (props.location.passwordChange) {
			setPasswordChange({
				isChanged: true,
				message: "Login to continue.",
			});
		}
	}, []);

	const al = () => {
		if (Step === -1) {
			return <LoadingComp style={{ width: "20vw" }} />;
		}
		if (Step === 0) {
			return (
				<div
					key={1}
					className={`login-wrapper ${Step === 1 ? "MoveLeft" : ""}`}
					style={{ maxWidth: "513px" }}
				>
					{/* <Autogenerate style={{ width: "240px" }} /> */}

					<h1 className="login-header">Verify email Failed.</h1>

					<div className="login-inner-wrapper">
						<div className="login-gp-1">
							<label className="login-main-label">
								Verification of email failed{" "}
							</label>
						</div>

						<div className="login-form">
							<VerifyEmailIcon />
						</div>

						<div className="login-footer">
							<Link
								to={{
									pathname: "/login",
								}}
								className="login-main-button link-custom"
								style={{ margin: "50px 0 0 0", width: "200px" }}
							>
								Login
							</Link>
						</div>
					</div>
				</div>
			);
		}
	};

	if (Redirectt) {
		return (
			<Redirect
				to={{
					pathname: "/login",
					email: Details.email,
					emailVerified: true,
				}}
			/>
		);
	} else return <div className="login-container">{al()}</div>;
}
const mapStateToProps = () => {
	var state = mystore.getState();
	return {
		isAuthenticated: state.auth.isAuthenticated,
		state: state.auth,
	};
};
export default connect(mapStateToProps, { login })(Login);
