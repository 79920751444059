import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { fetcher, token, url } from '../../../utils';
import InfiniteLoadingComponent from '../../../utils/InfiniteLoadingComponent'

export default function CopyTemplate() {
    const [template, setTemplate] = useState({ status: false, id: null });
    const hist = useHistory();
    const HandleTemplateAdd = (id) => {
        console.log({ inside: template });
        // console.log(localStorage.getItem("token"));
        if (id && localStorage.getItem("token")) {

            fetcher({
                header: {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                },
                url: `${url}/api/newvideo/video/copy/${id}/`,
                res: (res) => {
                    window.location.href = `/video/${res.data.result.id}/`;
                    //window.open(`/`, "_self");
                },
                err: (err) => {
                    if (err.response.status === 401) {
                        window.location.href = `/login/?template=${id}`;
                    }
                    else
                        console.log(err);
                }
            });
        }
        else window.location.href = `/login/?template=${id}`;

    };



    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let Templating = params.template;

        // if (Templating) {
        //     console.log(Templating);
        //     setTemplate({
        //         status: true,
        //         id: Templating,
        //     });

        HandleTemplateAdd(Templating)
        // }
    }, [])



    return (
        <InfiniteLoadingComponent />
    )
}