/* eslint-disable */
import React, { useEffect } from 'react'
import ReactJWPlayer from "react-jw-player";
// import Popup from "../../Components/Popup";
import Modal from "reactjs-popup";
import Video from "../../JohnDoe/common/Video";
import { ReactComponent as HowItWorkss } from "../../extras/how_it_works.svg";
import { ReactComponent as Cross } from "../../extras/account/cross.svg";

export default function HowItWorks({ children, trigger, isOpen }) {
  return (
    <Modal
      modal
      trigger={trigger}
      closeOnDocumentClick
      onClose={() => {
        // setOpen(false);
      }}
    >
      {(close) => (

        <div className="how-it-works" onClick={close}>
          <Video

            video={{
              src: "https://api.autogenerate.ai/media/video/finalVideo/0d0bbd74-88f9-11eb-9695-d5792ecd5b21_TcSl8zm.mp4",

              thumbnail: "https://wallpaperaccess.com/full/3458147.jpg"
            }}


          />

        </div>

      )}
    </Modal>







  )
}

