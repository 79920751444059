import { MediaMaker } from "../../../../../utils";
import { customVideoSeek } from "../../../../../utils/videoPlayThings";

export default function initAvatar({ obj, index, storeData }) {
  if (obj._Type === "avatar") {
    MediaMaker({
      type: "audio",
      id: `avatar${index}`,
      src: storeData.sound,
      cb: ({ ele }) => {
        ele.addEventListener("play", () => {
          document.setBigPlay(true);
        });
        ele.addEventListener("pause", () => {
          document.setBigPlay(false);
        });
      },
    });
  }
}
