import { fabric } from "fabric";
import store from "../../../../../store/store";
import { getById, Index } from "../../../../../utils";

import {
  convertOrigin,
  convertOriginToDecimal,
  freezeObject,
  getObjectCoords,
  restoreObject,
} from "../../../../../utils/cropyThings";
import { addObjectOutsideEvent } from "../../../../../utils/fabricInit";
import { InitAddObjectEvents } from "../../../../../utils/InitAddObjectEvents";
import {
  getFabricCancelControl,
  getFabricCropControl,
} from "../../../../../utils/inlineSVG";
import { fitAvatarBackgroundToCrop } from "./fitAvatarBackgroundToCrop";
import { getAvatarCropInfo } from "./getAvatarCropInfo";
import { handleCancelOnAvatarFrame } from "./handleCancelOnAvatarFrame";

function getImageObjectActualCoord(obj) {
  let _originalOriginX = convertOriginToDecimal(obj.originX),
    _originalOriginY = convertOriginToDecimal(obj.originY);
  let _actualOH = obj.getElement().height,
    _actualOW = obj.getElement().width;
  let _actualOL = obj.left / obj.scaleX - _originalOriginX * _actualOW,
    _actualOT = obj.top / obj.scaleY - _originalOriginY * _actualOH;
  return {
    left: _actualOL,
    top: _actualOT,
  };
}
export function cropImageObject(obj, left, top, height, width) {
  let _actualCord = getImageObjectActualCoord(obj);
  let _cropHeight = height / obj.scaleY,
    _cropWidth = width / obj.scaleX;

  let _relativeLeft = left / obj.scaleX - _actualCord.left;
  let _relativeTop = top / obj.scaleY - _actualCord.top;
  let _cropXO, _cropYO;
  if (_relativeLeft >= 0) {
    _cropXO = _relativeLeft;
  } else {
    _cropXO = 0;
    //_cropWidth -= _relativeLeft
  }
  if (_relativeTop >= 0) {
    _cropYO = _relativeTop;
  } else {
    _cropYO = 0;
    //_cropHeight -= _relativeTop
  }

  obj.set({
    cropX: _cropXO,
    cropY: _cropYO,
    left: obj.left + _cropXO * obj.scaleX,
    top: obj.top + _cropYO * obj.scaleY,
    height: _cropHeight,
    width: _cropWidth,
  });
  return obj;
}

export function applyAvatarCrop(avatarObj, frame) {
  let mainCanvas = avatarObj.canvas;
  let _id = avatarObj.id,
    _name = avatarObj._Name,
    _type = avatarObj._Type;

  let cropCanvas = document.ccx;
  if (frame === undefined) {
    frame = document.cccx.current.style.display === "none" ? 1 : 0;
  }
  if (frame === 0) {
    let _crntAvatar = fabric.util.object.clone(document.ccxa._objects[0]);
    _crntAvatar.set({
      opacity: 1,
      selectable: true,
      evented: true,
      id: _id,
      _Name: _name,
      _Type: _type,
    });
    mainCanvas.remove(avatarObj);
    _crntAvatar.set({
      ...document.selectionSettings,
    });
    _crntAvatar.controls = avatarObj.controls;
    mainCanvas.add(_crntAvatar);

    InitAddObjectEvents({
      obj: _crntAvatar,

      name: _name,
      _id: _id,
      type: _type,
    });
  } else {
    let _clipPath = document.ccx._objects.find((e) => e.id === "cropClipMask");
    let _originalAO = document.ccxa._objects;
    if (_clipPath && _originalAO.length === 1) {
      _clipPath.setCoords();
      let {
        left: _coLeft,
        top: _coTop,
        height: _coHeight,
        width: _coWidth,
      } = getObjectCoords(_clipPath);

      let _originalImage = cropImageObject(
        _originalAO[0],
        _coLeft,
        _coTop,
        _coHeight,
        _coWidth
      );

      _clipPath.set({
        left: -_originalImage.width / 2,
        top: -_originalImage.height / 2,
        scaleX: _clipPath.scaleX / _originalImage.scaleX,
        scaleY: _clipPath.scaleY / _originalImage.scaleY,
      });

      let _left = _originalImage.left,
        _top = _originalImage.top,
        _newG;
      _originalImage.set({
        left: null,
        top: null,
        clipPath: null,
        opacity: 1,
      });
      _clipPath.scaleX = _clipPath.scaleX * _originalImage.scaleX;
      _clipPath.scaleY = _clipPath.scaleY * _originalImage.scaleY;
      _clipPath.left = _clipPath.left * _originalImage.scaleX;
      _clipPath.top = _clipPath.top * _originalImage.scaleY;

      _originalImage.canvas.remove(_originalImage);
      let _avBgImage = document.ccx
        .getObjects()
        .find((e) => e.id === "cropAvatarBackground");

      if (_clipPath.canvas._objects.length === 3 && _avBgImage?.opacity !== 0) {
        // background as image
        let _bgImage = cropCanvas._objects.find(
          (_obj) => _obj.id === "cropAvatarBackground"
        );
        let _bgImageO;
        if (_bgImage) {
          _bgImageO = cropImageObject(
            _bgImage,
            _coLeft,
            _coTop,
            _coHeight,
            _coWidth
          );
          _bgImageO.set({
            left: null,
            top: null,
            originX: 0,
            originY: 0,
          });
          _newG = new fabric.Group([_bgImageO, _originalImage], {
            left: _coLeft,
            top: _coTop,
            clipPath: _clipPath,
          });
        } else {
          let _bgColor = _clipPath.canvas.backgroundColor || "#ffffff";
          _newG = new fabric.Group(
            [
              new fabric.Rect({
                height: _originalImage.height,
                width: _originalImage.width,
                scaleX: _originalImage.scaleX,
                scaleY: _originalImage.scaleY,
                fill: _bgColor,
              }),
              _originalImage,
            ],
            {
              left: _coLeft,
              top: _coTop,
              clipPath: _clipPath,
            }
          );
        }
      } else {
        // background as color
        let _bgColor = _clipPath.canvas.backgroundColor || "#ffffff";
        _newG = new fabric.Group(
          [
            new fabric.Rect({
              height: _originalImage.height,
              width: _originalImage.width,
              scaleX: _originalImage.scaleX,
              scaleY: _originalImage.scaleY,
              fill: _bgColor,
            }),
            _originalImage,
          ],
          {
            left: _coLeft,
            top: _coTop,
            clipPath: _clipPath,
          }
        );
      }
      _newG._objects[1].left = _newG._objects[1].left + _left - _coLeft;
      _newG._objects[1].top = _newG._objects[1].top + _top - _coTop;
      _newG.set({
        id: _id,
        _Name: _name,
        _Type: _type,
        shadow: avatarObj.shadow
      });
      mainCanvas.remove(avatarObj);
      _newG.set({
        ...document.selectionSettings,
      });
      mainCanvas.add(_newG);
      InitAddObjectEvents({
        obj: _newG,

        name: _name,
        _id: _id,
        type: _type,
        reload: true,
      });
    }
  }
  mainCanvas.renderAll();

  store.dispatch({
    type: "SET_FRAME",
    data: false,
  });
  cropCanvas.clear();
}

const handleCropOnAvatarFrame = () => {
  document.cccx.current.style.display = "none";
  document.cccxa.current.style.display = "none";
  let _crntAvatar = getById(`avatar${Index()}`, Index());
  applyAvatarCrop(_crntAvatar);
};
const _fabCancelControl = getFabricCancelControl(handleCancelOnAvatarFrame);
const _fabCropControl = getFabricCropControl(handleCropOnAvatarFrame);

export const addAvatarFabricControls = (obj) => {
  obj.controls = {
    ...obj.controls,
    cancelControl: _fabCancelControl,
    cropControl: _fabCropControl,
  };
  return 1;
};

function copyFabImage(obj) {
  let _newObj = new fabric.Image(obj.getElement());

  // if (_newObj.getElement().crossOrigin !== "anonymous") {
  //   _newObj.getElement().crossOrigin = "anonymous";
  // }
  _newObj.set({
    left: obj.left,
    top: obj.top,
    height: obj.height,
    width: obj.width,
    scaleX: obj.scaleX,
    scaleY: obj.scaleY,
    cropX: obj.cropX,
    cropY: obj.cropY,
    skewX: obj.skewX,
    skewY: obj.skewY,
    originX: obj.originX,
    originY: obj.originY,
    shadow: obj.shadow,
    vangle: obj.angle,
    flipX: obj.flipX,
    flipY: obj.flipY,
    opacity: obj.opacity,
  });
  return _newObj;
}

function resetGroupMask(obj, groupObj, canvas) {
  let _crnCanvas = canvas || document.ccx;

  // change left top
  let _crntScale = groupObj.scaleX;
  if (_crntScale !== 1) {
    groupObj.set({
      scaleX: 1,
      scaleY: 1,
    });
  }

  obj.set({
    left:
      groupObj.left - obj.cropX * obj.scaleX + (groupObj.width / 2 + obj.left),
    top:
      groupObj.top - obj.cropY * obj.scaleY + (groupObj.height / 2 + obj.top),
    scaleX: groupObj.scaleX * obj.scaleX,
    scaleY: groupObj.scaleY * obj.scaleY,
    cropX: 0,
    cropY: 0,
    clipPath: null,
  });
  obj._resetWidthHeight();

  if (_crntScale !== 1) {
    obj.setCoords();
    let boss = new fabric.Rect({
      left: groupObj.left,
      top: groupObj.top,
      height: groupObj.height,
      width: groupObj.width,
      fill: null,
    });
    let child = new fabric.Rect({
      left: obj.left,
      top: obj.top,
      height: obj.height,
      width: obj.width,
      scaleY: obj.scaleY,
      scaleX: obj.scaleX,
      fill: null,
    });
    _crnCanvas.add(boss);
    _crnCanvas.add(child);
    _crnCanvas.renderAll();

    freezeObject(boss, [child]);
    boss.set({
      scaleX: _crntScale,
      scaleY: _crntScale,
    });

    restoreObject(boss, [child]);
    restoreObject(boss, [child]);
    groupObj.set({
      scaleX: _crntScale,
      scaleY: _crntScale,
    });
    obj.set({
      left: child.left,
      top: child.top,
      scaleX: child.scaleX,
      scaleY: child.scaleY,
    });
    _crnCanvas.remove(child);
    _crnCanvas.remove(boss);
  }
}
export function addAvatarToCrop(avatarO) {
  avatarO.canvas?.discardActiveObject();
  avatarO.visible = false;
  avatarO.canvas?.renderAll();

  const backgroundC = document.ccxa;
  const clipCanvas = document.ccx;
  document.cccxa.current.style.display = "block";

  backgroundC.clear();
  clipCanvas.clear();
  backgroundC.backgroundColor = "#ffffff00";

  let avatarObj = fabric.util.object.clone(avatarO);
  convertOrigin(avatarObj, {
    originY: 0,
    originX: 0,
  });
  let _currntConfig = getAvatarCropInfo(avatarObj);
  let img = copyFabImage(_currntConfig.avatarObj);

  let clipMask, _bgImage;
  if (_currntConfig.clip) {
    document.cccx.current.style.display = "block";
    // remove clip
    resetGroupMask(img, avatarObj, clipCanvas);

    clipMask = fabric.util.object.clone(avatarO.clipPath);
    clipMask.set({
      id: "cropClipMask",
      clipImage: true,
      left: avatarO.left,
      top: avatarO.top,
      scaleX: (avatarO.scaleX * avatarO.width) / clipMask.width,
      scaleY: (avatarO.scaleY * avatarO.height) / clipMask.height,
      fill: "rgba(255,255,255,1)",
      globalCompositeOperation: "destination-in",
      lockScalingFlip: true,
      _controlsVisibility: {
        mtr: false,
      },
    });
    clipMask.set({
      ...document.selectionSettings,
    });

    if (_currntConfig.bgType === "image") {
      clipCanvas.backgroundColor = "#ff0000";
      _bgImage = new fabric.Image(_currntConfig.bgData);
      _bgImage.set({
        id: `cropAvatarBackground`,
        selectable: false,
        evented: false,
        type: "image",
      });
    } else {
      clipCanvas.backgroundColor = _currntConfig.bgData || "#ff0000";
    }
    img.set({
      opacity: 0.4,
    });
  } else {
    document.cccx.current.style.display = "none";
    img.set({
      opacity: 1,
    });
  }
  img.set({
    selectable: false,
    evented: false,
  });

  backgroundC.add(img);
  let _copyImage = fabric.util.object.clone(img);

  _copyImage.set({
    selectable: false,
    evented: false,
    isAvatar: true,
    opacity: 1,
  });

  clipCanvas.add(_copyImage);

  if (clipMask) {
    clipMask.refImage = _copyImage;
    addAvatarFabricControls(clipMask);
    addObjectOutsideEvent(clipMask);

    if (_currntConfig.bgType === "image") {
      clipCanvas.add(_bgImage);
      _bgImage.sendToBack();
      clipCanvas.add(clipMask);
      clipCanvas.setActiveObject(clipMask);

      fitAvatarBackgroundToCrop(_bgImage, clipMask);
      clipMask.on("moving", (e) => {
        fitAvatarBackgroundToCrop(_bgImage, clipMask);
      });
      clipMask.on("scaling", (e) => {
        fitAvatarBackgroundToCrop(_bgImage, clipMask);
      });
      clipMask.on("rotating", (e) => {
        fitAvatarBackgroundToCrop(_bgImage, clipMask);
      });
    } else {
      clipCanvas.add(clipMask);
      clipCanvas.setActiveObject(clipMask);
    }
  }
  clipCanvas.renderAll();
  backgroundC.renderAll();
}
