/* eslint-disable */
import { getById, Index } from "../utils";
import { fabric } from "fabric";
import store from "../store/store";

import { gsap } from "gsap";
import { cxxtoJSON } from "./config";
import objectCloner from "./objectCloner";
import { deleteFromAll } from "./deleteCloneOpeationObj";
import { VideoTrimFuntion } from "./VideoMusicTrim";

import { universalPlay } from "./videoPlayThings/universalPlay";
import { universalPause } from "./videoPlayThings/universalPause";

export function videoPlayUpdateFilter(vidEle) {
  if (vidEle?.filters?.length > 0) {
    let backend = fabric.filterBackend;
    if (backend && backend.evictCachesForKey) {
      backend.evictCachesForKey(vidEle.cacheKey);
      backend.evictCachesForKey(vidEle.cacheKey + "_filtered");
    }
    vidEle.applyFilters();
  }
}

// document.me = "me";

export function applyFilters(fabObj, filters, _colors) {
  const allFilters = [];
  if (filters) {
    filters.forEach((_fil) => {
      let _ofill = {
        ..._fil,
      };
      if (
        _colors &&
        _ofill.type === "ColorInterpolation" &&
        _ofill.tableValues
      ) {
        let _crntColor = _ofill.tableValues;
        if (_crntColor.r.length === _colors.length + 1) {
          let _rC = _ofill.tableValues.r.slice();
          let _gC = _ofill.tableValues.g.slice();
          let _bC = _ofill.tableValues.b.slice();
          for (let indx = 0; indx < _colors.length; indx++) {
            try {
              const _cColor = new fabric.Color(_colors[indx])._source;
              _rC[indx + 1] = _cColor[0] / 255;
              _gC[indx + 1] = _cColor[1] / 255;
              _bC[indx + 1] = _cColor[2] / 255;
            } catch (err) {
              console.log("Scene Animation Filter Color Change issue: ", err);
            }
          }
          _ofill.tableValues = {
            r: _rC,
            g: _gC,
            b: _bC,
            a: _ofill.tableValues.a,
          };
        }
      }
      allFilters.push(
        new fabric.Image.filters[_fil.type]({
          ..._ofill,
        })
      );
    });
    fabObj.filters = allFilters;
    fabObj.applyFilters();
  }
}
export const customSeekObj = (time, obj, elmt) => {
  if (obj._Type === "music") {
  } else {
    if (obj._Hidden) {
      obj.visible = false;
    } else {
      if (elmt) {
        const { enter, exit, stayTime, enterDelay } = elmt;
        if (time < enterDelay) {
          obj.visible = false;
        } else if (enterDelay <= time && time <= enterDelay + stayTime) {
          obj.visible = true;
        } else if (enterDelay + stayTime < time) {
          obj.visible = false;
        }
      }
    }
  }
}

export const customSeek = (
  time,
  index = Index(),
  temp = false,
  state = null,
  isRender = true,
) => {
  let scenes = (state || store.getState()).scenes[index];
  const _elem = scenes.elements;
  if (_elem) {
    let arr = document.cxx[index].getObjects();

    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i];
      if (scenes.haveEasyMode && document.screenChheda) {
        if (obj._Hidden) {
          obj.visible = false;
        } else {
          obj.visible = true;
        }
      } else {
        customSeekObj(time, obj, _elem[obj.id])
      }
    }
  }
  if (isRender) {
    document.cxx[index].renderAll();
  }
};

export const addMusicToAllCxx = ({ id, index }) => {
  const _prevDisableState = document.Disable;
  document.Disable = true;
  let arr = JSON.parse(store.getState().scenes.currentScene.arr);
  arr.forEach((e) => {
    let cxx = document.cxx[e];
    if (cxx) {
      if (Number(index) !== Number(e)) {
        objectCloner({
          _id: id,
          where: e,
          haveCopy: false,
          from: index,
          clone: true,
        });

        // document.cxx[index].fire("object")
        // console.log("cloneing", e);
      }
    }
  });
  document.Disable = _prevDisableState;
};

export const loadAndPauseVideo = ({ index }) => {
  // document.playByU=true;
  // if (document.playByU) {
  //   let allReady = true;
  //   document.vxx[index].forEach((vid) => {
  //     if (vid.readyState !== 0 && vid.getAttribute("src") !== "undefined") {
  //       if (vid.readyState < 2) {
  //         allReady = false;
  //       }
  //     }
  //   });
  //   if (allReady) {
  //     universalPlay({});
  //     // console.log("play");
  //     document.vxx[index].forEach((vid) => {
  //       if (vid.type === "video" || vid.type === "background")
  //         VideoTrimFuntion(vid);
  //     });
  //   } else {
  //     universalPause({
  //       id: null,
  //       userPause: false,
  //       playByU: true,
  //       pauseType: "load",
  //     });
  //     // console.log("Paused");
  //   }
  // }
};

export const removeMusicToAllCxx1 = ({ id, index }) => {
  Object.keys(document.cxx).forEach((e) => {
    let cxx = document.cxx[e];
    if (cxx) {
      let p = document.cxx[e]?.discardActiveObject()?.renderAll();
      if (Number(index) !== Number(e)) {
        deleteFromAll(id, true, e);
        setTimeout(() => {
          store.dispatch({
            type: "CANVAS_JSON_INDEX",
            data: {
              json: document.cxx[e].toJSON(cxxtoJSON),
              index: e,
            },
          });
        }, 1000);
      }
    }
  });
};
export const removeMusicToAllCxx = ({ id, index = Index() }) => {
  const _prevDisableState = document.Disable;
  document.Disable = true;
  let arr = JSON.parse(store.getState().scenes.currentScene.arr);
  arr.forEach((e) => {
    let canvas = document.cxx[e];
    if (canvas) {
      let obj = getById(id, null, canvas);
      if (Number(index) !== Number(e) && obj) {
        canvas.discardActiveObject();
        canvas.remove(obj);

        store.dispatch({
          type: "CANVAS_JSON_INDEX",
          data: {
            json: canvas.toJSON(cxxtoJSON),
            index: e,
          },
        });
        store.dispatch({
          type: "DELETE_OBJECT",
          data: {
            id: obj.id,
            type: obj._Type,
            index: e,
            isUpdateRoute: true,
          },
        });
      }
    }
  });
  document.Disable = _prevDisableState;
};

// export const removeMusicToAllCxx = async({
//   id
// }) => {
//   const _allIndex = Object.keys(document.cxx);
//   for(let _indx=0;_indx<_allIndex.length;_indx++){
//     let cxx = document.cxx[_indx];
//   }
//     if (cxx) {
//       const deleteFromAll = async (id, notObj = false, index = Index()) => {
//         tryMe(async () => {
//           let obj = getById(
//             id,
//             null,
//             notObj ? document.cxx[index] || { _objects: [] } : null
//           );
//           console.log(obj);

//           if (obj) {
//             let canvas = document.cxx[index];

//             if (obj) {
//               canvas.setActiveObject(obj);
//               canvas.remove(obj);
//               // canvas.renderAll();

//               if (obj._Type === "music") {
//                 let ms = document.getElementById(obj.id);
//                 if (ms) {
//                   ms.remove();
//                 }
//               }

//               store.dispatch({
//                 type: "DELETE_OBJECT",
//                 data: { id: obj.id, type: obj._Type, index },
//               });
//               canvas.fire("object:modified");
//               await UpdateBackground();
//             }
//           }
//         }, "deleteFromAll");
//       };

//     }
//   });
// };

export const addEventsInAudioHelper = (time, aud, _scene) => {
  //const index = _timeLineDurations.crntSceneId;
  const _mediaTime = aud?.currentTime;
  const _elementD = _scene.elements[aud.id];
  if (_elementD) {
    const { trimStart, trimEnd, adjustLength, stayTime, enter, enterDelay } = _elementD;
    if (adjustLength === 0) {
      if (trimStart > _mediaTime) {
        aud.customVideoSeek(trimStart);
      }
      if (trimEnd < _mediaTime) {
        aud.pause();
      }
      if (enterDelay + stayTime < time) {
        aud.pause();
      }
    }
    if (adjustLength === 1 || adjustLength === 4) {
      if (trimStart > _mediaTime || trimEnd < _mediaTime) {
        aud.customVideoSeek(trimStart);
      }
    }
  }
};

export const addEventsInAudio = (aud, index = Index()) => {
  aud.onplay = () => {
    console.log(aud.id, " is playing");
  };
  aud.onpause = () => {
    console.log(aud.id, " is pausing");
  };
  aud.ontimeupdate = () => { };
};

window.gsap = gsap;

export const updateMusic = () => {
  let musicList = JSON.parse(store.getState().media.music);
  musicList.map((e) => {
    let audEle = document.querySelector(`#${e.id}`);
  });
};

export async function customVideoSeek(duration) {
  return new Promise((resolve, reject) => {
    if (isNaN(duration)) {
      resolve(1)
    } else {
      this.currentTime = duration;
      this.addEventListener(
        "seeked",
        () => {
          resolve(1);
        },
        {
          once: true,
        }
      );
    }
  });
}

export default () => { };
