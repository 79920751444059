import { memo, useState, useEffect } from "react";

import { getById, fn, Index, getCordinate, selectAll } from "../../../utils";
import { getObjectCoords } from "../../../utils/cropyThings";
// import { AvatarPosition } from "../../LeftPanel/components/Avatar/Avatar";
import LabelInput from "./LabelInput";
import "./_Atom.scss";

function Dimensions({
  disable = { w: false, h: false, x: false, y: false },
  className = "",
  title,
  children,
  ele = <></>,
  id,
  obj,
}) {
  const [dim, setDim] = useState({ w: 0, h: 0, x: 0, y: 0 });
  const [ratio, setRatio] = useState(false);
  useEffect(() => {
    ////console.log(`${id} ${Index()}`);

    setTimeout(() => {
      let _obj = getById(id);
      if (obj) {
        _obj = obj;
      }
      if (_obj) {
        let dim = getObjectCoords(_obj);
        setDim({
          w: fn(dim.width),
          h: fn(dim.height),
          x: fn(dim.left),
          y: fn(dim.top),
        });
      }
    }, 500);

    document.fxx.change[id] = (obj) => {
      let dim = getObjectCoords(obj);

      setDim({
        w: fn(dim.width),
        h: fn(dim.height),
        x: fn(dim.left),
        y: fn(dim.top),
      });
      // ////console.log(obj)
    };
  }, [id]);

  const handleChange = (st) => {
    let o = {
      w: "width",
      h: "height",
      x: "left",
      y: "top",
    };
    return (e) => {
      let _obj = getById(id);
      if (_obj) {

        // _obj.set({ [o[st]]: Number(e.target.value) });

        _obj.canvas.renderAll();
        _obj.canvas.fire("object:modified");

      }
      setDim({ ...dim, [st]: e.target.value });
    };
  };

  // const fireHandle = () => {
  //   let _obj = getById(id);
  //   if (_obj) {
  //   }
  // };

  const handleF = (e) => {
    selectAll(e)
  }
  const handleChang = st => {
    // e.target.value = 
    let o = {
      w: "width",
      h: "height",
      x: "left",
      y: "top",
    };
    return (e) => {
      let _obj = getById(id);
      if (_obj) {
        let pro = o[st];
        switch (pro) {
          case "width":
            _obj.set({ scaleX: Number(e.target.value) / _obj.width });

            break;
          case "height":
            _obj.set({ scaleY: Number(e.target.value) / _obj.height });
            break;
          case "top":
          case "left":
            _obj.set({ [pro]: Number(e.target.value) });
            break;
          default:
            break;
        }
        setDim({ ...dim, [st]: e.target.value });

      }

    }
  }


  return (
    <div className={`Dimensions ${className}`}>
      <div className="Dimensions_col">
        <div className="Dimensions_col_input">
          <div className="Dimensions_col_input_row">
            <LabelInput
              label="W"
              value={dim.w}
              ml="5px"
              disable={disable.w}
              onBlur={handleChange("w")}
              onChange={handleChang("w")}
              onEnter={handleChange("w")}
              // onCleanup={e => handleChange("w")({ target: { value: e } })}

              onFocus={handleF}
            />
            <img src={`${document.PUBLIC_URL}/icons/linkActive.svg`} alt={"link"} />
            <LabelInput
              label="H"
              value={dim.h}
              ml="5px"
              disable={disable.h}
              onBlur={handleChange("h")}
              onChange={handleChang("h")}
              onFocus={handleF}

              onEnter={handleChange("h")}
            />
          </div>
          <div className="Dimensions_col_input_row">
            <LabelInput
              label="X"
              value={dim.x}
              ml="5px"
              disable={disable.x}
              onBlur={handleChange("x")}
              onChange={handleChang("x")}
              onEnter={handleChange("x")}
              onFocus={handleF}

            />
            <LabelInput
              label="Y"
              value={dim.y}
              ml="5px"
              disable={disable.y}
              onBlur={handleChange("y")}
              onChange={handleChang("y")}
              onEnter={handleChange("y")}
              onFocus={handleF}

            />
          </div>
        </div>
        <div className="Dimensions_col_position">{ele}</div>
      </div>
    </div>
  );
}
export default memo(Dimensions);
