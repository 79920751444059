import { getNumber } from "../../utils";
import { convertOrigin, getObjectCoords } from "../cropyThings";
import { universalPause } from "./universalPause";

export const universalPlay = async ({
  vidID = null,
  havePause = false,
  playByU = true,
}) => {
  if (document.Player.status !== 1) {
    document.Player.onPlay();
  } else {
    if (havePause) {
      universalPause({});
    }
  }
};


const setVideoElmCoords = (obj) => {
  const _vidElm = obj?._originalElement;
  if (obj?.canvas && _vidElm) {
    _vidElm.style.position = "absolute";
    _vidElm.style.zIndex = "-100";
    obj.canvas.wrapperEl.style.overflow = "hidden";
    obj.canvas.wrapperEl.appendChild(_vidElm);
    obj.globalCompositeOperation = 'destination-out'
    obj.canvas.renderAll();
    const _cropX = getNumber(obj.cropX) * obj.scaleX, _cropY = getNumber(obj.cropY) * obj.scaleY;
    const _coords = getObjectCoords(obj);
    const _zM = obj.canvas.getZoom();
    const _ff = obj.calcTransformMatrix().map((e) => e * _zM);
    _vidElm.style.transformOrigin = `left top`
    _vidElm.style.transform = `matrix(${_ff[0]},${_ff[1]},${_ff[2]},${_ff[3]},${(_coords.left - _cropX) * _zM},${(_coords.top - _cropY) * _zM})`;
  }
}

const initVideoWrapper = (obj, _indx = 0) => {
  const cxx = obj?.canvas;
  if (cxx && obj._Type === 'video') {
    cxx.wrapperEl.style.overflow = "hidden";
    let wrapper = document.createElement('div');
    const _vidElm = obj?._originalElement;
    wrapper.style.height = `${_vidElm.height}px`;
    wrapper.style.width = `${_vidElm.width}px`;
    wrapper.id = `videoWrapper_${_vidElm.id}`
    wrapper.style.overflow = "hidden";
    _vidElm.style.display = 'none'
    wrapper.appendChild(_vidElm);

    cxx.wrapperEl.appendChild(wrapper);
    wrapper.style.position = "absolute";
    wrapper.style.zIndex = `${_indx}`;
    wrapper.style.transformOrigin = `left top`
    obj.globalCompositeOperation = 'destination-out'
    obj._element = null;
    obj._videoWrapper = wrapper;
    obj.backgroundColor = "#ffffff"
    document.updateVideoElementCoords(obj, true)
  }
}

const removeVideoWrapper = (obj) => {
  if (obj._Type === 'video') {
    const cxx = obj?.canvas;
    if (obj?._videoWrapper && cxx) {
      const _media = document.querySelector('.Media');
      _media.appendChild(obj._originalElement);
      cxx.wrapperEl.removeChild(obj._videoWrapper)
    }
    obj.globalCompositeOperation = 'source-over'
    obj._element = obj?._originalElement;
    obj.backgroundColor = "";
    obj.videoTime = Math.random();
  }
}


const updateVideoElementCoords = (obj, _first = false) => {
  const _marginDist = 0.5;
  if (obj._Type !== 'video') {
    return false;
  }
  const _vidElm = obj?._originalElement;
  const _maskCanvas = obj._cacheCanvas;
  // get mask url
  let _maskUrl;
  if (obj.clipPath && _first) {
    const _clipData = obj._getCacheCanvasDimensions();
    const _newW = obj.width;
    const _newH = obj.height;
    let _canvas = document.createElement("canvas");
    _canvas.width = _newW;
    _canvas.height = _newH;
    let _offsetX, _offsetY, _height, _width;
    if (_maskCanvas.width > _clipData.x) {
      _offsetX = (_maskCanvas.width - _clipData.x) / 2;
      _offsetY = (_maskCanvas.height - _clipData.y) / 2;
      _height = _clipData.y;
      _width = _clipData.x;
    } else {
      _offsetX = 0;
      _offsetY = 0;
      _height = _maskCanvas.height;
      _width = _maskCanvas.width;
    }

    var ctx = _canvas.getContext("2d");
    ctx.drawImage(_maskCanvas, _offsetX, _offsetY, _width, _height, 0, 0, _newW, _newH);
    _maskUrl = _canvas.toDataURL("image/png");
  }

  if (_vidElm && obj?.canvas && obj?._videoWrapper) {
    if (obj.visible) {
      if (_vidElm.style.display === 'none') {
        _vidElm.style.display = ''
      }
      const _cropX = getNumber(obj.cropX), _cropY = getNumber(obj.cropY);
      const _coords = getObjectCoords(obj);
      const _zM = obj.canvas.getZoom();
      const _ff = obj.calcTransformMatrix().map((e) => e * _zM);
      // increase video area
      const _increaseScle = (2 * _marginDist) / obj.width;
      const _newMarginY = obj.height * (_increaseScle / 2);
      obj._videoWrapper.style.transform = `matrix(${_ff[0] + _increaseScle},${_ff[1]},${_ff[2]},${_ff[3] + _increaseScle},${(_coords.left * _zM) - _marginDist},${(_coords.top * _zM) - _newMarginY})`;
      //obj._videoWrapper.style.opacity = obj.opacity;
      //obj.backgroundColor = `rgba(255,255,255,${obj.opacity})`

      //obj._videoWrapper.style.transform = `matrix(${_ff[0]},${_ff[1]},${_ff[2]},${_ff[3]},${(_coords.left * _zM)},${(_coords.top * _zM)})`;
      obj._videoWrapper.style.height = `${obj.height}px`;
      obj._videoWrapper.style.width = `${obj.width}px`;
      //obj._videoWrapper.style.transformOrigin = `${-_marginDist}px ${-_newMarginY}px`
      _vidElm.style.transform = `translate(${-1 * _cropX}px, ${-1 * _cropY}px)`;

      if (_maskUrl) {
        obj._videoWrapper.style.webkitMaskImage = `url(${_maskUrl})`;
        obj._videoWrapper.style.maskImage = `url(${_maskUrl})`;
        //obj._videoWrapper.style.webkitMaskSize = `cover`;
      }
    } else {
      if (_vidElm.style.display !== 'none') {
        _vidElm.style.display = 'none';
      }
    }

  }
}

const hideCanvasWrapperVideo = (index) => {
  Object.keys(document.cxx).forEach((_indx) => {
    if (Number(_indx) !== index) {
      const _vids = document.cxx[_indx].wrapperEl.getElementsByTagName('video');
      for (let ii = 0; ii < _vids.length; ii++) {
        _vids[ii].style.display = 'none'
      }
    } else {
      const _vids = document.cxx[_indx].wrapperEl.getElementsByTagName('video');
      for (let ii = 0; ii < _vids.length; ii++) {
        _vids[ii].style.display = 'block'
      }
    }
  })
}

document.hideCanvasWrapperVideo = hideCanvasWrapperVideo;
document.setVideoElmCoords = setVideoElmCoords;
document.updateVideoElementCoords = updateVideoElementCoords;
document.initVideoWrapper = initVideoWrapper;
document.removeVideoWrapper = removeVideoWrapper;
window.getObjectCoords = getObjectCoords;
window.convertOrigin = convertOrigin;
