/* eslint-disable */
import "./_Tabs.scss";
// import I from "./Icons/";
// import { useCallback, useMemo, useState,useRef } from "react";
import { useRouteMatch, useLocation } from "react-router";
import Tab from "./components/Tab/Tab";
import { Index } from "../../../../utils";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

export default connect((state) => ({ haveVid: state.active.haveVid }))(
  function Tabs({ currentTab, tabIconNameList, handleCurrentTab, haveVid }) {
    ////console.log("Tabs renders");
    let { pathname } = useLocation();
    let b = pathname.split("/")[4];
    // const [show, setShow] = useState(false);
    // useEffect(() => {
    //   setTimeout(() => {
    //     setShow(true);
    //   }, 2000);
    // }, []);

    ////console.log(`%c${b}`,"color:blue")

    return (
      <div className="Tabs">
        {haveVid &&
          tabIconNameList.map((e) => {
            return (
              <Tab
                key={e.name}
                name={e.name}
                asrc={e.asrc}
                src={e.src}
                onClick={e.onClick}
                onTabChoose={handleCurrentTab}
                isActive={e.name === b}
              />
            );
          })}
      </div>
    );
  }
);
