/* eslint-disable */
import React, { useState } from "react";
import TitleRow from "../../../Chemistry/Atom/TitleRow";
import "./_Text.scss";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import Vr from "../../../Chemistry/Atom/Vr";
import Color from "../../../Chemistry/Atom/Color";
import EffectCompound from "../../../Chemistry/Compound/EffectCompound";
import SlideContainer from "../../../Chemistry/Atom/SlideContainer";
import { getById } from "../../../../utils";

export default function EffectText({ id }) {
  const { state } = useLocation();
  const hist = useHistory();
  const [now, setNow] = useState(getById(state.id)._Data?.id || 0);

  return (
    <SlideContainer>
      <div className="EffectText">
        <TitleRow
          cursor="pointer"
          title="< Effects"
          onClick={() => hist.goBack()}
        />
        <div className="EffectText_wrapper">
          {[
            { src: "none", x: 0, y: 0 },
            { src: "1", x: 5, y: 0 },
            { src: "2", x: 2, y: -1 },
            { src: "3", x: 2, y: -2 },
            { src: "4", x: 7, y: 2 },
            { src: "5", x: 2, y: -2 },
            { src: "6", x: 2, y: -2 },
            { src: "7", x: 0, y: 0 },
          ].map((k, i) => {
            return (
              <div
                key={k.src}
                className={`EffectText_wrapper_box ${now === i ? "EffectText_wrapper_box_active" : ""
                  }`}
                onClick={() => {
                  setNow(i);
                }}
              >
                <img
                  className="EffectText_wrapper_box_img"
                  src={`${document.PUBLIC_URL}/ico4/${k.src}.svg`}
                  alt={k}
                // style={{ top: k.y + "px", left: k.x + "px" }}
                />
              </div>
            );
          })}

        </div>
        <EffectCompound id={state.id} ef={now} />
      </div>
    </SlideContainer>
  );
}
