/* eslint-disable */
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
// import { url, token } from "../../../config/config";
import axios from "axios";

import { ReactComponent as CATClicked } from "../../../extras/analytics/CTAClicked.svg";
import { ReactComponent as Cross } from "../../../extras/account/cross.svg";

import Camera from "../../../extras/account/cam.svg";
import Info from "../../common/infoIcon";
import Loadingg from "../../common/loading";
import { fetcher, url } from "../../../../utils";

export default function EditProfile(props) {
	const [Loading, setLoading] = useState(false);
	const [Pass, setPass] = useState({})
	const [Erroor, setErroor] = useState("")
	const [Close, setClose] = useState(false)
	const handleChange = (e) => {
		setErroor("")
		setPass({
			...Pass,
			[e.target.name]: e.target.value
		})

	}



	const handleSave = () => {
		console.log("im running");
		setErroor("")
		console.log(Pass);
		console.log([Pass["newpassword"], Pass["confirmpassword"], Pass["oldpassword"]]);
		if (Pass["newpassword"].length < 6) {

			setErroor("Password length not match.")
		}
		else if (Pass["newpassword"] === Pass["confirmpassword"]) {
			console.log("im running3");
			var obj = { ...Pass }
			delete obj["confirmpassword"]


			// axios.post(`${url}/api/accounts/password/change/`, obj, options)
			// 	.then(res => {

			// 		// window.location.reload();
			// 	})
			fetcher({
				url: `${url}/api/accounts/password/change/`,
				method: "POST",
				data: obj,
				res: res => {

					if (res.data.success) {
						setClose(true)

					} else {
						setClose(false)
						Object.keys(res.data).map(d => {
							setErroor(`${d} : ${res.data[d]}`)
						})
					}
				}
			})


		}
		// else {
		// 	setErroor("Password not match.")
		// }
	}

	// console.log(Pass);

	return (
		<Popup
			trigger={props.children}
			modal
			nested
			onOpen={async () => {
				setErroor("")
				setPass({})
				setClose(false)

			}}
		>
			{(close) => (

				<div className="modal-dialog modal-dialog-centered  analytic-detail-popup-video CAT ProfilePopupEdit passwordChangePopup">
					<div className="modal-content vd-modal-content">
						<div className="modal-header vd-modal-header padding-40-popup">
							<div className="header-analyticp-poopup">
								<h5 className="modal-title-analutics">
									Change Password&nbsp;&nbsp;{" "}

								</h5>


							</div>
							{Close ? close() : null}
							<div className="header-popup-analytics-rightside">

								<Cross
									className="pointer"
									onClick={() => {
										close();
									}}

								/>
							</div>
						</div>
						<div className="modal-body modal-analytic-popup padding-40-popup pt-0">
							<div className="chart-container flex-column">
								{Loading ? (
									<Loadingg />
								) : (
									<div className="cat-list-container">
										<div className="profile-edit-popup-wrapper">
											<div className="bx8882_2">
												<div className="bxppp_list bcpp_lower">
													{[{
														name: "Current Password",
														lable: "oldpassword",
													},
													{
														name: "New Password",
														lable: "newpassword",
													}, {
														name: "Re-Enter New Password",
														lable: "confirmpassword",


													}
													].map(d =>
													(<div className="bxppp-item">

														<label htmlFor="" className="label-bx88i_name pxbllk11">{d.name}</label>
														<input type="password" defaultValue="" name={d.lable} placeholder={`Enter ${d.name}`} className="bx88i_input pxbllk11" onChange={(e) => { handleChange(e) }} />
													</div>)
													)}
												</div>
											</div>
										</div>
									</div>
								)}


								<div className="error-inpopup text-center">
									{Erroor.length > 0 ? Erroor : null}
								</div>
							</div>
						</div>
						<div className="modal-footer vd-modal-footer">

							<span>
								<button className="cancel-popup-profile-edit" onClick={() => {
									close()
								}}>Cancel</button>

								<button
									type="button"
									className="popup-profile-edit-select-btn"
									data-bs-dismiss="modal"
									onClick={() => {
										handleSave()
									}}
								>
									Save
								</button>
							</span>
						</div>


					</div>
				</div>
			)}
		</Popup>
	);
}
