/* eslint-disable */
import React, {

  useEffect,
  useRef,
  useState,
} from "react";
import TitleRow from "../../../../../Chemistry/Atom/TitleRow";
import {
  ControlsBackground,
  InputRangeWithTitle,
} from "../Background/BackgroundSettings";
import Vr from "../../../../../Chemistry/Atom/Vr";
import "./_TextSettings.scss";
import { OrderRow } from "../../../Avatar/Avatar";
import TitleWrapper from "../../../../../Chemistry/Atom/TitleWrapper";
import Dimensions from "../../../../../Chemistry/Atom/Dimensions";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { getById, getStyles, Index, selectAll, Vid } from "../../../../../../utils";
import EditSelect from "../../../../../Chemistry/Atom/EditSelect";
import Color from "../../../../../Chemistry/Atom/Color";
import EffectText from "../../../Text/EffectText";
import Angle from "../../../../../Chemistry/Atom/Angle";
import { deleteFromAll } from "../../../../../../utils/deleteCloneOpeationObj";
import ArrowWrapper from "../../../../../Chemistry/Atom/ArrowWrapper";
import ButtonIcon from "../../../../../Chemistry/Atom/ButtonIcon";
import Animation from "../../../../../Chemistry/Compound/Animation";
import objectCloner from "../../../../../../utils/objectCloner";
import LabelInput from "../../../../../Chemistry/Atom/LabelInput";
import { FABRIC_FONTS } from "../../../../../../utils/config";
import { storeAnimatationToStore } from "../../../../../../utils/shortcutFunction";
import { connect } from "react-redux";
import loadAllFonts, { loadFont } from "../../../../../../utils/loadFonts";
import { DelaySettingsInput } from "../../../../../Chemistry/One/DelaySettingsInput";
import { TabColor } from "../Elements/ElementSetting";


export default connect(state => ({
  mergeTag: state.media.mergeTag.list

}))(function TextSettings({ mergeTag }) {
  const { url } = useRouteMatch();
  const { state } = useLocation();
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(false)
    setTimeout(() => {
      setShow(true)
    }, 0)
  }, [state?.id])
  return (
    <Switch>
      <Route path={`${url}/effects`}>
        <EffectText />
      </Route>
      {document.editorType === "video" && <Route path={url + "/animation"}>
        <Animation
          type="tanimation"
          title="< Animation"
          obj={getById(state.id)}
          onSelect={storeAnimatationToStore(state.id)}
        />
      </Route>}
      <Route exact path={url}>
        {show && <DefaultPage objActive={state.id} mergeTag={document._mtag} />}
      </Route>
    </Switch>
  );
})

function DefaultPage({ objActive, mergeTag }) {
  const { state } = useLocation();
  const { url } = useRouteMatch();
  const hist = useHistory();
  const [id, setId] = useState(state.id);
  useEffect(() => {

    if (objActive) {

      setId(objActive);
    }
  }, [objActive])

  const handleChange = (e) => {
    const { cxx } = document;
    let obj = getById(id);
    if (obj) {
      obj.set({ opacity: e / 100 });
      obj.canvas.renderAll();
    }
  };

  const handleAddVariable = (e, i) => {

    let clickedMTag = mergeTag[i].name;
    let _text = getById(id);
    // const clickedMTag = e.target.innerText;
    const curntEdit = _text.isEditing;
    if (!curntEdit) {
      _text.enterEditing();
    }
    _text.insertChars(
      clickedMTag,
      null,
      _text.selectionStart,
      _text.selectionEnd
    );
    _text.selectionEnd += clickedMTag.length;
    _text.selectionStart += clickedMTag.length;
    // addTextBoxStyle();
    _text.exitEditing();
    _text.canvas.renderAll();
    if (curntEdit) {
      _text.enterEditing();
    }
  };

  const variableFocus = (e) => {
    setMerge(`{{variable}}`)
    setTimeout(() => {

      e.target.setSelectionRange(2, 10)
    }, 0)
  }

  const variableChange = e => {
    setMerge(e)

  }

  const onEnter = e => {
    handleAddVariable(e.target.name)
  }
  const [merge, setMerge] = useState("Insert Variable")

  return (
    <>
      <div className="TextSettings">
        <TitleRow title="Text Settings" />
        <ControlsBackground
          text="Change Effect"
          on_1={() => hist.push("text/effects", { id: id })}
          _1_src={`${document.PUBLIC_URL}/ico/changeEffect.svg`}
          _2_src={`${document.PUBLIC_URL}/ico/clone.svg`}
          on_3={(e) => {
            deleteFromAll(id);
          }}
          on_2={() => {
            objectCloner({ _id: id, clone: true });
          }}
        />
        <Vr m="15px" />
        <TitleWrapper title="Arrange">
          <OrderRow id={id} />
        </TitleWrapper>
        <Vr m="0px" />

        {document.editorType === "video" && <>
          <ArrowWrapper
            m="15px"
            p="10px 0px"
            to={`${url}/animation`}
            data={{ id: id }}
            ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/animation.svg`} />}
          >
            Animation
          </ArrowWrapper>
          <Vr m="0px" />
        </>}

        {/* <Vr m="10px" /> */}
        <TitleWrapper title="Text">
          <TextFormating id={id} />
        </TitleWrapper>
        {/* <Vr m="10px" /> */}



        <Vr m="10px 0px" />

        <TitleWrapper title="Opacity">
          <InputRangeWithTitle
            className="Opacity"
            value={getById(id).opacity * 100}
            onChange={handleChange}
            objActive={id}
          />
        </TitleWrapper>


        <Vr m="0px 0 15px" />
        <EditSelect
          className="TextFormating_merge newDropDown"
          selectedIndex={0}

          onSelect={handleAddVariable}

          // list={["{{name}}", "{{location}}"]}
          // arr={[{ name: "{{name}}", value: "{{name}}" }, { name: "{{location}}", value: "{{location}}" }, { name: "Custom Variable", value: "{{Variable}}" }]}
          arr={mergeTag}
          // arr={}
          haveVariable={true}
          title="Insert Variable"
          ele={"Insert Variable"}
        />
        <Vr m="15px" />

        <TitleWrapper title="Layout">
          <Dimensions
            disable={{ w: true, h: true }}
            id={id}
            ele={<Angle angle={45} id={id} />}
          />
        </TitleWrapper>
        <Vr />

      </div>
      <DelaySettingsInput id={objActive} />
    </>
  );
}

const TextFormating = connect((state) => ({ fonts: state.media.fonts.list }))(
  ({ id, fonts }) => {
    const ico = {
      left: "left.svg",
      center: "center.svg",
      right: "right.svg",
      bold: "bold.svg",
      underline: "underline.svg",
      italic: "italic.svg",
      strike: "strike.svg",
      fill: "fill.svg",
      color: "color.svg",
      stroke: "stroke.svg",
    };
    const i = (isActive) => (name) => {
      return `${document.PUBLIC_URL}/ico/text/${isActive ? "a" : ""}${ico[name]}`;
    };


    const { lastText } = document.zx;

    const [styles, setStyles] = useState({
      textAlign: lastText.textAlign || "left",
      fontWeight: lastText.fontWeight || "bold",
      fontStyle: lastText.fontStyle || "normal",
      underline: lastText.underline || false,
      linethrough: lastText.linethrough || false,
      fontSize: lastText.fontSize,
      fill: lastText.fill || "#ff00ff",
      stroke: lastText.stroke || "#ffffff",
      textBackgroundColor: lastText.textBackgroundColor || "#ffffff",
      fontFamily: lastText.fontFamily || "Poppins Regular",
      lineHeight: lastText.lineHeight || 1.16,
      charSpacing: lastText.charSpacing || 0,
    });
    const _textFormating = (style, family, c = false) => {
      let _text = document.cxx[Index()].getActiveObject();
      if (_text) {
        if (_text._Type === "text") {
          if (family === "textAlign") {
            _text.textAlign = style;
          } else if (family === "lineHeight" || family === "charSpacing") {
            _text.set({ [family]: style });
            _text.canvas.renderAll();
            _text.canvas.renderAll();
          } else {
            let selection = _text.getSelectedText();

            if (selection === "") {
              _text.enterEditing();
              _text.selectAll();
              _text.set({ [family]: style });
            } else {
            }
            _text.setSelectionStyles({
              [family]: style,
            });
          }
          if (!c) {
            _text.canvas.fire("object:modified");
          }
          _text.canvas.renderAll();
        }
      }
    };

    useEffect(() => {
      // let obj = getById(id);
      let obj = document.cxx[Index()].getActiveObject();
      if (obj) {
        obj.on("selection:changed", () => {
          const _styles = getStyles(obj);
          if (styles !== _styles) {
            setStyles(_styles);
          }
        });

        setStyles(getStyles(obj));
      }

      return () => {
        if (obj) {
          obj.off("selection:changed");
        }
      };
    }, [id]);

    const [_f, setF] = useState(false)

    useState(() => {
      setTimeout(() => {
        setF(true)
      }, 0)
    }, [])

    const handleFormat = (a, b, c) => {
      return async () => {
        if (b === "fontFamily") {
          let f = fonts?.[a];
          if (!f) {
            f = { name: c };
          }
          await loadFont(f.name);

          _textFormating(f.name, b, false);
          setStyles((st) => {
            let p = { ...st, [b]: f.name };
            document.zx.lastText = p;
            return p;
          });
        } else {
          _textFormating(a, b, c);
          setStyles((st) => {
            let p = { ...st, [b]: a };
            document.zx.lastText = p;
            return p;
          });
        }
      };
    };

    document.handleFormat = handleFormat;


    // for jump to color panel
    const handleColorPush = (e) => {
      return () => {
        document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/color`, e);
      };
    };



    const onFontSelect = (e, p = { handleFormat }) => {
      if (!Number.isNaN(parseInt(e))) {

        let f = Number(e);

        if (f < 500 && f > 5) {
          p.handleFormat(Number(e), "fontSize", true)();
        }
      }
    };

    return (
      <div className="TextFormating">
        <div className="flex j-between acenter TextFormating_row">
          <EditSelect
            className="TextFormating_fontfamily"
            selected={styles.fontFamily}
            onSelect={(e, i) => handleFormat(i, "fontFamily", e)()}
            mustCloseOnSelect={false}
            arr={fonts}
            disabled={true}
            hasFont={true}
          />

          <EditSelect
            // haveInc={tru}
            className="TextFormating_fontsize"
            onSelect={(k) => {

              onFontSelect(Number(k));
              document.functionQueue.run({ from: "flush" });
            }}
            list={[
              6, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 56, 64, 72, 80, 90, 100, 110
            ]}
            // onInputChange={onFontSelect}
            mustCloseOnSelect={false}

            wantFilter={false}
            secondClose={false}
            onShow={k => {
              // if (!k) {
              // console.log("SHOW");
              setTimeout(() => {

                document.functionQueue.run({ from: "lastOne" });
              }, 100)

              // }
            }}
            ele={

              <LabelInput
                className="TextFormating_fontsize"
                // onCleanup={e => onFontSelect(Number(e))}
                haveInc={true}
                type="number"
                min={20}
                max={600}
                width={"90px"}
                innerWidth={"46px"}
                step={1}
                value={String(Math.round(Number(styles.fontSize)))}
                extraFunction={
                  () => {
                    document.functionQueue.run({});

                  }
                }

                onChange={e => {
                  if (Number(styles.fontSize) !== Number(e.target.value)) {
                    // onFontSelect(e.target.value);
                    setStyles(s => ({ ...s, fontSize: Number(e.target.value) }))
                  }

                }}
                onBlur={e => {
                  document.functionQueue.add(
                    onFontSelect, this, [Number(e.target.value), { handleFormat }])
                  setTimeout(() => {
                    document.functionQueue.run({ from: "lastOne" })
                  }, 500)
                }
                }
                onEnter={e => onFontSelect(Number(e.target.value))}
                onFocus={(e) => selectAll(e)
                }

              />
            }
          />

        </div>
        <div className="flex TextFormating_row2 jbetween">
          {/* <img
            src={`${document.PUBLIC_URL}/ico/text/color.svg`}
            onClick={handleColorPush({
              id,
              title: "Fill Color",
              property: "fill",
            })}
            alt="abc"
          />*/}


          <div className="width50">

            {_f && <TabColor style={{ marginLeft: "10px", }} title={"Font Color"} obj={getById(id)} _color={styles.fill} onClick={handleColorPush({
              id,
              title: "Fill Color",
              property: "fill",
            })} />}


          </div>


          <div className="flex row acenter width70">


            <img
              alt="df"
              src={i(false)("fill")}
              onClick={handleColorPush({
                id,
                title: "Background Color",
                property: "textBackgroundColor",
              })}
            />

            <img
              src={`${document.PUBLIC_URL}/ico/text/stroke.svg`}
              onClick={handleColorPush({
                id,
                title: "Stroke Color",
                property: "stroke",
              })}
              alt="abc"
            />
          </div>

          {/* </ColorToggle> */}
        </div>




        <div className="flex  TextFormating_row2 jbetween ">
          <div className="width50">
            <LabelInput
              inputClassName="darkBackground"
              ml={"5px"}
              value={styles.lineHeight}
              label={<img src={`${document.PUBLIC_URL}/ico1/lineHeight.svg`} />}
              onChange={(e) => handleFormat(e.target.value, "lineHeight")()}
            />
          </div>

          <div className="width70">
            <LabelInput
              inputClassName="darkBackground"
              ml={"5px"}

              value={styles.charSpacing}
              label={<img src={`${document.PUBLIC_URL}/ico1/charSpace.svg`} />}
              onChange={(e) => handleFormat(e.target.value, "charSpacing")()}
            />
          </div>

        </div>
        <div className="flex j-between mt10">

          <div className="flex TextFormating_row  width50">
            <img
              src={i(styles.fontWeight === "bold")("bold")}
              onClick={handleFormat(
                styles.fontWeight === "bold" ? "normal" : "bold",
                "fontWeight"
              )}
              alt=""
            />
            <img
              src={i(styles.fontStyle === "italic")("italic")}
              onClick={handleFormat(
                styles.fontStyle === "italic" ? "normal" : "italic",
                "fontStyle"
              )}
              alt=""
            />
            <img
              src={i(styles.underline)("underline")}
              onClick={handleFormat(!styles.underline, "underline")}
              alt=""
            />
            <img
              src={i(styles.linethrough)("strike")}
              onClick={handleFormat(!styles.linethrough, "linethrough")}
              alt=""
            />
          </div>
          <div className="flex TextFormating_row acenter jstart width70">
            <img
              src={i(styles.textAlign === "left")("left")}
              onClick={handleFormat("left", "textAlign")}
              alt=""
            />
            <img
              src={i(styles.textAlign === "center")("center")}
              onClick={handleFormat("center", "textAlign")}
              alt=""
            />
            <img
              src={i(styles.textAlign === "right")("right")}
              onClick={handleFormat("right", "textAlign")}
              alt=""
            />
          </div>
        </div>

      </div>
    );
  }
);

export const ColorToggle = ({
  ele,
  children,
  color,
  onChange = () => { },
  onLastChange = () => { },
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const handle = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        //    handleChoose(_selected)()
        setShow(false);
        // onShow(false);
      }
    };
    document.addEventListener("mousedown", handle);
    return () => {
      document.removeEventListener("mousedown", handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={"ColorToggle"} ref={ref}>
      <div onClick={() => setShow(!show)}>{children}</div>

      {show && (
        <Color onChange={onChange} color={color} onLastChange={onLastChange} />
      )}
      {show && <div className="ColorToggle_tip"></div>}
    </div>
  );
};
