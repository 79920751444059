/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Screen from "./Screen";
import { fabric } from "fabric";
import { useHistory } from "react-router";
import {
  fetcher,
  Vid,
  url,

  veryFirstIndex,
  Jump,

} from "../../utils";
import store from "../../store/store";
import {
  addAllMusicFromJSON,

  giveAllEventAndTimeLine,
  removeExtraMusicLoopId,
  verifierElements,
} from "../../utils/loadFromServer";

import TimeLine from "../TimeLine/TimeLine";
import addAvatarToScreenFirstTime from "../LeftPanel/components/Avatar/functions/addAvatar";

import LeftPanel from "../../components/LeftPanel/LeftPanel";
import RightPanel from "../../components/RightPanel/RightPanel";
import Header from "../../components/Header/Header";
import Lazzer from "../Chemistry/One/Lazzer";
import {
  display,
} from "../../utils/videoPlayThings/addVideoToCanvasAndStore";
import { SAVE_TIME } from "../../utils/config";
import { universalPause } from "../../utils/videoPlayThings/universalPause";
import { addCanvasResizeEvent } from "../../utils/useFullFunctions/discardWork";
import { idConversion } from "../../utils/idConversion";
import { loadVideo } from "../LeftPanel/components/Video/functions/createVideoElement";

function setPtabData(_id) {



  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  console.log(params);

  let _ptab = params.ptab;
  console.log(_ptab);
  if (_ptab) {
    const _orgUrl = window.location.href.split('?')[0];
    sessionStorage.setItem(`_ptab_${_id}`, _ptab)
    window.history.replaceState({}, document.title, _orgUrl);

  }

}

const mptos = (state) => {
  return {
    sceneArr: JSON.parse(state.scenes.currentScene.sceneArr),
    sceneNumber: state.sceneNumber
  };
};

export default connect(mptos)(function Screens({ sceneArr, sceneNumber }) {
  const ref3 = useRef();
  const ref2 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const hist = useHistory();
  // const [data, ]

  // const hist = useHistory();
  const [isGet, setGet] = useState(false);
  useEffect(async () => {
    const handleContext = (e) => {
      e.preventDefault();
    }
    document.addEventListener("contextmenu", handleContext)

    if ("Crop Canvas") {
      const ccx = new fabric.Canvas(ref2.current);
      document.ccx = ccx;
      document.cccx = ref3;
      document.ccx.on("mouse:down", (e) => {
        if (!e.target?.clipImage) {
          let _clip = document.ccx._objects.find((e) => e.clipImage);
          if (_clip) {
            document.ccx.setActiveObject(_clip);
          }
        }
      });
    }

    if ("avatar Crop Cavnas") {
      document.ccxa = new fabric.Canvas(ref4.current);
      document.cccxa = ref5;
    }

    // use to navigate 
    document.hist = hist;

    // let Vi = Number(
    //   parseInt(/id=(\d+)/gi.exec(document.location.href)?.[1] || 0)
    // );
    let Vi = Vid()
    // store.dispatch({ type: "HAVE_VID", data: true });
    if (Vi !== 0) {
      console.log({ editorType: document.editorType });
      // hist.push(`/${document.editorType}/${Vi}/0/avatar`);
      localStorage.setItem("VideoID", Vi);
      document.title = Vi;
      console.log("step3");

    }
    // let Vi = Vid();
    if (Vi === 0 || Vi === "0") {
      fetcher({
        method: "POST",
        url: document.editorType === "video" ? `${url}/api/newvideo/video/create/` : `${url}/api/newimage/image/create/`,
        data: { d: JSON.stringify(store.getState().scenes) },
        res: (_data) => {
          //console.log(_data.data);
          let vid = _data.data.result.id;
          setPtabData(vid)

          hist.push(`/${document.editorType}/${vid}/0/avatar`);
          document.title = _data.data.result.name || "Untitled";

          store.dispatch({ type: "HAVE_VID", data: true });
          store.dispatch({ type: "RENAME", data: { name: _data.data.result.name } });

          setGet(true);
          setTimeout(() => {
            document.Save.save = true;
            addAvatarToScreenFirstTime({ index: 0 });
            document.requestManager.onlyOneTime(store.getState().scenes, SAVE_TIME);

          }, 0);

        },
      });
    } else {
      // store.dispatch({ type: "HAVE_VID", data: true });
      // hist.push(`/image/${Vi}/0/avatar`);
      setPtabData(Vi)

      document.title = "Loading Video..";
      fetcher({
        method: "GET",
        url: document.editorType === "video" ? `${url}/api/newvideo/video/${Vid()}/` : `${url}/api/newimage/image/${Vid()}/`,
        res: async (data) => {
          console.log("step1");

          let _p = JSON.parse(data.data.result.jsonData);
          console.log(_p);
          if (document._template) {
            let arr = JSON.parse(_p.currentScene.arr)
            arr.forEach(e => {

              _p[e] = idConversion({ json: _p[e], to: e, type: "template" })
            })
          }

          let _name = data.data.result?.name || 'Untitled';
          verifierElements({
            data: _p,
            cb: async (p) => {
              document.title = _name;

              if (data.data.result.isGenerated) {
                if (document.editorType === "video") {
                  document.hist.push(`/myvideos/${Vid()}`);
                } else {
                  document.hist.push(`/myimages/${Vid()}`);
                }
              } else {
                document._mode = p.mode ? 2 : 0;
                await store.dispatch({ type: "LOAD_FIRST", data: { jsonData: p, name: _name } });

                document.requestManager.onlyOneTime(p, SAVE_TIME);

                let next = veryFirstIndex();
                hist.push(`/${document.editorType}/${Vid()}/${next}/avatar`);

                store.dispatch({ type: "PARTIAL_SCENE_ACTIVE", data: next });

                document.Save.save = false;
                setGet(true);

                let arr = JSON.parse(p.currentScene.arr);

                let _arrPromise = [];
                arr.forEach((e) => {
                  _arrPromise.push(mainLoader({ p, e }))
                });
                (await Promise.all(_arrPromise));
                // remove extra music loop id
                removeExtraMusicLoopId();
                updateTextCoords();
                hist.push(Jump({ obj: {}, def: true }));
              }

            },
          });
        },
        status: (s) => {
          if (s === 404) {
            if (document.editorType === "video") {
              document.location.pathname = "/myvideos"
            } else {
              document.location.pathname = "/myimages"
            }
          }
        },
        err: (err) => {
          // hist.push(`/home`);
          console.log(err);
        },
      });
    }
    return () => {
      document.removeEventListener("contextmenu", handleContext)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let hidden = null;
    let visibilityChange = null;
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    const handleVisibilityChange = () => {
      if (document[hidden]) {
        //  this.setState({actions: [...this.state.actions, 'hide']});
        universalPause({})
      } else {
        //  this.setState({actions: [...this.state.actions, 'show']});
        // universalPlay({})
      }
    }

    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    }
  }, [])

  useEffect(() => {
    addCanvasResizeEvent()
  }, [])


  return (
    <>


      {isGet && (
        <>
          <Header />
          <LeftPanel />
          {document.editorType === "video" && <RightPanel />}
        </>
      )}
      {isGet &&
        sceneArr.map((e, i) => {
          return (
            e !== -1 && e !== null && (
              <>
                <Screen
                  show={e === sceneNumber}
                  key={e.toString()}
                  index={e}
                />
              </>
            )
          );
        })}

      {/* <div className="Screen"></div> */}

      <div className="CropCanvas" ref={ref3} onClick={() => {
        if (document.Player?.status !== 0) {
          document.outPlayMode();
        }
      }}>
        <canvas className="CropCanvass" ref={ref2}></canvas>
        <Lazzer />
      </div>

      <div className="AvatarCropCanvas" ref={ref5}>
        <canvas className="AvatarCropCanvass" ref={ref4}></canvas>
        <Lazzer />
      </div>

      {isGet && <TimeLine />}
    </>
  );
});




const mainLoader = async ({ p, e }) => {
  // let e = arr[i];


  // .forEach(async (e) => {
  document.Save.save = false;
  let modified = p[e].jsonData

  if (document.editorType === "video") {
    if (document._template) {

      // modified =.jsonData
    }
    // Video Loading
    let _allVideoData = (modified.objects || []).filter(
      (obj) =>
        obj._Type === "video"
    );

    let _allVideo = _allVideoData.map((e) => loadVideo(e._Video.media_file, e._Video.id, e.id));
    await Promise.all(_allVideo);

  } else {
    modified = await ({
      ...p[e].jsonData,
      objects: await p[e].jsonData.objects.map((obj) => {
        if (obj._Type === "video") {
          if (obj.src === obj._Video.thumbnail) {
            return { ...obj, isImage: true };
          } else {
            return {
              ...obj,
              src: obj._Video.thumbnail,
              isImage: false,
            };
          }
        }
        return obj;
      }),
    });
  }

  await new Promise((res) => document.cxx[e].loadFromJSON(
    JSON.stringify(modified),
    async (a) => {
      if (document.editorType === "video") {
        await addAllMusicFromJSON(
          modified,
          document.cxx[e],
          e
        );
      }

      giveAllEventAndTimeLine({ index: e, json: p[e] });
      store.dispatch({ type: "RANDOM" });
      document.smallScene.draw(e, true);

      res(true)
    }
  ))
}


const updateTextCoords = () => {
  fabric.charWidthsCache = {};
  Object.keys(document.cxx).forEach((index) => {
    const allT = document.cxx[index]._objects.filter(e => e._Type === 'text');
    allT.forEach((obj) => obj.initDimensions());
    document.cxx[index].renderAll();
  })
}