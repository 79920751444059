document.functionQueue = {
  arr: [],
  add: function (fn, ctx, parm) {
    let fun = this.make(fn, ctx, parm);
    this.arr.push(fun);
  },
  make: function (fn, ctx, parm) {
    return fn.bind(ctx, ...parm);
  },
  run: function ({ from = "all" }) {
    switch (from) {
      case "all":
        while (this.arr.length > 0) {
          let a = this.arr.shift();
          a();
        }
        break;
      case "lastOne":
        // if()
        let a = this.arr.pop();
        // a();
        if (a) {
          a();
        }
        while (this.arr.length > 0) {
          this.arr.shift();
        }
        break;
      case "flush":
        while (this.arr.length > 0) {
          this.arr.shift();
        }
        break;
      default:
        break;
    }
  },
};
