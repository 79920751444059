const elementObjToURL = (cxxJSON) => {
  // //console.log(cxxJSON);
  let newP = cxxJSON.objects.map((e) => {
    if (e._Type === "elements") {
      e.objects = e._Src;
      return e;
    } else {
      return e;
    }
  });
  cxxJSON.objects = newP;

  return cxxJSON;
};
export default elementObjToURL;
