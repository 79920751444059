/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import index from "uuid-random";
import { Index, MoveSeekTo, updateTimeLen } from "../../../utils";
import { per, UpdateBackground } from "../../Chemistry/One/Draggy";
import "./_TimeBar.scss";
import { perSecFind } from "./SceneBar";
import { customSeek } from "../../../utils/videoPlayThings";
import { updateVideosTime } from "../../../utils/videoPlayThings/updateVideosTimeHelper";
import { TimeZoom } from "./SceneBar/TimeZoom";
import { universalPause } from "../../../utils/videoPlayThings/universalPause";

// const perSecFind = (pix) =>
//   ((pix % 40) + 10) / (2 * Math.pow(5, 1 - Math.floor(pix / 40)));

export default connect((state) => ({
  offSetArr: state.config.offSetArr,
  arr: state.scenes.offSetArr,
  beforeMax: state.beforeMax,
  sceneNumber: state.sceneNumber
}))(function Time({ sceneNumber }) {
  const [timePerPix, setPix] = useState(90);
  const [mod, setMod] = useState(3);
  const [timeLen, setTimeLen] = useState(10);

  useEffect(() => {
    document.setPix = setPix;
    // document.timePerPix = timePerPix;
    document.timeLen = timeLen;
    document.setTimeLen = setTimeLen;
    // updateTimeLen();
    setTimeout(() => {
      updateTimeLen();
      UpdateBackground();
    }, 2000);
  }, []);
  useEffect(() => {
    let _0_fun = (x, y) => {
      let k = Math.floor(x / 40);

      return 5 ** k;
    };
    setMod(_0_fun(timePerPix, 5));
    // //console.log(mod);
    document.timePerPix = timePerPix;
  }, [timePerPix]);
  const sectomin = (sec) => {
    // return sec > 60 ? Number(sec / 60).toPrecision(3) + "m" : sec + "s";
    return sec > 1 ? sec : Number(sec).toPrecision(2);
  };
  const timeHoverRef = useRef();

  useEffect(() => {
    let k = document.querySelector(".Time_time_big");
    let p = document.querySelector("#time_move");
    let { left } = k.getBoundingClientRect();
    const handleMove = (e) => {

      const { layerX, clientX, offsetX } = e;
      p.style.opacity = 1;

      p.style.left = clientX - left - 60 + "px";

      p.innerHTML = `${Number((clientX - left) / document.per).toPrecision(
        3
      )}s`;
    };
    const hide = (e) => {
      // p.style
      p.style.opacity = 0;

    };
    k.addEventListener("mousemove", handleMove);
    k.addEventListener("mouseout", hide);

    return () => {
      k.removeEventListener("mousemove", handleMove);
      k.removeEventListener("mouseout", hide);
    };
  }, []);


  const handleClickOnTime = async (e) => {
    console.log('time click')
    let to = e.nativeEvent.offsetX / document.per
    if (!document.screenChheda) {
      await document.Player.seek(to, true)
    } else {
      MoveSeekTo({ position: "seek", seek: to, updateVideo: true })
    }
  };

  return (
    <div
      className="Time"
      style={{
        width: `${document._Max + 2 * 500}px`,
      }}
    >
      <div className="Time_blank flex row jbetween ">
        <div className="flex row sceneTime center" >{`Scene ${document.sceneNumberFromIndex[sceneNumber] + 1} (${Number(document._Max / document.per).toFixed(2)} sec)`}
        </div>
        <TimeZoom />
      </div>
      <div className="Time_time" onClick={handleClickOnTime}>
        <div className="Time_time_big">
          <div id="time_move" className="time_seek"></div>
        </div>
        {Array.from({ length: timeLen + Math.ceil(document.per / 80) }).map(
          (e, i) => {
            if (i % 5 === 0)
              return (
                <div
                  className="Time_minisec"
                  style={{
                    width: `${9 + (timePerPix % 40)}px`,
                    height: "20px",
                  }}
                >
                  <div className="Time_minisec_sec">
                    {0 + Number(sectomin(Number((2.5 * i * 10) / (2 * mod))))}s
                  </div>
                </div>
              );
            return (
              <div
                className="Time_sec"
                style={{
                  width: `${9 + (timePerPix % 40)}px`,
                  height: "10px",
                }}
              ></div>
            );
          }
        )}
      </div>
    </div>
  );
});
