/* eslint-disable */
import axios from "axios";

import store from "./store/store";

import "./utils/idConversion";

import { convertOriginToDecimal } from "./utils/cropyThings";
import { customSeek, customVideoSeek } from "./utils/videoPlayThings";
import { API_URL, MINH_CONTROL, MINW_CONTROL, SIZES } from "./utils/config";
import { updateVideosTime } from "./utils/videoPlayThings/updateVideosTimeHelper";
import { display } from "./utils/videoPlayThings/addVideoToCanvasAndStore";
import uuid from "uuid-random";

export const MainWebsiteUrl = "https://autovid.ai";

export const arrFromStore = (state = null) => {
  return JSON.parse((state || store.getState()).scenes.currentScene.arr);
};


export async function onMediaLoaded(elm) {
  return new Promise((resolve, reject) => {
    let _crntElm = elm;
    if (!elm && this) {
      _crntElm = this;
    }
    _crntElm.addEventListener("loadedmetadata", () => {
      console.log('media', _crntElm.id, _crntElm.duration)
      resolve(1);
    }, { once: true }
    );
  });
}

export const MediaMaker = async ({
  type = "audio",
  id = "",
  src = null,
  cb = () => { },
}) => {
  let Music = document.querySelector(".Media");
  let av = document.createElement(type);
  av.customVideoSeek = customVideoSeek;
  av.onMediaLoaded = onMediaLoaded;
  av.id = id;
  av.preload = "auto";
  av.src = await display(src, uuid());
  Music.appendChild(av);
  //await av.onMediaLoaded();
  cb({ ele: av });
};


export const padosiFromIndex = ({ index, state = null }) => {
  let arr = JSON.parse((state || store.getState()).scenes.currentScene.arr);

  let nextIndex = arr.findIndex((e) => e === index);
  let next = arr[nextIndex + 1];
  if (next === undefined) {
    return arr[nextIndex - 1];
  }

  return next;
};

export const MoveSeekTo = ({
  position = "seek",
  cb = () => { },
  seek = null, //sec

  index = Index(),
  updateVideo = false,
  seekMove = "yes",
  updateCS = true,
}) => {
  if (document.editorType === "image") {
    return;
  } else {
    let _ = document?.txx?.setX?.((x) => {
      let to = x;

      switch (position) {
        case "left":
          to = 0;
          break;

        case "same":
          to = x;
          break;

        case "center":
          let scenes = store.getState().scenes[index];
          if (scenes) {
            if (scenes.mode === 2) {
              const { stayTime } = scenes.elements[`background${index}`];
              to = stayTime / 2;
            } else {
              let av = scenes.elements[`avatar${index}`];
              if (av) {
                const { stayTime, enterDelay, enter } = av;
                to = enterDelay + stayTime / 2;
              }
            }

            to *= document.per;
          }

          break;
        case "seek":
          to = seek * document.per;
          break;
      }
      if (updateCS) {
        customSeek(to / document.per, index);
      }
      if (updateVideo) {
        updateVideosTime(to / document.per);
      }
      cb({
        to,
        x,
      });
      return seekMove === "yes" ? to : x;
    });
  }
};

export const Jump = ({ obj, def = false, index = Index(), _mode = null }) => {
  if (def) {
    let mode = null;
    if (_mode !== null) {
      mode = _mode;
    } else {
      mode = !!Number(document._mode);
    }

    if (mode) {
      document._TabName = "background";
      return `/${document.editorType}/${Vid()}/${index}/background`;
    } else {
      document._TabName = "avatar";

      return `/${document.editorType}/${Vid()}/${index}/avatar`;
    }
  }
  let p = `/${document.editorType}/${Vid()}/${index}/settings/${obj._Type}`;
  if (obj._Type === "background") {
    document._TabName = "background";

    // return `${p}/${back[obj._Data.tab]}`;
    return `/${document.editorType}/${Vid()}/${index}/background`;
  } else if (obj._Type === "avatar") {
    document._TabName = "avatar";

    return `/${document.editorType}/${Vid()}/${index}/${obj._Type}`;
  }
  document._TabName = obj._Type;

  return p;
};

export const functionListen = (id, doneTyping, time) => {
  var typingTimer; //timer identifier
  var doneTypingInterval = 500; //time in ms, 5 second for example
  // var $input = $('#myInput');
  let ele = document.querySelector(id);
  //on keyup, start the countdown
  // ele.addEventListener("keyup", function () {
  //   clearTimeout(typingTimer);
  //   typingTimer = setTimeout(doneTyping, doneTypingInterval);
  // });

  //addEventListener keydown, clear the countdown
  ele.addEventListener("keydown", function (e) {
    if (e.key === 'Enter') {
      doneTyping()
    }
    // clearTimeout(typingTimer);
  });
};

export const updateSmallScene = ({ index }) => {
  let W = 90 - 2 - 2,
    H = 50 - 2 - 2;
  let sxx = document.getElementById(`canvas${index}`);
  let cxx = document.cxx[index];
  if (sxx) {
    let ctx = sxx.getContext("2d");
    let cxv = cxx.getElement();
    ctx.drawImage(cxv, 0, 0, cxv.width, cxv.height, 0, 0, W, H);
  }
};

export const nextSceneNumber = (
  inStore = false,
  state = null,
  index = Index()
) => {
  let arr = JSON.parse(
    (state || store.getState()).scenes.currentScene.sceneArr
  );
  let next = arr.length;
  while (1) {
    if (arr.find((e) => next === e)) {
      next += 1;
    } else {
      break;
    }
  }

  return next;
  // updateOffsetArr();
};

export const tryMe = (cb, str) => {
  try {
    cb();
  } catch (err) {
    console.log(str, err);
  }
};

export let realNumber = (state = null) => {
  let arr = JSON.parse(
    (state || store.getState()).scenes.currentScene.sceneArr
  );
  let _next = -1;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== -1) {
      _next = arr[i];
      break;
    }
  }

  return _next;
};

export function sleep(cb, ms, id) {
  return new Promise((resolve) => {
    document.axx[id] = setTimeout(cb, ms);
  });
}

export const updateTimeLen = (
  inStore = false,
  state = null,
  _index = null,
  _time = document._Max
) => {
  let index = _index || Index();
  let time = _time || document._Max;
  let k = Math.ceil(time / ((document.timePerPix % 40) + 10)) + 1;

  let a = document?.setTimeLen?.(k);
  return k;
};

document.updateTimeLen = updateTimeLen;

export const updateOffsetArr = (state = null) => {
  let st = state || store.getState();
  let arr = JSON.parse(st.scenes.currentScene.arr);

  let a = arr.map((i, e) =>
    Number(
      Number(
        st.scenes[i]?.elements[`background${i}`]?.stayTime || 5
      ).toPrecision(2)
    )
  );

  // let c = arr;
  let sum = 0;
  let b = a.map((e) => {
    // eslint-disable-next-line no-unused-vars
    sum += e;
    return sum;
  });

  // b.splice(0, 0, 0);
  // b.splice(b.length - 1, 1, 0);

  let d = {};
  for (let i = 0; i < b.length; i++) {
    d[arr[i]] = b[i];
  }

  return d;
};

export function hexToRgb(hex) {
  if (hex.indexOf("rgba") > -1) {
    var [r, g, b, a] = hex.match(/[\d.]+/g);
    return {
      r,
      g,
      b,
      a,
    };
  }
  if (hex.indexOf("rgb") > -1) {
    var [r, g, b] = hex.match(/[\d.]+/g);
    return {
      r,
      g,
      b,
      a: 1,
    };
  }

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: 1,
    }
    : null;
}

export function rgbToHex(rgb) {
  return (
    "#" +
    [parseInt(rgb.r), parseInt(rgb.g), parseInt(rgb.b)]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
}

export function nextFromIndex(index, state = null) {
  let arr = document.screenChheda
    ? JSON.parse((state || store.getState()).scenes.currentScene.arr)
    : JSON.parse(document.scenes.currentScene.arr);
  let k = arr.findIndex((g) => g === index);
  return arr[k + 1];
}

export function getSceneArray(index) {
  if (document.scenes?.currentScene?.arr) {
    let arr = JSON.parse(document.scenes.currentScene.arr);
    return {
      sceneArr: arr,
      crntIndex: arr.findIndex((g) => g === index),
    };
  } else {
    return {
      sceneArr: [],
      crntIndex: 0,
    };
  }
}

export function prevFromIndex(index, state = null) {
  let arr = document.screenChheda
    ? JSON.parse((state || store.getState()).scenes.currentScene.arr)
    : JSON.parse(document.scenes.currentScene.arr);
  let k = arr.findIndex((g) => g === index);
  return arr[k - 1];
}

export function veryFirstIndex() {
  return JSON.parse(store.getState().scenes.currentScene.arr)[0];
}

const GetTokenNow = () => {
  function removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString =
        sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }

  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("token");
  var newToken = "";
  if (c) {
    window.history.pushState({}, "", url_string.split("?")[0]);
    localStorage.setItem("token", c);
    newToken = c;
  } else newToken = localStorage.getItem("token");
  return newToken;
};

export const token = GetTokenNow();

export const defaultDurationLengthInSec = 6;
export const url = API_URL;
export const headerA = {
  headers: {
    Authorization: `Token ${token}`,
  },
};
export const aurl = (str) => `${url}/api${str}`;
export const initDataFetcher = async () => {
  const avatarImageList = (
    await axios.get(`${url}/api/avatars/image/`, headerA)
  ).data.results;
  const avatarVoiceList = [];
  // const avatarVoiceList = (
  //   await axios.get(`${url}/api/avatars/sound/`, headerA)
  // ).data.results;
  // const avatarVideoWithVoice = (
  //   await axios.get(`${url}/api/avatars/avatar_sound/`, headerA)
  // ).data.results;
  const avatarLanguages = (
    await axios.get(`${url}/api/avatars/voices/`, headerA)
  ).data.result;

  // log(avatarImageList)
  return {
    avatarImageList,
    // avatarVideoWithVoice,
    avatarVoiceList,
    avatarLanguages,
  };
};

export function findNewPoint(angle, distance) {
  var result = {};
  result.x = Math.round(Math.cos((angle * Math.PI) / 180) * distance);
  result.y = Math.round(Math.sin((angle * Math.PI) / 180) * distance);
  return result;
}

export function getAngleFromXY(x, y) {
  let _t = (Math.atan(y / x) * 180) / Math.PI;
  if (x < 0) {
    _t += 180;
  } else if (y < 0) {
    _t += 360;
  }
  return _t;
}

export const fetchAndSave = ({ type }) => {
  const {
    [type]: { next },
  } = store.getState().media;
  if (next) {
    store.dispatch({
      type: "LLOAD",
    });
    fetcher({
      method: "GET",
      url: next,
      res: (res) => {
        const c = res.data;
        store.dispatch({
          type: "MEDIA_WITH_NEXT",
          data: {
            next: c.next,
            list: c.results || c.result,
            res: !!c.result,
            extra: {},
            type,
          },
        });
        store.dispatch({
          type: "MEDIA_WITH_NEXT",
          data: {
            next: null,
            list: c.inPlace || [],
            res: !!c.result,

            type: "place",
          },
        });
        store.dispatch({
          type: "LLOAD",
        });
      },
      err: (err) => {
        store.dispatch({
          type: "LLOAD",
        });
      },
    });
  }
};

export const initElementFetch = async () => {

  //OK
  const combineData = (await axios.get(`${url}/api/colors/combine/`, headerA)).data;

  return {
    colors: combineData.colors,
    gradient: combineData.gradients,
    fonts: combineData.fonts,
    mergeTag: combineData.mergeTags
  };
};

const NON_REDIRECT_TO_LOGIN_PATH = [
  "login",
  "forgotpassword",
  "reset-password",
  "signup-verify",
  "register",
  "new-template",
];

export const fetcher = ({
  method,
  url = "",
  data = {},
  res = () => { },
  err = () => { },
  header = null,
  status = () => { },
}) => {
  var header = header ? header : { ...headerA };

  if (!url) {
    return;
  }
  if (!method) {
    method = "GET";
  }
  axios({
    ...header,
    url,
    method,

    data,
    // baseURL: `${url}/api`,
  })
    .then(res)
    .catch((e) => {
      let RedirectUrl = window.location.pathname;
      err(e);
      if (e.response) {
        status(e.response.status);
        let _crntPathName = window.location.pathname.toLowerCase();
        if (
          !NON_REDIRECT_TO_LOGIN_PATH.some((_routeKey) =>
            _crntPathName.includes(_routeKey)
          )
        ) {
          if (e.response.status === 401) {
            window.location.href =
              RedirectUrl === "/"
                ? "/login/"
                : `/login/?redirect=${RedirectUrl}`;
          }
        }
      }
    });
};

export function setCaretPosition(elem, caretPos) {
  // var elem = document.getElementById(elemId);

  if (elem != null) {
    if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.move("character", caretPos);
      range.select();
    } else {
      if (elem.selectionStart) {
        elem.focus();
        elem.setSelectionRange(caretPos, caretPos);
      } else elem.focus();
    }
  }
}
export function getCaretPosition(editableDiv) {
  var caretPos = 0,
    sel,
    range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0);
      if (range.commonAncestorContainer.parentNode === editableDiv) {
        caretPos = range.endOffset;
      }
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    if (range.parentElement() === editableDiv) {
      var tempEl = document.createElement("span");
      editableDiv.insertBefore(tempEl, editableDiv.firstChild);
      var tempRange = range.duplicate();
      tempRange.moveToElementText(tempEl);
      tempRange.setEndPoint("EndToEnd", range);
      caretPos = tempRange.text.length;
    }
  }
  return caretPos;
}

export const fetcherAsync = async ({
  method = "GET",
  url = "",
  data = {},
  res = () => { },
  err = () => { },
}) => {
  return await axios({
    ...headerA,
    url,
    method,
    data,
    // baseURL: `${url}/api`,
  });
};

export function getById(id, num, cxx = null) {
  if (cxx) {
    return cxx._objects.find((e) => e.id === id);
  }
  return document.oxx[id];
}
document.getById = getById;

export const getCordinate = (prsCategory, prsPosition, avatarConfig) => {
  const avatarWidth = 1080;
  const CANVAS_WIDTH = 1920;
  if (prsCategory === 0) {
    //full
    if (prsPosition === 0) {
      return avatarConfig.FM;
    } else if (prsPosition === 1) {
      const ww = avatarWidth * avatarConfig.FM.scale;
      return {
        ax: (CANVAS_WIDTH - ww) / 2,
        ay: avatarConfig.FM.ay,
        scale: avatarConfig.FM.scale,
      };
    } else if (prsPosition === 2) {
      const ww = avatarWidth * avatarConfig.FM.scale;
      return {
        ax: CANVAS_WIDTH - ww - avatarConfig.FM.ax,
        ay: avatarConfig.FM.ay,
        scale: avatarConfig.FM.scale,
      };
    } else if (prsPosition === 3) {
      return avatarConfig.FB;
    } else if (prsPosition === 4) {
      const ww = avatarWidth * avatarConfig.FB.scale;
      return {
        ax: (CANVAS_WIDTH - ww) / 2,
        ay: avatarConfig.FB.ay,
        scale: avatarConfig.FB.scale,
      };
    } else if (prsPosition === 5) {
      const ww = avatarWidth * avatarConfig.FB.scale;
      return {
        ax: CANVAS_WIDTH - ww - avatarConfig.FB.ax,
        ay: avatarConfig.FB.ay,
        scale: avatarConfig.FB.scale,
      };
    } else {
      return avatarConfig.FM;
    }
  } else {
    // square and circle
    if (prsPosition === 0) {
      return avatarConfig.SM;
    } else if (prsPosition === 1) {
      const movedBy =
        (CANVAS_WIDTH - avatarConfig.SM.size) / 2 - avatarConfig.SM.x;
      return {
        ax: avatarConfig.SM.ax + movedBy,
        ay: avatarConfig.SM.ay,
        x: avatarConfig.SM.x + movedBy,
        y: avatarConfig.SM.y,
        scale: avatarConfig.SM.scale,
        size: avatarConfig.SM.size,
        anX: avatarConfig.SM.anX,
        anY: avatarConfig.SM.anY,
        anSc: avatarConfig.SM.anSc,
      };
    } else if (prsPosition === 2) {
      const movedBy =
        CANVAS_WIDTH - 2 * avatarConfig.SM.x - avatarConfig.SM.size;
      return {
        ax: avatarConfig.SM.ax + movedBy,
        ay: avatarConfig.SM.ay,
        x: avatarConfig.SM.x + movedBy,
        y: avatarConfig.SM.y,
        scale: avatarConfig.SM.scale,
        size: avatarConfig.SM.size,
        anX: avatarConfig.SM.anX,
        anY: avatarConfig.SM.anY,
        anSc: avatarConfig.SM.anSc,
      };
    } else if (prsPosition === 3) {
      return avatarConfig.SB;
    } else if (prsPosition === 4) {
      const movedBy =
        (CANVAS_WIDTH - avatarConfig.SB.size) / 2 - avatarConfig.SB.x;
      return {
        ax: avatarConfig.SB.ax + movedBy,
        ay: avatarConfig.SB.ay,
        x: avatarConfig.SB.x + movedBy,
        y: avatarConfig.SB.y,
        scale: avatarConfig.SB.scale,
        size: avatarConfig.SB.size,
        anX: avatarConfig.SB.anX,
        anY: avatarConfig.SB.anY,
        anSc: avatarConfig.SB.anSc,
      };
    } else if (prsPosition === 5) {
      const movedBy =
        CANVAS_WIDTH - 2 * avatarConfig.SB.x - avatarConfig.SB.size;
      return {
        ax: avatarConfig.SB.ax + movedBy,
        ay: avatarConfig.SB.ay,
        x: avatarConfig.SB.x + movedBy,
        y: avatarConfig.SB.y,
        scale: avatarConfig.SB.scale,
        size: avatarConfig.SB.size,
        anX: avatarConfig.SB.anX,
        anY: avatarConfig.SB.anY,
        anSc: avatarConfig.SB.anSc,
      };
    } else {
      return avatarConfig.SM;
    }
  }
};

document.fxx = {
  change: {},
  change1: {},
};
export function initForFirstTimeForTheObjectInCanvas({ id }) {
  let _;

  let _obj = getById(id);
  if (_obj)
    _obj.on("moving", () => {
      _ = document.fxx.change[id]?.(_obj);
    });
  _obj.on("scaling", () => {
    // eslint-disable-next-line no-unused-vars
    _ = document.fxx.change[id]?.(_obj);
  });
}
export function fn(deci) {
  return Math.round(deci);
}

export const initAvatar = (obj) => {
  obj._Position = 9;
};

export const controlsByObject = ({ obj, type = "normal", show = true }) => {
  let corner = ["tl", "tr", "br", "bl"];
  let center = ["ml", "mr", "mt", "mb"];
  let rotate = ["mtr"];
  let label = ["labelControl"];
  let final = [];
  let t = obj._Type;
  let beforeC = {};
  let full = [...label, ...rotate, ...corner, ...rotate, ...center].reduce(
    (a, v) => ({ ...a, [v]: !show }),
    beforeC
  );

  switch (type) {
    case "normal":
      final.push(...label, ...rotate);
      let sx = obj.scaleX;
      let sy = obj.scaleY;
      let w = sx * obj.width;
      let h = sy * obj.height;

      let minH = MINH_CONTROL;
      let minW = MINW_CONTROL;

      if (t === "text") {
        final.push(...center);
      } else if (t === "avatar") {
        beforeC = { rotate: false };
        final = [...corner, ...label];
      } else {
        if (w > minW && h > minH) {
          final.push(...center, ...corner);
        } else {
          beforeC = full;
          final = ["br", ...label, ...rotate];
          if (w <= minW && h <= minH) {
          } else {
            final.push(...center);
          }
        }
      }
      break;
    case "title":
      // controlsByObject({ obj, type: "normal", show: false });
      beforeC = full;
      final = label;

      break;
  }
  let C = final.reduce((a, v) => ({ ...a, [v]: show }), beforeC);

  obj.setControlsVisibility(C);
};

export const removeBlur = () => {
  // return new Promise((r, rj) => {
  //   let e = document.getElementById("XXXYYYZZZ");
  //   e.focus();
  //   e.setSelectionRange(0, e.target.value.length);
  //   r();
  // });
};
export const selectAll = async (e) => {
  // await removeBlur();
  // e.target.focus();
  e.target.select();
  // if (e.target) e.target.setSelectionRange(0, e.target.value.length);
};
export const getStyles = (textObj) => {
  let _cursorPositon = textObj?.selectionStart;
  if (!_cursorPositon) {
    _cursorPositon = 0;
  } else if (_cursorPositon === textObj.text.length) {
    _cursorPositon -= 1;
  }
  let _currentStyle = textObj?.getStyleAtPosition?.(_cursorPositon);
  // get Alignment
  let _textAlign = textObj.textAlign;
  let _fontWeight = _currentStyle?.fontWeight;
  if (!_fontWeight) {
    _fontWeight = textObj.fontWeight;
  }
  let _fontStyle = _currentStyle?.fontStyle;
  if (!_fontStyle) {
    _fontStyle = textObj.fontStyle;
  }
  let _underline = _currentStyle?.underline;
  if (!_underline) {
    _underline = textObj.underline;
  }
  let _linethrough = _currentStyle?.linethrough;
  if (!_linethrough) {
    _linethrough = textObj.linethrough;
  }
  let _fontSize = _currentStyle?.fontSize;
  if (!_fontSize) {
    _fontSize = textObj.fontSize;
  }
  let _fill = _currentStyle?.fill;
  if (!_fill) {
    _fill = textObj.fill;
  }
  let _stroke = _currentStyle?.stroke || "#ffffff";
  if (!_stroke) {
    _stroke = textObj.stroke || "#ffffff";
  }
  let _textBackgroundColor = _currentStyle?.textBackgroundColor;
  if (!_textBackgroundColor) {
    _textBackgroundColor = textObj.textBackgroundColor;
  }
  let _fontFamily = _currentStyle?.fontFamily;
  if (!_fontFamily) {
    _fontFamily = textObj.fontFamily;
  }
  return {
    textAlign: _textAlign,
    fontWeight: _fontWeight,
    fontStyle: _fontStyle,
    underline: _underline,
    linethrough: _linethrough,
    fontSize: _fontSize,
    fill: _fill,
    stroke: _stroke,
    textBackgroundColor: _textBackgroundColor,
    fontFamily: _fontFamily,
    lineHeight: textObj.lineHeight,
    charSpacing: textObj.charSpacing,
  };
};

export const Index = () => {
  return Number(document.location.pathname.split("/")[3] || 0);
};
export const Vid = () => {
  return Number(document.location.pathname.split("/")[2]) || 0;
  // return 0;
};
document.Vid = Vid;

document.Index = Index;

export function getSVGObjColor(obj) {
  let colorIndex = {};
  if (obj._objects) {
    obj._objects.forEach((_sobj, indx) => {
      let _f = _sobj.fill;
      let _s = _sobj.stroke;
      if (_f) {
        if (colorIndex[_f]?.[0]) {
          colorIndex[_f][0].push(indx);
        } else {
          colorIndex[_f] = {
            0: [indx],
          };
        }
      }
      if (_s) {
        if (colorIndex[_s]?.[1]) {
          colorIndex[_s][1].push(indx);
        } else {
          colorIndex[_s] = {
            1: [indx],
          };
        }
      }
    });
  } else {
    if (obj.fill) {
      colorIndex[obj.fill] = {
        2: 1,
      };
    }
    if (obj.stroke) {
      colorIndex[obj.stroke] = {
        3: 1,
      };
    }
  }
  return [Object.keys(colorIndex), Object.values(colorIndex)];
}

export function setSVGObjColor(index, newColor) {
  if (!this._DefaultColor) {
    this._DefaultColor = getSVGObjColor(this);
  }
  let _mainD = this._DefaultColor[1][index];
  if (_mainD?.[2]) {
    this.set({
      fill: newColor,
    });
  }
  if (_mainD?.[3]) {
    this.set({
      stroke: newColor,
    });
  }
  if (_mainD?.[0]) {
    _mainD[0].forEach((_objIndex) => {
      this._objects[_objIndex].set({
        fill: newColor,
      });
    });
  }
  if (_mainD?.[1]) {
    _mainD[1].forEach((_objIndex) => {
      this._objects[_objIndex].set({
        stroke: newColor,
      });
    });
  }
  this.canvas.renderAll();
}

export function setShapeSVGObjColor(obj, color) {
  if (obj._objects && obj._objects?.length > 0) {
    obj._DefaultColor = getSVGObjColor(obj);
    obj._CurrentColor = obj._DefaultColor[0];
    obj.setColor = setSVGObjColor;
    let _fill = obj.fill;
    if (color) {
      _fill = color;
      obj.fill = color;
    }
    if (!_fill) {
      _fill = obj._CurrentColor[0];
    }
    obj._CurrentColor.forEach((e, i) => {
      obj.setColor(i, _fill);
    });
  } else {
    if (color) {
      obj.set({
        fill: color,
      });
    }
  }
}

export const fitCenterToCXX = ({
  obj,
  cxx = document.cxx[Index()],
  percent = 50,
  posx = 0.5, // -1, 1
  posy = 0.5,
}) => {
  if (obj) {
    let ratio = SIZES[document?._size || 0].width / obj.width;
    let _minSize = Math.min(
      SIZES[document?._size || 0].width,
      SIZES[document?._size || 0].height
    );
    let _objSize = parseInt((_minSize * percent) / 100);
    const _canvasCenter = {
      x: parseInt(SIZES[document?._size || 0].width / 2),
      y: parseInt(SIZES[document?._size || 0].height / 2),
    };
    const _objectCoord = {
      left: parseInt(_canvasCenter.x - _objSize / 2) * 2 * posx,
      top: parseInt(_canvasCenter.y - _objSize / 2) * 2 * posy,
      height: _objSize,
      width: _objSize,
    };
    fitImageToRect(obj, _objectCoord, false);
  }
};

/**
 *
 * @param {Object} eventData
 * @param {Object} transform
 * @param {Object} backgroundC canvas which object what to cropped
 */

export function fitImageToRect(
  fabImage,
  rectData = {
    left: 0,
    top: 0,
    height: 100,
    width: 100,
    angle: 0,
    originX: 0,
    originY: 0,
  },
  isMax = true,
  isRender = true
) {

  let _imageScale,
    _newLeft = rectData.left,
    _newTop = rectData.top,
    _angle = rectData.angle | 0;
  const videoScale = rectData.height / rectData.width;
  if (isMax) {
    if (videoScale < fabImage.height / fabImage.width) {
      _imageScale = rectData.width / fabImage.width;
      let _remNewHeight = (fabImage.height * _imageScale - rectData.height) / 2;
      _newTop -= _remNewHeight;
    } else {
      _imageScale = rectData.height / fabImage.height;
      let _remNewWidth = (fabImage.width * _imageScale - rectData.width) / 2;
      _newLeft -= _remNewWidth;
    }
  } else {
    if (videoScale < fabImage.height / fabImage.width) {
      _imageScale = rectData.height / fabImage.height;
      let _remNewWidth = (fabImage.width * _imageScale - rectData.width) / 2;
      _newLeft -= _remNewWidth;
    } else {
      _imageScale = rectData.width / fabImage.width;
      let _remNewHeight = (fabImage.height * _imageScale - rectData.height) / 2;
      _newTop -= _remNewHeight;
    }
  }
  fabImage.scale(_imageScale).set({
    left:
      _newLeft +
      convertOriginToDecimal(fabImage.originX) *
      fabImage.width *
      fabImage.scaleX,
    top:
      _newTop +
      convertOriginToDecimal(fabImage.originY) *
      fabImage.height *
      fabImage.scaleY,
    angle: _angle,
  });
  fabImage.setCoords();
  if (isRender) {
    fabImage.fire("moving");
    try {
      fabImage.canvas.renderAll();
    } catch (err) {
      document.ccx.renderAll();
    }
  }
}

/**
 *
 * @param {*} img
 * @param {*} shape
 * @param {*} backgroundC from where the image is
 * @param {*} canCover
 */

export const HideAndShowHandler = (id, dis) => {
  try {
    document.getElementById(id).style.display = dis;
  } catch (err) {
    // console.log(err);
  }
};

export const EllipseMe = (text, character) => {
  return text.substring(0, character) + (text.length > character ? "..." : "");
};

export function getNumber(_val, defaultV = 0) {
  const _nm = Number(_val);
  if (isNaN(_nm)) {
    return defaultV
  }
  return _nm
}