/* eslint-disable */
import React, { useCallback } from "react";
import { fabric } from "fabric";
import Vr from "../../../Chemistry/Atom/Vr";
import "./_Text.scss";
import { fitCenterToCXX, Index, token, Vid } from "../../../../utils";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import uuid from "uuid-random";
import { InitAddObjectEvents } from "../../../../utils/InitAddObjectEvents";
import { DEFAULT_FONT } from "../../../../utils/config";

export default connect((state) => ({

}))(function Text({ }) {
  const history = useHistory();
  const handleAddText = ({ text, size, weight, width }) => {
    return (e) => {
      const { cxx } = document;

      let _id = uuid();
      const obj = new fabric.EffectTextBox(text, {
        id: _id,
        left: 0,
        top: 0,
        width: width,
        fontSize: size,
        fill: "#000000",
        _DefaultText: text,
        fontSize: size,
        fontWeight: weight,
        fontFamily: DEFAULT_FONT,
      });


      cxx[Index()].add(obj);

      fitCenterToCXX({ obj, posx: 0.2, percent: 100 });
      obj.set({ scaleX: 1, scaleY: 1 })

      obj.canvas.renderAll();
      InitAddObjectEvents({
        obj,
        _id,
        type: "text",
        name: document.duplicateName.getName({ type: "text", name: "Text" }),
        returnToUrl: false,
        onAddActive: false,
        // index: Index()
      });

    };
  };

  return (
    <div className="Text">
      <TextRow
        size="24"
        onClick={handleAddText({
          text: "Add a heading",
          size: 90,
          weight: "bold",
          index: Index(),
          width: 750,
        })}
      >
        Add a heading
      </TextRow>
      <TextRow
        size="14"
        onClick={handleAddText({
          text: "Add a subheading",
          size: 52,
          weight: "normal",
          index: Index(),
          width: 600,
        })}
      >
        Add a subheading
      </TextRow>
      <TextRow
        size="12"
        onClick={handleAddText({
          text: "Add some body text",
          size: 34,
          weight: "normal",
          index: Index(),
          width: 450,
        })}
      >
        Add some body text
      </TextRow>
      <Vr />
    </div>
  );
});

const TextRow = ({ size, text, children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="TextRow"
      style={{ fontSize: `${size}px` }}
    >
      {children || text}
    </div>
  );
};
