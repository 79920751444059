import React from "react";
import { useEffect } from "react";
import { gsap } from "gsap";
import { useRef } from "react";
import { connect } from "react-redux";

export default connect((state) => ({ tabName: state.tabName }))(
  function SlideContainer({ children, tabName }) {
    const ref = useRef();
    useEffect(() => {
      // gsap.fromTo(
      //   ref.current,
      //   { opacity: "0.5", ease: "expo.out", x: 100 },
      //   { opacity: "1", x: 0, duration: 0.3 }
      // );
    }, [tabName]);

    return (
      <div ref={ref} className="SlideContainer">
        {children}
      </div>
    );
  }
);
