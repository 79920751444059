import store from "../../../../../store/store";
import { getById, Index, Jump } from "../../../../../utils";

export const handleCancelOnAvatarFrame = (_data) => {
  const _index = Index();
  let _goB = true;
  if (_data?._goB !== undefined) {
    _goB = _data._goB;
  }
  document.cccx.current.style.display = "none";
  document.cccxa.current.style.display = "none";
  let _crntAvatar = getById(`avatar${_index}`, _index);
  if (_crntAvatar) {
    if (_crntAvatar._Hidden) {
      _crntAvatar.visible = false;
    } else {
      _crntAvatar.visible = true;
    }
    document.cxx[_index].renderAll();
  }
  if (_goB) {
    if (document.hist.location.pathname.split('frame').length >= 2) {
      document.hist.push(Jump({ obj: {}, def: true, index: _index }))
      //document.hist.goBack();
    }
  }
};
