import { memo } from "react";
import "./_Atom.scss";

function TabTop({ arrTab = [], nowTab, handleTab = () => {} }) {
  ////console.log(`TabTop : ${arrTab[nowTab]} Renders`);

  return (
    <div className="_TabTop">
      <div className="TabTop">
        {arrTab.map((e, i) => {
          return (
            <div
              key={`${e.name + i}`}
              onClick={() => {
                handleTab(i);
              }}
              className={`TabTop-child ${
                i === nowTab ? "TabTop-child-active" : ""
              }`}
            >
              {e.name}{" "}
              {e.icon ||
                (e.src && (
                  <img src={i === nowTab ? e.asrc : e.src} alt={"abc"} />
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default memo(TabTop);
