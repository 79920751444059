/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";


// import { url, token } from "../../config/config";

import { ReactComponent as Dashboard } from "./Dashboard.svg";
import Popup from 'reactjs-popup';


// import axios from "axios";
import "./index.css"
import "../index.css"

import "../_john.scss"
import lady_on_desk from "./ico/lady_on_desk.svg"
import fb_ico from "./ico/fb-icon.svg"
import linkedin_ico from "./ico/linkedin-icon.svg"
import twitter_ico from "./ico/twitter-icon.svg"
import check from "./ico/check.svg"
import TickOption from "./tickoption"
import close from "./ico/close.svg"
import { fetcher, url } from "../../../utils";

const _data = {
    fb: "https://facebook.com",
    tw: "https://www.twitter.com",
    ln: "https://linkedin.com"
}


function Contactus(props) {
    const [Obj, setObj] = useState({
        problemCategory: null,
        message: ""
    })



    const [Index, setIndex] = useState(0);
    const [isPopUpOpened, setPopUpOpened] = useState(false);

    // const { isAuthenticated, user } = props.auth;

    React.useEffect(() => {
        document
            .getElementById("bootstrap")
            .setAttribute("href", "/bootstrap.min.css");
        return () => {
            document.getElementById("bootstrap").removeAttribute("href");
        };
    }, []);

    const HandleClose = () => {

        setObj({
            problemCategory: null,
            message: ""
        })

        document.getElementsByName("issue-text")[0].value = ""
        if (isPopUpOpened) {
            setPopUpOpened(false)
        }
    }

    const HandleSubmit = async () => {

        fetcher({
            method: "POST",
            data: Obj,
            url: `${url}/api/accounts/contactus/`,
            res: (res) => {
                setPopUpOpened(true)

            }
        })

    }
    var arr = {

        0: [<Dashboard />, <div className="breadcrumb-item-lib">Contact Us</div>],

    }


    console.log('isPopUpOpened', isPopUpOpened)

    return (
        <div className="video-list-wrapper">
            <ul className="breadcrumb-custom">

                {/* {arr[Index].map((data,key)=>(<li className={`breadcrumbs-items ${key===arr[Index].length-1?"active-breadcrumb":""}`} onClick={()=>{if(key===1)
{

setIndex(0)
}}}>{data} {key===arr[Index].length-1?"":<BreadcrumbArrow/>} </li>))} */}

            </ul>
            {
                false ? <div>You are not Authenticated</div> :

                    <div className="video-list-main-container">


                        <div className="contact-box-wrapper">
                            <div className="contactus-container _cnt">

                                <div className="profile-imp-details">
                                    <div className="header-in-profile">
                                        <label htmlFor="" className="name-in-profile">
                                            Contact us
                                        </label>


                                    </div>


                                    <div className="_title">
                                        <label htmlFor="" className="_innerTitle">
                                            We are always here for you. Submit the issue you are facing and we will reply within 24 hours.
                                        </label>
                                    </div>

                                    <div className="row _contact">
                                        <div className="col-lg _form d-flex flex-column">

                                            <TickOption old={Obj.problemCategory} onChangee={(d) => {
                                                setObj({
                                                    ...Obj,
                                                    problemCategory: d.command
                                                })
                                            }} />

                                            <textarea placeholder="Describe your issue." className="_textarea" name="issue-text" id="" cols="30" rows="10" defaultValue={Obj.message} onBlur={(e) => {
                                                setObj({
                                                    ...Obj,
                                                    message: e.target.value
                                                })
                                            }}></textarea>


                                        </div>
                                        <div className="_line"></div>
                                        <div className="col-lg _banner d-flex justify-content-center">
                                            <div className="_wrap d-flex flex-column justify-content-center">
                                                <img src={lady_on_desk} alt="" className="_lady_icon" />
                                                <div className="_sm_title">You can also contact us at:</div>
                                                <div className="_mail_num">
                                                    <a className="_mail_num" href="mailto:care@autogenerate.ai">care@autogenerate.ai</a>

                                                </div>

                                                <div className="d-flex justify-content-center _social">
                                                    <div className="d-flex justify-content-around  _wrap">
                                                        <a href={_data.fb} target="_blank"><img src={fb_ico} alt="" className="_fb" /></a>
                                                        <a href={_data.ln} target="_blank"><img src={linkedin_ico} alt="" className="_ln" /></a>
                                                        <a href={_data.tw} target="_blank"><img src={twitter_ico} alt="" className="_tw" /></a>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>


                                    <div className="_send_btn" onClick={e => {
                                        HandleSubmit()
                                    }}>
                                        Submit
                                    </div>


                                    <Popup open={isPopUpOpened} position="right center" onClose={HandleClose}>
                                        <div className="_popup d-flex flex-column justify-content-center align-items-center">

                                            <img onClick={() => HandleClose()} src={close} alt="" className="_closex align-self-end" />
                                            <img src={check} alt="" className="_check" />
                                            <div className="_title">Your message has been sent!</div>
                                            <div className="_sm_title">We’ll get back to you within 24 hours. Thank you for your patience.</div>
                                            <div onClick={() => HandleClose()} className="_close align-self-end ">Close</div>
                                        </div>

                                    </Popup>




                                </div>

                            </div>

                        </div>
                    </div>

            }
        </div>
    );
}


const mapStateToProps = (state) => {
    console.log(state)
    return ({

        auth: state.auth,
    })
}
export default (Contactus);
