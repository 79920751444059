import React from "react";
import { connect } from "react-redux";
import "./_Atom.scss";
export default connect((state) => ({ l: state.active.leftLoading }))(
  function LoadingWrapping({ l }) {
    return (
      <div className="LoadingWrapping" style={{ display: l ? "flex" : "none" }}>
        <img src={`${document.PUBLIC_URL}/icons/loading.gif`} alt="ac" />
      </div>
    );
  }
);
