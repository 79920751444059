import { memo } from "react";

/**
 * 
 * @param {{title:string}} props 
 * @returns 
 */
function TitleRow({ title, cursor = "default", onClick = () => { } }) {
  return (
    <div style={{ cursor: cursor }} className="TitleRow" onClick={onClick}>
      {title}
    </div>
  );
}

export default memo(TitleRow);
