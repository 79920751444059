import uuid from "uuid-random";
import { Index, Vid } from "../../utils";
const groupSelectionEvents = (cxx) => {
  const handleSelector = () => {
    // return;
    // document.Disable = true;
    let id = uuid();

    let obj = cxx.getActiveObject();

    document.oxx[id] = obj;
    // console.log(obj._Type);
    if (!obj._Type && obj.type === "activeSelection") {
      obj.set(document.selectionSettings);
      let _;
      obj.on("moving", () => {
        _ = document.fxx.change[id]?.(obj);
        _ = document.fxx.change1[id]?.(obj);
      });
      obj.on("scaling", () => {
        // eslint-disable-next-line no-unused-vars
        _ = document.fxx.change[id]?.(obj);
      });
      obj.on("rotating", () => {
        // eslint-disable-next-line no-unused-vars
        _ = document.fxx.change[id]?.(obj);
      });
      obj.on("resizing", () => {
        // eslint-disable-next-line no-unused-vars
        _ = document.fxx.change[id]?.(obj);
      });
      obj.set({ id: id, _Type: "group" });

      cxx.renderAll();
      document.hist.push(
        `/${document.editorType}/${Vid()}/${Index()}/settings/group`,
        { id }
      );
    }
  };

  cxx.on("before:selection:cleared", () => {
    document.Disable = false;
  });
  cxx.on("selection:created", handleSelector);
  cxx.on("selection:updated", handleSelector);
};

export default groupSelectionEvents;
