import React from "react";

export default function LineText({ title, value }) {
  return (
    <div className="LineText">
      <div className="LineText_title">{title}</div>
      <div className="LineText_value">{value}</div>
    </div>
  );
}
