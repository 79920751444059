import React from "react";
import Popup from "../Popup";
import "./_EEE.scss";
import Modal from "reactjs-popup";
import Button from "../Button";
// import MainPopUp from "../../ImagePersonalised/MainPopup";
import { PerImage } from "./LinkPopup";
import InfiniteWrapper from "../../../components/Chemistry/Atom/Infinite";
import mystore from "../../appStore";
import { connect } from "react-redux";
import SelectOption from "../../../components/Chemistry/Atom/SelectOption";
import IFrame from "./IFrame/IFrame";
import { useState } from "react";
import { fetcher, url, token } from "../../../utils";
import { salespageURL } from "../../../utils/config";
import TemplatePreviewByImage from "./TemplatePreviewByImage";

export default connect((state) => ({
  list: state.media.template.list
  ,
  my: state.mydetail,

}))(
  function TemplatePopup({ trigger, onOpenHide, onCloseShow, list, my }) {
    const [isPreview, setPerview] = useState({})
    const handleSelect = (k) => {
      return (a) => {
        fetcher({
          method: "GET",
          url: `${url}/api/salespage/${k.id}/copy/?video_id=${my.id}&appType=1`,

          res: (res) => {
            let { id } = res.data.result;
            const _ptab = btoa(JSON.stringify({ videoId: my.id, tab: !document.getElementById("_linkPopupBtn") }))
            window.open(`${salespageURL}/${id}/?ptab=${_ptab}`);
          },
          err: (err) => {
            if (err?.response?.status === 401) {
              window.location.href = "/login"
            } else {
              console.error("CANT DUPLICATE", err);
            }
          },
        });
      };
    };


    // console.log(list);
    return (
      <Modal modal trigger={trigger} onOpen={onOpenHide} onClose={onCloseShow} closeOnDocumentClick={false}>
        {(close) => (
          <Popup
            name="Choose Template Page"
            close={close}

            //   haveButton={false}
            footer={
              <div className="SharingPopup_footer flex row1 ">
                <Button className="grey" value="Cancel" onClick={close}></Button>
                {/* <Button value="Edit" close={close}></Button> */}
              </div>
            }
          >

            <div className="SharingPopup flex TemplatePopup" id="SharingPopup">
              <Modal
                open={isPreview.open}
                onClose={() => {
                  setPerview({ open: false })

                }}
                closeOnDocumentClick={false}
              >
                {(close) => (
                  <IFrame
                    type="salespage"
                    isSaved={true}
                    id={isPreview.id}
                    close={close}
                  />
                )}
              </Modal>
              <InfiniteWrapper
                max={10}
                type={"template"}
                target={"SharingPopup"}
                mystore={mystore}
              >
                {list.map((e) => {
                  return (
                    <div key={e.id} className="SharingPopup_card flex column">
                      <PerImage
                        name={e.name}
                        have={e.isPersonalized}
                        src={e.previewImage}
                        className={"template_img"}
                        haveOption={
                          <div className=" fullTemplateOption">
                            <TemplatePreviewByImage mobile={e.mobileViewPreview} desktop={e.desktopViewPreview} id={e.id} ><Button
                              className="grey"
                              value="Preview"
                            ></Button></TemplatePreviewByImage>

                            <Button value="Edit" onClick={handleSelect(e)}></Button>
                          </div>
                        }

                      />
                      <div className="_name">{e.name}</div>
                    </div>
                  );
                })}
              </InfiniteWrapper>
            </div>
          </Popup>
        )}
      </Modal>
    );
  }
);

