/* eslint-disable */
import produce from "immer";
import addAvatarToScreenFirstTime from "../components/LeftPanel/components/Avatar/functions/addAvatar";
import store from "../store/store";
import {
  Index,
  setSVGObjColor,
  setShapeSVGObjColor,
  getById,
  Jump,
} from "../utils";

import { defaultID } from "./config";
import { InitAddObjectEvents } from "./InitAddObjectEvents";

import { addEventsInAudio, customVideoSeek } from "./videoPlayThings";
import { display } from "./videoPlayThings/addVideoToCanvasAndStore";
import { checkBufferVideoEvent } from "./videoPlayThings/checkBufferVideoEvent";
import { LoadSAnimationVideoToDOM } from "./videoPlayThings/TransistionPlayThings";
import { setAudioAvater } from "./videoPlayThings/updateVideosTimeHelper";

const LinkChanger = (str, index) => {
  if (!str) {
    return "/settings";
  }
  let p = str.split("/");
  p[3] = index;
  return p.join("/");
};

export const giveAllEventAndTimeLine = async ({
  index,
  duplicate = false,
  json = {},
}) => {
  const cxx = document.cxx[index];
  const objs = cxx.getObjects();
  let mode = json.mode;
  //document.hist.push(Jump({ obj: {}, def: true }));

  objs.forEach((obj) => {
    if (json.elements[obj.id]) {
      obj._Animation = json?.elements?.[obj.id]?.animation;
      let ani = json?.elements?.[obj.id]?.animation;
      if (ani) {
        if (ani.enter) {
        } else {
          obj._Animation = {
            ...obj._Animation,
            enter: { id: defaultID.animation.enter },
          };
          store.dispatch({
            type: "EDIT_ELEMENT",
            data: {
              id: obj.id,
              data: {
                ...json?.elements?.[obj.id],
                enter: 0,
              },
            },
          });
        }
        if (ani.exit) {
        } else {
          store.dispatch({
            type: "EDIT_ELEMENT",
            data: {
              id: obj.id,
              index,
              data: {
                ...json?.elements?.[obj.id],

                exit: 0,
              },
            },
          });
          obj._Animation = {
            ...obj._Animation,
            exit: { id: defaultID.animation.exit },
          };
        }
        if (ani.place) {
        } else {
          obj._Animation = {
            ...obj._Animation,
            place: { id: defaultID.animation.place },
          };
        }
      }
    }
    // console.log(obj._Animation, index);

    if (obj._Type === "elements") {
      // console.log(obj._Type, obj.id);
      if (obj._Data.type === "emoji") {
      } else if (obj._Data.type === "shapes" || obj._Data.type === "icons") {
        setShapeSVGObjColor(obj);
      } else {
        obj.setColor = setSVGObjColor;
        obj._CurrentColor.forEach((e, i) => {
          obj.setColor(i, e);
        });
      }
    }
    if (document.editorType === "video") {
      if (obj._Type === "video" || obj._Type === "music") {
        let ele = document.getElementById(obj.id);
        if (ele) {
          ele.muted = obj._Muted;
        }
        if (obj._Type === "music") {
          let _elementInfo = store.getState().scenes[index].elements[obj.id];

          // ele.volume = obj._Volume;
          if (_elementInfo?.adjustLength === 4) {
            document.musicLoop[obj.id] = obj;
            obj._adjustLength = 4;
          }
        } else {
          checkBufferVideoEvent(document.getElementById(obj.id));
        }
      }
    }

    if (obj._Type === "avatar") {
      if (mode === 2) {
        obj._Hidden = true;
      }
      setAudioAvater(index);
    }

    InitAddObjectEvents({
      obj,
      type: obj._Type,

      _id: obj.id,
      name: obj._Name,
      returnToUrl: false,
      onAddActive: false,
      index: index,
      media: obj._Media,
      data: obj._Data,
      reload: true,
      storeData: json.speech.sound,
    });
  });

  if (document.editorType === "video") {
    await LoadSAnimationVideoToDOM({
      index,
      animationData: json.sanimation?.animationData,
    });
  }

  store.dispatch({ type: "UPDATE_ELEMENT_LIST", data: { index } });
  let arr = JSON.parse(store.getState().scenes.currentScene.arr);

  if (arr[arr.length - 1] === index) {
    // addMissingAvatar();

    setTimeout(() => {
      arr.forEach((e) => {
        document.setToggle(getById(`avatar${Index()}`)?._Hidden);
      });
    }, 0);

    setTimeout(() => {
      //store.dispatch({ type: "HAVE_VID", data: true });
      // document.hitSpeechAPI({ con: true });
      arr.forEach((i) => {
        // document.smallScene.draw(i);/

        let avatar = getById(`avatar${i}`);
        if (avatar) {
        } else {
          console.log(i, "is missing");

          // addAvatarToScreenFirstTime({ index: i, onlyAvatar: true });
        }
      });

      document.Save.save = true;
    }, 0);
  }

  // document.smallScene.make(index);
};

export const addAllMusicFromJSON = async (cxxJSON, cxx, index) => {
  if (cxxJSON.objects) {
    const _allMusic = cxxJSON.objects.filter((e) => e._Type === 'music');
    const _musicElms = [];
    for (let _t = 0; _t < _allMusic.length; _t++) {
      let obj = _allMusic[_t];
      const Media = document.querySelector(".Media");
      let audEle = document.createElement("audio");
      const storee = store.getState().scenes[index].elements[obj.id];
      if (storee && !document.musicLoop[obj.id]) {
        const { trimStart, volume } = storee;
        let _volume = obj._Volume;
        audEle.preload = "auto";
        audEle.crossOrigin = "anonymous";
        // add for temp to prevent duplication
        document.musicLoop[obj.id] = true;

        audEle.src = await display(obj._Music.media_file, obj._Music.id);
        audEle.id = obj.id;
        audEle.customVideoSeek = customVideoSeek;

        // audEle.volume = volume / 100;
        audEle.customVideoSeek(trimStart);
        let k = document.getElementById(obj.id);
        if (k) {
          audEle = k;
        } else {
          Media.appendChild(audEle);
          addEventsInAudio(audEle, index);
          audEle._Music = obj._Music;
          audEle.volume = _volume;
        }

        audEle.pause();
      }
    }
  }
};

export const removeExtraMusicLoopId = () => {
  let _allK = Object.keys(document.musicLoop);
  _allK.forEach((_id) => {
    if (document.musicLoop[_id] === true) {
      delete document.musicLoop[_id];
    }
  });
};

export const verifierElements = ({ data, cb = () => { } }) => {
  cb(data);
  return;
  cb({
    ...produce((s = data) => {
      let sceneArr = JSON.parse(s.currentScene.arr);
      sceneArr.filter((index) => {
        let scene = s[index];
        let elements = {};
        let objs = [];

        (scene.jsonData.objects || []).forEach((obj) => {
          elements[obj.id] = scene.elements[obj.id];
          objs.push(obj.id);
        });
        if (!scene.jsonData.objects) {
          scene.jsonData.objects = [];
        }

        objs.reverse();

        scene.elements = elements;
        scene.elementList = JSON.stringify(objs);
      });

      return s;
    })(),
  });
};
