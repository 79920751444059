import store from "../store/store";
import { Index } from "../utils";

export const avatar = {
  hide: {
    get: (obj) => {
      //   //console.log("get", obj._Type);
      // obj._Voice = !obj._Hidden;
      // obj._Hidden = obj._Voice;
      return !obj._Voice;
    },
    set: (obj, state) => {
      if (obj) {
        let _mode = store.getState().scenes[Index()].mode;
        console.log({
          _mode,
        });
        if (_mode === 0) {
          document.mixedAvatar.voiceOnlyMode({
            index: Index(),
          });
          store.dispatch({
            type: "SMODE",
            data: 1,
          });
        } else if (_mode === 1) {
          document.mixedAvatar.avatarMode({
            index: Index(),
          });
          store.dispatch({
            type: "SMODE",
            data: 0,
          });
        }
        //if(obj._Voice)
        // document.setToggle((e) => !e);
        // obj._Voice = !!!obj._Voice;
        // obj._Hidden = obj._Voice;

        // obj.set({
        //   visible: Number(!obj._Voice),
        //   selectable: Number(!obj._Voice),
        // });
        // if (obj._Voice) {
        //   obj.canvas.discardActiveObject();
        // } else {
        //   obj.canvas.setActiveObject(obj);
        // }
        // obj.canvas.fire("object:modified");

        // obj.canvas.renderAll();
      }
    },
  },
};
let universalHideGet = (obj) => !obj._Hidden;
let universalHideSet = (obj, what) => {
  obj._Hidden = !what;
  obj.set({
    visible: what,
  });
  obj.canvas.fire("object:modified");
  obj.canvas.renderAll();
  store.dispatch({
    type: "RANDOM"
  });
};
const elements = {
  hide: {
    get: universalHideGet,
    set: universalHideSet,
  },
};
const image = {
  hide: {
    get: universalHideGet,
    set: universalHideSet,
  },
};
const text = {
  hide: {
    get: universalHideGet,
    set: universalHideSet,
  },
};
const universalMuteGet = (obj) => {
  //   let p = document.getElementById(obj.id);
  return obj._Muted;
};
const universalMuteSet = (obj, what) => {
  let p = document.getElementById(obj.id);
  p.muted = what;
  obj._Muted = what;

  obj.canvas.fire("object:modified");
};
const video = {
  hide: {
    get: universalHideGet,
    set: universalHideSet,
  },
  mute: {
    get: universalMuteGet,
    set: universalMuteSet,
  },
};

const music = {
  mute: {
    get: universalMuteGet,
    set: universalMuteSet,
  },
};

const all = {
  avatar,
  elements,
  music,
  image,
  text,
  video,
};
export default all;
