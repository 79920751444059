import React, { memo, useState } from "react";
import { useEffect } from "react";
import store from "../../../store/store";
import { fetcher, url } from "../../../utils";
import { UploadOption } from "../Compound/UploadScroll";
export function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}
export default memo(function MusicCard({
  hasOptions = true,
  name = "",
  time = "1:45",
  onPlay = () => { },
  isPlayed = false,
  refi = null,
  src,
  onSelect = () => { },
  musicId,
  selectedId,
  type,
}) {
  // const [isPlayed, setIsPlayed] = useState(false);
  // const ref = useRef
  const [duration, setDuration] = useState(fancyTimeFormat(time));
  useEffect(() => {
    setDuration(fancyTimeFormat(time));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayed]);
  const handlePlay = () => {
    const a = document.querySelector("#muicPlayerLeft");

    if (a) {
      if (!a.paused) {
        a.pause();
        onPlay(true);
        return;
      }
      a.src = src;
      a.ontimeupdate = () => {
        setDuration(fancyTimeFormat(Math.round(a.currentTime)));
      };
      a.onended = () => {
        onPlay(true);
        setDuration(time);
      };

      a.play();

      onPlay();
    }
  };
  const [rename, setRename] = useState(false);

  const handleBlur = (e) => {
    let name = e.target.value;

    fetcher({
      method: "PUT",
      url: `${url}/api/userlibrary/upload/${musicId}/`,
      data: { name },
      res: (data) => {
        setRename(false);
        console.log(data.data);
        store.dispatch({
          type: "RENAME_MEDIA",
          data: {
            type,
            id: musicId,
            name,
          },
        });
      },
      err: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <div
      className={`MusicCard ${selectedId === musicId ? "MusicCard_selected" : ""
        }`}
    >
      <div className="MusicCard_icon" onClick={handlePlay}>
        <img
          src={!isPlayed ? `${document.PUBLIC_URL}/icons/musicPlay.svg` : `${document.PUBLIC_URL}/ico/pause.svg`}
          alt="musicPlayIcons"
        />
      </div>
      <div className="MusicCard_details" onClick={onSelect}>
        <input
          disabled={!rename}
          onBlur={handleBlur}
          className="MusicCard_details_title"
          defaultValue={name}
          onClick={(e) => e.stopPropagation()}
        />
        <div className="MusicCard_details_time">{duration}</div>
      </div>
      <div className="MusicCard_option">
        {hasOptions &&
          UploadOption({
            id: musicId,
            del: () => musicId,
            src: `${document.PUBLIC_URL}/icons/musicOption.svg`,
            rename: setRename,
            type,
            text: "Music",
          })}
      </div>
    </div>
  );
});
