import { useEffect } from "react";
import { useCallback, useState, memo } from "react";
import TabTop from "../Atom/TabTop";
import "./_Compound.scss";
import { gsap } from "gsap";
import { useRef } from "react";

function Tabium({ arrTab, children, current = 0, onTabChange = () => {} }) {
  ////console.log("Tabium Renders");
  const ref = useRef();
  const [nowTab, setTab] = useState(current);
  const handleTab = useCallback((tab) => {
    setTab((_tab) => {
      // gsap.fromTo(ref.current, { x: (tab - _tab) * 100 }, { x: 0 });
      onTabChange(tab);
      return tab;
    });
  }, []);

  // useEffect(()=>{
  //   ////console.log()
  // },[nowTab])
  return (
    <div className="Tabium">
      <TabTop arrTab={arrTab} nowTab={nowTab} handleTab={handleTab} />
      <div ref={ref} className={"Tabium_child"} style={{ height: "100%" }}>
        {children[nowTab]}
      </div>
    </div>
  );
}
export default memo(Tabium);
