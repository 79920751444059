/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { register, resetState, registerUserWithOrg } from "./actions/auth";
// import {
// 	LOGIN_FAIL,
// 	REGISTER_FAIL,
// 	REGISTER_SUCCESS,
// } from "./actions/types";
import { ReactComponent as Autogenerate } from "../App/extras/aiLogo.svg";
import { ReactComponent as VerifyEmailIcon } from "../App/extras/misc/mailverify.svg";
import { ReactComponent as CrossError } from "../App/extras/misc/loginerrorcross.svg";
import { ReactComponent as PinkTick } from "../App/extras/analytics/tickAnalyticSm.svg";
import { ReactComponent as WhiteTick } from "../App/extras/misc/tickWhite.svg";
import { ReactComponent as Google } from "../App/extras/misc/logo-google.svg";
// import { url } from "../../config/config";

import axios from "axios";
import GoogleAuth from "./social-auth/GoogleHooks";
import { url } from "../utils";
import mystore from "../App/appStore";

// import { objectKeys } from 'react-foundation/utils';

function SignUp(props) {
	const [Details, setDetails] = useState(
		{
			name: "",
			email: "",
			password: "",
		},
		() => {
			console.log("frist");
		}
	);
	const [Step, setStep] = useState(0);
	const [Errors, setErrors] = useState({});
	const [InsideErrors, setInsideErrors] = useState({
		isError: false,
		error: [],
	});

	useEffect(() => {
		if (props.state && props.state.isError) {
			console.log(props.state.message);
			var obj;

			Object.keys(props.state.message).map((d) => {
				obj = {
					isError: true,
					...obj,
					message:
						d === "email"
							? props.state.message[d].status === 1
								? "Email already registered."
								: "Something went wrong. Please contact the Autogenerate.ai"
							: "Something Went wrong. Please contact the Autogenerate.ai",
				};
			});

			setErrors(obj);
		} else {
			// console.log(
			// 	props.state && props.state.isError === false && Details.code == null
			// );
			if (
				props.state &&
				props.state.isError === false &&
				Details.code == null
			) {
				setErrors({
					isError: false,
				});
				setStep(1);
			}
		}
	}, [mystore.getState().auth]);

	const handlerSubmit = (e) => {
		var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		// var password = /[!@#$%^&*(),.?":{}|<>-_;:|]/g;
		if (
			Details.email.match(mailformat) &&
			Details.password.length >= 6 &&
			Details.name.length > 0
		) {
			const urlParams = new URLSearchParams(window.location.search);
			const myParam = urlParams.get("code");

			// console.log(myParam);
			if (Details.code && Details.code?.length > 0) {
				props.registerUserWithOrg(
					Details.name,
					Details.code,
					Details.email,
					Details.password
				);
			} else {
				props.register(Details.name, Details.email, Details.password);
			}

			// props.login(Details.email,Details.password)

			// setStep(1)
		} else {
			if (!Details.name.length > 0) {
				document.getElementById("name-input").classList.add("errorInput");
			}
			if (!Details.email.match(mailformat)) {
				document.getElementById("email-input").classList.add("errorInput");
			}

			var arr = [];
			var error = false;

			if (Details.password.length < 6) {
				console.log(Details.password.length);
				document.getElementById("password-input").classList.add("errorInput");
				error = true;
				arr.push({
					status: false,
					message: "6 or more characters",
				});
			} else {
				error = false;
				document
					.getElementById("password-input")
					.classList?.remove?.("errorInput");
				arr.push({
					status: true,
					message: "6 or more characters",
				});
			}

			// if (Details.password.match(password)) {
			// 	document.getElementById("password-input").classList.add("errorInput");
			// 	error = true;
			// 	arr.push({
			// 		status: true,
			// 		message: "At least 1 symbol",
			// 	});
			// } else {
			// 	arr.push({
			// 		status: false,
			// 		message: "At least 1 symbol",
			// 	});
			// }
		}

		if (arr && arr.length > 0 && error) {
			setInsideErrors({
				isError: true,
				error: arr,
			});
		} else {
			setInsideErrors({
				isError: false,
				error: [],
			});
		}
	};

	const handleChange = (e) => {
		e.target.classList.remove("errorInput");
		setInsideErrors({ isError: false, error: [] });

		setDetails({ ...Details, [e.target.name]: e.target.value });
	};
	useEffect(async () => {
		const urlParams = new URLSearchParams(window.location.search);
		const myParam = urlParams.get("code");

		setDetails({
			name: document.getElementById(`name-input`).value,
			email: document.getElementById(`email-input`).value,
			password: document.getElementById(`password-input`).value,
		});
		setErrors({});
		setStep(0);

		Details;
		if (myParam) {
			var res = await axios.get(
				`${url}/api/accounts/user/organization/codeverify/?code=${myParam}`
			);
			if (res.data.isError) {
				var obj = {
					isError: true,
					...obj,
					message: "Verification failed",
				};
				setErrors(obj);
			} else {
				setDetails({ ...Details, email: res.data.email, code: myParam });
			}
		}
	}, []);

	const al = () => {
		if (Step === 0) {
			return (
				<div key={0} className={`login-wrapper ${Step === 0 ? "" : ""}`}>
					<div className="login-inner-wrapper">
						{/* <div className="login-gp-1">
							<GoogleAuth />
						</div>

						<h6 className="word-btw-line login">
							<span>OR</span>
						</h6> */}

						<div className="login-form">
							{Errors.isError ? (
								<div className="error-login-info">
									<label htmlFor="" className="error-login-info-heading">
										Error !
									</label>
									<label htmlFor="" className="error-login-info-label">
										{Errors.message}
									</label>
									<CrossError
										className="pointer"
										onClick={() => {
											setErrors({
												...Errors,
												isError: false,
											});
										}}
										style={{ position: "absolute", right: "16px", top: "35%" }}
									/>
								</div>
							) : null}
							<input
								id="name-input"
								type="text"
								className="login-item"
								placeholder="Name"
								name="name"
								onChange={(e) => {
									handleChange(e);
								}}
							/>
							<input
								id="email-input"
								type="email"
								className="login-item"
								placeholder="Email"
								name="email"
								defaultValue={Details.email}
								onChange={(e) => {
									handleChange(e);
								}}
							/>
							<span style={{ width: "100%", position: "relative" }}>
								<input
									id="password-input"
									type="password"
									className="login-item"
									placeholder="Password"
									name="password"
									onChange={(e) => {
										handleChange(e);
									}}
								/>
								{InsideErrors.error.map((d) => (
									<label htmlFor="" className="error-info-item">
										Password must contain {d.message}.
									</label>
								))}
								{/* {InsideErrors.isError ? (
									<div className="error-info-tooltip-tool">
										<label htmlFor="" className="error-info-tooltip-head">
											Password must have:
										</label>
										{InsideErrors.error.map((d) => (
											<label htmlFor="" className="error-info-item">
												{" "}
												{d.status ? <PinkTick /> : <WhiteTick />} &nbsp;{" "}
												{d.message}
											</label>
										))}
									</div>
								) : null} */}
							</span>
						</div>

						<div className="login-footer">
							<button
								className="login-main-button"
								onClick={(e) => {
									handlerSubmit(e);
								}}
							>
								Create Account
							</button>

							<label className="login-main-label">
								Already a member?{" "}
								<Link to="/login" className="link-custom">
									Log in.
								</Link>
							</label>
							{/* <Link to="/signup" className="link-custom forget-login-main" >Forgot Password?</Link> */}
						</div>
					</div>
				</div>
			);
		}
		if (Step === 1) {
			return (
				<div key={1} className={`login-wrapper`} style={{ maxWidth: "513px" }}>
					{/* <Autogenerate style={{ width: "240px" }} /> */}

					<h1 className="login-header" style={{ margin: "30px 0" }}>
						Verify email.
					</h1>

					<div className="login-inner-wrapper">
						<div className="login-gp-1">
							<label className="login-main-label">
								Verify email id to continue. A verification email has been sent
								to{" "}
							</label>
							<label className="login-verification-label">
								{Details.email}
							</label>

							{/* <GoogleAuth /> */}
							{/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
						</div>

						<div className="login-form">
							<VerifyEmailIcon />
						</div>

						<div className="login-footer">
							<Link
								to={{
									pathname: "/login",
									email: Details?.email,
								}}
								className="login-main-button link-custom"
								style={{ margin: "50px 0 0 0", width: "200px" }}
							>
								Login
							</Link>

							{/* <Link to="/signup" className="link-custom forget-login-main" >Forgot Password?</Link> */}
						</div>
					</div>
				</div>
			);
		}
	};

	if (props.isAuthenticated) {
		return (window.location.pathname = "/");
	} else return <div className="login-container">{al()}</div>;
}

const mapStateToProps = () => {
	var state = mystore.getState();

	return {
		isAuthenticated: state.auth.isAuthenticated,
		state: state.auth,
	};
};

export default connect(mapStateToProps, {
	register,
	resetState,
	registerUserWithOrg,
})(SignUp);
//export default LoginForm;
