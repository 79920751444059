import produce from "immer";
import store from "../store/store";
import { Vid } from "../utils";
import uuid from "uuid-random";

let ch = (arr, i) => {
  let obj = {};
  arr.map((str) => {
    if (str.indexOf("avatar") === 0) {
      obj[str] = `avatar${i}`;
    } else if (str.indexOf(`background`) === 0) {
      obj[str] = `background${i}`;
    } else if (str.indexOf("_") === 0) {
      obj[str] = `_${uuid()}`;
    } else {
      obj[str] = uuid();
    }
  });

  return obj;
};

let swap = (mem, kFrom, kTo) => {
  let k = {
    ...mem[kFrom],
  };
  mem[kTo] = k;
  delete mem[kFrom];
};

export const idConversion = ({ json, to, type = "duplicate" }) => {
  let f = json;
  let res = {
    elements: {},
    jsonData: {
      ...f.jsonData,
      objects: [],
    },
    elementList: "",
    background: {},
    speech: {
      data: {},
      arr: "",
    },
    media: {},
    sanimation: {},
    haveEasyMode: true,
    mode: 0,
  };
  let k = produce((d = res) => {
    // console.log({ f });
    let idMap = ch(JSON.parse(f.elementList), to);
    let sMap = ch(Object.keys(f.speech.data), to);

    // same id for music loop
    d.jsonData.objects = f.jsonData.objects.forEach((e) => {
      if (e._Type === "music") {
        if (type === "template") {
          idMap[e.id] = e.id;
        } else {
          if (document.musicLoop[e.id] !== undefined) {
            idMap[e.id] = e.id;
          }
        }
      }
    });

    //elements
    Object.keys(f.elements).forEach((e) => {
      d.elements[idMap[e]] = f.elements[e];
    });

    //speech

    d.speech = { ...f.speech, data: {} };

    Object.keys(f.speech.data).forEach((e) => {
      // d.speechdata[sMap[e]] = f.speech.data[e];
      d.speech = {
        ...d.speech,
        data: {
          ...d.speech.data,
          [sMap[e]]: f.speech.data[e],
        },
      };
    });

    d.speech.arr = JSON.stringify(Object.keys(d.speech.data));

    //object id,link
    d.jsonData.objects = f.jsonData.objects.map((e) => {
      let id = idMap[e.id];

      if (e._Type === "text") {
        return {
          ...e,
          id,
          _DefaultText: e.text,
        };
      }
      return {
        ...e,
        id,
      };
    });

    // element list
    if (1) {
      let list = JSON.parse(f.elementList);
      d.elementList = JSON.stringify(list.map((e) => idMap[e]));
    }

    d.background = f.background; // background
    // d.speech = f.speech; //speech
    //Speech Operation

    d.media = f.media; // media
    d.sanimation = f.sanimation; // sanimation
    // if()
    d.haveEasyMode = f.haveEasyMode; // sanimation
    if (type === "template") {
      d.haveEasyMode = true;
    }
    d.mode = f.mode; // sanimation

    return d;
  });

  //   console.log(k());
  return k();
};
document.idConversion = idConversion;
