import { defaultDurationLengthInSec, getById } from "../../utils";

export const EDIT_URL_NEW_MERGE_TEXTBOX = ({ state, data }) => {
  let _n = (e) => Number(Number(e).toFixed(2));
  let scenes = null,
    index = null;
  if (state.scenes.speechChangedAt !== undefined) {
    scenes = state.scenes[state.scenes.speechChangedAt];
    if (scenes) {
      index = state.scenes.speechChangedAt;
    } else {
      scenes = state.scenes[data.i];
      index = data.i;
    }
  } else {
    scenes = state.scenes[data.i];
    index = data.i;
  }
  // let index =
  //   state.scenes.speechChangedAt !== undefined
  //     ? state.scenes.speechChangedAt
  //     : data.i;
  // state.scenes.speechChangedAt=undefined;

  // let scenes = state.scenes[index];

  if ("EASYMODE" && scenes?.haveEasyMode) {
    let nexts = 0;
    if (data.haveDefault) {
      nexts = defaultDurationLengthInSec;
    } else {
      if (index === undefined) {
        if (data.extraIndex !== undefined) {
          index = data.extraIndex;
        } else {
          index = 0;
        }
      }
      nexts = _n(state.scenes[index].speech.sound.soundDuration); //data.data[index]?.combineData?.soundDuration;

      if (nexts === undefined) {
        nexts = document._Max;
      }
    }

    const { enterDelay } = scenes.elements[`avatar${index}`];
    //const stayTime = _n(state.scenes[index].speech.sound.soundDuration);
    Object.keys(scenes.elements).forEach((e) => {
      let ele = scenes.elements[e];
      if (e) {
        let obj = getById(e);
        if (
          obj._Type !== "music" &&
          obj._Type !== "background" &&
          obj._Type !== "avatar"
        ) {
          ele.stayTime = _n(nexts + enterDelay - ele.enterDelay);
          // if (_n(ele.stayTime + ele.enterDelay) >= _n(stayTime + enterDelay)) {
          //   ele.stayTime = _n(nexts + enterDelay - ele.enterDelay);
          // } else {
          // }
        }
      }
    });
  }

  if (!data.onlyDuration) {
    let currArr = JSON.parse(state.scenes.currentScene.arr);
    data.data.forEach((k, p) => {
      let i = currArr[p];
      if (state.scenes[i].mode !== 2) {
        if (data.data[p]) {
          data.data[p].results.forEach((e, _i) => {
            if (state.scenes[i].speech.data[e._id]) {
              state.scenes[i].speech.data[e._id].url = e.sound;
              state.scenes[i].speech.data[e._id].duration = _n(e.soundDuration);
            }
          });

          state.scenes[i].speech.sound = data.data[p].combineData;

          let sd = _n(state.scenes[i].speech.sound.soundDuration);
          let st = state.scenes[i].speech.sound.sound;
          state.scenes[i].speech.speechTime = sd;

          state.scenes[i].elements[`avatar${i}`] = {
            ...state.scenes[i].elements[`avatar${i}`],
            stayTime: sd,
            sound: st,
            duration: sd,
            trimEnd: sd,
            trimStart: 0,
          };

          state.config.speechLoad = false;

        }
      } else {
        state.scenes[i].elements[`avatar${i}`].stayTime = data.data[p].combineData.soundDuration;
      }
    });
  }


};
