import { memo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import store from "../../../store/store";
import { Index } from "../../../utils";
import { MIN_STAYTIME } from "../../../utils/config";
import { discardCurrentWork } from "../../../utils/useFullFunctions/discardWork";
import LabelInput from "../Atom/LabelInput";
import { UpdateBackground } from "../One/Draggy";

export const DelaySettingsInput = memo(({ id }) => {
  let ele = document._Elements[id] || {};
  const [delay, setDelay] = useState(ele.enterDelay);
  let _min = Number(Number(document._Max / document.per -
    Math.max(ele.enter + ele.exit, MIN_STAYTIME)
  ).toFixed(2));
  useEffect(() => {
    try {
      let ele = store.getState().scenes[Index()].elements[id];
      if (ele) {
        setDelay(ele.enterDelay);
      }
      document._setDelay[id] = () => {
        let ele = store.getState().scenes[Index()].elements[id];
        if (ele) {
          setDelay(ele.enterDelay);
        }
      };
    } catch (err) {
      console.log(err);
    }
  }, []);

  // const handleSet = (id) => {
  //   let ele = store.getState().scenes[Index()].elements[id];
  //   if (ele) {
  //     setDelay(ele.enterDelay);
  //   }
  // };

  const handleDuration = (e) => {
    // discard current work
    discardCurrentWork({ _goB: true });
    let d = Number(e.target.value);
    let handle = () => {
      setAlert({ say: ``, has: false });
    };
    console.log({ _min });
    if (d >= 0 && d <= _min) {
      clearTimeout(handle);
      setDelay(e.target.value);

    }
    else if (d < 0) {
      setAlert({ say: `Can't be less than 0!`, has: true });
      _min = 0
    }
    else {
      setAlert({ say: `Can't be more than ${_min}!`, has: true });
      setTimeout(handle, 2000);

    }
  };

  const [alert, setAlert] = useState({ has: false, say: "" });
  let index = Index()
  const handleBlurDuration = async (e, pram = { id, store, UpdateBackground, Index }) => {
    setTimeout(() => {
      let ele = pram.store.getState().scenes[pram.Index()].elements[pram.id];
      if (e.target.value === "") {
        e.target.value = ele.enterDelay;
      }
      if (ele) {
        pram.store.dispatch({
          type: "EDIT_ELEMENT",
          data: {
            id: pram.id,
            index: pram.Index(),
            data: {
              enterDelay: Number(e.target.value),
              stayTime: Number(
                ele.stayTime - (Number(e.target.value) - ele.enterDelay)
              ),
            },
          },
        });
        pram.UpdateBackground();
      }

    }, 0)

  };
  useEffect(() => {
    const returnFunc = (delay) => {
      handleBlurDuration({ target: { value: delay } })
    }
    return returnFunc(delay);
  }, [delay])


  return (
    <div className="Animation_duration DelaySettingsInput">
      <LabelInput
        label="Delay"
        type="number"
        value={delay}
        onChange={handleDuration}
        // onEnter={handleBlurDuration}
        onBlur={e => {
          document.functionQueue.add(handleBlurDuration, this, [e, { id, store, UpdateBackground, Index: () => index }])
          console.log("Blur Delay");
          setTimeout(() => {
            // handleBlurDuration(e)
            document.functionQueue.run({})
          }, 500)

        }

        }
        onEnter={handleBlurDuration}
        min={0}
        max={_min}
        step={0.25}
        width={90}
        haveInc={true}
        innerWidth="50px"
      // onCleanup={value => handleBlurDuration({ target: { value } })}


      // min={0}
      />
      <div style={{ marginLeft: "5px", fontSize: "12px" }}>S</div>

      {alert.has && (
        <div className="DelaySettingsInput_alert">
          {alert.say}
        </div>
      )}
    </div>
  );
});
