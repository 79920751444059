


import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";
import mystore, { vid } from "../appStore";
import { fetcher, url as _url } from "../../utils";

function InfiniteNewWrapper({

    type = "",
    children,
    target,
    mystore = mystore,
    className = ""
}) {

    const [HasMore, setHasMore] = useState(false);
    let urls = {
        solomail_history: `${_url}/api/newvideo/video/generate/solomail/${vid()}/`,
        download_history: `${_url}/api/newvideo/video/generate/download/${vid()}/`,
        solo_history: `${_url}/api/newvideo/video/generate/solo/${vid()}/`,
        batch_history: `${_url}/api/newvideo/video/generate/batch/${vid()}/?type=1`,
        isolo_history: `${_url}/api/newimage/image/generate/link/${vid()}/`,
    }
    const handleFetch = () => {

        document.lock[type] = true;
        const { [type]: { next } } = mystore.getState().media;
        fetcher({
            url: next === type ? urls[type] : next,
            res: (e) => {
                const { results, next } = e.data;
                setHasMore(next ? true : false)
                mystore.dispatch({
                    type: "MEDIA_WITH_NEXT",
                    data: { next, list: results, type },
                });
                document.lock[type] = false;
            },
        });
    };


    useEffect(() => {

        let next = mystore.getState().media[type].next

        if (next === type) {
            setHasMore(urls[type])


        } else {
            setHasMore(next)
        }

        handleFetch()

    }, [])

    return (
        <InfiniteScroll
            scrollableTarget={target}
            dataLength={children.length}
            next={handleFetch}
            className={className}
            hasMore={HasMore}
            //   style={{ display: "flex", flexDirection: "column-reverse" }}
            loader={
                <div className="INFINITELOAD">
                    <img alt="Loading" src={`${document.PUBLIC_URL}/icons/loading.gif`} />
                </div>
            }
        >
            {children}
        </InfiniteScroll>
    );
}
export default InfiniteNewWrapper;
