/* eslint-disable */
import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { ReactComponent as DeleteBtnPopup } from "../../../extras/Delete.svg";
import { ReactComponent as LargeTick } from "../../../extras/account/tickBig.svg";
import { ReactComponent as Cross } from "../../../extras/account/cross.svg";
import { Redirect } from "react-router-dom";

export default function LoadingConfirm({ open, setOpen, onClickk }) {

    const [Redirectt, setRedirect] = useState(false)


    if (Redirectt) {
        return <Redirect to="/login" />
    }
    else {
        return (
            <Popup
                open={open}
                onClose={() => setOpen(false)}
                modal
                nested
            >
                {close => (

                    <div className="modal-dialog modal-dialog-centered deletePop-detail-popup-video">
                        <div className="modal-content vd-modal-content">
                            <div className="modal-header vd-modal-header del-popup-heading">
                                <Cross
                                    className="pointer"
                                    onClick={() => {

                                        close();
                                    }}

                                />
                            </div>
                            <div className="modal-body modal-avatar-select">


                                <div className="deletecamp-in-popup">
                                    <div className="deletecamp-image">
                                        <LargeTick />
                                    </div>

                                    <label className="deletecamp-lable-lg">Are you sure you want to logout?</label>


                                </div>

                                <div>

                                </div>





                            </div>
                            <div className="modal-footer vd-modal-footer">
                                <span>
                                    <button
                                        className="edit-duplicate-camp"
                                        onClick={() => {
                                            close()


                                        }}
                                    >
                                        Close
                                    </button>

                                    <button
                                        type="button"
                                        className="vd-avatar-select-btn"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            onClickk()
                                            // setRedirect(true)
                                            close();
                                        }}
                                    >
                                        Logout
                                    </button>


                                </span>
                            </div>

                        </div>
                    </div>

                )}
            </Popup>
        );
    }

}