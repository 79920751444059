import { useState } from "react";
import store from "../../../../store/store";
import InputRange from "../../../Chemistry/Atom/InputRange";
import { perSecFind } from "../SceneBar";

export const TimeZoom = ({ }) => {
  const [V, setV] = useState(90);

  const handleSet = (e, i) => {
    if (!i) {
      document.setPix((k) => {
        // //console.log(k);
        document.timePerPix = Number(e.x);
        return Number(e.x);
      });

      setV(Number(e.x));
      store.dispatch({
        type: "UPDATE_TIME_LINE",

        data: { value: perSecFind(Number(e.x)) },
      });
    } else {
      document.setPix((p) => {
        document.timePerPix = Number(i);

        return i;
      });

      setV(i);
      store.dispatch({
        type: "UPDATE_TIME_LINE",
        data: { value: perSecFind(Number(i)) },
      });
    }
  };
  return (
    <div className="TimeZoom">
      <img
        src={`${document.PUBLIC_URL}/ico3/zoomOut.svg`}
        alt=""
        className="pointer"
        onClick={(d) => handleSet({}, V - 1)}
      />
      <InputRange
        className="TimeZoom_input"
        min={10}
        max={149}
        value={V}
        onChange={handleSet}
      />

      <img
        src={`${document.PUBLIC_URL}/ico3/zoomin.svg`}
        alt=""
        className="pointer"
        onClick={(d) => handleSet({}, V + 1)}
      />
    </div>
  );
};
