/* eslint-disable */
import "./_Tab.scss";
import { memo } from "react";
import { Link } from "react-router-dom";
import { Index, Vid } from "../../../../../../utils";
import { connect } from "react-redux";
function Tab({
  name,
  asrc,
  aicon,
  src,
  onTabChoose,
  isActive,

  onClick = () => { },

}) {
  ////console.log(`Tab: ${name} is rendered`)

  const handleClick = () => {
    document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/${name}`)
    onTabChoose(name);

    // onClick();
  };

  return (
    <a onClick={onClick}>
      <div
        className={`Tab ${isActive ? "Tab-active" : ""}`}
        onClick={handleClick}
      >
        <img src={isActive ? asrc : src} alt={"sadfs"} />
        <label>{name}</label>
      </div>
    </a>
  );
}

export default connect((state) => ({

}))(Tab);
