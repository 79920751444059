/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import "./components/style.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import EditProfilePopup from "./components/editProfile";
import ManageTeam from "./components/manageTeam";
import PasswordPopup from "./components/passwordChange";
import { MainContext } from "../common/videoListContext";
// import { url, token } from "../../config/config";
import { fetcher, token, url } from "../../../utils";
import DropZone from "react-drop-zone";
import { ReactComponent as UploadImg } from "../../extras/misc/emptyupload.svg";
import { ReactComponent as PinkPlus } from "../../extras/misc/pinkplus.svg";
import { ReactComponent as BreadcrumbArrow } from "../../extras/sidebar/BreadcrumbArrow.svg";
import { ReactComponent as Dashboard } from "../../extras/sidebar/Dashboard.svg";
import MailSm from "../../extras/account/mailsm.svg";
import PhoneSm from "../../extras/account/phonesmall.svg";
import { ReactComponent as Expand } from "../../extras/expand.svg";
import OrgSm from "../../extras/account/Org.svg";
import Cal from "../../extras/account/cal.svg";
import Fb from "../../extras/account/fb.svg";
import Linked from "../../extras/account/linked.svg";
import Twitt from "../../extras/account/twitter.svg";
import { ReactComponent as Key } from "../../extras/account/key.svg";
import { ReactComponent as Edit } from "../../extras/editSm.svg";
import Loadingg from "../common/loading";
import HoverVideoPlayer from "react-hover-video-player";
import InfiniteScroll from "react-infinite-scroll-component";
import musicIcon from "../../extras/music.svg";
import csvIcon from "../../extras/misc/csvicon.svg";
import Audio from "./components/audioplayer";
import axios from "axios";
import VideoPlayer from "./components/videoPreview";
import Docs from "../common/docsPreview";
import LoadingComp from "../common/loading";
import ImagePreviewPopup from "../common/ImagePreview";
import BlankProfile from "../../extras/misc/blankprofile.svg";

function Index(props) {
	// const [Data, setData] = useContext(MainContext);
	const [Loading, setLoading] = useState(true);
	// const [UpLoading, setUpLoading] = useState(false);
	const [Index, setIndex] = useState(0);
	const { user } = { user: { ...props.auth } }
	const [DataUpload, setDataUpload] = useState({});
	const [IndexSelected, setIndexSelected] = useState(0);
	const [Loadingc, setLoadingc] = useState(true);
	const UploadTabs = [
		{ tab: <Tab>Logo</Tab> },
		{ tab: <Tab>Image</Tab> },
		{ tab: <Tab>Videos</Tab> },
		{ tab: <Tab>Documents</Tab> },
		{ tab: <Tab>Audio</Tab> },
		{ tab: <Tab>CSV</Tab> },
	];

	React.useEffect(() => {
		document
			.getElementById("bootstrap")
			.setAttribute("href", "/bootstrap.min.css");
		return () => {
			document.getElementById("bootstrap").removeAttribute("href");
		};
	}, []);

	const handleSubmitFile = (e, type, com) => {
		// console.log(e.target.files[0]);
		var file = e;
		var name = e.name;
		var media = new FormData();
		media.append("media_file", file);
		media.append("name", name);
		media.append("category", type);
		// var index=UploadingData.data.length
		// var x = UploadingData.data

		// if(UploadingData.open){
		//     x.push({
		//         name:name,
		//         size:Math.round((e.size / 1024)),
		//         percent:0
		//     })

		//     setUploadingData({
		//         open:true,
		//         data:x
		//     })
		// }
		// else{
		//         x=[{
		//         name:name,
		//         size:Math.round((e.size / 1024)),
		//         percent:0
		//     }]

		//     setUploadingData({
		//         open:true,
		//         data:x
		//     })
		// }

		// var index=x.length
		const Token = token();
		const options = {
			headers: { Authorization: `Token ${Token}` },
			"Content-Type": "multipart/form-data",
			// onUploadProgress: (progressEvent) => {
			//   const { loaded, total } = progressEvent;

			//   let percent = Math.floor((loaded * 100) / total);
			//   console.log(`${loaded}kb of ${total}kb || percent ${percent}`);
			//   console.log("are u open?");
			//   var x = UploadingData.data
			//   x[index-1]={
			//       ...x[index-1],
			//       name:name,
			//       size:Math.round((total / 1024)),
			//       percent:percent

			//   }
			//   setUploadingData({
			//       ...UploadingData,
			//       data:x
			//   })

			// },
		};

		// axios.post(`${url}/api/userlibrary/upload/`, media, options).then((d) => {
		// }).catch(error => {
		// 	console.log(error);
		// 	if (error.response.status === 401) {
		// 		window.location.href = "/login"
		// 	}
		// });
		fetcher({
			url: `${url}/api/userlibrary/upload/`,
			method: "POST",
			data: obj,
			res: res => {
				LoadCate(com);

			}, err: error => {

			}
		})
	};

	const category = {
		3: "category=upload&type=application",
		1: "category=upload&type=image",
		2: "category=upload&type=video",
		0: "category=logo",
		4: "&limit=40&type=audio",
		5: "&limit=40&type=csv",
	};

	const handleInfiniteScroll = async (s) => {

		if (DataUpload[s]?.next !== null) {
			// console.log("Working");/
			const Token = token();
			var options = {
				headers: { Authorization: `Token ${Token}` },
			};
			var cat = category[s];

			await axios
				.get(DataUpload[s].next, options)
				.then((res) => {
					const data = res.data.results;
					setDataUpload({
						...DataUpload,
						[s]: {
							...DataUpload[s],
							list: [...DataUpload[s].list, ...data],
							count: res.data.count,
							next: res.data.next,
						},
					});
				})
				.then(() => {
					setLoading(false);
					setLoadingc(false);
				}).catch(error => {
					console.log(error.response.status);
					if (error.response.status === 401) {
						window.location.href = "/login"
					}
				});
		}
	};

	const LoadCate = async (s) => {
		const Token = token();

		var options = {
			headers: { Authorization: `Token ${Token}` },
		};
		var cat = category[s];

		await axios
			.get(`${url}/api/userlibrary/upload/?limit=10&${cat}`, options)
			.then((res) => {
				const data = res.data.results;
				setDataUpload({
					...DataUpload,
					[s]: {
						first: true,
						list: data,
						count: res.data.count,
						next: res.data.next,
					},
				});
			})
			.then(() => {
				setLoading(false);
				setLoadingc(false);
			}).catch(error => {
				console.log(error.response.status);
				if (error.response.status === 401) {
					window.location.href = "/login"
				}
			});
	};
	const reloadData = async () => {
		const Token = token;

		var options = {
			headers: { Authorization: `Token ${Token}` },
		};
		var cat = category[IndexSelected];

		await axios
			.get(`${url}/api/userlibrary/upload/?limit=20&${cat}`, options)
			.then((res) => {
				const data = res.data.results;
				setDataUpload({
					...DataUpload,
					[IndexSelected]: {
						first: true,
						list: data,
						count: res.data.count,
						next: res.data.next,
					},
				});
			})
			.then(() => {
				setLoading(false);
				setLoadingc(false);
			}).catch(error => {
				console.log(error.response.status);
				if (error.response.status === 401) {
					window.location.href = "/login"
				}
			});

	};


	useEffect(() => {
		reloadData();

	}, []);

	var arr = {
		0: [
			<Dashboard />,
			<div className="breadcrumb-item-lib">Account Settings</div>,
		],
	};

	const LinkBreadcrumbs = {
		0: `/`,
		1: "/settings",
	};

	return (
		<div className="video-list-wrapper">
			{/* <ul className="breadcrumb-custom">
				{arr[Index].map((data, key) => (
					<Link to={`${LinkBreadcrumbs[key]}`} className="link-custom-bread">
						<li
							className={`breadcrumbs-items ${key === arr[Index].length - 1 ? "active-breadcrumb" : ""
								}`}
							onClick={() => {
								if (key === 1) {
									setIndex(0);
								}
							}}
						>
							{data} {key === arr[Index].length - 1 ? "" : <BreadcrumbArrow />}{" "}
						</li>
					</Link>
				))}
			</ul> */}
			{false ? (
				<div>{window.location.replace("/")}</div>
			) : (
				<div
					className="video-list-main-container"
					id="UploadingContainer"
					style={{ marginTop: "24px" }}
				>
					{/* <div className="header-analytics">
    <h2 className="heading-analytics">
    Profile
    </h2>
</div> */}

					<div className="profile-box-wrapper">
						<div className="profile-peronal-container">
							<img
								className="image-profile-setting"
								src={
									user.profile_image && user.profile_image?.length > 0
										? `${user.profile_image}`
										: BlankProfile
								}
								alt="profile"
							/>
							<div className="profile-imp-details">
								<div className="header-in-profile justify-content-start">
									<label htmlFor="" className="name-in-profile">
										{user.first_name} {user.last_name}
									</label>
									{user.org_is_admin ? (
										<div className="label-type-of-user">Admin</div>
									) : null}
								</div>

								<div className="info-in-profile">
									<div className="info-list-in-profile">
										<label htmlFor="" className="info-heading-dsm">
											{" "}
											<img
												src={MailSm}
												alt=""
												className="logo-personal-profile"
											/>{" "}
											Email
										</label>
										<label htmlFor="" className="info-heading-sm">
											{user.email}
										</label>
									</div>
									<div className="info-list-in-profile">
										<label htmlFor="" className="info-heading-dsm">
											<img
												src={PhoneSm}
												alt=""
												className="logo-personal-profile"
											/>{" "}
											Phone
										</label>
										{user.phone_number?.length > 0 ? (
											<label htmlFor="" className="info-heading-sm">
												{user.phone_number}
											</label>
										) : (
											<label
												htmlFor=""
												className="info-heading-sm bx0098i2 disable"
											>
												Click edit profile to edit this.
											</label>
										)}
									</div>
									{user?.organization?.name === "_blank" ? <></> : <div className="info-list-in-profile">
										<label htmlFor="" className="info-heading-dsm">
											<img
												src={OrgSm}
												alt=""
												className="logo-personal-profile"
											/>
											Organisation Name
										</label>
										{JSON.stringify(user?.organization?.name)?.length > 0 ? (
											<label htmlFor="" className="info-heading-sm">
												{user?.organization?.name}
											</label>
										) : (
											<label
												htmlFor=""
												className="info-heading-sm bx0098i2 disable"
											>
												Click edit profile to add link.
											</label>
										)}
									</div>}
								</div>
							</div>
						</div>
						<div className="profile-links-container">
							<div className="profile-imp-details w-100">
								<div className="header-in-profile left-inbx2-profile">
									{user.org_is_admin ? (
										<ManageTeam user={user}>
											<button className="button-n-bx2-manage-team">
												Manage Team &nbsp; <Edit />
											</button>
										</ManageTeam>
									) : null}

									<EditProfilePopup user={user}>
										<button
											className="button-n-bx2-profile"
											style={{ margin: "0px 20px" }}
										>
											Edit Profile &nbsp; <Edit />
										</button>
									</EditProfilePopup>
									{user.isCP && <PasswordPopup>
										<button className="button-n-bx2-profile">
											Change Password &nbsp; <Key />
										</button>
									</PasswordPopup>}
								</div>

								<div className="info-in-profile bx-22pp">
									{[
										{
											name: "Calendar Link",
											icon: Cal,
											lable: user.calendar_url,
										},
										{
											name: "Linkedin Link",
											icon: Linked,
											lable: user.linkedin_url,
										},
										{
											name: "Facebook Link",
											icon: Fb,
											lable: user.facebook_url,
										},
										{
											name: "Twitter Link",
											icon: Twitt,
											lable: user.twitter_url,
										},
									].map((d) => (
										<div className="info-list-in-profile ">
											<label htmlFor="" className="info-heading-dsm">
												{" "}
												<img
													src={d?.icon}
													alt=""
													className="logo-personal-profile"
												/>
												{d.name}
											</label>

											{d.lable?.length > 0 ? (
												<label htmlFor="" className="info-heading-sm bx0098i2">
													{d.lable}
												</label>
											) : (
												<label
													htmlFor=""
													className="info-heading-sm bx0098i2 disable"
												>
													Click edit profile to add link.
												</label>
											)}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					{(Loading || true) ? (
						<div></div>
					) : (
						<div className="my-upload-box-wrapper">
							<div className="header-analytics">
								<h2 className="heading-analytics">My Uploads</h2>
							</div>

							<div className="my-upload-box-tabs">
								<Tabs
									className="black-tabs"
									selectedIndex={IndexSelected}
									onSelect={(index) => {
										setIndexSelected(index);
										if (
											DataUpload[index]?.list?.length > 0 ||
											DataUpload[index]?.first
										) {
										} else {
											setLoadingc(true);
											LoadCate(index);
										}
									}}
								>
									<TabList>{UploadTabs.map((d) => d.tab)}</TabList>

									<TabPanel>
										{Loadingc ? (
											<LoadingComp style={{ height: "80vh", width: "100%" }} />
										) : (
											<div className="tab-myupload-continer">
												{DataUpload[0] == null ||
													(DataUpload[0].list &&
														DataUpload[0].list.length === 0) ? (
													<DropZone
														handleClick={true}
														onDrop={(file, text) => {
															handleSubmitFile(file, "logo", 0);
														}}
													>
														{({ over, overDocument }) => {
															return (
																<div
																	className={`myupload-empty ${overDocument ? "hello" : ""
																		}`}
																>
																	{
																		<div className="active-upload-salespage-empty">
																			<UploadImg />
																			<label
																				htmlFor=""
																				className="upload-empty1"
																			>
																				Start adding your files
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty2"
																			>
																				Drag & drop files or upload from your
																				computer
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty3"
																			>
																				Upload <PinkPlus />
																			</label>
																		</div>
																	}
																</div>
															);
														}}
													</DropZone>
												) : null}

												<InfiniteScroll
													dataLength={DataUpload[0].list.length}
													next={() => {
														handleInfiniteScroll(0);
													}}
													hasMore={DataUpload[0].next == null ? false : true}
													loader={<h4></h4>}
													scrollableTarget="UploadingContainer"
												>
													{DataUpload[0].list.map((d) => (
														<ImagePreviewPopup link={d.media_file}>
															<div className="image-container-myupload">
																<img
																	src={d.media_file}
																	alt=""
																	className="image-my-upload pointer"
																/>
																<label
																	htmlFor=""
																	className="myupload-label-profile"
																>
																	{d.name}
																</label>
																<div className="overlay-videocreate">
																	<Expand />
																</div>
															</div>
														</ImagePreviewPopup>
													))}
												</InfiniteScroll>
											</div>
										)}
									</TabPanel>
									<TabPanel>
										{/* image */}

										{Loadingc ? (
											<LoadingComp style={{ height: "80vh", width: "100%" }} />
										) : (
											<div className="tab-myupload-continer">
												{DataUpload[1] == null ||
													DataUpload[1].list.length === 0 ? (
													<DropZone
														handleClick={true}
														onDrop={(file, text) => {
															handleSubmitFile(file, "", 1);
														}}
													>
														{({ over, overDocument }) => {
															return (
																<div
																	className={`myupload-empty ${overDocument ? "hello" : ""
																		}`}
																>
																	{
																		<div className="active-upload-salespage-empty">
																			<UploadImg />
																			<label
																				htmlFor=""
																				className="upload-empty1"
																			>
																				Start adding your files
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty2"
																			>
																				Drag & drop files or upload from your
																				computer
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty3"
																			>
																				Upload <PinkPlus />
																			</label>
																		</div>
																	}
																</div>
															);
														}}
													</DropZone>
												) : null}

												<InfiniteScroll
													dataLength={
														DataUpload[1] && DataUpload[1].list
															? DataUpload[1].list.length
															: 0
													}
													next={() => {
														handleInfiniteScroll(1);
													}}
													hasMore={
														DataUpload[1] && DataUpload[1].next == null
															? false
															: true
													}
													loader={<h4></h4>}
													scrollableTarget="UploadingContainer"
												>
													{DataUpload[1] ? (
														DataUpload[1].list.map((d) => (
															<ImagePreviewPopup link={d.media_file}>
																<div className="image-container-myupload">
																	<img
																		src={d.media_file}
																		alt=""
																		className="image-my-upload pointer"
																	/>
																	<label
																		htmlFor=""
																		className="myupload-label-profile"
																	>
																		{d.name}
																	</label>
																	<div className="overlay-videocreate">
																		<Expand />
																	</div>
																</div>
															</ImagePreviewPopup>
														))
													) : (
														<div></div>
													)}
												</InfiniteScroll>
											</div>
										)}
									</TabPanel>
									<TabPanel>
										{/* Video */}
										{Loadingc ? (
											<LoadingComp style={{ height: "80vh", width: "100%" }} />
										) : (
											<div className="tab-myupload-continer">
												{DataUpload[2] == null ||
													DataUpload[2].list.length === 0 ? (
													<DropZone
														handleClick={true}
														onDrop={(file, text) => {
															handleSubmitFile(file, "", 2);
														}}
													>
														{({ over, overDocument }) => {
															return (
																<div
																	className={`myupload-empty ${overDocument ? "hello" : ""
																		}`}
																>
																	{
																		<div className="active-upload-salespage-empty">
																			<UploadImg />
																			<label
																				htmlFor=""
																				className="upload-empty1"
																			>
																				Start adding your files
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty2"
																			>
																				Drag & drop files or upload from your
																				computer
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty3"
																			>
																				Upload <PinkPlus />
																			</label>
																		</div>
																	}
																</div>
															);
														}}
													</DropZone>
												) : null}

												<InfiniteScroll
													dataLength={
														DataUpload[2] && DataUpload[2].list
															? DataUpload[2].list.length
															: 0
													}
													next={() => {
														handleInfiniteScroll(2);
													}}
													hasMore={
														DataUpload[2] && DataUpload[2].next == null
															? false
															: true
													}
													loader={<h4></h4>}
													scrollableTarget="UploadingContainer"
												>
													{DataUpload[2]
														? DataUpload[2].list.map((d, key) => (
															<VideoPlayer
																link={d.media_file}
																thumb={d.media_thumbnail}
															>
																<div className="image-container-myupload pointer">
																	<HoverVideoPlayer
																		preload="none"
																		key={key}
																		unloadVideoOnPaused
																		className="image-my-upload pointer my-upload-video-in-setting"
																		videoSrc={d.media_file}
																		pausedOverlay={
																			<img
																				src={d.media_thumbnail}
																				alt=""
																				className="image-my-upload pointer"
																			/>
																		}
																		loadingOverlay={
																			<div className="loading-overlay"></div>
																		}
																	/>
																	<label
																		htmlFor=""
																		className="myupload-label-profile"
																	>
																		{d.name}
																	</label>
																	<div className="overlay-videocreate">
																		<Expand />
																	</div>
																</div>
															</VideoPlayer>
														))
														: null}
												</InfiniteScroll>
											</div>
										)}
									</TabPanel>
									<TabPanel>
										{/* docs */}
										{Loadingc ? (
											<LoadingComp style={{ height: "80vh", width: "100%" }} />
										) : (
											<div className="tab-myupload-continer">
												{DataUpload[3] == null ||
													DataUpload[3].list.length === 0 ? (
													<DropZone
														handleClick={true}
														onDrop={(file, text) => {
															handleSubmitFile(file, "", 3);
														}}
													>
														{({ over, overDocument }) => {
															return (
																<div
																	className={`myupload-empty ${overDocument ? "hello" : ""
																		}`}
																>
																	{
																		<div className="active-upload-salespage-empty">
																			<UploadImg />
																			<label
																				htmlFor=""
																				className="upload-empty1"
																			>
																				Start adding your files
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty2"
																			>
																				Drag & drop files or upload from your
																				computer
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty3"
																			>
																				Upload <PinkPlus />
																			</label>
																		</div>
																	}
																</div>
															);
														}}
													</DropZone>
												) : null}

												<InfiniteScroll
													dataLength={
														DataUpload[3] && DataUpload[3].list
															? DataUpload[3].list.length
															: 0
													}
													next={() => {
														if (DataUpload[3]) handleInfiniteScroll(3);
													}}
													hasMore={
														DataUpload[3] && DataUpload[3].next == null
															? false
															: true
													}
													loader={<h4></h4>}
													scrollableTarget="UploadingContainer"
												>
													{DataUpload[3]
														? DataUpload[3].list.map((d) => (
															<Docs link={d.media_file}>
																<div className="image-container-myupload pointer">
																	<img
																		src={d.media_thumbnail}
																		alt=""
																		className="image-my-upload pointer"
																	/>
																	<label
																		htmlFor=""
																		className="myupload-label-profile"
																	>
																		{d.name}
																	</label>
																	<div className="overlay-videocreate">
																		<Expand />
																	</div>
																</div>
															</Docs>
														))
														: null}
												</InfiniteScroll>
											</div>
										)}
									</TabPanel>
									<TabPanel>
										{Loadingc ? (
											<LoadingComp style={{ height: "80vh", width: "100%" }} />
										) : (
											<div className="tab-myupload-continer-music">
												{DataUpload[4] == null ||
													DataUpload[4].list.length === 0 ? (
													<DropZone
														handleClick={true}
														onDrop={(file, text) => {
															handleSubmitFile(file, "bgMusic", 4);
														}}
													>
														{({ over, overDocument }) => {
															return (
																<div
																	className={`myupload-empty ${overDocument ? "hello" : ""
																		}`}
																>
																	{
																		<div className="active-upload-salespage-empty">
																			<UploadImg />
																			<label
																				htmlFor=""
																				className="upload-empty1"
																			>
																				Start adding your files
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty2"
																			>
																				Drag & drop files or upload from your
																				computer
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty3"
																			>
																				Upload <PinkPlus />
																			</label>
																		</div>
																	}
																</div>
															);
														}}
													</DropZone>
												) : null}
												<InfiniteScroll
													dataLength={
														DataUpload[4] && DataUpload[4].list
															? DataUpload[4].list.length
															: 0
													}
													next={() => {
														if (DataUpload[4]) handleInfiniteScroll(4);
													}}
													hasMore={
														DataUpload[4] && DataUpload[4].next == null
															? false
															: true
													}
													loader={<h4></h4>}
													scrollableTarget="UploadingContainer"
												>
													{DataUpload[4]
														? DataUpload[4].list.map((d) => (
															<div className="tab-myupload-container">
																<Audio
																	link={d.media_file}
																	id={d.id}
																	name={d.name}
																>
																	<div
																		className="bgMusic-single-container"
																	// onClick={() => SelectMusic(data, key)}
																	>
																		<span
																			className={`bgMusic-vd-setting pointer `}
																		>
																			<img src={musicIcon} alt="" />
																		</span>
																		<label className="my-uplaod-lable-music">
																			{d.name}
																		</label>
																	</div>
																</Audio>
															</div>
														))
														: null}
												</InfiniteScroll>
											</div>
										)}{" "}
									</TabPanel>
									{/* CSV */}
									<TabPanel>
										{Loadingc ? (
											<LoadingComp style={{ height: "80vh", width: "100%" }} />
										) : (
											<div className="tab-myupload-continer-music">
												{DataUpload[5] == null ||
													DataUpload[5].list.length === 0 ? (
													<DropZone
														handleClick={true}
														onDrop={(file, text) => {
															handleSubmitFile(file, "groupCampaignCsv", 5);
														}}
													>
														{({ over, overDocument }) => {
															return (
																<div
																	className={`myupload-empty ${overDocument ? "hello" : ""
																		}`}
																>
																	{
																		<div className="active-upload-salespage-empty">
																			<UploadImg />
																			<label
																				htmlFor=""
																				className="upload-empty1"
																			>
																				Start adding your files
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty2"
																			>
																				Drag & drop files or upload from your
																				computer
																			</label>
																			<label
																				htmlFor=""
																				className="upload-empty3"
																			>
																				Upload <PinkPlus />
																			</label>
																		</div>
																	}
																</div>
															);
														}}
													</DropZone>
												) : null}

												<InfiniteScroll
													dataLength={
														DataUpload[5] && DataUpload[5].list
															? DataUpload[5].list.length
															: 0
													}
													next={() => {
														handleInfiniteScroll(5);
													}}
													hasMore={
														DataUpload[5] && DataUpload[5].next == null
															? false
															: true
													}
													loader={<h4></h4>}
													scrollableTarget="UploadingContainer"
												>
													{DataUpload[5]
														? DataUpload[5].list.map((d) => (
															<div className="tab-myupload-container">
																<div
																	className="bgMusic-single-container"
																	style={{ cursor: "default" }}
																// onClick={() => SelectMusic(data, key)}
																>
																	<span
																		className={`bgMusic-vd-setting pointer `}
																	>
																		<img src={csvIcon} alt="" />
																	</span>
																	<label className="my-uplaod-lable-music">
																		{d.name}
																	</label>
																</div>
															</div>
														))
														: null}
												</InfiniteScroll>
											</div>
										)}{" "}
									</TabPanel>
								</Tabs>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

const mapStateToProps = (state) => {

	return {
		auth: state.user,
	};
};

export default connect(mapStateToProps)(Index);
