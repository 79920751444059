/* eslint-disable */
import store from "../../store/store";
import { Index } from "../../utils";
export const putToStore = (data) => {
  store.dispatch({ type: "UNDO_REDO_ADD", data });
};

const undoRedoStateManager = () => {
  return;
  try {
    const cxx = document.cxx[Index()];
    cxx.on("object:moved", (e) => {
      let { left, top } = e.transform.original;
      let obj = e.target;
      // let { left, top } = e.transform.original;
      // //console.log(e);
      putToStore({
        type: "MOVE",
        data: {
          id: e.target.id,
          before: { left, top },
          after: { left: e.target.left, top: e.target.top },
        },
      });
    });
    cxx.on("object:scaled", (e) => {
      // let { id, left, top, scaleX, scaleY } = e.target;
      let { left, top, scaleX, scaleY } = e.transform.original;
      let obj = e.target;

      // putToStore({ type: "SCALE", data: { id, left, top, scaleX, scaleY } });
      putToStore({
        type: "SCALE",
        data: {
          id: e.target.id,
          before: { left, top, scaleX, scaleY },
          after: {
            left: obj.left,
            top: obj.top,
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
          },
        },
      });
    });

    cxx.on("object:rotated", (e) => {
      let { left, top, angle } = e.target;
      let obj = e.target;

      // putToStore({ type: "ROTATE", data: { id, left, top, angle } });
      putToStore({
        type: "ROTATE",
        data: {
          id: e.target.id,
          before: { left, top, angle },
          after: { left: obj.left, top: obj.top, angle: obj.angle },
        },
      });
    });
    cxx.on("object:added", (e) => {
      // //console.log(e.target.id);
      putToStore({
        type: "ADD",
        data: {
          id: e.target.id,
          before: { visible: false },
          after: { visible: true },
        },
      });
    });

    cxx.on("text:changed", (e, a) => {
      //console.log(e, a);
      // let text =
      // putToStore({
      //   type: "EDIT",
      //   data: {
      //     id: e.target.id,
      //     before: {  },
      //     after: { visible: true },
      //   },
      // });
    });
  } catch (e) {
    //console.log(e);
  }
};

export default undoRedoStateManager;
