import Popup from "../../../App/Components/Popup";
import Modal from "reactjs-popup";
import React from 'react'
import Button from "../../../App/Components/Button";
import { useState, useEffect } from "react";
import store from "../../../store/store";
import { connect } from "react-redux";

export const SaveConfirm = connect(state => ({ name: state.name }))(({ onGenerate, name }) => {
    const [Name, setName] = useState(name);
    useEffect(() => {
        if (Name !== name) {
            setName(name)
        }
    }, [name]);

    return (

        <Modal
            trigger={
                <div className="GenerateButton pointer">
                    {document.editorType === "video" ? "Generate Video" : "Publish"}
                </div>
            }
            modal
        >
            {(close) => (
                <Popup
                    name={"Rename"}
                    close={close}
                    footer={<Button value={document.editorType === "video" ? "Generate Video" : "Publish"} onClick={() => { close(); onGenerate(Name) }} />}

                    centerHeader={false}
                    haveButton={true}

                >
                    <div className="deletecamp-in-popup">

                        <label className="deletecamp-lable-sm justify-content-start" style={{ margin: "0 0 30px 0" }}>Rename your {document.editorType} to make it easier to identify. After entering a unique name, click “{document.editorType === "video" ? 'Generate' : "Publish"}”.</label>

                        <span className="funnellabel" >{document.editorType === "video" ? 'Video' : "Image"} Name</span>
                        <input className="input-save-rename" defaultValue={name} type="text" onFocus={() => {
                            document.isCanActive = false
                        }} onChange={(e) => {
                            setName(e.target.value)
                        }}
                            onBlur={() => {

                                document.isCanActive = true

                            }}
                        />


                    </div>
                </Popup>
            )}
        </Modal>




    )
}
)


