import {
    fabric
} from "fabric";
(function (global) {

    'use strict';

    var filters = fabric.Image.filters,
        createClass = fabric.util.createClass;

    filters.ColorInterpolation = createClass(filters.BaseFilter, /** @lends fabric.Image.filters.SwapColor.prototype */ {

        /**
         * Filter type
         * @param {String} type
         * @default
         */
        type: 'ColorInterpolation',

        /**
         * Fragment source for the SwapColor program
         */
        fragmentSource: 'precision highp float;\n' +
            'uniform sampler2D uTexture;\n' +
            'varying vec2 vTexCoord;\n' +
            'const int MAX_INTERPOLATION = 20;\n' +
            'uniform float colorR[MAX_INTERPOLATION];\n' +
            'uniform float colorG[MAX_INTERPOLATION];\n' +
            'uniform float colorB[MAX_INTERPOLATION];\n' +
            'uniform float colorA[MAX_INTERPOLATION];\n' +
            'uniform int getIndex;\n' +
            'void main() {\n' +
            'vec4 color = texture2D(uTexture, vTexCoord);\n' +
            'if (getIndex == 1) {\n' +
            'gl_FragColor = vec4(colorR[0],colorG[0],colorB[0],colorA[0]);\n' +
            '} else {\n' +
            'for (int w = 0; w < MAX_INTERPOLATION; w+=1) {\n' +
            'if (w >= getIndex-1){break;}\n' +
            'float crntRange = float(w)/float(getIndex-1);\n' +
            'float nextRange = float(w+1)/float(getIndex-1);\n' +
            'if(color.r>crntRange && color.r<=nextRange) {\n' +
            'color.r = colorR[w+1];\n' +
            '}else if(color.r==crntRange){\n' +
            'color.r = colorR[w];\n' +
            '}\n' +
            'if(color.g>crntRange && color.g<=nextRange) {\n' +
            'color.g = colorG[w+1];\n' +
            '}else if(color.g==crntRange){\n' +
            'color.g = colorG[w];\n' +
            '}\n' +
            'if(color.b>crntRange && color.b<=nextRange) {\n' +
            'color.b = colorB[w+1];\n' +
            '}else if(color.b==crntRange){\n' +
            'color.b = colorB[w];\n' +
            '}\n' +
            'if(color.a>crntRange && color.a<=nextRange) {\n' +
            'color.a = colorA[w+1];\n' +
            '}else if(color.a==crntRange){\n' +
            'color.a = colorA[w];\n' +
            '}\n' +
            '}\n' +
            'gl_FragColor = color;\n' +
            '}\n' +
            '}',

        /**
         * SwapColor TableValues, a css color
         * @param {String} colorSource
         * @default
         */
        tableValues: {
            r: [0, 0.7647058823529411, 1],
            g: [0, 0.8823529411764706, 1],
            b: [0, 0.10588235294117647, 1],
            a: [0, 0.5, 1]
        },

        parseRange: function (multipleFactor = 255) {
            if (this.tableValues.r.length === this.tableValues.g.length && this.tableValues.g.length === this.tableValues.b.length && this.tableValues.b.length === this.tableValues.a.length) {
                return {
                    r: this.tableValues.r.map((elm) => multipleFactor * elm),
                    g: this.tableValues.g.map((elm) => multipleFactor * elm),
                    b: this.tableValues.b.map((elm) => multipleFactor * elm),
                    a: this.tableValues.a.map((elm) => multipleFactor * elm)
                };
            } else {
                return {
                    r: [multipleFactor],
                    g: [multipleFactor],
                    b: [multipleFactor],
                    a: [1]
                };
            }
        },


        /**
         * Apply the ColorInterpolation operation to a Uint8ClampedArray representing the pixels of an image.
         *
         * @param {Object} options
         * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
         */
        applyTo2d: function (options) {
            let imageData = options.imageData,
                data = imageData.data,
                i, len = data.length,
                interplateRange = this.parseRange();
            let totalN = interplateRange.r.length,
                sSeq;
            if (totalN > 1) {
                sSeq = 1 / Math.ceil(255 / (totalN - 1))
            }
            let _map = {
                0: 'r',
                1: 'g',
                2: 'b',
                3: 'a'
            }
            totalN = totalN - 1;
            let _r = interplateRange.r.slice(1);
            let _rr = interplateRange.r[0];
            let _g = interplateRange.g.slice(1);
            let _gg = interplateRange.g[0];
            let _b = interplateRange.b.slice(1);
            let _bb = interplateRange.b[0];
            let _a = interplateRange.a.slice(1);
            let _aa = interplateRange.a[0];

            for (i = 0; i < len; i += 4) {
                if (totalN === 1) {
                    data[i] = _rr;
                    data[i + 1] = _gg;
                    data[i + 2] = _bb;
                    data[i + 3] = _aa;
                } else {
                    //color = {r: data[i]/255,g: data[i+1]/255,b: data[i+2]/255,a: data[i+3]/255}
                    // for(let ii=0;ii<=totalN;ii++){
                    //     let crntRange = ii/totalN;
                    //     let nextRange = (ii+1)/totalN;
                    //     if(color.r>crntRange && color.r<=nextRange) {
                    //         data[i] = interplateRange.r[ii+1];
                    //     }else if(color.r===crntRange){
                    //         data[i] = interplateRange.r[ii];
                    //     }
                    //     if(color.g>crntRange && color.g<=nextRange) {
                    //         data[i+1] = interplateRange.g[ii+1];
                    //     }else if(color.g===crntRange){
                    //         data[i+1] = interplateRange.g[ii];
                    //     }
                    //     if(color.b>crntRange && color.b<=nextRange) {
                    //         data[i+2] = interplateRange.b[ii+1];
                    //     }else if(color.b===crntRange){
                    //         data[i+2] = interplateRange.b[ii];
                    //     }
                    //     if(color.a>crntRange && color.a<=nextRange) {
                    //         data[i+3] = interplateRange.a[ii+1];
                    //     }else if(color.a===crntRange){
                    //         data[i+3] = interplateRange.a[ii];
                    //     }              
                    // }
                    if (data[i]) {
                        data[i] = _r[~~(data[i] * sSeq)];
                    } else {
                        data[i] = _rr;
                    }
                    if (data[i + 1]) {
                        data[i + 1] = _g[~~(data[i + 1] * sSeq)];
                    } else {
                        data[i + 1] = _gg;
                    }
                    if (data[i + 2]) {
                        data[i + 2] = _b[~~(data[i + 2] * sSeq)];
                    } else {
                        data[i + 2] = _bb;
                    }
                    if (data[i + 3]) {
                        data[i + 3] = _a[~~(data[i + 3] * sSeq)];
                    } else {
                        data[i + 3] = _aa;
                    }
                }

            }
        },

        getUniformLocations: function (gl, program) {
            return {
                uColorR: gl.getUniformLocation(program, 'colorR'),
                uColorG: gl.getUniformLocation(program, 'colorG'),
                uColorB: gl.getUniformLocation(program, 'colorB'),
                uColorA: gl.getUniformLocation(program, 'colorA'),
                uGetIndex: gl.getUniformLocation(program, 'getIndex'),
            };
        },

        sendUniformData: function (gl, uniformLocations) {
            let _colorRange = this.parseRange(1);
            let totalN = _colorRange.r.length;
            gl.uniform1fv(uniformLocations.uColorR, _colorRange.r);
            gl.uniform1fv(uniformLocations.uColorG, _colorRange.g);
            gl.uniform1fv(uniformLocations.uColorB, _colorRange.b);
            gl.uniform1fv(uniformLocations.uColorA, _colorRange.a);
            gl.uniform1i(uniformLocations.uGetIndex, totalN);
        },

        isNeutralState: function () {
            return false;
        },

        /**
         * Returns object representation of an instance
         * @return {Object} Object representation of an instance
         */
        toObject: function () {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                tableValues: this.tableValues,
            });
        }
    });

    /**
     * Returns filter instance from an object representation
     * @static
     * @param {Object} object Object to create an instance from
     * @param {function} [callback] to be invoked after filter creation
     * @return {fabric.Image.filters.SwapColor} Instance of fabric.Image.filters.SwapColor
     */
    fabric.Image.filters.ColorInterpolation.fromObject = fabric.Image.filters.BaseFilter.fromObject;

})(typeof exports !== 'undefined' ? exports : this);



const ImageFilters = {
    "None": [],
    "Grayscale": [new fabric.Image.filters.Grayscale()],
    "Invert": [new fabric.Image.filters.Invert()],
    "Sepia": [new fabric.Image.filters.Sepia()],
    "Black & White": [new fabric.Image.filters.BlackWhite()],
    "Brownie": [new fabric.Image.filters.Brownie()],
    "Vintage": [new fabric.Image.filters.Vintage()],
    "Kodachrome": [new fabric.Image.filters.Kodachrome()],
    "Technicolor": [new fabric.Image.filters.Technicolor()],
    "Polaroid": [new fabric.Image.filters.Polaroid()],
    "ColorInterpolation": [new fabric.Image.filters.ColorInterpolation({
        tableValues: {
            r: [0, 0.7647058823529411, 1],
            g: [0, 0.8823529411764706, 1],
            b: [0, 0.10588235294117647, 1],
            a: [0, 0.5, 1]
        }
    })]
}

export default ImageFilters;