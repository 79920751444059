import { MaxSuper } from "../../components/Chemistry/One/Draggy";
import { getById, updateTimeLen } from "../../utils";
import { MIN_STAYTIME, MIN_TIMELINE, TIMELINE_STICK } from "../../utils/config";

export const WORKSPACE_RESIZE = ({ data, sceneNumber, state }) => {
  if ("WORKSPACE_RESIZE") {
    let _n = (e) => Number(Number(e).toFixed(2));
    let nexts = data.next;
    let now = data.now;

    let scenes = state.scenes[data.index];
    let stopTime = Object.keys(scenes.elements).map((e) => {
      let ele = scenes.elements[e];
      if (e) {
        let { enterDelay: ed, stayTime: st, enter, exit } = ele;
        let asum = Math.max(enter + exit, MIN_STAYTIME);

        let obj = getById(e);
        if (obj) {
          switch (obj._Type) {
            case "avatar":
              if (scenes.mode === 2) return 0;
              return ed + st;
            case "background":
              return 0;
            default:
              return ed + asum;
          }
        }
      }
      return 0;
    });

    let realStop = [...stopTime, MIN_TIMELINE, nexts];
    nexts = Math.max(...realStop);
    document._Max = nexts * document.per;

    document.setS[sceneNumber](Number(_n(nexts)));

    Object.keys(scenes.elements).forEach((e) => {
      let ele = scenes.elements[e];
      if (e) {
        let obj = getById(e);
        if (obj)
          if (obj._Type !== "avatar") {
            let setTo = Math.max(MIN_STAYTIME, _n(nexts - ele.enterDelay));

            if (_n(ele.stayTime + ele.enterDelay) === _n(now)) {
              ele.stayTime = setTo;
            } else if (_n(ele.stayTime + ele.enterDelay) > _n(nexts)) {
              ele.stayTime = setTo;
            } else {
              let diff = Math.abs(_n(ele.stayTime + ele.enterDelay - now));
              if (diff <= TIMELINE_STICK / document.per) {
                ele.stayTime = setTo;
              }
            }
          }
      }
    });
    let allMax = MaxSuper(state);
    document._Max = allMax * document.per;

    document.setS[sceneNumber](Number(_n(allMax)));
    document.setWx(_n(allMax * document.per));
    updateTimeLen();
    state.beforeMax = allMax;
  }
};
