import React, { useState } from "react";
// import Popup from "../Popup";
import Popup from "../Components/Popup";
// import "./_EEE.scss";

import "../Components/PopUp/_EEE.scss"

import Modal from "reactjs-popup";
// import Button from "../Button";


import { connect } from "react-redux";
// 
// import mystore, { vid } from "../../../appStore";

import copy from "copy-to-clipboard";
import Button from "../Components/Button";
import mystore from "../appStore";
import LabelInput from "../../components/Chemistry/Atom/LabelInput";
import { fetcher, url } from "../../utils";
import InfiniteNewWrapper from "../Components/infiniteNewWrapper";
import { CopyLink } from "../Components/PopUp/DownloadPopup";




export default connect((state) => ({ my: state.mydetail, media: state.media, list: state.media.isolo_history.list, next: state.media.isolo_history.next }))(
    function LinkPopup({
        my,
        next,
        list,
        trigger = (
            <Button
                className={`${mystore?.getState().mydetail?.mainVideoGenerate?.status !== 1 ? "Disabled-button-generate-invarPopup" : ""}`}
                id={"_shareLinkBtn"}
                value={
                    <>
                        Share via Link <img src={`${document.PUBLIC_URL}/app/link.svg`} alt="button"></img>
                    </>
                }
            />
        ),
        name = "Share via Link",
    }) {

        const [tag, setTag] = useState({});
        const [load, setLoad] = useState(false);
        const [err, setErr] = useState({ text: "", have: false });

        const handleChange = (i) => {
            // setTag()
            return (e) => {
                setTag((k) => ({ ...k, [i]: e.target.value }));
            };
        };

        const copyMe = (data) => {

            navigator.clipboard.writeText(my.link);
            copy(my.link)
            let oldText = document.getElementById(`copy-${my.id}`).innerHTML
            document.getElementById(`copy-${my.id}`).innerHTML = "Copied !";
            setTimeout(() => {
                document.getElementById(`copy-${my.id}`).innerHTML = oldText;
            }, 600)
            return data
        }

        const handleGenerateLink = () => {
            setLoad(true);
            console.log('generate', tag)
            fetcher({
                method: "POST",
                url: `${url}/api/newimage/image/generate/link/${my.id}/`,
                res: (res) => {
                    const { isError, result } = res.data;
                    console.log('res', result)
                    setLoad(false);
                    // console.log(res);
                    if (isError) {
                        setErr({ have: true, text: res.data.message });
                        setTag({});
                        setTimeout(() => {
                            setErr({ have: false, text: res.data.message });
                        }, 2000);

                    } else {
                        setTag({});

                        mystore.dispatch({
                            type: "MEDIA_WITH_FRONT",
                            data: {
                                type: "isolo_history",
                                next: null,
                                list: [result],
                            },
                        });
                    }
                },
                err: (e) => {
                    console.log(e);
                },
                data: {
                    mergeTagData: tag,
                },
            });
        }
        console.log(next)
        return (
            <Modal modal closeOnDocumentClick={true} trigger={<Button
                // className={`${mystore?.getState().mydetail?.mainVideoGenerate?.status !== 1 ? "Disabled-button-generate-invarPopup" : ""}`}
                id={"_shareLinkBtn"}
                value={
                    <>
                        Share via Link <img src={`${document.PUBLIC_URL}/app/link.svg`} alt="button"></img>
                    </>
                }
            />} nested  >
                {(close) => (
                    <Popup
                        UpperId="Linkpopup-1x1"
                        name={name}
                        close={close}
                        haveButton={false}
                        rightHeader={
                            <>
                                {/* <Button onClick={() => window.open(my.link, "_blank")} value={<div>Live Preview <img src={`${document.PUBLIC_URL}/app/up.svg`}></img></div>} className="ButtonIcon" /> */}

                                {/* <HowitWorks trigger={<ButtonIcon value={<div>How it works?</div>} icon=" " />} /> */}
                            </>
                        }
                    >

                        <div className="flex _column">
                            <div className="LinkPopop DownloadPopup flex " style={{ justifyContent: "center" }}>
                                {my?.isPersonalized ? (
                                    <>
                                        {err.have && <div className="Err">Error: {err.text}</div>}
                                        {load && (
                                            <div
                                                className="ScreenLoading"
                                            // style={{ display: l ? "flex" : "none" }}
                                            >
                                                <img src={`${document.PUBLIC_URL}/icons/Loading.svg`} alt="hey"></img>
                                                <div className="Text">Generating...</div>
                                            </div>
                                        )}
                                        <div className="DownloadPopup_merge">
                                            {my.mergeTag.map((e, i) => {
                                                return (
                                                    <div className="VariablePopup_flex" key={i}>

                                                        <LabelInput
                                                            label={`${e[0]}`}
                                                            type={e[1]}
                                                            value={tag[`${e[0]}_${e[1]}`]}
                                                            onChange={handleChange(`${e[0]}_${e[1]}`)}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <Button onClick={handleGenerateLink} className="" value={" Generate"} />
                                    </>

                                ) : (
                                    <CopyLink onCopy={copyMe} id={`copy-${my.id}`} url={my.link} haveLink={false} text="Copy Link !" status={1} type="copy" icon={`${document.PUBLIC_URL}/app/copyLink.svg`} />

                                )}

                            </div>
                            {my.imageSoloGenCount !== 0 && (
                                <div className="PersonalisedHistory">
                                    <div className="PersonalisedHistory_title">
                                        Personalised Link History
                                    </div>
                                    <div className="PersonalisedHistory_list realLinkHIstoryTab" id={"isolo_history_mdiv"}>
                                        <InfiniteNewWrapper type="isolo_history" target={"isolo_history_mdiv"} mystore={mystore} url={next || `${url}/api/newimage/image/generate/link/${my.id}/`} >
                                            {list.map((e) => {
                                                return (
                                                    <LinkHistoryTab
                                                        key={e.id}
                                                        id={e.id}
                                                        date={e.timestamp}
                                                        url={e.thumbnail}
                                                        full={e}
                                                    />)

                                            })}

                                        </InfiniteNewWrapper>

                                    </div>
                                </div>
                            )}
                        </div>

                    </Popup >
                )}
            </Modal >
        );
    });




const LinkHistoryTab = ({
    url,
    id,
    status,
    progress,
    date = "2021-11-22T14:16:20.354895Z",
    full, code, type = "isolo_history",
    media = {}
}) => {
    const [dd] = useState(new Date(date));
    const [open, setOpen] = useState(false);
    const copyMe = () => {
        navigator.clipboard.writeText(url);
        copy(url)
        let oldText = document.getElementById(`copy-${id}`).innerHTML
        document.getElementById(`copy-${id}`).innerHTML = "Copied !";
        setTimeout(() => {
            document.getElementById(`copy-${id}`).innerHTML = oldText;
        }, 600)
        return url

    }

    const newTab = () => {
        window.open(
            url, "_blank");
    }

    return (
        <div className="LinkHistoryTab realLinkHIstoryTab">
            <div className="LinkHistoryTab_head flex acenter between">
                <div className="LinkHistoryTab_head_link" style={{ width: "40%" }}>{url || code}</div>
                {/* <TablePopup media={media} /> */}
                <div className="flex">

                    <CopyLink onCopy={copyMe} id={`copy-${id}`} className={"solomail_history"} url={url} haveLink={false} text={"Copy Link !"} status={1} type="copy" icon={`${document.PUBLIC_URL}/app/copyLink.svg`} extraIcon={
                        <Button onClick={newTab} className={full.isGenerated ? "" : "Disabled-button-generate-invarPopup"} value={
                            < img
                                src={`${document.PUBLIC_URL}/app/newTab.svg`}
                                className="LinkHistoryTab_NewTab"
                                alt="23"
                            />
                        }>
                        </Button>

                    } />
                    <img
                        onClick={() => setOpen((o) => !o)}
                        className="pointer"
                        style={{ transform: `rotateZ(${!open ? "0" : "180"}deg)` }}
                        src={`${document.PUBLIC_URL}/app/downArrow.svg`}
                        alt="downArrow"
                    />
                </div>
            </div>
            {open && (
                <div className="LinkHistoryTab_body">
                    <div className="LinkHistoryTab_body_date flex">
                        <img src={`${document.PUBLIC_URL}/app/date.svg`} alt="date" /> {" " + dd.toDateString()}
                    </div>
                    <div className="LinkHistoryTab_body_value">
                        {" Variables & its values"}
                    </div>
                    {full.mergeTag.map((e, i) => {
                        return (
                            <div className="LinkHistoryTab_body_merge flex" key={i}>
                                <div className="LinkHistoryTab_body_merge_tag">{e[0]}:</div>
                                <div className="LinkHistoryTab_body_merge_value">{full.mergeTagValue[i]}</div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );

};
