/* eslint-disable */
import { gsap } from "gsap";
import React, { useEffect, useState, memo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./_SceneBar.scss";
import {

  Index,
  Jump,
  MoveSeekTo,
  nextSceneNumber,

  updateTimeLen,
  Vid,
} from "../../../utils";

import { universalPause } from "../../../utils/videoPlayThings/universalPause";
import { universalPlay } from "../../../utils/videoPlayThings/universalPlay";
import addAvatarToScreenFirstTime from "../../LeftPanel/components/Avatar/functions/addAvatar";
import { TimeLineClose } from "./TimeBar";
import store from "../../../store/store";
import { SCENE_LIMIT, TIME_PER_SEC } from "../../../utils/config";
import TwoSceneAnimation from "../../Chemistry/One/TwoSceneAnimation";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// import Popup from "../../../App/Components/Popup";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { UpdateBackground } from "../../Chemistry/One/Draggy";
import {
  setAudioAvater,

} from "../../../utils/videoPlayThings/updateVideosTimeHelper";
import { Scene } from "./SceneBar/Scene";
import { ScenePlay } from "./SceneBar/ScenePlay";
import { discardCurrentWork } from "../../../utils/useFullFunctions/discardWork";
import { loadVideo } from "../../LeftPanel/components/Video/functions/createVideoElement";


document.arrowState = {};

export async function loadSceneAnimationData(sceneData) {
  let allScene = JSON.parse(sceneData.currentScene.arr);
  const allLoadingAsset = [];
  allScene.forEach((sceneIndex) => {
    let _loadAsset = sceneData[sceneIndex].sanimation?.animationData?.loadAsset;
    if (_loadAsset) {
      _loadAsset.forEach((_elm) => {
        allLoadingAsset.push(loadVideo(_elm.url, _elm.id, _elm.id));
      });
    }
  });
  await Promise.all(allLoadingAsset);
}

const mptos = (state) => {
  return {

    sceneArr: state.scenes.currentScene.arr,
    sceneDisabled: state?.disable?.scene,
  };
};

const mftos = (dispatch) => {
  return {
    addNewScene: (data) => dispatch({ type: "ADD_SCENE", data: data }),
    active: (index) => dispatch({ type: "SCENE_ACTIVE", data: index }),
  };
};
const CustomArrow = ({ src, type, endLen }) => {
  const [a, setA] = useState(false);
  useEffect(() => {
    document.arrowState[type] = setA;
  }, []);
  const handleScroll = () => {
    let list = document?.querySelector(".SceneBar_list") || {};
    if (type === "left") {
      list.scrollLeft -= 150;
    } else {
      list.scrollLeft += 150;
    }
  };
  return (
    <div className="CustomArrow">
      <img
        className="CustomArrow_img"
        style={{ display: a ? "block" : "none" }}
        src={src}
        onClick={handleScroll}
      />
    </div>
  );
};

let Item = memo(
  SortableElement(
    memo(({ _index, number, key, index, onClick, isActive, total }) => {
      // console.log("Hey");
      return (
        <Scene

          number={number}
          key={_index}
          // isShow={e === -1}
          index={_index}
          onClick={onClick}
          isActive={isActive}
          total={total}
        />
      );
    })
  )
);

export default connect(
  mptos,
  mftos
)(function SceneBar({

  sceneArr,
  addNewScene,
  active,
  sceneDisabled,
}) {
  sceneArr = JSON.parse(sceneArr)

  const hist = useHistory();
  const activeScroll = (current = null) => {
    try {
      let a = document?.querySelector(".SceneBar_list");

      let b = current || document?.querySelector(".SceneBar_Scene_active");
      let { left: aLeft, right: aRight } = a.getBoundingClientRect();
      let { left: bLeft, right: bRight } = b.getBoundingClientRect();
      let left = bLeft - aLeft;
      let right = aRight - bRight;
      if (left < 0) {
        a.scrollLeft += left - 20;
      } else if (right < 0) {
        a.scrollLeft -= right - 10;
      }
    } catch (e) {
      console.log("err", e);
    }

  };
  // useEffect(() => {
  //   console.log(`$ scene add`);
  //   return () => {
  //     console.log(`$ scene remove`);
  //   }
  // }, [sceneArr);
  document.activeScroll = activeScroll;
  const handleActive = (i) => {
    return async () => {
      const _isSceneDraw = document.screenChheda;

      document.outPlayMode();
      // await removeBlur()

      setTimeout(() => {
        discardCurrentWork();


        // set element as active
        const _allElm = document.cxx[i].getObjects().filter(e => e._Type === 'elements')
        _allElm.map((obj) => { document.cxx[i].setActiveObject(obj); document.cxx[i].renderAll(); })
        document.cxx[i].discardActiveObject();


        active(i);
        handler();
        let pre = Index();

        document.cxx[pre].discardActiveObject();

        document.hist.push(Jump({ obj: {}, def: true, index: i }))
        activeScroll();
        updateTimeLen(false, false, i);
        UpdateBackground();
        setAudioAvater();
        document.setS[i](Number(document._Max / document.per).toFixed(2))

        let scenes = store.getState().scenes;
        if (1) {

          MoveSeekTo({ position: "center", updateVideo: true, index: i })

        }
        if (i !== pre) {
          // let preAv = scenes[pre].elements[`avatar${pre}`];
          // if (preAv) {


          //   const { stayTime, enterDelay, enter } =
          //     preAv;
          //   customSeek(enterDelay + stayTime / 2, pre);

          //   // document.smallScene.make(pre, false);
          //   // document.smallScene.update(pre);

          // }

          MoveSeekTo({ position: "center", updateVideo: true, index: pre, seekMove: "no" })
          // customSeek(enterDelay + stayTime / 2, pre);
          if (_isSceneDraw) {
            document.smallScene.draw(pre, false);
          }


        }
        document.functionQueue.run({})
      }, 0);
    };
  };
  document.handleActive = handleActive;
  let handler = () => {
    if (document.screenChheda) {
    } else {
      document.outPlayMode();
    }
  };
  const videoPlayInit = async () => {
    // load asset
    // let _storeSceneData = store.getState().scenes;
    // await loadSceneAnimationData(_storeSceneData);
    universalPlay({ videID: "", havePause: true });
  };

  const settings = {
    // dots: true,
    className: "Slider_Scene",
    // infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    swipeToSlide: true,
    // variableWidth: true,
  };
  function scrolling(scroller) {
    if (scroller)
      return Math.floor(
        (scroller.scrollLeft / (scroller.scrollWidth - scroller.clientWidth)) *
        100
      );
    // return percent;
  }
  const [scper, setScPer] = useState(0);
  const handleScroll = (e) => {
    // e.prevetDefault();
    // console.log(document.querySelector(".SceneBar_list"));
    // document.querySelector(".SceneBar_list").addEventListener("wheel", (e) => {
    // setScPer(scrolling(e.target));

    let k = scrolling(e.target);
    let s = e.target.scrollLeft;
    document.scrollLeftSceneList = s;
    // console.log(k);
    if (k < 10) {
      document.arrowState["left"](false);
      document.arrowState["right"](true);
    }
    if (k > 90 && k <= 100) {
      // calc(100% - 490px);
      document.arrowState["left"](true);
      document.arrowState["right"](false);
    }
    if (k > 10 && k < 90) {
      document.arrowState["left"](true);
      document.arrowState["right"](true);
    }

    if (e.target.scrollWidth === e.target.clientWidth) {
      document.arrowState["left"](false);
      document.arrowState["right"](false);
    }

    // });
  };
  document.handleScroll = handleScroll;

  useEffect(() => {
    handleScroll({ target: document?.querySelector(".SceneBar_list") });
  }, []);
  useEffect(() => {
    // handleScroll({ target: document?.querySelector(".SceneBar_list") });

    let list = document?.querySelector(".SceneBar_list") || {};
    list.scrollLeft = document.scrollLeftSceneList;
  });

  const handleList = () => {
    document.outPlayMode();
    document.cxx[Index()].discardActiveObject();
    store.dispatch({
      type: "SET_ACTIVE_OBJECT",
      data: "",
    });
  };
  let List = memo(
    SortableContainer(({ items, styles }) => {
      return (
        <>
          <div
            onScroll={handleScroll}
            className="SceneBar_list flex row "
            onClick={handleList}
            style={styles}
          >
            {/* <Slider className="Slider_Scene flex row" settings={settings}> */}
            {items.map((e, i) => {
              return (
                <React.Fragment key={e}>
                  <Item
                    number={e + 1}
                    key={e}
                    // isShow={e === -1}
                    _index={i + 1}
                    index={i}
                    onClick={(k) => {
                      handleActive(e)(k)
                      // setTimeout(() => {
                      //   handleActive(e)(k)

                      // }, 0)
                    }}
                    isActive={e === Index()}
                    total={items.length}
                  />
                  {sceneArr.length === i + 1 || <TwoSceneAnimation index={e} />}
                </React.Fragment>
              );
            })}
            {/* </Slider> */}
          </div>
        </>
      );
    })
  );

  const handleSort = (data) => {
    handleCursor("default")();
    store.dispatch({ type: "SCENE_SORT", data });
    setTimeout(() => {
      updateTimeLen(false, false);
    });
  };
  const handleCursor = (st) => {
    return () => (document.body.style.cursor = st);
  };
  // const [gayab, setGayab] = useState(false)
  useEffect(() => {
    let aka = document.querySelector(".SceneBar_list");
    if (sceneArr.length >= SCENE_LIMIT) {
      aka.style.maxWidth = "calc(100% - 200px)";
      // setGayab(true)
    } else {
      // setGayab(false)
      aka.style.maxWidth = "calc(100% - 350px)";
    }
  });

  const handleSceneAdd = (e) => {
    document.outPlayMode()

    setTimeout(() => {
      if (true) {
        discardCurrentWork();
        gsap.from(document.querySelector(".SceneBar_add"), { x: -90 });
        let pre = Index()
        let next = nextSceneNumber();
        addNewScene({ index: sceneArr.length, number: next });

        handleScroll({
          target: document?.querySelector(".SceneBar_list"),
        });
        active(next);

        hist.push(`/${document.editorType}/${Vid()}/${next}/avatar`);

        setTimeout(() => {
          addAvatarToScreenFirstTime({ index: next });
          setTimeout(async () => {
            UpdateBackground();
            await document.smallScene.draw(pre);
            await document.smallScene.draw(next);
          }, 300);
        }, 0);
        setTimeout(() => {
          activeScroll();
        }, 0);
        // }
      }
    }, 0)
  };
  return (
    <div className="SceneBar">
      <TimeLineClose />
      <VerticalText text="Preview" />
      <ScenePlay onPlay={videoPlayInit} />
      <VerticalText text="Scene" className="VerticalText_Scene" />
      {/* <img src={`${document.PUBLIC_URL}/ico4/leftS.svg`}  /> */}

      <CustomArrow src={`${document.PUBLIC_URL}/ico4/leftS.svg`} type="left" endLen={sceneArr.length} />
      {/* <DisabledWrapper isDisable={sceneDisabled}> */}
      <List
        lockOffset={"0%"}
        items={sceneArr}
        axis="x"
        lockAxis="x"
        distance={1}
        onSortEnd={handleSort}
        onSortStart={handleCursor("grabbing")}
        lockToContainerEdges={true}
      />{" "}
      {/* </DisabledWrapper> */}
      {/* <img src={`${document.PUBLIC_URL}/ico4/rightS.svg`}  /> */}
      <CustomArrow
        src={`${document.PUBLIC_URL}/ico4/rightS.svg`}
        type="right"
        endLen={sceneArr.length}
      />
      {sceneArr.length < SCENE_LIMIT && <SceneAdd onClick={handleSceneAdd} />}
      {/* <TimeZoom /> */}
    </div>
  );
});

const SceneAdd = ({ onClick }) => {
  return (
    <div onClick={onClick} className="SceneBar_Scene SceneBar_add">
      <img src={`${document.PUBLIC_URL}/ico/addScript.svg`} alt="addscene" />
    </div>
  );
};
export const perSecFind = (pix) =>
  (0.8 * ((pix % 40) + 10)) / (2 * Math.pow(5, 1 - Math.floor(pix / 40)));

const VerticalText = ({ text, className = "" }) => {
  return <div className={`VerticalText ${className}`}>{text}</div>;
};
document.per = TIME_PER_SEC;
