/* eslint-disable */
import gsap from "gsap";
import store from "../store/store";
import { intialSceneSetup, parseSceneData } from "./shortcutFunction";

const DOMTimeLine = {
  tweens: {
    // "1234-123-1233": {enter:xyz, exit:abc}
    // xyz:{animation:type, duration:344, delay:343, tween:tween}
  },
  sweens: {},
  timeLine: { temp: {} },
  screenLine: {},
  addScreen: function ({ start, end, where, durationStart, durationEnd }) {
    // start:{from:{...}, to:{...}}
    // end:{from:{...}, to:{...}}
    try {
      delete this.screenLine[where];
      this.screenLine[where] = gsap.timeline({ paused: true });
      let arr = JSON.parse(store.getState().scenes.currentScene.arr);
      let startIndex = arr.findIndex((e) => e === where);
      let next = arr[startIndex + 1];
      this.sweens[where] = { start, end, durationStart };
      let gets = (k) => document.getElementById(`screen${k}`);
      if (gets(next) && gets(where)) {
        this.screenLine[where].fromTo(
          gets(where),
          start.from,
          { ...start.to, duration: durationStart },
          "RocketRoll"
        );
        this.screenLine[where].fromTo(
          gets(next),
          end.from,
          { ...end.to, duration: durationEnd },
          "RocketRoll"
        );
      }
    } catch (err) {
      console.error(err);
    }
  },

  init: function (index) {
    this.timeLine[index] = gsap.timeline({ paused: true });
    this.screenLine[index] = gsap.timeline({ paused: true });
    this.tweens[index] = {};
    document.txx = this;
  },

  makeTween: function ({
    what,
    obj,
    delay,
    duration,
    animationFrom = {},
    animationTo = {},
    where,
  }) {
    let _animation = { from: animationFrom, to: animationTo };

    return this.timeLine[where][what](
      obj,
      {
        ..._animation.from,
      },
      {
        ..._animation.to,
        duration,
        delay,
      },
      "alphaGO"
    );
    // Tween.fromTo(obj, asfasf,asfas)
  },
  makeTweenRow: function ({
    obj,
    duration,
    delay,
    animationFrom = {},
    animationTo = {},
    what,
    where,
  }) {
    return {
      tween: this.makeTween({
        obj,
        duration,
        delay,
        animationFrom: animationFrom || {},
        animationTo: animationTo || {},
        what,
        where,
      }),
      delay,
      duration,

      animation: { from: animationFrom, to: animationTo },

      obj,
    };
  },

  add: function ({
    obj,
    enterDuration,
    exitDuration,
    enterDelay,
    stayTime,
    where,
    animationEnter = {},
    animationExit = {},
    animationPlace = {},
  }) {
    if (obj) {
      intialSceneSetup(obj._Animation?.["enter"]?.animationData?.init || [], {
        object: obj,
      });
      let _enterAnimation = parseSceneData(obj, {
        from: animationEnter.from,
        to: animationEnter.to,
      });
      intialSceneSetup(obj._Animation?.["exit"]?.animationData?.init || [], {
        object: obj,
      });
      let _exitAnimation = parseSceneData(obj, {
        from: animationExit.from,
        to: animationExit.to,
      });

      intialSceneSetup(obj._Animation?.["place"]?.animationData?.init || [], {
        object: obj,
      });
      let _placeAnimation = parseSceneData(obj, {
        from: animationPlace.from,
        to: animationPlace.to,
      });

      let enter = this.makeTweenRow({
        obj,
        duration: enterDuration,
        delay: enterDelay,
        what: "fromTo",
        animationFrom: _enterAnimation.from,
        animationTo: { ..._enterAnimation.to },
        where,
      });

      let place = this.makeTweenRow({
        obj,
        duration: stayTime - enterDuration - exitDuration,
        delay: enterDelay + enterDuration,
        what: "fromTo",
        animationFrom: { ..._placeAnimation.from },
        animationTo: _placeAnimation.to,
        where,
      });

      let exit = this.makeTweenRow({
        obj,
        duration: exitDuration,
        delay: stayTime + enterDelay - exitDuration,
        what: "fromTo",
        animationFrom: { ..._exitAnimation.from },
        animationTo: _exitAnimation.to,
        where,
      });

      this.tweens[where][obj.id] = { exit, enter, place };
    }
  },
  threeNumber: (p) => Number(Number(p).toPrecision(3)),
  updateTime: function ({
    obj,
    enterDuration,
    exitDuration,
    enterDelay,
    stayTime,
    where,
  }) {
    let p = this.tweens[where]?.[obj.id];
    if (p) {
      p.enter.duration = this.threeNumber(enterDuration);
      p.enter.delay = this.threeNumber(enterDelay);
      p.exit.delay = this.threeNumber(stayTime + enterDelay - exitDuration);
      p.exit.duration = this.threeNumber(exitDuration);

      p.place.delay = this.threeNumber(enterDelay + enterDuration);
      p.place.duration = this.threeNumber(
        stayTime - enterDuration - exitDuration
      );
    }

    delete this.timeLine[where];
    this.timeLine[where] = gsap.timeline({ paused: true });
    Object.keys(this.tweens[where]).forEach((e) => {
      let g = this.tweens[where][e];
      this.add({
        obj: g.enter.obj,
        enterDuration: g.enter.duration,
        enterDelay: g.enter.delay,
        exitDuration: g.exit.duration,
        stayTime: stayTime,
        where,
        animationEnter: g.enter.animation,
        animationExit: g.exit.animation,
        animationPlace: g.place.animation,
      });
    });
  },
};
// //console.log(gsap);

export default DOMTimeLine;
