import avatar from "./avatar.svg";
import background from "./background.svg";
import clone from "./clone.svg";
import _delete from "./delete.svg";
import drag from "./drag.svg";
import hide from "./hide.svg";
import mute from "./mute.svg";
import text from "./text.svg";
import unhide from "./unhide.svg";
import unmute from "./unmute.svg";
import video from "./video.svg";

const I = {
  avatar,
  background,
  clone,
  _delete,
  drag,
  hide,
  mute,
  text,
  unhide,
  unmute,
  video,
};
export default I;
