import React from "react";
import Popup from "../Popup";
import Model from "reactjs-popup";
import "./_KK.scss";
import { useEffect } from "react";
import Papa from "papaparse"
import { useState } from "react";
export default function TablePopup({ trigger, open, onOpen, media = {} }) {
  const [csv, setCSV] = useState({ headers: [], table: [] })
  const [load, setLoad] = useState(true)
  useEffect(() => {
    if (open) {
      Papa.parse(media.media_thumbnail, {
        worker: true,
        header: true,
        download: true,
        complete: (res) => {

          setCSV({ headers: res.meta.fields, table: res.data })
          setLoad(false)
        }
      })
    } else {
      setCSV({ headers: [], table: [] })
      setLoad(true)

    }

  }, [open])


  return (
    <Model modal open={open} onClose={() => {
      onOpen(false)

    }}>
      {(close) => (
        <Popup close={close} name={media.name} haveButton={false}>
          {load && <div className="INFINITELOAD">
            <img alt="Loading" src={`${document.PUBLIC_URL}/icons/loading.gif`} />
          </div>}
          <div className="TablePopup">
            <div className="TablePopup_column">

              {csv.headers.map((e, i) => (
                <div className="TablePopup_column_each" key={e}>{e}</div>
              ))}
            </div>
            <div className="TablePopup_data">
              {csv.table.map((e, i) => {
                return <TableRow key={i} id={i} column={csv.headers} data={e} />;
              })}
            </div>
          </div>
        </Popup>
      )}
    </Model>
  );
}

export const TableRow = ({ data, column, id }) => {
  return (
    <div className="TableRow">
      {/* <div className="TableRow_index">{id}</div> */}
      {column.map((e, i) => {
        return (
          <div id={`table${i}`} className="TableRow_each" key={e}>
            {data[e]}
          </div>
        );
      })}
    </div>
  );
};

