/* eslint-disable */
import watch from "redux-watch";
import store from "../store/store";
import { create } from "jsondiffpatch";
import { fetcher, Index, url, Vid } from "../utils";

//save dom init here SAVE_DOM_INIT
document.Save = {
  save: false,
  storeCreated: false,
  changeSave: function (con) {
    this.save = con;
    if (!con) {
      this.storeCreated = true;
      this.saveState();
    }
  },
  /**
   *
   * @returns {bool} retrun after the save
   */
  toggleSave: function () {
    this.changeSave(!this.save);
    return this.save;
  },
  saveState: function () {
    this.state = store.getState().scenes;
  },
  // unstabled
  forceSave: function () {
    let cxx = document.cxx[Index()];
    cxx.fire("object:modified");
  },

  changeOld: function (oldVal) {
    if (this.storeCreated) {
      this.storeCreated = false;
      return this.state;
    } else {
      return oldVal;
    }
  },
};

document.requestManager = {
  arr: [],
  oldState: {},
  lock: false,
  have: false,
  saveBtn: { style: {} },
  init: function requestManagerInit(time = 1000) {
    this.saveBtn = document.querySelector(".RightHeader_save");

    try {
      document
        .querySelector(".Media")
        .querySelectorAll("audio")
        .forEach((e) => e.pause());
    } catch (err) {
      console.log(err);
    }

    setInterval(() => {
      if (this.lock) {
      } else {
        //calucate diff;
        let newState = this.arr[this.arr.length - 1];
        let diff = JSON.stringify(_diff.diff(this.oldState, newState));
        if (diff && newState) {
          // console.log(diff);
          this.arr = [];
          let k = document.querySelector(".RightHeader_save");
          if (k) {
            this.saveBtn = k;
            this.saveBtn.style.opacity = 0.3;
          }
          this.lock = true;
          document.saveFull.two();

          fetcher({
            method: "PUT",
            url:
              document.editorType === "video"
                ? `${url}/api/newvideo/video/${Vid()}/`
                : `${url}/api/newimage/image/${Vid()}/`,
            data: {
              d: diff,
            },
            res: (data) => {
              this.oldState = newState;
              this.lock = false;
              let k = document.querySelector(".RightHeader_save");
              if (k) {
                this.saveBtn = k;
                this.saveBtn.style.opacity = 1;
              }
              document.saveFull.three();
            },
            err: (err) => {
              document.saveFull.three();

              this.lock = false;
            },
          });
        }
      }
    }, time);
  },

  addToQueue: function requestManagerQueueAdder(newState) {
    this.arr.push(newState);
    // alert("ADDED");
    let k = document.querySelector(".RightHeader_save");
    if (k) {
      this.saveBtn = k;
      this.saveBtn.style.opacity = 0.3;
    }
    document.saveFull.one();

    // console.log("ADDED", this.arr.length);
  },
  onlyOneTime: function (state, time) {
    if (!this.have) {
      this.addToQueue(state);
      this.init(time);
      this.have = true;
    }
  },
};

// document.requestManager.init(5000);

const _diff = create({
  objectHash: function (obj, index) {
    // try to find an id property, otherwise just use the index in the array
    return obj.name || obj.id || obj._id || "$$index:" + index;
  },
});
// assuming mySelector is a reselect selector defined somewhere
let w = watch(store.getState, "scenes");

store.subscribe(
  w((newVal, oldVal, objPath) => {
    try {
      // console.log("Bhar");

      if (document.Save.save) {
        // console.log("save ander");

        // let _data = _diff.diff(document.Save.changeOld(oldVal), newVal);
        let RightHeader_save = document.querySelector(".RightHeader_save");
        if (RightHeader_save) {
          let p = Vid();
          if (p === "0") {
          } else {
            document.requestManager.addToQueue(newVal);
          }
        }
      }
    } catch (err) {
      document.querySelector(".RightHeader_save").style.opacity = 1;
      //console.log(err);
      console.log(err);
    }
  })
);
export default () => {};
