import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import store from "../../../../../../store/store";
import { getById, Index, setShapeSVGObjColor } from "../../../../../../utils";
import ButtonIcon from "../../../../../Chemistry/Atom/ButtonIcon";
import NewColor from "../../../../../Chemistry/Atom/NewColor";
import SlideContainer from "../../../../../Chemistry/Atom/SlideContainer";
import TitleRow from "../../../../../Chemistry/Atom/TitleRow";
import "./_ColorSetting.scss";
export default connect(
  (state) => ({
    colorArr: state.scenes.colorArr,
  }),
  (dispatch) => {
    return {
      EditColor: (colorArr) => {
        dispatch({ type: "EDIT_COLOR", data: colorArr });
      },
    };
  }
)(function ColorSettings({ EditColor, colorArr }) {
  const { state } = useLocation();
  useEffect(() => {
    let obj = getById(state.id);
    if (obj) {
    }
  }, [])

  const handlerBack = () => {
    document.hist.goBack();
    let obj = getById(state.id);
    if (obj) {
      if (obj._Type === "text") {
        let cxx = obj.canvas;
        if (cxx) {
          obj.set({ selectionColor: "rgba(17,119,255,0.3)" })

          cxx.discardActiveObject(obj);

          cxx.setActiveObject(obj);
          cxx.fire("object:modified");
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // useEffect(() => {
  //   console.log(state);
  //   // //console.log(document.handleFormat);
  // }, []);

  const handleChange = (col) => {
    col = col ? col : `#00000000`;
    if (state) {
      if (state.id) {
        let obj = getById(state.id);
        if (obj._Type === "text") {
          obj.set({ selectionColor: "rgba(17,119,255,0.0)", lastColor: col })

          // document.zx.lastText[state.property]
          document.handleFormat(col, state.property, true)();
        } else if (obj._Type === "elements") {
          if (obj._Data.type === "shapes" || obj._Data.type === "icons") {
            if (state.property === "fill") {
              // if (col) {
              setShapeSVGObjColor(obj, col);
              // }
            } else {
              obj.set({ [state.property]: col });
            }
          }
        }
        obj.canvas.renderAll();
        obj.canvas.fire("object:modified");
      }
    }
  };
  const handleLast = (col) => {
    // console.log(col);
    if (state) {
      if (state.id) {
        getById(state.id).canvas.fire("object:modified");
      }
      if (state.type === "sanimation") {
        store.dispatch({
          type: "SANIMATION_COLOR",
          data: {
            index: state.sindex,
            colorIndex: state.index,
            color: col,
          },
        });
      }
    }
  };

  const getDefaultColor = () => {
    const _obj = getById?.(state?.id);
    if (_obj) {
      if (_obj._Type === 'text') {
        return _obj.lastColor || _obj[state.property] || state?.color;
      }
      return _obj[state.property] || state?.color || "#ffffffff";
    }
    return state?.color || "#ffffffff";
  }

  return (
    <SlideContainer>
      <div className="ColorSettings">
        <TitleRow
          cursor="pointer"
          title={`< ${state?.title || "Color"}`}
          onClick={handlerBack}
        />

        <ButtonIcon
          // src={`${document.PUBLIC_URL}/app/odelete.svg`} 
          icon={<></>}
          src={null}
          value="Transparent"
          className="Transparent"
          onClick={() => {
            handleChange(null);
          }}
        />
        <NewColor
          arr={colorArr}
          setDefault={
            getDefaultColor()
          }
          onDelete={EditColor}
          onSelect={(e) => {
            handleChange(e);
            document.cxx[Index()].fire("object:modified");
          }}
          onAdd={EditColor}
          onChange={handleChange}
          handleLastChange={handleLast}

        />
      </div>
    </SlideContainer>
  );
});
