/* eslint-disable */
import info from "./ico/info.svg";
import image from "./ico/image.svg";
import profile from "./ico/profile.svg";
import logo from "./ico/logo.svg";
import text from "./ico/text.svg";
import website from "./ico/website.svg";
import undo from "./ico/undo.svg";
import redo from "./ico/redo.svg";
import save from "./ico/save.svg";
import close from "./ico/close.svg";
import artboard from "./ico/artboard.svg";
import lock from "./ico/lock.svg";
import lock2 from "./ico/lock2.svg";
import colorCheck from "./ico/colorCheck.svg";
import colorDrop from "./ico/colorDrop.svg";
import whiteDrop from "./ico/whiteDrop.svg";
import greyCheck from "./ico/greyCheck.svg";
import colorImage from "./ico/colorImage.svg";
import whiteImage from "./ico/whiteImage.svg";
import down from "./ico/down.svg";
import add from "./ico/add.svg";
import upload from "./ico/upload.svg";
import search from "./ico/search.svg";
import dummy from "./ico/dummy.png";
import refresh from "./ico/refresh.svg";
import down1 from "./ico/down1.svg";
import link from "./ico/link.svg";
import unlink from "./ico/unlink.svg";
import dragDots from "./ico/dragDots.svg";
import blank from "./ico/blank.svg";
import remove from "./ico/delete.svg";
import zindex from "./ico/zindex.svg";
import settings from "./ico/settings.svg";
import settingsActive from "./ico/settingsActive.svg";
import settingsNormal from "./ico/settingsNormal.svg";
import deleteNormal from "./ico/deleteNormal.svg";
import _Delete from "./ico/_Delete.svg";
import _SettingsActive from "./ico/_SettingsActive.svg";
import _SettingsNormal from "./ico/_SettingsNormal.svg";
import shape from "./ico/shape.svg";
import imageDefault from "./ico/imageDefault.png";
import logoImage from "./ico/logoImage.png";
import websiteImage from "./ico/websiteImage.png";
import profileImage from "./ico/profileImage.png";

import boldTextActive from "./ico/text/boldTextActive.svg";
import boldTextWhite from "./ico/text/boldTextWhite.svg";
import centerAlignActive from "./ico/text/centerAlignActive.svg";
import centerAlignWhite from "./ico/text/centerAlignWhite.svg";
import fontColorActive from "./ico/text/fontColorActive.svg";
import fontColorWhite from "./ico/text/fontColorWhite.svg";
import fontStrokeColorActive from "./ico/text/fontStrokeColorActive.svg";
import fontStrokeColorWhite from "./ico/text/fontStrokeColorWhite.svg";
import italicTextActive from "./ico/text/italicTextActive.svg";
import italicTextWhite from "./ico/text/italicTextWhite.svg";
import leftAlignActive from "./ico/text/leftAlignActive.svg";
import leftAlignWhite from "./ico/text/leftAlignWhite.svg";
import rightAlignActive from "./ico/text/rightAlignActive.svg";
import rightAlignWhite from "./ico/text/rightAlignWhite.svg";
import strikethroughTextActive from "./ico/text/strikethroughTextActive.svg";
import strikethroughTextWhite from "./ico/text/strikethroughTextWhite.svg";
import textBackgroundActive from "./ico/text/textBackgroundActive.svg";
import textBackgroundWhite from "./ico/text/textBackgroundWhite.svg";
import underlineTextActive from "./ico/text/underlineTextActive.svg";
import underlineTextWhite from "./ico/text/underlineTextWhite.svg";
import mtr from "./ico/mtr.png";

const textIcons = {
  mtr,
  boldTextActive,
  boldTextWhite,
  centerAlignActive,
  centerAlignWhite,
  fontColorActive,
  fontColorWhite,
  fontStrokeColorActive,
  fontStrokeColorWhite,
  italicTextActive,
  italicTextWhite,
  leftAlignActive,
  leftAlignWhite,
  rightAlignActive,
  rightAlignWhite,
  strikethroughTextActive,
  strikethroughTextWhite,
  textBackgroundActive,
  textBackgroundWhite,
  underlineTextActive,
  underlineTextWhite,
};

export default {
  ...textIcons,
  colorCheck,
  colorDrop,
  whiteDrop,
  greyCheck,
  colorImage,
  whiteImage,
  info,
  image,
  profile,
  logo,
  text,
  website,
  undo,
  save,
  close,
  artboard,
  lock,
  lock2,
  down,
  redo,
  add,
  upload,
  search,
  dummy,
  refresh,
  down1,
  link,
  unlink,
  dragDots,
  blank,
  remove,
  zindex,
  settings,
  settingsActive,
  settingsNormal,
  deleteNormal,
  _Delete,
  _SettingsActive,
  _SettingsNormal,
  shape,
  imageDefault,
  logoImage,
  websiteImage,
  profileImage,
};
