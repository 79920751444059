import React from 'react'
import Popup from "reactjs-popup";
import { ReactComponent as DeletePreview } from "../../extras/misc/DuplicateAndEdit.svg";
import { ReactComponent as Cross } from "../../extras/account/cross.svg";

export default function DuplciatePopup({ isConfirm, onOpen, text, onClickk }) {
    React.useEffect(() => {
        document
            .getElementById("bootstrap")
            .setAttribute("href", "/bootstrap.min.css");
        return () => {
            // document.getElementById("bootstrap").removeAttribute("href");
        };
    }, []);


    return (
        <Popup
            open={isConfirm}
            modal

            onClose={() => { onOpen(false) }}

        >
            {close => (

                <div className="modal-dialog modal-dialog-centered deletePop-detail-popup-video">
                    <div className="modal-content vd-modal-content">
                        <div className="modal-header vd-modal-header del-popup-heading">
                            <Cross
                                className="pointer"
                                onClick={() => {

                                    close();
                                }}

                            />
                        </div>
                        <div className="modal-body modal-avatar-select">


                            <div className="deletecamp-in-popup">
                                <div className="deletecamp-image">
                                    <DeletePreview />
                                </div>
                                <label className="deletecamp-lable-lg">You're about to Duplicate this {text}</label>
                                <label className="deletecamp-lable-sm">Are you sure you want to duplicate this {text}?</label>

                            </div>
                            <div>
                            </div>
                        </div>
                        <div className="modal-footer vd-modal-footer">
                            <span>
                                <button
                                    className="edit-duplicate-camp"
                                    onClick={() => {
                                        close()


                                    }}
                                >
                                    Cancel
                                </button>

                                <button
                                    type="button"
                                    className="vd-avatar-select-btn"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        onClickk()
                                        close();
                                    }}
                                >
                                    Duplicate & Edit
                                </button>


                            </span>
                        </div>
                    </div>
                </div>

            )}
        </Popup>
    );
}
