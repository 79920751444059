import React, { useEffect, useState } from "react";
import Popup from "../Popup";
import "./_EEE.scss";
import ButtonIcon from "../../../components/Chemistry/Atom/ButtonIcon";
import Modal from "reactjs-popup";
import Button from "../Button";

import { connect } from "react-redux";
import LabelInput from "../../../components/Chemistry/Atom/LabelInput";

import mystore, { vid } from "../../appStore";
import { fetcher, url } from "../../../utils";
import ProgressBar from "react-customizable-progressbar";
import InfiniteNewWrapper from "../infiniteNewWrapper";
import HowitWorks from "./HowItsWorking"
import { getDateFormatFileName } from "../../../components/Chemistry/Atom/popup/VideoRecording";


export default connect((state) => ({ my: state.mydetail, media: state.media }))(
  function DownloadPopup({
    my,
    isOpen,
    setOpen,
    name = "Generate Download Link",
    media,
  }) {
    useEffect(() => { }, []);
    const [tag, setTag] = useState({});
    const [load, setLoad] = useState(false);
    const [err, setErr] = useState({ text: "", have: false });
    const handleChange = (i) => {
      // setTag()
      return (e) => {
        setTag((k) => ({ ...k, [i]: e.target.value }));
      };
    };
    const handleGenerate = () => {
      setLoad(true);
      fetcher({
        method: "POST",
        url: `${url}/api/newvideo/video/generate/download/${my.id}/`,
        res: (res) => {
          const { isError, result } = res.data;
          setLoad(false);
          // console.log(res);
          if (isError) {
            setErr({ have: true, text: res.data.message });
            setTag({});
            setTimeout(() => {
              setErr({ have: false, text: res.data.message });
            }, 2000);
            // alert("SOMETING ERROR in TAG");
          } else {
            setTag({});

            mystore.dispatch({
              type: "MEDIA_WITH_FRONT",
              data: {
                type: "download_history",
                next: null,
                list: [result],
              },
            });
          }
        },
        err: (e) => {
          console.log(e);
        },
        data: {
          mergeTagData: tag,
        },
      });
    };

    return (
      <Modal
        modal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
      >
        {(close) => (
          <Popup
            name={name}
            close={close}
            haveButton={false}
            rightHeader={
              <>
                <Button className="ButtonIcon" onClick={() => window.open(my.link, "_blank")} value={<div>Live Preview</div>} />

                <HowitWorks trigger={<ButtonIcon value={<div>How it works?</div>} src={`${document.PUBLIC_URL}/app/up.svg`} />} />
              </>
            }
          >
            <div className="flex _column">
              <div className="LinkPopop DownloadPopup flex ">
                {err.have && <div className="Err">Error: {err.text}</div>}
                {/* <Lazzer l={true} /> */}
                {load && (
                  <div
                    className="ScreenLoading"
                  // style={{ display: l ? "flex" : "none" }}
                  >
                    <img src={`${document.PUBLIC_URL}/icons/Loading.svg`} alt="hey"></img>
                    <div className="Text">Generating...</div>
                  </div>
                )}
                <div className="DownloadPopup_merge">
                  {my.mergeTag.map((e, i) => {
                    return (
                      <div className="VariablePopup_flex" key={i}>
                        {/* <div className="VariablePopup_flex_label">{e}</div> */}
                        {/* <MiniInput label="hey" /> */}
                        <LabelInput
                          label={`${e[0]}`}
                          type={e[1]}
                          value={tag[e[0]]}
                          onChange={handleChange(e[0])}
                        />
                      </div>
                    );
                  })}
                </div>

                <Button
                  onClick={handleGenerate}
                  className=""
                  value={" Generate"}
                />
              </div>
              {my.downloadGenCount !== 0 && (
                <div className="PersonalisedHistory">
                  <div className="PersonalisedHistory_title">
                    Generated Download History
                  </div>
                  <div className="PersonalisedHistory_list" style={{ width: "45vw" }} id={"download_history_div"}>
                    <InfiniteNewWrapper target={"download_history_div"} type="download_history" mystore={mystore} url={media.download_history.next || `${url}/api/newvideo/video/generate/download/${vid()}/`}>
                      {media.download_history.list.map((e) => (
                        <LinkHistoryTab
                          key={e.id}
                          videoname={my.name}
                          date={e.timestamp}
                          url={e.video}
                          progress={e.progress}
                          status={e.status}
                          variableValue={my.mergeTag.map((k, i) => [
                            k[0],
                            e.mergeTagValue[i],
                          ])}
                        />
                      ))}
                    </InfiniteNewWrapper>
                  </div>
                </div>
              )}
            </div>
          </Popup>
        )}
      </Modal>
    );
  }
);

export const LinkHistoryTab = ({
  url,
  videoname,
  date = "2021-11-22T14:16:20.354895Z",
  variableValue = [["{{Home}}", "GOO"]],
  status = 3,
  progress = 50,
}) => {
  const [dd] = useState(new Date(date));
  const [open, setOpen] = useState(false);

  const onDownloadClick = (e, status, url) => {
    const _filename = `(Download) ${videoname} ${getDateFormatFileName(dd)}.mp4`;
    if (status === 1) {
      window.open(url + `?download=1&filename=${_filename}`, "_blank");
    }
  }

  return (
    <div className="LinkHistoryTab" style={{ width: "100%" }}>
      <div className="LinkHistoryTab_head flex acenter between">
        <div className="LinkHistoryTab_head_link">{url}</div>
        <div className="flex">
          <CopyLink
            url={url}
            haveLink={false}
            status={status}
            progress={progress}
            onCopy={onDownloadClick}
          // onCopy={}
          />
          <img
            onClick={() => setOpen((o) => !o)}
            className="pointer"
            style={{ transform: `rotateZ(${!open ? "0" : "180"}deg)` }}
            src={`${document.PUBLIC_URL}/app/downArrow.svg`}
            alt="downArrow"
          />
        </div>
      </div>
      {open && (
        <div className="LinkHistoryTab_body">
          <div className="LinkHistoryTab_body_date flex">
            <img src={`${document.PUBLIC_URL}/app/date.svg`} alt="date" /> {" " + dd.toDateString()}
          </div>
          <div className="LinkHistoryTab_body_value">
            {" Variables & its values"}
          </div>
          {variableValue.map((e) => {
            return (
              <div className="LinkHistoryTab_body_merge flex">
                <div className="LinkHistoryTab_body_merge_tag">{e[0]}:</div>
                <div className="LinkHistoryTab_body_merge_value">{e[1]}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};


export const TopCard = ({ title = "", name = "", src = "", have }) => {
  return (
    <div className="TopCard flex _column">
      <div className="TopCard_top flex  between acenter">
        <div className="flex _column ">
          <div className="TopCard_title">{title}</div>
          <div className="TopCard_name">{name}</div>
        </div>
        <img src={`${document.PUBLIC_URL}/app/tedit.svg`} alt="" />
      </div>
      <PerImage have={have} className="TopCard_img" src={src} alt="" />
    </div>
  );
};

export const PerImage = ({
  haveOption = null,
  have = false,
  src,
  className,
  isActive = false,
  onClick = () => { },
}) => {
  return (
    <div className={`PerImage`} onMouseEnter={(e) => { document.getElementById(`hoverable-btn-${src}`).style.display = "block" }} onMouseLeave={(e) => { document.getElementById(`hoverable-btn-${src}`).style.display = "none" }}>
      <div style={{ display: "none" }} id={`hoverable-btn-${src}`} className="PerImage_option">{haveOption || <></>}</div>

      <img
        className={`${className} ${isActive ? "PerImage_active" : ""}`}
        src={src}
        alt={src}
        onClick={onClick}
      />
      {have && <div className="PerImage_text">Personalised</div>}
    </div>
  );
};



// STATUS = (
//   (0,'ERROR'),

//   (2,'RUNNING'),
//   (3,'PENDING'),

//   (1,'COMPLETED'),
// )

export const CopyLink = ({
  url,
  id,
  onCopy = (e, status, url) => { },
  haveLink = true,
  text = "Download",
  haveIcon = true,
  status = 0,
  progress = 0,
  icon = `${document.PUBLIC_URL}/app/vdownload.svg`,
  extraIcon = null,
  extraIcon2 = null,
  style = {}
}) => {
  let statusMap = {
    0: "Error!",
    1: text,
    2: "Generating",
    3: "Pending",
  };

  return (
    <div
      className="CopyLink flex _column center"
      style={haveLink ? style : { ...style, margin: "0px", width: "unset" }}
    >
      {haveLink && <div className="CopyLink_link">{url}</div>}
      <div
        className="CopyLink_button flex"
        style={haveLink ? {} : { margin: "0px", width: "unset" }}
      >
        {extraIcon2}
        {extraIcon}

        <Button
          onClick={(e) => onCopy(e, status, url)}
          className="flex center"
          id={id}
          value={
            <>
              {statusMap[status]}
              {status === 3 && (
                <img
                  src={`${document.PUBLIC_URL}/icons/vLoading.svg`}
                  // style={{ marginLeft: "10px" }}
                  className="CopyLink_button_pending"
                  alt="23"
                />
              )}
              {status === 1 && (
                <img
                  src={icon}
                  style={{ marginLeft: "10px" }}
                  alt="donwload"
                />
              )}
              {status === 2 && (
                <ProgressBar
                  progress={progress - 5}
                  radius={10}
                  strokeColor="white"
                  strokeWidth={3}
                  trackStrokeWidth={4}
                  trackStrokeColor="rgba(0,0,0,0)"

                />
              )}
            </>
          }
        />
      </div>
    </div>
  );
};
