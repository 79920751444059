import { useMemo, useCallback, useEffect } from "react";
import Tabium from "../../../Chemistry/Compound/Tabium";
import "./_Background.scss";
import { connect } from "react-redux";


import NewColor from "../../../Chemistry/Atom/NewColor";

import { addBackground } from "./functions/changeBackground";
import { getById, Index } from "../../../../utils";

function Background({

  changeBackground,
  EditColor,
  colorArr,
  gradient,
}) {

  const handleColorChange = useCallback((color) => {

    addBackground.changeColor(color);

  }, []);


  // useEffect(() => {
  //   if (!getById(`background${Index()}`, Index())) {
  //     addBackground.insert(document.cxx[Index()]);
  //   }

  // }, []);
  const handelBackgroundChange = (type) => {
    return (e) => {
      addBackground.insertImage(
        { ...e, type },
        () => {
          changeBackground({
            type: type,
            current: e,
          });
        },
        true,
        true
      );

    };
  };
  const handleLastChange = () => {
    document.cxx[Index()].fire("object:modified");
  };


  return (
    <div className="Background">

      <NewColor
        arr={colorArr}
        onChange={handleColorChange}
        onSelect={(e) => {
          handleColorChange(e);
          document.cxx[Index()].fire("object:modified")
        }}
        gradientList={gradient.list}
        setDefault={
          getById(`background${Index()}`, Index())?.fill || "#ffffff"
        }
        onAdd={EditColor}

        onDelete={EditColor}
        onGradientSelect={handelBackgroundChange("color")}
        handleLastChange={handleLastChange}
      />
    </div>
  );
}
const mstop = (state) => {
  const {
    media: { video, stock, upload, gradient },
    scenes,
    currentScene,
  } = state;
  return {
    video,
    stock,
    upload,
    scenes,
    currentScene,
    gradient,
    colorArr: scenes.colorArr,
  };
};
const mftop = (dispatch) => {
  return {
    changeBackground: (selected) => {
      dispatch({ type: "CHANGE_BACKGROUND", data: selected });
    },
    EditColor: (colorArr) => {
      dispatch({ type: "EDIT_COLOR", data: colorArr });
    },
  };
};
export default connect(mstop, mftop)(Background);
