import {
    fabric
} from "fabric";


export const overlayApplyFilters = (fabObj, filters, _colors, apply = true) => {
    const allFilters = [];
    if (filters) {
        filters.forEach((_fil) => {
            let _ofill = {
                ..._fil
            };
            if (
                _colors &&
                _ofill.type === "ColorInterpolation" &&
                _ofill.tableValues
            ) {
                let _crntColor = _ofill.tableValues;
                if (_crntColor.r.length === _colors.length + 1) {
                    let _rC = _ofill.tableValues.r.slice();
                    let _gC = _ofill.tableValues.g.slice();
                    let _bC = _ofill.tableValues.b.slice();
                    for (let indx = 0; indx < _colors.length; indx++) {
                        try {
                            const _cColor = new fabric.Color(_colors[indx])._source;
                            _rC[indx + 1] = _cColor[0] / 255;
                            _gC[indx + 1] = _cColor[1] / 255;
                            _bC[indx + 1] = _cColor[2] / 255;
                        } catch (err) {
                            console.log("Scene Animation Filter Color Change issue: ", err);
                        }
                    }
                    _ofill.tableValues = {
                        r: _rC,
                        g: _gC,
                        b: _bC,
                        a: _ofill.tableValues.a,
                    };
                }
            }
            allFilters.push(new fabric.Image.filters[_fil.type]({
                ..._ofill
            }));
        });
        fabObj.filters = allFilters;
        if (apply) {
            fabObj.applyFilters();
        }
    }
}