import React, { useState } from "react";
import ImageScroll from "../../../Chemistry/Compound/ImageScroll";
import Tabium from "../../../Chemistry/Compound/Tabium";
import UploadScroll from "../../../Chemistry/Compound/UploadScroll";
import "./_Image.scss";
import { fabric } from "fabric";
import {
  fitCenterToCXX,
  getById,
  Index,
} from "../../../../utils";
import { useLocation } from "react-router";
import uuid from "uuid-random";
import store from "../../../../store/store";
import { InitAddObjectEvents } from "../../../../utils/InitAddObjectEvents";
import { SIZES } from "../../../../utils/config";
import { askConvertedVideo } from "../../../../utils/videoPlayThings/addVideoToCanvasAndStore";
import { replaceObj } from "../../../../utils/replaceImage";
import Model from "reactjs-popup";

// fitCenterToCXX

export default function Image() {
  const { state } = useLocation();
  const handleSelect = async (e) => {
    let id = uuid();
    const _index = Index();
    const { cxx } = document;

    if (state?.tab === undefined) {
      store.dispatch({ type: "LOADING", data: "Image is Loading ..." });
      store.dispatch({
        type: "SCENE_DISABLED",
        data: true,
      });
      let _Image = e;

      let { res } = await askConvertedVideo(e.id, e.ctg, () => { }, "image", e);
      _Image = res;


      if (!res) {
        store.dispatch({ type: "LOADING", data: "Image is Loading ..." });
        return;
      };

      fabric.Image.fromURL(_Image.media_file, (obj) => {
        store.dispatch({ type: "LOADING", data: "Image is Loading ..." });
        let _crntSize = SIZES[document?._size || 0];
        let ratio = _crntSize.width / obj.width;

        obj.set({
          id,
          scaleX: ratio / 2,
          scaleY: ratio / 2,
          left: (_crntSize.width - (obj.width * ratio) / 2) / 2,
          top: (_crntSize.height - (obj.height * ratio) / 2) / 2,
        });
        fitCenterToCXX({ obj, posx: 0.2, percent: 90 })
        cxx[_index].add(obj);
        obj._Image = _Image;

        let _Name = document.duplicateName.getName({ name: e.name, type: "image" })
        if (res.id === 0) {
          obj._haveMerge = true;
          obj._Variable = "VariableImage"
          obj._RealVariable = true
        }
        cxx[_index].renderAll();
        InitAddObjectEvents({
          obj,
          _id: id,
          type: "image",
          name: _Name,
          returnToUrl: false,
          index: _index
        });

        store.dispatch({
          type: "SCENE_DISABLED",
          data: false,
        });

        // cxx[Index()].setActiveObject(obj);
      });
    } else {
      store.dispatch({ type: "LOADING", data: "Replacing the Image" });
      let obj = getById(state.id);
      askConvertedVideo(
        e.id,
        e.ctg,
        ({ res }) => {
          replaceObj(obj, res.media_file).then((obj) => {
            obj._Image = res;
            store.dispatch({ type: "LOADING", data: "Replacing the Image" });
            document.cxx[Index()].fire("object:modified")
          })
        },
        "image",
        e
      );
    }
  };
  const [mopen, setmOpen] = useState({ open: false, data: {} })

  return (
    <div className="ImageTab">
      <Tabium
        current={state?.tab || 0}
        arrTab={[{ name: "Stock Media" }, { name: "Uploads" }]}
      >
        <ImageScroll onPreview={setmOpen} onSelect={handleSelect} />
        <UploadScroll onPreview={setmOpen} onSelect={handleSelect} />
      </Tabium>

      <Model
        closeOnDocumentClick
        modal
        open={mopen.open}
        onClose={e => {
          setmOpen({ open: false, data: {} })
        }}
      >
        <div className="HoverImageCard_model">
          {mopen.data.ctype === "image" && <img src={mopen.data.media_file} alt="mone" />}
          {mopen.data.ctype === "video" && <video
            autoPlay
            muted
            controls
            controlsList="nodownload"
            src={mopen.data.media_file} alt="mone"
          />}
        </div>
      </Model>
    </div>
  );
}
