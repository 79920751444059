import { useState } from "react";

export const ScenePlay = ({ onPlay, onPause }) => {
  const [play, setPlay] = useState("pause");
  document.setPlay = setPlay;
  return (
    <div onClick={onPlay} className="SceneBar_Scene SceneBar_play">
      {play === "pause" && <img alt="playscene" src={`${document.PUBLIC_URL}/ico/playScene.svg`} />}
      {play === "play" && <img alt="playscene" src={`${document.PUBLIC_URL}/ico/previewPause.svg`} />}
      {play === "load" && (
        <img alt="playscene" className="Loading" src={`${document.PUBLIC_URL}/icons/Loading.svg`} />
      )}
    </div>
  );
};
