/* eslint-disable */
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
// import { url, token } from "../../../config/config";
import axios from "axios";
import { ReactComponent as Cross } from "../../../extras/account/cross.svg";
import DotsComp from "./manageTeamEs"
import Loadingg from "../../common/loading";
import { ReactComponent as InviteSend } from "../../../extras/account/invitesend.svg";
import { fetcher, url } from "../../../../utils";

export default function TeamManage(props) {
    const [Loading, setLoading] = useState(false);

    const [EmailErrors, setEmailErrors] = useState({
        status: false,
        msg: "",
        length: 0
    })

    const [Close, setClose] = useState(false)


    const [Org, setOrg] = useState([])





    const getData = async () => {
        setLoading(true)

        fetcher({
            url: `${url}/api/accounts/user/organization/`,
            method: "GET",
            res: res => {
                setOrg(res.data)
                setLoading(false)
            }
        })

    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleInvitation = async () => {
        var okEmail = validateEmail(document.getElementById("invitation-input").value)

        if (okEmail) {

            var email = { email: document.getElementById("invitation-input").value }


            // await axios.post(`${url}/api/accounts/user/organization/add/`, email, options)
            fetcher({
                method: "POST",
                data: email,
                url: `${url}/api/accounts/user/organization/add/`
            })
            setOrg([...Org, {
                first_name: "",
                email: email.email,
                last_login: "Invitation Pending!",
                totalProspect: null,
                engagementRate: null
            }])
            setEmailErrors({
                status: false,
                msg: "",
                length: 0,
            })

            document.getElementById("invitation-input").value = ""
        }
        else {
            setEmailErrors({
                ...EmailErrors,
                status: true,
                msg: "Email Invalid"
            })
        }

    }

    console.log(document.getElementById("invitation-input")?.value.length === 0);

    return (
        <Popup
            trigger={props.children}
            modal
            nested
            onOpen={async () => {
                getData()
                setClose(false)

            }}
        >
            {(close) => (

                <div className="modal-dialog modal-dialog-centered  analytic-detail-popup-video CAT ProfilePopupEdit" >
                    <div className="modal-content vd-modal-content">
                        <div className="modal-header vd-modal-header padding-40-popup">
                            <div className="header-analyticp-poopup">
                                <h5 className="modal-title-analutics">
                                    Manage Team
                                </h5>
                                <label htmlFor="" className="manageteam-lable-subheading">
                                    Enter your team member’s email Id and send invite.
                                </label>
                            </div>
                            {Close ? close() : null}
                            <div className="header-popup-analytics-rightside">
                                <Cross
                                    className="pointer"
                                    onClick={() => {
                                        close();
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-body modal-analytic-popup padding-40-popup pt-0" style={{ height: "68vh" }}>
                            <div className="chart-container flex-column">
                                {Loading ? (
                                    <Loadingg />
                                ) : (
                                    <div className="cat-list-container">
                                        <div className="manage-team-popup-wrapper">
                                            <div className="manage-team-popup-wrapper-header">
                                                <lable className="headingg">Email</lable>
                                                <span style={{ position: "relative", width: "100%", marginRight: "20px" }}><input type="email" name="invitation-input" id="invitation-input" onChange={(e) => {
                                                    if (e.target.value.length === 0) {
                                                        setEmailErrors({
                                                            ...EmailErrors,
                                                            length: 0,
                                                        })
                                                    }
                                                    else {
                                                        setEmailErrors({
                                                            ...EmailErrors,
                                                            length: e.target.value.length,
                                                        })
                                                    }
                                                }} className={`${EmailErrors.status ? "error-camp-input" : ""}`} /> {EmailErrors.status ? <span title="Invalid Email" style={{ position: "absolute", right: "0px", top: "0px" }} className="Icon-material-error">!</span> : null} </span>
                                                <button className={`btn-custom-manage-team ${EmailErrors.length === 0 ? "button-custom-disable" : ""}`} onClick={() => { handleInvitation() }}>Send Invite &nbsp;<InviteSend /></button>
                                            </div>
                                            <div className="manage-team-table">
                                                <div className="manage-team-th">

                                                    <div className="manage-team-thd" style={{ width: "190px" }}>
                                                        Email ID
                                                    </div>
                                                    <div className="manage-team-thd" style={{ width: "190px" }}>
                                                        Last Login
                                                    </div>
                                                    <div className="manage-team-thd" style={{ width: "150px" }}>
                                                        Name
                                                    </div>
                                                    <div className="manage-team-thd" style={{ width: "140px" }}>
                                                        Total Prospects
                                                    </div>
                                                    <div className="manage-team-thd" style={{ width: "148px" }}>
                                                        Engagement Rate
                                                    </div>
                                                    <div className="manage-team-thd" style={{ width: "25px", margin: "0" }} ></div>
                                                </div>
                                                {/* map the res info */}


                                                {Org.map((d, k) => (
                                                    <div className="manage-team-tr" key={k}>

                                                        <div className="manage-team-trd" style={{ width: "190px" }}>
                                                            {d.email}
                                                        </div>
                                                        <div className={`manage-team-trd ${d.first_name == null ? "manage-invitation-pending" : ""}`} style={{ width: "190px" }}>
                                                            {d.last_login}
                                                        </div>
                                                        <div className="manage-team-trd" style={{ width: "150px" }}>
                                                            {d.first_name} {d.org_is_admin ? "(Admin)" : ""}
                                                        </div>
                                                        <div className="manage-team-trd" style={{ width: "140px" }}>
                                                            {d.totalProspect}
                                                        </div>
                                                        <div className="manage-team-trd" style={{ width: "148px" }}>
                                                            {d.engagementRate}{d.engagementRate == null ? "" : "%"}
                                                        </div>
                                                        <div className="manage-team-thd" style={{ width: "25px", margin: "0" }} ><DotsComp adminRemoved={(e, val) => {
                                                            var ar = [...Org]
                                                            var objIndex = ar.findIndex((obj => obj.email == e));


                                                            ar[objIndex].org_is_admin = val

                                                            setOrg(ar)

                                                        }} memberRemoved={(email) => {
                                                            var ar = [...Org];
                                                            var myArray = ar.filter(function (obj) {
                                                                return obj.email !== email;
                                                            });

                                                            setOrg(myArray)

                                                        }} email={d.email} org_is_admin={d.org_is_admin} /></div>
                                                    </div>
                                                ))}


                                            </div>




                                        </div>
                                    </div>
                                )}


                            </div>
                        </div>

                    </div>
                </div>
            )}
        </Popup>
    );
}
