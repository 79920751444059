import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import store from "../../../store/store";
import { getById, Index, Jump, updateTimeLen, Vid } from "../../../utils";
import { MIN_STAYTIME, MIN_TIMELINE } from "../../../utils/config";
import { deleteFromAll } from "../../../utils/deleteCloneOpeationObj";
import { UpdateBackground } from "../One/Draggy";
import LabelInput from "./LabelInput";
import { removeMusicToAllCxx } from "../../../utils/videoPlayThings";
import ButtonIcon from "./ButtonIcon";
import { openFullscreen } from "../../../utils/fullScreen";

export default connect(
  (state) => ({
    list:
      document.cxx[state.sceneNumber]?._objects
        ?.filter((e) => e?._Type === "music")
        ?.map((e) => ({ id: e.id, name: e._Name })) || [],
    now: state.active.objActive,
    smode: state.scenes[state.sceneNumber]?.mode,
    haveEasyMode: state.scenes[state.sceneNumber]?.haveEasyMode,
    musicRemove: state.scenes.musicRemove
  })
)(function MusicContainer({
  list,
  now,
  smode,

  index,
  musicRemove
}) {
  const [scene, setS] = useState(
    Number(document._Max / document.per)
  );

  const [_alert, setAlert] = useState({ have: false, v: 3 });
  useEffect(() => {
    // UpdateBackground();
    let nSceneDuration = (k) => setS(Number(k))

    document.setS[index] = nSceneDuration;

  }, []);
  const handleBlur = (e) => {
    setS(e.target.value);
    let _v = Object.keys(document._Elements).map(e => document._Elements[e]).sort((a, b) => (-(a.enterDelay + a.enter + a.exit) + (b.enterDelay + b.enter + b.exit)))[0]

    let v = Math.max(_v.enterDelay + _v.enter + _v.exit, _v.enterDelay + MIN_STAYTIME, MIN_TIMELINE, Number(e.target.value));

    if (Number(e.target.value) < v) {
      setAlert({ have: true, say: `Can't be more than ${v} !` });
      if (Number(e.target.value) < 0) {
        setAlert({ have: true, say: `Can't be less than 0 !` });

      }
      setTimeout(() => {

        setAlert({ have: false, });


      }, 2000);
    } else {


      setS(Number(v));
    }
  };
  // let index = Index()
  let nowMax = document._Max / document.per

  const handleBlurDefalut = (e) => {
    let v = store.getState().scenes[Index()].elements[`avatar${Index()}`];
    if (e.target.value === "") {
      e.target.value = v.stayTime;
    }

    let fun = ({ store, MIN_TIMELINE, nowMax, index, scene, updateTimeLen }) => {
      store.dispatch({
        type: "WORKSPACE_RESIZE",
        data: {
          next: Math.max(MIN_TIMELINE, Number(scene)),
          now: nowMax,
          index
        },
      });
      // updateTimeLen();

    }
    document.functionQueue.add(fun, this, [{ store, MIN_TIMELINE, nowMax, index, scene, updateTimeLen }])
    setTimeout(() => {
      document.functionQueue.run({ from: "lastOne" })
    }, 500);
  };
  return (
    !musicRemove && <div
      className="flex row bottomWrapper"
    >
      <div className="MusicContainer">
        {list.map((e) => {
          return (
            <div
              className={`MusicContainer_music ${now === e.id ? "MusicContainer_music_active" : ""
                }`}
              onClick={(k) => {
                k.stopPropagation()
                const _target = k.target;
                if (_target && _target.className !== 'MusicContainer_music_close') {
                  setTimeout(() => {
                    let _obj = getById(e.id, null, document.cxx[Index()]);
                    if (_obj) {
                      document.preId = e.id;

                      document.hist.push(
                        Jump({
                          obj: _obj,
                        }),
                        { id: e.id }
                      );

                      setTimeout(function CxxEventsActive() {
                        store.dispatch({
                          type: "SET_ACTIVE_OBJECT",
                          data: _obj.id,
                        });
                      }, 0);
                    }
                  }, 10)
                }

              }}
            >
              <div className="MusicContainer_music_name">{e.name}</div>
              <img
                className="MusicContainer_music_img"
                alt="music"
                src={`${document.PUBLIC_URL}/ico/music.svg`}
              />
              {now === e.id && (
                <img
                  className="MusicContainer_music_close"
                  src={`${document.PUBLIC_URL}/ico/cancel.svg`}
                  alt=""
                  onClick={(k) => {
                    let obj = getById(e.id, null, document.cxx[Index()]);
                    if (obj) {
                      const _musicElm = store.getState().scenes[Index()].elements[e.id];
                      document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/${document._TabName}`);
                      if (_musicElm?.adjustLength === 1) {
                        document.cxx[Index()]?.discardActiveObject();
                        deleteFromAll(e.id, true);
                      } else {
                        document.cxx[Index()]?.discardActiveObject();
                        removeMusicToAllCxx({ id: e.id });
                        deleteFromAll(e.id, true);
                        delete document.musicLoop[e.id];
                      }
                    }
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="flex row acenter SceneDuration">
        {smode === 2 && document.editorType === "video" && (
          <LabelInput
            onChange={handleBlur}
            onBlur={handleBlurDefalut}
            type="number"
            value={String((scene))}
            min={Math.max(MIN_TIMELINE, document._maxEnterDelay + MIN_STAYTIME)}
            step={0.5}
            label={"Scene Duration"}
            onEnter={handleBlurDefalut}
            haveInc={true}
            width={"90px"}
            labelAfter={<div style={{ marginLeft: "5px", fontSize: "12px" }}>S</div>}
          />
        )}

        <ButtonIcon onClick={openFullscreen} className="ExpandButton" value={null} src={`${document.PUBLIC_URL}/icons/expand.svg`} />
      </div>

      {_alert.have && (
        <div className="SceneDurationAlert">{_alert.say}</div>
      )}
    </div>
  );
});
