import React from "react";
import { connect } from "react-redux";
import { Index } from "../../utils";
import { discardCurrentWork } from "../../utils/useFullFunctions/discardWork";
import DisabledWrapper from "../Chemistry/Atom/DisabledWrapper";
import SceneBar from "./Components/SceneBar";
import TimeBar from "./Components/TimeBar";
import "./_TimeLine.scss";
// import Resize from "react-resize-panel";

export default connect((state) => ({
  framActive: state.frameActive,
  haveEasyMode: state.scenes[Index()],
  sceneDisabled: state?.disable?.scene,
}))(function TimeLine({ framActive, sceneDisabled, haveEasyMode }) {
  const _handleOnClickSettings = (e) => {
    discardCurrentWork({ _goB: true });
  }
  return (
    <div className="TimeLine" onClick={_handleOnClickSettings}>
      <DisabledWrapper
        // setOnDisable={<h1>He</h1>}
        isDisable={sceneDisabled}
      >
        <SceneBar />
      </DisabledWrapper>
      {/* <DisabledWrapper isDisable={!haveEasyMode}> */}
      <TimeBar />
      {/* </DisabledWrapper> */}
    </div>
  );
});

const EasyModeWrapper = () => {
  return <div></div>;
};
