import React, { useCallback, useEffect, useRef, useState } from "react";
import "./_Atom.scss";
import uuid from "uuid-random";
export default function SelectOption({
  ele = null,
  onShow = () => { },
  list = [],

  onBodyClick = () => { },
  onSelect = () => { },
  hasFont = false,
  haveIcon = false,
  mustCloseOnSelect = true,
  secondClose = true
}) {


  const handleSelect = useCallback((sle, i) => {

    return (e) => {
      e.stopPropagation();

      onSelect(sle, i, e);
      if (mustCloseOnSelect) {
        setShow(false);
        onShow(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ref = useRef();
  const [isShow, setShow] = useState(false);
  const handleShow = useCallback((con) => {
    return (e) => {
      if (secondClose || con) {
        e.stopPropagation();

        setShow(con);
        onShow(con);
      }


    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const handle = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        //    handleChoose(_selected)()
        // if (isShow) {
        onShow(false);
        setShow(false);
        // }

      }
    };
    document.addEventListener("mousedown", handle);
    return () => {
      document.removeEventListener("mousedown", handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`SelectOption ${isShow ? "SelectOption_active" : ""}`}
      ref={ref}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <div className="SelectOption_title" onClick={handleShow(!isShow)}>
        {ele}
      </div>

      {isShow && (
        <div className="SelectOption_list">
          {list.map(
            (e, i) =>
              e.name === "Delete" ?
                e.isDefault ? <></> : e.name && (
                  <div
                    key={uuid()}
                    id={`_${uuid()}`}
                    onClick={handleSelect(e, i)}
                    className="SelectOption_list_item"
                    style={
                      hasFont ? { fontFamily: e.name, fontSize: "14px" } : {}
                    }
                  >
                    {(e.src) && (
                      <img
                        className="SelectOption_list_item_img"
                        src={e.src}
                        alt=""
                      />
                    )}
                    {e.name}
                  </div>
                )
                : e.name && (
                  <div
                    key={uuid()}
                    id={`_${uuid()}`}
                    onClick={handleSelect(e, i)}
                    className="SelectOption_list_item"
                    style={
                      hasFont ? { fontFamily: e.name, fontSize: "14px" } : {}
                    }
                  >
                    {(e.src) && (
                      <img
                        className="SelectOption_list_item_img"
                        src={e.src}
                        alt=""
                      />
                    )}
                    {e.name}
                  </div>
                )
          )}
        </div>
      )}
    </div>
  );
}


export function FontFamilySelectOption({
  ele = null,
  onShow = () => { },
  list = [],
  onSelect = () => { },
  mustCloseOnSelect = true,
  secondClose = true
}) {


  const handleSelect = useCallback((sle, i) => {

    return (e) => {
      e.stopPropagation();
      onSelect(sle, i, e);
      if (mustCloseOnSelect) {
        setShow(false);
        onShow(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ref = useRef();
  const [isShow, setShow] = useState(false);
  const handleShow = useCallback((con) => {
    return (e) => {
      if (secondClose || con) {
        e.stopPropagation();

        setShow(con);
        onShow(con);
      }


    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handle = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        //    handleChoose(_selected)()
        // if (isShow) {
        onShow(false);
        setShow(false);
        // }

      }
    };
    document.addEventListener("mousedown", handle);
    return () => {
      document.removeEventListener("mousedown", handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`SelectOption ${isShow ? "SelectOption_active" : ""}`}
      ref={ref}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <div className="SelectOption_title" onClick={handleShow(!isShow)}>
        {ele}
      </div>

      {isShow && (
        <div className="SelectOption_list" style={{ width: "300px" }}>
          {list.map(
            (e, i) =>
              e.name && (
                <div
                  key={uuid()}
                  id={`_${uuid()}`}
                  onClick={handleSelect(e, i)}
                  className="SelectOption_list_item font_hover"

                >
                  <img
                    className="SelectOption_list_item_img"
                    style={{ height: "14px" }}
                    src={`https://api.autogenerate.ai/media/fabricFonts/font-wprev/${e.name.replaceAll(' ', '')}-400.png`}
                    alt=""
                  />
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
}
