/* eslint-disable */

import { MoveSeekTo } from "../utils";
import "./config/functionQueue";
import "./config/initDocVar";
import "./config/duplicateName";
import "./config/smallScene";
import "./config/blobMedia";

export default () => { };

export const salespageURL = "https://app.autovid.ai/page";
// export const PUBLIC_URL = `https://storage.googleapis.com/app.autovid.ai`;
export const PUBLIC_URL = 'https://app.autovid.ai'; // `https://d10owimoxojc8q.cloudfront.net`;

document.PUBLIC_URL = PUBLIC_URL;

// Timeline Element Permission Map for Resizing and Dragging of TImeline
export const draggyType = {
  undefined: {
    resize: true,
    drag: false,
  },
  avatar: {
    resize: true,
    drag: false,
  },
  text: { resize: false },
  normal: {
    resize: false,
    drag: false,
  },
  image: {
    resize: false,
  },
  video: {
    resize: false,
  },
  elements: {
    resize: false,
    drag: false,
  },
  music: {
    resize: true,
    drag: true,
  },
  background: {
    resize: true,
    drag: true,
  },
};

export const SCENE_LIMIT = 15;

// Minimum Size Appreading Controls
export const MINW_CONTROL = 60;
export const MINH_CONTROL = 60;

// Max text length of Speech
export const MAX_TEXT_LENGTH = 999;

// TimeLine Varulabels
export const TIME_PER_SEC = 40; // Per Second Pixed
export const TIMELINE_STICK = 10; // Stickness of TImeline Stick
export const MIN_STAYTIME = 0.5; // Minimum Staytime of ELement in timeline in Sec
export const MIN_TIMELINE = 2; //minimum Timline Duration in Sec
export const MIN_ON_NON_AVATAR = 5; //minimum Timline Duration in non-avatar in Sec
export const API_URL = "https://app.autovid.ai";

// Fabric Object Export
export const cxxtoJSON = [
  "id",
  "_Position",
  "_Type",
  "_Name",
  "_originalUrl",
  "_AnimationID",
  "_Video",
  "_Src",
  "_Music",
  "_CurrentColor",
  "_Mask",
  "_Animation",
  "_Muted",
  "_Variable",
  "_Data",
  "_Effect",
  "_Volume",
  "_Hidden",
  "adjustLength",
  "_haveMerge",
  "_Editable",
  "_Image",
  "_Voice",
  "_RealVariable",
  "_SmallW",
  "_SmallH",
];

// Canvas Alignment Variables
export const LINE_OFFSET = 5;
export const LINE_MARGIN = 10;
export const LINE_WIDTH = 1.5;
export const LINE_COLOR = "#e7416a";
export const DEFAULT_FONT = "Arial";

// List of Fabric Fonts
export const FABRIC_FONTS = [
  "Arial",
  "Courier New",
  "Georgia",
  "Poppins",
  "Tahoma",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
];

// Supported Sized and its ratio
export const SIZES = {
  0: {
    width: 1920,
    height: 1080,
    ratio: 16 / 9,
    str_ratio: "16:9",
  },
  1: {
    width: 1920,
    height: 1920,
    ratio: 1 / 1,
    str_ratio: "1:1",
  },
  2: {
    width: 1080,
    height: 1920,
    ratio: 9 / 16,
    str_ratio: "9:16",
  },
};

export const NOWSIZE = document?._size || 0;

// if True then Take Data from offlineData.js, dont call for api
export const OFFLINEID = false;

export const getPadding = () => {
  return document.body.clientWidth < 1440 ? 0.9 : 0.8;
};

export const SAVE_TIME = 5000; // in minisec

export const defaultID = {
  animation: {
    enter: 5,
    exit: 5,
    place: 34,
  },
};
