/* eslint-disable */
import { UpdateBackground } from "../components/Chemistry/One/Draggy";
import store from "../store/store";
import { getById, Index, Jump, tryMe } from "../utils";
import { cxxtoJSON } from "./config";
import { putToStore } from "./useFullFunctions/undoRedoStateManager";
// import { putToStore } from "../components/Screen/Screen";
export default () => { };

export const deleteFromAll = async (id, notObj = false, index = Index()) => {
  tryMe(async () => {
    let obj = getById(
      id,
      null,
      notObj ? document.cxx[index] || { _objects: [] } : null
    );
    console.log(obj, id);

    if (obj) {
      let canvas = document.cxx[index];

      if (obj) {
        canvas.setActiveObject(obj);
        canvas.remove(obj);
        // canvas.renderAll();

        if (obj._Type === "music") {
          let ms = document.getElementById(obj.id);
          if (ms) {
            ms.remove();
          }
        }

        store.dispatch({
          type: "DELETE_OBJECT",
          data: { id: obj.id, type: obj._Type, index },
        });
        document.hist.push(Jump({ def: true, obj: {} }));
        canvas.fire("object:modified");
        await UpdateBackground();
      }
    }
  }, "deleteFromAll");
};
