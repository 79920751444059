import React, { useRef } from "react";
import "./_CSS.scss";
import Image from "react-image-shimmer";
import Playbutton from "./PopUp/Playbutton";
import { EllipseMe } from "../../utils";
export default function Card({
  src,
  playbutton,
  name = "Template Name",
  option = null,
  onSelect = () => { },
  isActive = false,
  isPer
}) {
  const ref = useRef();
  const handleSelect = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      onSelect();
    }
  };



  return (
    <div className="Card">
      <div
        className={`Card_img ${isActive ? "Card_active" : ""}`}
        onClick={handleSelect}
      >
        {isPer && <div className="Card_per">Personlized</div>}
        <Image
          className="Card_img_small"
          src={src}
          nativeImgProps={{ className: "Card_img_small" }}
          fallback={<img className="Card_img_small" src={`${document.PUBLIC_URL}/icons/loadingRect.gif`} alt="loading" />}
        />
        {/* {playbutton && <Playbutton />} */}
        <div className="Card_img_option" ref={ref}>
          {option}
        </div>
      </div>
      <div className="Card_name" title={`${name}`}>{EllipseMe(name, 42)}</div>
    </div>
  );
}
