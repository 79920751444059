/* eslint-disable */
// import Tabium from "../../../Chemistry/Compound/Tabium";
import "./_TabContainer.scss";
// import I from "../Tabs/Icons/";
// import ButtonIcon from "../../../Chemistry/Atom/ButtonIcon";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
// import { spring } from "react-router-transition";
import DisabledWrapper from "../../../Chemistry/Atom/DisabledWrapper";
import {
  fetcher,
  Index,
  Jump,
  nextSceneNumber,
  realNumber,
  url,
  Vid,
} from "../../../../utils";
import store from "../../../../store/store";
import Mask from "../../../Chemistry/One/Mask";
import ColorSettings from "../Settings/Components/Color/ColorSettings";
import ThreeVideo from "../../../Chemistry/One/ThreeVideo";
import LoadingWrapping from "../../../Chemistry/Atom/LoadingWrapping";
import { SIZES } from "../../../../utils/config";
import { useEffect, useState } from "react";

// import { useMemo } from "react";
export default function TabContainer({ currentTab, tabList }) {
  ////console.log("TabContainer Renders");

  const { state } = useLocation();

  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(false)
    setTimeout(() => {
      setShow(true)
    }, 0)
  }, [state?.id])

  return (
    // <DisabledWrapper isDisable={true}>
    <div className="TabContainer">
      <LoadingWrapping />

      <Switch>
        {tabList.map((r) => {
          return (
            <Route key="" path={`/${document.editorType}/:vid/:id/${r.name}`}>
              {r.ele}
            </Route>
          );
        })}
        <Route key="" path={`/${document.editorType}/:vid/:id/animation`}>
          <Mask
            title={<div style={{ marginBottom: "15px", width: "100px" }}>{"< Scene Transition"}</div>}
            type="sanimation"
            className="Animation SAnimation"
            normal={false}
            onSelect={(mask, i) => {
              //console.log(i);
              // const { color } = store.getState().scenes[Index()].sanimation;
            }}
            haveDuration={false}
            Container={ThreeVideo}
            myGoBack={() => {
              document.hist.push(Jump({ obj: {}, def: true }))
              store.dispatch({ type: "NOWSANIMATION", data: { index: "" } })

            }}
            onCleanUp={() => {
              store.dispatch({ type: "NOWSANIMATION", data: { index: "" } })

            }}
          />
        </Route>

        <Route key="" path={`/${document.editorType}/:vid/:id/color`}>
          {show && <ColorSettings />}
        </Route>

        <Route path="/create">
          {(k) => {
            fetcher({
              method: "POST",
              url: `${url}/api/newvideo/video/create/`,
              data: { d: JSON.stringify(store.getState().scenes) },
              res: (data) => {
                //console.log(data.data);
                // localStorage.setItem("VideoId", data.data.result.id);
                k.history.push(`/${document.editorType}/${data.data.result.id}/0/avatar`);
              },
            });
          }}
        </Route>
        <Route path={`/${document.editorType}/:vid/:id/settings/text`}>
          {(data) => {

            alert("go");
          }}
        </Route>


        <Route path={`/${document.editorType}/:vid/:id`} exact>
          {(data) => {

          }}
        </Route>

        <Route path="/video" exact>
          {(data) => {
            // alert("ehy")

            window.open(url, "_blank")
          }}
        </Route>
      </Switch>

      {/* <h1>{currentTab.toUpperCase()}</h1>
        <Tabium arrTab={[{name:"HOME",icon:I.settings}, {name:"HOME",icon:I.settings},{name:"HOME"}]}>
            
            <h1>Hey1</h1>
            <h1>Hey2</h1>
            <>
            <ButtonIcon value={"Hey"} icon={I.music}/>
            <ButtonIcon value={""} icon={I.music}/>
            </>
        </Tabium> */}
    </div>
    // </DisabledWrapper>
  );
}
