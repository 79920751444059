import { customVideoSeek } from "../../../../../utils/videoPlayThings";
import { display } from "../../../../../utils/videoPlayThings/addVideoToCanvasAndStore";


export const loadVideo = async (url, _videoId, id) => {
    return new Promise(async (resolve, reject) => {
        if (url) {
            const _blobUrl = await display(url, _videoId);
            var video = document.createElement("video");
            video.preload = "auto";
            video.crossOrigin = "anonymous";
            video.onloadeddata = function () {
                video.height = video.videoHeight;
                video.width = video.videoWidth;
                video.customVideoSeek = customVideoSeek;
                const Media = document.querySelector(".Media");
                if (Media) {
                    Media.appendChild(video);
                }
                if (id) {
                    video.id = id;
                }
                resolve(video);
            };
            /** @ignore */
            video.onerror = function () {
                resolve(null);
            };
            video.src = _blobUrl;
        } else {
            resolve(null);
        }
    });
};