import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch, useLocation } from "react-router";
import store from "../../../store/store";
import { fetchAndSave, Index } from "../../../utils";
import { onCancelClick } from "../../../utils/cropyThings";
import { LoadSAnimationVideoToDOM } from "../../../utils/videoPlayThings/TransistionPlayThings";
import ArrowWrapper from "../Atom/ArrowWrapper";
import LabelInput from "../Atom/LabelInput";
import SlideContainer from "../Atom/SlideContainer";
import ThreeCard from "../Atom/ThreeCard";
import TitleRow from "../Atom/TitleRow";
import Tabium from "../Compound/Tabium";
import "./_One.scss";

let Data = {
  mask: "_Mask",
  filter: "_Filter",
};

export default connect(
  (state) => ({
    media: state.media,
    sanimation: state.scenes,

  }),
  (dispatch) => ({
    setActive: (data) => dispatch({ type: "SET_ACTIVE", data }),
  })
)(function Mask({
  to,
  type = "mask",
  media,
  obj,
  title = "Mask",
  selectedIndex = 0,
  selected = {},
  onSelect = () => { },
  onBack = () => { },
  setActive,
  hasTab = false,
  normal = true,
  sanimation,

  Container = ThreeCard,
  className = "",
  haveDuration = true,
  myGoBack = null,
  onCleanUp = null
}) {
  const { state } = useLocation();

  const { url } = useRouteMatch();
  const history = useHistory();

  const [nowSel, setSel] = useState(
    normal ? obj?.[Data[type]] : sanimation[state.id].sanimation
  );

  // store.getState().media.mask.list
  useEffect(() => {
    if (obj) obj.setSel = setSel;
    store.dispatch({ type: "SET_FRAME", data: true });
    return () => {
      if (onCleanUp) {
        onCleanUp()
      }
    }
    // document.screenChheda = false;
  }, []);

  const [duration, setDuration] = useState(
    normal ? 0 : sanimation[state.id].sanimation?.duration || 1
  );

  const handlerBack = useCallback(() => {
    store.dispatch({ type: "SET_FRAME", data: false });
    // document.screenChheda = true;

    onCancelClick(null, null, null);
    if (myGoBack) {
      myGoBack()

    } else {

      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAndSave({ type });
  }, []);
  useEffect(() => {
    setSel(normal ? obj?.[Data[type]] : sanimation[state.id].sanimation);
  }, [state.id]);

  const handleSelect = (_obj) => {
    return (e) => {
      onSelect(_obj, state?.id);
      setSel(_obj);
      setActive({ type, ..._obj });
      if (normal) {
        obj[Data[type]] = _obj;
      } else {
        // e.stopPropagation();
        let a = _obj.animationData;
        let animationData = {
          ...a,
          start: { ...a.start, to: { ...a.start.to } },
          end: { ...a.end, to: { ...a.end.to } },
        };
        store.dispatch({
          type: "SANIMATION_ADD",
          data: {
            ..._obj,
            animationData,

            duration,
            index: state.id,
          },
        });
        LoadSAnimationVideoToDOM({ index: state.id, animationData });
      }
    };
  };

  return (
    <SlideContainer>
      <div className={`Mask ${className}`}>
        <TitleRow cursor="pointer" title={title} onClick={handlerBack} />

        <div className="Mask_list">
          {media[type].list.map((e) => (
            <Container
              onClick={handleSelect(e)}
              asrc={e.src}
              src={e.src}
              title={e.name}
              sample={e.sample}
              colors={e.colors}
              isActive={
                nowSel?.id === e.id || (!nowSel?.id && e.name === "None")
              }
              index={state.id}
            />
          ))}
        </div>
      </div>
      {type === "animation" ||
        (type !== "sanimation" && (
          <div className="Animation_duration">
            <LabelInput
              label="Duration"
              type="number"
              value={duration}
              onChange={(e) => {
                setDuration(Number(e.target.value));
              }}
            />
          </div>
        ))}
    </SlideContainer>
  );
});
