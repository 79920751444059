import React, { useEffect, useState } from "react";
// import Popup from "../Popup";
import Popup from "../Components/Popup";
// import "./_EEE.scss";

import "../Components/PopUp/_EEE.scss"

import Modal from "reactjs-popup";
// import Button from "../Button";


import { connect } from "react-redux";
// 
// import mystore, { vid } from "../../../appStore";
import Hr from "../../components/Chemistry/Atom/Hr";
import copy from "copy-to-clipboard";
import Button from "../Components/Button";
import mystore from "../appStore";
import { CopyLink } from "../Components/PopUp/DownloadPopup";
import MiniToggle from "../ImagePersonalised/Components/MiniComponents/MiniToggle";

import Toggle from "../../components/Chemistry/Atom/Toggle"
import InputIcon from "../../components/Chemistry/Atom/InputIcon";
import DisabledWrapper from "../../components/Chemistry/Atom/DisabledWrapper";
import { fetcher, url } from "../../utils";
import MailPopup from "../Components/PopUp/MailPopup";
import ButtonIcon from "../../components/Chemistry/Atom/ButtonIcon";


export default connect((state) => ({ my: state.mydetail, media: state.media, list: state.media.solo_history.list, next: state.media.solo_history.list }))(
    function LinkPopup({
        my,
        next,
        list,
        trigger = (
            <Button
                className={`${mystore?.getState().mydetail?.mainVideoGenerate?.status !== 1 ? "Disabled-button-generate-invarPopup" : ""}`}
                id={"_shareLinkBtn"}
                value={
                    <>
                        Share via Link <img src={`${document.PUBLIC_URL}/app/link.svg`} alt="button"></img>
                    </>
                }
            />
        ),
        name = "Share via Mail",
    }) {

        React.useEffect(() => {

            document.getElementById("bootstrap").removeAttribute("href");

        }, []);


        const copyMe = (data) => {
            // let _redirectUrl = document.getElementById('_redirect_url')?.value;
            // if (!_redirectUrl || !toggle) {
            //     _redirectUrl = "#"
            // }
            // const _copyValue = my.code.join(_redirectUrl);
            navigator.clipboard.writeText(my.code);
            copy(my.code)
            let oldText = document.getElementById(`copy-${my.id}`).innerHTML
            document.getElementById(`copy-${my.id}`).innerHTML = "Copied !";
            setTimeout(() => {
                document.getElementById(`copy-${my.id}`).innerHTML = oldText;
            }, 600)
            return data
        }

        const isToggleOn = (value) => {
            if (value) {
                return true;
            }
            return false
        }


        const [toggle, setToggle] = useState(isToggleOn(my.redirectUrl));
        const [redirectBtnActive, setRedirectBtnActive] = useState(false);
        document.redirectBtnActive = redirectBtnActive;

        useEffect(() => {
            setToggle(isToggleOn(my.redirectUrl))
        }, [my.redirectUrl])

        const handleToggle = () => {
            if (toggle) {
                if (my.redirectUrl) {
                    setRedirectBtnActive(true);
                }
            } else {
                const _redirectUrl = document.getElementById('_redirect_url')?.value
                if (my.redirectUrl === _redirectUrl) {
                    setRedirectBtnActive(false);
                }
            }
            setToggle(e => !e)
        }

        const onInputChange = (e) => {
            if (e !== my.redirectUrl) {
                if (!document.redirectBtnActive) {
                    setRedirectBtnActive(true)
                }
            } else {
                setRedirectBtnActive(false)
            }
        }

        const onApply = (e) => {
            e.target.innerHTML = "Loading..."
            let _redirectUrl = ""
            if (toggle) {
                _redirectUrl = document.getElementById('_redirect_url')?.value;
            }

            fetcher({
                method: "PUT",
                url: `${url}/api/newimage/image/details/${my.id}/`,
                data: { redirectUrl: _redirectUrl },
                res: (res) => {
                    mystore.dispatch({
                        type: "EDIT_DETAIL",
                        data: {
                            ...res.data.result,
                            redirectUrl: _redirectUrl
                        },
                    });
                    e.target.innerHTML = "Apply"
                    setRedirectBtnActive(false)
                },
            });
            setTimeout(() => {

            }, 1000)
        }
        console.log(redirectBtnActive, toggle)
        return (
            <Modal modal closeOnDocumentClick={true} trigger={<Button
                // className={`${mystore?.getState().mydetail?.mainVideoGenerate?.status !== 1 ? "Disabled-button-generate-invarPopup" : ""}`}
                id={"_shareLinkBtn"}
                value={
                    <>
                        Share via Mail <img src={`${document.PUBLIC_URL}/app/mail.svg`} alt="button"></img>
                    </>
                }
            />} nested  >
                {(close) => (
                    <Popup
                        UpperId="Linkpopup-1x1"
                        name={name}
                        close={close}
                        haveButton={false}
                        rightHeader={
                            <>
                                {/* <Button onClick={() => window.open(my.link, "_blank")} value={<div>Live Preview <img src={`${document.PUBLIC_URL}/app/up.svg`}></img></div>} className="ButtonIcon" /> */}

                                {/* <HowitWorks trigger={<ButtonIcon value={<div>How it works?</div>} icon=" " />} /> */}
                                <MailPopup
                                    trigger={
                                        <ButtonIcon
                                            value={<div>Email Client - {my.mailClient?.name}</div>}
                                            src={`${document.PUBLIC_URL}/app/mail.svg`}

                                        />
                                    }
                                    isVideo={false}

                                />
                            </>
                        }
                    >

                        <div className="x023 x024  flex _column">
                            <div className="x024 flex  center">
                                <div className="x024 flex _column "  >
                                    <div className="flex ">
                                        <div>
                                            Link it to landing page?
                                        </div>
                                        <Toggle toggle={toggle} onToggle={handleToggle} />

                                    </div>


                                    <div className="flex">
                                        <div className="flex">
                                            <DisabledWrapper isDisable={!toggle}>
                                                <InputIcon style={{ width: "285px", height: "35px" }} value={my.redirectUrl ? my.redirectUrl : ""} id="_redirect_url" onChange={onInputChange} onButtonActiveChange={redirectBtnActive} />
                                            </DisabledWrapper>
                                            <Button style={redirectBtnActive ? { marginLeft: "10px" } : { marginLeft: "10px", opacity: "0.3", cursor: "not-allowed" }} value="Apply" onClick={onApply} />
                                        </div>
                                        <CopyLink onCopy={copyMe} id={`copy-${my.id}`} url={typeof my.code === 'object' ? my.code?.join('#') : my.code} haveLink={false} text="Copy Code !" status={1} type="copy" icon={`${document.PUBLIC_URL}/app/copyLink.svg`} style={{ paddingLeft: "35px" }} />
                                    </div>





                                </div>

                            </div>
                            <div>
                            </div>
                        </div>


                    </Popup >
                )}
            </Modal >
        );
    });











