import { prevFromIndex } from "../../../../../utils";
import objectCloner from "../../../../../utils/objectCloner";

export default function addLoopMusicToNewScene({ index }) {
  Object.keys(document.musicLoop).forEach((e) => {
    const _prevId = prevFromIndex(index);
    objectCloner({
      _id: e,
      where: index,
      haveCopy: false,
      from: _prevId,
      clone: true,
    });
  });
}
