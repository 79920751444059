import { memo, useRef } from "react";
import { useState } from "react";

import store from "../../../../store/store";

import { sceneAdderFromJSON } from "../../../LeftPanel/components/Templates/Templates";

import SelectOption from "../../../Chemistry/Atom/SelectOption";
import { SceneInside } from "./SceneInside";
import { DeletePopup } from "./DeletePopup";
import { padosiFromIndex } from "../../../../utils";
import { connect } from "react-redux";
// import  from "uuid-random";
import { Index } from "../../../../utils";
export const Scene = connect(state => ({
  sceneNumber: state.sceneNumber,

}))(memo(
  ({ isShow, number, index, onClick = () => { }, total, sceneNumber }) => {
    let isActive = number - 1 === sceneNumber;
    const handleSelect = async (opt) => {
      if (opt.name === "Duplicate") {
        store.dispatch({
          type: "LOADING",
          data: "Duplicating the Scene",
        });
        if (document.Player?.status) {
          await document.outPlayMode();
        }

        let json = store.getState().scenes[number - 1];
        sceneAdderFromJSON([json], () => {
          store.dispatch({
            type: "LOADING",
            data: "Duplicating the Scene",
          });
        });
      } else if (opt.name === "Delete") {
        await document.outPlayMode();
        if (total === 1) {
          setOne(true);
        } else {
          setConfirm(true);
        }

      }

    };
    const [isHovered, setHoverd] = useState(false);
    const [isConfirm, setConfirm] = useState(false);
    const [haveOne, setOne] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const handleHover = (con) => {
      return () => {
        setHoverd(con);
      };
    };

    const ref = useRef();


    return (
      <div
        onClick={(e) => !isConfirm ? onClick(e) : () => { }}
        className={`SceneBar_Scene Scene ${isActive && "SceneBar_Scene_active"}`}
        onMouseOver={handleHover(true)}
        onMouseLeave={handleHover(false)}
        id={`SceneBar_Scene${number}`}
        style={isShow ? { display: "none" } : {}}
        ref={ref}
      >
        {(isActive || isHovered || isOpen) && (
          <SelectOption
            ele={<img alt="option" src={`${document.PUBLIC_URL}/ico/fileOption.svg`} />}
            list={[
              { name: "Delete", src: `${document.PUBLIC_URL}/ico/deleteFile.svg` },
              { name: "Duplicate", src: `${document.PUBLIC_URL}/ico/clone.svg` },
            ]}
            onSelect={handleSelect}
            onShow={(k) => {


              setHoverd(k)
              setOpen(k);

            }}
          />
        )}
        <DeletePopup
          isConfirm={isConfirm}
          setConfirm={setConfirm}
          text={"Scene"}
          onDelete={async () => {

            let delVar = padosiFromIndex({ index: number - 1 });

            store.dispatch({
              type: "DELETE_SCENE",
              data: { number: number - 1 },
            });

            setTimeout(() => {

              document.handleActive(delVar)()
              document.handleScroll({
                target: document?.querySelector(".SceneBar_list"),
              });
              document.activeScroll();
              setTimeout(() => {
                store.dispatch({
                  type: "COMPLETE_DELETE",
                  data: { number: number - 1 },
                });
              }, 0)



            }, 0)

          }}
        />



        <DeletePopup
          isConfirm={haveOne}
          setConfirm={setOne}
          haveFooter={true}
          customText={"Can't Delete Only One Scene!"}
        />



        <SceneInside number={number} />
        <div className="SceneBar_Scene_number">{index}</div>
      </div>
    );
  }
));
