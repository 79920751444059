/* eslint-disable */
import React, { useEffect, useState } from "react"

const { log } = console
export default function Video({ video, thumbnail, src, isCol = false, }) {
  const [videoID, setID] = useState("__" + new Date().getTime());
  // useEffect(() => {
  //   videojs(videoID, { fluid: true, autoplay: isCol });
  // });
  return (
    <video
      id={videoID}
      // style={{ width: "100%", height: "100%" }}
      // width={"100%"}
      poster={video?.thumbnail}
      controls="true"
      class=""
      data-setup="{}"
    // autoPlay={isCol}
    >
      <source src={video.src} />

    </video>
  )

}