import React, { useState } from "react";
import { ReactComponent as Back } from "./salespagebackarrow.svg";
import { ReactComponent as Autogenerate } from "./aiLogo.svg";
import { ReactComponent as Desktop } from "./desktop.svg";
import { ReactComponent as Cross } from "./crossSm.svg";
import { ReactComponent as Mobile } from "./phone.svg";

import "./IFrame.css";
// import { token} from "../../../../../config/config";
import { token } from "../../../../utils";
const ROOT_URL_local = "https://salespage.autogenerate.ai";
const IFrame = ({ close, ...props }) => {
  const [state, setState] = useState({ width: "100%", type: 0 });
  // console.log({ isSaved: props.isSaved });
  const url = {
    salespage: `${ROOT_URL_local}/preview/salespage/${props.id}?token=${token}`,
    campaign: `${ROOT_URL_local}/preview/${props.id}?email=campaign_test`,
  };

  return props.isSaved ? (
    <div
      className="IFrame"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="salespage-preview">
        <div className="gp-preview">
          <Back className=" pointer back-btn-preview" onClick={close} />
          <Autogenerate />
        </div>
        <div className="gp-preview">
          <div
            className="select-size-icon-container pointer"
            onClick={() => {
              setState({
                width: "100%",
                type: 0,
              });
            }}
          >
            <Desktop />
            <span
              className={`selected-size-bd ${state.type === 0 ? "true" : ""}`}
            ></span>
          </div>
          <div
            className="select-size-icon-container pointer"
            onClick={() => {
              setState({
                width: "485px",
                type: 1,
              });
            }}
          >
            <Mobile />
            <span
              className={`selected-size-bd x99 ${state.type === 1 ? "true" : ""
                }`}
            ></span>
          </div>
        </div>
        <div className="gp-preview">
          {/* <button className="closesalespagebtn flex center" onClick={close}>
            Close Preview <Cross />
          </button> */}
        </div>
      </div>

      <div
        style={{ display: state.type === 0 ? "" : "none" }}
        className="Frame Desktop"
      >
        <iframe src={url[props.type]} title="desktop" />
      </div>
      <div
        style={{ display: state.type === 1 ? "" : "none" }}
        className="Frame Mobile"
      >
        <div className="MobileFrame">
          <svg
            id="mobile-frame-con-wrapper"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 560.804 1038.996"
          >
            <defs>
              <filter id="52wksrypja" x="0" y="0" filterUnits="userSpaceOnUse">
                <feOffset dx="5" dy="5" />
                <feGaussianBlur result="blur" stdDeviation="15" />
                <feFlood flood-opacity=".6" />
                <feComposite in2="blur" operator="in" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g
              filter="url(#52wksrypja)"
              transform="translate(-1021.048 -281.807) matrix(1, 0, 0, 1, 1021.05, 281.81)"
            >
              <path
                id="mobile-frame-container"
                fill="#222"
                stroke="rgba(0,0,0,0)"
                stroke-miterlimit="10"
                d="M462.461 149.907h-.893v-93.67A56.235 56.235 0 0 0 405.333 0H64.469A56.235 56.235 0 0 0 8.234 56.237v93.669h-.893A7.342 7.342 0 0 0 0 157.248v64.794a7.342 7.342 0 0 0 7.342 7.342h.893v15.454h-.894A7.342 7.342 0 0 0 0 252.18v64.794a7.342 7.342 0 0 0 7.342 7.342h.893v567.448A56.235 56.235 0 0 0 64.469 948h340.864a56.235 56.235 0 0 0 56.235-56.235V269.124h.893a7.341 7.341 0 0 0 7.342-7.342V157.248a7.341 7.341 0 0 0-7.342-7.341z"
                transform="translate(40.5 40.5)"
              />
            </g>
          </svg>

          <iframe src={url[props.type]} title="mobile" />
        </div>
      </div>
    </div>
  ) : (
    <h1>saving</h1>
  );
};

export default IFrame;
