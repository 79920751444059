import React, { useEffect } from 'react';
import { useState } from 'react';
import Model from "reactjs-popup"
import Button from '../Button';
import Popup from '../Popup';
import "./_KK.scss"

export default function AvatarChoosePopup({ trigger, def = 0 }) {
    const [who, setWho] = useState(def)

    // console.log(def);
    // useEffect(() => {
    //     setWho(def)
    // }, [def])

    return (<Model modal trigger={trigger} onClose={() => (setWho(def))} closeOnDocumentClick>
        {close => (<Popup close={() => close()} footer={<Button value="Create Video" onClick={() => {
            window.open(`${document.location.origin}/video?mode=${who}`)
            close()

        }} />} >
            <div className='flex AvatarChoosePopup'>
                <div onClick={(i) => {
                    setWho(0)
                }} className={`AvatarChoosePopup_avatar ${who === 0 ? "AvatarChoosePopup_active" : ""}`}>
                    {/* if want to put an image */}
                    {/* <div onClick={(i) => {
                        setWho(0)
                    }} className='AvatarChoosePopup_avatar_img'></div>*/}
                    <div className="AvatarChoosePopup_avatar_text">Avatar</div>


                </div>
                <div onClick={(i) => {
                    setWho(1)
                }} className={`AvatarChoosePopup_avatar ${who === 1 ? "AvatarChoosePopup_active" : ""}`}>
                    {/* <div className='AvatarChoosePopup_avatar_img'></div> */}
                    <div className="AvatarChoosePopup_avatar_text">Non-Avatar</div>
                </div>
            </div>
        </Popup>)}
    </Model>)
}
