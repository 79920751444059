/* eslint-disable */
import { memo, useEffect, useRef } from "react";
import { fabric } from "fabric";
import { initAligningGuidelines, initCenteringGuidelines } from "./utils";
import {
  getSceneArray,
  Index,
  Jump,
  MoveSeekTo,
} from "../../utils";

import store from "../../store/store";
import MusicContainer from "../Chemistry/Atom/MusicContainer";
import DOMTimeLine from "../../utils/domTimeline";


import { getPadding, SIZES } from "../../utils/config";

import groupSelectionEvents from "../../utils/useFullFunctions/groupSelectionEvents";
import undoRedoStateManager from "../../utils/useFullFunctions/undoRedoStateManager";
import Lazzer from "../Chemistry/One/Lazzer";
import { CxxEvents } from "../../utils/CxxEvents";
import CanvasMenu from "../ContextMenu/CanvasMenu";
import { closeFullScreen } from "../../utils/fullScreen";
import { cropResize, firstSize } from "../../utils/useFullFunctions/resizeCanvas";
import { discardCurrentWork } from "../../utils/useFullFunctions/discardWork";
// import { ContextMenuTrigger, ContextMenu, ContextMenuItem } from 'rctx-contextmenu';
// import CanvasMenu from "../ContextMenu/CanvasMenu";






function Screen({ index, show }) {
  const cRef = useRef();
  const sRef = useRef();
  useEffect(() => {
    try {


      const { width, height } = sRef.current.getBoundingClientRect();

      fabric.devicePixelRatio = 2;
      let cxx = new fabric.Canvas(cRef.current, {
        width: width * getPadding(),
        height: height * getPadding(),
        backgroundColor: "#ffffff",
        selectionKey: 'ctrlKey'

      });

      CxxEvents({ cxx, index })

      cxx.preserveObjectStacking = true;
      document.cxx[index] = cxx;
      undoRedoStateManager();
      groupSelectionEvents(cxx);

      DOMTimeLine.init(index);

      window.onload = () => {
        document.smallScene.draw(index, false)
        firstSize({ cxx, sRef });
        cropResize()
      };

      setTimeout(() => {

        firstSize({ cxx, sRef });
        cropResize()
        document.smallScene.draw(index, false)
      }, 0);
      cxx.setZoom(cxx.height / SIZES[document?._size || 0].height);

      cxx.renderAll();
      initAligningGuidelines(cxx);
      // initCenteringGuidelines(cxx);

      const a = (padding, size) => {
        firstSize({ cxx, sRef, p: padding, index, size });
      };
      document.firstResize[index] = a;

    } catch (err) {
      console.error("ERRORSCREEN280", err);
    }
  }, []);

  useEffect(() => {

    if (show) {
      document._index = index;
      if (!document.Player?.status) {
        sRef.current.style.zIndex = 0;
        sRef.current.style.opacity = 1;
      }
    } else {
      if (!document.Player?.status) {
        document.txx.screenLine[index].play();
        sRef.current.style.zIndex = -100;
        sRef.current.style.opacity = 0;
      }
    }
  }, [show]);

  useEffect(() => {
    document.outPlayMode = async () => {

      if (document.screenChheda) {
        let index = Index()
        if (!document.disableoutPlayMode) {
          document.render = false;
        }
        document.cxx[index].renderAll();
      } else {
        // exit player mode
        document.Player.exit(0);
      }
    };

    let k = sRef.current;

    k.addEventListener("click", document.outPlayMode);

    return () => {
      k.removeEventListener("click", document.outPlayMode);
    };
  }, []);

  const hanleDisble = (e) => {
    document.outPlayMode();

    if (e.target.tagName !== "CANVAS") {
      discardCurrentWork({ _goB: true });
    }
    if (e.target.tagName !== "CANVAS" && (e.target.className !== "MusicContainer_music" && e.target.className !== "MusicContainer_music_active" && e.target.className !== "MusicContainer_music_name" && e.target.className !== "MusicContainer_music_img")) {
      if (document.screenChheda) {
        document.cxx[index].discardActiveObject();
        document.cxx[index].renderAll();
        document.hist.push(Jump({ obj: {}, def: true }))
        store.dispatch({ type: "SET_ACTIVE_OBJECT", data: "" });
      }
    }
  };

  useEffect(() => {
    let sc = document.getElementById(`screen${index}`);
    const fhandle = e => {
      // document.fullscreenElement
      closeFullScreen({})
    }
    sc.addEventListener("fullscreenchange", fhandle)
    return () => {
      sc.removeEventListener("fullscreenchange", fhandle)
    }
  }, [])
  return (
    // <>
    <>

      <div
        ref={sRef}
        id={`screen${index}`}
        className="Screen"
        onClick={hanleDisble}
      >
        <Lazzer />


        <CanvasMenu id={`_canvas${index}`}>
          <div className="_canvas">
            <canvas ref={cRef} />

          </div>

          <div className="Wrapper" id={`Wrapper${index}`}></div>
          <MusicContainer index={index} />
        </CanvasMenu>

      </div>
    </>


  );
}

export default memo(Screen);
