import { ReactComponent as CrossError } from "../App/extras/misc/loginerrorcross.svg";

import React, { useState } from "react";
import { forgetPassword } from "./actions/auth";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { ReactComponent as Autogenerate } from "../App/extras/aiLogo.svg";

import axios from "axios";
// import {url} from "../../config/config"

import { ReactComponent as VerifyEmailIcon } from "../App/extras/misc/mailverify.svg";
import { url } from "../utils";
import mystore from "../App/appStore";

function ResetPasswordForm(props) {
	const [Details, setDetails] = useState({
		email: "",
	});
	const [Step, setStep] = useState(0);
	const [Errors, setErrors] = useState({ isError: false });

	console.log(props);

	const forgetpassword = async (e) => {
		const res = await axios.post(`${url}/api/accounts/password/reset/`, {
			email: e,
		});
		const messages = {
			email: {
				0: "Email not registered.",
			},
		};
		console.log(res);
		if (res.data.isError) {
			var obj;

			Object.keys(res.data.message).map((d) => {
				obj = {
					isError: true,
					...obj,
					message: messages[d][res.data.message[d].status],
				};
			});

			setErrors(obj);
		} else {
			setStep(1);
			setErrors({
				isError: false,
			});
		}
	};

	console.log(Errors);

	const handlerSubmit = (e) => {
		var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		if (Details.email.match(mailformat)) {
			forgetpassword(Details.email);
		} else {
			if (!Details.email.match(mailformat)) {
				console.log("ll");

				document.getElementById("email-input").classList.add("errorInput");
			}
		}
	};

	const handleChange = (e) => {
		e.target.classList.remove("errorInput");

		setDetails({ ...Details, [e.target.name]: e.target.value });
	};

	const al = () => {
		if (Step === 0) {
			return (
				<div className="login-wrapper">
					<div className="login-inner-wrapper">
						<div className="login-form">
							{Errors.isError ? (
								<div className="error-login-info">
									<label htmlFor="" className="error-login-info-heading">
										Error !
									</label>
									<label htmlFor="" className="error-login-info-label">
										{Errors.message}
									</label>
									<CrossError
										className="pointer"
										onClick={() => {
											setErrors({
												...Errors,
												isError: false,
											});
										}}
										style={{ position: "absolute", right: "16px", top: "35%" }}
									/>
								</div>
							) : null}
							<input
								id="email-input"
								type="email"
								className="login-item"
								placeholder="Email"
								name="email"
								defaultValue={
									props.location && props.location.email
										? props.location.email
										: ""
								}
								onChange={(e) => {
									handleChange(e);
								}}
							/>
							{/* <input id="password-input" type="password" className="login-item" placeholder="Password" name="password" onChange={(e)=>{handleChange(e)}}  /> */}
						</div>

						<div className="login-footer">
							<button
								className="login-main-button"
								onClick={(e) => {
									handlerSubmit(e);
								}}
							>
								Reset Password
							</button>

							<label className="login-main-label">
								Go back to{" "}
								<Link to="/login" className="link-custom">
									Log in
								</Link>
							</label>
						</div>
					</div>
				</div>
			);
		}
		if (Step === 1) {
			return (
				<div key={1} className={`login-wrapper`} style={{ maxWidth: "513px" }}>
					{/* <Autogenerate style={{ width: "240px" }} /> */}

					<h1 className="login-header" style={{ margin: "42px 0" }}>
						Email sent.
					</h1>

					<div className="login-inner-wrapper">
						<div className="login-gp-1">
							<label className="login-main-label">
								An email with the link to change password has been sent to{" "}
							</label>
							<label className="login-verification-label">
								{Details.email}
							</label>

							{/* <GoogleAuth /> */}
							{/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
						</div>

						<div className="login-form">
							<VerifyEmailIcon />
						</div>

						<div className="login-footer">
							<Link
								to={{
									pathname: "/login",
									email: Details.email,
								}}
								className="login-main-button link-custom"
								style={{ margin: "50px 0 0 0", width: "200px" }}
								onClick={(e) => {
									handlerSubmit(e);
								}}
							>
								Login
							</Link>

							{/* <Link to="/signup" className="link-custom forget-login-main" >Forgot Password?</Link> */}
						</div>
					</div>
				</div>
			);
		}
	};

	if (props.isAuthenticated) {
		return <Redirect to="/" />;
	} else return <div className="login-container">{al()}</div>;
}

const mapStateToProps = () => {
	var state = mystore.getState();
	return {
		isAuthenticated: state.auth.isAuthenticated,
		state: state,
	};
};

export default connect(mapStateToProps, { forgetPassword })(ResetPasswordForm);
