import { memo } from "react";
import "./_Atom.scss";

function TitleWrapper({ className = "", title, children, ele = " ", onClick = () => { } }) {
  ////console.log(`TitleWrapper : ${title} Renders`);

  return (
    <div className={`TitleWrapper ${className}`} onClick={onClick}>
      <label className="TitleWrapper_label" htmlFor="">
        {title}
        {ele}
      </label>
      <div className={`TitleWrapper__wrapper`}>{children}</div>
    </div>
  );
}
export default memo(TitleWrapper);
