import React from "react";
import { useLocation } from "react-router";
import { Index } from "../../../../../../utils";
import Angle from "../../../../../Chemistry/Atom/Angle";
import Dimensions from "../../../../../Chemistry/Atom/Dimensions";
import TitleRow from "../../../../../Chemistry/Atom/TitleRow";
import TitleWrapper from "../../../../../Chemistry/Atom/TitleWrapper";
import "./_GroupSettings.scss";
export default function GroupSettings() {
  const { state } = useLocation();
  return (
    <div className="GroupSettings">
      <TitleRow title="Group Settings" />
      <TitleWrapper title="Layout">
        <Dimensions
          obj={document.cxx[Index()].getActiveObject()}
          id={state?.id}
          ele={
            <Angle
              obj={document.cxx[Index()].getActiveObject()}
              id={state?.id}
            />
          }
        />
      </TitleWrapper>
    </div>
  );
}
