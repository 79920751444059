import React, { useEffect, useState } from "react";

import uuid from "uuid-random"
import { getById, Index } from "../../utils";
import { CopyObject, DeleteObject, PasteObject } from "../Header/Header";
import { setOrder } from "../LeftPanel/components/Avatar/Avatar";
import { Menu } from "./Menu";
import { MenuItem } from "./MenuItem";
export default function CanvasMenu({ children, id, className }) {
    // const [id] = useState("SCREEN")
    return (
        <>
            <div id={id} className={className}>
                {children}
            </div>
            {<CanvasMenuItem id={id} />}
        </>
    )
}
const CanvasMenuItem = ({ id }) => {
    let [_id, setId] = useState(id)

    const [opt, setOptions] = useState({
        paste: true,
        copy: false,
        delete: false,
        front: false,
        back: false,
        forward: false,
        backward: false
    })
    const handleCopy = () => {
        let obj = getById(_id);
        if (obj) {
            CopyObject({ obj })
        }
    }
    const handlePaste = () => {
        let obj = getById(document.zx.toBeCopy);
        console.log(pos);
        // console.log(obj);
        if (obj) {
            PasteObject({ obj, pos })
        }
    }
    const handleDelete = () => {
        let obj = getById(_id)
        // console.log(obj);
        if (obj) {
            DeleteObject({ obj })
        }
    }

    const [show, setShow] = useState(false)
    useEffect(() => {
        // console.log("I am Back", show);

        if (show) {
            let id = document.currentObj.id;
            setId(id)
            let obj = getById(id);
            // console.log(obj._Type, obj._Name);
            if (obj) {
                let ops = {
                    paste: true,
                    copy: true,
                    delete: true,
                    front: true,
                    back: true,
                    forward: true,
                    backward: true
                }
                if (obj._Type === "avatar") {
                    ops = { ...ops, delete: false }
                } else if (obj._Type === 'text' && obj.isEditing) {
                    ops = {
                        paste: false,
                        copy: false,
                        delete: false,
                        front: false,
                        back: false,
                        forward: false,
                        backward: false
                    }
                }
                setOptions(ops)

            } else {
                let ops = {
                    paste: true,
                    copy: false,
                    delete: false,
                    front: false,
                    back: false,
                    forward: false,
                    backward: false
                }
                const _selectedObj = document.cxx[Index()]?.getActiveObject();
                if (_selectedObj && ((_selectedObj._Type === 'text' && _selectedObj.isEditing) || document.zx?.toBeCopyType === undefined)) {
                    ops.paste = false;
                }
                setOptions(ops);
            }

        }
    }, [show])
    const [pos, setPos] = useState({ x: 0, y: 0 })

    const handleShow = (sh, po) => {
        console.log(sh, po);
        setShow(sh);
        setPos(po)

    }


    return <Menu id={id} onShow={handleShow} >
        {opt.copy && <MenuItem src={`${document.PUBLIC_URL}/ico/clone.svg`} onClick={handleCopy} cmd={`Ctrl + C`}>
            Copy
        </MenuItem>}
        {opt.paste && <MenuItem src={null} onClick={handlePaste} cmd={`Ctrl + V`}>
            Paste
        </MenuItem>}
        {opt.delete && <MenuItem src={`${document.PUBLIC_URL}/ico/delete.svg`} onClick={handleDelete} cmd={`Delete`}>
            Delete
        </MenuItem>}
        {opt.back && <MenuItem
            src={`${document.PUBLIC_URL}/ico2/Back.svg`}
            onClick={setOrder(0, { id: _id })} cmd={``}>
            Back
        </MenuItem>}
        {opt.backward && <MenuItem
            src={`${document.PUBLIC_URL}/ico2/Backward.svg`}
            onClick={setOrder(1, { id: _id })} cmd={``}
        >
            Backward
        </MenuItem>}
        {opt.forward && <MenuItem
            src={`${document.PUBLIC_URL}/ico2/Forward.svg`}
            onClick={setOrder(2, { id: _id })} cmd={``}
        >
            Forward
        </MenuItem>}
        {opt.front && <MenuItem
            src={`${document.PUBLIC_URL}/ico2/Front.svg`}
            onClick={setOrder(3, { id: _id })} cmd={``}
        >
            Front
        </MenuItem>}
    </Menu>
}


