import React from "react";
import "./_Home.scss";
import AppHeader from "./Components/AppHeader";
import { Link, Switch, Route } from "react-router-dom";
import Templates from "./Components/Templates";
import Model from "../AccountRoutes/Model";
import MyVideo from "./Components/MyVideo";
// import VideoDetail from "./Components/VideoDetail";
import { connect, Provider } from "react-redux";
import mystore from "./appStore";
import FAQ from "./JohnDoe/FAQ/FAQ";
import ContactUs from "./JohnDoe/ContactUs/ContactUs";
import Subscription from "./JohnDoe/Subscription/Subscription";
import Setting from "./JohnDoe/accountSettingUi/Setting";
import LoginForm from "../accounts/Login";
import SignupForm from "../accounts/SignUp";
import ForgotPassForm from "../accounts/ForgetPassword";

import ResetPassword from "../accounts/resetPassword";
import SignupVerify from "../accounts/signUpVerify";
import { fetcher, MainWebsiteUrl, url } from "../utils"
import InfiniteLoadingComponent from "../utils/InfiniteLoadingComponent";
import CopyTemplate from "./Components/NewTemplates/CopyTemplate";
import ImageDetail from "./ImageTemplate/VideoDetail";
import VideoDetail from "./Components/VideoDetail";



import MyImage from "./Components/MyImage";
// document._size = 0;//


export default function Home({ isVideo }) {
  let PublicRoutes = [
    {
      path: "/login",
      main: (dd) => (
        <div>
          <LoginForm {...dd} />
        </div>
      ),
    },
    {
      path: "/forgotpassword",
      main: (dd) => (
        <div>
          <ForgotPassForm {...dd} />
        </div>
      ),
    },
    {
      path: "/reset-password/:id",
      main: (dd) => (
        <div>
          <ResetPassword {...dd} />
        </div>
      ),
    },
    {
      path: "/signup-verify/:id",
      main: (dd) => (
        <div>
          <SignupVerify {...dd} />
        </div>
      ),
    },
    {
      path: "/register",
      main: (dd) => (
        <div>
          <SignupForm {...dd} />
        </div>
      ),
    },
  ];


  const MatchPublicRoutes = ({ path }) => window.location.href.includes(path)



  React.useEffect(() => {

    document
      .getElementById("bootstrap")
      .setAttribute("href", "/bootstrap.min.css");
    // // return () => {
    // document.getElementById("bootstrap").removeAttribute("href");
    // // };
  }, []);

  const [MainLoad, setMainLoad] = React.useState(true)

  React.useEffect(() => {

    var RedirectUrl = window.location.href


    fetcher({
      method: "GET",
      url: `${url}/api/accounts/user/?isCP=1`,
      res: (res) => {

        mystore.dispatch({
          type: "USER_DATA",
          data: { ...res.data, isAuthenticated: true },
        })
        setMainLoad(false)
      },
      err: (err) => {
        localStorage.removeItem?.("token")

        mystore.dispatch({
          type: "USER_DATA",
          data: { isAuthenticated: false },
        })
        var x = PublicRoutes.some(MatchPublicRoutes)

        if (!x)
          setMainLoad(false)
      },
    });

  }, []);



  const FindNoHeader = () => {
    var x = PublicRoutes.some(MatchPublicRoutes)
    return (!x ? <AppHeader /> : <></>)
  }

  const MainLoader = () => MainLoad ? <InfiniteLoadingComponent /> : <></>


  return (
    <Provider store={mystore}>
      <div className="Home">
        <MainLoader />
        <FindNoHeader />
        <Switch>
          <Route path="/myvideos/:vid">
            <VideoDetail />

          </Route>
          <Route path="/myimages/:vid">
            <ImageDetail />

          </Route>
          <Route path="/myvideos">
            <MyVideo />
          </Route>

          <Route path="/myimages">
            <MyImage />
          </Route>

          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/contactus">
            <ContactUs />
          </Route>

          <Route path="/subscription">
            <Subscription />
          </Route>

          <Route path="/settings">
            <Setting />
          </Route>

          <Route path="/new-template">
            <CopyTemplate />
          </Route>



          <Route path="/" exact>
            <>
              {/* <VideoCreator /> */}

              <Templates />
            </>
          </Route>
          {/* account related routes */}
          {PublicRoutes.map((route, index) => {
            return (
              // Render more <Route>s with the same paths as
              // above, but different components this time.
              <Route path={route.path} exact render={() => <Model />} />
            );
          })}

        </Switch>
        {/* <Link to="/video">
        <h1 style={{ color: "white" }}>click here for Video Creator</h1>
      </Link> */}
      </div>
    </Provider>
  );
}
