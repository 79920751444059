import "./_Atom.scss";
function ButtonIcon({
  value = "button",
  onClick = () => { },
  disabled = false,
  icon = null,
  src = "",
  className = "",
  classNameImg = ""
  // ele=null
}) {
  return (
    <button
      onClick={(e) => {
        if (!disabled) onClick(e);
      }}
      className={`ButtonIcon ${className} ${disabled ? "ButtonIcon-disabled" : ""
        } ${value === "" ? "ButtonIcon-mini" : ""}`}
    >
      {value} {icon || <img className={`${classNameImg}`} src={src} alt={"ff"} />}
    </button>
  );
}

export default ButtonIcon;
