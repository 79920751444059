import uuid from "uuid-random";
import store from "../store/store";
import { controlsByObject, Jump } from "../utils";
import { fabric } from "fabric";
import labelTag from "./labelTag";
import { customVideoSeek } from "./videoPlayThings";
import { defaultID } from "./config";
import intiBackground from "../components/LeftPanel/components/Background/functions/intiBackground";
import initTextForInitAdd from "../components/LeftPanel/components/Text/functions/initTextForInitAdd";
import initAvatar from "../components/LeftPanel/components/Avatar/functions/initAvatar";
import { addObjectOutsideEventC } from "./fabricInit";

export function InitAddObjectEvents({
  obj,
  type,
  name,
  _id,
  __id,
  index = null,
  returnToUrl = true,
  media = {},
  src,

  data = {},
  timeLineData = {},
  reload = false,
  clone = false,
  from = 0,
  storeData = {},
  _toStore = true
}) {
  let id = uuid();

  let _;

  obj._Type = type;
  obj._Name = name;
  obj._Data = data;

  obj._Animation = obj._Animation || {
    enter: {
      id: defaultID.animation.enter,
    },
    exit: {
      id: defaultID.animation.exit,
    },
    place: {
      id: defaultID.animation.place,
    },
  };

  if (_id) {
    id = _id;
  }
  if (src) {
    obj._Src = src;
  }
  obj.id = id;

  if (!document.oxx[index]) {
    document.oxx[Number(index)] = {};
  }

  const myLabelCondition = obj._Type === "background";

  //getElement byid
  document.oxx[obj.id] = obj;

  obj.set({
    lockScalingFlip: true,
  });
  // obj.set(document.selectionSettings);
  if (obj._Type === "music") {
    obj.set({
      hasControls: false,
      hasBorders: false,
      selectable: false,
      evented: false,
    });
  } else {
    obj.set({
      cornerColor: "#ffffff",
      borderColor: "#e7416a",
      cornerStrokeColor: "#e7416a",
      cornerStyle: "circle",
      transparentCorners: false,
      cornerSize: 12,
    });
  }
  obj.on("moving", () => {
    _ = document.fxx.change[id]?.(obj);
    _ = document.fxx.change1[id]?.(obj);
  });

  obj.on("scaling", () => {
    _ = document.fxx.change[id]?.(obj);
  });

  obj.on("rotating", () => {
    _ = document.fxx.change[id]?.(obj);
    _ = document.fxx.change[`${id}angle`]?.(obj);
  });

  obj.on("resizing", () => {
    _ = document.fxx.change[id]?.(obj);
  });

  obj.on("scaled", () => {
    controlsByObject({ obj });

    obj.canvas.renderAll();
  });

  // if (!myLabelCondition) {
  obj.on("selected", () => {
    obj.set({
      hoverCursor: "move",
    });
  });
  obj.on("deselected", () => {
    obj.set({
      hoverCursor: "default",
    });
  });
  // }

  obj.set({
    hoverCursor: "default",
  });
  if (document.editorType === "image") {
    if (obj._Type === "video" && reload) {
      if (!obj.isImage) getNewCoordsAfterImageHeightWidthChange(obj, false);
    }
  }

  //Avatar Init and Events
  initAvatar({ obj, index, storeData });

  //Text Init and Events
  initTextForInitAdd({ obj });

  // Background Init and Events
  intiBackground({ obj });

  ControlRelatedWork({ obj });

  // obj.canvas.renderAll();
  if (returnToUrl) {
    document.hist.push(
      Jump({
        obj,
      }),
      {
        id,
      }
    );

  }

  if (_toStore) {
    store.dispatch({
      type: "ADD_OBJECT",
      data: {
        id,
        _id: __id,
        obj,
        type,
        index,
        media,
        timeLineData,
        reload,
        clone,
        from,
      },
    });
  }
}

export const ControlRelatedWork = ({ obj }) => {
  if (
    obj._Type === "elements" &&
    (obj._Data.type === "shapes" || obj._Data.type === "icons") &&
    obj._objects === undefined &&
    obj.type !== "polygon" &&
    obj.type !== "path"
  ) {
    obj.set({
      objectCaching: false,
    });
    let _ml = new fabric.Control({
      x: -0.5,
      y: 0,
      cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      actionHandler: fabric.controlsUtils.changeWidth,
      getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    });

    let _mr = new fabric.Control({
      x: 0.5,
      y: 0,
      cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      actionHandler: fabric.controlsUtils.changeWidth,
      getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    });

    let _mb = new fabric.Control({
      x: 0,
      y: 0.5,
      cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      actionHandler: fabric.controlsUtils.changeHeight,
      getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    });

    let _mt = new fabric.Control({
      x: 0,
      y: -0.5,
      cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      actionHandler: fabric.controlsUtils.changeHeight,
      getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    });
    obj.controls = {
      ...obj.controls,
      mr: _mr,
      ml: _ml,
      mt: _mt,
      mb: _mb,
    };
  }
  let _iconSize = 30;

  let tag = null;
  if (obj._Type !== "elements") {
    tag = obj._Type;
  } else {
    tag = obj._Data.type;
    if (tag === "elements") {
      tag = `illustration`;
    }
  }
  let markSVG = `data:image/svg+xml,${encodeURIComponent(labelTag[tag])}`;

  // console.log(markSVG);

  let markSVGImg = document.createElement("img");
  markSVGImg.src = markSVG;
  markSVGImg.onload = () => {
    // console.log(markSVGImg.width, markSVGImg.height);
    let labelControl = new fabric.Control({
      x: -0.5,
      y: -0.5,
      offsetY: -0.6 * markSVGImg.height,
      offsetX: 0.5 * markSVGImg.width,
      cursorStyle: "pointer",

      render: function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        ctx.save();
        if (!this._noROffset) {
          ctx.translate(left, top);
          let _angle = fabricObject.angle;
          if (_angle > 90 && _angle < 270) {
            ctx.rotate(fabric.util.degreesToRadians(180 + fabricObject.angle));
          } else {
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
          }
          ctx.drawImage(markSVGImg, -markSVGImg.width / 2, -markSVGImg.height / 2, markSVGImg.width, markSVGImg.height);
        } else {
          if (this._absPos?.x !== undefined && this._absPos?.y !== undefined) {
            ctx.translate(this._absPos?.x, this._absPos?.y)
          } else {
            ctx.translate(0, 0)
          }
          ctx.drawImage(markSVGImg, 0, 0, markSVGImg.width, markSVGImg.height);
        }
        ctx.restore();
      },
      _size: { x: markSVGImg.width, y: markSVGImg.height },
    });

    obj.controls = {
      ...obj.controls,
      labelControl,
    };
    addObjectOutsideEventC(obj);
  };
};

const getNewCoordsAfterImageHeightWidthChange = (obj, isImage) => {
  let _fileInfo;
  if (isImage) {
    _fileInfo = obj?._Image?.fileInfo;
  } else {
    _fileInfo = obj?._Video?.fileInfo;
  }
  //con.log(`Obj URL: `,obj._originalElement.src)
  if (
    _fileInfo &&
    _fileInfo.height &&
    _fileInfo.width &&
    obj._originalElement
  ) {
    // get data
    let { height, width, scaleX, scaleY, cropX, cropY } = obj;
    let prevHeight = _fileInfo.height,
      prevWidth = _fileInfo.width;
    let { height: newHeight, width: newWidth } = obj._originalElement;

    // handle without crop
    const newScaleX = (prevWidth * scaleX) / newWidth;
    const newScaleY = (prevHeight * scaleY) / newHeight;

    const _newScaleX = prevWidth / newWidth;
    const _newScaleY = prevHeight / newHeight;

    const newCropX = parseInt(cropX / _newScaleX);
    const newCropY = parseInt(cropY / _newScaleY);

    const _newWidth = parseInt(width / _newScaleX);
    const _newHeight = parseInt(height / _newScaleY);

    // handle with crop
    if (obj.clipPath) {
      const {
        left: cLeft,
        top: cTop,
        scaleX: cScaleX,
        scaleY: cScaleY,
        height: cHeight,
        width: cWidth,
      } = obj.clipPath;
      const _cHEIGHT = cHeight * cScaleY,
        _CWIDTH = cWidth * cScaleX;
      obj.clipPath.left = cLeft / _newScaleX;
      obj.clipPath.top = cTop / _newScaleY;
      let _newClipHeight = _cHEIGHT / _newScaleY,
        _newClipWidth = _CWIDTH / _newScaleX;
      obj.clipPath.scaleX = _newClipWidth / cWidth;
      obj.clipPath.scaleY = _newClipHeight / cHeight;
    }
    //update shadow
    if (obj.shadow) {
      if (
        obj.shadow.offsetX !== undefined &&
        obj.shadow.offsetY !== undefined
      ) {
        obj.shadow.offsetX *= 1 / _newScaleX;
        obj.shadow.offsetY *= 1 / _newScaleY;
      }
      if (obj.shadow.blur) {
        obj.shadow.blur *= 1 / _newScaleY;
      }
    }
    obj.set({
      height: _newHeight,
      width: _newWidth,
      scaleX: newScaleX,
      scaleY: newScaleY,
      cropX: newCropX,
      cropY: newCropY,
    });

    obj.canvas.renderAll();
  }
};
