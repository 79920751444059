import React, { useEffect, useState } from "react";
import LabelInput from "./LabelInput";
import "./_Atom.scss";
import { getById, Index } from "../../../utils";

export default function Angle({ id, obj }) {
  const [angle, setAngle] = useState(0);
  useEffect(() => {
    let _obj = getById(id);
    if (obj) {
      _obj = obj;
    }
    setAngle(Math.round(_obj.angle));
    if (_obj)
      document.fxx.change[`${id}angle`] = (obj) => {
        setAngle(Math.round(_obj.angle));
        // //console.log(obj)
      };
  }, [id]);
  const handleAngle = (e) => {
    let _obj = getById(id);
    if (_obj) {
      // _obj = obj;
      setAngle(e.target.value);
      _obj.rotate(e.target.value)
      // _obj.set({ angle: e.target.value });

      _obj.canvas.renderAll();
    }
  };
  const fireHandle = () => {
    let _obj = getById(id);
    if (_obj) {
      _obj.canvas.fire("object:modified");
    }
  };
  return (
    <div className="Angle">
      <LabelInput
        value={angle}
        min={0}
        max={359}
        onChange={handleAngle}
        onBlur={fireHandle}
        label={<img className="Angle_img" src={`${document.PUBLIC_URL}/ico/angle.svg`} alt="angle" />}
      />
    </div>
  );
}
