import React, { Suspense, useState } from "react";
import Popup from "../Popup";
import "./_EEE.scss";
import ButtonIcon from "../../../components/Chemistry/Atom/ButtonIcon";
import Modal from "reactjs-popup";
import Button from "../Button";
import SharingPopup from "./SharingPopup";
import VariablePopup from "./VariablePopup";

import { connect } from "react-redux";
import IFrame from "./IFrame/IFrame";
import mystore, { vid } from "../../appStore";
import { CopyLink } from "./DownloadPopup";
import BatchCsvPopup from "./BatchCsvPopup";
import copy from "copy-to-clipboard";
import InfiniteNewWrapper from "../infiniteNewWrapper";
import { url, HideAndShowHandler } from "../../../utils";
import HowitWorks from "./HowItsWorking"
// import ChoosePopupLoad from "../../ImagePersonalised/ChoosePopUpLoad";
const ChoosePopup = React.lazy(() =>
  import(
    "../../ImagePersonalised/ChoosePopUpLoad" /* webpackChunkName: "ImagePersionalized" */
  )
);




export default connect((state) => ({ my: state.mydetail, media: state.media, list: state.media.solo_history.list, next: state.media.solo_history.list }))(
  function LinkPopup({
    my,
    next,
    list,
    trigger = (
      <Button
        className={`${mystore?.getState().mydetail?.mainVideoGenerate?.status !== 1 ? "Disabled-button-generate-invarPopup" : ""}`}
        id={"_shareLinkBtn"}
        value={
          <>
            Share via Link <img src={`${document.PUBLIC_URL}/app/link.svg`} alt="button"></img>
          </>
        }
      />
    ),
    name = "Share via Link",
  }) {

    const copyMe = (data) => {

      navigator.clipboard.writeText(my.link);
      copy(my.link)
      let oldText = document.getElementById(`copy-${my.id}`).innerHTML
      document.getElementById(`copy-${my.id}`).innerHTML = "Copied !";
      setTimeout(() => {
        document.getElementById(`copy-${my.id}`).innerHTML = oldText;
      }, 600)
      return data
    }

    return (
      <Modal modal closeOnDocumentClick={true} trigger={<Button
        className={`${mystore?.getState().mydetail?.mainVideoGenerate?.status !== 1 ? "Disabled-button-generate-invarPopup" : ""}`}
        id={"_shareLinkBtn"}
        value={
          <>
            Share via Link <img src={`${document.PUBLIC_URL}/app/link.svg`} alt="button"></img>
          </>
        }
      />} nested  >
        {(close) => (
          <Popup
            UpperId="Linkpopup-1x1"
            name={name}
            close={close}
            haveButton={false}
            rightHeader={
              <>
                <Button onClick={() => window.open(my.link, "_blank")} value={<div>Live Preview <img src={`${document.PUBLIC_URL}/app/up.svg`}></img></div>} className="ButtonIcon" />

                <HowitWorks trigger={<ButtonIcon value={<div>How it works?</div>} icon=" " />} />
              </>
            }
          >
            <div className="flex column">
              <div className="LinkPopop flex ">
                <Suspense fallback={<div>Loading...</div>}>
                  <ChoosePopup
                    Detail={my}
                    trigger={
                      <button className="BadButton" id={"_linkPopupBtnT"}>
                        <TopCard
                          name={`From ${my.thumbnailInst.name}`}
                          title="Thumbnail"
                          src={my.thumbnailInst.thumbnail}
                          have={my.thumbnailInst.isPersonalized}
                        />
                      </button>
                    }
                    nowImage={{ id: 35, category: 0, name: "Guslhan" }}
                  />
                </Suspense>
                <SharingPopup
                  onOpenHide={() => {
                    HideAndShowHandler("Linkpopup-1x1", "none")
                  }}
                  onCloseShow={() => {
                    HideAndShowHandler("Linkpopup-1x1", "block")

                  }}
                  trigger={
                    <button className="BadButton" id={"_linkPopupBtn"}>
                      <TopCard
                        name={`${my.sharingPage?.name}`}
                        src={my.sharingPage?.previewImage}
                        have={my?.sharingPage?.isPersonalized}
                        title="Sharing Page"
                      />
                    </button>
                  }
                />
                {(my?.isPersonalized || my.sharingPage?.isPersonalized || my.thumbnailInst.isPersonalized) ? (
                  <VariablePopup
                    haveBatch={false}
                    onOpenHide={() => {
                      HideAndShowHandler("Linkpopup-1x1", "none")
                    }}
                    onCloseShow={() => {
                      HideAndShowHandler("Linkpopup-1x1", "block")

                    }}
                    trigger={
                      <button className="BadButton">
                        <NewPersonalized text="New Personalised Link" />
                      </button>
                    }
                  ></VariablePopup>
                ) : (
                  <CopyLink onCopy={copyMe} id={`copy-${my.id}`} url={my.link || my.code} haveLink={false} text="Copy Link !" status={1} type="copy" icon={`${document.PUBLIC_URL}/app/copyLink.svg`} />

                )}
              </div>
              {my.sharingPageGenCount !== 0 && (
                <div className="PersonalisedHistory">
                  <div className="PersonalisedHistory_title">
                    Personalised Link History
                  </div>
                  <div className="PersonalisedHistory_list realLinkHIstoryTab" id={"solo_history_div"}>
                    <InfiniteNewWrapper target={"solo_history_div"} type="solo_history" mystore={mystore} url={next || `${url}/api/newvideo/video/generate/solo/${vid()}/`} >
                      {list.map((e) => {
                        return (
                          <LinkHistoryTab
                            key={e.id}
                            id={e.id}
                            progress={e.progress}
                            status={e.status}
                            date={e.timestamp}
                            url={e.campaignUrl}

                            // variableValue={e.variableValue}
                            full={e}
                          />)

                      })}

                    </InfiniteNewWrapper>

                  </div>
                </div>
              )}
            </div>
          </Popup >
        )}
      </Modal >
    );
  });



export const NewPersonalized = ({ text = "New Personalised Code" }) => {
  return (
    <div className="NewPersonalized pointer flex center">
      <img src={`${document.PUBLIC_URL}/app/addPulse.svg`} alt="addPulse"></img>
      {text}
    </div>
  );
};

const CsvTablePopup = ({ id, fileName, count }) => {

  return <BatchCsvPopup id={id} fileName={fileName} trigger={<Button className={"m-0"} onClick={() => { }} value="View CSV" />} />

}



export const LinkHistoryTab = ({
  url,
  id,
  status,
  progress,
  date = "2021-11-22T14:16:20.354895Z",
  full, code, type = "solo_history",
  media = {}
}) => {
  const [dd] = useState(new Date(date));
  const [open, setOpen] = useState(false);
  const copyMe = () => {
    let _copyData;
    if (type === 'solomail_history') {
      _copyData = code;
    } else {
      _copyData = url;
    }

    if (status === 1) {
      navigator.clipboard.writeText(_copyData);
      copy(_copyData)
      let oldText = document.getElementById(`copy-${id}`).innerHTML
      document.getElementById(`copy-${id}`).innerHTML = "Copied !";
      setTimeout(() => {
        document.getElementById(`copy-${id}`).innerHTML = oldText;
      }, 600)
      return _copyData
    }
  }

  const newTab = () => {
    window.open(
      url, "_blank");
  }

  return (
    <div className="LinkHistoryTab realLinkHIstoryTab">
      <div className="LinkHistoryTab_head flex acenter between">
        <div className="LinkHistoryTab_head_link">{url || code}</div>
        {/* <TablePopup media={media} /> */}
        <div className="flex">

          <CopyLink onCopy={copyMe} progress={progress} id={`copy-${id}`} className={type} url={url || code} haveLink={false} text={type === "solomail_history" ? "Copy Code !" : "Copy Link !"} status={full.status} type="copy" icon={`${document.PUBLIC_URL}/app/copyLink.svg`} extraIcon={
            <Button onClick={newTab} className={full.status === 1 ? "" : "Disabled-button-generate-invarPopup"} value={
              < img
                src={`${document.PUBLIC_URL}/app/newTab.svg`}

                className="LinkHistoryTab_NewTab"
                alt="23"

              />

            }>


            </Button>

          } />
          {!type === "solomail_history" && <img
            onClick={() => setOpen((o) => !o)}
            className="pointer"
            style={{ transform: `rotateZ(${!open ? "0" : "180"}deg)` }}
            src={`${document.PUBLIC_URL}/app/downArrow.svg`}
            alt="downArrow"
          />}
          <img
            onClick={() => setOpen((o) => !o)}
            className="pointer"
            style={{ transform: `rotateZ(${!open ? "0" : "180"}deg)` }}
            src={`${document.PUBLIC_URL}/app/downArrow.svg`}
            alt="downArrow"
          />
        </div>
      </div>
      {open && (
        <div className="LinkHistoryTab_body">
          <div className="LinkHistoryTab_body_date flex">
            <img src={`${document.PUBLIC_URL}/app/date.svg`} alt="date" /> {" " + dd.toDateString()}
          </div>
          <div className="LinkHistoryTab_body_value">
            {" Variables & its values"}
          </div>
          {full.allMergeTag.map((e, i) => {
            return (
              <div className="LinkHistoryTab_body_merge flex" key={i}>
                <div className="LinkHistoryTab_body_merge_tag">{e[0]}:</div>
                <div className="LinkHistoryTab_body_merge_value">{full.mergeTagValue[i]}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export const BatchHistoryTab = ({
  url,
  id,
  fileName,
  status,
  progress,
  date = "2021-11-22T14:16:20.354895Z",
  full, code, type = "batch_history",
  media = {}
}, props) => {
  const [dd] = useState(new Date(date));

  const copyMe = () => {
    if (status === 1) {
      navigator.clipboard.writeText(code);
      copy(code)
      let oldText = document.getElementById(`copy-${id}`).innerHTML
      document.getElementById(`copy-${id}`).innerHTML = "Copied !";
      setTimeout(() => {
        document.getElementById(`copy-${id}`).innerHTML = oldText;
      }, 600)
      return url
    }
  }
  const newTab = () => {
    window.open(
      url, "_blank");
  }


  return (
    <div className="LinkHistoryTab BatchHistoryTab realLinkHIstoryTab">
      <div className="LinkHistoryTab_head flex acenter between">
        <BatchCsvPopup id={id} fileName={fileName} trigger={<div className="LinkHistoryTab_head_link pointer">

          <label htmlFor="">
            {fileName || code}
            <br />
            <div className="LinkHistoryTab_body_date flex">
              <img src={`${document.PUBLIC_URL}/app/date.svg`} alt="date" /> {" " + dd.toDateString()}
            </div>
          </label>

        </div>} />

        {/* <TablePopup media={media} /> */}
        <div className="flex">
          <CopyLink onCopy={copyMe} progress={progress} id={`copy-${id}`} className={type} url={url || code} haveLink={false} text="Copy Code !" status={full.status} type="copy" icon={`${document.PUBLIC_URL}/app/copyLink.svg`} extraIcon2={<CsvTablePopup id={id} fileName={fileName} />} extraIcon={


            // <Button onClick={newTab} className={`mx-2 ${full.status === 1 ? "" : "Disabled-button-generate-invarPopup"}`} value={
            <Button onClick={newTab} className={`mx-2`} value={
              <img
                src={`${document.PUBLIC_URL}/app/newTab.svg`}

                className="LinkHistoryTab_NewTab"
                alt="23"

              />

            }>


            </Button>
          } />



        </div>
      </div>

    </div>
  );
};




export const TopCard = ({ title = "", name = "", src = "", have }) => {

  return (
    <div className="TopCard flex column">
      <div className="TopCard_top flex  between acenter">
        <div className="flex column ">
          <div className="TopCard_title">{title}</div>
          <div className="TopCard_name">{name}</div>
        </div>
        <img src={`${document.PUBLIC_URL}/app/tedit.svg`} alt="" />
      </div>
      <PerImage have={have} className="TopCard_img" src={src} alt="" />
    </div>
  );
};

export const TopCardNoSharePage = ({ title = "", name = "No Sharing page selected", src = `${document.PUBLIC_URL}/app/noSharePage.svg`, have }) => {

  return (
    <div className="TopCard flex column">
      <div className="TopCard_top flex  between acenter">
        <div className="flex column ">
          <div className="TopCard_title">{title}</div>
          <div className="TopCard_name">{name}</div>
        </div>
        <img src={`${document.PUBLIC_URL}/app/tedit.svg`} alt="" />
      </div>
      <PerImage have={have} className="TopCard_img" src={src} alt="" />
    </div>
  );
};

export const PerImage = ({
  haveOption = null,
  have = false,
  src,
  className,
  isActive = false,
  onClick = () => { },
}) => {

  return (
    <div className={`PerImage`} >
      <div id={`hoverable-btn-${src}`} className="PerImage_option">{haveOption || <></>}</div>

      <img
        className={`${className} ${isActive ? "PerImage_active" : ""}`}
        src={src}
        alt={src}
        onClick={onClick}
      />
      {have && <div className="PerImage_text">Personalised</div>}
    </div>
  );
};

// export const CopyLink = ({ url, onCopy = () => { }, haveLink = true }) => {
//   return (
//     <div
//       className="CopyLink flex column center"
//       style={haveLink ? {} : { margin: "0px", width: "unset" }}
//     >
//       {haveLink && <div className="CopyLink_link">{url}</div>}
//       <div
//         className="CopyLink_button flex"
//         style={haveLink ? {} : { margin: "0px", width: "unset" }}
//       >
//         <div className="CopyLink_button_tab flex center">
//           <img src={`${document.PUBLIC_URL}/app/newTab.svg`} alt="" />
//         </div>
//         <Button
//           onClick={onCopy}
//           className="flex center"
//           value={
//             <>
//               Copy Link{" "}
//               <img src={`${document.PUBLIC_URL}/app/copyLink.svg`} style={{ marginLeft: "10px" }} />
//             </>
//           }
//         />
//       </div>
//     </div>
//   );
// };

