import React, { useState } from "react";
import "./_CSS.scss";
import Button from "./Button";
import { Link } from "react-router-dom";
import Profile from "./Profile";
import { useEffect } from "react";
import EditSelect from "../../components/Chemistry/Atom/EditSelect";
import SelectOption from "../../components/Chemistry/Atom/SelectOption";
import Selector from "../ImageTemplate/Selector";
import { connect } from "react-redux";
export default connect(s => ({ isVideo: s.isVideo }))(function AppHeader({ isVideo }) {
  const [who, setWho] = useState(true);
  const handleTab = (_) => {
    return () => {
      // if (who !== _) setWho(!who);
      setWho(Math.random())
    };
  };
  useEffect(() => {
    document.setWho = setWho

  }, [])


  let rx = /\/(myvideos|myimages)/ig.exec(document.location.pathname)?.[0]

  return (
    <div className="AppHeader flex ">
      <div className="flex " >

        <Link to="/" onClick={handleTab(true)}>
          <img className="" src={`${document.PUBLIC_URL}/app/icon.svg`} alt="" />
        </Link>
        {/* <Selector /> */}
      </div>

      <div className="AppHeader_buttons flex">
        <Link to="/" onClick={handleTab(true)} >
          <Button value="Templates" isActive={document.location.pathname === "/"} />
        </Link>
        <Link to="/myvideos" onClick={handleTab(false)}>
          <Button
            value={"My Videos"}
            isActive={rx === "/myvideos"}
          />
        </Link>

        <Link to="/myimages" onClick={handleTab(false)}>
          <Button
            value={"My Image"}
            isActive={rx === "/myimages"}
          />
        </Link>
      </div>
      {/* <div>John Doe</div> */}
      <Profile />
    </div>
  );
})

