import React from "react";
export const MenuItem = ({ children, src, cmd, onClick = () => { } }) => {
    const handleClick = (e) => {

        onClick(e)
    }
    return <div className="MenuItem" onClick={handleClick}>
        <div className="flex row">
            <div className="MenuItem_icon">{src && <img src={src} alt={src}></img>}</div>
            <div className="MenuItem_text">{children}</div>
        </div>

        <div className="MenuItem_cmd">{cmd}</div>
    </div>
}