export const LOAD_FIRST = ({ state, data }) => {
  state.name = data.name;
  state.scenes = data.jsonData;
  if (1) {
    let arr = JSON.parse(state.scenes?.currentScene?.sceneArr).filter(
      (e) => Number(e) !== -1
    );
    state.scenes.currentScene.arr =
      state.scenes.currentScene.arr || JSON.stringify(arr);
    state.scenes.currentScene.sceneArr = state.scenes.currentScene.arr;
    document.sceneNumberFromIndex = arr.reduce(
      (a, b, i) => ({ ...a, [b]: i }),
      {}
    );
  }
};
