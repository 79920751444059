/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router";
import TitleRow from "../../../../../Chemistry/Atom/TitleRow";
import Vr from "../../../../../Chemistry/Atom/Vr";
import {
  ControlsBackground,
  InputRangeWithOutTitle,
  MakeVariableButton,
} from "../Background/BackgroundSettings";
import "./_ImageSettings.scss";
import TitleWrapper from "../../../../../Chemistry/Atom/TitleWrapper";
import Dimensions from "../../../../../Chemistry/Atom/Dimensions";
import { InputRangeWithTitle } from "../Background/BackgroundSettings";
import { fitImageToRect, getById, Index, Vid } from "../../../../../../utils";

import { OrderRow } from "../../../Avatar/Avatar";
import Angle from "../../../../../Chemistry/Atom/Angle";
// import DisabledWrapper from "../../../../../Chemistry/Atom/DisabledWrapper";
import { connect } from "react-redux";
import { deleteFromAll } from "../../../../../../utils/deleteCloneOpeationObj";
import ArrowWrapper from "../../../../../Chemistry/Atom/ArrowWrapper";
import ButtonIcon from "../../../../../Chemistry/Atom/ButtonIcon";
import Mask from "../../../../../Chemistry/One/Mask";
import Animation from "../../../../../Chemistry/Compound/Animation";
import objectCloner from "../../../../../../utils/objectCloner";
import { storeAnimatationToStore } from "../../../../../../utils/shortcutFunction";
import Shadow from "../../../../../Chemistry/One/Shadow";
import {
  startImageCropProcess,
} from "../../../../../../utils/cropyThings";
import { DelaySettingsInput } from "../../../../../Chemistry/One/DelaySettingsInput";
import { SIZES } from "../../../../../../utils/config";
import { replaceObj } from "../../../../../../utils/replaceImage";
import FilterApplier from "../../../../../../utils/FilterApplier";
import MaskApplier from "../../../../../../utils/MaskApplier";
import { fitCenterToCXX } from "../../../../../../utils";
import { discardCurrentWork } from "../../../../../../utils/useFullFunctions/discardWork";

export default function ImageSettings({ }) {
  const { url } = useRouteMatch();
  const { state } = useLocation();

  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(false)
    setTimeout(() => {
      setShow(true)
    }, 0)
  }, [state?.id])


  return (
    <>
      <Switch>
        <Route path={`${url}/mask`}>
          <Mask
            title="< Mask"
            type="mask"
            obj={getById(state.id)}
            onSelect={MaskApplier({ id: state.id })}
          />
        </Route>
        <Route path={`${url}/filter`}>
          <Mask
            title="< Filter"
            type="filter"
            obj={getById(state.id)}
            onSelect={FilterApplier({ id: state.id })}
          />
        </Route>
        {document.editorType === "video" && <Route path={url + "/animation"}>
          <Animation
            type="animation"
            title="< Animation"
            obj={getById(state.id)}
            onSelect={storeAnimatationToStore(state.id)}
          />
        </Route>}
        <Route path={url + "/shadow"}>
          <Shadow id={state.id} />
        </Route>
        <Route path={url} exact>
          {show && <DefaultPage />}
        </Route>
      </Switch>
    </>
  );
}

export const DefaultPage = connect((state) => ({
  frameActive: state.frameActive,
  mask: state.active.mask,
  objActive: state.active.objActive
}))(function DefaultPage({ frameActive, mask, objActive }) {
  const { url } = useRouteMatch();

  const { state } = useLocation();

  let obj = getById(state.id)
  // let id = state.id
  const [id, setId] = useState(state.id);
  useEffect(() => {
    // console.log(id);
    console.log(objActive);
    if (objActive) {

      setId(objActive);
    }
  }, [objActive])
  const handleChange = (e) => {
    discardCurrentWork({ _goB: true });
    const { cxx } = document;
    let obj = getById(id)
    if (obj) {
      obj.set({ opacity: e / 100 });
      obj.canvas.renderAll();
    }
  };
  const handleCrop = () => {
    startImageCropProcess(obj, document.cccx.current);
  };

  const handleDelete = () => {
    discardCurrentWork({ _goB: true });
    deleteFromAll(id);
  };

  const handleClone = () => {
    discardCurrentWork({ _goB: true });
    objectCloner({ _id: id, clone: true });
  };

  const handleGoChange = () => {
    // let obj = obj;
    discardCurrentWork({ _goB: true });
    document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/image`, {
      tab: 0,
      id: id,
    });
  };

  const handleSetVariable = (e) => {
    // let obj = obj;
    let VarURL = "https://api.autogenerate.ai/media/ImageMergeTagDefault.webp"

    replaceObj(obj, e ? VarURL : obj?._Image?.media_file || VarURL).then((obj) => {
      document.cxx[Index()].fire("object:modified");
    })

  };

  const handleFit = () => {
    // let obj = obj;
    discardCurrentWork({ _goB: true });
    if (obj) {
      fitImageToRect(obj, {
        top: 0,
        left: 0,
        height: SIZES[document?._size || 0].height,
        width: SIZES[document?._size || 0].width,
        // selectable: false,
      });
      document.cxx[Index()].fire("object:modified")

    }
  }

  const _handleOnClickSettings = (e) => {
    discardCurrentWork({ _goB: true });
  }

  return (
    <>
      {/* <DisabledWrapper isDisable={frameActive}>
     */}
      <div className="ImageSettings">
        <TitleRow title="Image Settings" />
        <ControlsBackground
          text="Replace Image"
          _1_src={`${document.PUBLIC_URL}/ico/replace.svg`}
          _4_src={`${document.PUBLIC_URL}/ico/clone.svg`}
          _6_src={`${document.PUBLIC_URL}/icons/expand.svg`}
          on_2={handleCrop}
          on_3={handleDelete}
          on_4={handleClone}
          on_1={handleGoChange}
          on_6={handleFit}
        />
        {/* <Vr m="10px 10px 10px" /> */}


        <Vr m="20px" />

        <TitleWrapper title="Arrange" onClick={_handleOnClickSettings}>
          <OrderRow id={id} />
        </TitleWrapper>


        <Vr m="0px 0 5px 5px" />


        {document.editorType === "video" && <>
          <ArrowWrapper
            m="15px"
            p="10px 0px"
            to={`${url}/animation`}
            data={{ id: id }}
            ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/animation.svg`} />}
          >
            Animation
          </ArrowWrapper>
          <Vr m="0px" />
        </>}

        <ArrowWrapper
          m="15px"
          p="10px 0px"
          to={`${url}/mask`}
          data={{ id: id }}
          ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/mask.svg`} />}
        >
          Mask
        </ArrowWrapper>
        <Vr m="0px" />
        <ArrowWrapper
          m="15px"
          p="10px 0px"
          to={`${url}/filter`}
          data={{ id: id }}
          ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/filter.svg`} />}
        >
          Filter
        </ArrowWrapper>

        <Vr m="0px" />
        <ArrowWrapper
          m="15px"
          p="10px 0px"
          to={`${url}/shadow`}
          data={{ id: id }}
          ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/shadow.svg`} />}
        >
          Shadow
        </ArrowWrapper>
        <Vr m="0 0 20px" />

        <MakeVariableButton
          obj={obj}
          onSelect={handleSetVariable}
          onClick={_handleOnClickSettings}
        />
        <Vr m="20px 0 0px" />
        <TitleWrapper title="Opacity">
          <InputRangeWithTitle
            value={Math.round(obj.opacity * 100)}
            className="ImagePanel_range"
            // onFullChange={(e) => alert(e.target.value)}
            onChange={handleChange}
            objActive={id}
          />
        </TitleWrapper>
        <Vr m="0 0 20px" />


        {/* <TitleWrapper title="Skew">
          <div className="OrderRow flex row1">

            <InputRangeWithOutTitle
              label="X"
              min={-50}
              max={50}
              postfix={""}
              value={obj.skewY}
              className="ImagePanel_range"
              style={{ width: "46%" }}

              // onFullChange={(e) => alert(e.target.value)}
              onChange={(e) => {
                if (obj) {
                  obj.set({ skewX: e });
                  obj.canvas?.renderAll();
                }
              }}
              objActive={id}
            />
            <InputRangeWithOutTitle
              label="Y"
              min={-50}
              max={50}
              postfix={""}
              value={obj.skewY}
              className="ImagePanel_range"
              style={{ width: "46%" }}
              onChange={(e) => {
                if (obj) {
                  obj.set({ skewY: e });

                  obj.canvas?.renderAll();
                }
              }}
              objActive={id}
            />
          </div>
        </TitleWrapper>
        <Vr m="0 0 20px" /> */}

        <TitleWrapper title="Layout" onClick={_handleOnClickSettings}>
          <Dimensions id={id} ele={<Angle id={id} />} />
        </TitleWrapper>
        <Vr m="0 0 20px" />



      </div>
      <DelaySettingsInput id={id}></DelaySettingsInput>
      {/* </DisabledWrapper> */}
    </>
  );
});
