import { useEffect, useState } from "react";
import Model from "reactjs-popup";
import Button from "../../../../App/Components/Button";
import uuid from "uuid-random";
import axios from "axios";
import "./_VideoRecording.scss"
import { token, url } from "../../../../utils";
import store from "../../../../store/store";

export function getDateFormatFileName(_date = new Date(), timeSep = "-") {
    let mm = _date.getMonth() + 1;
    let dd = _date.getDate();
    let _hr = _date.getHours();
    let _mn = _date.getMinutes();

    return `${_date.getFullYear()}-${(mm > 9 ? '' : '0') + mm}-${(dd > 9 ? '' : '0') + dd}_${(_hr > 9 ? '' : '0') + _hr}${timeSep}${(_mn > 9 ? '' : '0') + _mn}`;
};


function msToTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24); //milliseconds = Math.floor((duration % 1000) / 100),

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return minutes + ":" + seconds; // hours + ":" + minutes + ":" + seconds;
}


export const VideoRecordingPopup = ({
    onCancel = () => { },
    isConfirm,
    setConfirm = () => { },

}) => {
    const chunkMS = 200;
    const MAX_RECORDING_DURATION = 300; // IN SECONDS

    const [isPause, setPause] = useState(false);
    const [isStreamEnded, setStreamEnded] = useState(false);
    const [recordingDuration, setRecordingDuration] = useState(0);

    const stopMediaRecorder = async () => {
        if (document._videoRecording.mediaRecorder?.state && document._videoRecording.mediaRecorder?.state !== 'inactive') {
            await document._videoRecording.mediaRecorder.stop();
        }
    }

    useEffect(() => {
        console.log(isConfirm, 'confirm')
        if (isConfirm && document._videoRecording?.stream) {
            document._videoRecording.mediaRecorder = new MediaRecorder(document._videoRecording.stream);
            setStreamEnded(false)

            document._videoRecording.mediaRecorder.ondataavailable = function (e) {
                if (e.data.size > 0) {
                    document._videoRecording.chunks.push(e.data);
                    const _videoDurt = document._videoRecording.chunks.length * 200;
                    setRecordingDuration(_videoDurt)
                    if (_videoDurt > MAX_RECORDING_DURATION * 1000) {
                        stopMediaRecorder();
                    }
                }
            };
            document._videoRecording.stream.oninactive = function () {
                stopMediaRecorder();
            }

            document._videoRecording.mediaRecorder.onstop = function () {
                console.log('stop media recording')
                let tracks = document._videoRecording.stream.getTracks();
                tracks.forEach(track => track.stop());
                document._videoRecording.stream = null;
                setStreamEnded(true)

            };
            document._videoRecording.mediaRecorder.start(chunkMS); // For every 200ms the stream data will be stored in a separate chunk.
        }
    }, [isConfirm]);



    const handleDiscard = async () => {
        await stopMediaRecorder();
        setTimeout(() => {
            document._videoRecording.chunks = []
            document._videoRecording.mediaRecorder = null;
            setConfirm(false);
            onCancel();
        }, 100)
    };

    const uploadVideoChunk = (blob) => {

        const name = `Screen Recording ${getDateFormatFileName()}`;
        let formData = new FormData();
        formData.append("name", name);
        formData.append("category", "autovid_video");
        formData.append("media_file", blob, name.replace(' ', '_') + '.webm');
        let id = uuid();
        let size = blob.size;
        document.FeedBack.pushList({ id, item: { name, size } });
        axios.post(`${url}/api/userlibrary/upload/?process=1`, formData, {
            headers: { Authorization: `Token ${token}` },
            onUploadProgress: (e) => {
                // console.log((e.loaded / e.total) * 100);
                document.FeedBack.updatePrgress({
                    id,
                    progress: (e.loaded / e.total) * 100,
                });
            },
        }).then((res) => {
            let obj = res.data;

            store.dispatch({
                type: "MEDIA_WITH_FRONT",
                data: { type: "video_upload", list: [obj] },
            });
        })
            .catch((err) => {
                console.log(err);
            });

    };

    const handleSave = async () => {
        // upload chunk to server
        await stopMediaRecorder();

        const blob = new Blob(document._videoRecording.chunks, {
            type: 'video/webm'
        });
        uploadVideoChunk(blob);
        document._videoRecording.chunks = []
        document._videoRecording.mediaRecorder = null;

        setConfirm(false);

    };

    const handlePauseResume = () => {
        if (!isStreamEnded) {
            if (isPause) {
                document._videoRecording.mediaRecorder.resume();
                setPause(false)
            } else {
                document._videoRecording.mediaRecorder.pause();
                setPause(true)

            }
        }

    };


    return (
        <Model
            open={isConfirm}
            modal
            onClose={() => setConfirm(false)}
            closeOnDocumentClick={false}
        >
            {(close) => {
                return (
                    <div className="Popup flex column">
                        <div className="Popup_header flex">
                            <div className="Popup_header_name">Video Recording</div>
                            <div className="Popup_header_close">
                                {msToTime(recordingDuration)}
                            </div>
                        </div>
                        <div className="Popup_content">
                            {/* <img
                                src={`${document.PUBLIC_URL}/ico/deleteIconPopup.svg`}
                                className="DeletePopup_icon"
                                alt=""
                            /> */}
                            <Button onClick={handleDiscard} value="Discard" className="video_recording_btn"></Button>
                            <Button onClick={handleSave} value="Save" className="video_recording_btn"></Button>
                            <Button onClick={handlePauseResume} value={!isPause ? "Pause" : "Resume"} className={isStreamEnded ? "video_recording_btn video_recording_btn_disable" : "video_recording_btn"}></Button>
                        </div>

                    </div>


                );
            }}
        </Model>
    );
};

