import LinkPopup, { TopCardNoSharePage } from "./LinkPopup";
import { connect } from "react-redux";
import Button from "../Button";
import Modal from "reactjs-popup";
import Popup from "../Popup";
import ButtonIcon from "../../../components/Chemistry/Atom/ButtonIcon";
import "./_EEE.scss";
import React, { Suspense, useState } from "react";
import SharingPopup from "./SharingPopup";
import VariablePopup from "./VariablePopup";
import {
  TopCard,
  PerImage,
  LinkHistoryTab,
  BatchHistoryTab,
  NewPersonalized,
} from "./LinkPopup";
import HowitWorks from "./HowItsWorking"
import MailPopup from "./MailPopup";
import InfiniteWrapper from "../../../components/Chemistry/Atom/Infinite";
import mystore, { vid } from "../../appStore";
import { CopyLink } from "./DownloadPopup";
import copy from "copy-to-clipboard";
import { url, HideAndShowHandler } from "../../../utils";
import InfiniteNewWrapper from "../infiniteNewWrapper";
const ChoosePopup = React.lazy(() =>
  import(
    "../../ImagePersonalised/ChoosePopUpLoad" /* webpackChunkName: "ImagePersionalized" */
  )
);






export default connect((state) => ({ my: state.mydetail, list: state.media.solomail_history.list, next: state.media.solomail_history.next, batchList: state.media.batch_history.list, batchnext: state.media.batch_history.next }))(function LinkPopup({
  my, list, batchList, next, batchnext,
  trigger = (
    <Button
      className={`${mystore?.getState().mydetail?.mainVideoGenerate?.status !== 1 ? "Disabled-button-generate-invarPopup" : ""}`}

      id={"_shareMailBtn"}
      value={
        <>
          Share via Mail <img src={`${document.PUBLIC_URL}/app/mail.svg`} alt="mail"></img>


        </>
      }
    />
  ),
  name = "Share via Mail",
}) {

  const [tab, settab] = useState(0);



  function SoloLinkShare({ type = "solomail_history" }) {
    return (
      <div className="PersonalisedHistory_list" id={"solomail_history_div"}>
        {/* {my.linkHistory.map((e) => (
        <LinkHistoryTab
          key={e.id}
          date={e.timestamp}
          url={e.url}
          variableValue={e.variableValue}
        />
      ))} */}

        <InfiniteNewWrapper target={"solomail_history_div"} type={type} mystore={mystore} url={next || `${url}/api/newvideo/video/generate/solomail/${vid()}/`} >
          {list.map((e) => (
            <LinkHistoryTab
              key={e.id}
              id={e.id}
              date={e.timestamp}
              progress={e.progress}
              status={e.status}
              url={e.campaignUrl}
              // variableValue={e.variableValue}
              full={e}
              type={type}
              code={e.code}
            />
          ))}
        </InfiniteNewWrapper>
      </div>
    )
  }
  function BatchShare({ type = "batch_history" }) {
    return (
      <div className="PersonalisedHistory_list" id={"batch_history_div"}>
        {/* {my.linkHistory.map((e) => (
        <LinkHistoryTab
          key={e.id}
          date={e.timestamp}
          url={e.url}
          variableValue={e.variableValue}
        />
      ))} */}

        <InfiniteNewWrapper target={"batch_history_div"} type={type} mystore={mystore} url={batchnext || `${url}/api/newvideo/video/generate/batch/${vid()}?type=1`} >
          {batchList.map((e) => (
            <BatchHistoryTab
              key={e.id}
              date={e.timestamp}
              url={e.previewUrl}
              id={e.id}
              fileName={e.fileName}
              // variableValue={e.variableValue}
              full={e}
              type={type}
              code={e.code}
              progress={e.progress}
              status={e.status}
            />
          ))}
        </InfiniteNewWrapper>
      </div>
    )
  }


  const copyMe = (data) => {
    navigator.clipboard.writeText(data);
    copy(data)
    let oldText = document.getElementById(`copy-${my.id}`).innerHTML
    document.getElementById(`copy-${my.id}`).innerHTML = "Copied !";
    setTimeout(() => {
      document.getElementById(`copy-${my.id}`).innerHTML = oldText;
    }, 600)
    return data
  }

  return (
    <Modal modal trigger={trigger} nested >
      {(close) => (
        <Popup
          name={name}
          UpperId="Mailpopup-1x1"
          close={close}
          haveButton={false}
          rightHeader={
            <>
              <Button onClick={() => window.open(my.link, "_blank")} value={<div>Live Preview <img src={`${document.PUBLIC_URL}/app/up.svg`}></img></div>} className="ButtonIcon" />
              <HowitWorks trigger={<ButtonIcon value={<div>How it works?</div>} icon=" " />} />


              <MailPopup
                trigger={
                  <ButtonIcon
                    value={<div>Email Client - {my.mailClient?.name}</div>}
                    src={`${document.PUBLIC_URL}/app/mail.svg`}

                  />
                }
              />
            </>
          }
        >
          <div className="flex column">
            <div className="LinkPopop flex ">
              <Suspense fallback={<div>Loading...</div>}>
                <ChoosePopup
                  Detail={my}
                  trigger={
                    <button className="BadButton" id={"_mailPopupBtnT"}>

                      <TopCard
                        name={`From ${my.thumbnailInst.name}`}
                        title="Thumbnail"
                        src={my.thumbnailInst.thumbnail}
                        have={my.thumbnailInst.isPersonalized}
                      />
                    </button>
                  }
                  nowImage={{ id: 35, category: 0, name: "Guslhan" }}
                />
              </Suspense>

              <SharingPopup
                onOpenHide={() => {
                  HideAndShowHandler("Mailpopup-1x1", "none")
                }}
                onCloseShow={() => {
                  HideAndShowHandler("Mailpopup-1x1", "block")

                }}
                trigger={
                  <button className="BadButton" id={"_mailPopupBtn"}>
                    {my.sharingPage != null ? <TopCard
                      name={`${my.sharingPage?.name}`}
                      src={my.sharingPage?.previewImage}
                      have={my.sharingPage?.isPersonalized}
                      title="Sharing Page"
                    /> :
                      <TopCardNoSharePage

                        have={false}
                        title="Sharing Page"

                      />

                    }
                  </button>
                }
              />

              {(my.isPersonalized) ? ( //|| my?.sharingPage?.isPersonalized || my.thumbnailInst.isPersonalized
                <VariablePopup
                  onOpenHide={() => {
                    HideAndShowHandler("Mailpopup-1x1", "none")
                  }}
                  onCloseShow={() => {
                    HideAndShowHandler("Mailpopup-1x1", "block")

                  }}
                  // haveBatch={false}
                  selectedTab={tab}
                  type="solomail_history"
                  trigger={
                    <button className="BadButton">
                      <NewPersonalized />
                    </button>
                  }
                ></VariablePopup>
              ) : (
                <CopyLink onCopy={() => copyMe(my.code)} id={`copy-${my.id}`} url={my.link || my.code} haveLink={false} text="Copy Code !" status={1} type="copy" icon={`${document.PUBLIC_URL}/app/copyLink.svg`} />
              )}
            </div>
            {(my.isPersonalized) ? //my.batchMailGenCount > 0 || my.soloMailGenCount > 0
              <div className="PersonalisedHistory">
                <div className="PersonalisedHistory_title">
                  Personalised Mail History
                </div>
                <div className="PersonalisedHistory_list">
                  <div>    <>
                    {true && (
                      <div className="VariablePopup_tab">
                        {["Solo Mail", "Batch Mail"].map((e, i) => {
                          return (
                            <div
                              onClick={() => {
                                settab(i);
                              }}
                              key={e}
                              className={`VariablePopup_tab_tab ${tab === i ? "VariablePopup_tab_tab_active" : ""
                                }`}
                            >
                              {e}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <div className="VariablePopup">

                      {tab === 0 && (<>
                        <div className="VariablePopup_flex">
                          <SoloLinkShare />
                        </div>
                      </>)}
                      {tab === 1 && (
                        <>
                          <div className="VariablePopup_flex">

                            <BatchShare type="batch_history" />
                          </div>

                        </>
                      )}
                    </div>
                  </></div>
                </div>
              </div>
              :
              <></>
            }



          </div>
        </Popup>
      )}
    </Modal>
  );
});
