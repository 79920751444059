/* eslint-disable */
import { fabric } from "fabric";
import { getById, Index, Vid, fitImageToRect } from "../../../../../utils";
import { deleteFromAll } from "../../../../../utils/deleteCloneOpeationObj";

import store from "../../../../../store/store";
import { SIZES } from "../../../../../utils/config";
import { askConvertedVideo } from "../../../../../utils/videoPlayThings/addVideoToCanvasAndStore";
import { InitAddObjectEvents } from "../../../../../utils/InitAddObjectEvents";

let typeMap = {
  color: 0,
  video: 2,
  upload: 3,
  image: 1,
};

export const addBackground = {
  insert: (cxx, returnToUrl = false, discard = false) => {
    let zoom = cxx?.getZoom() || 1;
    let rect = new fabric.Rect({});
    cxx.add(rect);
    rect.set({
      selectable: false,
      evented: false,
      id: `background${Index()}`,
      fill: "#ffffff",
      top: -1,
      left: -1,
      width: SIZES[document?._size || 0].width + 1,
      height: SIZES[document?._size || 0].height + 1,
    });
    rect.canvas.sendToBack(rect);
    InitAddObjectEvents({
      obj: rect,
      type: "background",

      name: "Background",

      wantDeselection: false,

      returnToUrl,
      _id: `background${Index()}`,
      data: { tab: 0 },
      returnToUrl: false,
      onAddActive: false,
      timeLineData: {
        adjustLength: 3,
        enter: 0,
        exit: 0,
        trimStart: 0,
        trimEnd: 10,
      },
    });
    if (!discard) cxx.discardActiveObject();
  },
  insertImage: async (e, cb, returnToUrl = false, discard = false) => {
    //console.log(e);
    // const { cxx: canvas, index } = document;
    const _index = Index();
    store.dispatch({ type: "LOADING", data: "Background is Loading ..." });
    let _Image = e;
    let { res } = await askConvertedVideo(e.id, e.ctg, () => { }, "image", e);
    _Image = res;
    if (!res) {
      return;
    }

    const { cxx: canvas, index } = document;
    canvas.backgroundColor = null;
    fabric.Image.fromURL(_Image.media_file, function (img) {
      console.log("BG");
      let cxx = canvas[_index];
      let preImg = getById(`background${_index}`, _index);
      cxx.remove(preImg);
      cxx.add(img);

      img._Image = _Image;
      img.set({ id: `background${_index}`, top: 0, left: 0 });

      fitImageToRect(img, {
        left: -1,
        top: -1,
        height: SIZES[document?._size || 0].height + 1,
        width: SIZES[document?._size || 0].width + 1,
      });
      img.canvas.sendToBack(img);

      InitAddObjectEvents({
        obj: img,
        type: "background",
        wantDeselection: false,
        name: "Background",
        _id: `background${Index()}`,
        returnToUrl: false,
        onAddActive: false,
        data: { tab: typeMap[e.type] },
        timeLineData: {
          adjustLength: 3,
          enter: 0,
          exit: 0,
        },
      });
      store.dispatch({ type: "LOADING", data: "Background is Loading ..." });
    });

    cb();
  },
  p: true,
  changeColor: function (color, id) {
    let obj = getById(id || `background${Index()}`, Index());
    let p = true;

    if (obj.type === "rect") {
      obj.set({ fill: color });
      obj.canvas.renderAll();
      this.p = true;
    } else if (obj.type === "image" && this.p) {
      let cxx = obj.canvas;
      deleteFromAll(id || `background${Index()}`);
      this.insert(cxx);
      this.changeColor(color, id);
      this.p = false;
    }
  },
};
