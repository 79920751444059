import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import store from "../../../../store/store";
import { getById, Jump } from "../../../../utils";
import ErrorBoundary from "../../../Chemistry/Atom/ErrorBoundry";
import BackgroundSettings from "./Components/Background/BackgroundSettings";
import ElementSetting from "./Components/Elements/ElementSetting";

import ImageSettings from "./Components/Image/ImageSettings";
import MusicSettings from "./Components/Music/MusicSettings";
import TextSettings from "./Components/Text/TextSettings";
import VideoSettings from "./Components/Video/VideoSettings";
import GroupSettings from "./Components/GroupSettings/GroupSettings";



const PrimarySetting = () => {
  useEffect(() => {
    let obj = getById(document.preId);
    if (obj && obj._Type !== "group") {

      obj.canvas.setActiveObject(obj)
      document.hist.push(
        Jump({
          obj,
        }),
        { id: obj.id }
      );

      setTimeout(function CxxEventsActive() {
        store.dispatch({
          type: "SET_ACTIVE_OBJECT",
          data: obj.id,
        });
      }, 0);
      obj.canvas.renderAll()
    }
  }, [])
  return <div className="flex center" style={{
    height: "80%",
    fontSize: "24px",
    textAlign: "center",
    padding: "20px"
  }}>
    Please select an item to view it's settings
  </div >
}

export default function Settings() {

  return (
    <ErrorBoundary>
      <Switch>
        <Route path={`/${document.editorType}/:vid/:id/settings/background`}>
          <BackgroundSettings />
        </Route>
        <Route path={`/${document.editorType}/:vid/:id/settings/text`}>
          <ErrorBoundary>
            <TextSettings />
          </ErrorBoundary>
        </Route>

        <Route path={`/${document.editorType}/:vid/:id/settings/image`}>
          <ErrorBoundary>
            <ImageSettings />
          </ErrorBoundary>
        </Route>

        <Route path={`/${document.editorType}/:vid/:id/settings/elements`}>
          <ErrorBoundary>
            <ElementSetting />
          </ErrorBoundary>
        </Route>

        <Route path={`/${document.editorType}/:vid/:id/settings/music`}>
          <ErrorBoundary>
            <MusicSettings />
          </ErrorBoundary>
        </Route>
        <Route path={`/${document.editorType}/:vid/:id/settings/video`}>
          <ErrorBoundary>
            <VideoSettings />
          </ErrorBoundary>
        </Route>
        <Route path={`/${document.editorType}/:vid/:id/settings/group`}>
          <ErrorBoundary>
            <GroupSettings />
          </ErrorBoundary>
        </Route>
        <Route path={`/${document.editorType}/:vid/:id/settings`}>
          <ErrorBoundary>
            <PrimarySetting />
          </ErrorBoundary>
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}
