import React from 'react'
import Popup from 'reactjs-popup';
import infoImg from "../../extras/info.svg"
export default function InfoIcon(props) {

    return (
      <span className="info-logo-new" style={{...props.iconStyle}}>
      <Popup
      className="infologo"
trigger={
<img src={infoImg} alt=""/>
}
position={props.pos?props.pos:"right center"}
closeOnDocumentClick
nested
on={['hover']}
>
<div className="popup-info arrow242424" style={{...props.style}}> {props.data?props.data:""} </div>
</Popup>
      
      </span>
        
    )
}
