/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DisabledWrapper from "../../Chemistry/Atom/DisabledWrapper";
import TimeElement from "../../Chemistry/One/TimeElement";
import "./_TimeBar.scss";
import { getById, Index, updateTimeLen } from "../../../utils";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Rnd } from "react-rnd";
import { customSeek } from "../../../utils/videoPlayThings";
import { universalPause } from "../../../utils/videoPlayThings/universalPause";
import Time from "./Time";
import { updateVideosTime } from "../../../utils/videoPlayThings/updateVideosTimeHelper";
import { useMemo } from "react";
import { useCallback } from "react";
import store from "../../../store/store";
import { UpdateBackground } from "../../Chemistry/One/Draggy";
import { resizeAll } from "../../../utils/useFullFunctions/resizeCanvas";
export default connect(
  (state) => ({
    frameActive: state.frameActive,

    haveEasyMode: state.scenes?.[state.sceneNumber]?.haveEasyMode,
    smode: state.scenes[state.sceneNumber].mode,
    mode: state.scenes.mode,
    sceneNumber: state.sceneNumber,
    haveVid: state.active.haveVid,
    _elementList: state.scenes[state.sceneNumber].elementList,
    random: state.random
  }),
  (dispatch) => {
    return {
      sortObject: (data) => {
        dispatch({ type: "SORT_OBJECT", data });

        document.cxx[Index()].fire("object:modified");
      },
    };
  }
)(function TimeBar({

  sceneNumber,
  _elementList,
  sortObject,
  haveEasyMode,
  smode,
  haveVid,
  random

}) {
  let elementList = JSON.parse(_elementList)
  // let elementList = ;
  // console.log(elementList);
  const handleSort = (e) => {
    // console.log(e.layerX);
    // let list = document.querySelector(".List");
    // console.log();
  };






  const handleEasyToProMode = () => {
    store.dispatch({ type: "ENABLE_PRO_MODE" });
    document.outPlayMode();
  };
  const Element = SortableElement((props) => <TimeElement  {...props} />);
  const List = SortableContainer(({ list }) => {
    return (
      <div className="List">
        {list
          .filter((e) => {
            if (getById(e)?._Type === "background") {
              return false
            }
            if (smode === 2) {
              return getById(e)?._Type !== `avatar`;
            } else {

              return true
            }


          })
          .map((e, i) => (
            <Element
              key={i}
              length={list.length - 1}
              index={i}
              id={e}
              // disabled={getById(e)?._Type === "background"}
              obj={getById(e)}
            />
          ))}

        {haveEasyMode && (
          <div className="haveEasyMode">
            <button className="GenerateButton" onClick={handleEasyToProMode}>
              Switch to PRO mode{" "}
            </button>
            <div className="small">
              With PRO mode, adjust timeline manually. This action cannot be
              reverted!
            </div>
          </div>
        )}
      </div>
    );
  });
  useEffect(() => { }, [elementList]);
  const handleSortStart = (e) => {
    // let list = document.querySelector(".List");
    // list.scrollLeft = 10;
  };
  return (
    <div className="TimeBar">
      {/* <DisabledWrapper isDisable={frameActive}> */}
      {/* <div className="TimeScene">abc</div> */}
      <SeekBar haveEasyMode={haveEasyMode} />
      <Time />
      <List
        haveEasyMode={haveEasyMode}
        lockToContainerEdges={true}
        axis={"y"}
        lockAxis="y"
        useDragHandle={true}
        list={elementList}
        onSortEnd={sortObject}
        onSortMove={handleSort}
        onSortStart={handleSortStart}
      />
      {/* </DisabledWrapper> */}
    </div>
  );
});

const SeekBar = connect(state => ({
  sceneNumber: state.sceneNumber
}))(({ haveEasyMode, sceneNumber }) => {
  const [x, setX] = useState(2);
  useEffect(() => {
    document.txx.setX = setX;
  }, []);
  const handleSeekDrag = useCallback((e, p) => {
    // document.outPlayMode();

    if (document._Max) {
      setX(Math.min(Math.max(p.x, 1), document._Max));
    }
    let seekTo = Math.min(Math.max(p.x, 1), document._Max) / document.per;
    // setTimeout(() => {
    //   updateVideosTime(seekTo);
    // }, 0);
    // universalPause({});

    if (document.screenChheda) {
      customSeek(seekTo, Index(), true);
    } else {
      document.Player.seek(seekTo)
    }
    // handleSeekDrag();
  }, [sceneNumber]);

  const [wx, setWx] = useState(document._Max);

  const handleDrag = (a, b, c, d) => {
    // console.log(a, b, c, d);
    setWx(b.x);
  };

  useEffect(() => {
    setWx(document._Max)
    // console.log({ wx });
  }, [sceneNumber])

  document.setWx = setWx;


  const handleWorkFullDrag = (a, b, c, d) => {
    store.dispatch({
      type: "WORKSPACE_RESIZE",
      data: {
        next: wx / document.per,
        now: document._Max / document.per,
        index: Index()
      },
    });
    // UpdateBackground();
    // updateTimeLen();
  };
  const handleClick = () => {
    console.log("CLICk");
  }
  return (
    <div
      className="SeekBar_container"
      style={
        {
          width: `${document._Max + 900}px`,
        }
      }
    // onClick={handleClick}
    >
      <Rnd
        bounds=".SeekBar_container"
        className="SeekBar"
        position={{ x: x, y: 0 }}
        size={{ width: "3px", height: "200%" }}
        enableResizing={{}}
        onDrag={handleSeekDrag}
        onDragStop={(e) => {
          const _time = x / document.per;
          if (document.screenChheda || !(document.Player?.status !== 2 || document.Player?.status !== 1)) {
            updateVideosTime(_time);
          } else {
            document.Player.seek(_time, true);
          }
        }}
      >
        <div id="triangle"></div>
        {/* <div className="time_seek">
          {Number(Math.abs(x / document.per)).toPrecision(2)}s
        </div> */}
      </Rnd>

      <Rnd
        bounds=".SeekBar_container"
        className="Worksace"
        position={{ x: wx || 0, y: 0 }}
        size={{ width: "10px", height: "40px" }}
        enableResizing={{}}
        onDragStop={handleWorkFullDrag}
        onDrag={handleDrag}
        disableDragging={haveEasyMode}
      ></Rnd>
    </div>
  );
});

export const TimeLineClose = ({ smode }) => {
  const [isClosed, setClose] = useState(true);
  // useEffect(() => {
  //   handleClose();
  // }, []);
  const handleClose = () => {
    document.documentElement.style.setProperty(
      "--timelineHeight",
      !isClosed ? "75px" : "254px"
    );
    setClose(!isClosed);

    resizeAll();

    // document.firstResize[Index()]();

    // Object.keys(document.firstResize).filter(e => e !== Index()).forEach((e) => {
    //   setTimeout(() => {

    //     document.firstResize[e]();
    //   }, 0)
    // });
    // // setTimeout(())
    // setTimeout(() => {
    //   document.cropResize()
    // }, 10)


  };
  return (
    <div className="TimeBar_close" onClick={handleClose}>
      <img
        src={`${document.PUBLIC_URL}/icons/timelineclose.svg`}
        alt="close"
        style={{ transform: `rotateZ(${!isClosed ? "0deg" : "180deg"})` }}
      />
    </div>
  );
};

export const AvtarSpeechClose = connect((s) => ({ mode: s.scenes.mode }))(({ mode }) => {
  const [isClosed, setClose] = useState(false);

  const handleClose = () => {
    if (document.editorType === "video") {
      document.documentElement.style.setProperty(
        "--rigthWidth",
        !isClosed ? "0" : "334px"
      );
      setClose(!isClosed);



      resizeAll();
      // document.firstResize[Index()]();
      // setTimeout(() => {
      //   Object.keys(document.firstResize).filter(e => e !== Index()).forEach((e) => {
      //     document.firstResize[e]();
      //   });
      //   document.cropResize()

      // }, 10)
    }





  };
  useEffect(() => {
    if (mode) {
      handleClose()
    }
  }, [])
  return (
    (!mode) && <div className="AvtarSpeechClose" onClick={handleClose}>
      <img
        src={`${document.PUBLIC_URL}/icons/timelineclose.svg`}
        alt="close"
        style={{ transform: `rotateZ(${!isClosed ? "-90deg" : "-270deg"})` }}
      />
    </div>
  );
});
