import store from "../store/store";
import { getById } from "../utils";
import ImageFilters from "./fabricFilters";

export default function FilterApplier({ id }) {
  return (mask) => {
    let obj = getById(id);
    obj._Filter = mask;
    store.dispatch({ type: "LOADING", data: "Applying the Filter" });

    if (obj._originalElement.crossOrigin !== "anonymous") {
      obj._originalElement.crossOrigin = "anonymous";
      obj._originalElement.onload = () => {
        obj.canvas.renderAll();
        let _filterName = mask.name;
        obj.filters = ImageFilters[_filterName];
        obj.applyFilters();
        obj.canvas.renderAll();
      };
    } else {
      let _filterName = mask.name;
      obj.filters = ImageFilters[_filterName];
      obj.applyFilters();
      obj.canvas.renderAll();
    }
    // console.timeEnd("time");
    store.dispatch({ type: "LOADING", data: "Applying the Filter" });
  };
}
