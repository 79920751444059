import { memo } from "react";
import { Index, Vid } from "../../../utils";
import "./_Atom.scss";

function ThreeCard({
  src,
  className = "",
  asrc,
  title,
  ele,
  isActive = false,
  index,
  onClick = () => { },
  onMouseOver = () => { },
  onMouseOut = () => [],
  colors,
  sindex,
}) {
  ////console.log(`ThreeCard : ${title} Renders`);

  return (
    <div
      onMouseEnter={onMouseOver}
      onMouseOut={onMouseOut}
      className={`ThreeCard ${className}`}
    >
      <div
        className={`ThreeCard__wrapper ${isActive ? "ThreeCard__active" : ""}`}
        onClick={() => onClick(index)}
      >
        {ele ||
          (src && (
            <img
              className="ThreeCard__wrapper__Image"
              src={isActive ? asrc : src}
              alt={"imdage"}
            />
          ))}
      </div>
      {colors && isActive ? (
        <label htmlFor="" className="colors_label">
          <div>{title}</div>{" "}
          <div className="colors">
            {colors.map((e, i) => (
              <div
                style={{ background: e }}
                className="colors_color"
                onClick={(k) => {
                  document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/color/`, {
                    type: "sanimation",
                    index: i,
                    color: e,
                    sindex: sindex,
                  });
                }}
              ></div>
            ))}
          </div>{" "}
        </label>
      ) : (
        <label htmlFor="">{title}</label>
      )}
    </div>
  );
}
export default memo(ThreeCard);
