import React, { useState, useCallback, useRef } from "react";

import { connect } from "react-redux";
import DragUpload from "../../../Chemistry/Atom/DragUpload";
import InfiniteWrapper from "../../../Chemistry/Atom/Infinite";
import MusicCard from "../../../Chemistry/Atom/MusicCard";
import Tabium from "../../../Chemistry/Compound/Tabium";
import "./_Music.scss";
import axios from "axios";
import store from "../../../../store/store";
import { url, token, Index, getById } from "../../../../utils";
import uuid from "uuid-random";
import { fabric } from "fabric";
import {
  addEventsInAudio,
  addMusicToAllCxx,
  customVideoSeek,
} from "../../../../utils/videoPlayThings";
import { useLocation } from "react-router";
import { InitAddObjectEvents } from "../../../../utils/InitAddObjectEvents";
import { display } from "../../../../utils/videoPlayThings/addVideoToCanvasAndStore";


export default connect((state) => ({
  list: state.media.music.list,
  list2: state.media.music_upload.list,
}))(function Music({ list, list2 }) {
  const ref = useRef();
  const [music, setMusic] = useState({ time: "5:6" });
  const { state } = useLocation();
  // useEffect(() => {}, []);
  const handlePlay = (e) => {
    return (k) => {
      ////console.log(e);
      let a = ref.current;
      if (k) {
        setMusic({});
      } else {
        setMusic(e);
      }
      // a.src = e.media_file;
      document.a = a;
    };
  };

  const handleSelect = (e) => {
    return async () => {
      // let id = uuid();
      let id = `_${uuid()}`;
      if (state?.id) {
        id = state.id;
        store.dispatch({
          type: "LLOAD",
          data: "Music",
        });
        let src = await display(e.media_file, state.id);
        document.getElementById(state.id).src = src;
        store.dispatch({
          type: "LLOAD",
          data: "Music",
        });
        const _musicElm = store.getState().scenes[Index()].elements[state.id];
        if (_musicElm.adjustLength === 1) {
          // handle end at scene replace
          let obj = getById(state.id, null, document.cxx[Index()]);
          if (obj) {
            obj._Name = e.name;
            obj._Music = e;
            obj._Src = src;
            obj.canvas?.fire("object:modified")
            let duration = e.fileInfo.duration
            store.dispatch({
              type: "EDIT_ELEMENT", data: {
                id: state.id,
                data: {
                  trimStart: 0,
                  trimEnd: duration,
                  duration,

                }
              }
            })
          }
        } else {
          // handle loop replace
          JSON.parse(store.getState().scenes.currentScene.arr).forEach(index => {
            let obj = getById(state.id, null, document.cxx[index] || { _objects: [] });
            if (obj) {
              obj._Name = e.name;
              obj._Music = e;
              obj._Src = src;
              obj.canvas?.fire("object:modified")
            }
          })
          let duration = e.fileInfo.duration
          store.dispatch({
            type: "EDIT_ELEMENT", data: {
              id: state.id,
              data: {
                trimStart: 0,
                trimEnd: duration,
                duration,

              }
            }
          })
        }
      } else {
        store.dispatch({
          type: "LLOAD",
          data: "Music",
        });
        const Media = document.querySelector(".Media");
        const audEle = document.createElement("audio");
        audEle.customVideoSeek = customVideoSeek;
        audEle.src = await display(e.media_file, id);

        audEle.preload = "auto";
        audEle.id = id;
        Media.appendChild(audEle);

        audEle.onloadedmetadata = () => {
          let rect = new fabric.Rect({
            width: 5,
            height: 5,
            fill: "rgba(0,0,0,0)",
            // selectable: true,
            left: -10,
            top: -10,
          });
          // rect.adjustLength = 1;
          document.cxx[Index()].add(rect);
          // document.musicLoop[id] = rect;
          // addMusicToAllCxx

          //console.log(e);
          rect._Music = e;
          rect._Volume = 1;
          rect._Src = e.media_file;
          InitAddObjectEvents({
            obj: rect,
            _id: id,
            // name: e.name,
            name: document.duplicateName.getName({ type: "music", name: e.name }),

            type: "music",
            media: e,


            timeLineData: {
              duration: e.fileInfo.duration,
            },
            returnToUrl: false,
            onAddActive: false,
          });
          // document.cxx[Index()].setActiveObject(rect);

          document.musicLoop[id] = rect;
          addMusicToAllCxx({ id: id, index: Index() });
          store.dispatch({
            type: "EDIT_ELEMENT",
            data: {
              id: id,
              data: { adjustLength: 4 },
            },
          });

          addEventsInAudio(audEle);
          store.dispatch({
            type: "LLOAD",
            data: "Music",
          });
          document.cxx[Index()].setActiveObject(rect);

        };
      }

    };
  };
  return (
    <div className="Music ">
      <audio id="muicPlayerLeft" ref={ref} style={{ display: "none" }} />

      <Tabium arrTab={[{ name: "Stock Media" }, { name: "Uploads" }]}>
        <div id="music_wrapper">
          <InfiniteWrapper type="music" target="music_wrapper" max={10}>
            {list.map((e) => (
              <MusicCard
                onPlay={handlePlay(e)}
                // name={e.name}
                // time={e.time}
                musicId={e.id}
                hasOptions={false}
                src={e.media_file}
                time={e.fileInfo?.duration}
                isPlayed={e.id === music.id}
                refi={ref}
                name={e.name}
                type={"music"}
                onSelect={handleSelect({ ...e, type: "music" })}
              />
            ))}
          </InfiniteWrapper>
        </div>

        <MusicTabUpload
          list={list2}
          max={10}
          type="music_upload"
          handleSelect={(k) => handleSelect({ ...k, type: "music" })}
        />
      </Tabium>
    </div>
  );
});

export const MusicTabUpload = ({
  list,
  max = 10,
  type = "music_upload",
  handleSelect = () => { },
  categroy = "music",
  text,
  ico,
  customUpload,
  selectedId,
}) => {
  // let id = uuid();
  const [music, setMusic] = useState({ time: "5:6" });
  const [id, setID] = useState(uuid());
  const ref = useRef();
  const handlePlay = (e) => {
    return (k) => {
      ////console.log(e);
      let a = ref.current;
      if (k) {
        setMusic({});
      } else {
        setMusic(e);
      }
      // a.src = e.media_file;
      document.a = a;
    };
  };

  const handleDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      const { name, size } = acceptedFiles[0];
      if (acceptedFiles[0].size < 100 * 2 ** 20) {
        let formData = new FormData();
        formData.append("name", name);
        formData.append("category", categroy);
        formData.append("media_file", acceptedFiles[0]);
        let id = uuid();
        document.FeedBack.pushList({ id, item: { name, size } });

        axios
          .post(`${url}/api/userlibrary/upload/`, formData, {
            headers: { Authorization: `Token ${token}` },
            onUploadProgress: (e) => {
              // console.log((e.loaded / e.total) * 100);
              document.FeedBack.updatePrgress({
                id,
                progress: (e.loaded / e.total) * 100,
              });
            },
          })
          .then((res) => {
            let obj = res.data;

            store.dispatch({
              type: "MEDIA_WITH_FRONT",
              data: { type, list: [obj] },
            });
          });
      }
    }
  }, []);
  return (
    <div className="music_wrapper2" id={`_${id}`}>
      <audio id="muicPlayerLeft" ref={ref} style={{ display: "none" }} />

      {customUpload || (
        <DragUpload
          text={text}
          ico={ico}
          accept=".mp3,.wav"
          onDrop={handleDrop}
        />
      )}
      <InfiniteWrapper type={type} target={`_${id}`} max={max}>
        {list.map((e) => (
          <MusicCard
            onPlay={handlePlay(e)}
            // name={e.name}
            // time={e.time}
            key={e.id}
            type={type}
            hasOptions={true}
            musicId={e.id}
            selectedId={selectedId}
            src={e.media_file}
            isPlayed={e.id === music.id}
            refi={ref}
            name={e.name}
            time={e.fileInfo?.duration}
            onSelect={handleSelect(e)}
          />
        ))}
      </InfiniteWrapper>
    </div>
  );
};
