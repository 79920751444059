/* eslint-disable */
import uuid from "uuid-random";
import { Vid } from "../utils";
// import { API_URL } from "../config";

import { OFFLINEID, TIME_PER_SEC, SIZES, API_URL } from "../utils/config";
let _id = uuid();
let k = OFFLINEID;

export const initState = {
  random: 0,
  isImage: false,
  frameActive: false,
  tabName: "0",
  per: TIME_PER_SEC,
  active: {
    music: "",
    mask: {},
    activeFrame: 0,
    objActive: null,
    haveVid: k,
    leftLoading: false,
    hoverObjActive: null,
  },
  disable: {
    tab: false,
    right: false,
    scene: false,
    timeline: false,
    left: false,
  },
  avatar: {
    avatarImageList: [],
    avatarVoiceList: [],
    avatarVideoWithVoice: {},
  },

  undoRedo: {
    arr: [],
    pointer: 0,
  },
  media: {
    mergeTag: {
      list: []
    },
    video: {
      next: `${API_URL}/api/bgclip/search/?type=video`,

      list: [],
    },
    place: {
      next: null,
      list: [],
    },
    stock: {
      next: `${API_URL}/api/bgclip/search/?type=image`,
      list: [],
    },
    upload: {
      next: `${API_URL}/api/userlibrary/upload/?type=image&category=autovid_image`,

      list: [],
    },

    music: {
      next: `${API_URL}/api/aivideo/music/`,
      list: [],
    },
    music_upload: {
      next: `${API_URL}/api/userlibrary/upload/?category=music`,
      list: [],
    },
    video_upload: {
      next: `${API_URL}/api/userlibrary/upload/?type=video&category=autovid_video`,
      list: [],
    },
    speechupload: {
      next: `${API_URL}/api/userlibrary/upload/?category=speechupload`,
      list: [],
    },
    speechrecord: {
      next: `${API_URL}/api/userlibrary/upload/?category=speechrecord`,
      list: [],
    },
    icons: {
      next: `${API_URL}/api/assets/icons/`,
      list: [],
    },
    emoji: {
      next: `${API_URL}/api/assets/emoji/`,
      list: [],
    },
    shapes: {
      next: `${API_URL}/api/assets/shapes/`,
      list: [],
    },
    elements: {
      next: `${API_URL}/api/assets/elements/`,
      list: [],
    },
    mask: {
      next: `${API_URL}/api/assets/masks/`,
      list: [],
    },
    filter: {
      next: `${API_URL}/api/newvideo/filter/`,
      list: [],
    },
    animation: {
      next: `${API_URL}/api/newvideo/animation/`,
      list: [],
    },
    gradient: {
      next: `${API_URL}/api/aivideo/gradient/`,
      list: [],
    },
    sanimation: {
      next: `${API_URL}/api/newvideo/animation/scene/`,
      list: [],
    },
    tanimation: {
      next: `${API_URL}/api/newvideo/text/animation/`,
      list: {
        char: [],
        line: [],
        word: [],
      },
    },
    template: {
      // next: `${API_URL}/api/newvideo/video_template/?size=${
      //   SIZES[document._size || 0].str_ratio
      // }&human=${document._mode === 2 ? 0 : 1}`,
      list: [],
    },
    fonts: {
      next: "",
      list: [],
    },
    search: {
      next: "",
      list: [],
    },
  },
  scenes: {
    0: {
      jsonData: {},
      background: {
        type: "color",
        current: { id: "#ffffff" },
      },
      speech: {
        /**
         * type: type, upload, record
         */
        type: "type",
        data: {
          [_id]: {
            text: "",
            haveDelay: false,
            delay: 0,
          },
        },
        sound: {},
        arr: JSON.stringify([_id]),
      },
      elements: {},
      media: {
        video: `[]`,
        music: `[]`,
      },
      elementList: "[]",
      sanimation: {},
      haveEasyMode: true,
      // mixedAvatar: false,
      mode: 0,
      /**
       * 0: Default
       * 1: Voice Only
       * 2: Mixed
       *
       */
    },
    size: {},
    mode: false,
    /**
     * true: non-avatar
     * false:avatar
     */
    currentScene: {
      sceneArr: "[0]",
      arr: "[0]",
    },
    colorArr: [],
    mask: {},
    currentAvatar: {
      lang: { id: 131, i: 33 },//{ "id": 53, "i": 12 },
      video: {},
      audio: { "id": 760, "name": "Cora", "gender": 2, "samples": "https://api.autogenerate.ai/media/appAssets/avatar_sounds/default.mp3", "lang": { "id": 131, "name": "English (United States)", "code": "en-US", "country": [{ "name": "United States", "code": "US" }], "image": "https://api.autogenerate.ai/media/country/US.svg" } },
      image: {
        id: 4,
        name: "Asian Avatar 1",
        gender: 2,
        img: "https://api.autogenerate.ai/media/avatars/image/av1_small.jpeg",
        largeImg: "https://api.autogenerate.ai/media/avatars/image/AV_1.jpg",
        transparentImage:
          "https://api.autogenerate.ai/media/avatars/image/4_95.webp",
        avatarConfig: {
          FM: {
            ax: -13,
            ay: 128,
            scale: 0.728,
          },
          FB: {
            ax: 11,
            ay: 530,
            scale: 0.408,
          },
          SM: {
            ax: 94,
            ay: 274,
            x: 213,
            y: 263,
            scale: 0.717,
            size: 535,
            anX: 85,
            anY: 56,
            anSc: 0.7794392523364486,
          },
          SB: {
            ax: -57,
            ay: 725,
            x: 40,
            y: 716,
            scale: 0.48,
            size: 324,
            anX: 67,
            anY: 39,
            anSc: 0.7407407407407407,
          },
          faceCenterPoint: {
            x: 526,
            y: 241,
            scale: 0.874,
          },
        },
      },
    },
    isChheda: false,
    speechChanged: false,
  },
  sceneNumber: 0,
  config: {
    offSetArr: [0],
    ScreenLoading: false,
    loadingText: "Loading",
    speechLoad: false,
  },
};
export default () => { };
