import React, { useEffect, useState } from "react";
import "./_CSS.scss";
import TabHeader from "./TabHeader";
import { Ratio } from "./VideoCreator";
import Button from "./Button";
import Card from "./Card";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import SelectOption from "../../components/Chemistry/Atom/SelectOption";
import InfiniteWrapper from "../../components/Chemistry/Atom/Infinite";
import uuid from "uuid-random";
import mystore from "../appStore";
import { connect } from "react-redux";
import { url, fetcher } from "../../utils";
import AvatarChoosePopup from "./PopUp/AvatarChoosePopup";

import PurePopup from "./PurePopup"
import { DeletePopup } from "../../components/TimeLine/Components/SceneBar/DeletePopup";

import store from "../../store/store";
import DuplciatePopup from "./PopUp/DuplciatePopup";

export default connect((state) => ({
  generated: state.media.generated,
  draft: state.media.draft,
  img_generated: state.media.img_generated,
  img_draft: state.media.img_draft,
  isVideo: state.isVideo
}))(function MyImage() {
  const [tab, setTab] = useState(0);
  const handleTab = (i) => {
    setTab(i);
  };

  useEffect(() => {

    mystore.dispatch({
      type: "DEFAULT",
    })

  }, [])
  return (
    <div className="MyVideo">
      <TabHeader
        arrTab={[{ name: "Generated" }, { name: "Drafts" }]}
        onTab={handleTab}
        nowTab={tab}
      />


      {tab === 0 ? <ListGenerated /> : <ListDraft />}
    </div>
  );
});

export const FirstMyVideoCard = ({
  ele = null,
  have = true,
  className = "",
}) => {
  return (
    <div className={`FirstMyVideoCard flex column center ${className}`}>
      {/* {have && <Ratio />} */}
      {ele || <Button value={<>Create Video</>} />}
    </div>
  );
};

const LinkCard = (e) => {

  const hist = useHistory();
  const handleSelect = () => {
    console.log(e.type);
    if (e.type === "generated") {
      hist.push(`/myvideos/${e.id}`, { index: e.index, id: e.id });
    } else if (e.type === "img_generated") {
      hist.push(`/myimages/${e.id}`, { index: e.index, id: e.id });
    } else if (e.type === 'img_draft') {
      window.open(`/image/${e.id}/`, "_blank");
    } else {
      window.open(`/video/${e.id}/`, "_blank");
    }
    // //console.log("abc");
  };
  const handleOptionSelect = (s) => {
    const { id } = s;
    // console.log(s);
    switch (id) {
      case "preview":
        // hist.push(`/myvideos/${e.id}`, { index: e.index, id: e.id });
        onOpen(true)
        break;
      case "download":
        // console.log(e);
        const {
          mainVideoGenerate: { video },
        } = e.data;
        window.open(video + `?download=1`, "_blank");
        break;

      case "delete":
        _onOpen(true)
        break;
      case "duplicate":

        _onOpenD(true)

        break;
      case "edit":
        window.open(`/video/${e.id}/`, "_self");
        break;
      default:
        break;
    }
  };
  let OptionMap = {
    draft: [
      // { name: "Edit",  src: `${document.PUBLIC_URL}/app/oedit.svg` id: "edit" },
      { name: "Duplicate & Edit", src: `${document.PUBLIC_URL}/app/oedit.svg`, id: "duplicate" },
      { name: "Delete", src: `${document.PUBLIC_URL}/app/odelete.svg`, id: "delete" },
    ],
    img_draft: [
      // { name: "Edit",  src: `${document.PUBLIC_URL}/app/oedit.svg` id: "edit" },
      { name: "Duplicate & Edit", src: `${document.PUBLIC_URL}/app/oedit.svg`, id: "duplicate" },
      { name: "Delete", src: `${document.PUBLIC_URL}/app/odelete.svg`, id: "delete" },
    ],
    img_generated: [
      // { name: "Edit",  src: `${document.PUBLIC_URL}/app/oedit.svg` id: "edit" },
      { name: "Duplicate & Edit", src: `${document.PUBLIC_URL}/app/oedit.svg`, id: "duplicate" },
      { name: "Delete", src: `${document.PUBLIC_URL}/app/odelete.svg`, id: "delete" },
    ],
    generated: [
      { name: "Preview", src: `${document.PUBLIC_URL}/app/oeye.svg`, id: "preview" },
      { name: "Download", src: `${document.PUBLIC_URL}/app/odownload.svg`, id: "download" },
      // { name: "Share",  src: `${document.PUBLIC_URL}/app/oshare.svg`, id: "share" },
      { name: "Duplicate & Edit", src: `${document.PUBLIC_URL}/app/oedit.svg`, id: "duplicate" },
      { name: "Delete", src: `${document.PUBLIC_URL}/app/odelete.svg`, id: "delete" },
    ],
  };
  const [open, onOpen] = useState(false)
  const [_open, _onOpen] = useState(false)
  const [_openD, _onOpenD] = useState(false)
  console.log(e, e.isVideo)
  return (
    // <Link to={`/myvideos/${e.id}`}>
    <>
      <Card
        src={e.src}
        name={e.name}
        id={e.id}
        isPer={e.data.isPersonalized}
        option={
          <SelectOption
            ele={<img src={`${document.PUBLIC_URL}/app/option.svg`} alt="go" />}
            list={OptionMap[e.type]}
            onSelect={handleOptionSelect}
          />
        }
        onSelect={handleSelect}
      />
      <PurePopup open={open} onOpen={onOpen} footer={<></>}>
        <video autoPlay src={e.video} className="PreviewVideo" controls controlsList="nodownload"></video>
      </PurePopup>
      <DeletePopup text={e.name} isConfirm={_open} setConfirm={_onOpen} onDelete={() => {
        if (e.isVideo) {
          fetcher({
            method: "DELETE",
            url: `${url}/api/newvideo/video/${e.id}/`,
            res: (k) => {
              // alert(`${data.id} is deleted!`);
              mystore.dispatch({
                type: "DELETE_MEDIA",
                data: {
                  id: e.id,
                  // detail: data.data.result,
                  type: e.type,
                },
              });
              // handleBack();
            },
            err: (err) => {
              alert(`${e.id} is Error on Deleted!`);
            },
          });
        } else {
          fetcher({
            method: "DELETE",
            url: `${url}/api/newimage/image/${e.id}/`,
            res: (k) => {
              // alert(`${data.id} is deleted!`);
              mystore.dispatch({
                type: "DELETE_MEDIA",
                data: {
                  id: e.id,
                  // detail: data.data.result,
                  type: e.type,
                },
              });
            },
            err: (err) => {
              mystore.dispatch({
                type: "DELETE_MEDIA",
                data: {
                  id: e.id,
                  type: e.type,
                },
              });
            },
          });
        }
      }} />
      <DuplciatePopup
        text={"image"}
        isConfirm={_openD}
        onOpen={_onOpenD}
        onClickk={() => {
          if (e.isVideo) {
            fetcher({
              url: `${url}/api/newvideo/video/copy/${e.id}/`,
              res: (res) => {
                window.open(`/video/${res.data.result.id}/`, "_blank");
              },
            });

          } else {
            fetcher({
              url: `${url}/api/newimage/image/copy/${e.id}/`,
              res: (res) => {
                window.open(`/image/${res.data.result.id}/`, "_blank");
              },
            });
          }
        }}

      />




    </>
    // </Link>
  );
};


const ListDraft = connect((state) => ({
  list: state.media.draft.list,
  img_list: state.media.img_draft.list,
  isVideo: false
}))(({ list, isVideo, img_list }) => {
  return (
    <div
      className="ListTab MyVideoList flex"
      style={{ height: "70vh", overflow: "auto" }}
      id={`draft`}
    >
      <InfiniteWrapper
        max={10}
        type={"img_draft"}
        target={"draft"}
        mystore={mystore}
      >
        {(img_list).map((e, i) => (
          <LinkCard
            data={e}
            key={e.id}
            index={i}
            isVideo={isVideo}
            id={e.id}
            name={e.name}
            src={e.thumbnail}
            // video={e.mainVideoGenerate.video}
            type={"img_draft"}
          />
        ))}
      </InfiniteWrapper>
    </div>
  );
});


const ListGenerated = connect((state) => ({
  list: state.media.generated.list,
  img_list: state.media.img_generated.list,
  isVideo: false,
}))(({ list, isVideo, img_list }) => {
  return (
    <div
      className="ListTab MyVideoList flex"
      style={{ height: "70vh", overflow: "auto" }}
      id={`generated`}
    >
      <InfiniteWrapper
        max={10}
        type={"img_generated"}
        target={"generated"}
        mystore={mystore}
      >

        {[[
          <FirstMyVideoCard ele={<Button value={<>Create Image</>} onClick={() => {
            window.open("/image?mode=2", "_blank")
          }} />} />
        ],
        ...img_list.map((e, i) => (
          <LinkCard
            data={e}
            key={e.id}
            index={i}
            id={e.id}
            name={e.name}
            src={e.thumbnail}
            video={e?.mainVideoGenerate?.video}
            isVideo={isVideo}
            type={"img_generated"}
          />
        )),
        ]}
      </InfiniteWrapper>
    </div>
  );
});
