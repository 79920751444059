/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import "./components/style.css";
// import { isLoading as loadingFn } from "../../actions/loading"

import "react-tabs/style/react-tabs.css";
import moment from "moment";
import { ReactComponent as SeeMore } from "../../extras/analytics/SeeMore.svg";
// import EditProfilePopup from "./components/editProfile";
import ShowMorePopup from "./components/showmorePopup";
// import AddOnPopup from "./components/addonPopup";
// import Info from "../common/infoIcon";
import { MainContext } from "../common/videoListContext";
// import { url, token } from "../../config/config";
import { ReactComponent as BreadcrumbArrow } from "../../extras/sidebar/BreadcrumbArrow.svg";
import { ReactComponent as Dashboard } from "../../extras/sidebar/Dashboard.svg";
import { ReactComponent as VideoC } from "../../extras/account/videoC.svg";
// import { ReactComponent as AvatarSubs } from "../../extras/account/personsubs.svg";
// import { ReactComponent as VideoSubs } from "../../extras/account/videosubs.svg";
import { ReactComponent as CreditSubs } from "../../extras/account/creditC.svg";
import { ReactComponent as NoCredit } from "../../extras/misc/noCreds.svg";
import { connect } from 'react-redux';
import LoadingComp from "../common/loading";
import axios from "axios";
import AddOnPopupConfirm from "./components/addonConfirm";
import { Link } from "react-router-dom"
import { fetcher, url } from "../../../utils";

import "../_john.scss"


export const CREDIT_TYPE_MAPPING = {
    0: 'Avatar Video',
    1: 'Non Avatar Video',
    2: 'Video Personalization',
    3: 'Salespage Personalization',
    4: 'Thumbnail Personalization',
    5: 'Image Personalization',

}


function Index(props) {
    // const [Data, setData] = useContext(MainContext)
    const [Credit, setCredit] = useState([]);
    const [SubscriptionData, setSubscriptionData] = useState({});

    const [Loading, setLoading] = useState(true)
    const [Index, setIndex] = useState(0)
    const { isAuthenticated, user } = { user: { ...props.auth } };

    const reloadData = async () => {

        fetcher({
            url: `${url}/api/subscription/info/`,
            res: (res) => {
                const data = res.data.result;
                setSubscriptionData(data)
            }, err: err => {

            }
        })



        fetcher({
            url: `${url}/api/subscription/details/?limit=6`,
            res: (res) => {

                const data = res.data.results
                setCredit(data)
            }, err: err => {

            }
        })
        setLoading(false)
    }

    useEffect(() => {
        reloadData()
        document.body.style.overflow = 'scroll';

    }, [])

    React.useEffect(() => {
        document
            .getElementById("bootstrap")
            .setAttribute("href", "/bootstrap.min.css");
        return () => {
            document.getElementById("bootstrap").removeAttribute("href");
        };
    }, []);


    console.log('subscription', Credit)

    return (
        <div className="video-list-wrapper">



            <div className="video-list-main-container">
                <div className="s0001">

                    My Subscription
                </div>



                <div className="credit-box-wrapper">
                    <div className="s002">
                        <div className="s002_plan">
                            {SubscriptionData.planName} Active
                        </div>
                        <div className="dates-header-in-subs">
                            <span className="date-item-in-header">
                                <label htmlFor="" className="date-label-headr">Plan Started On: </label>
                                <label htmlFor="" className="date-label-date"> {SubscriptionData?.subscriptionStart && moment(SubscriptionData?.subscriptionStart).format(
                                    "DD MMM, YYYY"
                                )} </label>
                            </span>

                            <span className="date-item-in-header">
                                <label htmlFor="" className="date-label-headr">Plan Expires On:  </label>
                                <label htmlFor="" className="date-label-date"> {SubscriptionData?.subscriptionEnd && moment(SubscriptionData?.subscriptionEnd).format(
                                    "DD MMM, YYYY"
                                )} </label>
                            </span>

                        </div>

                        <div className="creditbx001">

                            {/* <div className="date-in-credit">
                                {moment(user?.subs_end).format(
                                    "MMM, YYYY"
                                )}
                            </div> */}


                            {SubscriptionData?.subscription && SubscriptionData?.subscription.map((e, i) => {
                                if (e.creditType !== 1) {
                                    console.log(e)
                                    return (
                                        <>
                                            <label htmlFor="" className="videocreditleft">
                                                {CREDIT_TYPE_MAPPING[e.creditType]}
                                            </label>
                                            <div className="twotone-credit">
                                                <div className="pink-tone tone">
                                                    {e.totalCredit - e.usedCredit}
                                                </div>
                                                <div className="black-tone tone">
                                                    {e.totalCredit}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                            })}

                            <AddOnPopupConfirm closee={() => { }} request={"Request Sent for Renewal of subscription."}>

                                <button className="renew-sub-button">
                                    Renew Subscription
                                </button>

                            </AddOnPopupConfirm>



                        </div>
                    </div>

                    <div className="creditbx002">
                        {Credit.length > 0 ? <lable className="sm-in-credits">
                            Credit Use History
                        </lable> : null}

                        <div className="credit-list-container">

                            {Loading ? <div className="credit-list-container"><LoadingComp style={{ height: "500px" }} /></div> :
                                <div>



                                    {Credit.length > 0 ? <div className="credit-list-container">
                                        {Credit.map((d, k) => {
                                            let _name = d.meta["name"];

                                            if (d.creditType === 3) {
                                                _name = d.meta["pageName"]
                                            } else if (d.creditType === 4) {
                                                _name = d.meta["thumbnailName"]
                                            }
                                            return (
                                                <div key={k} className="credit-list-item">
                                                    {true ? <VideoC /> : <CreditSubs />}<div className="credit-list-inneritem">
                                                        <div className="name-in-credits-list"> <span className="bold-incredits">{Math.round(d.usedCredit)} credit</span> used in <span className="bold-incredits">{CREDIT_TYPE_MAPPING[d.creditType]} </span>“{_name}”</div> <label htmlFor="" className="time-in-credit-his">
                                                            {moment(d.timestamp).format("h:mm A, DD MMM")}
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className="d-flex justify-content-center p-un-credit">
                                            <ShowMorePopup date={user?.subs_end} used={user?.usedVideoCredit} out={user?.totalVideoCredit} >
                                                <button className="showmore-analytics">See More &nbsp;<SeeMore style={{ transform: "rotateZ(90deg)" }} />
                                                </button>

                                            </ShowMorePopup>

                                        </div>
                                    </div> :

                                        <div style={{
                                            width: "100%",
                                            height: "100%"
                                        }}>


                                            <div style={{ margin: "auto", maxWidth: "588px", display: "flex", flexDirection: "column", justifyContent: "center" }} >
                                                <NoCredit style={{ margin: "0px auto" }} />
                                                <p className={"heading-e6e6e6"} style={{ margin: "20px auto" }}>No credits used</p>
                                                <p className={"sub-a9a9a9"} style={{ margin: "0 auto" }}>Your used credit history will appear here once you start creating and sharing videos.</p>
                                            </div>







                                        </div>






                                    }</div>


                            }

                        </div>

                    </div>
                </div>

                {/* <div className="my-upload-box-wrapper">
                    <div className="header-analytics">
                        <h2 className="heading-analytics d-flex">
                            Addons &nbsp;&nbsp;&nbsp;
                            <span className="camp-analytic-info">

                            </span>
                        </h2>
                    </div>
                    <div className="addons-container-list">
                        {

                            [
                                {
                                    header: <AvatarSubs />,
                                    name: "Personalised Avatar",
                                    about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  ut labore et dolore magna. ",
                                    button: <button className="addon-buttons">Personalised Avatar &nbsp; <AddIcon /> &nbsp; </button>,
                                    style: {}
                                },

                                {
                                    header: <VideoSubs />,
                                    name: "Premium Video",
                                    about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  ut labore et dolore magna. ",
                                    button: <button className="addon-buttons">Get Premium Video</button>,
                                    style: { margin: "0 0 40px 0" }
                                },
                                {
                                    header: "+500",
                                    name: "Add Video Credit",
                                    about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  ut labore et dolore magna. ",
                                    button: <button className="addon-buttons"><AddIcon /> &nbsp; 500 Video Credit</button>,
                                    style: { margin: "0" }
                                },
                                {
                                    header: "+1000",
                                    name: "Add Video Credit",
                                    about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  ut labore et dolore magna. ",
                                    button: <button className="addon-buttons"><AddIcon /> &nbsp; 1000 Video Credit</button>,
                                    style: { margin: "0" }
                                },
                                {
                                    header: "+2000",
                                    name: "Add Video Credit",
                                    about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  ut labore et dolore magna. ",
                                    button: <button className="addon-buttons"><AddIcon /> &nbsp; 2000 Video Credit</button>,
                                    style: { margin: "0" }
                                },

                            ]
                                .map(d => (
                                    <div className="Rectangle-272-credit">

                                        <div className="header-addon" style={d.style} >
                                            {d.header}
                                        </div>
                                        <label htmlFor="" className="heading-addons">{d.name}</label>
                                        <p className="about-addon">
                                            {d.about}
                                        </p>
                                        <div className="button-addons">
                                            <AddOnPopup name={d.name} header={d.header} about={d.about}>
                                                {d.button}
                                            </AddOnPopup>
                                        </div>


                                    </div>))}
                    </div>



                </div> */}

            </div>

        </div>
    );
}
const mapStateToProps = (state) => {
    return ({
        LoadingState: state.loading,
        auth: state.user,
    })
};

export default connect(mapStateToProps)(Index);
