import React, { useEffect, useState } from "react";
import Popup from "../Popup";
import "./_EEE.scss";
import "../_Custom.scss"
import ButtonIcon from "../../../components/Chemistry/Atom/ButtonIcon";
import Modal from "reactjs-popup";
import Button from "../Button";
// import MainPopUp from "../../ImagePersonalised/MainPopup";
import { PerImage, TopCard } from "./LinkPopup";
import Card from "../Card";
import MiniInput from "../../ImagePersonalised/Components/MiniComponents/MiniInput";
import LabelInput from "./LabelInputCustom";
import SelectOption from "../../../components/Chemistry/Atom/SelectOption";
import { connect } from "react-redux";
import { fetcher, url, fetchAndSave, token, HideAndShowHandler } from "../../../utils";
import mystore from "../../appStore";
import DragUpload from "../../../components/Chemistry/Atom/DragUpload"
import axios from "axios";
import InfiniteWrapper from "../../../components/Chemistry/Atom/Infinite";
import TablePopup from "./TablePopup"
import MapPopup from "./MapPopup";
import { DeletePopup } from "../../../components/TimeLine/Components/SceneBar/DeletePopup";


const VariablePopup = connect(state => ({ allMergeTag: state.mydetail.allMergeTag, my: state.mydetail, csvupload: state.media.csvupload.list }))(({ trigger, onOpenHide, onCloseShow, haveBatch = true, allMergeTag, my, type = "solo_history", selectedTab = 0, csvupload }) => {
  const [tab, settab] = useState(selectedTab);

  useEffect(() => {
    settab(selectedTab)
  }, [selectedTab])


  const [optionActive, setActive] = useState({});
  const onChooseMail = (mail) => {
    return () => {
      setActive(mail);
      setSe(mail)
    };
  };
  const [tag, setTag] = useState({});
  const handleChange = (k, i) => {

    let newErr = { ...Err }
    if (newErr.mergeTagData[i]) {
      let newErr = { ...Err }
      delete newErr.mergeTagData[i[0]]
      if (newErr.mergeTagData.length > 0)
        setErr({ ...newErr })
      else
        setErr({ have: false, text: "", mergeTagData: {} })

    }
    var t = { ...tag }

    setTag((l) => ({ ...t, [i]: k.target.value }));

  };



  const _typeLink = {
    solo_history: "solo",
    solomail_history: "solomail"
  }

  const [Err, setErr] = useState({ have: false, text: "", mergeTagData: {} })

  const [load, setLoad] = useState(false);
  const handleGenerate = (close) => {
    setLoad(true);
    fetcher({
      method: "POST",
      url: `${url}/api/newvideo/video/generate/${_typeLink[type]}/${my.id}/`,
      res: (res) => {
        const { isError, result, mergeTagData } = res.data;
        setLoad(false);
        // console.log(res);

        // mystore.dispatch({
        //   type: "ADD_MEDIA_IN_LIST",
        //   data: { result, type: `${_typeLink[type]}_history` },
        // });

        if (isError) {
          setErr({ have: true, text: res.data.message, mergeTagData });
          // setTag({});
          // setTimeout(() => {
          // setErr({ have: false, text: res.data.message });
          // }, 2000);
          // alert("SOMETING ERROR in TAG");
        } else {
          setTag({});
          close()
          mystore.dispatch({
            type: "MEDIA_WITH_FRONT",
            data: {
              type,
              next: null,
              list: [result],
            },
          });
        }
      },
      err: (e) => {
        console.log(e);
      },
      data: {
        mergeTagData: tag,
      },
    });
  };



  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const { name, size } = acceptedFiles[0];
      let formData = new FormData();
      formData.append("name", name);
      formData.append("category", "csvupload");
      formData.append("media_file", acceptedFiles[0]);

      // let id = uuid();
      // document.FeedBack.pushList({ id, item: { name, size } });
      if (size <= 100 * 2 ** 20)
        axios
          .post(`${url}/api/userlibrary/upload/`, formData, {
            headers: { Authorization: `Token ${token}` },
            // onUploadProgress: (e) => {
            //   // console.log((e.loaded / e.total) * 100);
            //   // document.FeedBack.updatePrgress({
            //   //   id,
            //   //   progress: (e.loaded / e.total) * 100,
            //   // });
            // },
          })
          .then((res) => {
            let obj = res.data;

            mystore.dispatch({
              type: "MEDIA_WITH_FRONT",
              data: { type: "csvupload", list: [obj] },
            });
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }

  const [tableOpen, setOpen] = useState(false)
  const [se, setSe] = useState({})
  const [isConfirm, setConfirm] = useState({
    status: false,
    data: null
  })
  const handleSelect = (sel) => {

    return (k) => {
      switch (k.key) {
        case "preview":

          setOpen(true)
          setSe(sel)

          break;
        case "delete":
          setConfirm({
            status: true,
            data: sel
          })

          break;

        default:
          break;
      }

    }
  }




  return (
    <>
      <TablePopup onOpen={setOpen} open={tableOpen} media={se || {}} />

      <Modal modal closeOnDocumentClick={false} trigger={trigger} onClose={() => {
        onCloseShow()
        setOpen(false)
      }} onOpen={() => {
        onOpenHide()
      }} >
        {(close) => (
          <Popup
            UpperId="VariablePopup-1-1x"
            name="Enter variable values"
            close={close}

            //   haveButton={false}
            footer={
              <div className="VariablePopup_footer flex row1">
                {/* <Button className="grey" value="Cancel"></Button> */}
                {tab === 0 ? (
                  <Button value={type === "solo_history" ? "Create Link" : "Create Code"} onClick={() => handleGenerate(close)}></Button>
                ) : (

                  se.id && <MapPopup
                    onOpenHide={() => {
                      HideAndShowHandler("VariablePopup-1-1x", "none")
                    }}
                    onCloseShow={() => {
                      // HideAndShowHandler("VariablePopup-1-1x", "block")

                    }}
                    closeMePlease={() => {

                      close()
                    }} media={se || {}} trigger={
                      <Button value="Validate"></Button>
                    } />
                )}
              </div>
            }
          >
            {haveBatch && (
              <div className="VariablePopup_tab">
                {["Solo Mail", "Batch Mail"].map((e, i) => {
                  return (
                    <div
                      onClick={() => {
                        settab(i);
                      }}
                      key={e}
                      className={`VariablePopup_tab_tab ${tab === i ? "VariablePopup_tab_tab_active" : ""
                        }`}
                    >
                      {e}
                    </div>
                  );
                })}
              </div>
            )}
            <div className="VariablePopup">
              {load && (
                <div
                  className="ScreenLoading"
                // style={{ display: l ? "flex" : "none" }}
                >
                  <img src={`${document.PUBLIC_URL}/icons/Loading.svg`} alt="hey"></img>
                  <div className="Text">Generating...</div>
                </div>
              )}
              {tab === 0 &&
                allMergeTag.map((e) => {


                  var err = {
                    have: Err.mergeTagData[e[0]] ? true : false,
                    text: Err.mergeTagData[e[0]] ? Err.mergeTagData[e[0]] : ""
                  }
                  return (
                    <div className="VariablePopup_flex">
                      {/* <div className="VariablePopup_flex_label">{e}</div> */}
                      {/* <MiniInput label="hey" /> */}
                      <LabelInput error={err} label={e[0]} type={e[1]}
                        value={tag[e[0]]}
                        onChange={(k) => handleChange(k, e[0])}


                      />
                    </div>
                  );
                })}
              {tab === 1 && (
                <>
                  <InfiniteWrapper className="VariablePopup_grid" type="csvupload" mystore={mystore} target={"VariablePopup_grid"}>
                    <DeletePopup
                      isConfirm={isConfirm.status}
                      setConfirm={(d) => { setConfirm({ status: d, data: null }) }}
                      text={"CSV"}
                      onDelete={async () => {

                        fetcher({
                          method: "DELETE",
                          url: `${url}/api/userlibrary/upload/${isConfirm.data.id}/`,

                          res: (res) => {
                            mystore.dispatch({
                              type: "DELETE_MEDIA",
                              data: {
                                id: isConfirm.data.id,
                                // detail: data.data.result,
                                type: "csvupload",
                              },
                            });
                          },
                          err: (err) => { },
                        });

                      }}
                    />
                    {[
                      <DragUpload
                        text="Drag & Drop your CSV here!"
                        onDrop={handleDrop}
                        // onDrop={handleDrop}
                        accept=".csv"
                      />
                      ,
                      ...csvupload.map((e) => {
                        return (
                          <Card
                            src={`${document.PUBLIC_URL}/app/csvIcon.svg`}
                            className="MailIcon"
                            isActive={e.id === optionActive.id}
                            option={
                              <SelectOption
                                onSelect={handleSelect(e)}
                                ele={
                                  <img
                                    className="option"
                                    src={`${document.PUBLIC_URL}/app/option.svg`}
                                    alt="go"
                                  />
                                }
                                list={[
                                  { name: "Preview", src: `${document.PUBLIC_URL}/app/oeye.svg`, key: "preview" },
                                  { name: "Delete", src: `${document.PUBLIC_URL}/app/odelete.svg`, key: "delete" },
                                ]}
                              />
                            }
                            onSelect={onChooseMail(e)}
                            name={e.name}
                          />
                        );
                      })]}
                  </InfiniteWrapper>



                </>
              )}
            </div>
          </Popup>
        )}
      </Modal>
    </>

  );
}
)

const SoloLink = () => {
  return
}
export default VariablePopup

