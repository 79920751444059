import { useDropzone } from "react-dropzone";
import "./_Atom.scss";
function DragUpload({
  text = "Drag & Drop your image here!",
  ico = `${document.PUBLIC_URL}/icons/upload.svg`,
  onDrop = () => { },
  accept = "image/*",
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1,
  });

  return (
    <div
      className={`_DragAndDropImage ${isDragActive && "_DragAndDropImage_Active"
        }`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <img src={ico} alt={"uploadicon"} />
      {text}
    </div>
  );
}
export default DragUpload;
