import React, { useCallback, useEffect, useState } from "react";
// import InputIcon from './InputIcon'
import SelectOption, { FontFamilySelectOption } from "./SelectOption";

export default function EditSelect({
  list = [],
  className = "",
  hasFont = false,
  selected = "",
  onSelect = () => { },
  onInputChange = () => { },
  selectedIndex = 0,
  disabled = false,
  haveVariable = false,
  title = null,
  arr = null,
  haveIcon = false,
  mustCloseOnSelect = true,
  type = "text",
  step = null,
  min = 0,
  max = null,
  onInputBlur = () => { },
  onInputFocus = () => {

  },
  wantFilter = true,
  haveInc = false,
  ele = null,
  secondClose = true,
  onInputEnter = () => { },
  onShow = () => {

  }
  , selectedID = null
}) {
  const [_list, setList] = useState(
    arr || list.map((e) => ({ name: e })) || []
  );
  const [_selected, setSelected] = useState(
    selected || arr?.[selectedIndex]?.name || list[selectedIndex] || arr?.find(e => e.id === selectedID)?.name
  );
  const [whole, setWhole] = useState(
    arr?.[selectedIndex] || _list[selectedIndex] || arr?.find(e => e.id === selectedID)
  );
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    if (hasFont) {
      setList(arr)
    }
  }, [arr]);

  const handleSearch = useCallback((e) => {
    if (wantFilter) {
      setList(
        (arr || list.map((e) => ({ name: e }))).filter(
          (k) => k.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
        )
      );
    }

    if (!haveVariable) setSelected(e.target.value);
    onInputChange(e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSelect = (e, i) => {
    // if (e?.name !== _selected) {
    // if (!haveVariable)
    onSelect(e.name, i);
    setSelected(e.name);
    setWhole(e);
    // }

    // alert(e.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  const handleShow = ((bo) => {
    onShow(bo)
    setShow(bo);
    //const _listArr = arr || list.map((e) => ({ name: e })) || [];
    setList(_listArr => {
      return _listArr;
    });
  });
  // useEffect(() => {
  //   setSelected(selected);
  // }, [selected]);
  const customF = (what) => {
    return (e) => {
      document.isCanActive = what;
      if (what) {

        onInputBlur(e);
      } else {
        onInputFocus(e)
      }
    };
  };

  const Enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      onInputEnter(e)
    }
  }

  return (
    <div className={`EditSelect ${className}`}>
      {hasFont ? (<FontFamilySelectOption
        // hasFont={hasFont}
        onShow={handleShow}
        list={_list}
        onSelect={handleSelect}
        haveIcon={haveIcon}
        mustCloseOnSelect={mustCloseOnSelect}
        secondClose={secondClose}
        ele={ele ||
          <div className="EditSelect_input">
            {haveIcon && (
              <img
                className="EditSelect_input_img"
                src={whole?.src}
                alt="sn"
              ></img>
            )}


            <input
              value={title || _selected}
              onChange={handleSearch}
              onBlur={customF(true)}
              onFocus={customF(false)}
              disabled={disabled}
              type={type}
              step={step}
              min={min}
              max={max}
              onKeyDown={Enter}
            />

            <img
              style={{ transform: `rotateZ(${!isShow ? "180" : "0"}deg)` }}
              src={`${document.PUBLIC_URL}/ico/down.svg`}
              alt="tag"
            />
          </div>
        }
      />)
        : (<SelectOption
          // hasFont={hasFont}
          onShow={handleShow}
          list={_list}
          onSelect={handleSelect}
          haveIcon={haveIcon}
          mustCloseOnSelect={mustCloseOnSelect}
          secondClose={secondClose}
          ele={ele ||
            <div className="EditSelect_input">
              {haveIcon && (
                <img
                  className="EditSelect_input_img"
                  src={whole?.src}
                  alt="sn"
                ></img>
              )}


              <input
                value={title || _selected}
                onChange={handleSearch}
                onBlur={customF(true)}
                onFocus={customF(false)}
                disabled={disabled}
                type={type}
                step={step}
                min={min}
                max={max}
                onKeyDown={Enter}
              />

              <img
                style={{ transform: `rotateZ(${!isShow ? "180" : "0"}deg)` }}
                src={`${document.PUBLIC_URL}/ico/down.svg`}
                alt="tag"
              />
            </div>
          }
        />)}

    </div>
  );
}
