import {
  Switch,
  Route,
  useRouteMatch,
  // useHistory,
  // useParams,
} from "react-router-dom";
// import ArrowWrapper from "../../../../Chemistry/Atom/ArrowWrapper";
import ButtonIcon from "../../../Chemistry/Atom/ButtonIcon";
import TitleRow from "../../../Chemistry/Atom/TitleRow";
import Vr from "../../../Chemistry/Atom/Vr";
import I from "./Icons/";
import "./_Avatar.scss";
// import AvatarSettings from "./components/AvatarSettings";
import { AvatarSettings } from "../../../../utils/universalImport";
import ArrowWrapper from "../../../Chemistry/Atom/ArrowWrapper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import store from "../../../../store/store";
import TitleWrapper from "../../../Chemistry/Atom/TitleWrapper";
import Dimensions from "../../../Chemistry/Atom/Dimensions";
import Frame from "./components/Frame";
import { useState, useCallback, useEffect } from "react";
import DisabledWrapper from "../../../Chemistry/Atom/DisabledWrapper";
import Toggle from "../../../Chemistry/Atom/Toggle";
import {
  fitImageToRect,
  getById,
  getCordinate,
  Index,
} from "../../../../utils";
import { connect } from "react-redux";
import Animation from "../../../Chemistry/Compound/Animation";
import Shadow from "../../../Chemistry/One/Shadow";
import Mask from "../../../Chemistry/One/Mask";
import { storeAnimatationToStore } from "../../../../utils/shortcutFunction";
import { convertOrigin } from "../../../../utils/cropyThings";
import EditSelect from "../../../Chemistry/Atom/EditSelect";
import SelectOption from "../../../Chemistry/Atom/SelectOption";

import Model from "reactjs-popup"
import Popup from "../../../../App/Components/Popup";
import Button from "../../../../App/Components/Button";

function Avatar({ sceneNumber }) {
  const { url } = useRouteMatch();

  return (
    <div className="Avatar">
      <Switch>
        <Route path={url + "/avatar_settings"}>
          <AvatarSettings />
        </Route>
        <Route path={url + "/frame"}>
          <Frame />
        </Route>
        {document.editorType === "video" && <Route path={url + "/animation"}>
          <Animation
            type="animation"
            title="< Animation"
            obj={getById(`avatar${Index()}`, Index())}
            onSelect={storeAnimatationToStore(`avatar${Index()}`)}
          />
        </Route>}
        <Route path={url + "/shadow"}>
          <Shadow id={`avatar${Index()}`} />
        </Route>
        <Route path={url} exact>
          <DefaultPage />
        </Route>
      </Switch>
    </div>
  );
}

export const DefaultPage = connect((state) => {
  return {
    avatarId: state.scenes[state.sceneNumber]?.elements.avatar?.id,

    currentAvatar: state.scenes.currentAvatar,
    smode: state.scenes[state.sceneNumber]?.mode,
    langName: state.avatar.avatarLanguages?.[state.scenes.currentAvatar?.lang?.i]

  };
})(({ avatarId, sceneNumber, currentAvatar, smode, langName }) => {
  const { url } = useRouteMatch();
  const [toggle, setToggle] = useState(false);
  document.setToggle = setToggle;

  const {
    currentAvatar: {
      image: { img: avtarLink },
    },
  } = store.getState().scenes;
  const [dim, setDim] = useState({});

  useEffect(() => {
    let obj = getById(`avatar${Index()}`, Index());
    if (obj) {

      setToggle(!!obj._Voice);
    }

  }, [avatarId, Index()]);


  const handleChangeMode = (k, i) => {
    console.log(k);

    if (k.key === 0) {
      document.mixedAvatar.avatarMode({ index: Index() });

      store.dispatch({ type: "SMODE", data: k.key });

    } else if (k.key === 1) {
      document.mixedAvatar.voiceOnlyMode({ index: Index() });
      store.dispatch({ type: "SMODE", data: k.key });

    } else if (k.key === 2) {
      setAlert(true)

    }


  };

  const modeList = [{ txt: "Default", key: 0 }, { txt: "Voice Only", key: 1 }, { txt: "No Avatar", key: 2 }]
  const FmodeList = modeList.filter((e, i) => {
    if (e.key === 1 && document.editorType === "image") return false;
    return true;
  });


  const [arrS, setArrS] = useState(false);
  const [noalert, setAlert] = useState(false);
  return (
    <div className="DefaultPage">
      <div
        style={{ width: "100%" }}
        className="flex row acenter DefaultPage_between"
      >
        <Model modal open={noalert} onClose={() => {
          setAlert(false)
        }}>
          <Popup haveButton={true} close={() => setAlert(false)} footer={<>
            <Button value="Continue" onClick={() => {
              setAlert(false);
              document.mixedAvatar.nonAvatarMode({ index: Index() });
              store.dispatch({ type: "SMODE", data: 2 });


            }} />
          </>}

          >
            <div className="AvatarWarning"> You can't switch back to Avatar mode for this scene.<br /> However, you can always a add new scene.</div>
          </Popup>
        </Model>

        <TitleRow title="AI Avatar" />
        <div
          style={{ marginBottom: "5px" }}
          className="DefaultPage_mixedavatar"
        >

          <DisabledWrapper isDisable={smode === 2}>
            <SelectOption

              ele={
                <div className="x002">
                  {modeList[smode]?.txt}
                  <img
                    src={`${document.PUBLIC_URL}/ico/down.svg`}
                    alt=""
                    style={{ transform: `rotateZ(${arrS ? "0" : "180"}deg)` }}
                  />
                </div>
              }
              onSelect={handleChangeMode}
              onShow={setArrS}
              list={FmodeList.map((e, i) => ({
                name: (
                  <div className="flex row x001">
                    <div>{e.txt}</div>
                    {(e.key === smode) && <img src={`${document.PUBLIC_URL}/ico/tick2.svg`} alt="tick" />}
                  </div>
                ),
                key: e.key
              }))}
            />
          </DisabledWrapper>

        </div>
      </div>

      <DisabledWrapper isDisable={smode === 2}>
        <ArrowWrapper
          className="Avatar_Profile"
          to={`${url}/avatar_settings`}
          m="15px"
          p="16px 0px"
          ele={
            <img
              alt="kk"
              src={avtarLink}
              className="Avatar_Profile_img"
              style={{ borderRadius: "10px" }}
            />
          }
        >
          <div
            className="AVATAR_INFO"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexFlow: "column",
              letterSpacing: "0px",
              wordSpacing: "3px"
            }}
          >
            <div style={{ marginBottom: "15px", fontWeight: "400", fontSize: "15px" }}>
              Avatar Settings
            </div>
            <label htmlFor="" style={{ marginBottom: "10px" }}>
              <img src={`${document.PUBLIC_URL}/ico3/face.svg`} alt="abc" />
              {currentAvatar.image.name}
            </label>
            {/* <hr /> */}
            {/* <Vr /> */}
            <label htmlFor="">
              <img src={`${document.PUBLIC_URL}/ico3/voice.svg`} alt="abc" />
              <div className="flex column">
                <div>{langName?.name || "UPDATETO2.0"}</div>
                <div>{currentAvatar.audio.name}</div>
              </div>
            </label>
          </div>
        </ArrowWrapper>
      </DisabledWrapper>

      <Vr m="20px 0 10px" />
      {/* <div className="VoiceOnly">
        Voice Only <Toggle toggle={toggle} onToggle={handleToggle} />
      </div> */}
      <DisabledWrapper isDisable={smode === 1 || smode === 2}>
        <OrderRow id={`avatar${Index()}`} />

        {/* <Vr m="0" /> */}
        <TitleWrapper title="Layout">
          <Dimensions
            disabled={[]}
            id={`avatar${Index()}`}
            dim={dim}
            ele={<AvatarPosition />}
          />
          {/* <AvatarPosition /> */}
        </TitleWrapper>

        <Vr m="0" />
        <ArrowWrapper
          to={`${url}/frame`}
          m="15px"
          p="10px 0px"
          ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/frame.svg`} />}
          onClick={() => store.dispatch({ type: "SET_FRAME", data: true })}
        >
          Frame
        </ArrowWrapper>
        {document.editorType === "video" && <>
          <Vr m="0" />
          <ArrowWrapper
            to={`${url}/animation`}
            m="15px"
            p="10px 0px"


            ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/animation.svg`} />}
          >
            Animation
          </ArrowWrapper>
        </>}
        <Vr m="0" />
        <ArrowWrapper
          m="15px"
          p="10px 0px"
          to={`${url}/shadow`}
          ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/shadow.svg`} />}
        >
          Shadow
        </ArrowWrapper>
        <Vr m="0" />

      </DisabledWrapper>
    </div>
  );
});

export const AvatarPosition = ({ now, onChange }) => {
  const icoMap = [
    { src: `${document.PUBLIC_URL}/icons/topLeft.svg`, asrc: `${document.PUBLIC_URL}/icons/atopLeft.svg` },
    { src: `${document.PUBLIC_URL}/icons/topCenter.svg`, asrc: `${document.PUBLIC_URL}/icons/atopCenter.svg` },
    { src: `${document.PUBLIC_URL}/icons/topRight.svg`, asrc: `${document.PUBLIC_URL}/icons/atopRight.svg` },
    { asrc: `${document.PUBLIC_URL}/icons/acenterLeft.svg`, src: `${document.PUBLIC_URL}/icons/centerLeft.svg` },
    { asrc: `${document.PUBLIC_URL}/icons/acenterCenter.svg`, src: `${document.PUBLIC_URL}/icons/centerCenter.svg` },
    { asrc: `${document.PUBLIC_URL}/icons/acenterRight.svg`, src: `${document.PUBLIC_URL}/icons/centerRight.svg` },
    { asrc: `${document.PUBLIC_URL}/icons/abottomLeft.svg`, src: `${document.PUBLIC_URL}/icons/bottomLeft.svg` },
    { asrc: `${document.PUBLIC_URL}/icons/abottomCenter.svg`, src: `${document.PUBLIC_URL}/icons/bottomCenter.svg` },
    { asrc: `${document.PUBLIC_URL}/icons/abottomRight.svg`, src: `${document.PUBLIC_URL}/icons/bottomRight.svg` },
  ];

  const [current, setCurrent] = useState(5);
  const handleChange = (i) => {
    const { currentAvatar } = store.getState().scenes;

    return (e) => {
      setCurrent(i);
      let av = getById(`avatar${Index()}`, Index());
      convertOrigin(av, { originY: 0, originX: 0 });
      if (av.clipPath && av.type === "group") {
        // square
        const screenHeight = 1080;
        const fmapper = {
          0: 3,
          1: 4,
          2: 5,
          3: 0,
          4: 1,
          5: 2,
          6: 3,
          7: 4,
          8: 5,
        };
        let { x, y, size } = getCordinate(
          1,
          fmapper[i],
          currentAvatar.image.avatarConfig
        );
        if (i <= 2) {
          y = screenHeight - y - size;
        }
        fitImageToRect(av, { left: x, top: y, height: size, width: size });
      } else {
        const fmapper = {
          0: 0,
          1: 1,
          2: 2,
          3: 0,
          4: 1,
          5: 2,
          6: 3,
          7: 4,
          8: 5,
        };
        const { ax, ay, scale } = getCordinate(
          0,
          fmapper[i],
          currentAvatar.image.avatarConfig
        );
        av.set({
          left: ax,
          top: ay,
          scaleX: scale,
          scaleY: scale,
          _Position: i,
        });
      }
      document.fxx.change[av.id](av);
      av.canvas.fire("object:modified");
      document.cxx[Index()].renderAll();
    };
  };

  return (
    <div className="AvatarPosition">
      {icoMap.map((e, i) => {
        return (
          <img
            key={e.src}
            onClick={handleChange(i)}
            src={current === i ? e.asrc : e.src}
            alt="e"
          />
        );
      })}
    </div>
  );
};

export const setOrder = (order, { id, cb = () => { } }) => {
  return () => {
    const obj = getById(id);
    console.log(id);
    if (obj) {
      const { canvas } = obj;
      let index = () => canvas._objects.findIndex((e) => e.id === obj.id);

      // eslint-disable-next-line default-case
      switch (order) {
        case 0:
          canvas.sendToBack(obj);
          if (index() === 0) {
            canvas.bringForward(obj);
          }
          break;
        case 1:
          canvas.sendBackwards(obj);

          if (index() === 0) {
            canvas.bringForward(obj);
          }
          break;
        case 2:
          canvas.bringForward(obj);
          break;
        case 3:
          canvas.bringToFront(obj);
          break;
      }
      store.dispatch({
        type: "ADD_SORT_OBJECT",
        data: canvas
          .getObjects()
          .map((e) => e.id)
          .reverse(),
      });
      obj.canvas.fire("object:modified");

      // document.cxx].fire("object:modified");

      canvas.renderAll();
      // obj., canvas.sendToBack, canvas.bringForward, and canvas.bringToFront
    }
    cb()
    // handleOrder();
  };
};

export function OrderRow({ id }) {
  const [state, setState] = useState(0);

  const handleOrder = () => {
    const obj = getById(id);
    if (obj) {
      let _o = obj.canvas.getObjects();
      let p = _o.indexOf(obj);
      if (p === 0) {
        setState(0);
      } else if (_o.length === 1) {
        setState(4);
      } else if (p < _o.length - 1) {
        setState(1);
      } else if (p === _o.length - 1) {
        setState(2);
      }
    }
  };



  useEffect(() => {
    handleOrder();
  }, []);
  return (
    <div className="OrderRow flex row1">
      <ButtonIcon
        onClick={setOrder(0, { id, cb: handleOrder })}
        value="Back"
        src={`${document.PUBLIC_URL}/ico2/Back.svg`}
        disabled={state === 0}
      />
      <ButtonIcon
        // onClick={setOrder(1)}
        onClick={setOrder(1, { id, cb: handleOrder })}

        value="Backward"
        src={`${document.PUBLIC_URL}/ico2/Backward.svg`}
        disabled={state === 0}
      />
      <ButtonIcon
        // onClick={setOrder(2)}
        onClick={setOrder(2, { id, cb: handleOrder })}

        value="Froward"
        src={`${document.PUBLIC_URL}/ico2/Forward.svg`}
        disabled={state === 2}
      />
      <ButtonIcon
        // onClick={setOrder(3)}
        onClick={setOrder(3, { id, cb: handleOrder })}

        value="Front"
        src={`${document.PUBLIC_URL}/ico2/Front.svg`}
        disabled={state === 2}
      />
    </div>
  );
}

const mstp = (state) => {
  return {

  };
};
export default connect(mstp)(Avatar);
