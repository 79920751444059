export function getAvatarCropInfo(avatarObj) {
  //check is Clip enabled?
  let _clipP = avatarObj.clipPath;
  let finalData = {
    clip: false,
    clipType: "rect",
    bgType: "color",
    bgData: "#ff0000",
    avatarObj: avatarObj,
  };
  if (_clipP) {
    finalData.clip = true;
    finalData.clipType = _clipP.type; // rect or circle
    if (avatarObj._objects?.length === 2) {
      let _avatarObj = avatarObj._objects[1];
      let _background = avatarObj._objects[0];
      if (_background.type === "image") {
        finalData.bgType = "image";
        finalData.bgData = _background.getElement();
      } else {
        finalData.bgType = "color";
        finalData.bgData = _background.fill || "#ff0000";
      }
      finalData.avatarObj = _avatarObj;
    }
  }
  return finalData;
}
