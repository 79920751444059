/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import "./_Video.scss";

import Tabium from "../../../Chemistry/Compound/Tabium";
// import { addVideoToCanvasAndStore } from "../../../../utils/videoPlayThings";

import DragUpload from "../../../Chemistry/Atom/DragUpload";
import axios from "axios";
import {
  url,
  token,
  getById,
  fitImageToRect,
  MoveSeekTo,
  fitCenterToCXX,
} from "../../../../utils";
import store from "../../../../store/store";
import { after } from "lodash";
import VideoWrapper from "../../../Chemistry/Atom/VideoWrapper";
import uuid from "uuid-random";
import { UploadOption } from "../../../Chemistry/Compound/UploadScroll";
import {
  addVideoToCanvasAndStore,
  askConvertedVideo,
} from "../../../../utils/videoPlayThings/addVideoToCanvasAndStore";
import { useLocation } from "react-router";
import {
  customVideoSeek,
  videoPlayUpdateFilter,
} from "../../../../utils/videoPlayThings";
import { getObjectCoords } from "../../../../utils/cropyThings";
import Model from "reactjs-popup";


export default connect((state) => ({
  list: state.media.video.list,
  list2: state.media.video_upload.list,
}))(function Video({ list, list2 }) {
  const { state } = useLocation();

  const handleDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      const { name, size } = acceptedFiles[0];
      if (acceptedFiles[0].size < 100 * 2 ** 20) {
        let formData = new FormData();
        formData.append("name", name);
        formData.append("category", "autovid_video");
        formData.append("media_file", acceptedFiles[0]);
        let id = uuid();
        document.FeedBack.pushList({ id, item: { name, size } });
        axios
          .post(`${url}/api/userlibrary/upload/`, formData, {
            headers: { Authorization: `Token ${token}` },
            onUploadProgress: (e) => {
              // console.log((e.loaded / e.total) * 100);
              document.FeedBack.updatePrgress({
                id,
                progress: (e.loaded / e.total) * 100,
              });
            },
          })
          .then((res) => {
            let obj = res.data;

            store.dispatch({
              type: "MEDIA_WITH_FRONT",
              data: { type: "video_upload", list: [obj] },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, []);

  const handleVideo = (p) => {
    // console.log(p);
    return async () => {
      if (state?.id == undefined) {
        addVideoToCanvasAndStore(p)();
      } else {
        store.dispatch({ type: "LOADING", data: "Replacing the Video" });
        store.dispatch({
          type: "LLOAD",
          data: "Video",
        });
        store.dispatch({
          type: "SCENE_DISABLED",
          data: true,
        });

        let vidEle = document.getElementById(state.id);

        let obj = getById(state.id);

        let pre = getObjectCoords(obj);
        const _prevObjOrgCoord = {
          height: obj._originalElement.height,
          width: obj._originalElement.width,
          angle: obj.angle,
        };
        let _backupProperties = {
          clipPath: obj.clipPath,
          height: obj.height,
          width: obj.width,
          //angle: obj.angle,
          cropX: obj.cropX,
          cropY: obj.cropY,
        };
        obj.set({ clipPath: null, cropX: 0, cropY: 0 });

        askConvertedVideo(
          p.id,
          p.ctg,
          ({ res, media_file }) => {
            vidEle._Video = res;
            obj._Video = res
            vidEle.src = media_file;
            vidEle.onloadeddata = (e) => {
              e.target.height = e.target.videoHeight;
              e.target.width = e.target.videoWidth;

              const _newObjOrgCoord = {
                height: obj._originalElement.height,
                width: obj._originalElement.width,
              };

              const _ratio = {
                ratioX: _newObjOrgCoord.width / _prevObjOrgCoord.width,
                ratioY: _newObjOrgCoord.height / _prevObjOrgCoord.height,
              };
              obj.set({ angle: 0 });
              _backupProperties.cropX *= _ratio.ratioX;
              _backupProperties.cropY *= _ratio.ratioY;
              _backupProperties.width *= _ratio.ratioX;
              _backupProperties.height *= _ratio.ratioY;
              if (_backupProperties.clipPath) {
                _backupProperties.clipPath.left *= _ratio.ratioX;
                _backupProperties.clipPath.top *= _ratio.ratioY;
                _backupProperties.clipPath.scaleX *= _ratio.ratioX;
                _backupProperties.clipPath.scaleY *= _ratio.ratioY;
              }
              obj.set({ ..._backupProperties });

              fitImageToRect(obj, pre, false);
              obj.set({ angle: _prevObjOrgCoord.angle });

              // document.txx.setX((x) => {
              //   return x;
              // });
              MoveSeekTo({
                position: "same", cb: ({ x }) => {
                  vidEle.customVideoSeek(x / document.per);
                }
              });
              obj.videoTime = Math.random();
              videoPlayUpdateFilter(obj);
              let duration = vidEle.duration;
              store.dispatch({
                type: "EDIT_ELEMENT",
                data: {
                  id: state.id,
                  data: {
                    duration,
                    trimStart: 0,
                    trimEnd: duration,
                    statyTime: duration,
                  },
                },
              });

              store.dispatch({ type: "LOADING", data: "Replacing the Video" });
              store.dispatch({
                type: "LLOAD",
                data: "Video",
              });
              store.dispatch({
                type: "SCENE_DISABLED",
                data: false,
              });

              obj.canvas?.fire("object:modified");
            };
          },
          "video",
          p
        );
      }
    };
  };
  const [mopen, setmOpen] = useState({ open: false, data: {} })
  return (
    <div className="Video">
      <Tabium arrTab={[{ name: "Stock Media" }, { name: "Uploads" }]}>
        <VideoWrapper type="video" list={list} onPreview={setmOpen} onSelect={handleVideo} />
        {/* <h1>confused in api</h1> */}
        <div>
          <DragUpload
            accept={".mp4,.avi,.webm,.gif,.mov"}
            text="Drag & Drop your video here!"
            onDrop={handleDrop}
          />

          <VideoWrapper
            list={list2}
            type="video_upload"
            onSelect={handleVideo}
            UploadOption={UploadOption}
            height={"71vh"}
            onPreview={setmOpen}
          />
        </div>
      </Tabium>

      <Model
        closeOnDocumentClick
        modal
        open={mopen.open}
        onClose={e => {
          setmOpen({ open: false, data: {} })
        }}
      >
        <div className="HoverImageCard_model">
          {mopen.data.ctype === "image" && <img src={mopen.data.media_file} alt="mone" />}
          {mopen.data.ctype === "video" && <video
            autoPlay
            muted
            controls
            controlsList="nodownload"
            src={mopen.data.media_file} alt="mone"
          />}
        </div>
      </Model>
    </div>
  );
});
