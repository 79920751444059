import { getById, updateTimeLen } from "../../utils";
import { TIMELINE_STICK } from "../../utils/config";

export const ADJUST = ({ state, data, scenes, sceneNumber }) => {
  const { max } = data;

  Object.keys(scenes.elements).forEach((e) => {
    if (scenes.elements[e]) {
      const { adjustLength, enterDelay } = scenes.elements[e];
      let objF = getById(e);
      let obj = objF?._Type;

      switch (obj) {
        case "background":
          scenes.elements[e].stayTime = max;
          return;
        case "video":
          if (scenes.haveEasyMode) {
            scenes.elements[e].stayTime = max - enterDelay;
          } else if (adjustLength === 3) {
            scenes.elements[e].stayTime = max - enterDelay;
          }
          return;

        case "music":
          scenes.elements[e].stayTime = max;

          break;

        case "avatar":
          break;

        default:
          return;
      }
      console.log('adjust end')
    }
  });
  state.beforeMax = max;

  if ("STICKY") {
    let ele = scenes.elements[data.id];

    if (ele) {
      let eleList = Object.keys(scenes.elements);

      let _min = {};
      let _minD = {};
      let tempMin = 999999999999999;
      let tempMinD = 99999999999999;

      let n = (e) => Number(Number(e).toFixed(3));
      eleList.map((e) => {
        let k = scenes.elements[e];
        if (k && e !== data.id && e !== `background${sceneNumber}`) {
          let min = n(Math.abs(ele.enterDelay - k.enterDelay));
          if (min < tempMin) {
            _min = {
              ...k,
              min,
            };
            tempMin = min;
          }
        }

        if (k && e !== data.id && e !== `background${sceneNumber}`) {
          let min = n(
            Math.abs(
              ele.enterDelay + ele.stayTime - (k.enterDelay + ele.stayTime)
            )
          );
          if (min < tempMinD) {
            _minD = {
              ...k,
              min,
            };
            tempMinD = min;
          }
        }
      });

      switch (data.what) {
        case "DRAG":
          if (
            n(Math.abs(_min.enterDelay - ele.enterDelay)) <=
            TIMELINE_STICK / document.per
          ) {
            ele.enterDelay = n(_min.enterDelay);
          }

          if (
            n(
              Math.abs(
                _minD.enterDelay +
                _minD.stayTime -
                (ele.enterDelay + ele.stayTime)
              )
            ) <=
            TIMELINE_STICK / document.per
          ) {
            ele.enterDelay = n(
              _minD.enterDelay + _minD.stayTime - ele.stayTime
            );
          }
          break;

        case "RESIZE_LEFT":
          if (
            n(Math.abs(_min.enterDelay - ele.enterDelay)) <=
            TIMELINE_STICK / document.per
          ) {
            let diff = n(ele.enterDelay) - n(_min.enterDelay);
            ele.enterDelay = n(_min.enterDelay);
            ele.stayTime += diff;
          }
          break;

        case "RESIZE_RIGHT":
          if (
            n(
              Math.abs(
                _minD.enterDelay +
                _minD.stayTime -
                (ele.enterDelay + ele.stayTime)
              )
            ) <=
            TIMELINE_STICK / document.per
          ) {
            ele.stayTime = n(
              _minD.enterDelay + _minD.stayTime - ele.enterDelay
            );
          }
          break;
        default:
          break;
      }
    }
  }

  updateTimeLen(true, state);
};
