import React, { useEffect, useState } from "react";
import Card from "./Card";
import TabHeader from "./TabHeader";
import Modal from "reactjs-popup";
import Popup from "./Popup";
import { Ratio } from "./VideoCreator";
import ButtonIcon from "../../components/Chemistry/Atom/ButtonIcon";
import InfiniteWrapper from "../../components/Chemistry/Atom/Infinite";
import mystore from "../appStore";
import uuid from "uuid-random";
import { connect } from "react-redux";
import { FirstMyVideoCard } from "./MyVideo";
import AvatarChoosePopup from "./PopUp/AvatarChoosePopup";
import Button from "./Button";
import { fetcher, url } from "../../utils";


export default connect((state) => ({
  img_human: state.media.img_human,
  human: state.media.human,
  nonhuman: state.media.nonhuman,
  img_nonhuman: state.media.img_nonhuman,
  isVideo: state.isVideo
}))(function Templates({ human, img_human, nonhuman, img_nonhuman, isVideo }) {
  const [tab, setTab] = useState(0);
  // const [lists, setLists] = useState({ human: [], nonhuman: [] });
  const handleTab = (i) => {
    setTab(i);
  };
  // useEffect(() => {
  //   const { human, nonhuman } = mystore.getState().media;
  //   //console.log({ human, nonhuman });
  //   setLists({ human: human.list, nonhuman: nonhuman.list });
  // }, []);

  return (
    <div className="Templates">
      <TabHeader
        arrTab={[{ name: "Avatar Videos" }, { name: "Non-Avatar Videos" }, { name: "Image Templates" }]}
        onTab={handleTab}
        nowTab={tab}
      />
      <ListTab
        isVideo={isVideo}
        tab={tab}
        list={({ 0: human.list, 1: nonhuman.list, 2: img_human.list })}
        type={({ 0: "human", 1: "nonhuman", 2: "img_human" })}
        handleTab={handleTab}
      />
    </div>
  );
});

const handleJumpImage = () => {
  window.open("/image?mode=2", "_blank")
}

export function ListTab({ tab, type = {}, list = {}, frontList = {}, isVideo, handleTab }) {
  const id = uuid();
  let _id = `_${id};`;

  if (tab === 0) {
    return (
      <div className="ListTab flex" id={_id} style={{ height: "70vh", overflowY: "scroll" }}>
        {frontList[tab]}
        <InfiniteWrapper type={type[tab]} target={_id} mystore={mystore}>
          {[
            ...isVideo ? [<FirstMyVideoCard ele={<AvatarChoosePopup def={0} trigger={<Button value={<>Create Video</>} />} />} />] : [

              <FirstMyVideoCard ele={<Button value={<>Create Image</>} onClick={handleJumpImage} />} />
            ]
            , ...list[tab].map((e, i) => {
              return (
                <ModelCardVideo
                  key={e.id}
                  name={e.name}
                  src={e?.hVideo?.thumbnail}
                  data={e}
                  id={e.id}
                  isVideo={isVideo}

                />
              );
            })]}
        </InfiniteWrapper>
      </div>
    );
  } else if (tab === 1) {
    // console.log();
    if (!isVideo) {
      handleTab(0);
      return <div></div>
    }
    return (
      <div className="ListTab flex" id={_id}>
        {frontList[tab]}
        <InfiniteWrapper type={type[tab]} target={_id} mystore={mystore}>
          {[
            <FirstMyVideoCard ele={<AvatarChoosePopup def={1} trigger={<Button value={<>Create Video</>} />} />} />
            , ...list[tab].map((e, i) => {
              // console.log(e);
              return (
                <ModelCardVideo
                  key={e.id}
                  name={e.name}
                  src={e.hVideo.thumbnail}
                  data={e}
                  id={e.id}
                  isVideo={isVideo}
                />
              );
            })]}
        </InfiniteWrapper>
      </div>
    );
  } else if (tab === 2) {
    return (
      <div className="ListTab flex" id={_id} style={{ height: "70vh", overflowY: "scroll" }}>
        {frontList[tab]}
        <InfiniteWrapper type={type[tab]} target={_id} mystore={mystore}>
          {[[

            <FirstMyVideoCard ele={<Button value={<>Create Image</>} onClick={handleJumpImage} />} />
          ]
            , ...list[tab].map((e, i) => {
              return (
                <ModelCardVideo
                  key={e.id}
                  name={e.name}
                  src={e?.hVideo?.thumbnail}
                  data={e}
                  id={e.id}
                  isVideo={false}

                />
              );
            })]}
        </InfiniteWrapper>
      </div>
    );
  }
}

let Si = {
  0: "hVideo",
  1: "sVideo",
  2: "vVideo",
};

const ModelCardVideo = (e) => {
  const [tab, setTab] = useState(0);
  const handleRatio = (k) => {
    //console.log(k);
    setTab(k);
  };
  console.log(e)
  return (
    <Modal
      trigger={
        <button className="ModelCardVideo">
          <Card src={e.isVideo ? e.src : e.data.thumbnail || e.data.hVideo.thumbnail} isPer={e.isVideo ? e.data.hVideo.isPersonalized : e.data.isPersonalized} name={e.name} playbutton={true} />
        </button>
      }
      modal
    >
      {(close) => (
        <Popup
          name={e.name}
          close={close}
          footer={<div className="flex">

            <Button value="Edit This Template" onClick={() => {
              // console.log(e);
              if (e.isVideo) {
                fetcher({
                  url: `${url}/api/newvideo/video/copy/${e.data.hVideo.id}/`,
                  res: (res) => {
                    const _videoP = document.getElementById("_templateVideo");
                    if (_videoP) {
                      _videoP.pause()
                    }
                    window.open(`/video/${res.data.result.id}/?template=true`, "_blank");
                  },
                });
              } else {
                fetcher({
                  url: `${url}/api/newimage/image/copy/${e.id}/`,
                  res: (res) => {
                    window.open(`/image/${res.data.result.id}/?template=true`, "_blank");
                    //window.location.pathname = (`/image/${res.data.result.id}/?template=true`);

                  },
                });

              }
            }} />


            {!e.isVideo && <Button value="Use This Template" style={{ marginLeft: "10px" }} onClick={() => {
              // console.log(e);
              fetcher({
                url: `${url}/api/newimage/template/copy/${e.id}/`,
                res: (res) => {
                  window.location.pathname = `/myimages/${res.data.result.id}/`;

                },
              });
            }} />}


          </div>}

          centerHeader={false}
          haveButton={true}

        >
          <div className="ModelCardVideo_IMG">
            {/* <img key={tab} src={e.data[Si[tab]].thumbnail} alt="dempopopp" /> */}
            {e.isVideo ? <video
              key={tab}
              id="_templateVideo"
              poster={e.data[Si[tab]].thumbnail}
              src={e.data[Si[tab]].mainVideoGenerate.video}
              controls
              controlsList="nodownload"
              autoPlay
            ></video> : <img src={e.data.thumbnail} />}
          </div>
        </Popup>
      )}
    </Modal>
  );
};
