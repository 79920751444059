/* eslint-disable */
import React from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router";
import { fitImageToRect, getById, Index, Vid } from "../../../../../../utils";
import Angle from "../../../../../Chemistry/Atom/Angle";
import ArrowWrapper from "../../../../../Chemistry/Atom/ArrowWrapper";
import ButtonIcon from "../../../../../Chemistry/Atom/ButtonIcon";
import Dimensions from "../../../../../Chemistry/Atom/Dimensions";
import EditSelect from "../../../../../Chemistry/Atom/EditSelect";
import TitleRow from "../../../../../Chemistry/Atom/TitleRow";
import TitleWrapper from "../../../../../Chemistry/Atom/TitleWrapper";
import Vr from "../../../../../Chemistry/Atom/Vr";
import { OrderRow } from "../../../Avatar/Avatar";
import {
  ControlsBackground,
  InputRangeWithTitle,
} from "../Background/BackgroundSettings";

import "./_VideoSettings.scss";
import Mask from "../../../../../Chemistry/One/Mask";
import Shadow from "../../../../../Chemistry/One/Shadow";
import Animation from "../../../../../Chemistry/Compound/Animation";
import Trim from "../../../../../Chemistry/One/Trim";
import store from "../../../../../../store/store";
import ImageFilters from "../../../../../../utils/fabricFilters";
import objectCloner from "../../../../../../utils/objectCloner";
import { deleteFromAll } from "../../../../../../utils/deleteCloneOpeationObj";
import {
  objProMapper,
  storeAnimatationToStore,
} from "../../../../../../utils/shortcutFunction";
import {

  UpdateBackground,
} from "../../../../../Chemistry/One/Draggy";
import {
  onNoneMask,
  resetImageMask,
  startImageCropProcess,
} from "../../../../../../utils/cropyThings";
import { customVideoSeek } from "../../../../../../utils/videoPlayThings";
import { DelaySettingsInput } from "../../../../../Chemistry/One/DelaySettingsInput";
import { SIZES } from "../../../../../../utils/config";
import MaskApplier from "../../../../../../utils/MaskApplier";
import FilterApplier from "../../../../../../utils/FilterApplier";
import { discardCurrentWork } from "../../../../../../utils/useFullFunctions/discardWork";

export default function VideoSettings() {
  const { url } = useRouteMatch();
  const { state } = useLocation();
  return (
    <Switch>
      <Route path={`${url}/mask`}>
        <Mask
          title="< Mask"
          obj={getById(state.id)}
          type="mask"
          onSelect={MaskApplier({ id: state.id })}
        />
      </Route>
      {document.editorType === "video" && <Route path={`${url}/trim`}>
        <Trim />
      </Route>}

      <Route path={`${url}/filter`}>
        <Mask
          title="< Filter"
          type="filter"
          obj={getById(state.id)}
          onSelect={FilterApplier({ id: state.id })}
        />
      </Route>
      <Route path={`${url}/animation`}>
        <Animation
          type="animation"
          title="< Animation"
          obj={getById(state.id)}
          onSelect={storeAnimatationToStore(state.id)}
        />
      </Route>
      <Route path={`${url}/shadow`}>
        <Shadow id={state.id} />
      </Route>

      <Route path={url} exact>
        <DefaultPage />
      </Route>
    </Switch>
  );
}
function DefaultPage() {
  const { url } = useRouteMatch();
  const { state } = useLocation();

  const handleChange = (e) => {
    discardCurrentWork({ _goB: true });
    const { cxx } = document;
    let obj = getById(state.id);

    if (obj) {
      obj.set({ opacity: e / 100 });
      obj.canvas.renderAll();
    }
  };
  const handleDelete = () => {
    discardCurrentWork({ _goB: true });
    deleteFromAll(state.id);
  };
  const handleClone = () => {
    discardCurrentWork({ _goB: true });
    objectCloner({ _id: state.id, clone: true });
  };
  const handleCrop = () => {
    const cb = () => store.dispatch({ type: "SET_FRAME", data: false });
    startImageCropProcess(getById(state.id), document.cccx.current);
    //store.dispatch({ type: "SET_FRAME", data: true });
  };
  const handleOnVolume = (e) => {
    document.getElementById(state.id).volume = parseFloat(e / 100);
    getById(state.id)._Volume = e / 100;
  };

  const handleSetVolume = () => {
    store.dispatch({
      type: "EDIT_ELEMENT",
      data: {
        id: state.id,
        data: {
          volume: getById(state.id)._Volume,
        },
      },
    });
  };
  const handleReplace = () => {
    discardCurrentWork({ _goB: true });
    document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/video`, {
      tab: 0,
      id: state.id,
    });
  };
  const handleFit = () => {
    discardCurrentWork({ _goB: true });
    let obj = getById(state.id);
    if (obj) {
      // let cxx = document.cxx[Index()];
      // let a =..

      fitImageToRect(obj, {
        top: 0,
        left: 0,
        height: SIZES[document?._size || 0].height,
        width: SIZES[document?._size || 0].width,
        // selectable: false,
      });

    }
  }

  const _handleOnClickSettings = (e) => {
    discardCurrentWork({ _goB: true });
  }


  return (
    <>
      <div className="MusicSettings VideoSettings">
        <TitleRow title="Video Settings"></TitleRow>
        <ControlsBackground
          text="Replace Video"
          _4_src={`${document.PUBLIC_URL}/ico/clone.svg`}
          _6_src={`${document.PUBLIC_URL}/icons/expand.svg`}
          on_6={handleFit}
          on_3={handleDelete}
          on_2={handleCrop}
          on_4={handleClone}
          on_1={handleReplace}
          _5_src={document.editorType === "video" && `${document.PUBLIC_URL}/ico3/trim.svg`}

          on_5={() => {
            discardCurrentWork({ _goB: true });
            document.hist.push(`${url}/trim`, { id: state.id })
          }}
        />
        <Vr m="20px" />
        <TitleWrapper title="Arrange" onClick={_handleOnClickSettings}>
          <OrderRow id={state.id} />
        </TitleWrapper>

        <Vr m="10px 0 0px" />
        {document.editorType === "video" && <>
          <ArrowWrapper
            m="15px"
            p="10px 0px"
            to={`${url}/animation`}
            data={{ id: state.id }}
            ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/animation.svg`} />}
          >
            Animation
          </ArrowWrapper>
          <Vr m="0px" />
        </>}
        <ArrowWrapper
          m="15px"
          p="10px 0px"
          to={`${url}/mask`}
          data={{ id: state.id }}
          ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/mask.svg`} />}
        >
          Mask
        </ArrowWrapper>
        <Vr m="0px" />
        {/* <ArrowWrapper
          m="15px"
          p="10px 0px"
          to={`${url}/filter`}
          data={{ id: state.id }}
          ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/filter.svg`} />}
        >
          Filter
        </ArrowWrapper>
        <Vr m="0px" /> */}

        <ArrowWrapper
          m="15px"
          p="10px 0px"
          to={`${url}/shadow`}
          data={{ id: state.id }}
          ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/shadow.svg`} />}
        >
          Shadow
        </ArrowWrapper>

        <Vr m="0px 0 15px"></Vr>
        {document.editorType === "video" && <>
          <div className="MusicSettings_loop">
            Adjust video length{" "}
            <EditSelect
              selectedIndex={
                store.getState().scenes[Index()].elements[state.id]
                  ?.adjustLength || 2
              }
              disabled={true}
              selected={null}
              list={[null, "Loop", "Freeze at Last Frame", "Adjust as Scene"]}
              onSelect={(e, b) => {
                _handleOnClickSettings();

                let vidEle = document.getElementById(state.id);
                let data = {};
                if (b === 1) {
                  data = { resize: false, drag: false };
                  vidEle.playbackRate = 1;
                } else if (b === 0) {
                  data = { resize: true, drag: false };
                  vidEle.playbackRate = 1;
                } else if (b === 2) {
                  data = {
                    // stayTime: timeLine - ele.enter - ele.exit,
                    resize: false,
                    drag: false,
                  };
                  vidEle.playbackRate = 1;
                } else if (b === 3) {
                  data = {
                    enterDelay: 0,

                    resize: true,
                    drag: true,
                  };
                }
                store.dispatch({
                  type: "EDIT_ELEMENT",
                  data: {
                    id: state.id,
                    data: {
                      ...data,
                      adjustLength: b,
                      // stayTime: ele.trimEnd - ele.trimStart,
                      // enterDelay: 0,
                    },
                  },
                });
                // if()
                if (
                  store.getState().scenes[Index()].elements[state.id]
                    ?.adjustLength === 3
                )
                  store.dispatch({
                    type: "EDIT_ELEMENT",
                    data: {
                      id: state.id,
                      data: {
                        stayTime: document._Max / document.per,
                        // stayTime: ele.trimEnd - ele.trimStart,
                        // enterDelay: 0,
                      },
                    },
                  });
                UpdateBackground();
              }}


            />
          </div>
          <Vr m="15px"></Vr>
          <TitleWrapper title="Volume">
            <InputRangeWithTitle
              // value={33}
              className="ImagePanel_range"
              onChange={handleOnVolume}
              onFullChange={handleSetVolume}
              value={Number(getById(state.id)._Volume * 100).toFixed(0)}
              objActive={state.id}

            // onFullChange={(e) => alert(e.target.value)}
            // onChange={() => {}}
            />
          </TitleWrapper>
        </>
        }

        <TitleWrapper title="Opacity">
          <InputRangeWithTitle
            value={Math.round(getById(state.id).opacity * 100)}
            className="ImagePanel_range"
            // onFullChange={(e) => alert(e.target.value)}
            onChange={handleChange}
            objActive={state.id}

          />
        </TitleWrapper>



        {/* <Vr m="0px" /> */}


        <Vr m="0px" />
        <TitleWrapper title="Layout" onClick={_handleOnClickSettings}>
          <Dimensions id={state.id} ele={<Angle id={state.id} />} />
        </TitleWrapper>
        <Vr m="0px" />

      </div>
      <DelaySettingsInput id={state.id} />
    </>
  );
}
