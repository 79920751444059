import { SIZES } from "./config";
function setZoom(canvas, newZoomLevel) {
  // zoomLevel = newZoomLevel;
  // let canvasWidth = canvas.width;
  // let canvasHeight = canvas.height;
  //console.log({ canvasWidth, canvasHeight });
  // let preZoom = 0.6630208333333333;
  canvas.setZoom(newZoomLevel);

  canvas.setDimensions({
    width: SIZES[document?._size || 0].width * newZoomLevel,
    height: SIZES[document?._size || 0].height * newZoomLevel,
  });
  canvas.renderAll();
}

document.setZoom = setZoom;
