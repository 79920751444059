import React, { useEffect, useState } from "react";
import Slide from "react-input-slider";
import "../Atom/_Atom.scss";
export default function InputRange({
  min,
  max,
  value,
  onChange,
  lastChange,
  step,
  onMouseUp = () => { },
  className,
  objActive = null,
  style = {},
}) {
  const [_value, setValue] = useState(value);

  const handleChange = (e) => {
    setValue(e.x);
    onChange(e);
  };
  useEffect(() => {
    setValue(value)
    // onMouseUp({ x: value });
    // console.log(objActive);

  }, [objActive])
  const handleLast = (e) => {
    onMouseUp({ x: _value });
  };


  return <div className={`InputRange ${className}`} style={style}>
    <Slide
      axis="x"
      xmax={max}
      xmin={min}
      xstep={step}
      onDragEnd={handleLast}
      x={_value}
      onChange={handleChange}
      styles={{
        track: {
          backgroundColor: "#242424",
        },
        active: {
          backgroundColor: "#e7416a",
        },
        thumb: {
          width: 16,
          height: 16,
          opacity: 0.8,
        },
      }}
    />
  </div>

}
