import { getById } from "../../../../../utils";

export const CURRENT_AVATAR = ({ state, data }) => {
  if (data.image) {
    state.scenes.currentAvatar.image = data.image;
  }
  if (data.audio) {
    state.scenes.currentAvatar.audio = data.audio;
  }
};
