import { composeWithDevTools } from "redux-devtools-extension";
// import { getById } from "./utils";
import uuid from "uuid-random";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import produce, { current } from "immer";
import { url } from "../utils";
import { uniqBy } from "lodash";
import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REST_ERROR_STATE,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  FORGET_PASSWORD_SUCCESS,
  SIGNUP_VERIFY_FAIL,
  SIGNUP_VERIFY_SUCCESS,
  RESET_STATE,
} from "../accounts/actions/types";
export const vid = () => {
  return document.location.href.split("/")[4];
};
/**
 * @param {object} [state={}]
 */
const initState = {
  media: {
    nonhuman: {
      next: `${url}/api/newvideo/video/template/`,
      list: [],
    },
    human: {
      next: `${url}/api/newvideo/video/template/?human=0`,
      list: [],
    },

    img_nonhuman: {
      next: `${url}/api/newvideo/image/template/`,
      list: [],
    },
    img_human: {
      next: `${url}/api/newimage/image/template/`,
      list: [],
    },
    generated: {
      next: `${url}/api/newvideo/video/generated/`,
      list: [],
    },
    draft: {
      next: `${url}/api/newvideo/video/draft/`,
      list: [],
    },
    img_generated: {
      next: `${url}/api/newimage/image/?generated=1`,
      list: [],
    },
    img_draft: {
      next: `${url}/api/newimage/image/?generated=0`,
      list: [],
    },
    salespage: {
      next: `${url}/api/salespage/?isPublish=1&appType=1`,
      list: [],
    },

    template: {
      next: `${url}/api/salespage/public/ `,
      list: [],
    },
    mailclient: {
      next: true,
      list: [],
    },
    ivideo: {
      next: null,
      list: [],
    },
    iimage: {
      next: `${url}/api/newimage/image/`,
      list: [],
    },
    itemplate: {
      next: `${url}/api/newimage/image/template/`,
      list: [],
    },
    isolo_history: {
      next: `isolo_history`,
      list: [],
    },
    download_history: {
      next: `download_history`,
      list: [],
    },
    solo_history: {
      next: `solo_history`,
      list: [],
    },
    batch_history: {
      next: `batch_history`,
      list: [],
    },
    solomail_history: {
      next: `solomail_history`,
      list: [],
    },
    csvupload: {
      next: `${url}/api/userlibrary/upload/?category=csvupload`,
      list: [],
    },
  },
  mydetail: {
    thumbnailInst: {},
    sharingPage: {},
    linkHistory: [],
    mergeTag: [],
  },
  user: {},
  auth: { isAuthenticated: false },
  isVideo: true,
};
const reducer = produce((state = initState, action) => {
  const { data } = action;
  // console.log(action);

  switch (action.type) {
    case "":
      break;

    case "SETTYPE":
      state.isVideo = data;
      document.isVideo = data;
      break;

    case "MEDIA_WITH_NEXT":
      state.media[data.type].next = data.next;
      const _fId = data.list?.[0]?.id;
      let _alreadyExist = false;
      if (_fId) {
        _alreadyExist = state.media[data.type].list.find((e) => e.id === _fId);
      }
      if (!_alreadyExist) {
        state.media[data.type].list.push(...data.list);
      }
      break;

    case "MEDIA_WITH_FRONT":
      if (!state.media[data.type].list.find((e) => e.id === data.list?.[0]?.id)) {
        state.media[data.type].next = data.next;
        state.media[data.type].list = [
          ...data.list,
          ...state.media[data.type].list,
        ];
      }


      if ("SWITCHING") {
        switch (data.type) {
          case "download_history":
            state.mydetail.downloadGenCount =
              state.media[data.type].list.length;
            break;
          case "solomail_history":
            state.mydetail.soloMailGenCount =
              state.media[data.type].list.length;
            break;
          case "solo_history":
            state.mydetail.sharingPageGenCount =
              state.media[data.type].list.length;
            break;

          case "isolo_history":
            state.mydetail.imageSoloGenCount =
              state.media[data.type].list.length;
            break;

          default:
            break;
        }
      }

      break;

    case "ADD_SINGLE_MEDIA":
      if (data.addIfPresent) {
        if (!state.media[data.type].list.find((e) => e.id === data.result?.id) && state.media[data.type].list.length) {
          state.media[data.type].list.unshift(data.result);
        }
      } else {
        if (!state.media[data.type].list.find((e) => e.id === data.result?.id)) {
          state.media[data.type].list.unshift(data.result);
        }
      }
      break;

    case "DELETE_MEDIA":
      state.media[data.type].list = state.media[data.type].list.filter(
        (e) => data.id !== e.id
      );
      break;

    case "EDIT_DETAIL":
      state.mydetail = {
        ...state.mydetail,
        ...data,
      };
      break;

    case "EDIT_MEDIA":
      let item = state.media[data.type].list.findIndex((e) => e.id === data.id);
      if (item >= 0) {
        state.media[data.type].list[item] = {
          ...state.media[data.type].list[item],
          ...data.edit,
        };
        if ("SWITCHING") {
          switch (data.type) {
            case "download_history":
              state.mydetail.downloadGenCount =
                state.media[data.type].list.length;
              break;
            case "solomail_history":
              state.mydetail.soloMailGenCount =
                state.media[data.type].list.length;
              break;
            case "solo_history":
              state.mydetail.sharingPageGenCount =
                state.media[data.type].list.length;
              break;

            default:
              break;
          }
        }
      }
      break;

    case "ADD_ID":
      state.mydetail = data.detail;
      state.media.ivideo.list = data.detail.sceneThumbnails;
      document.currentImage = {
        ...data.detail.thumbnailInst,
      };
      break;

    case "USER_DATA":
      state.user = { ...data };
      state.auth = { isAuthenticated: data.isAuthenticated };

      break;
    case "ADD_MEDIA_IN_LIST":
      var { type, result } = data;
      console.log({ type, result });
      const list = [...state.media[type].list];

      const isFound = list.some((element) => {
        if (element.id === result.id) {
          return true;
        }
      });

      console.log({ alredyAddedByWebsocket: isFound }); // 👉️ true

      if (!isFound) {
        // object is contained in array
        state.media[type].list = [{ ...result }, ...list];

        if (type === "batch_history") {
          state.mydetail = {
            ...state.mydetail,
            batchMailGenCount: state.mydetail.batchMailGenCount + 1,
          };
        } else if (type === "solomail_history") {
          state.mydetail = {
            ...state.mydetail,
            soloMailGenCount: state.mydetail.soloMailGenCount + 1,
          };
        } else {
        }
      }

      break;

    //account related

    case USER_LOADING:
      state = {
        ...state,
        isLoading: true,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      // console.log(action.payload);
      if (action.payload.isError) {
        state.auth = {
          isAuthenticated: false,
          isError: true,
          message: action.payload.message,
        };
        state.user = {};
      } else {
        // window.open(`/`);
        state.user = { ...action.payload.user };
        state.auth = { isAuthenticated: true, token: action.payload.token };
      }

      break;
    case REST_ERROR_STATE:
      state.auth = { isAuthenticated: false };
      break;

    case FORGET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case SIGNUP_VERIFY_SUCCESS:
    case REGISTER_SUCCESS:
      console.log("i am running");
      localStorage.removeItem("token");
      state.auth = { isError: false };
      break;
    case REGISTER_FAIL:

    case SIGNUP_VERIFY_FAIL:
    case FORGET_PASSWORD_FAIL:
    case RESET_PASSWORD_FAIL:
    case LOGIN_FAIL:
      localStorage.removeItem("token");
      state = {
        ...state,
        ...action,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      state.user = {};
      state.auth = { isAuthenticated: false };

    case "DEFAULT":
      state.media = {
        ...state.media,
        download_history: {
          next: `download_history`,
          list: [],
        },
        solo_history: {
          next: `solo_history`,

          list: [],
        },
        batch_history: {
          next: `batch_history`,

          list: [],
        },
        solomail_history: {
          next: `solomail_history`,

          list: [],
        },
        isolo_history: {
          next: `isolo_history`,
          list: [],
        },
      };
      break;

    default:
      return state;
  }
});

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});
const mystore = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
// const Store = mystore();
export default mystore;
