document.duplicateName = {
  hash: {
    text: 0,
    image: 0,
    video: 0,
    shape: 0,
    elements: 0,
    background: 0,
    music: 0,
  },
  getName: function ({ name, type }) {
    this.hash[type]++;
    return name + `_${this.hash[type]}`;
  },
};
