
import React, { useEffect, useState } from "react";
import TitleWrapper from "../../../Chemistry/Atom/TitleWrapper";
import "./_Elements.scss";
import Slider from "react-slick";
import { Link, useHistory, Switch, Route, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  Index,
  getSVGObjColor,
  setSVGObjColor,
  url,
  fetcher,
  functionListen,
  getById,
  setShapeSVGObjColor,
  fitCenterToCXX,
  fitImageToRect,
} from "../../../../utils";
import { fabric } from "fabric";
import InfiniteWrapper from "../../../Chemistry/Atom/Infinite";
import { useRef } from "react";
import InputIcon from "../../../Chemistry/Atom/InputIcon";
import TitleRow from "../../../Chemistry/Atom/TitleRow";
import SlideContainer from "../../../Chemistry/Atom/SlideContainer";
import store from "../../../../store/store";
import { getObjectCoords } from "../../../../utils/cropyThings";
import { InitAddObjectEvents } from "../../../../utils/InitAddObjectEvents";
import { loadElement } from "./functions/loadElement";
import { deleteFromAll } from "../../../../utils/deleteCloneOpeationObj";

export default connect((state) => ({
  icons: state.media.icons.list,
  shapes: state.media.shapes.list,
  elements: state.media.elements.list,
  emoji: state.media.emoji.list,
}))(function Elements({ icons, shapes, elements, emoji }) {
  const { state } = useLocation();

  return (
    <Switch>
      <Route path={`/${document.editorType}/:vid/:id/elements/icons`}>
        <ElementsListInfinite
          className="ElementsListInfinite_shapes ElementsListInfinite_icons"
          list={icons}
          type="icons"
          max={80}
        />
      </Route>
      <Route path={`/${document.editorType}/:vid/:id/elements/shapes`}>
        <ElementsListInfinite
          className="ElementsListInfinite_shapes"
          list={shapes}
          type="shapes"
          max={80}
        />
      </Route>
      <Route path={`/${document.editorType}/:vid/:id/elements/emoji`}>
        <ElementsListInfinite
          className="ElementsListInfinite_shapes"
          list={emoji}
          type="emoji"
          max={40}
        />
      </Route>
      <Route path={`/${document.editorType}/:vid/:id/elements/illustrations`}>
        <ElementsListInfinite list={elements} type="elements" />
      </Route>
      <Route exact path={`/${document.editorType}/:vid/:id/elements`}>
        <DefaultPage
          icons={icons}
          shapes={shapes}
          elements={elements}
          emoji={emoji}
        />
      </Route>
    </Switch>
  );
});

const ElementsListInfinite = ({ list, type, className = "", max }) => {
  const ref = useRef();
  const [searchArr, setArr] = useState([]);
  const [isSearched, setSearch] = useState(false);
  const { state } = useLocation();

  const handleAdd = (svg) => {
    return async () => {
      const cxx = document.cxx[Index()];

      // if replace then store postion
      let _prevObjData;
      if (state?.replace) {
        // already exist
        let _obj = getById(state.id);
        if (_obj) {
          _prevObjData = { pos: getObjectCoords(_obj), _Animation: _obj._Animation };
          cxx.remove(_obj)
        }
      }

      store.dispatch({ type: "LOADING", data: "Element is Loading ..." });
      let _newObj = await loadElement(svg.src);
      store.dispatch({ type: "LOADING", data: "Element is Loading ..." });
      if (!_newObj) {
        return null;
      }

      // set default data
      _newObj.set({ _Type: "elements", strokeWidth: 0, fill: "#000000" });
      cxx.add(_newObj);
      if (type === "shapes") {
        setShapeSVGObjColor(_newObj, _newObj._CurrentColor[0]);
        _newObj.stroke = _newObj._CurrentColor[0];
      }

      //check if new or replace
      if (_prevObjData) {
        // already exist
        const _crntId = state.id;
        fitImageToRect(_newObj, _prevObjData.pos, false, false);

        _newObj.id = _crntId;
        _newObj._Animation = _prevObjData._Animation;

        InitAddObjectEvents({
          obj: _newObj,
          _id: _crntId,
          name: document.duplicateName.getName({ type: "elements", name: svg.name }),
          type: "elements",
          src: svg.src,
          data: { type },
          returnToUrl: false,
          onAddActive: false,
          _toStore: false
        });
        cxx.fire("object:modified");

      } else {
        //new element added
        fitCenterToCXX({ obj: _newObj, posx: 0.2 });

        InitAddObjectEvents({
          obj: _newObj,
          name: document.duplicateName.getName({ type: "elements", name: svg.name }),
          type: "elements",
          src: svg.src,
          data: { type },
          returnToUrl: false,
          onAddActive: false,
        });
      }


      // cxx.setActiveObject(_newObj);
      cxx.renderAll();
    };
  };

  useEffect(() => {
    functionListen("#ElementSearch", handleChange);
  }, []);
  const handleChange = (e) => {
    let d = document.querySelector("#ElementSearch").value;
    if (d.length >= 1) {
      fetcher({
        method: "GET",
        url: `${url}/api/assets/${type}/?q=${d}`,
        res: (res) => {
          setSearch(true);
          //console.log(res.data.results);
          setArr(res.data.results);
        },
        err: (err) => {
          setSearch(false);
        },
      });
    } else {
      setSearch(false);
    }
  };
  return (
    <SlideContainer>
      <div
        id="ElementListInfinite"
        className={`ElementListInfinite ${className}`}
        ref={ref}
      >
        <TitleRow
          title={
            <>
              <img
                alt="s"
                onClick={() => {
                  document.hist.goBack();
                }}
                className="backBtn"
                src={`${document.PUBLIC_URL}/ico/backBtn.svg`}
              />
              <InputIcon
                src={`${document.PUBLIC_URL}/icons/search.svg`}
                placeholder={`Search ${type}`}
                // onChange={handleChange}
                id="ElementSearch"
              />{" "}
            </>
          }
        />
        <InfiniteWrapper
          stop={isSearched}
          max={max}
          type={type}
          target={`ElementListInfinite`}
        >
          {(isSearched ? searchArr : list).map((e) => (
            <img
              className="ElementListInfinite_img"
              src={e.src}
              alt={e.name}
              key={e.name}
              onClick={handleAdd(e)}
            />
          ))}
        </InfiniteWrapper>
      </div>
    </SlideContainer>
  );
};

const DefaultPage = ({ icons, shapes, elements, emoji }) => {
  useEffect(() => {
    if (!document._.elementFirstTimeFetched["common"]) {
      // fetchAndSave({ type });
      fetcher({
        url: `${url}/api/assets/common/`,
        res: (res) => {
          Object.keys(res.data).forEach(e => {
            let ele = res.data[e];
            store.dispatch({
              type: "MEDIA_WITH_NEXT", data: {
                next: ele.next,
                list: ele.results,
                type: e,
                res: true
              }
            })
          })
        }
      })
      document._.elementFirstTimeFetched["common"] = true;
    }
  }, []);

  return (
    <div className="Elements">
      <ElementSlider
        title="Shapes and Lines"
        to={`elements/shapes`}
        max={50}
        show={5}
        scroll={2}
        list={shapes}
        type="shapes"
      />
      <ElementSlider
        to={`elements/icons`}
        title="Icons"
        show={5}
        scroll={2}
        max={50}
        list={icons}
        type="icons"

      />
      <ElementSlider
        title="Illustrations"
        to={`elements/illustrations`}
        show={5}
        scroll={2}
        max={100}
        list={elements}
        type="elements"
      />
      <ElementSlider
        title="Emoji"
        to={`elements/emoji`}
        show={5}
        scroll={2}
        max={100}
        list={emoji}
        type="emoji"
      />
    </div>
  );
};

const ElementSlider = ({ list, show, to, title, scroll, max, type }) => {
  // useEffect(() => {
  //   if (!document._.elementFirstTimeFetched[type]) {
  //     fetchAndSave({ type });
  //     document._.elementFirstTimeFetched[type] = true;
  //   }
  // }, []);
  const hist = useHistory();
  const settings = {
    // dots: true,
    // infinite: false,
    slidesToShow: show,
    slidesToScroll: scroll,
    draggable: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
  };
  function ArrowRight({ onClick }) {
    return (
      <img
        className="SlickArrow"
        onClick={onClick}
        src={`${document.PUBLIC_URL}/icons/sliderRight.svg`}
        alt={"arrowRight"}
      />
    );
  }
  function ArrowLeft({ onClick }) {
    return (
      <img
        className="SlickArrow"
        onClick={onClick}
        src={`${document.PUBLIC_URL}/icons/sliderLeft.svg`}
        alt={"arrowLeft"}
      />
    );
  }

  const handleAdd = (svg) => {
    return async () => {

      const cxx = document.cxx[Index()];
      store.dispatch({ type: "LOADING", data: "Element is Loading ..." });
      let _newObj = await loadElement(svg.src);
      store.dispatch({ type: "LOADING", data: "Element is Loading ..." });
      if (!_newObj) {
        return null;
      }

      // set default data
      _newObj.set({ _Type: "elements", strokeWidth: 0, fill: "#000000" });
      cxx.add(_newObj);
      if (type === "shapes") {
        setShapeSVGObjColor(_newObj, _newObj._CurrentColor[0]);
        _newObj.stroke = _newObj._CurrentColor[0];
      }

      //new element added
      fitCenterToCXX({ obj: _newObj, posx: 0.2 });

      InitAddObjectEvents({
        obj: _newObj,
        name: document.duplicateName.getName({ type: "elements", name: svg.name }),
        type: "elements",
        src: svg.src,
        data: { type },
        returnToUrl: false,
        onAddActive: false,
      });

      // cxx.setActiveObject(_newObj);
      cxx.renderAll();
    };
  };

  return (
    <div className="ElementSlider">
      <TitleWrapper
        ele={
          <Link className="ElementSlider_link" to={to}>
            See All
          </Link>
        }
        title={title}
      >
        <Slider {...settings}>
          {list.map((e) => {
            return (
              <img
                onClick={handleAdd(e)}
                className="ElementSlider_img"
                src={e.src}
                key={e.name}
                alt={e.name}
              />
            );
          })}
        </Slider>
      </TitleWrapper>
    </div>
  );
};
