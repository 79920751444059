import React from "react";
import { useEffect, useState } from "react";
import uuid from "uuid-random";
import ProgressBar from "react-customizable-progressbar";
const FeedBack = {
  list: [{ id: 2323, name: "2323", size: 2 >> 3 }],
  setProgress: {},
  setHide: () => { },
  init: function ({ setList, setHide }) {
    this.setList = setList;
    this.setHide = setHide;
  },
  pushList: function ({ id, item }) {
    this.list.push({ id, ...item });
    this.setList((k) => [{ id, ...item }, ...k]);
    this.setHide(false);
  },
  addProgress: function ({ id, setProgress }) {
    this.setProgress[id] = setProgress;
  },
  updatePrgress: function ({ id, progress }) {
    this.setProgress[id](progress);
  },
};

document.FeedBack = FeedBack;
function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}
export default function UploadFeedBack() {
  const [list, setList] = useState([]);
  const [hide, setHide] = useState(true);
  useEffect(() => {
    const { FeedBack } = document;
    FeedBack.init({ setList, setHide });
  }, []);
  const handleClose = () => {
    setHide(true);
    setList([]);
  };
  return (
    document.FeedBack.list.length > 0 && (
      <div
        className="UploadFeedBack"
        style={{ display: hide ? "none" : "block" }}
      >
        <div className="UploadFeedBack_header">
          <div>
            Uploading {list.length} {list.length == 1 ? "item" : "items"}
          </div>
          <img
            className="UploadFeedBack_header_img"
            src={`${document.PUBLIC_URL}/app/closePopup.svg`}
            alt=""
            onClick={handleClose}
          />
        </div>
        <div className="flex column UploadFeedBack_list">
          {list.map((e) => (
            <UploadFeedBackItem key={e.id} {...e} />
          ))}
        </div>
        {list.length - 4 > 0 && (
          <div className="UploadFeedBack_total_info">
            + {list.length - 4} Files Uploading…
          </div>
        )}
      </div>
    )
  );
}

const UploadFeedBackItem = ({ id, name, size }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const { FeedBack } = document;
    FeedBack.addProgress({ setProgress, id });
  }, []);
  return (
    <div className="flex row  UploadFeedBack_list_item">
      <div className="UploadFeedBack_list_item_name">{name}</div>
      <div className="UploadFeedBack_list_item_size">{bytesToSize(size)}</div>
      <div className="UploadFeedBack_list_item_progress">
        {progress !== 100 ? (
          <ProgressBar
            strokeColor="#e7416a"
            progress={progress}
            radius={10}
            strokeWidth={4}
            trackStrokeWidth={0}
          />
        ) : (
          <img src={`${document.PUBLIC_URL}/ico4/progressOK.svg`} />
        )}
      </div>
    </div>
  );
};
