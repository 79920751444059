import { fabric } from "fabric";
import { SIZES } from "./config";
import { getObjectCoords } from "./cropyThings";

export function pointAfterRotation(rPoint, centerPoint, angle) {
  angle = (-1 * angle * Math.PI) / 180;
  let Cx =
    centerPoint.x +
    (rPoint.x - centerPoint.x) * Math.cos(angle) -
    (rPoint.y - centerPoint.y) * Math.sin(angle);
  let Cy =
    centerPoint.y +
    (rPoint.x - centerPoint.x) * Math.sin(angle) +
    (rPoint.y - centerPoint.y) * Math.cos(angle);
  return { x: Cx, y: Cy };
}

function addAbsoluteControls(obj) {
  obj._setCornerCoords = function () {
    var coords = this.oCoords;
    for (var control in coords) {
      let _angle = this.angle;
      let _pos = { x: coords[control].x, y: coords[control].y }
      if (this.controls[control]._noROffset) {
        _angle = 0;
        _pos = this.controls[control]._absPos;
      }
      var controlObject = this.controls[control];
      coords[control].corner = controlObject.calcCornerCoords(
        _angle, this.cornerSize, _pos.x, _pos.y, false);
      coords[control].touchCorner = controlObject.calcCornerCoords(
        _angle, this.touchCornerSize, _pos.x, _pos.y, true);
    }
  }
}

export function addObjectOutsideEvent(obj) {
  obj.on("moving", () => {
    isObjectOutside(obj);
  });
  obj.on("scaling", () => {
    isObjectOutside(obj);
  });
  obj.on("rotating", () => {
    isObjectOutside(obj);
  });
  obj.on("resizing", () => {
    isObjectOutside(obj);
  });
  addAbsoluteControls(obj);
  updateCacheControlsOffset(obj, "cropControl");
  updateCacheControlsOffset(obj, "cancelControl");
  if (obj.controls.resetControl) {
    updateCacheControlsOffset(obj, "resetControl");
  }
  isObjectOutside(obj);
  obj.canvas?.renderAll();
}


export function addObjectOutsideEventC(obj) {
  obj.on("moving", () => {
    isObjectOutsideC(obj);
  });
  obj.on("scaling", () => {
    isObjectOutsideC(obj);
  });
  obj.on("rotating", () => {
    isObjectOutsideC(obj);
  });
  obj.on("resizing", () => {
    isObjectOutsideC(obj);
  });
  isObjectOutsideC(obj);
}


const CONTROLS_MAP = {
  "0.5_0.5": "br",
  "-0.5_0.5": "bl",
  "0.5_-0.5": "tr",
  "-0.5_-0.5": "tl",
  "0_-0.5": "mt",
  "0_0.5": "mb",
  "-0.5_0": "ml",
  "0.5_0": "mr",
  "0_0": "cc",
};
function updateControls(obj, postions) {
  let controls = obj.controls.cancelControl;
  let _command = CONTROLS_MAP[`${controls.x}_${controls.y}`];
  if (!_command) {
    _command = "cc";
  }
  // if(postions!=='cc' && _command==='cc'){
  //     if(obj._controlsDefaultOffset){
  //         obj.controls.cancelControl.offsetX = obj._controlsDefaultOffset.cancel.offsetX;
  //         obj.controls.cancelControl.offsetY = obj._controlsDefaultOffset.cancel.offsetY;
  //         obj.controls.cropControl.offsetX = obj._controlsDefaultOffset.crop.offsetX;
  //         obj.controls.cropControl.offsetX = obj._controlsDefaultOffset.crop.offsetX;
  //     }
  // }
  if (_command !== postions || _command === "cc") {
    if (postions === "bl") {
      obj.controls.cropControl.x = obj.controls.cancelControl.x = -0.5;
      obj.controls.cropControl.y = obj.controls.cancelControl.y = 0.5;
      obj.controls.cancelControl.offsetX =
        -1 * Math.abs(obj.controls.cancelControl.offsetX);
      obj.controls.cancelControl.offsetY =
        -1 * Math.abs(obj.controls.cancelControl.offsetY);

      obj.controls.cropControl.offsetX =
        -1 * Math.abs(obj.controls.cropControl.offsetX);
      obj.controls.cropControl.offsetY =
        -1 * Math.abs(obj.controls.cropControl.offsetY);

      if (obj.controls.resetControl) {
        obj.controls.resetControl.x = obj.controls.cancelControl.x;
        obj.controls.resetControl.y = obj.controls.cancelControl.y;
        obj.controls.resetControl.offsetX =
          -1 * Math.abs(obj.controls.resetControl.offsetX);
        obj.controls.resetControl.offsetY =
          -1 * Math.abs(obj.controls.resetControl.offsetY);
      }
      return;
    } else if (postions === "tr") {
      obj.controls.cropControl.x = obj.controls.cancelControl.x = 0.5;
      obj.controls.cropControl.y = obj.controls.cancelControl.y = -0.5;
      obj.controls.cancelControl.offsetX = Math.abs(
        obj.controls.cancelControl.offsetX
      );
      obj.controls.cancelControl.offsetY = Math.abs(
        obj.controls.cancelControl.offsetY
      );

      obj.controls.cropControl.offsetX = Math.abs(
        obj.controls.cropControl.offsetX
      );
      obj.controls.cropControl.offsetY = Math.abs(
        obj.controls.cropControl.offsetY
      );

      if (obj.controls.resetControl) {
        obj.controls.resetControl.x = obj.controls.cancelControl.x;
        obj.controls.resetControl.y = obj.controls.cancelControl.y;
        obj.controls.resetControl.offsetX = Math.abs(obj.controls.resetControl.offsetX);
        obj.controls.resetControl.offsetY = Math.abs(obj.controls.resetControl.offsetY);
      }
      return;
    } else if (postions === "tl") {
      obj.controls.cropControl.x = obj.controls.cancelControl.x = -0.5;
      obj.controls.cropControl.y = obj.controls.cancelControl.y = -0.5;
      obj.controls.cancelControl.offsetX =
        -1 * Math.abs(obj.controls.cancelControl.offsetX);
      obj.controls.cancelControl.offsetY =
        -1 * Math.abs(obj.controls.cancelControl.offsetY);

      obj.controls.cropControl.offsetX =
        -1 * Math.abs(obj.controls.cropControl.offsetX);
      obj.controls.cropControl.offsetY =
        -1 * Math.abs(obj.controls.cropControl.offsetY);

      if (obj.controls.resetControl) {
        obj.controls.resetControl.x = obj.controls.cancelControl.x;
        obj.controls.resetControl.y = obj.controls.cancelControl.y;
        obj.controls.resetControl.offsetX =
          -1 * Math.abs(obj.controls.resetControl.offsetX);
        obj.controls.resetControl.offsetY =
          -1 * Math.abs(obj.controls.resetControl.offsetY);
      }
      return;
    } else if (postions === "br") {
      obj.controls.cropControl.x = obj.controls.cancelControl.x = 0.5;
      obj.controls.cropControl.y = obj.controls.cancelControl.y = 0.5;
      obj.controls.cancelControl.offsetX = Math.abs(
        obj.controls.cancelControl.offsetX
      );
      obj.controls.cancelControl.offsetY = Math.abs(
        obj.controls.cancelControl.offsetY
      );

      obj.controls.cropControl.offsetX = Math.abs(
        obj.controls.cropControl.offsetX
      );
      obj.controls.cropControl.offsetY = Math.abs(
        obj.controls.cropControl.offsetY
      );
      if (obj.controls.resetControl) {
        obj.controls.resetControl.x = obj.controls.cancelControl.x;
        obj.controls.resetControl.y = obj.controls.cancelControl.y;
        obj.controls.resetControl.offsetX = Math.abs(obj.controls.resetControl.offsetX);
        obj.controls.resetControl.offsetY = Math.abs(obj.controls.resetControl.offsetY);
      }
      return;
    } else if (postions === "cc") {
      let _canvasCenter = { x: 1920 / 2, y: 1080 / 2 };
      let _objCenter = obj.getCenterPoint();
      if (!obj._coordData) {
        obj._coordData = {
          width: obj.width * obj.scaleX,
          height: obj.height * obj.scaleY,
        };
      }
      let _dX = (_canvasCenter.x - _objCenter.x) / obj._coordData.width,
        _dY = (_canvasCenter.y - _objCenter.y) / obj._coordData.height;

      obj.controls.cropControl.x = obj.controls.cancelControl.x = _dX;
      obj.controls.cropControl.y = obj.controls.cancelControl.y = _dY;

      if (obj.controls.resetControl) {
        obj.controls.resetControl.x = obj.controls.cancelControl.x;
        obj.controls.resetControl.y = obj.controls.cancelControl.y;
      }
    }
  }

  return;
}

function updateCacheControlsOffset(obj, _label) {
  if (obj._chacheControlsOffset === undefined) {
    obj._chacheControlsOffset = {};
  }
  if (!obj._chacheControlsOffset[_label]) {
    obj._chacheControlsOffset[_label] = { offsetX: Math.abs(obj.controls[_label].offsetX), offsetY: Math.abs(obj.controls[_label].offsetY) };
  }
}


function updateLabelControls(obj, postions, controlCoords = { x: 0, y: 0 }, _label = "labelControl") {
  let controls = obj.controls[_label];
  // add offset to cache
  updateCacheControlsOffset(obj, _label);

  let { offsetX: _offsetX, offsetY: _offsetY } = obj._chacheControlsOffset[_label];
  let _command = CONTROLS_MAP[`${controls.x}_${controls.y}`];

  if (postions === 'cc') {
    controls._noROffset = true;
    controls._absPos = { ...controlCoords };
    return;
  } else {
    controls._noROffset = false;
  }

  const _controlsData = {
    "bl": { x: -0.5, y: 0.5, oX: 1, oY: 1 },
    "tr": { x: 0.5, y: -0.5, oX: -1, oY: -1 },
    "tl": { x: -0.5, y: -0.5, oX: 1, oY: -1 },
    "br": { x: 0.5, y: 0.5, oX: -1, oY: 1 },
    "mt": { x: 0, y: -0.5, oX: 0, oY: -1 },
    "mb": { x: 0, y: 0.5, oX: 0, oY: 1 },
    "ml": { x: -0.5, y: 0, oX: 0, oY: 1 },
    "mr": { x: 0.5, y: 0, oX: 0, oY: 1 },
  }
  if (_command !== postions) {
    if (_controlsData[postions]) {
      obj.controls[_label].x = _controlsData[postions].x;
      obj.controls[_label].y = _controlsData[postions].y;
      obj.controls[_label].offsetX = _controlsData[postions].oX * _offsetX;
      obj.controls[_label].offsetY = _controlsData[postions].oY * _offsetY;
    }
  }

  return;
}

function isOutsideCanvas(cCoords) {
  if (
    cCoords.x < 0 &&
    cCoords.y < 0 &&
    cCoords.xw > 1920 &&
    cCoords.yh > 1080
  ) {
    return true;
  } else if (cCoords.xw < 0 || cCoords.yh < 0) {
    return true;
  } else if (cCoords.x > 1920 || cCoords.y > 1080) {
    return true;
  }
  return false;
}

function isInsideBoundary(posS, _objCoords, canvasHeight, canvasWidth, marginDist = 15) {
  let pos = { x: 0, y: 0 };
  if (posS === 'mt') {
    pos.x = (_objCoords['tl'].x + _objCoords['tr'].x) / 2
    pos.y = (_objCoords['tl'].y + _objCoords['tr'].y) / 2
  } else if (posS === 'mb') {
    pos.x = (_objCoords['bl'].x + _objCoords['br'].x) / 2
    pos.y = (_objCoords['bl'].y + _objCoords['br'].y) / 2
  } else if (posS === 'ml') {
    pos.x = (_objCoords['tl'].x + _objCoords['bl'].x) / 2
    pos.y = (_objCoords['tl'].y + _objCoords['bl'].y) / 2
  } else if (posS === 'mr') {
    pos.x = (_objCoords['tr'].x + _objCoords['br'].x) / 2
    pos.y = (_objCoords['tr'].y + _objCoords['br'].y) / 2
  } else {
    pos = _objCoords[posS];
  }
  if (pos.x >= marginDist && pos.y >= marginDist && pos.x < canvasWidth - marginDist && pos.y < canvasHeight - marginDist) {
    return true
  }
  return false
}


function convertGetCoordToArr(_gtC) {
  let _farr = [[_gtC['tl'].x, _gtC['tl'].y], [_gtC['tr'].x, _gtC['tr'].y], [_gtC['br'].x, _gtC['br'].y], [_gtC['bl'].x, _gtC['bl'].y]];
  let _yArr = _farr.map((e) => e[1]);
  const _yIndexMin = _yArr.indexOf(Math.min.apply(null, _yArr));
  return [..._farr.slice(_yIndexMin, 4), ..._farr.slice(0, _yIndexMin)]
}

function getLineData(l1, l2, isBottom = false, canvasHeight = 0) {
  if (l2[1] - l1[1] === 0) {
    return { x: l1[0] + l2[0], y: 0 }
  } else if (l2[0] - l1[0] === 0) {
    return { x: l1[0], y: 0 }
  }
  const m = ((l2[1] - l1[1]) / (l2[0] - l1[0]));
  const c = (l2[1] - (m * l2[0]))
  if (isBottom) {
    return { x: ((canvasHeight - c) / m), y: canvasHeight }
  }
  return { y: c, x: (-c / m) }
}


function getControlOffset(_objCoords, { canvasHeight, canvasWidth }, _labelSize, marginDist = 15) {
  const { x: labelWidth, y: labelHeight } = _labelSize;


  const _objCoordsArr = convertGetCoordToArr(_objCoords);
  const _rightLineData = getLineData(_objCoordsArr[0], _objCoordsArr[1]);
  const _leftLineData = getLineData(_objCoordsArr[0], _objCoordsArr[3]);

  // top line
  if (_objCoordsArr[0][1] <= marginDist && _leftLineData.x + labelWidth <= canvasWidth && _rightLineData.x >= 0) {
    if (_leftLineData.x > 0) {
      return { x: _leftLineData.x, y: 0 }
    }
    return { x: 0, y: 0 }
  } else if (_rightLineData.y > 0 && _rightLineData.x <= 0 && _rightLineData.y + labelHeight < canvasHeight) {
    // left line
    return { x: 0, y: _rightLineData.y }
  }
  //handle bottom line
  const _bottomlLineData = getLineData(_objCoordsArr[0], _objCoordsArr[3], true, canvasHeight);
  //const _bottomrLineData = getLineData(_objCoordsArr[0], _objCoordsArr[3], true, canvasHeight);
  if (_bottomlLineData.x + labelWidth < canvasWidth) {
    if (_bottomlLineData.x <= 0) {
      return { x: 0, y: canvasHeight - labelHeight }
    }
    return { x: _bottomlLineData.x, y: canvasHeight - labelHeight }
  }
  return { x: 0, y: 0 }
}


export function isObjectOutsideC(obj) {
  if (obj.canvas) {

    let { height: canvasHeight, width: canvasWidth } = obj.canvas;
    let _objCoords = obj._getCoords();
    // top left
    if (isInsideBoundary("tl", _objCoords, canvasHeight, canvasWidth)) {
      updateLabelControls(obj, "tl");
    } else if (isInsideBoundary("tr", _objCoords, canvasHeight, canvasWidth)) {
      updateLabelControls(obj, "tr");
    } else if (isInsideBoundary("br", _objCoords, canvasHeight, canvasWidth)) {
      updateLabelControls(obj, "br");
    } else if (isInsideBoundary("bl", _objCoords, canvasHeight, canvasWidth)) {
      updateLabelControls(obj, "bl");
      // } else if (isInsideBoundary("mt", _objCoords, canvasHeight, canvasWidth)) {
      //   updateLabelControls(obj, "mt");
      // } else if (isInsideBoundary("mb", _objCoords, canvasHeight, canvasWidth)) {
      //   updateLabelControls(obj, "mb");
      // } else if (isInsideBoundary("ml", _objCoords, canvasHeight, canvasWidth)) {
      //   updateLabelControls(obj, "ml");
      // } else if (isInsideBoundary("mr", _objCoords, canvasHeight, canvasWidth)) {
      //   updateLabelControls(obj, "mr");
    } else {
      updateLabelControls(obj, "cc", getControlOffset(_objCoords, { canvasHeight, canvasWidth }, obj.controls.labelControl._size));
    }
  }
}


export function isObjectOutside(obj) {
  let _angle = fabric.util.degreesToRadians(obj.angle);
  let _cp = obj.refImage.getCenterPoint();
  let _objHeight = obj.height * obj.scaleY, _objWidth = obj.width * obj.scaleX;
  obj._coordData = { left: obj.left, top: obj.top, height: _objHeight, width: _objWidth, angle: _angle };
  let cCoords = customGetCoords(obj._coordData);
  let _mainImgCoord = getObjectCoords(obj.refImage);
  let _oLeft = _cp.x - _mainImgCoord.width / 2 - _objWidth, _oTop = _cp.y - _mainImgCoord.height / 2 - _objHeight;
  let _mP = pointAfterRotation(new fabric.Point(_oLeft, _oTop), obj.refImage.getCenterPoint(), -1 * obj.angle);
  let _pcoords = customGetCoords({ left: _mP.x, top: _mP.y, height: _mainImgCoord.height + 2 * _objHeight, width: _mainImgCoord.width + 2 * _objWidth, angle: _angle });

  // is mask inside boundary
  let inBounds = true;
  cCoords.forEach((c) => { if (!inside(c, _pcoords)) inBounds = false; });
  _pcoords.forEach((c) => { if (inside(c, cCoords)) inBounds = false; });
  if (inBounds) {
    obj._prevCoord = { left: obj.left, top: obj.top, scaleX: obj.scaleX, scaleY: obj.scaleY, height: obj.height, width: obj.width, angle: obj.angle };
  } else {
    obj.set(obj._prevCoord);
  }

  if (obj.canvas) {

    let { height: canvasHeight, width: canvasWidth } = obj.canvas;
    let _objCoords = obj._getCoords();

    const _isResetControls = obj.controls.resetControl;
    const _halfW = obj.controls.cropControl.cornerSize / 2;
    const _marginDist = 35;

    // top left
    let _crntControlsPos = "tr";
    if (isInsideBoundary("tr", _objCoords, canvasHeight, canvasWidth, _marginDist)) {
      _crntControlsPos = "tr";
    } else if (isInsideBoundary("tl", _objCoords, canvasHeight, canvasWidth, _marginDist)) {
      _crntControlsPos = "tl";
    } else if (isInsideBoundary("br", _objCoords, canvasHeight, canvasWidth, _marginDist)) {
      _crntControlsPos = "br";
    } else if (isInsideBoundary("bl", _objCoords, canvasHeight, canvasWidth, _marginDist)) {
      _crntControlsPos = "bl";
    } else {
      let _absPos;
      if (_isResetControls) {
        _absPos = getControlOffset(_objCoords, { canvasHeight, canvasWidth }, { x: obj.controls.cropControl.cornerSize, y: obj.controls.cropControl.cornerSize });
        updateLabelControls(obj, "cc", { x: _absPos.x + obj._chacheControlsOffset["resetControl"]["offsetX"], y: _absPos.y + _halfW }, 'resetControl');
      } else {
        _absPos = getControlOffset(_objCoords, { canvasHeight, canvasWidth }, { x: obj.controls.cropControl.cornerSize, y: obj.controls.cropControl.cornerSize });
      }

      updateLabelControls(obj, "cc", { x: _absPos.x + obj._chacheControlsOffset["cropControl"]["offsetX"], y: _absPos.y + _halfW }, 'cropControl');
      updateLabelControls(obj, "cc", { x: _absPos.x + obj._chacheControlsOffset["cancelControl"]["offsetX"], y: _absPos.y + _halfW }, 'cancelControl');
      return;
    }
    updateLabelControls(obj, _crntControlsPos, { x: 0, y: 0 }, 'cropControl');
    updateLabelControls(obj, _crntControlsPos, { x: 0, y: 0 }, 'cancelControl');
    _isResetControls && updateLabelControls(obj, _crntControlsPos, { x: 0, y: 0 }, 'resetControl');
  }



}

export function removeObjectOutsideEvent(obj) {
  obj.off("moving");
  obj.off("scaling");
  obj.off("rotating");
  obj.off("resizing");
}

export function customGetCoords(
  _coords = { left: 0, top: 0, height: 0, width: 0, angle: 0 }
) {
  var x = _coords.left;
  var y = _coords.top;
  var angle = _coords.angle; //(_coords.angle * Math.PI) / 180;

  var coords = [{ x, y }];
  x += _coords.width * Math.cos(angle);
  y += _coords.width * Math.sin(angle);
  coords.push({ x, y });

  angle += Math.PI / 2;
  x += _coords.height * Math.cos(angle);
  y += _coords.height * Math.sin(angle);
  coords.push({ x, y });

  angle += Math.PI / 2;
  x += _coords.width * Math.cos(angle);
  y += _coords.width * Math.sin(angle);
  coords.push({ x, y });
  return coords;
}

function inside(p, vs) {
  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i].x,
      yi = vs[i].y;
    var xj = vs[j].x,
      yj = vs[j].y;
    var intersect =
      yi > p.y !== yj > p.y && p.x < ((xj - xi) * (p.y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

export default {};

