import React, { useCallback, useState } from "react";
import TitleRow from "../Atom/TitleRow";
import "./_One.scss";
import { useHistory } from "react-router";
import Vr from "../Atom/Vr";
import Toggle from "../Atom/Toggle";
import DisabledWrapper from "../Atom/DisabledWrapper";
// import Color from "color";
import {
  findNewPoint,
  getAngleFromXY,
  getById
} from "../../../utils";
import TitleWrapper from "../Atom/TitleWrapper";
import { InputRangeWithTitle } from "../../LeftPanel/components/Settings/Components/Background/BackgroundSettings";
import SlideContainer from "../Atom/SlideContainer";
import { fabric } from "fabric";
import NewColorWrapper from "./NewColorWrapper";
import { useEffect } from "react";
// import store from "../../../App/ImagePersonalised/store";
import store from "../../../store/store";
// import store from "../../../App/ImagePersonalised/store";

export default function Shadow({ id }) {
  const history = useHistory();

  useEffect(() => {
    store.dispatch({ type: "SET_FRAME", data: true });
  }, []);
  const handlerBack = useCallback(() => {
    store.dispatch({ type: "SET_FRAME", data: false });

    history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set initial data
  let shadowDistance = 0;
  const obj = getById(id);
  let _op = 70,
    _clr = "#000000",
    _ang = 0;
  if (obj?.shadow) {
    try {
      let _colorObj = (new fabric.Color(obj.shadow.color));
      _clr = "#" + _colorObj.toHex();
      _op = _colorObj.getAlpha() ? parseInt(_colorObj.getAlpha() * 100) : 100;
    } catch (err) { }

    if (obj.shadow.offsetX && obj.shadow.offsetY) {
      _ang = parseInt(getAngleFromXY(obj.shadow.offsetX, obj.shadow.offsetY));
      shadowDistance = parseInt(Math.sqrt((obj.shadow.offsetX * obj.shadow.offsetX) + (obj.shadow.offsetY * obj.shadow.offsetY)))
    }
  }

  const [isShadow, setShadow] = useState(!!obj?.shadow);
  const [shadowData, setShadowData] = useState({
    angle: _ang,
    blur: parseInt(obj?.shadow?.blur || 40),
    color: _clr,
    opacity: _op,
    distance: shadowDistance
  });

  const handlerAll = (type, e, shadowData) => {
    if (obj && shadowData) {
      // obj: fabric object
      // e: input range value, #ffffff for color
      // use `hexToRgb` funciton for convert hex to rgba(...,...,...) , or rgba to rgba

      if (!obj.shadow && type !== "remove") {
        let shadowD = findNewPoint(shadowData.angle, shadowData.distance);
        const c = (new fabric.Color(shadowData.color))._source;
        obj.set({
          shadow: new fabric.Shadow({
            blur: shadowData.blur,
            color: `rgba(${c[0]},${c[1]},${c[2]}, ${shadowData.opacity / 100})`,
            offsetX: shadowD.x,
            offsetY: shadowD.y,
            opacity: shadowData.opacity
          }),
        });
      }
      switch (type) {
        case "color":
          const c = (new fabric.Color(e))._source;
          let col = `rgba(${c[0]},${c[1]},${c[2]}, ${shadowData.opacity / 100})`;
          obj.set({
            shadow: { ...obj.shadow, color: col },
          });

          break;
        case "opacity":
          const _c = (new fabric.Color(obj.shadow.color))._source;
          let _col = `rgba(${_c[0]},${_c[1]},${_c[2]}, ${e / 100})`;
          obj.set({
            shadow: { ...obj.shadow, color: _col },
          });
          break;

        case "blur":
          obj.set({
            shadow: { ...obj.shadow, blur: e },
          });
          break;

        case "angle":
          let _sd = findNewPoint(e, shadowData.distance);
          obj.set({
            shadow: { ...obj.shadow, offsetX: _sd.x, offsetY: _sd.y },
          });
          break;

        case "remove":
          obj.set({ shadow: null });
          break;

        case "distance":
          let _sdd = findNewPoint(shadowData.angle, e);
          obj.set({
            shadow: { ...obj.shadow, offsetX: _sdd.x, offsetY: _sdd.y },
          });
          break;

        default:
          break;
      }

      // obj.canvas.fire("object:modified");

      obj.canvas.renderAll();
    }

  };

  const handleSave = () => {
    getById(id).canvas.fire("object:modified");
  };

  const handleColorApply = (e) => {
    setShadowData((_shadowData) => {
      let p = { ..._shadowData, color: e };
      handlerAll("color", e, p);
      return p;
    });
  };

  return (
    <SlideContainer>
      <div className="Shadow">
        <TitleRow
          cursor="pointer"
          onClick={handlerBack}
          title="< Apply Shadow"
        ></TitleRow>
        <Vr m="15px" />

        <div className="Shadow_flex">
          Shadow{" "}
          <Toggle
            toggle={isShadow}
            onToggle={() => {
              if (isShadow) {
                setShadowData((_shadowData) => {
                  let p = { ..._shadowData };
                  handlerAll("remove", null, p);
                  return p;
                });
              } else {
                setShadowData((_shadowData) => {
                  let p = { ..._shadowData };
                  handlerAll("add", null, p);
                  return p;
                });
              }
              setShadow(!isShadow);
            }}
          />
        </div>
        <DisabledWrapper isDisable={!isShadow}>
          <NewColorWrapper
            value={shadowData.color}
            onLastChange={handleSave}
            onChange={handleColorApply}
            onSelect={handleColorApply}
          />
          {/* <Color
            color={shadowData.color}
            onLastChange={(e) => {
              setShadowData({ ...shadowData, color: e });
              handlerAll("color")(e);
            }}
          /> */}
          <TitleWrapper title="Opacity">
            <InputRangeWithTitle
              className="Opacity"
              value={shadowData.opacity}
              onChange={(e) => {
                setShadowData((_shadowData) => {
                  let p = { ..._shadowData, opacity: e };
                  handlerAll("opacity", e, p);
                  return p;
                });
              }}
              onFullChange={handleSave}

            // onFullChange={}
            />
          </TitleWrapper>

          <TitleWrapper title="Blur">
            <InputRangeWithTitle
              className="Opacity"
              value={shadowData.blur}
              onChange={(e) => {
                setShadowData((_shadowData) => {
                  let p = { ..._shadowData, blur: e };
                  handlerAll("blur", e, p);
                  return p;
                });
              }}
              onFullChange={handleSave}
            />
          </TitleWrapper>
          <TitleWrapper title="Angle">
            <InputRangeWithTitle
              className="Opacity"
              value={shadowData.angle}
              max={359}
              min={0}
              step={1}
              postfix={"deg"}
              onChange={(e) => {
                setShadowData((_shadowData) => {
                  let p = { ..._shadowData, angle: e };
                  handlerAll("angle", e, p);
                  return p;
                });
              }}
              onFullChange={handleSave}
            />
          </TitleWrapper>

          <TitleWrapper title="Distance">
            <InputRangeWithTitle
              className="Opacity"
              value={shadowData.distance}
              max={359}
              min={0}
              step={1}
              postfix={"px"}
              onChange={(e) => {

                setShadowData((_shadowData) => {
                  let p = { ..._shadowData, distance: e };
                  handlerAll("distance", e, p);
                  return p;
                });

              }}
              onFullChange={handleSave}
            />
          </TitleWrapper>
        </DisabledWrapper>
      </div>
    </SlideContainer>
  );
}
