import React, { useEffect, useState } from "react";
import "./_Menu.scss"

export const Menu = ({ id, children, onShow = () => { } }) => {
    const [show, setShow] = useState(false);
    const [pos, setPos] = useState({ x: 0, y: 0 })
    const [pos2, setPos2] = useState({ x: 0, y: 0 })
    useEffect(() => {
        let ele = document.getElementById(id);
        const handleR = (e) => {
            // setShow(false)

            onShow(false, pos2)

            e.preventDefault();
            console.log(e);
            setPos({ x: e.clientX, y: e.clientY })
            setPos2(k => {
                onShow(true, { x: e.layerX, y: e.layerY })

                return ({ x: e.layerX, y: e.layerY })
            })
            console.log(e);
            // console.log(e);

            setShow(true)
            document.dontMoveHover = true

        }
        const handleL = (e) => {
            // e.preventDefault();
            // console.log(show);
            // if (show) {
            // setShow(false)
            setShow(s => {
                if (s) {
                    document.dontMoveHover = false;
                    setTimeout(() => {
                        onShow(false, pos2)

                    }, 0)
                    return false
                }
                return s
            })


        }
        if (ele) {
            ele.addEventListener("contextmenu", handleR)
            document.addEventListener("click", handleL)
            return () => {
                ele.removeEventListener("contextmenu", handleR)
                document.removeEventListener("click", handleL)
            }
        }

    }, [pos2])

    return show && <div className="Menu" style={{ top: pos.y, left: pos.x }}>
        <>
            {children}
        </>
    </div>
}