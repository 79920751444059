import React, { useEffect, useState } from "react";
import Popup from "../Popup";
import Model from "reactjs-popup";
import "./_KK.scss";
import { fetcher, url } from "../../../utils";
import copy from "copy-to-clipboard";
import { ReactComponent as Tick } from "../../../App/extras/analytics/tickAnalyticSm.svg";

import InfiniteScroll from "react-infinite-scroll-component";
import ButtonIcon from "../../../components/Chemistry/Atom/ButtonIcon";
import mystore from "../../appStore";


// import { useState } from "react/cjs/react.development";

function InfiniteWrapper({
  type = "",
  children,
  target,
  max,
  onFetchNext = () => { },
  url = null,
  className = ""
}) {



  const handleFetch = () => {
    // alert("calling");


    fetcher({
      url: url,
      res: (e) => {
        const { results, next } = e.data;
        onFetchNext(results)
      },
    });

  };
  useEffect(() => {
    if (children.length <= max) {
      //console.log(children.length);
      handleFetch();
      ////console.log("featch");
    }
  });

  return (
    <InfiniteScroll
      scrollableTarget={target}
      dataLength={children.length}
      next={handleFetch}
      className={className}
      hasMore={url ? true : false}
      //   style={{ display: "flex", flexDirection: "column-reverse" }}
      loader={
        <div className="INFINITELOAD">
          <img alt="Loading" src={`${document.PUBLIC_URL}/icons/loading.gif`} />
        </div>
      }
    >
      {children}
    </InfiniteScroll>
  );
}





export default function TablePopup({ trigger, id, fileName }) {
  const [csv, setCSV] = useState({ headers: [], data: [], next: null, })
  const [load, setLoad] = useState(false);


  const DownloadNow = () => {

    fetcher({
      method: "GET",
      url: `${url}/api/newvideo/video/details/batch/${id}/generated/`,
      res: (res) => {
        // console.log(res);
        const _filename = `${fileName}-${mystore?.getState().mydetail.name}.csv`
        window.open(res.data.result.generatedFile + `?download=1&filename=${_filename}`, "_blank")

      },
      err: (err) => console.log(err),
    });
  }


  const handleOpen = () => {
    document.socket.isUpdate = false;
    setLoad(true);
    fetcher({
      method: "GET",
      url: `${url}/api/newvideo/video/details/batch/${id}/?limit=14`,
      res: (res) => {

        setCSV({ ...res.data.results, next: res.data.next, count: res.data.count });

      },
      err: (err) => console.log(err),
    });
    setLoad(false)

  }
  const handleClose = () => {
    setLoad(false)
    setCSV({ headers: [], data: [], next: null });
    document.socket.isUpdate = true;
    document.socket.restoreHistory();

  }

  return (
    <Model modal trigger={trigger} onOpen={handleOpen} onClose={handleClose} nested>
      {(close) => (
        <Popup close={close} name={fileName} haveButton={false} rightHeader={
          <ButtonIcon onClick={() => { DownloadNow() }} value={<div>Download CSV</div>} src={`${document.PUBLIC_URL}/app/vdownload.svg`} />
        }>

          <div className="TablePopup">
            {load ? <div className="INFINITELOAD">
              <img alt="Loading" src={`${document.PUBLIC_URL}/icons/loading.gif`} />
            </div> : <>
              <div className="TablePopup_column">



                {csv.headers.map((e, i) => e[0] == "status" ? null : (
                  <div className={`TablePopup_column_each ${e[0] === "campaignUrl" ? "campaign_url_csv_row" : ""}`} key={e[0]}>{

                    e[0] === "campaignUrl" ? "Campaign URL" : e[0]

                  }
                    {e[0] === "campaignUrl" ? <span id={`copy-campaignurl-copied-note`}  ></span> : <></>}

                  </div>
                ))}

              </div>
              <div className="TablePopup_data">
                <InfiniteWrapper
                  list={csv.data}
                  url={csv.next}
                  max={csv.count}

                  onFetchNext={(res) => {

                    setCSV({ ...csv, data: [...csv.data, ...res.data.results.data], next: res.data.next, count: res.data.count });

                  }}
                >
                  {csv.data.map((e, i) => {

                    return <TableRow key={i} id={i} column={csv.headers} data={e} csv={csv} />;
                  })}

                </InfiniteWrapper>

              </div>

            </>}

          </div>
        </Popup>
      )
      }
    </Model >
  );
}



export const TableRow = ({ data, column, id, csv }) => {
  const copyMe = (data, id) => {
    navigator.clipboard.writeText(data);
    copy(data)
    let oldText = document.getElementById(`copy-${id}`).innerHTML
    document.getElementById(`copy-${id}`).innerHTML = "   Copied ! ";
    setTimeout(() => {
      document.getElementById(`copy-${id}`).innerHTML = oldText;
    }, 600)
    return data
  }



  return (
    <div className="TableRow">

      {/* <div className="TableRow_index">{id}</div> */}
      {column.map((e, i) => e[0] === "status" ? null : (e[0] === "campaignUrl" ? <div id={`table${i}`} onClick={() => { copyMe(data[i], data[i]) }} className="TableRow_each campaign_url_csv_row pointer" key={e[0]}>
        <label className="campaign_url_csv_inner">{data[i]}</label>  <span id={`copy-${data[i]}`}  ></span>
      </div>
        : <div id={`table${i}`} className="TableRow_each" key={e[0]}>
          {data[i]}  {e[0] == "{{email}}" && csv.data[id][csv.data[id].length - 2] === 1 ? <Tick /> : ""}
        </div>
      )
      )}
    </div>
  );
};


