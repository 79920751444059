import { fabric } from "fabric";

// change rotation icon
fabric.Object.prototype.controls.mtr.cursorStyleHandler = function (
  eventData,
  control /* fabricObject */
) {
  const brushSize = 18;
  const getDrawCursor = () => {
    const circle = `<svg xmlns="http://www.w3.org/2000/svg" width="${brushSize}" height="${brushSize}" viewBox="0 0 ${48} ${48}">
            <defs>
              <style>
                .cls-1 {
                  fill: #fff;
                  stroke: #000;
                  stroke-linecap: round;
                  stroke-width: 2px;
                }
              </style>
            </defs>
            <path id="Union_2" data-name="Union 2" class="cls-1" d="M19.448,41.264a20.66,20.66,0,0,1-8.77-38.717,20.757,20.757,0,0,1,24.59,3.487L38.12,8.72V5.381a3.5,3.5,0,0,1,7,0V16.8a3.52,3.52,0,0,1-.016.365,3.479,3.479,0,0,1-.3,1.116h0l-.017.037,0,0-.016.033,0,.01-.014.027-.006.012-.013.026-.006.012-.013.025-.007.013-.013.025-.008.014-.013.023-.008.014-.014.024-.008.013-.015.025-.006.01-.017.028-.005.008-.021.033h0a3.518,3.518,0,0,1-.673.779l-.014.012-.016.014-.023.019-.006,0a3.476,3.476,0,0,1-.822.505h0l-.037.016a3.479,3.479,0,0,1-1.1.266l-.023,0h-.018q-.122.008-.245.007H30.181a3.5,3.5,0,1,1,0-7H32.8l-2.406-2.265a13.662,13.662,0,1,0,3.142,14.133,3.5,3.5,0,1,1,6.6,2.332A20.768,20.768,0,0,1,20.641,41.3Q20.047,41.3,19.448,41.264Z" transform="translate(1.024 1.015)"/>
          </svg>
          `;
    return `data:image/svg+xml;base64,${window.btoa(circle)}`;
  };
  return `url(${getDrawCursor()}) ${brushSize / 2} ${brushSize / 2}, crosshair`;
};

function isTransformCentered(transform) {
  return transform.originX === "center" && transform.originY === "center";
}

// a function that runs when there is change in the height of the textbox
function changeHeight(eventData, transform, x, y) {
  let target = transform.target,
    localPoint = fabric.controlsUtils.getLocalPoint(
      transform,
      transform.originX,
      transform.originY,
      x,
      y
    ),
    strokePadding =
      target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
    multiplier = isTransformCentered(transform) ? 2 : 1,
    oldHeight = target.height,
    newHeight =
      Math.abs((localPoint.y * multiplier) / target.scaleY) - strokePadding;
  let _newHeight = Math.max(newHeight, 0);
  if (target._Type && target._Type !== "elements") {
    target.manualIncreaseSize = _newHeight;
    let _textActualHeight = target.calcTextHeight();
    _newHeight = Math.max(newHeight, _textActualHeight);
    target.set("height", _newHeight);
  } else {
    target.set("height", _newHeight);
  }
  return oldHeight !== newHeight;
}

function changeWidth(eventData, transform, x, y) {
  let target = transform.target,
    localPoint = fabric.controlsUtils.getLocalPoint(
      transform,
      transform.originX,
      transform.originY,
      x,
      y
    ),
    strokePadding =
      target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
    multiplier = isTransformCentered(transform) ? 2 : 1,
    oldWidth = target.width,
    newWidth =
      Math.abs((localPoint.x * multiplier) / target.scaleX) - strokePadding;
  target.set("width", Math.max(newWidth, 0));
  if (target.manualIncreaseSize) {
    target.set("height", Math.max(target.manualIncreaseSize, target.height));
  }
  return oldWidth !== newWidth;
}

//when canvans get ready
if (fabric.Textbox) {
  fabric.Textbox.prototype.controls.mb = new fabric.Control({
    x: 0,
    y: 0.5,
    actionHandler: fabric.controlsUtils.wrapWithFireEvent(
      "resizing",
      fabric.controlsUtils.wrapWithFixedAnchor(changeHeight)
    ),
    getActionName: fabric.Textbox.prototype.controls.mr.getActionName,
    cursorStyleHandler: fabric.Textbox.prototype.controls.mr.cursorStyleHandler,
  });
  fabric.Textbox.prototype.controls.mt = new fabric.Control({
    x: 0,
    y: -0.5,
    actionHandler: fabric.controlsUtils.wrapWithFireEvent(
      "resizing",
      fabric.controlsUtils.wrapWithFixedAnchor(changeHeight)
    ),
    getActionName: fabric.Textbox.prototype.controls.mr.getActionName,
    cursorStyleHandler: fabric.Textbox.prototype.controls.mr.cursorStyleHandler,
  });
  fabric.Textbox.prototype.controls.mr = new fabric.Control({
    x: 0.5,
    y: 0,
    actionHandler: fabric.controlsUtils.wrapWithFireEvent(
      "resizing",
      fabric.controlsUtils.wrapWithFixedAnchor(changeWidth)
    ),
    getActionName: fabric.Textbox.prototype.controls.mr.getActionName,
    cursorStyleHandler: fabric.Textbox.prototype.controls.mr.cursorStyleHandler,
  });
  fabric.Textbox.prototype.controls.ml = new fabric.Control({
    x: -0.5,
    y: 0,
    actionHandler: fabric.controlsUtils.wrapWithFireEvent(
      "resizing",
      fabric.controlsUtils.wrapWithFixedAnchor(changeWidth)
    ),
    getActionName: fabric.Textbox.prototype.controls.ml.getActionName,
    cursorStyleHandler: fabric.Textbox.prototype.controls.ml.cursorStyleHandler,
  });
}

(function (fabric) {
  "use strict";

  // var fabric = global.fabric || (global.fabric = {});

  fabric.EffectTextBox = fabric.util.createClass(fabric.Textbox, {
    type: "effectTextBox",
    stateProperties: fabric.Object.prototype.stateProperties.concat(
      "effectLayer",
      "effectTop"
    ),
    cacheProperties: fabric.Object.prototype.cacheProperties.concat(
      "effectLayer",
      "effectTop"
    ),
    initDimensions: function () {
      if (this.__skipDimension) {
        return;
      }
      this.isEditing && this.initDelayedCursor();
      this.clearContextTop();
      this._clearCache();
      // clear dynamicMinWidth as it will be different after we re-wrap line
      this.dynamicMinWidth = 0;
      // wrap lines
      this._styleMap = this._generateStyleMap(this._splitText());
      // if after wrapping, the width is smaller than dynamicMinWidth, change the width and re-wrap
      if (this.dynamicMinWidth > this.width) {
        this._set("width", this.dynamicMinWidth);
      }
      if (this.textAlign.indexOf("justify") !== -1) {
        // once text is measured we need to make space fatter to make justified text.
        this.enlargeSpaces();
      }
      // clear cache and re-calculate height
      let _textHeight = this.calcTextHeight();
      if (this.manualIncreaseSize) {
        if (_textHeight > this.manualIncreaseSize) {
          this.height = _textHeight;
        } else {
          this.height = this.manualIncreaseSize;
        }
      } else {
        this.height = _textHeight;
      }
      this.saveState({ propertySet: "_dimensionAffectingProps" });
    },

    initHiddenTextarea: function () {
      this.callSuper('initHiddenTextarea');
      // for full screen mode
      this.hiddenTextarea.style.position = 'fixed'
      fabric.document.body.removeChild(this.hiddenTextarea);
      this.canvas.wrapperEl.appendChild(this.hiddenTextarea);
    },
    _renderChar: function (
      method,
      ctx,
      lineIndex,
      charIndex,
      _char,
      left,
      top
    ) {
      var decl = this._getStyleDeclaration(lineIndex, charIndex),
        fullDecl = this.getCompleteStyleDeclaration(lineIndex, charIndex),
        shouldFill = method === "fillText" && fullDecl.fill,
        shouldStroke =
          method === "strokeText" && fullDecl.stroke && fullDecl.strokeWidth,
        fillOffsets,
        strokeOffsets;

      if (!shouldStroke && !shouldFill) {
        return;
      }
      ctx.save();

      shouldFill && (fillOffsets = this._setFillStyles(ctx, fullDecl));
      shouldStroke && (strokeOffsets = this._setStrokeStyles(ctx, fullDecl));

      ctx.font = this._getFontDeclaration(fullDecl);

      if (decl && decl.textBackgroundColor) {
        this._removeShadow(ctx);
      }
      if (decl && decl.deltaY) {
        top += decl.deltaY;
      }
      shouldFill &&
        ctx.fillText(
          _char,
          left - fillOffsets.offsetX,
          top - fillOffsets.offsetY
        );
      shouldStroke &&
        ctx.strokeText(
          _char,
          left - strokeOffsets.offsetX,
          top - strokeOffsets.offsetY
        );
      ctx.restore();
    },
    _renderChars: function (method, ctx, line, left, top, lineIndex) {
      // set proper line offset
      var lineHeight = this.getHeightOfLine(lineIndex),
        isJustify = this.textAlign.indexOf("justify") !== -1,
        actualStyle,
        nextStyle,
        charsToRender = "",
        charBox,
        boxWidth = 0,
        timeToRender,
        path = this.path,
        shortCut =
          !isJustify &&
          this.charSpacing === 0 &&
          this.isEmptyStyles(lineIndex) &&
          !path,
        isLtr = this.direction === "ltr",
        sign = this.direction === "ltr" ? 1 : -1,
        drawingLeft;

      ctx.save();
      top -= (lineHeight * this._fontSizeFraction) / this.lineHeight;
      if (shortCut) {
        // render all the line in one pass without checking
        // drawingLeft = isLtr ? left : left - this.getLineWidth(lineIndex);
        ctx.canvas.setAttribute("dir", isLtr ? "ltr" : "rtl");
        ctx.direction = isLtr ? "ltr" : "rtl";
        ctx.textAlign = isLtr ? "left" : "right";
        this._renderChar(
          method,
          ctx,
          lineIndex,
          0,
          line.join(""),
          left,
          top,
          lineHeight
        );
        ctx.restore();
        return;
      }
      for (var i = 0, len = line.length - 1; i <= len; i++) {
        timeToRender = i === len || this.charSpacing || path;
        charsToRender += line[i];
        charBox = this.__charBounds[lineIndex][i];
        if (boxWidth === 0) {
          left += sign * (charBox.kernedWidth - charBox.width);
          boxWidth += charBox.width;
        } else {
          boxWidth += charBox.kernedWidth;
        }
        if (isJustify && !timeToRender) {
          if (this._reSpaceAndTab.test(line[i])) {
            timeToRender = true;
          }
        }
        if (!timeToRender) {
          // if we have charSpacing, we render char by char
          actualStyle =
            actualStyle || this.getCompleteStyleDeclaration(lineIndex, i);
          nextStyle = this.getCompleteStyleDeclaration(lineIndex, i + 1);
          timeToRender = this._hasStyleChanged(actualStyle, nextStyle);
        }
        if (timeToRender) {
          if (path) {
            ctx.save();
            ctx.translate(charBox.renderLeft, charBox.renderTop);
            ctx.rotate(charBox.angle);
            this._renderChar(
              method,
              ctx,
              lineIndex,
              i,
              charsToRender,
              -boxWidth / 2,
              0,
              lineHeight
            );
            ctx.restore();
          } else {
            drawingLeft = left;
            ctx.canvas.setAttribute("dir", isLtr ? "ltr" : "rtl");
            ctx.direction = isLtr ? "ltr" : "rtl";
            ctx.textAlign = isLtr ? "left" : "right";
            this._renderChar(
              method,
              ctx,
              lineIndex,
              i,
              charsToRender,
              drawingLeft,
              top,
              lineHeight
            );
          }
          charsToRender = "";
          actualStyle = nextStyle;
          left += sign * boxWidth;
          boxWidth = 0;
        }
      }
      ctx.restore();
    },
    _renderTextCommonEffect: function (ctx, method) {
      ctx.save();
      let lineHeights = 0,
        left = this._getLeftOffset(),
        top = this._getTopOffset(),
        zoom = this.canvas.getZoom();
      if (this.leftOffset) {
        left += this.leftOffset * zoom;
      }
      if (this.topOffset) {
        top += this.topOffset * zoom;
      }
      if (this.objectBlur) {
        ctx.filter = `blur(${parseInt(
          this.objectBlur * zoom * this.scaleX
        )}px)`;
      }
      for (let i = 0, len = this._textLines.length; i < len; i++) {
        let heightOfLine = this.getHeightOfLine(i),
          maxHeight = heightOfLine / this.lineHeight,
          leftOffset = this._getLineLeftOffset(i);
        this._renderChars(
          method,
          ctx,
          this._textLines[i],
          left + leftOffset,
          top + lineHeights + maxHeight,
          i
        );
        lineHeights += heightOfLine;
      }
      ctx.restore();
    },
    _renderTextFillEffect: function (ctx) {
      if (!this.fill && !this.styleHas("fill")) {
        return;
      }

      this._renderTextCommonEffect(ctx, "fillText");
    },
    _renderTextStrokeEffect: function (ctx) {
      if ((!this.stroke || this.strokeWidth === 0) && this.isEmptyStyles()) {
        return;
      }

      if (this.shadow && !this.shadow.affectStroke) {
        this._removeShadow(ctx);
      }

      ctx.save();
      this._setLineDash(ctx, this.strokeDashArray);
      ctx.beginPath();
      this._renderTextCommonEffect(ctx, "strokeText");
      ctx.closePath();
      ctx.restore();
    },
    _renderTextEffect: function (ctx) {
      if (this.paintFirst === "stroke") {
        this._renderTextStrokeEffect(ctx);
        this._renderTextFillEffect(ctx);
      } else {
        this._renderTextFillEffect(ctx);
        this._renderTextStrokeEffect(ctx);
      }
    },
    _renderTextDecorationEffect: function (ctx, type) {
      if (!this[type] && !this.styleHas(type)) {
        return;
      }
      let heightOfLine,
        size,
        _size,
        lineLeftOffset,
        dy,
        _dy,
        line,
        lastDecoration,
        leftOffset = this._getLeftOffset(),
        topOffset = this._getTopOffset(),
        top,
        boxStart,
        boxWidth,
        charBox,
        currentDecoration,
        maxHeight,
        currentFill,
        lastFill,
        path = this.path,
        charSpacing = this._getWidthOfCharSpacing(),
        offsetY = this.offsets[type],
        zoom = this.canvas.getZoom();

      if (this.leftOffset) {
        leftOffset += this.leftOffset * zoom;
      }
      if (this.topOffset) {
        topOffset += this.topOffset * zoom;
      }
      for (let i = 0, len = this._textLines.length; i < len; i++) {
        heightOfLine = this.getHeightOfLine(i);
        if (!this[type] && !this.styleHas(type, i)) {
          topOffset += heightOfLine;
          continue;
        }
        line = this._textLines[i];
        maxHeight = heightOfLine / this.lineHeight;
        lineLeftOffset = this._getLineLeftOffset(i);
        boxStart = 0;
        boxWidth = 0;
        lastDecoration = this.getValueOfPropertyAt(i, 0, type);
        lastFill = this.getValueOfPropertyAt(i, 0, "fill");
        top = topOffset + maxHeight * (1 - this._fontSizeFraction);
        size = this.getHeightOfChar(i, 0);
        dy = this.getValueOfPropertyAt(i, 0, "deltaY");
        for (let j = 0, jlen = line.length; j < jlen; j++) {
          charBox = this.__charBounds[i][j];
          currentDecoration = this.getValueOfPropertyAt(i, j, type);
          currentFill = this.getValueOfPropertyAt(i, j, "fill");
          _size = this.getHeightOfChar(i, j);
          _dy = this.getValueOfPropertyAt(i, j, "deltaY");
          if (path && currentDecoration && currentFill) {
            ctx.save();
            ctx.fillStyle = lastFill;
            ctx.translate(charBox.renderLeft, charBox.renderTop);
            ctx.rotate(charBox.angle);
            ctx.fillRect(
              -charBox.kernedWidth / 2,
              offsetY * _size + _dy,
              charBox.kernedWidth,
              this.fontSize / 15
            );
            ctx.restore();
          } else if (
            (currentDecoration !== lastDecoration ||
              currentFill !== lastFill ||
              _size !== size ||
              _dy !== dy) &&
            boxWidth > 0
          ) {
            let drawStart = leftOffset + lineLeftOffset + boxStart;
            if (this.direction === "rtl") {
              drawStart = this.width - drawStart - boxWidth;
            }
            if (lastDecoration && lastFill) {
              ctx.fillStyle = lastFill;
              ctx.fillRect(
                drawStart,
                top + offsetY * size + dy,
                boxWidth,
                this.fontSize / 15
              );
            }
            boxStart = charBox.left;
            boxWidth = charBox.width;
            lastDecoration = currentDecoration;
            lastFill = currentFill;
            size = _size;
            dy = _dy;
          } else {
            boxWidth += charBox.kernedWidth;
          }
        }
        let drawStart = leftOffset + lineLeftOffset + boxStart;
        if (this.direction === "rtl") {
          drawStart = this.width - drawStart - boxWidth;
        }
        ctx.fillStyle = currentFill;
        currentDecoration &&
          currentFill &&
          ctx.fillRect(
            drawStart,
            top + offsetY * size + dy,
            boxWidth - charSpacing,
            this.fontSize / 15
          );
        topOffset += heightOfLine;
      }
      // if there is text background color no
      // other shadows should be casted
      this._removeShadow(ctx);
    },
    _renderEffectLayer: function (ctx) {
      if (this.effectLayer && this.effectLayer.length > 0) {
        let _originalDataBeforeReplace = {};
        for (let indx = 0; indx < this.effectLayer.length; indx++) {
          delete this.effectLayer[indx].left;
          delete this.effectLayer[indx].top;
          Object.keys(this.effectLayer[indx]).forEach((key, _i) => {
            if (_originalDataBeforeReplace[key] === undefined) {
              _originalDataBeforeReplace[key] = this[key];
            }
            this[key] = this.effectLayer[indx][key];
          });
          ctx.save();
          if (this.effectLayer[indx].applyMask) {
            ctx.globalCompositeOperation = "source-atop";
          }
          this._setOpacity(ctx);
          this._setShadow(ctx, this);
          this._setTextStyles(ctx);
          this._renderTextLinesBackground(ctx);
          this._renderTextDecorationEffect(ctx, "underline");
          this._renderTextEffect(ctx);
          this._renderTextDecorationEffect(ctx, "overline");
          this._renderTextDecorationEffect(ctx, "linethrough");
          // reset original
          Object.keys(_originalDataBeforeReplace).forEach((key, _i) => {
            this[key] = _originalDataBeforeReplace[key];
          });
          ctx.restore();
        }
      }
    },

    _render: function (ctx) {
      if (!this.effectTop) {
        this._renderEffectLayer(ctx);
      }
      let path = this.path;
      path && !path.isNotVisible() && path._render(ctx);
      this._setTextStyles(ctx);
      this._renderTextLinesBackground(ctx);
      this._renderTextDecoration(ctx, "underline");
      this._renderText(ctx);
      this._renderTextDecoration(ctx, "overline");
      this._renderTextDecoration(ctx, "linethrough");
      if (this.effectTop) {
        this._renderEffectLayer(ctx);
      }
    },

    toObject: function (propertiesToInclude) {
      return this.callSuper(
        "toObject",
        ["effectLayer", "effectTop"].concat(propertiesToInclude)
      );
    },
  });

  fabric.EffectTextBox.fromObject = function (params, callback) {
    callback && callback(new fabric.EffectTextBox(params.text, params));
  };
})(fabric);

// add changeHeight
fabric.controlsUtils.changeHeight = fabric.controlsUtils.wrapWithFireEvent(
  "resizing",
  fabric.controlsUtils.wrapWithFixedAnchor(changeHeight)
);
