/* eslint-disable no-whitespace-before-property */
import gsap from "gsap";
import { fabric } from "fabric";
import { display } from "./addVideoToCanvasAndStore";
import { fitImageToRect, nextFromIndex, prevFromIndex } from "../../utils";
import { customSeek, videoPlayUpdateFilter } from "../videoPlayThings";
import { updateVideosTime } from "./updateVideosTimeHelper";
import { intialSceneSetup, parseSceneData } from "../shortcutFunction";
import { SIZES } from "../config";
import { overlayApplyFilters } from "../overlayApplyFilters";

//save dom init here SAVE_DOM_INIT
document.SceneAnimation = {
  sceneData: {},
  sceneIndex: null,
  totalDuration: 0,
  crntTimeline: null,
  canvas: null,
  fabCanvas: null,
  which: 0, // which one is active {0: 'no one',1: 'prev' (avatar canvas) ccxa,2: 'next' (crop canvas) ccx}
  scale: 1,

  init: function (_sceneData = {}) {
    const sceneData = {
      prev: {
        isExist: false,
        init: false,
        sceneIndex: null,
        canvas: null,
        fabCanvas: null,
        crntTimeline: null,
        duration: 0,
        animationData: {},
        isOverlayVideo: false,
        overlayVideoElm: null,
        overlayFabObj: null,
        timeline: gsap.timeline({
          paused: true,
        }),
        renderCanvas: document.ccxa,
      },
      next: {
        isExist: false,
        init: false,
        sceneIndex: null,
        canvas: null,
        fabCanvas: null,
        crntTimeline: null,
        duration: 0,
        animationData: {},
        isOverlayVideo: false,
        overlayVideoElm: null,
        overlayFabObj: null,
        timeline: gsap.timeline({
          paused: true,
        }),
        renderCanvas: document.ccx,
      },
    };
    this.sceneData = {
      ...sceneData,
      ..._sceneData,
    };
  },

  setup: function ({ index, timeline }) {
    this.crntTimeline = timeline;
    this.totalDuration = this.crntTimeline.duration();
    this.sceneIndex = index;
    this.canvas = document.cxx[this.sceneIndex].getElement();

    // handle scale
    this.scale = SIZES[document?._size || 0].height / this.canvas.height;

    this.fabCanvas = new fabric.Image(this.canvas, {
      left: 0,
      top: 0,
      scaleX: this.scale,
      scaleY: this.scale,
      opacity: 1,
    });

    this.sceneData.prev.sceneIndex = prevFromIndex(index);
    this.sceneData.next.sceneIndex = nextFromIndex(index);

    // previous scene setup
    if (
      this.sceneData.prev.sceneIndex !== undefined &&
      this.sceneData.prev.sceneIndex >= 0
    ) {
      this.sceneData.prev.crntTimeline =
        document.txx?.timeLine?.[this.sceneData.prev.sceneIndex];
      if (this.sceneData.prev.crntTimeline) {
        const _td = this.sceneData.prev.crntTimeline.duration();
        this.sceneData.prev.crntTimeline.seek(_td);
        customSeek(_td, this.sceneData.prev.sceneIndex);
        // updateVideosTime(_td, this.sceneData.prev.sceneIndex);
        document.cxx[this.sceneData.prev.sceneIndex].renderAll();
        this.sceneData.prev.canvas =
          document.cxx[this.sceneData.prev.sceneIndex].getElement();
        this.sceneData.prev.fabCanvas = new fabric.Image(
          this.sceneData.prev.canvas,
          {
            left: 0,
            top: 0,
            scaleX: this.scale,
            scaleY: this.scale,
            opacity: 1,
          }
        );
      }
      this.sceneData.prev.animationData =
        document.scenes?.[this.sceneData.prev.sceneIndex]?.sanimation;
      if (this.sceneData.prev.animationData && this.sceneData.prev.canvas && this.sceneData.prev.animationData.name !== 'None') {
        this.sceneData.prev.duration =
          (this.sceneData.prev.animationData.animationData?.end?.to?.duration ||
            0) / 2;
        if (this.sceneData.prev.duration) {
          this.sceneData.prev.isExist = true;
        }

        const _loadAssets =
          this.sceneData.prev.animationData.animationData?.loadAsset;
        if (_loadAssets) {
          const _videoElm = document.getElementById(
            `sanimation${this.sceneData.prev.sceneIndex}`
          );
          if (_videoElm && !(_videoElm.height || _videoElm.width)) {
            _videoElm.height = _videoElm.videoHeight;
            _videoElm.width = _videoElm.videoWidth;
            _videoElm.currentTime = _videoElm.duration * 0.5;
          }
          if (_videoElm) {
            this.sceneData.prev.isOverlayVideo = true;
            this.sceneData.prev.overlayFabObj = new fabric.Image(_videoElm, {
              left: 0,
              top: 0,
            });
            overlayApplyFilters(
              this.sceneData.prev.overlayFabObj,
              _loadAssets.filters,
              this.sceneData.prev.animationData.colors,
              false
            );
          } else {
            this.sceneData.prev.isExist = false;
          }
        }
      }
    }

    // next scene setup
    if (
      this.sceneData.next.sceneIndex !== undefined &&
      this.sceneData.next.sceneIndex >= 0
    ) {
      this.sceneData.next.crntTimeline =
        document.txx?.timeLine?.[this.sceneData.next.sceneIndex];
      if (this.sceneData.next.crntTimeline) {
        const _td = 0;
        this.sceneData.next.crntTimeline.seek(_td);
        customSeek(_td, this.sceneData.next.sceneIndex);
        // updateVideosTime(_td, this.sceneData.next.sceneIndex);
        document.cxx[this.sceneData.next.sceneIndex].renderAll();
        this.sceneData.next.canvas =
          document.cxx[this.sceneData.next.sceneIndex].getElement();
        this.sceneData.next.fabCanvas = new fabric.Image(
          this.sceneData.next.canvas,
          {
            left: 0,
            top: 0,
            scaleX: this.scale,
            scaleY: this.scale,
            opacity: 1,
          }
        );
      }
      this.sceneData.next.animationData =
        document.scenes?.[this.sceneIndex]?.sanimation;
      if (this.sceneData.next.animationData && this.sceneData.next.canvas && this.sceneData.next.animationData.name !== 'None') {
        this.sceneData.next.duration =
          (this.sceneData.next.animationData.animationData?.start?.to
            ?.duration || 0) / 2;
        if (this.sceneData.next.duration) {
          this.sceneData.next.isExist = true;
        }

        const _loadAssets =
          this.sceneData.next.animationData.animationData?.loadAsset;
        if (_loadAssets) {
          const _videoElm = document.getElementById(
            `sanimation${this.sceneIndex}`
          );
          if (_videoElm && !(_videoElm.height || _videoElm.width)) {
            _videoElm.height = _videoElm.videoHeight;
            _videoElm.width = _videoElm.videoWidth;
            _videoElm.currentTime = 0;
          }
          if (_videoElm) {
            this.sceneData.next.isOverlayVideo = true;
            this.sceneData.next.overlayFabObj = new fabric.Image(_videoElm, {
              left: 0,
              top: 0,
            });
            overlayApplyFilters(
              this.sceneData.next.overlayFabObj,
              _loadAssets.filters,
              this.sceneData.next.animationData.colors,
              false
            );
          } else {
            this.sceneData.next.isExist = false;
          }
        }
      }
    }
    this.createTimeLine();
  },

  //
  //document.cccxa.current 29 prev
  // document.cccx.current 30 next
  //

  showCanvas: function (which) {
    if (which === 1) {
      // prev canvas
      document.cccxa.current.style.display = "block";
      document.cccx.current.style.display = "none";
    } else if (which === 2) {
      // next canvas
      document.cccxa.current.style.display = "none";
      document.cccx.current.style.display = "block";
    } else {
      document.cccxa.current.style.display = "none";
      document.cccx.current.style.display = "none";
    }
  },

  createTimeLine: function () {
    if (this.sceneData.prev.isExist) {
      this.fabCanvas.set({
        opacity: 0,
      });
      this.sceneData.prev.renderCanvas.clear();
      this.sceneData.prev.timeline.clear();

      this.sceneData.prev.renderCanvas.add(this.sceneData.prev.fabCanvas);
      this.sceneData.prev.renderCanvas.add(this.fabCanvas);

      if (this.sceneData.prev.isOverlayVideo) {
        // const _crntImg = await this.sceneAnimation.prevScene.batchLoader.next();
        // if (_crntImg) {
        //   this.updateCanvasFilter(_crntImg, this.sceneAnimation.prevScene.overlayCanvas, this.sceneAnimation.prevScene.overlayFabObj);
        // }
        this.sceneData.prev.renderCanvas.add(this.sceneData.prev.overlayFabObj);
        fitImageToRect(this.sceneData.prev.overlayFabObj, {
          top: 0,
          left: 0,
          height: SIZES[document?._size || 0].height,
          width: SIZES[document?._size || 0].width,
        });
        this.fabCanvas.set({
          opacity: 1,
        });
      }
      let parseAnimation = {
        start: parseSceneData(
          this.sceneData.prev.fabCanvas,
          this.sceneData.prev.animationData.animationData.start
        ),
        end: parseSceneData(
          this.fabCanvas,
          this.sceneData.prev.animationData.animationData.end
        ),
      };
      // intial setup
      if (this.sceneData.prev.animationData.animationData.init) {
        intialSceneSetup(this.sceneData.prev.animationData.animationData.init, {
          canvas: this.sceneData.prev.renderCanvas,
        });
      }

      this.sceneData.prev.timeline.fromTo(
        this.sceneData.prev.fabCanvas,
        parseAnimation.start.from,
        parseAnimation.start.to,
        0
      );
      this.sceneData.prev.timeline.fromTo(
        this.fabCanvas,
        parseAnimation.end.from,
        parseAnimation.end.to,
        0
      );
    }

    if (this.sceneData.next.isExist) {
      this.sceneData.next.renderCanvas.clear();
      this.sceneData.next.timeline.clear();

      this.sceneData.next.renderCanvas.add(this.fabCanvas);
      this.sceneData.next.renderCanvas.add(this.sceneData.next.fabCanvas);

      if (this.sceneData.next.isOverlayVideo) {
        // const _crntImg = await this.sceneAnimation.prevScene.batchLoader.next();
        // if (_crntImg) {
        //   this.updateCanvasFilter(_crntImg, this.sceneAnimation.prevScene.overlayCanvas, this.sceneAnimation.prevScene.overlayFabObj);
        // }
        this.sceneData.next.renderCanvas.add(this.sceneData.next.overlayFabObj);
        fitImageToRect(this.sceneData.next.overlayFabObj, {
          top: 0,
          left: 0,
          height: SIZES[document?._size || 0].height,
          width: SIZES[document?._size || 0].width,
        });
        this.sceneData.next.fabCanvas.set({
          opacity: 0,
        });
      }
      let parseAnimation = {
        start: parseSceneData(
          this.fabCanvas,
          this.sceneData.next.animationData.animationData.start
        ),
        end: parseSceneData(
          this.sceneData.next.fabCanvas,
          this.sceneData.next.animationData.animationData.end
        ),
      };
      // intial setup
      if (this.sceneData.next.animationData.animationData.init) {
        intialSceneSetup(this.sceneData.next.animationData.animationData.init, {
          canvas: this.sceneData.next.renderCanvas,
        });
      }

      this.sceneData.next.timeline.fromTo(
        this.fabCanvas,
        parseAnimation.start.from,
        parseAnimation.start.to,
        0
      );
      this.sceneData.next.timeline.fromTo(
        this.sceneData.next.fabCanvas,
        parseAnimation.end.from,
        parseAnimation.end.to,
        0
      );
    }
  },

  ticker: function ({ time }) {
    let _isSanimation = false;
    if (this.sceneData.prev.isExist) {
      if (time <= this.sceneData.prev.duration) {
        if (this.which !== 1) {
          this.which = 1;
          this.showCanvas(this.which);
        }
        const _progress = 0.5 + time / (2 * this.sceneData.prev.duration);
        this.sceneData.prev.timeline.progress(_progress);

        if (this.sceneData.prev.isOverlayVideo) {
          this.sceneData.prev.overlayFabObj._originalElement.currentTime =
            this.sceneData.prev.overlayFabObj._originalElement.duration *
            _progress;
          videoPlayUpdateFilter(this.sceneData.prev.overlayFabObj);
        }
        this.sceneData.prev.renderCanvas.renderAll();
        _isSanimation = true;
      }
    }

    if (this.sceneData.next.isExist) {
      const _frameDiff = this.totalDuration - this.sceneData.next.duration;
      if (time >= _frameDiff) {
        if (this.which !== 2) {
          this.which = 2;
          this.showCanvas(this.which);
        }
        const _progress =
          (time - _frameDiff) / (2 * this.sceneData.next.duration);
        this.sceneData.next.timeline.progress(_progress);
        if (this.sceneData.next.isOverlayVideo) {
          this.sceneData.next.overlayFabObj._originalElement.currentTime =
            this.sceneData.next.overlayFabObj._originalElement.duration *
            _progress;
          videoPlayUpdateFilter(this.sceneData.next.overlayFabObj);
        }
        this.sceneData.next.renderCanvas.renderAll();
        _isSanimation = true;
      }
    }

    if (this.which !== 0 && !_isSanimation) {
      this.which = 0;
      this.showCanvas(this.which);
    }
  },
};

export const LoadSAnimationVideoToDOM = async ({ index, animationData }) => {
  if (animationData) {
    const { loadAsset } = animationData;
    if (loadAsset) {
      const { id, media_file: url } = loadAsset;
      let murl = await display(url, id);
      let sEle = null;
      let oldEle = document.getElementById(`sanimation${index}`);
      if (oldEle) {
        sEle = oldEle;
        sEle.src = murl;
      } else {
        sEle = document.createElement("video");
        sEle.id = `sanimation${index}`;
        sEle.preload = "auto";
        sEle.src = murl;
        document.querySelector(".Media").appendChild(sEle);
      }
    }
  }
};
