import React from 'react'
import Popup from 'reactjs-popup';
export default function Tooltip(props) {

    return (
      <Popup
      className="infologo 1"
trigger={
props.children
}
// position={props.pos?props.pos:"bottom center"}
closeOnDocumentClick
nested
on={['hover']}
>
<div className="popup-info" style={{...props.style}}> {props.data?props.data:""} </div>
</Popup>
        
    )
}
