/* eslint-disable */
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Video from "./Video";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Home from "./App/Home";
import makeImgEditor from "./makeImgEditor";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route
            path="/image"
            render={() => {
              makeImgEditor();
              document.documentElement.style.setProperty(
                "--timelineHeight",
                "0px"
              );
              document.documentElement.style.setProperty("--rigthWidth", "0px");
              document.editorType = "image";
              // console.log(document.editorType);
              return <Video />;
            }}
          ></Route>
          <Route
            path="/video"
            render={() => {
              document.editorType = "video";
              return <Video />;
            }}
          ></Route>

          <Route path="/">
            <Home />
          </Route>

          <Route path="/" exact>
            {(data) => {
              data.history.push(`/`);
            }}
          </Route>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
