import {
  setSVGObjColor,
  getSVGObjColor,
  setShapeSVGObjColor,
} from "../../utils";
import { fabric } from "fabric";
import { customVideoSeek } from "../videoPlayThings";

let con = console;

(function (global) {
  "use strict";

  var filters = fabric.Image.filters,
    createClass = fabric.util.createClass;

  /**
   * ColorInterpolation filter class
   * @class fabric.Image.filters.ColorInterpolation
   * @memberOf fabric.Image.filters
   * @extends fabric.Image.filters.BaseFilter
   * @see {@link fabric.Image.filters.ColorInterpolation#initialize} for constructor definition
   * @example
   * var filter = new fabric.Image.filters.ColorInterpolation({
   *   tableValues: {r: [0,0.7647058823529411,1],g: [0,0.8823529411764706,1],b: [0,0.10588235294117647,1],a: [0,0.5,1]},
   * });
   * object.filters.push(filter);
   * object.applyFilters();
   **/

  filters.ColorInterpolation = createClass(
    filters.BaseFilter,
    /** @lends fabric.Image.filters.SwapColor.prototype */ {
      /**
       * Filter type
       * @param {String} type
       * @default
       */
      type: "ColorInterpolation",

      /**
       * Fragment source for the ColorInterpolation program
       */
      fragmentSource:
        "precision highp float;\n" +
        "uniform sampler2D uTexture;\n" +
        "varying vec2 vTexCoord;\n" +
        "const int MAX_INTERPOLATION = 20;\n" +
        "uniform float colorR[MAX_INTERPOLATION];\n" +
        "uniform float colorG[MAX_INTERPOLATION];\n" +
        "uniform float colorB[MAX_INTERPOLATION];\n" +
        "uniform float colorA[MAX_INTERPOLATION];\n" +
        "uniform int getIndex;\n" +
        "void main() {\n" +
        "vec4 color = texture2D(uTexture, vTexCoord);\n" +
        "if (getIndex == 1) {\n" +
        "gl_FragColor = vec4(colorR[0],colorG[0],colorB[0],colorA[0]);\n" +
        "} else {\n" +
        "for (int w = 0; w < MAX_INTERPOLATION; w+=1) {\n" +
        "if (w >= getIndex-1){break;}\n" +
        "float crntRange = float(w)/float(getIndex-1);\n" +
        "float nextRange = float(w+1)/float(getIndex-1);\n" +
        "if(color.r>crntRange && color.r<=nextRange) {\n" +
        "color.r = colorR[w+1];\n" +
        "}else if(color.r==crntRange){\n" +
        "color.r = colorR[w];\n" +
        "}\n" +
        "if(color.g>crntRange && color.g<=nextRange) {\n" +
        "color.g = colorG[w+1];\n" +
        "}else if(color.g==crntRange){\n" +
        "color.g = colorG[w];\n" +
        "}\n" +
        "if(color.b>crntRange && color.b<=nextRange) {\n" +
        "color.b = colorB[w+1];\n" +
        "}else if(color.b==crntRange){\n" +
        "color.b = colorB[w];\n" +
        "}\n" +
        "if(color.a>crntRange && color.a<=nextRange) {\n" +
        "color.a = colorA[w+1];\n" +
        "}else if(color.a==crntRange){\n" +
        "color.a = colorA[w];\n" +
        "}\n" +
        "}\n" +
        "gl_FragColor = color;\n" +
        "}\n" +
        "}",

      /**
       * ColorInterpolation TableValues, a rgba object with color interpolation
       * @param {String} tableValues
       * @default
       */
      tableValues: {
        r: [0, 0.7647058823529411, 1],
        g: [0, 0.8823529411764706, 1],
        b: [0, 0.10588235294117647, 1],
        a: [0, 0.5, 1],
      },

      parseRange: function (multipleFactor = 255) {
        if (
          this.tableValues.r.length === this.tableValues.g.length &&
          this.tableValues.g.length === this.tableValues.b.length &&
          this.tableValues.b.length === this.tableValues.a.length
        ) {
          return {
            r: this.tableValues.r.map((elm) => multipleFactor * elm),
            g: this.tableValues.g.map((elm) => multipleFactor * elm),
            b: this.tableValues.b.map((elm) => multipleFactor * elm),
            a: this.tableValues.a,
          };
        } else {
          return {
            r: [multipleFactor],
            g: [multipleFactor],
            b: [multipleFactor],
            a: [1],
          };
        }
      },

      /**
       * Apply the ColorInterpolation operation to a Uint8ClampedArray representing the pixels of an image.
       *
       * @param {Object} options
       * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
       */
      applyTo2d: function (options) {
        var imageData = options.imageData,
          data = imageData.data,
          i,
          len = data.length,
          interplateRange = this.parseRange();
        let totalN = interplateRange.r.length,
          sSeq;
        if (totalN > 1) {
          sSeq = 255 / (totalN - 1);
        }
        console.log(sSeq, interplateRange, len);
        for (i = 0; i < len; i += 4) {
          if (totalN === 1) {
            data[i] = interplateRange.r[0];
            data[i + 1] = interplateRange.g[0];
            data[i + 2] = interplateRange.b[0];
            data[i + 2] = interplateRange.a[0];
          } else {
            data[i] = interplateRange.r[Math.floor(data[i] / sSeq)];
            data[i + 1] = interplateRange.g[Math.floor(data[i + 1] / sSeq)];
            data[i + 2] = interplateRange.b[Math.floor(data[i + 2] / sSeq)];
            data[i + 2] = interplateRange.a[Math.floor(data[i + 3] / sSeq)];
          }
        }
      },

      getUniformLocations: function (gl, program) {
        return {
          uColorR: gl.getUniformLocation(program, "colorR"),
          uColorG: gl.getUniformLocation(program, "colorG"),
          uColorB: gl.getUniformLocation(program, "colorB"),
          uColorA: gl.getUniformLocation(program, "colorA"),
          uGetIndex: gl.getUniformLocation(program, "getIndex"),
        };
      },

      sendUniformData: function (gl, uniformLocations) {
        let _colorRange = this.parseRange(1);
        let totalN = _colorRange.r.length;

        console.log(_colorRange, totalN);
        gl.uniform1fv(uniformLocations.uColorR, _colorRange.r);
        gl.uniform1fv(uniformLocations.uColorG, _colorRange.g);
        gl.uniform1fv(uniformLocations.uColorB, _colorRange.b);
        gl.uniform1fv(uniformLocations.uColorA, _colorRange.a);
        gl.uniform1i(uniformLocations.uGetIndex, totalN);
      },

      isNeutralState: function () {
        return false;
      },

      /**
       * Returns object representation of an instance
       * @return {Object} Object representation of an instance
       */
      toObject: function () {
        return fabric.util.object.extend(this.callSuper("toObject"), {
          tableValues: this.tableValues,
        });
      },
    }
  );

  /**
   * Returns filter instance from an object representation
   * @static
   * @param {Object} object Object to create an instance from
   * @param {function} [callback] to be invoked after filter creation
   * @return {fabric.Image.filters.ColorInterpolation} Instance of fabric.Image.filters.ColorInterpolation
   */
  fabric.Image.filters.ColorInterpolation.fromObject =
    fabric.Image.filters.BaseFilter.fromObject;
})(typeof exports !== "undefined" ? exports : this);


export const loadMusic = async (url, id, options) => {
  return new Promise((resolve, reject) => {
    if (url) {
      var video = document.createElement("audio");
      video.customVideoSeek = customVideoSeek;

      video.preload = "auto";
      video.crossOrigin = "anonymous";
      video.onloadeddata = function () {
        video.id = id;
        const Media = document.querySelector(".Media");
        if (Media) {
          Media.appendChild(video);
        }
        resolve(video);
      };
      /** @ignore */
      video.onerror = function () {
        resolve(null);
      };
      video.src = url;
    } else {
      resolve(null);
    }
  });
};

const generateEditorControlUID = function generateEditorControlUID(type) {
  return (
    type + "_" + new Date().getTime() + "_" + Math.round(Math.random() * 1000)
  );
};

/**
 * Fabric Video
 */
fabric.util.loadVideo = function (url, callback, context, crossOrigin) {
  if (!url) {
    callback && callback.call(context, url);
    return;
  }

  var img = document.createElement("video");
  img.customVideoSeek = customVideoSeek;

  img.preload = "auto";
  crossOrigin = "anonymous";
  //   if(document.getElementById(img.id))

  /** @ignore */
  var onLoadCallback = function () {
    img.height = img.videoHeight;
    img.width = img.videoWidth;
    // img.play().then(() => {
    //   img.pause();
    callback && callback.call(context, img, false);
    img = img.onloadeddata = img.onerror = null;
    // });
  };

  img.onloadeddata = onLoadCallback;
  /** @ignore */
  img.onerror = function () {
    con.log("Error loading " + img.src);
    callback && callback.call(context, null, true);
    img = img.onloadeddata = img.onerror = null;
  };

  if (
    url.indexOf("data") !== 0 &&
    crossOrigin !== undefined &&
    crossOrigin !== null
  ) {
    img.crossOrigin = crossOrigin;
  }
  img.src = url;
};

fabric.Video = fabric.util.createClass(fabric.Image, {
  type: "video",
  title: "Video",
  _Type: "video",
  // _Link: "",
  _Src: "",
  _render: function _render(ctx) {
    this.callSuper("_render", ctx);
  },

  initialize: function initialize(element, options) {
    this.callSuper("initialize", element, options);
    options &&
      this.set(
        "id",
        options.id ? options.id : generateEditorControlUID(this.type)
      );
  },
  toObject: function toObject() {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      id: this.uid,

      _Type: this._Type,
      _Src: this._Src,
    });
  },
});

fabric.Video.fromObject = function (_object, callback) {
  var object = fabric.util.object.clone(_object);
  fabric.util.loadVideo(
    object.src,
    function (img, isError) {
      if (isError) {
        callback && callback(null, true);
        return;
      }
      fabric.Image.prototype._initFilters.call(
        object,
        object.filters,
        function (filters) {
          object.filters = filters || [];
          fabric.Image.prototype._initFilters.call(
            object,
            [object.resizeFilter],
            function (resizeFilters) {
              object.resizeFilter = resizeFilters[0];
              fabric.util.enlivenObjects(
                [object.clipPath],
                function (enlivedProps) {
                  object.clipPath = enlivedProps[0];
                  var image = new fabric.Image(img, object);
                  callback(image, false);
                }
              );
            }
          );
        }
      );
    },
    null,
    object.crossOrigin
  );
};

fabric.Video.fromURL = function (url, callback, imgOptions) {
  fabric.util.loadVideo(
    url,
    function (img, isError) {
      callback && callback(new fabric.Video(img, imgOptions), isError);
    },
    null,
    imgOptions && imgOptions.crossOrigin
  );
};
fabric.Video.async = true;

// -----------------------------------

// --------------------------------

fabric.Video.fromObjectCustom = function (object, callback) {
  let _videoElm = document.getElementById(object.id);
  if (_videoElm) {
    fabric.Image.prototype._initFilters.call(
      object,
      object.filters,
      function (filters) {
        object.filters = filters || [];
        fabric.Image.prototype._initFilters.call(
          object,
          [object.resizeFilter],
          function (resizeFilters) {
            object.resizeFilter = resizeFilters[0];
            fabric.util.enlivenObjects(
              [object.clipPath],
              function (enlivedProps) {
                object.clipPath = enlivedProps[0];
                var image = new fabric.Image(_videoElm, object);
                callback(image, false);
              }
            );
          }
        );
      }
    );
  } else {
    callback && callback(null, true);
    return;
  }
};

const _loadFabricJson = async (jsonData, canvas) => {
  return new Promise((resolve, reject) => {
    canvas.loadFromJSON(JSON.stringify(jsonData), (a) => {
      canvas.renderAll();
      resolve(canvas);
    });
  });
};

export const loadFabricJson = async (jsonData, canvas, timeout = 2000) => {
  let ret = new Promise(async (resolve, reject) => {
    setTimeout(() => {
      if (!ret.isResolved) {
        resolve(canvas);
      }
    }, timeout);

    await _loadFabricJson(jsonData, canvas);
    resolve(canvas);
  });
  return ret;
};

fabric.util.enlivenObjects = function (objects, callback, namespace, reviver) {
  objects = objects || [];

  var enlivenedObjects = [],
    numLoadedObjects = 0,
    numTotalObjects = objects.length;

  function onLoaded() {
    if (++numLoadedObjects === numTotalObjects) {
      // console.log(enlivenedObjects, numLoadedObjects, numTotalObjects);
      callback &&
        callback(
          enlivenedObjects.filter(function (obj) {
            // filter out undefined objects (objects that gave error)
            return obj;
          })
        );
    }
  }

  if (!numTotalObjects) {
    callback && callback(enlivenedObjects);
    return;
  }

  objects.forEach(function (o, index) {
    // if sparse array
    if (!o || !o.type) {
      onLoaded();
      return;
    }

    if (o._Type === "video" && document.editorType === "video") {
      fabric.Video.fromObjectCustom(o, function (obj, error) {
        obj.set({
          objectCaching: true,
          statefullCache: true,
          cacheProperties: ["videoTime"],
        });

        obj._originalElement._fabObj = obj;

        error || (enlivenedObjects[index] = obj);
        reviver && reviver(o, obj, error);
        onLoaded();
      });
    } else {
      var klass = fabric.util.getKlass(o.type, namespace);
      klass.fromObject(o, function (obj, error) {
        error || (enlivenedObjects[index] = obj);
        reviver && reviver(o, obj, error);
        onLoaded();
      });
    }
  });
};
