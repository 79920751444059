import DragUpload from "../Atom/DragUpload";
import ImageScroll from "./ImageScroll";
import { useCallback } from "react";
import axios from "axios";
import { fetcher, token, url } from "../../../utils";
import store from "../../../store/store";
import SelectOption from "../Atom/SelectOption";
import uuid from "uuid-random";
import Model from "reactjs-popup";
import Popup from "../../../App/Components/Popup";
import Button from "../../../App/Components/Button";
import { useState } from "react";
import { DeletePopup } from "../../TimeLine/Components/SceneBar/DeletePopup";

function UploadScroll({ onSelect, onPreview }) {
  const handleSelect = (e) => {
    onSelect({ ...e, type: "upload" });
  };
  const handleDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      const { name, size } = acceptedFiles[0];
      let formData = new FormData();
      formData.append("name", name);
      formData.append("category", "autovid_image");
      formData.append("media_file", acceptedFiles[0]);
      let id = uuid();
      document.FeedBack.pushList({ id, item: { name, size } });
      if (size <= 100 * 2 ** 20)
        axios
          .post(`${url}/api/userlibrary/upload/`, formData, {
            headers: { Authorization: `Token ${token}` },
            onUploadProgress: (e) => {
              // console.log((e.loaded / e.total) * 100);
              document.FeedBack.updatePrgress({
                id,
                progress: (e.loaded / e.total) * 100,
              });
            },
          })
          .then((res) => {
            let obj = res.data;

            store.dispatch({
              type: "MEDIA_WITH_FRONT",
              data: { type: "upload", list: [obj] },
            });
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }, []);

  return (
    <>
      <DragUpload
        text="Drag & Drop your media here!"
        onDrop={handleDrop}
        accept=".png,.jpeg,.jpg,.webp"
      />
      <ImageScroll
        upFunction={UploadOption}
        isTitle={true}
        type="upload"
        onSelect={handleSelect}
        onPreview={onPreview}
        height="72vh"
      // handler={}
      />
    </>
  );
}

export const UploadOption = ({
  id,
  rename,
  del,
  src = `${document.PUBLIC_URL}/ico/fileOption.svg`,
  type = "upload",
  setActive = () => { },
  imgSrc,
  vidSrc = null,
  text = null,
  ctype
}) => {
  const [isCon, setConf] = useState(false);
  const handleSelect = (a, b) => {
    if (b === 1) {
      // console.log(del());
      setConf(true);
    }
    if (b === 2) {
      rename(true);
    }
  };

  return (
    <div className="UploadOption">
      <SelectOption
        ele={<img className="UploadOption_img" alt="option" src={src} />}
        list={[
          {
            name: (
              <Model
                modal
                trigger={<button className="BadButton">Preview</button>}
              >
                <div className="HoverImageCard_model">
                  {(vidSrc && <video

                    autoPlay
                    muted
                    controls
                    controlsList="nodownload"
                    src={vidSrc} alt="mone"
                  ></video>) || (
                      <img src={imgSrc} alt="mone" />
                    )}
                </div>
              </Model>
            ),
            src: `${document.PUBLIC_URL}/app/r_eye.svg`,
          },
          {
            name: "Delete",
            src: `${document.PUBLIC_URL}/ico/deleteFile.svg`,
          },
          { name: "Rename", src: `${document.PUBLIC_URL}/ico/renameFile.svg` },
        ]}
        onSelect={handleSelect}
        onShow={(e) => setActive(e)}
      />
      <DeletePopup
        text={text}
        isConfirm={isCon}
        setConfirm={setConf}
        onDelete={() => {
          fetcher({
            method: "DELETE",
            url: `${url}/api/userlibrary/upload/${id}/`,
            res: (data) => {
              store.dispatch({
                type: "DELETE_MEDIA",
                data: {
                  type,
                  id,
                },
              });
            },
            err: (err) => {
              //console.log(err);
            },
          });
        }}
      />
    </div>
  );
};

export default UploadScroll;
