/* eslint-disable */
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
// import { url, token } from "../../../config/config";
import { ReactComponent as Dot } from "../../../extras/dot.svg";
import axios from "axios";
import { ReactComponent as VideoC } from "../../../extras/account/videoC.svg";
import { ReactComponent as Cross } from "../../../extras/account/cross.svg";
import { ReactComponent as CreditSubs } from "../../../extras/account/creditC.svg";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import Loadingg from "../../common/loading";
import { fetcher, url } from "../../../../utils";
import { CREDIT_TYPE_MAPPING } from "../Subscription";

export default function EditProfile(props) {
	const [Loading, setLoading] = useState(true);
	const [Credit, setCredit] = useState({ data: [] })
	const reloadData = async () => {
		// const Token = token();

		// var options = {
		//     headers: { Authorization: `Token ${Token}` }

		// };
		// var cat = category[IndexSelected]

		// var res=await  axios.get(`${url}/api/videocredit/?limit=10`, options)
		// console.log(res);
		// setCredit({data:data,next:res.data.next})
		// console.log(data);
		fetcher({
			url: `${url}/api/subscription/details/?limit=50`,
			res: res => {
				setCredit({ data: [...Credit.data, ...res.data.results], next: res.data.next })

			}
		})

		setLoading(false)
	}

	useEffect(() => {
		reloadData()

	}, [])

	const HandleInfiniteScroll = async () => {
		// const Token = token();

		// var options = {
		//     headers: { Authorization: `Token ${Token}` }

		// };
		// var res=await  axios.get(Credit.next, options)

		fetcher({
			url: Credit.next,
			res: (res) => {

				// const data = res.data.results
				setCredit({ data: [...Credit.data, ...res.data.results], next: res.data.next })
			}
		})
	}


	return (
		<Popup
			trigger={props.children}
			modal
			nested
			onOpen={async () => {

			}}
		>
			{(close) => (
				<div className="modal-dialog modal-dialog-centered  analytic-detail-popup-video CAT ProfilePopupEdit passwordChangePopup subs-cred-hiss-pop modal-dialog-scrollable">
					<div className="modal-content vd-modal-content">
						<div className="modal-header vd-modal-header padding-40-popup">
							<div className="header-analyticp-poopup">
								<h5 className="modal-title-analutics">
									Credit Use History&nbsp;&nbsp;{" "}

								</h5>

								<div className="sub-heading-analytic-video-popup">
									<label htmlFor="" className="analytic-views-headinc887">
										{props.used} Used out of {props.out}
									</label>
									<Dot />
									<label className="label-time-analytic-popup">{moment(props.date).format(
										"MMM, YYYY"
									)}</label>
								</div>
							</div>

							<div className="header-popup-analytics-rightside">

								<Cross className="pointer" onClick={() => {
									close();
								}} />
							</div>
						</div>
						<div className="modal-body modal-analytic-popup padding-40-popup pt-0" id="tabs-in-gp-solo-popup-try">
							<div className="chart-container">
								{Loading ? (
									<Loadingg />
								) : (
									<div className="cat-list-container">
										<div className="profile-edit-popup-wrapper">

											<div className="credit-list-container">

												<InfiniteScroll
													dataLength={
														Credit.data?.length || 0
													}
													next={() => {
														HandleInfiniteScroll();
													}}
													hasMore={
														Credit.next == null
															? false
															: true
													}
													loader={<h4></h4>}
													scrollableTarget="tabs-in-gp-solo-popup-try"
												>
													{Credit.data.map((d, k) => {
														let _name = d.meta["name"];

														if (d.creditType === 3) {
															_name = d.meta["pageName"]
														} else if (d.creditType === 4) {
															_name = d.meta["thumbnailName"]
														}
														return (
															<div key={k} className="credit-list-item">
																{true ? <VideoC /> : <CreditSubs />}<div className="credit-list-inneritem">
																	<div className="name-in-credits-list"> <span className="bold-incredits">{Math.round(d.usedCredit)} credit</span> used in <span className="bold-incredits">{CREDIT_TYPE_MAPPING[d.creditType]} </span>“{_name}”</div> <label htmlFor="" className="time-in-credit-his">
																		{moment(d.timestamp).format("h:mm A, DD MMM")}
																	</label>
																</div>
															</div>
														)
													})}

												</InfiniteScroll>

											</div>



										</div>
									</div>
								)}
							</div>
						</div>


					</div>
				</div>
			)}
		</Popup>
	);
}
