import { Index } from "../utils";
import { gsap } from "gsap";

// if (document?.cxx?.[Index()]) {
const cxxNow = () => document.cxx[Index()]?.getActiveObject();
const cxxObjects = () => document.cxx[Index()]?._objects;
window.cxxNow = cxxNow;
document.cxxNow = cxxNow;
window.cxxObjects = cxxObjects;
// }

window.temp = () => {
  let _sAnimation = gsap.timeline({ paused: true });
  let _prevObj = document.ccx._objects[0];
  let _nextObj = document.ccx._objects[1];
  _prevObj.opacity = 1;
  _nextObj.opacity = 1;
  document.ccx.renderAll();
  _sAnimation.fromTo(_prevObj, { opacity: 1 }, { opacity: 0, duration: 5 }, 0);
  _sAnimation.fromTo(_nextObj, { opacity: 0 }, { opacity: 1, duration: 5 }, 0);
  _sAnimation.play();
};
