import React from 'react';
import { useEffect, useState } from 'react';
import Model from 'reactjs-popup'
import EditSelect from '../../../components/Chemistry/Atom/EditSelect';
import SelectOption from '../../../components/Chemistry/Atom/SelectOption';
import Popup from '../Popup';
import "./_KK.scss"
import Papa from "papaparse"
import { connect } from "react-redux"
import Button from '../Button';
import { fetcher, fetcherAsync, url, HideAndShowHandler } from '../../../utils';
import ErrorPopup from './ErrorPopup';
import GeneratePopup from './GeneratePopup';

function MapPopup({ trigger, onOpenHide, onCloseShow, allMergeTag = [], media = {}, my, closeMePlease }) {
    let [col, setCol] = useState([])
    let [load, setLoad] = useState(true);
    let [open, setOpen] = useState(false)
    let [map, setMap] = useState({ [`{{email}}_email`]: "" })
    useEffect(() => {
        if (open) {

            console.log(media);
            Papa.parse(media.media_thumbnail, {
                worker: true,
                header: true,
                download: true,
                complete: (res) => {
                    setCol(res.meta.fields)
                    console.log(res);
                    setLoad(false)
                }

            })
        } else {
            setCol([])
            setLoad(true)
        }

    }, [open])

    const [err, setErr] = useState({});
    const [errOpen, setErrOpen] = useState(false)
    const [genOpen, setGenOpen] = useState(false)
    const [CsvPayload, setCsvPayload] = useState({})
    const [NoError, setNoError] = useState(false)

    const handleValidate = () => {

        fetcher({
            method: "POST",
            url: `${url}/api/newvideo/video/batch/validate/${my.id}/`,
            res: (res) => {
                const { isError, result } = res.data;
                // setLoad(false);
                // console.log(res);
                if (isError) {
                    setErrOpen(true)
                    setErr(res.data)

                    setCsvPayload({ ...res.data })
                    setGenOpen(false)

                    // alert("SOMETING ERROR in TAG");
                } else {
                    setCsvPayload({ ...res.data })
                    setNoError(true)
                    setErr(res.data)
                    setErrOpen(true)

                }
            },
            err: (e) => {
                console.log(e);
            },
            data: {
                mergeTagMap: map,
                csvFile: media.id
            },
        });
    }
    return <Model onClose={() => {
        onCloseShow()
        setMap({ [`{{email}}_email`]: "" })
        setOpen(false)


        // console.log("called in map popup");
        // closeMePlease()
    }} onOpen={() => {
        setOpen(true)
        onOpenHide()
    }} modal trigger={trigger} >
        {close => (<Popup close={() => {
            close()
            closeMePlease()
        }} UpperId={"matchpopup-1x1"} name={"Match Column"} footer={<Button className={`${Object.keys(map).length === [[`{{email}}`, `email`], ...allMergeTag].length ? "" : "Disabled-button-generate-invarPopup"}`} onClick={handleValidate} value="Validate"></Button>} >
            {load && <div className="INFINITELOAD">
                <img alt="Loading" src={`${document.PUBLIC_URL}/icons/loading.gif`} />
            </div>}

            {!load && <div className='MapPopup flex column'>
                <ErrorPopup
                    onOpenHide={() => {
                        HideAndShowHandler("matchpopup-1x1", "none")
                    }}
                    onCloseShow={() => {
                        // HideAndShowHandler("VariablePopup-1-1x", "block")

                    }}
                    closeMePlease={(d) => {
                        close()
                        console.log("called inerror");
                        closeMePlease()
                    }} csvChangeErrorClose={() => {
                        close()
                        HideAndShowHandler("VariablePopup-1-1x", "block")
                        // csvChangeErrorClose()
                        // closeMePlease()
                    }} noError={NoError} csvPayload={{ id: CsvPayload.id }} open={errOpen} onGenerate={setGenOpen} setOpen={setErrOpen} err={err} />

                <div className='MapPopup_head'></div>
                <div className="MapPopup_body flex column">
                    {
                        [[`{{email}}`, `email`], ...allMergeTag].map(e => {
                            return (<div className='MapPopup_body_row flex row'>
                                <div className='MapPopup_body_row_merge'>{e[0]}</div>
                                <EditSelect disabled selected={"Select a Column"} onSelect={(k) => {
                                    setMap(p => ({ ...p, [`${e[0]}_${e[1]}`]: k }))
                                }} list={col} />
                            </div>)
                        })
                    }


                </div>
            </div>}
            {!load && <div className='MapPopup flex column'>


                <GeneratePopup csvPayload={{ id: CsvPayload.id }} open={genOpen}
                    onOpenHide={() => {
                        HideAndShowHandler("errorPopup-1x1", "none")
                    }}
                    onCloseShow={() => {
                        HideAndShowHandler("errorPopup-1x1", "block")

                    }}
                    onOpenCus={(d) => {
                        setGenOpen(d)
                        closeMePlease()

                    }}
                >

                </GeneratePopup>


            </div>}
        </Popup>)}
    </Model>;
}

export default connect(state => ({ allMergeTag: state.mydetail.allMergeTag, my: state.mydetail }))(MapPopup)
