/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Rnd } from "react-rnd";
import { useLocation } from "react-router";
import ButtonIcon from "../../../../../Chemistry/Atom/ButtonIcon";
import EditSelect from "../../../../../Chemistry/Atom/EditSelect";
import SelectOption from "../../../../../Chemistry/Atom/SelectOption";
import TitleRow from "../../../../../Chemistry/Atom/TitleRow";
import TitleWrapper from "../../../../../Chemistry/Atom/TitleWrapper";
import Vr from "../../../../../Chemistry/Atom/Vr";
import LineText from "../../../../../Chemistry/One/LineText";
import {
  ControlsBackground,
  InputRangeWithTitle,
} from "../Background/BackgroundSettings";
import "./_MusicSettings.scss";
import { Vid, Index, getById } from "../../../../../../utils";
import store from "../../../../../../store/store";
import { fancyTimeFormat } from "../../../../../Chemistry/Atom/MusicCard";
import {
  addMusicToAllCxx,
  customVideoSeek,
  removeMusicToAllCxx,
} from "../../../../../../utils/videoPlayThings";
import { deleteFromAll } from "../../../../../../utils/deleteCloneOpeationObj";

export default connect(
  (state) => ({ elements: state.scenes[Index()].elements }),
  (dispatch) => ({
    deleteIt: ({ id, type }) =>
      dispatch({ type: "DELETE_OBJECT", data: { id, type } }),
  })
)(function MusicSettings({ deleteIt, elements }) {
  const { state } = useLocation();
  const [ele, setEle] = useState(elements[state.id]);
  const getMusicListIndex = () => {
    const adjustMapping = { 0: 0, 1: 0, 4: 1 }
    if (ele?.adjustLength) {
      return adjustMapping[ele.adjustLength];
    } else {
      return 1;
    }
  }

  const [st, setState] = useState({
    perSec: 3,
    from: 0,
  });
  useEffect(() => {
    if (elements[state.id]) {
      const {
        duration,

        trimStart,
        trimEnd,
        adjustLength,
      } = elements[state.id];

      let cW =
        document.querySelector(".MusicSettings_bound")?.clientWidth || 331;
      let perSec = cW / duration;
      setState((s) => ({
        ...s,
        from: trimStart,
        width: (trimEnd - trimStart) * perSec,
        perSec,
        adjustLength,
      }));
    }
  }, [ele]);


  const handleDrag = (e, data) => {
    let vidEle = document.getElementById(state.id);
    setState((s) => {
      let from = data.x / s.perSec;
      store.dispatch({
        type: "EDIT_ELEMENT",
        data: {
          id: state.id,

          data: {
            trimStart: from,
            trimEnd: from + s.width / s.perSec,
          },
        },
      });
      vidEle.customVideoSeek(from);

      return { ...s, from };
    });
  };
  const handleResize = (a, b, c, d) => {
    let vidEle = document.getElementById(state.id);
    let p = d.width;
    let k = p;
    if (b === "left") {
      p = -1 * p;
      k = p;
    } else {
      p = -1 * p;
      // k = -1 * p;
      k = 0;
    }

    setState((s) => {

      let width = s.width - p;

      let data = {};

      store.dispatch({
        type: "EDIT_ELEMENT",
        data: {
          id: state.id,
          data: {
            trimStart: st.from + k / s.perSec,
            trimEnd: st.from + k / s.perSec + width / s.perSec,
          },
        },
      });

      vidEle.customVideoSeek(st.from);

      return {
        ...s,
        width,
        from: st.from + k / s.perSec,
      };
    });
  };

  // const [vol, setVol] = useState(getById(state.id)._Volume);
  const handleOnVolume = (e) => {
    document.getElementById(state.id).volume = parseFloat(e / 100);

    getById(state.id)._Volume = e / 100;
  };
  const handleSetVolume = () => {
    store.dispatch({
      type: "EDIT_ELEMENT",
      data: {
        id: state.id,
        data: {
          volume: getById(state.id)._Volume,
        },
      },
    });
  };

  const handleType = (e, b) => {
    let _data = {};
    let obj = getById(state.id);
    const _musicElm = store.getState().scenes[Index()].elements[state.id];
    let flag = true;

    if (b == 0) {
      // end at the scene
      delete document.musicLoop[state.id];
      if (_musicElm.adjustLength === 1) {
        flag = false;
        return;
      }
      _data = { adjustLength: 1 };
    } else if (b == 1) {
      // loop in all scene
      if (_musicElm.adjustLength === 4) {
        flag = false;
        return;
      }

      document.musicLoop[state.id] = getById(state.id);
      obj._Index = Index();
      _data = { adjustLength: 4 };
    }


    if (flag) {
      store.dispatch({
        type: "EDIT_ELEMENT",
        data: {
          id: state.id,
          data: _data,

        },
      });
      if (b === 1) {
        addMusicToAllCxx({ id: state.id, index: Index() });
      } else {
        removeMusicToAllCxx({ id: state.id, index: Index() });
      }
    }
  };

  const trimHandle = (e) => {
    let vidEle = e.target;
    if (!vidEle.paused) {
      if (!vidEle.customVideoSeek) {
        vidEle.customVideoSeek = customVideoSeek;
      }
      let _ele = store?.getState()?.scenes?.[Index()]?.elements?.[state.id];
      if (_ele) {
        let time = vidEle.currentTime;
        if (_ele.trimStart > time) {
          vidEle.customVideoSeek(_ele.trimStart);
        }
        if (_ele.trimEnd < time) {
          vidEle.pause();
          vidEle.customVideoSeek(_ele.trimStart);
        }

      }
    }
  };

  const [play, setPlay] = useState(undefined);
  const handlePlay = () => {
    let id = state.id;
    let aud = document.getElementById("_trimAudio");
    if (aud) {
      aud.volume = getById(state.id)._Volume
      if (!aud.customVideoSeek) {
        aud.customVideoSeek = customVideoSeek;
      }
      if (play === undefined) {
        const _musicElm = store.getState().scenes[Index()].elements[state.id];
        // initial start from trim
        if (_musicElm) {
          aud.customVideoSeek(_musicElm.trimStart)
        }
        aud.play()
        setPlay(true)
      } else if (aud.paused) {
        aud.play();
        setPlay(true);
      } else {
        aud.pause();
        setPlay(false);
      }
      aud.onpause = () => {
        setPlay(false);
      };
    }
  };

  return (
    <div className="MusicSettings">
      <TitleRow title="Music Settings" />
      <ControlsBackground
        text="Replace Music"
        _2_src={null}
        on_3={() => {
          const _musicElm = store.getState().scenes[Index()].elements[state.id];
          let obj = getById(state.id, null, document.cxx[Index()]);
          if (obj) {
            if (_musicElm.adjustLength === 1) {
              document.cxx[Index()]?.discardActiveObject();
              deleteFromAll(state.id, true);
            } else {
              document.cxx[Index()]?.discardActiveObject();
              removeMusicToAllCxx({ id: state.id, index: -1 });
              document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/${document._TabName}`);
            }
          }
        }}
        on_1={() => {
          // let p = getById(state.id);
          // let cxx = p.canvas;
          // cxx.remove(p);
          // cxx.fire("object:modified");

          // cxx.renderAll();
          // deleteIt({ type: "music", id: state.id, replace: true });
          document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/music`, { id: state.id });
        }}
      />
      <Vr m="15px" />
      <div className="MusicSettings_loop">
        loop{" "}
        <EditSelect
          selectedIndex={getMusicListIndex()}
          disabled={true}
          selected={null}
          list={["End at this scene", "Loop in all scenes"]}
          onSelect={handleType}
        />
      </div>

      <Vr m="15px" />
      <TitleWrapper title="Volume">
        <InputRangeWithTitle
          value={Number(Number(getById(state.id)._Volume * 100).toFixed(0))}
          className="ImagePanel_range"
          onFullChange={handleSetVolume}
          min={0}
          max={100}
          onChange={handleOnVolume}
        />
      </TitleWrapper>

      <TitleWrapper title="Trim">
        <div className="MusicSettings_bound">
          <Rnd
            bounds=".MusicSettings_bound"
            // size={{ height: 32 }}
            size={{ height: 32, width: st.width || 331 }}
            position={{ x: st.from * st.perSec, y: 0 }}
            onResizeStop={handleResize}
            onDragStop={handleDrag}
            // position={{ x: 0, y: 0 }}
            // minWidth={100}
            className="MusicSettings_bound_drag"
            // dragAxis="x"
            enableResizing={{ left: true, right: true }}
          ></Rnd>
        </div>
      </TitleWrapper>
      <div className="MusicSettings_play">
        <audio
          id="_trimAudio"
          onTimeUpdate={trimHandle}
          src={document.getElementById(state.id).src}
          style={{ display: "none" }}
        >

        </audio>
        <ButtonIcon
          src={!play ? `${document.PUBLIC_URL}/ico/playScene.svg` : `${document.PUBLIC_URL}/ico/pause.svg`}
          onClick={handlePlay}
          value=" "
        />
        <div className="MusicSettings_play">
          <LineText title="From" value={fancyTimeFormat(st.from)} />
          <LineText
            title="To"
            value={fancyTimeFormat(
              st.from + st.width / st.perSec ||
              getById(state.id)._Music.fileInfo.duration
            )}
          />
        </div>
      </div>

      <Vr m="15px" />
    </div>
  );
});
