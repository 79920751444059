import { fabric } from "fabric";

export default function initTextForInitAdd({ obj }) {
  if (obj._Type === "text") {
    obj.on("editing:entered", () => {
      obj.hasControls = true;
      obj.set({
        borderColor: "#e7416a",
        _Editable: true,
      });
      if (obj._DefaultText === obj.text) obj.selectAll();
      obj.controls.customMove = fabric.controlsUtils.customMove;
      obj.canvas.renderAll();
    });

    obj.on("editing:exited", () => {
      obj.set({
        _Editable: false,
        selectionColor: "rgba(17,119,255,0.3)",
      });
      delete obj.controls.customMove;
    });

    obj.on("mouseup", () => {
      if (obj._isCustomMove) {
        obj._isCustomMove = false;
        obj.enterEditing();
      }
    });
  }
}
