/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import TitleRow from "../../../../../Chemistry/Atom/TitleRow";
import "./_ElementSettings.scss";
import {
  ControlsBackground,
  InputRangeWithTitle,
} from "../Background/BackgroundSettings";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { getById, Vid } from "../../../../../../utils";
import TitleWrapper from "../../../../../Chemistry/Atom/TitleWrapper";
import { OrderRow } from "../../../Avatar/Avatar";
import Vr from "../../../../../Chemistry/Atom/Vr";
import Dimensions from "../../../../../Chemistry/Atom/Dimensions";
import ArrowWrapper from "../../../../../Chemistry/Atom/ArrowWrapper";
// import { useState } from "react/cjs/react.development";
import { Link } from "react-router-dom";
import { Index } from "../../../../../../utils";
import Color from "../../../../../Chemistry/Atom/Color";
import Angle from "../../../../../Chemistry/Atom/Angle";
import { deleteFromAll } from "../../../../../../utils/deleteCloneOpeationObj";
import Animation from "../../../../../Chemistry/Compound/Animation";
import ButtonIcon from "../../../../../Chemistry/Atom/ButtonIcon";
import objectCloner from "../../../../../../utils/objectCloner";
import { ColorToggle } from "../Text/TextSettings";
import { storeAnimatationToStore } from "../../../../../../utils/shortcutFunction";
import Shadow from "../../../../../Chemistry/One/Shadow";
import { DelaySettingsInput } from "../../../../../Chemistry/One/DelaySettingsInput";
import { connect } from "react-redux";

export default function ElementSetting() {
  const { url } = useRouteMatch();
  const { state } = useLocation();

  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(false)
    setTimeout(() => {
      setShow(true)
    }, 0)
  }, [state?.id])
  return (
    <Switch>
      <Route path={`${url}/color`}>
        <ColorPage />
      </Route>
      {document.editorType === "video" && <Route path={`${url}/animation`}>
        <Animation
          type="animation"
          title="< Animation"
          obj={getById(state.id)}
          onSelect={storeAnimatationToStore(state.id)}
        />
      </Route>}
      <Route path={url + "/shadow"}>
        <Shadow id={state.id} />
      </Route>
      <Route path={url}>
        {show && <DefaulPage obj={getById(state.id)} />}
      </Route>
    </Switch>
  );
}

const DefaulPage = connect(s => ({
  objActive: s.active.objActive

}))(({ obj, objActive }) => {

  const { state } = useLocation();
  const { url } = useRouteMatch();

  const [colorList, setList] = useState([]);
  const handleChange = (e) => {
    const { cxx } = document;
    let obj = getById(state.id);
    if (obj) {
      obj.set({ opacity: e / 100 });
      obj.canvas.renderAll();
    }
  };

  useEffect(() => {
    setList(getById(state.id)._CurrentColor);
  }, []);

  const handleDelete = () => {
    deleteFromAll(state.id);
  };
  const handleClone = () => {
    objectCloner({ _id: state.id, from: Index(), clone: true });
  };
  const handlestrokeWidthChange = (e) => {
    obj.set({ strokeWidth: e });
    obj.canvas.renderAll();
  };

  const handleColorPush = (e) => {
    return () => {
      document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/color`, e);
    };
  };

  const handleReplace = (e) => {
    let _routeP = obj._Data.type;
    if (_routeP === 'elements') {
      _routeP = 'illustrations';
    }
    document.hist.push(
      `/${document.editorType}/${Vid()}/${Index()}/elements/${_routeP}`,
      { id: obj.id, replace: true }
    );
  };

  function capitalizeFirstLetter(string) {
    if (string === "elements") return "Illustration"
    return string.charAt(0).toUpperCase() + string.slice(1);

  }
  return (
    <>
      <div className="ElementSetting">
        <TitleRow title={`${capitalizeFirstLetter(obj._Data.type)} Settings`} />
        <ControlsBackground
          text={`Replace ${obj._Data.type}`}
          _2_src={`${document.PUBLIC_URL}/ico/clone.svg`}
          on_1={handleReplace}
          on_3={handleDelete}
          on_2={handleClone}
        />
        <Vr m="20px" />
        <TitleWrapper title="Arrange">
          <OrderRow id={state.id} />
        </TitleWrapper>
        <Vr m="0px" />


        {document.editorType === "video" && <>
          <ArrowWrapper
            m="15px"
            p="10px 0px"
            to={`${url}/animation`}
            data={{ id: state.id }}
            ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/animation.svg`} />}
          >
            Animation
          </ArrowWrapper>
          <Vr m="0px" />
        </>}

        <ArrowWrapper
          m="15px"
          p="10px 0px"
          to={`${url}/shadow`}
          data={{ id: state.id }}
          ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/animation.svg`} />}
        >
          Shadow
        </ArrowWrapper>

        {/* <vr m="10px" /> */}

        <Vr m="0px 0 10px" />
        {obj._Data.type === "elements" && (
          <>
            <ArrowWrapper
              m="0"
              to={`/${document.editorType}/${Vid()}/${Index()}/settings/elements/color`}
              data={{ id: state.id }}
            >
              <ColorTabList list={colorList} id={state.id} />
            </ArrowWrapper>
            <Vr m="0px 0 20px" />
          </>

        )}

        {(obj._Data.type === "shapes" || obj._Data.type === "icons") && (
          <>
            <div className="flex jbetween" style={{ marginRight: "20px" }}>
              <TabColor
                title="Fill Color"
                property={"fill"}
                style={{ width: "70px" }}
                obj={obj}
                _img={obj.fill === "#ffffffff"}
                _color={obj.fill}
                onClick={handleColorPush({
                  id: obj.id,
                  title: "Fill Color",
                  property: "fill",
                })}
              />

              {obj._Data.type !== "icons" && (

                <TabColor
                  title="Border Color"
                  property={"stroke"}
                  obj={obj}
                  _color={obj.stroke}
                  _img={obj.stroke === "#ffffffff"}
                  onClick={handleColorPush({
                    id: obj.id,
                    title: "Border Color",
                    property: "stroke",
                  })}
                />)}



              {/* <TabColor title="Border Color" color="hotpink" /> */}
            </div>
            <Vr m="10px" />

          </>
        )}


        <TitleWrapper title="Opacity">
          <InputRangeWithTitle
            value={Math.round(getById(objActive)?.opacity * 100) || 100}
            className="ImagePanel_range"
            // onFullChange={(e) => alert(e.target.value)}
            onChange={handleChange}
            objActive={objActive}
          />
        </TitleWrapper>
        {obj._Data.type === "shapes" && (


          <TitleWrapper title="Border Width">
            <InputRangeWithTitle
              value={obj.strokeWidth || 0}
              className="ImagePanel_range"
              postfix={"px"}
              // onFullChange={(e) => alert(e.target.value)}
              onChange={handlestrokeWidthChange}
              objActive={objActive}

            />
          </TitleWrapper>

        )}
        <Vr m="0px" />


        <TitleWrapper title="Layout">
          <Dimensions id={state.id} ele={<Angle id={state.id} />} />
        </TitleWrapper>
        <Vr m="0px 0 20px" />

      </div>
      <DelaySettingsInput id={state.id} />
    </>
  );
})

export const TabColor = ({ title, property, obj, _color, onClick, _img, style = {} }) => {
  const [color, setColor] = useState(_color);
  const handleColor = (_c) => {
    obj.set({ [property]: _c });
    obj.canvas.renderAll();
    setColor(_c);
  };
  useEffect(() => {
    setColor(_color);
  }, [_color]);

  return (
    <div className="TabColor">
      <div className="TabColor_title" style={style}>{title}</div>
      {/* <ColorToggle onChange={handleColor}> */}
      <div
        onClick={onClick}
        className="TabColor_color"
        style={{ background: color }}
      >
        {_img && <img src={`${document.PUBLIC_URL}/ico4/colorTransparent.svg`} ></img>}
      </div>
      {/* </ColorToggle> */}
    </div>
  );
};

const ColorTabList = ({ list, id }) => {
  const hist = useHistory();
  return (
    <div className="ColorTabList">
      <div className="ColorTabList_title">Color</div>
      {list.map((e, i) => {
        return (
          <div
            className="ColorTabList_color"
            style={{ background: e }}
            onClick={(e) => {
              e.stopPropagation();
              hist.push(`elements/color`, { index: i, id });
            }}
          ></div>
        );
      })}
    </div>
  );
};

const ColorTab = ({ list = [], onChange = () => { }, index = 0 }) => {
  const [_index, setIndex] = useState(index);
  const handColor = useCallback(
    (c) => {
      ////console.log(_index, "index");
      onChange(c, _index);
    },
    [_index, onChange]
  );
  useEffect(() => {
    document.elementColorIndex = index;
  }, []);

  return (
    <div className="ColorTab">
      <div className="ColorTab_tab">
        {list.map((e, i) => (
          <div
            style={{ background: e }}
            className={`ColorTab_tab_color ${_index === i ? "ColorTab_tab_color_active" : ""
              }`}
            onClick={() => {
              setIndex(i);
              document.elementColorIndex = i;
              ////console.log(i);
            }}
          ></div>
        ))}
      </div>
      <Color color={list[_index]} onChange={handColor} />
    </div>
  );
};

const ColorPage = ({ }) => {
  const hist = useHistory();
  const { state } = useLocation();
  const [list, setList] = useState([]);

  useEffect(() => {
    const obj = getById(state.id);
    setList(obj._CurrentColor);
  }, []);
  const handleChange = (color, index) => {
    const obj = getById(state.id);
    //console.log(obj, obj._CurrentColor, document.elementColorIndex, color);
    let _tm = obj._CurrentColor.map((e) => e);
    _tm[document.elementColorIndex] = color;
    obj._CurrentColor = _tm;
    obj.setColor(document.elementColorIndex, color);
    setList(obj._CurrentColor);
  };
  return (
    <div className="ColorPage">
      <TitleRow
        title={"< Illustration Color"}
        onClick={() => {
          hist.goBack();
        }}
        cursor="pointer"
      />
      <Vr m="10px 0 20px" />
      <ColorTab list={list} index={state.index} onChange={handleChange} />
    </div>
  );
};
