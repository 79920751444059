import "./_Atom.scss";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { discardCurrentWork } from "../../../utils/useFullFunctions/discardWork";

function ArrowWrapper({
  m,
  data = {},
  p,
  onClick = () => { },
  to,
  children,
  ele,
  className,
}) {
  const hist = useHistory();
  return (
    <div
      onClick={(e) => {
        discardCurrentWork({ _goB: true });
        hist.push(to, data);
        onClick();
      }}
      className={`ArrowWrapper ${className}`}
      style={{ padding: p || "0" }}
    >
      {ele}
      <div style={{ marginLeft: m || "20px" }} className="Content">
        {children}
      </div>
      <img src={`${document.PUBLIC_URL}/icons/ArrowRight.svg`} className="Arrow" alt={"ArrowRight"} />
    </div>
  );
}

export default ArrowWrapper;
