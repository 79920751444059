import { UpdateBackground } from "../../../../Chemistry/One/Draggy";
import store from "../../../../../store/store";
import {
  defaultDurationLengthInSec,
  getById,
  Index,
} from "../../../../../utils";
import { MIN_ON_NON_AVATAR } from "../../../../../utils/config";

const mixedAvatar = {
  //default
  hideMe: function ({ index, what, cb = () => {} }) {
    let obj = getById(`avatar${index}`);
    if (obj) {
      obj._Hidden = what;
      obj._Voice = what;
      obj.set({ visible: !what });

      // obj.canvas
      document.cxx[index].fire("object:modified");
      cb(obj);
      setTimeout(() => {
        // document.smallScene.make(index);
        // document.smallScene.update(index);
        document.smallScene.draw(index);
      }, 100);
    }
    // store.dispatch({
    //   type: "EDIT_URL_NEW_MERGE_TEXTBOX",
    //   data: {
    //     id: `avatar${index}`,
    //     onlyDuration: true,
    //     i: index,
    //     extraIndex: index,
    //     haveDefault: true,
    //     data: {
    //       [index]: {
    //         combineData: {
    //           soundDuration: defaultDurationLengthInSec,
    //         },
    //       },
    //       results: [],

    //       // stayTime: defaultDurationLengthInSec,
    //     },
    //   },
    // });
    // UpdateBackground();
  },
  avatarMode: function ({ index }) {
    this.hideMe({ index, what: false });

    if (document.editorType === "video") {
      // store.dispatch({
      //   type: "EDIT_ELEMENT",
      //   data: {
      //     id: `avatar${index}`,
      //     data: {
      //       stayTime: defaultDurationLengthInSec,
      //     },
      //   },
      // });
      // UpdateBackground();
    }

    document.cxx[index].renderAll();
  },

  //non vatar
  nonAvatarMode: function ({ index }) {
    this.hideMe({
      index,
      what: true,
      cb: (obj) => {
        obj.canvas.discardActiveObject();
      },
    });

    if (document.editorType === "video") {
      // store.dispatch({
      //   type: "EDIT_ELEMENT",
      //   data: {
      //     id: `avatar${index}`,
      //     data: {
      //       stayTime: MIN_ON_NON_AVATAR,
      //     },
      //   },
      // });
      store.dispatch({
        type: "WORKSPACE_RESIZE",
        data: {
          next: MIN_ON_NON_AVATAR,
          index: Index(),
        },
      });

      UpdateBackground();
    }
    document.cxx[index].renderAll();
  },

  voiceOnlyMode: function ({ index }) {
    this.hideMe({
      index,
      what: true,
      cb: (obj) => {
        obj.canvas.discardActiveObject();
      },
    });
    document.cxx[index].renderAll();
  },
};

document.mixedAvatar = mixedAvatar;
