import { SIZES } from "../config";
export const fitDimensionFromAngle = (
  angle,
  cx = {
    width: SIZES[document?._size || 0].width,
    height: SIZES[document?._size || 0].height,
    ratio: SIZES[document?._size || 0].ratio,
  }
) => {
  const anCon = Number(angle) > 180;
  const sin = (deg = anCon ? 360 - angle : angle) =>
    Math.sin((deg * Math.PI) / 180);
  const cos = (deg = anCon ? 360 - angle : angle) =>
    Math.cos((deg * Math.PI) / 180);
  const a = cx.width;
  const b = cx.height;
  const x = a * cos() + b * sin();
  const y = b * cos() + a * sin();
  const u = x * cos() + y * sin();
  const v = y * cos() + x * sin();

  //   if(x>y){
  //       return {
  //         height: y,
  //         width:y*cx.ratio
  //       }
  //   }else{

  let mx = Math.max(x, y);
  console.log(`x:${x},y:${y}`);
  //   if(anCon){
  //     return {
  //         width: y * cx.ratio,
  //         height: y,
  //       };
  //   }
  return {
    width: y * cx.ratio,
    height: y,
  };

  //   }
};
document.fit = (obj) => {
  let p = fitDimensionFromAngle(obj.angle);
  console.log(p);
  obj.set({ p, scaleX: 1, scaleY: 1, scale: 1 });
  obj.canvas.renderAll();
};

export default () => {};
