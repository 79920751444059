/* eslint-disable */
import React, { Suspense, useRef } from "react";
import { connect } from "react-redux";
import ButtonIcon from "../Chemistry/Atom/ButtonIcon";
import TitleRow from "../Chemistry/Atom/TitleRow";
import Tabium from "../Chemistry/Compound/Tabium";
import MergeSpeech, { Delay, speechToArrTime } from "./components/MergeSpeech";
// import { ReactMic } from "react-mic";
import "./_RightPanel.scss";
import { useCallback, useState } from "react";
import uuid from "uuid-random";
// import { FALSE } from "node-sass";
// import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { MusicTabUpload } from "../LeftPanel/components/Music/Music";
// import Mic from "../Chemistry/Atom/Mic";
import { useEffect } from "react";
import { fetcher, sleep, url, Index, updateTimeLen, MoveSeekTo, arrFromStore, onMediaLoaded } from "../../utils";
import store from "../../store/store";
import DisabledWrapper from "../Chemistry/Atom/DisabledWrapper";
import { MaxSuper, UpdateBackground } from "../Chemistry/One/Draggy";
import { AvtarSpeechClose } from "../TimeLine/Components/TimeBar";
import { customSeek } from "../../utils/videoPlayThings";
import { display } from "../../utils/videoPlayThings/addVideoToCanvasAndStore";

const Mic = React.lazy(() =>
  import("../Chemistry/Atom/Mic" /* webpackChunkName: "Mic" */)
);

function RightPanel({ uploadList, recordList, speech, smode }) {
  useEffect(() => {
    console.log({ image: document.editorType });
  }, [])
  const handleSelect = (e) => {
    return () => {
      store.dispatch({ type: "SET_SOUND", data: e });
      updateTimeLen();
      UpdateBackground();
    };
  };
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      const unSelectHandler = () => {
        document.cxx[Index()].discardActiveObject();
        store.dispatch({
          type: "SET_ACTIVE_OBJECT",
          data: "",
        });
      };
      ref.current.addEventListener("click", unSelectHandler);

      return () => {
        ref.current?.removeEventListener?.("click", unSelectHandler);
      };
    }
  }, []);


  const disablePlayMode = () => {
    document.outPlayMode()
  }
  return (
    <div className="RightPanel" onClick={disablePlayMode} ref={ref} style={{}}>
      <AvtarSpeechClose />
      <DisabledWrapper isDisable={smode === 2}>
        <TitleRow title={`Avatar Speech`} />
        <audio
          src={speech?.sound?.media_file}
          id="RightPanel_audio"
          preload={"auto"}
        />
        <Tabium
          arrTab={[{ name: "Type" }, { name: "Uploads" }, { name: "Record" }]}
        >
          {speech?.type === "type" ? (
            <TypeScriptTab />
          ) : (
            <OnUseComponent
              type="type"
              speech={speech?.type}
              sound={speech?.sound}
            />
          )}
          {speech?.type === "upload" ? (
            <MusicTabUpload
              list={uploadList}
              max={10}
              type="speechupload"
              categroy="speechupload"
              handleSelect={handleSelect}
              selectedId={speech?.sound?.id}
            />
          ) : (
            <OnUseComponent
              type="upload"
              speech={speech?.type}
              sound={speech?.sound}
            />
          )}
          {speech?.type === "record" ? (
            <RecordTab recordList={recordList} selectedId={speech?.sound?.id} />
          ) : (
            <OnUseComponent
              type="record"
              speech={speech?.type}
              sound={speech?.sound}
            />
          )}
        </Tabium>
      </DisabledWrapper>
    </div>
  );
}
const OnUseComponent = ({ type, speech, sound }) => {
  const handleSwitch = () => {
    store.dispatch({ type: "SWITCH_SOUND", data: type });
  };
  let icons = {
    // type:"",
    record: `${document.PUBLIC_URL}/ico1/recordingInUse.svg`,
    upload: `${document.PUBLIC_URL}/ico1/uploadInUse.svg`,
  };
  let namee = {
    type: "type script",
    record: "recording",
    upload: "uploaded music",
  };
  let title = () => `You can ${namee[type]} or use your own ${namee[speech]}.`;
  return (
    <div className="OnUseComponent">
      {icons[speech] && (
        <img src={icons[speech]} alt="" className="OnUseComponent_img" />
      )}
      {sound?.name && speech !== "type" && (
        <div className="OnUseComponent_name">
          "{sound.name.substring(0, 15)}..." in use!
        </div>
      )}
      <div className="OnUseComponent_label">{title()}</div>
      <button className="OnUseComponent_button" onClick={handleSwitch}>
        Use {namee[type]}
      </button>
    </div>
  );
};

const tmstp = (state) => {
  return {
    speech: state.scenes[state.sceneNumber].speech,
    rightDisabled: state?.disable?.right,
    scenes: state.scenes[state.sceneNumber],
    currentAvatar: state.scenes.currentAvatar,
  };
};

const handleBigPlay = (onTimeLine) => {
  return async () => {
    document.hitSpeechAPI({
      cb: () => {
        let ele = document.getElementById(`avatar${Index()}`);
        if (ele) {
          if (ele.paused) {
            ele.currentTime = 0;
            ele.play();
          } else {
            ele.pause();
          }
        }
      }
    })

  };
};
document.handleBigPlay = handleBigPlay;




const tmftp = (dispatch) => {
  return {
    addNewMergeTextBox: (index) => {
      dispatch({ type: "ADD_NEW_MERGE_TEXTBOX", data: index });
    },
    deleteMergeTextBox: (index) => {
      dispatch({ type: "DELETE_NEW_MERGE_TEXTBOX", data: index });
    },
    editTextBox: ({ index, text, length, html }) => {
      dispatch({
        type: "EDIT_NEW_MERGET_TEXTBOX",
        data: { index, text, length, html, i: Index() },
      });
      store.dispatch({
        type: "SPEECH_DISABLE",
        data: true
      });

      updateTimeLen();
    },
    editURLTextBox: async (index, url, duration, data) => {
      let arr = arrFromStore()
      let dataP = data.map((one, i) => new Promise(async res => {
        let index = arr[i];
        const _audioElm = document.getElementById(`avatar${index}`);
        if (_audioElm) {
          _audioElm.src = await display(
            one.combineData.sound,
            uuid()
          );
          await onMediaLoaded(_audioElm);
          //await _audioElm.onMediaLoaded();
        }
        res()
      }))

      await Promise.all(dataP)

      // })
      dispatch({
        type: "EDIT_URL_NEW_MERGE_TEXTBOX",
        data: { index, url, duration, data, i: Index() },
      });
      updateTimeLen();
      UpdateBackground();
    },
    editPush: (data) => {
      dispatch({
        type: "EDIT_PUSH",
        data: data,
      });
    },
  };
};
const TypeScriptTab = connect(
  tmstp,
  tmftp
)(
  ({
    speech,
    addNewMergeTextBox,
    deleteMergeTextBox,
    editTextBox,
    editURLTextBox,
    editPush,
    scenes,
    currentAvatar,
  }) => {
    const handleDelete = useCallback((index) => {
      return () => {
        deleteMergeTextBox(index);
        setTimeout(() => {
          hitSpeechAPI({});
        }, 100);
      };
    }, []);

    const handleAdd = useCallback((index) => {
      return () => {
        addNewMergeTextBox(index);
      };
    }, []);

    const hitSpeechAPI = async ({ cb = () => { }, err = () => { }, con = false, fullWorkspace = false }) => {

      return await new Promise((r, rj) => {
        let _store = store.getState();
        let scenes = _store.scenes[Index()];
        let avatarSound = _store.scenes.currentAvatar?.audio?.id;
        let av = scenes.elements[`avatar${Index()}`];
        let ichange = _store.scenes.speechChangedAt
        let speech = scenes.speech;
        document.setPlay("load");
        store.dispatch({ type: "SCENE_DISABLED", data: true })

        if (_store.config.speechLoad || con || _store.scenes.speechChanged) {
          setTimeout(() => {
            fetcher({
              method: "POST",
              url: `${url}/api/audio/batch/`,
              data: JSON.parse(_store.scenes.currentScene.arr).map(k => {
                return Object.keys(_store.scenes[k].speech.data).map((e, i) => {
                  let s = _store.scenes[k].speech.data[e];
                  return { _id: e, text: s.text, avatarSound, delay: s.delay };
                })
              })
              ,

              res: async (res) => {
                if (document.fromVoiceSpeech) {
                  con = true;
                  fullWorkspace = true;
                  document.fromVoiceSpeech = false

                }

                console.log('hitSpeechAPI before editUrlTextbox');
                await editURLTextBox(ichange, null, null, res.data);
                console.log('hitSpeechAPI editUrlTextbox Completed');

                let max_at = document._Max / document.per
                UpdateBackground();
                console.log('hitSpeechAPI UpdateBackground Completed');

                let arr = arrFromStore(_store);
                console.log('hitSpeechAPI arrFromStore Completed');
                for (let i = 0; i < arr.length; i++) {
                  let one = arr[i];
                  let max = MaxSuper(_store, one, false)
                  console.log('hitSpeechAPI MaxSuper Completed', i);
                  let speechNow = res.data[i].combineData.soundDuration;
                  await store.dispatch({
                    type: "WORKSPACE_RESIZE", data: {
                      next: speechNow,
                      now: max,
                      index: one
                    }
                  });
                }
                console.log('hitSpeechAPI WORKSPACE_RESIZE Completed');

                await store.dispatch({
                  type: "SPEECH_DISABLE",
                  data: false
                });

                updateTimeLen();
                document.setWx(document._Max)
                document.setPlay("pause");
                await store.dispatch({ type: "SCENE_DISABLED", data: false });
                console.log('hitSpeechAPI SCENE_DISABLED Completed');
                if (cb) {
                  cb();
                }
                r();
              },
              status: (code) => {
                if (code !== 200) {
                  store.dispatch({ type: "SCENE_DISABLED", data: false })
                  document.setPlay("pause");

                }
              },
              err: (e) => {
                err();
                store.dispatch({ type: "SCENE_DISABLED", data: false })
                document.setPlay("pause");

                r()
                cb();
              },
            });
          }, 100);
        } else {
          if (cb) {
            document.setPlay("pause");
            store.dispatch({ type: "SCENE_DISABLED", data: false });
            r()
            cb()
          }
        }
      })


    };
    document.hitSpeechAPI = hitSpeechAPI;

    const handleSetDelay = useCallback((index) => {
      return (value) => {
        editPush({ index, delay: Number(value.target.value) });
        hitSpeechAPI({});

      };
    }, []);

    const handleEdit = (dataIndex) => {
      return async (e, cb) => {
        const {
          target: { innerHTML: value, innerText: inText },
        } = e;
        await editTextBox({
          index: dataIndex,
          text: inText,
          length: inText.length,
        });
        if (inText.length) {
          // hitSpeechAPI({
          //   cb,
          //   err: () => {
          //     editTextBox({
          //       index: dataIndex,
          //       text: inText,
          //       length: inText.length,
          //     });
          //   },
          // });


        } else {
          store.dispatch({ type: "ZERO_SOUND" });
          UpdateBackground();
          updateTimeLen();
        }
      };
    };

    const handleSmallPlay = useCallback((src) => {
      return () => {
        let aud = audRef.current;
        aud.src = src;
        aud.play();
      };
    }, []);

    const audRef = useRef();

    const [bigPlay, setBigPlay] = useState(false);

    useEffect(() => {
      document.setBigPlay = setBigPlay;
    }, []);
    return (
      <div className="TypeScriptTab">
        <audio ref={audRef} preload="auto" />

        <audio src={"NULL"} preload="auto" className="avatarRealSound" />
        <ButtonIcon
          value="Play Script"
          // src={`${document.PUBLIC_URL}/icons/playIcon.svg`} 
          src={`${document.PUBLIC_URL}/ico/${bigPlay ? "previewPause" : "playScene"}.svg`}
          onClick={handleBigPlay()}
        />
        {/* <SpeechBox /> */}
        <div
          className="ListOfSpeech"
          style={{
            height: 453,
          }}
        >
          {JSON.parse(speech.arr).map((e, i) => {
            const { text, haveDelay, delay, length, url } = speech.data[e];
            // if (haveDelay) {
            return (
              <>
                <Delay time={delay} onChange={handleSetDelay(e)} />
                <MergeSpeech
                  length={length}
                  text={text}
                  onBlur={handleEdit(e)}
                  key={`${e}${Index()}`}
                  id={e}
                  url={url}
                  onPlay={handleSmallPlay(url)}
                  onAdd={handleAdd(i)}
                  isActive={Object.keys(speech).length > 1}
                  onDelete={
                    JSON.parse(speech.arr).length < 1
                      ? () => { }
                      : handleDelete(e)
                  }
                />
              </>
            );
            // }
          })}
        </div>
      </div>
    );
  }
);

const RecordTab = ({ recordList, selectedId }) => {
  const [state, setState] = useState("file");

  useEffect(() => {
    if (recordList.length !== 0) {
      setState("file");
    } else {
      setState("init");
    }
  }, [recordList]);

  const handleState = (st) => {
    return () => {
      setState(st);
    };
  };
  let InitState = ({ }) => {
    return (
      <div className="InitState">
        <img src={`${document.PUBLIC_URL}/ico/mic.svg`} alt="mic" />
        <label htmlFor="">No Recording Yet!</label>

        <ButtonIcon
          value="Start Recording"
          onClick={handleState("record")}
          icon={<></>}
        />
      </div>
    );
  };

  let RecordState_ = () => {
    return (
      // <div className="InitState RecordState">
      <Suspense fallback={<img src={`${document.PUBLIC_URL}/icons/loading.gif`} />}>
        <Mic
          onStop={(blob) => {
          }}
          onDone={() => {
            setState("file");
          }}
        />
      </Suspense>

      // </div>
    );
  };
  const handleSelect = (e) => {
    return () => {
      store.dispatch({ type: "SET_SOUND", data: e });

      updateTimeLen();
      UpdateBackground();
    };
  };

  return (
    <div className="RecordTab">
      {state === "init" && <InitState />}
      {state === "record" && <RecordState_ />}
      {state === "file" && (
        <MusicTabUpload
          text="Click here for new recording"
          ico={`${document.PUBLIC_URL}/ico/mic_ico.svg`}
          type="speechrecord"
          list={recordList}
          categroy="speechrecord"
          max={10}
          selectedId={selectedId}
          handleSelect={handleSelect}
          customUpload={
            <div
              className={`_DragAndDropImage`}
              onClick={() => {
                setState("record");
              }}
            >
              {/* <input /> */}
              <img src={`${document.PUBLIC_URL}/ico/mic_ico.svg`} alt={"uploadicon"} />
              {"Click here for new recording"}
            </div>
          }
        />
      )}
    </div>
  );
};

export default connect((state) => ({
  uploadList: state.media.speechupload.list,
  recordList: state.media.speechrecord.list,
  speech: state.scenes[state.sceneNumber]?.speech,
  rightDisabled: state?.disable?.right,
  smode: state.scenes[state.sceneNumber]?.mode,
  sceneNumber: state.sceneNumber
  // smode:
}))(RightPanel);
