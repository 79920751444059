import store from "../../store/store";
import { getSceneArray, Index } from "../../utils";

const updateVideosTimeHelper = (scene, time, _timeLineDurations, e) => {
  if (e._Type === "video" || e._Type === "avatar") {
    if (scene.elements[e.id]) {
      const { trimStart, trimEnd, adjustLength, stayTime, enterDelay } =
        scene.elements[e.id];
      let vidEle = document.querySelector(`#${e.id}`);
      if (vidEle) {
        if (adjustLength === 0) {
          let toBeSeek = trimStart + time - enterDelay;
          if (trimStart <= toBeSeek && toBeSeek <= trimEnd) {
            vidEle.customVideoSeek(toBeSeek);
          } else if (trimStart > toBeSeek) {
            vidEle.customVideoSeek(trimStart);
          } else if (toBeSeek > trimEnd) {
            vidEle.customVideoSeek(trimEnd);
          }

        } else if (adjustLength === 1) {
          let startTime = enterDelay;
          let endTime = startTime + stayTime;
          let trimDiff = trimEnd - trimStart;
          if (startTime <= time && endTime >= time) {
            let toBeSeek = (time - startTime) % trimDiff;
            vidEle.customVideoSeek(trimStart + toBeSeek);
          } else if (startTime > time) {
            vidEle.customVideoSeek(trimStart);
          } else if (time > endTime) {
            vidEle.customVideoSeek(trimEnd);
          }
        } else if (adjustLength === 2) {
          let toBeSeek = trimStart + time - enterDelay;
          if (trimStart <= toBeSeek && toBeSeek <= trimEnd) {
            vidEle.customVideoSeek(toBeSeek);
          } else if (trimStart > toBeSeek) {
            vidEle.customVideoSeek(trimStart);
          } else if (toBeSeek > trimEnd) {
            vidEle.customVideoSeek(trimEnd);
          }
        } else if (adjustLength === 3) {
          let speed = vidEle.playbackRate;
          vidEle.customVideoSeek(time * speed);
        }
      }
    }
  } else if (e._Type === "music") {
    if (scene.elements[e.id]) {
      const { trimStart, trimEnd, adjustLength, stayTime } =
        scene.elements[e.id];
      let vidEle = document.querySelector(`#${e.id}`);
      if (vidEle) {
        if (adjustLength === 1) {
          // adjust as scene
          let startTime = 0;
          let trimDiff = trimEnd - trimStart;
          if (startTime <= time && stayTime >= time) {
            let toBeSeek = time % trimDiff;
            vidEle.customVideoSeek(trimStart + toBeSeek);
          }
        } else {
          // loop in all scene
          let startTime = 0;
          for (let _ii = 0; _ii < _timeLineDurations.crntIndex; _ii++) {
            startTime += _timeLineDurations.totalDurations[_ii];
          }
          const _totalDurations = startTime + time;

          let endTime = startTime + stayTime;
          let trimDiff = trimEnd - trimStart;
          if (startTime <= _totalDurations && endTime >= _totalDurations) {
            let toBeSeek = _totalDurations % trimDiff;
            vidEle.customVideoSeek(trimStart + toBeSeek);
          }
        }
      }
    }
  }
};

export const updateVideosTime = (_time = null, index = Index()) => {

  let scene = document.screenChheda
    ? store.getState().scenes[index]
    : document.Player.scenes[index];
  let time = _time || document.txx.timeLine[index].time();
  let _timeLineDurations = {
    totalDurations: [],
    crntSceneId: index,
    ...getSceneArray(index),
  };

  _timeLineDurations.sceneArr.forEach((_scIndex) => {
    _timeLineDurations.totalDurations.push(
      document.txx.timeLine[_scIndex]?.duration?.()
    );
  });

  scene.jsonData.objects.map(
    updateVideosTimeHelper.bind(this, scene, time, _timeLineDurations)
  );
};

export const setAudioAvater = (index = Index()) => {
  // let scenes = store.getState()
  const _avatar = store.getState().scenes[index].elements[`avatar${index}`];
  if (_avatar) {
    let av = document.querySelector(".avatarRealSound");
    if (av) {
      av.src = _avatar.sound;
    }
  }
};
