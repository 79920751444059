import React from "react";
import ButtonIcon from "../../components/Chemistry/Atom/ButtonIcon";
import InputIcon from "../../components/Chemistry/Atom/InputIcon";
import TabTop from "../../components/Chemistry/Atom/TabTop";
import "./_CSS.scss";
export default function TabHeader({ arrTab, nowTab, onTab = () => {} }) {
  return (
    <div className="TabHeader flex">
      <TabTop arrTab={arrTab} nowTab={nowTab} handleTab={onTab} />
    </div>
  );
}

const Tab = () => {};
