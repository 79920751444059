import "../../../components/Chemistry/Atom/_Atom.scss";
import Tooltip from "../../JohnDoe/common/tooltip"
function LabelInput({
    ml,
    type = "number",
    min,
    max,
    onChange = () => { },
    onBlur = () => { },
    width,
    error = { have: false, text: "" },
    value = "",
    label,
    ele,
    disable = false,
    onEnter = () => {

    },
    step = 1,
}) {
    const customF = (what) => {
        return (e) => {
            document.isCanActive = what;
            if (what) {
                onBlur(e);
            }
        };
    };
    const handleKeyDown = (e) => {
        // console.log(e);
        if (e.key === "Enter") {
            onEnter(e)
        }
    }
    return (
        <div className="LabelInput">
            <label htmlFor="" className="Label">
                {label}
            </label>
            <div
                className={`LabelInput_Input ${error.have ? "errorInput" : ""}`}
                style={{ marginLeft: ml || "15px", width: width || "60px" }}
            >
                {ele || (
                    <input
                        type={type || "text"}
                        min={min}
                        max={max}
                        disabled={disable}
                        value={value}
                        onBlur={customF(true)}
                        onFocus={customF(false)}
                        onChange={onChange}
                        step={step}
                        onKeyDown={handleKeyDown}
                    />

                )}
                {error.have && <Tooltip data={error.text}><div className="_error_i error-new-hide pointer" id="error-first_name">!</div></Tooltip>}

            </div>
        </div>
    );
}

export default LabelInput;
