
import "./_Atom.scss";
import { fetcher } from "../../../utils";
import store from "../../../store/store";
// import mystore from "../../../App/appStore";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";

// import { useState } from "react/cjs/react.development";

function InfiniteWrapper({
  type = "",
  children,
  target,
  max = 18,
  stop = false,
  mystore = store,
  isSearched = false,
  url = null,
  className = ""
}) {
  const { log } = console;
  let _t = type;
  // log(type);
  useEffect(() => {
    document.lock[type] = false;
  }, []);
  useEffect(() => {
    if (isSearched) {
      type = "search";
    } else {
      type = _t;
    }
  }, [isSearched]);
  const [HasMore, setHasMore] = useState(mystore.getState().media[type].next ? true : false)

  // console.log(" I am Running");
  useEffect(() => {
    setHasMore(mystore.getState().media[type].next ? true : false)

  }, [JSON.stringify(mystore.getState().media[type].next)]);

  const handleFetch = () => {

    if (!stop) {
      document.lock[type] = true;
      const {
        [type]: { next },
      } = mystore.getState().media;
      console.log('MEDIA_WITH_NEXT', type, next, url)
      fetcher({
        url: next || url,
        res: (e) => {
          const { results, next } = e.data;

          setHasMore(next ? true : false)
          mystore.dispatch({
            type: "MEDIA_WITH_NEXT",
            data: { next, list: results, type },
          });
          document.lock[type] = false;
        },
      });
    }
  };

  useEffect(() => {
    if (children.length <= max) {
      //console.log(children.length);
      handleFetch();
      ////console.log("featch");
    }
  });


  return (
    <InfiniteScroll
      scrollableTarget={target}
      dataLength={children.length}
      next={handleFetch}
      className={className}
      hasMore={HasMore}
      //   style={{ display: "flex", flexDirection: "column-reverse" }}
      loader={
        <div className="INFINITELOAD">
          <img alt="Loading" src={`${document.PUBLIC_URL}/icons/loading.gif`} />
        </div>
      }
    >
      {children}
    </InfiniteScroll>
  );
}
export default InfiniteWrapper;
