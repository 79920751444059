
import { StyledDropZone } from 'react-drop-zone'
import 'react-drop-zone/dist/styles.css'
import React, { Component } from 'react'
import Popup from "reactjs-popup";


export default class fileUpload extends Component {

  state = {
    file: undefined,
    close: false
  }

  setFile = (file, text) => {
    this.setState({ file: file, close: true })
    this.props.fileUpload(file)
  }

  render() {
    // console.log(this.state)
    const label = this.state.file ?
      this.state.file.name :
      'Click or drop your file here';
    return (
      <Popup
        trigger={
          <div>	{this.props.children} </div>
        }
        onOpen={() => {
          this.setState({
            file: undefined,
            close: false
          })
        }}
        modal
        nested
      >
        {(close) => (

          <div className="fileUpload-dragdrop" >
            <StyledDropZone
              accept={this.props.accept}
              onDrop={this.setFile}
              label={label}
            />
            {this.state.close ? close() : null}
          </div>



        )}
      </Popup>

    );
  }
}


