import { MaxSuper } from "../../components/Chemistry/One/Draggy";
import {
  defaultDurationLengthInSec,
  getById,
  updateTimeLen,
} from "../../utils";
import {
  cxxtoJSON,
  defaultID,
  draggyType,
  MIN_ON_NON_AVATAR,
  TIME_PER_SEC,
} from "../../utils/config";
import elementObjToURL from "../../utils/elementObjToURL";

export const ADD_OBJECT = ({ state, data, scenes, sceneNumber }) => {
  if (data.reload) {
    return state;
  }
  let customClone = false;
  if (data.clone) {
    if (
      data.index === data.from ||
      !scenes.haveEasyMode ||
      data.type === "music"
    ) {
      state.scenes[data.index].elements[data.id] = {
        ...state.scenes[data.from].elements[data._id],
        enter: 0,
        exit: 0,
      };

      getById(data.id)._Animation = {
        enter: {
          id: defaultID.animation.enter,
        },
        exit: {
          id: defaultID.animation.exit,
        },
        place: {
          id: defaultID.animation.place,
        },
      };
    } else {
      data.clone = false;
      customClone = true;
    }
  }

  if (!data.clone) {
    let obj = getById(data.id);

    let __data = {
      adjustLength: state.scenes.isChheda ? 0 : 3,
      // stayTime:
    };
    let elementStayTime =
      scenes.mode === 2 ? MIN_ON_NON_AVATAR : defaultDurationLengthInSec;
    if (document._Max) {
    }

    if (data.type === "video") {
      // let arr = JSON.parse(scenes.media[data.type]);
      // arr.push(data.media);
      // (data.index ? state.scenes[data.index] : scenes).media[data.type] =
      //   JSON.stringify(arr);
      let _xpp = document.querySelector(`#${data.id}`);
      __data = {
        ...__data,
        width: _xpp.duration * TIME_PER_SEC,
        stayTime: document._Max / document.per,
        duration: _xpp.duration,
        volume: 1.0,
        trimEnd: _xpp.duration,
        adjustLength: 2,

        ...((data.index ? state.scenes[data.index] : scenes).elements[
          data.id
        ] || {}),
      };
    } else if (data.type === "background" && obj._Data?.tab === 2) {
      let _xpp = document.querySelector(`#${data.id}`).duration;
      __data = {
        ...__data,
        width: _xpp * TIME_PER_SEC,
        stayTime: _xpp,
        duration: _xpp,
        volume: 1.0,
        trimEnd: _xpp,
        adjustLength: 2,
      };
    } else if (data.type === "music") {
      __data.adjustLength =
        document.musicLoop?.[data._id] === undefined ? 0 : 4;
      // let _xpp = data.media.fileInfo ||
      //   (data.index ? state.scenes[data.index] : scenes).elements[data.id];
      let _xpp = data.obj._Music?.fileInfo;
      if (_xpp) {
        __data = {
          ...__data,
          width: _xpp.duration * TIME_PER_SEC,
          stayTime: document._Max / document.per,
          trimEnd: _xpp.duration,
          duration: _xpp.duration,
          adjustLength: 1,
          volume: 1.0,
          ..._xpp,
          ...(data.timeLineData || {}),
        };
      }

      // break;
    } else if (data.type === "avatar") {
      if (scenes.speech.type === "type") {
        let s = scenes.speech.speechTime;
        if (s) {
          __data = {
            ...__data,
            width: s * TIME_PER_SEC,
            stayTime: s,

            ...(data.timeLineData || {}),
          };
        } else {
          __data = {
            ...__data,
            width: elementStayTime * TIME_PER_SEC + 100,
            stayTime: elementStayTime,
            adjustLength: 0,
            ...(data.timeLineData || {}),
          };
        }
      }
    } else {
      __data = {
        ...__data,
        width: elementStayTime * TIME_PER_SEC + 100,
        stayTime: elementStayTime,
        ...(data.timeLineData || {}),
      };
    }

    let px = (data.index ? state.scenes[data.index] : scenes).elements[data.id];
    (data.index ? state.scenes[data.index] : scenes).elements[data.id] =
      (px && {
        ...px,
        ...__data,
      }) || {
        ...__data,
        x: 0,
        enter: 0,
        exit: 0,
        enterDelay: 0,
        trimStart: 0,
        // adjustLength: 0,
        chheda: false,
        resize: draggyType[obj._Type].resize,
        drag: draggyType[obj._Type].drag,
      };
  }



  if ("AFTER_AVATAR") {
    let list = document.cxx[data.index || sceneNumber].getObjects();
    let obj = list.find((e) => e.id === data.id);
    if (obj && obj._Type !== "background" && obj._Type !== "avatar") {
      list = list.filter((e) => e.id !== data.id);
      let avIndex = list.findIndex((e) => e._Type === "avatar");

      if (avIndex !== -1) {
        list.splice(avIndex, 0, obj);
      } else {
        list = [...list, obj];
      }
    }

    document.cxx[data.index || sceneNumber]._objects = list;
  }

  if (customClone) {
    let obj = getById(data.id);
    (data.index ? state.scenes[data.index] : scenes).elements[data.id] = {
      ...(data.index ? state.scenes[data.index] : scenes).elements[data.id],
      enter: 0,
      exit: 0,
    };
    obj._Animation = {
      enter: {
        id: defaultID.animation.enter,
      },
      exit: {
        id: defaultID.animation.exit,
      },
      place: {
        id: defaultID.animation.place,
      },
    };
  }

  (data.index ? state.scenes[data.index] : scenes).jsonData = elementObjToURL(
    document.cxx[data.index || sceneNumber].toJSON(cxxtoJSON)
  );

  let max = MaxSuper(state);
  if (!data.clone) {
    // scenes.elements
    let ele = (data.index ? state.scenes[data.index] : scenes).elements[
      data.id
    ];
    if (ele) {
      ele.stayTime = max;
    }
  }
  updateTimeLen(true, state);

  let p = document.cxx[data.index || sceneNumber]._objects
    .map((e) => e.id)
    .reverse();

  (data.index ? state.scenes[data.index] : scenes).elementList =
    JSON.stringify(p);

};
