import { memo, useRef, useState } from "react";
import "./_Atom.scss";
// import {  } from "react-image-shimmer/dist/Image";
import Image from "react-image-shimmer";
import { fetcher, url } from "../../../utils";
import store from "../../../store/store";
import Model from "reactjs-popup";
import Button from "../../../App/Components/Button";
function HoverImageCard({
  src,
  id,
  title = null,
  count,
  upElement = null,
  downElement = null,
  isActive = false,
  onSelect = () => { },
  onPreview,
  onExpand = () => { },
  checkIcon = false,
  UpFunction = null,
  ctype = "image",
  vidSrc = null,
  data = {}
}) {
  ////console.log(`HoverImageCard : ${title} Renders`);
  const ref = useRef();
  const inpRef = useRef();

  const handleSelect = (e) => {
    if (id === 'video_recording') {
      onSelect()
    } else if (ref.current && !ref.current.contains(e.target)) {
      onSelect();
    }
  };
  const [rename, setRename] = useState(false);
  const handleBlur = (e) => {
    let name = e.target.value;

    fetcher({
      method: "PUT",
      url: `${url}/api/userlibrary/upload/${id}/`,
      data: { name },
      res: (data) => {
        store.dispatch({
          type: "RENAME_MEDIA",
          data: {
            type: "upload",
            id,
            name,
          },
        });
        setRename(false);
      },
      err: (err) => {
        //console.log(err);
      },
    });
  };
  const refI = useRef();
  const [isHover, setHover] = useState(false);
  const [sActive, setActive] = useState(false);
  const handleHover = (con) => {
    return () => {
      setHover(con);
    };
  };
  const handleRename = (p) => {
    setRename(p);
    setTimeout(() => {
      refI.current.focus();
    }, 100);
  };

  return (
    <div className={`HoverImageCard `}>
      <div
        className={`HoverImageCard-wrapper ${isActive ? "HoverImageCard-wrapper_active" : ""
          }`}
        onClick={handleSelect}
        onMouseOver={handleHover(true)}
        onMouseLeave={handleHover(false)}
      >
        {checkIcon && (
          <img className="HoverImageCard_check" src={`${document.PUBLIC_URL}/ico/check.svg`}></img>
        )}
        {/* <img className="Image" src={src} alt={"imdage"} /> */}
        <span className={`Image_wrapper `}>
          <Image
            className="Image"
            src={src}
            fallback={<img src={`${document.PUBLIC_URL}/icons/loadingRect.gif`} alt="loading" />}
          />
        </span>

        {(isHover || sActive) && (
          <div className="HoverImageCard-wrapper-frame" ref={ref}>
            {(UpFunction && (
              <UpFunction
                id={id}
                rename={handleRename}
                del={() => id}
                setActive={setActive}
                imgSrc={src}
                ctype={ctype}
                vidSrc={vidSrc}
              />
            )) ||
              upElement || (

                <button className=" pointer BadButton" onClick={k => onPreview({ open: true, data: { ...data, ctype } })}>
                  <img

                    src={`${document.PUBLIC_URL}/ico/expandPreview.svg`}
                    alt={"avc"}
                  />
                </button>

              )}
            {downElement || (
              <div className="HoverImageCard-wrapper-frame_number">
                {count} Scenes
              </div>
            )}
          </div>
        )}
      </div>
      {/* <label htmlFor="">{title}</label> */}
      {title && (
        <input
          disabled={!rename}
          className={`HoverImageCard_title ${rename ? "HoverImageCard_title_active" : ""
            }`}
          defaultValue={title || ""}
          ref={refI}
          onBlur={handleBlur}
          autoFocus
        />
      )}
    </div>
  );
}
export default memo(HoverImageCard);
