import FontFaceObserver from "fontfaceobserver";
export const loadSingleFont = async (fontData) => {
  const _currentFont = new FontFace(
    fontData.fontFamily,
    fontData.fontUrl,
    fontData.fontConfig
  );
  await _currentFont.load();
  document.fonts.add(_currentFont);
  return true;
};
const loadAllFonts = async (arr = []) => {
  if (arr.length > 0) {
    const allFontsData = arr || [
      {
        fontFamily: "Poppins Regular",
        fontUrl:
          "url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2)",
        fontConfig: { style: "normal", weight: "normal" },
      },
      {
        fontFamily: "Poppins Regular",
        fontUrl:
          "url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2)",
        fontConfig: { style: "normal", weight: "bold" },
      },
      {
        fontFamily: "Poppins Light",
        fontUrl:
          "url(https://fonts.gstatic.com/s/poppins/v15/pxiGyp8kv8JHgFVrLPTucHtA.woff2)",
        fontConfig: { style: "normal", weight: "normal" },
      },
      {
        fontFamily: "Poppins Light",
        fontUrl:
          "url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecg.woff2)",
        fontConfig: { style: "normal", weight: "bold" },
      },
    ];

    const allLoadedImage = Promise.all(allFontsData.map(loadSingleFont));
    await allLoadedImage;
  }
  return true;
};

const DEFUALT_FONT = ['Arial','Calibri','Cambria','Courier New','Garamond','Georgia','Tahoma','Times New Roman','Verdana'];
export const loadFont = async(fontFamily) => {
  if(DEFUALT_FONT.indexOf(fontFamily)>=0){
    return;
  }else{
    console.log("Loading Font: ",fontFamily)
    let _font = new FontFaceObserver(fontFamily);
    try{
      await _font.load();
      console.log("Loaded Font: ",fontFamily)
    }catch(err){
      console.log("unable to load font",fontFamily)
    }

  }
}

export default loadAllFonts;
