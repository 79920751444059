import { memo, useState, useCallback, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import LabelInput from "./LabelInput";

const Color = ({
  color,
  goTo = () => { },
  onChange = () => { },
  onLastChange = () => { },
}) => {
  ////console.log("%cColor is Rendered", "color:pink");
  // const [color, setColor] = useState(() => color);
  const handleColor = useCallback((e) => {
    // //console.log()
    const color = e.target ? ("#" + e.target.value) : e;
    // //console.log(e);
    // setColor(color);
    onChange(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   setColor(color);
  // }, [color]);
  const handleDrop = async () => {
    // eslint-disable-next-line no-undef
    const eyeDropper = new EyeDropper();

    try {
      const selectedColor = await eyeDropper.open();
      // //console.log(selectedColor);
      handleColor(selectedColor.sRGBHex);
      // logs: { sRGBHex: '#ff0000' }
    } catch (err) {
      //console.log("color selection cancelled");
    }
  };

  return (
    <div className="Color">
      <HexColorPicker
        onChange={handleColor}
        color={color}
        onMouseUp={() => {
          // handleColor(color);
          onLastChange(color);
        }}
      />
      <div className="Color_drop flex row1">
        <LabelInput type="text" onChange={handleColor} value={color?.substring(1)} label={"Hex:  #"} width="80px" labelStyle={{ width: "40px" }} ml="0" />
        <img
          className="Color_picker"
          src={`${document.PUBLIC_URL}/ico4/eyedropper.svg`}
          alt=""
          onClick={handleDrop}
          style={{ marginLeft: "1em" }}
        />
      </div>
    </div>
  );
};

export default Color;
