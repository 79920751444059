/* eslint-disable */
import { findNewPoint } from "../../../../utils";
import { fabric } from "fabric";
let effect4Relate1 = findNewPoint(45, 15);
let effect1Relate1 = findNewPoint(45, 5);
let effect4Relate2 = findNewPoint(45, 30);
var effect5Relate1 = findNewPoint(20, 7);
let textColor = "#e7416a",
  shadowColor = "#000",
  shadowOff = findNewPoint(45, 5);
let t = 3;

const EffectList = {
  0: {
    stroke: null,
    effectLayer: null,
    opacity: 1,
    shadow: null,
  },
  1: {
    fill: "#e7416a",
    stroke: null,
    shadow: new fabric.Shadow({
      blur: 60,
      color: "#e7416a",
      offsetX: effect1Relate1.x,
      offsetY: effect1Relate1.y,
    }),
    textBackgroundColor: null,
  },
  2: {
    fill: null,
    stroke: "#e7416a",
    strokeWidth: 1,
    shadow: null,
    textBackgroundColor: null,
  },
  3: {
    stroke: "#e7416a",
    strokeWidth: 2,
    fill: null,
    shadow: null,
    textBackgroundColor: null,
    effectLayer: [
      {
        fill: "#e7416a",
        objectBlur: 6,
        opacity: 0.8,
      },
    ],
  },
  4: {
    fill: "#e7416a",
    textBackgroundColor: null,

    effectLayer: [
      {
        fill: "#5441e7",
        leftOffset: effect4Relate1.x,
        topOffset: effect4Relate1.y,
        opacity: 0.3,
      },
      {
        fill: "#5441e7",
        leftOffset: effect4Relate2.x,
        topOffset: effect4Relate2.y,
        opacity: 0.2,
      },
    ],
  },
  5: {
    fill: "#e7416a",
    textBackgroundColor: null,
    effectLayer: [
      {
        fill: "#9f16db",
        leftOffset: -effect5Relate1.x,
        topOffset: -effect5Relate1.y,
        opacity: 0.45,
      },
      {
        fill: "#5441e7",
        leftOffset: effect5Relate1.x,
        topOffset: effect5Relate1.y,
        opacity: 0.45,
      },
    ],
  },
  6: {
    fill: "#e7416a",
    textBackgroundColor: null,
    effectTop: true,
    effectLayer: [
      {
        fill: "#fff",
        stroke: null,
        shadow: null,
        objectBlur: 1,
        opacity: 0.4,
        leftOffset: -t,
        topOffset: -t,
      },
      {
        fill: "#000",
        stroke: null,
        shadow: null,
        objectBlur: 1,
        opacity: 0.4,
        leftOffset: t,
        topOffset: t,
      },
      {
        fill: "#fff",
        opacity: 0.3,
        stroke: null,
        shadow: null,
      },
      {
        fill: textColor,
        applyMask: true,
        leftOffset: shadowOff.x,
        topOffset: shadowOff.y,
        opacity: 1,
        objectBlur: 5 / 7,
        stroke: null,
        shadow: null,
      },
    ],
  },
  7: {
    textBackgroundColor: null,

    effectLayer: [
      {
        fill: "#e7416a",
        stroke: null,
        shadow: null,
        //objectBlur: null,
      },
      {
        fill: "#e7416a",
        objectBlur: 30,
        opacity: 0.35,
      },
    ],
    fill: "rgba(255,255,255,0.8)",
    stroke: "#e7416a",
    strokeWidth: 1,
    shadow: new fabric.Shadow({
      color: "#e7416a",
      blur: 18,
      offsetX: 0,
      offsetY: 0,
    }),
    opacity: 1,
  },
};

export default EffectList;
