import "./allImport";
// AutoVid
import "./_App.scss";

import { Jump, url, Vid } from "./utils";

import objDiff from "./utils/objDiff";

import { useEffect, useState } from "react";
import { initDataFetcher, initElementFetch } from "./utils";
import store from "./store/store";
// import TimeLine from "./components/TimeLine/TimeLine";
import Screens from "./components/Screen/Screens";

import Media from "./Media";
import { connect } from "react-redux";
import { OFFLINEID, SIZES } from "./utils/config";
import { useHistory, useLocation } from "react-router";

// import "./utils/idConversion";

objDiff();

// document.me = "me";
document.cxx = {};

document.index = 0;
function Video(props) {
  ////console.log("Video is renders");
  let hist = useHistory();
  useEffect(() => {
    async function fetch1(s) {
      store.dispatch({ type: "LLOAD" });
      if (OFFLINEID) {
        import(
          "./utils/offlineData" /* webpackChunkName: "OfflineData" */
        ).then((data) => {
          store.dispatch({ type: "OFFLINE_DATA_SET", data: data.default });

          // console.log(data.default);
        });
      } else {
        const data = await initDataFetcher();
        store.dispatch({ type: "SET_INIT_DATA", data: { avatar: data } });
        const data1 = await initElementFetch();
        store.dispatch({ type: "SET_INIT_ELEMENT", data: data1 });
        store.dispatch({ type: "HAVE_VID", data: true });
      }

      ////console.log(store.getState().avatar);
    }
    fetch1();
    //some my iinit

    window.addEventListener(
      "unload",
      function (e) {
        navigator.sendBeacon(
          `${url}/api/newvideo/draftthumbnail/update/${Vid()}/`
        );
      },
      false
    );
  }, []);

  const emoji = () => {
    let start = 128512;
    let end = 128580;
    return `&#${Math.floor(Math.random() * (end - start + 1) + start)};`;
  };
  const Emoji = ({ time = 0, arr }) => {
    let [a, setA] = useState(emoji());

    return <div dangerouslySetInnerHTML={{ __html: a }}></div>;
  };
  useEffect(() => {
    let str = document.location.href;

    let size = /size=(.)/gi.exec(str)?.[1];
    let mode = /mode=(.)/gi.exec(str)?.[1];
    let template = /template=(.)/gi.exec(str)?.[1];
    if (size) {
      document._size = size;
    } else {
      document._size = 0;
    }

    if (mode) {
      document._mode = Number(mode);

      document._Max = 3 * 40;
    } else {
      document._mode = 0;
      document._Max = 6 * 40;
    }

    document._template = template;

    store.dispatch({
      type: "ADD_SIZE",
      data: SIZES[document._size],
    });

    store.dispatch({
      type: "ADD_MODE",
      data: !!Number(document._mode),
    });
    hist.push(Jump({ obj: {}, def: true }));
  }, []);
  // useEffect(()=>{
  //   document.
  // },[show])
  useEffect(() => {
    document.getElementById("bootstrap").removeAttribute("href");

    return () => {
      document
        .getElementById("bootstrap")
        .setAttribute("href", "/bootstrap.min.css");
    };
  }, []);
  const [arr, setArr] = useState([0, 1, 34, 4, 5, 5]);
  return (
    <div className="App">
      <Media />
      <Screens />

      {!props.show && (
        <>
          <div className="LOADING " style={{ zIndex: 9999 }}>
            {/* {arr.map((e, i) => {
              return <Emoji key={i} arr={setArr} />;
            })} */}
            <img
              className="MainLoading"
              src={`${document.PUBLIC_URL}/icons/loading.gif`}
              alt="Loading"
            ></img>
          </div>
        </>
      )}
    </div>
  );
}

export default connect((state) => ({ show: state.active.haveVid }))(Video);
