document.blobMedia = {
  blob: {},
  setBlobUrl: function (id, data) {
    // from server
    this.blob[id] = data;
  },
  getBlobUrl: function (id) {
    return this.blob[id];
  },
  res: {},
  setRes: function (id, data) {
    // obj.id
    this.res[id] = data;
  },
  getRes: function (id) {
    return this.res[id];
  },
};
