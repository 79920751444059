/* eslint-disable */
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
// import { url, token } from "../../../config/config";
// import axios from "axios";
import { ReactComponent as Cross } from "../../../extras/account/cross.svg";
import BlankProfile from "../../../extras/misc/blankprofile.svg";
import { ReactComponent as Lock } from "../../../extras/account/lock.svg";
import FileInput from "../../common/fileUpload";
import Camera from "../../../extras/account/cam.svg";
import Info from "../../common/infoIcon";
import Loadingg from "../../common/loading";
import { fetcher, url } from "../../../../utils"
import validator from 'validator';
import { display } from "../../../../utils/videoPlayThings/addVideoToCanvasAndStore";


export default function EditProfile(props) {
	const [Loading, setLoading] = useState(false);
	const [ChangeData, setChangeData] = useState({});
	const [RemoveImage, setRemoveImage] = useState(false);
	const [File, setFile] = useState(null);

	const validateMe = (type, text) => {
		const validateType = {
			link: (text) => validator.isURL(text),
			email: (text) => validator.isEmail(text),
			phone_number: (text) => /^(\+\d{1,3}[- ]?)?\d{10}$/.test(text),
			first_name: (text) => validator.isAlpha(text, ['en-US'], { ignore: "-s" }),

		}

		if (text == "") {
			return true
		}
		else {
			if (type.includes("url")) {

				return validateType.link(text)
			}
			else {
				return validateType[type](text)
			}
		}


	}

	// console.log(ChangeData);

	const handlerChange = (e) => {
		e.target.classList.remove("errorInput")
		document.getElementById(`error-${e.target.name}`).classList.add("error-new-hide");
		setChangeData({ ...ChangeData, [e.target.name]: e.target.value });


	};
	const FileUploadHandler = (e) => {
		document.getElementById(`error-profile_image`).innerHTML = "";

		setChangeData({ ...ChangeData, profile_image: e });
	};
	const handleSave = () => {
		var obj;
		var image = true;
		var isValidated = true

		var validationProcess = Object.keys(ChangeData).map((e, k) => {
			if (e !== "profile_image")
				var flag = validateMe(e, ChangeData[e])
			// console.log({ e, flag });
			if (flag === false && e !== "profile_image") {

				isValidated = isValidated && flag
				document.getElementById(`error-${e}`).classList.remove("error-new-hide");
				document.getElementsByName(e)[0].classList.add("errorInput")
				console.log({ e, flag });
				return 0
			}
			else
				return 0
		});

		console.log({ isValidated });

		// const Token = token();

		// if (image) {
		// 	var options = {
		// 		headers: { Authorization: `Token ${Token}` },
		// 		"Content-Type": "multipart/form-data",
		// 	};
		// } else {
		// 	var options = {
		// 		headers: { Authorization: `Token ${Token}` },
		// 	};
		// }

		// axios.put(`${url}/api/accounts/user/`, obj, options).then((res) => {
		// 	console.log(res);
		// });

		if (isValidated) {

			if (ChangeData.hasOwnProperty("profile_image")) {
				if (ChangeData["profile_image"] === "") {
					obj = {
						...ChangeData,
					};
				} else {
					image = true;
					obj = new FormData();
					obj.append("profile_image", ChangeData["profile_image"]);

					for (var key in ChangeData) {

						obj.append(key, ChangeData[key]);
					}
				}
			} else {
				obj = {
					...ChangeData,
				};
			}

			fetcher({
				url: `${url}/api/accounts/user/`,
				method: "PUT",

				data: obj,
				res: (res) => {
					window.location.reload();
				},
				err: (error) => {
					console.log(error.response.data);

					Object.keys(error.response.data).map((e, k) => {
						if (e === "profile_image") {
							document.getElementById(`error-profile_image`).innerHTML = error.response.data[e][0]
						}
						else {
							document.getElementById(`error-${e}`).classList.remove("error-new-hide");
							document.getElementsByName(e)[0].classList.add("errorInput");
						}
					})



				},

			})
		}





	};

	// console.log(props.user.profile_image.length > 0 && !RemoveImage && File === null);
	// console.log(ile);

	return (
		<Popup
			trigger={props.children}
			modal
			nested
			onOpen={async () => {
				setChangeData({});
				setRemoveImage(props.user.profile_image === null);
				setFile(null);
			}}
		>
			{(close) => (
				<div className="modal-dialog modal-dialog-centered  analytic-detail-popup-video CAT ProfilePopupEdit">
					<div className="modal-content vd-modal-content">
						<div className="modal-header vd-modal-header padding-40-popup">
							<div className="header-analyticp-poopup">
								<h5 className="modal-title-analutics">
									Edit Profile Details
									<span className="camp-analytic-info">
										<Info />
									</span>
								</h5>
							</div>

							<div className="header-popup-analytics-rightside">
								<Cross
									className="pointer"
									onClick={() => {
										close();
									}}
								/>
							</div>
						</div>
						<div className="modal-body modal-analytic-popup padding-40-popup pt-0">
							<div className="chart-container">
								{Loading ? (
									<Loadingg />
								) : (
									<div className="cat-list-container">
										<div className="profile-edit-popup-wrapper">
											<div className="bx8882_1">
												<div className="bx88i_left">
													<div className="bxppp_list">
														<div className="bxppp-item">
															<label htmlFor="" className="label-bx88i_name">
																Full Name
															</label>

															<input
																type="text"
																name="first_name"
																defaultValue={props.user.first_name}
																placeholder="Enter Name"
																className={`bx88i_input`}
																onChange={(e) => handlerChange(e)}
															/>
															<div className="_error_i error-new-hide" id="error-first_name">!</div>
														</div>
														<div className="bxppp-item">
															<label htmlFor="" className="label-bx88i_name">
																Email
															</label>
															<input
																type="text"
																name="email"
																defaultValue={props.user.email}
																className={`bx88i_input`}
																style={{ pointerEvents: "none" }}
															/>
															<Lock className="lock-in-input" />
														</div>
														<div className="bxppp-item">
															<label htmlFor="" className="label-bx88i_name">
																Phone Number
															</label>
															<input
																type="text"
																name="phone_number"
																defaultValue={props.user.phone_number}
																placeholder="Enter Phone Number"
																className={`bx88i_input`}
																onChange={(e) => handlerChange(e)}
															/>
															<div className="_error_i error-new-hide" id="error-phone_number" >!</div>

														</div>
														{/* <div className="bxppp-item">
															<label htmlFor="" className="label-bx88i_name">
															Organisation Name
															</label>
															<input
																type="text"
																name="email"
																defaultValue={props.user.organization.name}
																className={`bx88i_input`}
																style={{ pointerEvents: "none" }}
															/>
															<Lock className="lock-in-input" />
														</div> */}
													</div>
												</div>
												<div className="bx88i_right">
													<div className="bxppp_img-container">
														<img
															src={
																RemoveImage ? BlankProfile : File ? URL.createObjectURL(File) : props.user.profile_image && props.user.profile_image.length > 0
																	? `${props.user.profile_image}` : BlankProfile
															}
															alt="hello"
															className="bxppp_img"
														/>
														<span className="bjjjhhs_button">
															<FileInput
																accept=".jpg,.jpeg,.webp,.png"
																fileUpload={(file) => {
																	FileUploadHandler(file);
																	setFile(file);
																	setRemoveImage(false);
																}}
															>
																<img src={Camera} alt="camera" />
															</FileInput>
														</span>
														{RemoveImage && File === null
															? null : <span
																className="bjjjhhs_remove"
																onClick={() => {
																	setChangeData({
																		...ChangeData,
																		profile_image: null,
																	});
																	setRemoveImage(true);
																	document.getElementById(`error-profile_image`).innerHTML = ""

																	setFile(null);
																}}
															>
																Remove Image
															</span>}
													</div>
												</div>
											</div>
											<div className="bx8882_2 ">
												<div className="bxppp_list bcpp_lower">
													{[
														{
															name: "Calendar Link",

															lable: "calendar_url",
															value: props.user.calendar_url
																? props.user.calendar_url
																: "https://",
														},
														{
															name: "Linkedin Link",

															lable: "linkedin_url",
															value: props.user.linkedin_url
																? props.user.linkedin_url
																: "https://",
														},
														{
															name: "Facebook Link",

															lable: "facebook_url",
															value: props.user.facebook_url
																? props.user.facebook_url
																: "https://",
														},
														{
															name: "Twitter Link",
															lable: "twitter_url",
															value: props.user.twitter_url
																? props.user.twitter_url
																: "https://",
														},
													].map((d) => (
														<div className="bxppp-item">
															<label htmlFor="" className="label-bx88i_name">
																{d.name}
															</label>
															<input
																type="text"
																name={d.lable}
																defaultValue={d.value}
																className={`bx88i_input`}
																onChange={(e) => handlerChange(e)}
															/>
															<div className="_error_i error-new-hide" id={`error-${d.lable}`}>!</div>
														</div>
													))}



												</div>
											</div>

											<div className="bx8882_2 justify-content-center bjjjhhs_remove" id={`error-profile_image`}> </div>

										</div>
									</div>
								)}
							</div>
						</div>
						<div className="modal-footer vd-modal-footer">
							<span>
								<button
									className="cancel-popup-profile-edit"
									onClick={() => {
										close();
									}}
								>
									Cancel
								</button>

								<button
									type="button"
									className="popup-profile-edit-select-btn"
									data-bs-dismiss="modal"
									onClick={() => {
										handleSave();
										// close()
									}}
								>
									Save
								</button>
							</span>
						</div>
					</div>
				</div>
			)}
		</Popup>
	);
}
