import { fetcher, getById, Index, selectAll, Vid } from "../../utils";
import Hr from "../Chemistry/Atom/Hr";
import InputIcon from "../Chemistry/Atom/InputIcon";
import "./_Header.scss";
import { url } from "../../utils";
import { fabric } from "fabric";
import store from "../../store/store";
import ZoomCxx from "../Chemistry/Atom/ZoomCxx";
import ButtonIcon from "../Chemistry/Atom/ButtonIcon";
import objectCloner from "../../utils/objectCloner";
import { deleteFromAll } from "../../utils/deleteCloneOpeationObj";
import ProgressBar from "react-customizable-progressbar";
import { useEffect, useState } from "react";
import { SaveConfirm } from "./components/SaveConfirm";

import Button from "../../App/Components/Button";
import { DeletePopup } from "../TimeLine/Components/SceneBar/DeletePopup";
import { getObjectCoords } from "../../utils/cropyThings";

export default function Header() {
  useEffect(() => {

    document.addEventListener("keydown", (e) => {

      let obj = document.cxx?.[Index()]?.getActiveObject();

      let _;
      if (document.isCanActive) {
        if (!obj?.isEditing && e.ctrlKey && e.key === 'v') {
          PasteObject({ obj, e })
        } else if (e.ctrlKey && !e.shiftKey && obj) {
          // Undo Redo
          console.log(e.ctrlKey, e.key)
          if (e.key === "z") {
            e.preventDefault();

            // undoIt();
          } else if (e.key === "y") {
            e.preventDefault();

            // redoIt();
          }
          // Move Object
          else if (e.key === "ArrowUp") {
            e.preventDefault();
            _ = obj?.set({ top: obj.top - 100 });
            updateMove({ obj })
          } else if (e.key === "ArrowLeft") {
            e.preventDefault();
            _ = obj?.set({ left: obj.left - 100 });
            updateMove({ obj })
            e.preventDefault();
          } else if (e.key === "ArrowRight") {
            e.preventDefault();
            _ = obj?.set({ left: obj.left + 100 });
            updateMove({ obj })



          } else if (e.key === "ArrowDown") {
            e.preventDefault();
            _ = obj?.set({ top: obj.top + 100 });
            updateMove({ obj })



          }

          /// Object Cloning
          else if (e.key === "c") {
            CopyObject({ obj, e })
          }
          // else if (e.key === "v") {
          //   PasteObject({ obj, e })
          // }
        }
        if (!e.ctrlKey && !e.shiftKey && obj) {
          if (e.key === "ArrowUp") {
            obj.set({ top: obj.top - 2 });
            updateMove({ obj })

          } else if (e.key === "ArrowLeft") {
            obj.set({ left: obj.left - 2 });
            updateMove({ obj })

          } else if (e.key === "ArrowRight") {
            obj.set({ left: obj.left + 2 });
            updateMove({ obj })

          } else if (e.key === "ArrowDown") {
            obj.set({ top: obj.top + 2 });
            updateMove({ obj })

          }
          // deleteing the object
          else if (e.key === "Delete") {
            DeleteObject({ obj })
          } else {
            // e.preventDefault();
          }
        }
        if (!e.ctrlKey && e.shiftKey && obj) {
          if (e.key === "ArrowUp") {
            e.preventDefault();

            obj.set({ top: obj.top - 10 });
            updateMove({ obj })

          } else if (e.key === "ArrowLeft") {
            e.preventDefault();

            obj.set({ left: obj.left - 10 });
            updateMove({ obj })

          } else if (e.key === "ArrowRight") {
            e.preventDefault();

            obj.set({ left: obj.left + 10 });
            updateMove({ obj })

          } else if (e.key === "ArrowDown") {
            e.preventDefault();

            obj.set({ top: obj.top + 10 });
            updateMove({ obj })

          }
        }

        // obj.canvas.renderAll();
      }

      // console.log(e);
    });

  }, [])
  ////console.log("Header Renders");
  const handleSetName = (e) => {
    document.isCanActive = true;

    const _name = e.target.value;
    if (_name && _name.length > 0) {
      store.dispatch({ type: "RENAME", data: { name: _name } });

      fetcher({
        method: "PUT",
        url: document.editorType === "video" ? `${url}/api/newvideo/video/${Vid()}/` : `${url}/api/newimage/image/${Vid()}/`,
        data: { name: _name },
        res: (data) => {
          document.title = _name;
        },
        err: (err) => {
          console.log(`${Vid()} is Error on rename! ${err}`);
        },
      });
    } else {
      e.target.value = store.getState().name;
    }
  };

  const handleFocus = (e) => {
    // document
    // e.target.select()
    // e.target.setSelectionRange(0, e.target.value.length)
    selectAll(e)
    document.isCanActive = false;

  }

  const handleHeaderClick = (e) => {
    if (document.Player?.status && e?.target?.className !== "RightHeader_save") {
      document.Player.exit();
    }
  }

  return (
    <div className="Header" onClick={handleHeaderClick}>
      {/* {Math.random()} */}
      <img className="Logo" src={`${document.PUBLIC_URL}/app/icon.svg`} alt={"img"} />
      <InputIcon id={"name-in-img"} onFocus={handleFocus} value={store.getState().name} placeholder="name" src={null} onBlur={handleSetName} />
      <RightHeader />
    </div>
  );
}

document.me = "mein";

const undoIt = () => {
  const { arr, pointer } = store.getState().undoRedo;
  let now = arr[pointer - 1];
  if (now) {
    // let pre = arr[pointer];
    let obj = getById(now.data.id);
    const { data } = now;

    switch (now.type) {
      case "MOVE":
        obj.set(data.before);

        break;
      case "SCALE":
        obj.set(data.before);
        break;

      case "ROTATE":
        obj.set(data.before);
        break;

      case "ADD":
        obj.set(data.before);
        break;

      case "DELETE":
        break;

      default:
        break;
    }
    obj.canvas.renderAll();
    obj.canvas.fire("object:modified");

    store.dispatch({ type: "UNDO_IT", data: -1 });
  }
};

const updateMove = ({ obj }) => {
  if (obj) {
    let _;
    _ = obj?.canvas?.renderAll()
    _ = document.fxx.change[obj.id]?.(obj);
  }

}

const redoIt = () => {
  const { arr, pointer } = store.getState().undoRedo;
  let now = arr[pointer];
  if (now) {
    //console.log("now", now);
    let obj = getById(now.data.id);
    const { data } = now;
    switch (now.type) {
      case "MOVE":
        obj.set(data.after);

        break;
      case "SCALE":
        obj.set(data.after);
        break;

      case "ROTATE":
        obj.set(data.after);
        break;

      case "ADD":
        obj.set(data.after);
        break;

      default:
        break;
    }
    obj.canvas.renderAll();
    obj.canvas.fire("object:modified");

    store.dispatch({ type: "UNDO_IT", data: 1 });
  }
};

const handleDelete = () => {
  fetcher({
    method: "DELETE",
    url: document.editorType === "video" ? `${url}/api/newvideo/video/${Vid()}/` : `${url}/api/newimage/image/${Vid()}/`,
    res: (data) => {
      // alert(`${Vid()} is deleted!`);
      document.hist.push("/myvideos/")
      document.title = "AutoVid.ai"
    },
    err: (err) => {
      document.hist.push("/myvideos/")
      //alert(`${Vid()} is Error on Deleted!`);
    },
  });
};


const generateVideo = (_returnUrl, name) => {
  fetcher({
    method: "POST",
    data: { name },
    // url: `${url}/api/newvideo/video/${Vid()}/`,
    url: document.editorType === "video" ? `${url}/api/newvideo/video/generate/${Vid()}/` : `${url}/api/newimage/image/generate/${Vid()}/`,
    res: (data) => {
      // alert(`${Vid()} is deleted!`);
      if (document.editorType === "video") {

        document.location.pathname = `/myvideos/${Vid()}`;
      } else {
        window.location.href = _returnUrl;

      }
    },
    err: (err) => {
      if (document.editorType === "video") {
        document.location.pathname = '/myvideos/'
      } else {
        window.location.href = _returnUrl;

      }
    },
  });
}

const handleGenerate = async (name) => {
  store.dispatch({ type: "HAVE_VID", data: false });
  let _returnUrl = `/myimages/${Vid()}`;
  if (document.editorType === "image") {


    const _ptabData = sessionStorage.getItem(`_ptab_${Vid()}`);
    sessionStorage.removeItem(`_ptab_${Vid()}`);
    if (_ptabData) {
      const _parseData = JSON.parse(atob(_ptabData));
      if (_parseData.videoId) {
        _returnUrl = `/myimages/${_parseData.videoId}/?ptab=${btoa(JSON.stringify({ ..._parseData, imageId: Vid() }))}`;
      }
    }
  }


  generateVideo(_returnUrl, name)

  // if (name?.length > 0 || name !== store.getState().name) {
  //   fetcher({
  //     method: "PUT",
  //     url: document.editorType === "video" ? `${url}/api/newvideo/video/${Vid()}/` : `${url}/api/newimage/image/${Vid()}/`,
  //     data: { name },
  //     res: (data) => {
  //     },
  //     err: (err) => {
  //       console.log(`${Vid()} is Error on rename!`);
  //       generateVideo(_returnUrl)
  //     },
  //   });
  // } else {
  //   generateVideo();
  // }



};

function RightHeader() {
  const [isSaved, setSave] = useState(false);
  const [pro, setPro] = useState(0);
  const handleSave = async () => {
    if (document.Player?.status) {
      await document.Player.exit();
    }
    setSave(true);
    store.dispatch({ type: "ALL_CANVAS_JSON" })

    // setPro(50);
    setTimeout(() => {
      // setSave(false);
      setPro(50);
    }, 0);


    fetcher({
      method: "POST",
      url: document.editorType === "video" ? `${url}/api/newvideo/video/${Vid()}/` : `${url}/api/newimage/image/${Vid()}/`,
      data: { d: JSON.stringify(store.getState().scenes) },
      res: (data) => {
        //console.log(data.data);
        // setSave(false)
        setPro(100);
        setTimeout(() => {
          setSave(false);
          setPro(0);
        }, 500);
      },
    });
  };


  // document.saveFull = saveFull;

  const [confirm, setConfirm] = useState(false);


  return (
    <div className="RightHeader">
      {/* <ZoomCxx /> */}
      <Hr h="30px" m="25px" />
      <ButtonIcon
        src={`${document.PUBLIC_URL}/ico/clone.svg`}
        value="Dupicate"
        onClick={(e) => {
          e.target.style.opacity = 0.5;
          fetcher({
            url: document.editorType === "video" ? `${url}/api/newvideo/video/copy/${Vid()}/` : `${url}/api/newimage/image/copy/${Vid()}/`,
            res: (res) => {
              // console.log(res.data.result.id);
              e.target.style.opacity = 1;

              window.open(`/${document.editorType}/${res.data.result.id}/0/avatar`, "_blank");
            },
          });
        }}
      />
      {/* <Hr h="0" m="10px" />
      <img src={`${document.PUBLIC_URL}/icons/undoActive.svg`} alt={"img"} onClick={undoIt} />

      <Hr h="0" m="10px" />
      <img src={`${document.PUBLIC_URL}/icons/redoInactive.svg`} alt={"img"} onClick={redoIt} /> */}
      <Hr h="30px" m="25px" />
      <img src={`${document.PUBLIC_URL}/icons/delete.svg`} alt={"img"} onClick={() => {
        setConfirm(true);
      }} />
      <DeletePopup text={"Video"} isConfirm={confirm} setConfirm={setConfirm} onDelete={handleDelete} onCancel={() => {
        setConfirm(false);
      }}></DeletePopup>
      <Hr h="0" m="0px" />
      {!isSaved ? (
        <img
          className="RightHeader_save"
          src={`${document.PUBLIC_URL}/icons/save.svg`}
          alt={"img"}
          onClick={handleSave}
        />
      ) : (
        <ProgressBar
          radius={10}
          progress={pro}
          strokeColor="#e7416a"
          strokeWidth={3}
          trackStrokeWidth={4}
          trackStrokeColor="rgba(0,0,0,0)"
        />
      )}

      <Hr h="0" m="0px" />
      <SaveConfirm onGenerate={
        handleGenerate} />

    </div>
  );
}

function getObjectsActualCoordsOffest(groupObj) {
  let _res = {};
  groupObj._objects.forEach((obj) => {
    let matrix = obj.calcTransformMatrix();
    _res[obj.id] = { left: matrix[4], top: matrix[5] };
  })
  return _res
}

export const CopyObject = ({ obj, e = null }) => {
  if (obj) {
    if (obj?._Type === "text" && obj?._Editable) {
    } else {
      if (e) {

        e.preventDefault();
      }

      if (obj?._Type === "background") {
      } else {
        document.zx.toBeCopy = obj.id;
        document.zx.toBeCopyIndex = Index();
        document.zx.toBeCopyType = obj._Type
        if (obj?._Type === "group") {
          // let arr = 
          //save objects location for manual paste
          document.zx._groupCoords = { left: obj.left, top: obj.top };

          document.zx.toBeCopyArrIds = obj._objects.map(o => o.id);
          let arr = document.zx.toBeCopyArrIds;
          let brr = obj.canvas._objects.map(o => o.id);
          let crr = []
          while (arr.length) {
            let a = arr.shift();
            crr.push({ i: brr.findIndex(e => e === a), id: a })
          }
          crr.sort((xx, yy) => xx.i - yy.i)
          console.log('objectgorup', crr)
          document.zx.toBeCopyArrIds = crr.map(e => e.id);

        }

      }
    }
  }
}

export const PasteObject = ({ obj, e = null, pos }) => {
  if (e) {
    e.preventDefault();
  }

  if (obj?._Type === "text" && obj?._Editable) {
    return 0;
  }

  if (document.zx?.toBeCopyType !== undefined) {
    if (document.zx.toBeCopyType === "group") {
      setTimeout(async () => {
        document.cxx[document.zx.toBeCopyIndex].discardActiveObject()
        let _pos;
        const zo = document.cxx[document.zx.toBeCopyIndex].getZoom();
        if (pos) {
          _pos = { x: (pos.x / zo) - document.zx._groupCoords.left, y: (pos.y / zo) - document.zx._groupCoords.top }
        }

        for (let _ii = 0; _ii < document.zx.toBeCopyArrIds.length; _ii++) {
          const _id = document.zx.toBeCopyArrIds[_ii];
          let _obj = getById(_id);
          if (_obj) {
            let _objPos;
            if (_pos) {
              _objPos = { x: (_obj.left + _pos.x) * zo, y: (_obj.top + _pos.y) * zo };
            }
            await objectCloner({
              _id,
              where: Index(),
              from: document.zx.toBeCopyIndex,
              clone: true,
              pos: _objPos
            });
          }

        }
      }, 0)
    } else {
      objectCloner({
        _id: document.zx.toBeCopy,
        where: Index(),
        from: document.zx.toBeCopyIndex,
        clone: true,
        pos
      });
    }
  }
}

export const DeleteObject = ({ obj }) => {
  if (obj) {

    if (obj._Type === "avatar" || obj._Type === "background") {
    } else {
      if (obj._Type === "group") {
        obj._objects.forEach(e => {
          if (e._Type === "avatar" || e._Type === "background") {

          } else {

            deleteFromAll(e.id);
          }

        })
      } else {

        deleteFromAll(obj.id);
      }

    }
  }

}