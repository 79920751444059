/* eslint-disable */
// import $ from "jquery";
import React, { useState, useEffect } from "react";
import { login as LoginNow } from "./actions/auth";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
// import { ReactComponent as Google } from "../../extras/misc/logo-google.svg";
import GoogleAuth from "./social-auth/GoogleHooks";

import { ReactComponent as CrossError } from "../App/extras/misc/loginerrorcross.svg";
import mystore from "../App/appStore";
import { fetcher, url } from "../utils";
function Login(props) {
	const [Details, setDetails] = useState({
		email: props.location && props.location.email ? props.location.email : "",
		password: "",
	});

	const [Errors, setErrors] = useState({ isError: false });
	const [PasswordChange, setPasswordChange] = useState({ isChanged: false });
	const [EmailVerified, setEmailVerified] = useState({ isChanged: false });
	const [template, setTemplate] = useState({ status: false, id: null });

	useEffect(() => {
		const messages = {
			email: {
				0: "Email is Blocked.",
				1: "Email is not Verified.",
				2: "Email is not linked with any organisation.",
			},
			password: {
				0: "Incorrect Email or Password",
			},
		};

		if (props.auth && props?.auth?.isError) {
			var obj;

			Object.keys(props.auth.message).map((d) => {
				obj = {
					isError: true,
					...obj,
					message: messages[d][props.auth.message[d].status],
				};
			});

			setErrors(obj);
		} else {
			setErrors({
				isError: false,
			});

			if (props.auth.isAuthenticated) {
				HandleTemplateAdd();
			}
		}
	}, [props.auth]);

	useEffect(() => {
		if (props.location.passwordChange) {
			setPasswordChange({
				isChanged: true,
				message: "Login to continue.",
			});
		}

		if (props.location.emailVerified) {
			setEmailVerified({
				isChanged: true,
				message: "Login to continue.",
			});
		}
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		let Templating = params.template;
		let RedirectUrl = params.redirect;

		if (Templating) {
			setTemplate({
				status: true,
				id: Templating,
			});

			// console.log(value);
		}
		if (RedirectUrl) {
			setTemplate({
				status: "redirect",
				id: RedirectUrl,
			});
			console.log(RedirectUrl);
		}
	}, []);

	// console.log(template);

	const handlerSubmit = (e) => {
		setEmailVerified({
			isChanged: false,
		});
		var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		if (Details.email.match(mailformat)) {
			props.LoginNow(Details.email, Details.password);
			// 	console.log(Details.email, Details.password);
		} else {
			document.getElementById("email-input").classList.add("errorInput");
		}
	};

	const handleChange = (e) => {
		e.target.classList.remove("errorInput");
		setErrors({ isError: false });
		setDetails({ ...Details, [e.target.name]: e.target.value });
	};

	const ResetErrorState = () => {
		mystore.dispatch({ type: "REST_ERROR_STATE" });
	};

	const HandleTemplateAdd = () => {
		if (template.status === "redirect" && localStorage.getItem("token")) {
			window.open(`${template.id}`, "_self");
		} else if (template.status && localStorage.getItem("token")) {
			fetcher({
				header: {
					headers: {
						Authorization: `Token ${localStorage.getItem("token")}`,
					},
				},
				url: `${url}/api/newvideo/video/copy/${template.id}/`,
				res: (res) => {
					// console.log(res);
					window.open(`/video/${res.data.result.id}/`, "_self");
					//window.open(`/`, "_self");
				},
			});
		} else window.location.href = "/";
	};

	return (
		<div className="login-container">
			<div className="login-wrapper">
				<div className="login-inner-wrapper">
					{/* <div className="login-gp-1">
						<GoogleAuth />
						
					</div>

					<h6 className="word-btw-line login">
						<span>OR</span>
					</h6> */}

					<div className="login-form">
						{Errors.isError ? (
							<div className="error-login-info">
								<label htmlFor="" className="error-login-info-heading">
									Error !
								</label>
								<label htmlFor="" className="error-login-info-label">
									{Errors.message}
								</label>
								<CrossError
									className="pointer"
									onClick={() => {
										setErrors({
											...Errors,
											isError: false,
										});
									}}
									style={{ position: "absolute", right: "16px", top: "35%" }}
								/>
							</div>
						) : null}
						{PasswordChange.isChanged ? (
							<div className="error-login-info">
								<label htmlFor="" className="error-login-info-heading">
									Password Changed
								</label>
								<label htmlFor="" className="error-login-info-label">
									{PasswordChange.message}
								</label>
								<CrossError
									className="pointer"
									onClick={() => {
										setPasswordChange({
											isChanged: false,
										});
									}}
									style={{ position: "absolute", right: "16px", top: "35%" }}
								/>
							</div>
						) : null}
						{EmailVerified.isChanged ? (
							<div className="error-login-info">
								<label htmlFor="" className="error-login-info-heading">
									Email verified!
								</label>
								<label htmlFor="" className="error-login-info-label">
									Login to continue.
								</label>
								<CrossError
									className="pointer"
									onClick={() => {
										setEmailVerified({
											isChanged: false,
										});
									}}
									style={{ position: "absolute", right: "16px", top: "35%" }}
								/>
							</div>
						) : null}
						<input
							id="email-input"
							type="email"
							className="login-item"
							placeholder="Email"
							name="email"
							defaultValue={
								props.location && props.location.email
									? props.location.email
									: ""
							}
							onChange={(e) => {
								handleChange(e);
							}}
						/>
						<input
							id="password-input"
							type="password"
							className="login-item"
							placeholder="Password"
							name="password"
							onChange={(e) => {
								handleChange(e);
							}}
						/>
					</div>

					<div className="login-footer">
						<button
							className="login-main-button"
							onClick={(e) => {
								handlerSubmit(e);
							}}
						>
							Login
						</button>

						<label className="login-main-label">
							Don’t have an account?{" "}
							<Link
								onClick={() => ResetErrorState()}
								to={{
									pathname: "/register",
									e: "xsznk",
								}}
								className="link-custom"
							>
								Create Account
							</Link>
						</label>
						<Link
							to="/forgotpassword"
							className="link-custom forget-login-main"
						>
							Forgot Password?
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = () => ({
	isAuthenticated: mystore.getState()?.auth?.isAuthenticated,
	auth: mystore.getState().auth,
});
export default connect(mapStateToProps, { LoginNow })(Login);
