import React, { useState, useEffect } from "react";
import ProgressBar from "react-customizable-progressbar";

export default function RenderWrapper({ progress, status, message, isLoading = true }) {

  const Message = {
    0: { message: message || "Something Wrong happend! Please try again." },
    1: { message: "This should not come : bug! bug! bug!" },
    2: { message: "Generating Video..." },
    3: { message: "Added in Queue..." },
    99: { message: "Pending..." }, // miscellaneous status
  }
  if (isLoading) {
    return <div className="VideoDetail_detail_thumb_render" style={{ background: "#242424" }}>
      <div className="INFINITELOAD">
        <img alt="Loading" src={`${document.PUBLIC_URL}/icons/loading.gif`} />
      </div>
    </div>
  } else if (status === 3 || status === 0 || (progress >= 0 && status === 2)) {
    return (
      <div className="VideoDetail_detail_thumb_render">
        {status === 2 ? <ProgressBar
          progress={progress}
          radius={80}
          strokeColor="#e7416a"
          strokeWidth={10}
          trackStrokeWidth={10}
          initialAnimation={true}
          transition="1.5s ease 0.5s"
          trackTransition="0s ease"
        >
          <div className="indicator">
            <div>{progress} %</div>
          </div>
        </ProgressBar> : status === 3 ? <div class="loading_infinite">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4335 4335" width="164px" height="164px">
            <path fill="#e7416a" d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
            />
          </svg>
        </div> : <></>}
        <div className="VideoDetail_detail_thumb_render_title">
          {Message[status].message}
        </div>
        {/* <div className="VideoDetail_detail_thumb_render_time">
        2 Min 30 Sec Remaining
      </div> */}
      </div>
    );
  } else if (!isLoading && status !== 1) {
    return (<div className="VideoDetail_detail_thumb_render">
      {<div class="loading_infinite">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4335 4335" width="164px" height="164px">
          <path fill="#e7416a" d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
          />
        </svg>
      </div>}
      <div className="VideoDetail_detail_thumb_render_title">
        {Message[99].message}
      </div>
      {/* <div className="VideoDetail_detail_thumb_render_time">
          2 Min 30 Sec Remaining
        </div> */}
    </div>)
  } else {
    return <></>
  }
}
