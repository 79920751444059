import store from "../../../../../store/store";
import { fabric } from "fabric";
import { getCordinate, Index, Jump } from "../../../../../utils";

import { addBackground } from "../../Background/functions/changeBackground";
import { InitAddObjectEvents } from "../../../../../utils/InitAddObjectEvents";

import addLoopMusicToNewScene from "../../Music/functions/addLoopMusicToNewScene";


export default function addAvatarToScreenFirstTime({
  index = 0,
  onlyAvatar = false,
  addMusic = true,
}) {
  const { currentAvatar } = store.getState().scenes;
  const cxx = document.cxx[index];
  if (!onlyAvatar) {
    addBackground.insert(cxx);
  }
  store.dispatch({ type: "SET_ACTIVE_OBJECT", data: "" });
  let mode = store.getState().scenes.mode;
  fabric.Image.fromURL(
    currentAvatar.image.transparentImage,
    (img) => {
      img.set({
        id: currentAvatar.id,
        ...document.selectionSettings,
      });
      img.setControlsVisibility(document.selectionSettings);

      const { ax, ay, scale } = getCordinate(
        0,
        2,
        currentAvatar.image.avatarConfig
      );

      img.set({
        left: ax,
        top: ay,
        scaleX: scale,
        scaleY: scale,
        id: `avatar${index}`,
        visible: !mode,
        _Hidden: mode,
      });


      cxx.add(img);

      cxx.discardActiveObject();
      InitAddObjectEvents({
        obj: img,
        _id: `avatar${index}`,

        name: `Avatar ${Index()}`,
        type: "avatar",
        returnToUrl: false,
        onAddActive: !mode,
        index: Index(),
        timeLineData: {
          enter: 0,
          exit: 0,
        },
      });

      document.smallScene.draw(index);

      if (addMusic) {
        addLoopMusicToNewScene({ index });
      }


      cxx.renderAll();
    },
    {
      crossOrigin: "anonymous",
    }
  );


  // use to jump to background after adding the scene in non avatar video
  if (mode) {
    setTimeout(() => {
      document.cxx[Index()].discardActiveObject();
      document.hist.push(
        Jump({
          obj: {},
          def: true,
        })
      );
    }, 500);
  }
}

export const addMissingAvatar = () => {
  console.log(document.missingAvatar);
};
