import { controlsByObject, Index, Jump, Vid } from "../utils";
import store from "../store/store";
import { cxxtoJSON } from "./config";
let corner = ["tl", "tr", "br", "bl"];
let center = ["ml", "mr", "mt", "mb"];
let rotate = ["mtr"];
let label = ["labelControl"];
let final = [...corner, ...center, ...rotate];
let finalObjFalse = final.reduce((a, v) => ({ ...a, [v]: false }), {});
let finalObjTrue = final.reduce((a, v) => ({ ...a, [v]: true }), {});
// console.log(finalObj);
export const CxxEvents = ({ cxx, index }) => {
  cxx.on("mouse:up:before", (e) => { });
  cxx.on("mouse:up", (e) => {
    // console.log(e);
  });

  cxx.on("mouse:down", (e) => {
    let obj = cxx.getActiveObject();
    document.isCanActive = true;
    document.render = false;
    cxx.renderAll();
    // console.log(obj._Type);
    if (obj) {
      let id = obj.id;
      if (!document.Disable) {
        // e.target.setControlsVisibility(finalObjTrue);
        controlsByObject({ obj: e.target });

        document.preId = id;
        document.hist.push(
          Jump({
            obj,
          }),
          { id }
        );

        setTimeout(function CxxEventsActive() {
          store.dispatch({
            type: "SET_ACTIVE_OBJECT",
            data: obj.id,
          });
        }, 0);
      }
    } else {
      setTimeout(function CxxEventsActive() {
        store.dispatch({
          type: "SET_ACTIVE_OBJECT",
          data: "",
        });
      }, 0);
      document.hist.push(Jump({ def: true }));
    }
  });

  cxx.on("object:modified", (e) => {
    if (document.Save.save) {
      store.dispatch({
        type: "CANVAS_JSON_INDEX",
        data: {
          index,
          json: cxx.toJSON(cxxtoJSON),
        },
      });
    }

  });

  cxx.on("mouse:over", function (e) {
    if (e.target) {
      if (e.target !== cxx.getActiveObject()) {
        if (!document.dontMoveHover || true) {
          controlsByObject({ obj: e.target, type: "title" });
          document.currentObj = {
            have: true,
            id: e.target.id,
          };

          e.target._renderControls(e.target.canvas.contextTop, {
            hasControls: true,
          });
        }

        // e.target.canvas.renderAll();
      } else {
        if (cxx.getActiveObject()) {
          document.currentObj = {
            have: true,
            id: e.target.id,
          };
        }
      }
    }
  });

  cxx.on("mouse:out", function (e) {
    if (e.target) {
      if (!document.dontMoveHover || true) {
        e.target.canvas.clearContext(e.target.canvas.contextTop);
        document.currentObj = {
          have: false,
          id: "",
        };
        cxx.renderAll();
      }

      // document.currentObj.have = false;
      // document.currentObj({
      //   have: false,
      //   id: "",
      // });
    }
  });
};
