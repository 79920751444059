/* eslint-disable */
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import uuid from "uuid-random";

import produce from "immer";
import { arrayMoveImmutable } from "array-move";
import {
  defaultDurationLengthInSec,
  getById,
  updateTimeLen,
  Vid,
  updateOffsetArr,
  url,
  headerA,
  tryMe,
  Jump,
} from "../utils";
import { initState } from "./initState";
import { cxxtoJSON, OFFLINEID } from "../utils/config";
import elementObjToURL from "../utils/elementObjToURL";
import axios from "axios";

import { arrayMove } from "react-sortable-hoc";
import { LOAD_FIRST } from "./case/LOAD_FIRST";
import { CURRENT_AVATAR } from "../components/LeftPanel/components/Avatar/functions/replaceAvatar";
import { ADJUST } from "./case/ADJUST";
import { WORKSPACE_RESIZE } from "./case/WORKSPACE_RESIZE";
import { EDIT_URL_NEW_MERGE_TEXTBOX } from "./case/EDIT_URL_NEW_MERGE_TEXTBOX";
import { ADD_OBJECT } from "./case/ADD_OBJECT";

const reducer = produce((state = initState, action) => {
  const { data } = action;
  const id = uuid();
  const sceneNumber = Number(document.location.pathname.split("/")[3] || 0);
  const scenes = state.scenes[sceneNumber];
  let k;
  switch (action.type) {
    case "SET_INIT_DATA":
      return {
        ...state,
        ...data,
      };

    case "IMG_EDITOR":
      state.isImage = true;
      break;

    case "OFFLINE_DATA_SET":
      state.media = data.media;
      state.avatar = data.avatar;
      state.active.leftLoading = !state.active.leftLoading;
      break;

    case "MUSICC_REMOVE":
      state.scenes.musicRemove = data;
      break;

    case "RANDOM":
      state.random = Math.random();
      break;

    case "SET_INIT_ELEMENT":
      state.media.gradient.list = data.gradient;

      state.media.fonts.list = data.fonts;

      state.scenes.colorArr = data.colors;

      state.media.mergeTag.list = data.mergeTag;
      document._mtag = data.mergeTag;

      state.active.leftLoading = false; //!state.active.leftLoading;

      break;

    case "ADD_MODE":
      state.scenes.mode = data;
      state.scenes[0].mode = data ? 2 : 0;
      document._TabName = data ? "background" : "avatar";
      document.documentElement.style.setProperty(
        "--rigthWidth",
        data ? "0" : "334px"
      );
      break;

    case "SET_AVATAR_FRAME":
      state.active.setFrame = data;
      break;

    case "CANVAS_JSON":
      scenes.jsonData = elementObjToURL(data);
      break;
    case "COMPLETE_DELETE":
      delete state.scenes[data.number];
      break;

    case "NOWSANIMATION":
      state.sanimation = data.index;
      break;

    case "CANVAS_JSON_INDEX":
      state.scenes[data.index].jsonData = elementObjToURL(data.json);
      break;

    case "ALL_CANVAS_JSON":
      JSON.parse(state.scenes.currentScene.arr).forEach((e) => {
        state.scenes[e].jsonData = elementObjToURL(
          document.cxx[e].toJSON(cxxtoJSON)
        );
      });
      break;

    case "RENAME":
      state.name = data.name;
      break;

    case "LOAD_FIRST":
      LOAD_FIRST({ state, data });
      break;

    case "UNDO_REDO_ADD":
      state.undoRedo.arr = state.undoRedo.arr.splice(0, state.undoRedo.pointer);
      state.undoRedo.arr.push(data);
      state.undoRedo.pointer += 1;
      break;

    case "UNDO_IT":
      state.undoRedo.pointer += data;

      break;

    case "EDIT_COLOR":
      axios
        .post(
          `${url}/api/colors/`,
          {
            colors: data.join(","),
          },
          headerA
        )
        .then((e) => {
          //console.log(e);
        });

      state.scenes.colorArr = data;
      break;

    case "SET_ACTIVE_OBJECT":
      // state.active.music = data;

      document.objActive = data;
      state.active.objActive = data;
      break;

    case "SET_HOVER_ACTIVE_OBJECT":
      state.active.hoverObjActive = data;
      break;

    case "SCENE_DISABLED":
      state.disable.scene = data;
      break;

    case "CURRENT_AVATAR":
      CURRENT_AVATAR({ state, data });

      break;

    case "ALL_LOAD":
      state.disable = {
        ...state.disable,
        ...data,
      };
      document.Disable = Object.values(state.disable).some((e) => e);
      break;

    case "SET_LANG":
      state.scenes.currentAvatar.lang = data;
      break;

    case "MEDIA_WITH_NEXT":
      state.media[data.type].next = data.next || null;
      // handle uniqueness
      if (!document._media_unique[data.type]) {
        document._media_unique[data.type] = {};
      }
      let _newList = [];
      data.list.forEach((e) => {
        if (!document._media_unique[data.type][e.id]) {
          document._media_unique[data.type][e.id] = true;
          _newList.push(e);
        }
      });

      if (!data.res) {
        state.media[data.type].list.push(
          ..._newList.map((e) => ({
            ...e,
            _Type: data.type,
          }))
        );
      } else {
        state.media[data.type].list = _newList.map((e) => ({
          ...e,
          _Type: data.type,
        }));
      }

      break;

    case "MEDIA_WITH_NEW":
      // STORE ID FOR UNIQUENESS
      document._media_unique[data.type] = {};
      data.list.forEach((e) => {
        document._media_unique[data.type][e.id] = true;
      });

      state.media[data.type].next = data.next;
      state.media[data.type].list = data.list.map((e) => ({
        ...e,
        _Type: data.type,
      }));

      break;

    case "MEDIA_WITH_FRONT":
      state.media[data.type].list.splice(0, 0, {
        ...data.list[0],
        _Type: data.type,
      });

      break;

    case "CHANGE_BACKGROUND":
      scenes.background.type = data.type;
      scenes.background.current = data.current;

      break;

    case "ADD_NEW_MERGE_TEXTBOX":
      k = JSON.parse(scenes.speech.arr);
      k.splice(data + 1, 0, id);
      scenes.speech.arr = JSON.stringify(k);
      scenes.speech.data[id] = {
        text: "",
        haveDelay: true,
        delay: 1,
        id,
        duration: 0,
        url: "",
      };
      scenes.speech.data[k[0]].haveDelay = false;

      break;

    case "DELETE_NEW_MERGE_TEXTBOX":
      k = JSON.parse(scenes.speech.arr);

      if (k[0] === data) {
        if (k.length === 1) break;
      }
      let _newArr = k.filter((e) => e !== data);
      delete scenes.speech.data[data];
      scenes.speech.data[_newArr[0]].haveDelay = false;
      scenes.speech.arr = JSON.stringify(_newArr);
      state.config.speechLoad = !state.config.speechLoad;

      break;

    case "EDIT_NEW_MERGET_TEXTBOX":
      if (1) {
        let field = state.scenes[data.i].speech.data[data.index];

        if (field) {
          state.scenes[data.i].speech.data[data.index].text = data.text;
          state.scenes[data.i].speech.data[data.index].length = data.length;
        }
        state.config.speechLoad = !state.config.speechLoad;
        state.scenes.speechChangedAt = data.i;
      }

      // state.scenes.config.speech = true;
      break;

    case "SPEECH_DISABLE":
      // state.config.speech = false;
      state.scenes.speechChanged = data;

      break;
    // case "":

    case "EDIT_PUSH":
      scenes.speech.data[data.index] = {
        ...scenes.speech.data[data.index],
        ...data,
      };
      state.config.speechLoad = !state.config.speechLoad;

      break;

    case "ENABLE_PRO_MODE":
      scenes.haveEasyMode = false;

      break;

    case "ADJUST":
      ADJUST({ state, data, scenes, sceneNumber });
      break;

    case "WORKSPACE_RESIZE":
      WORKSPACE_RESIZE({ state, data, sceneNumber });
      break;

    case "EDIT_URL_NEW_MERGE_TEXTBOX":
      EDIT_URL_NEW_MERGE_TEXTBOX({ state, data, sceneNumber, scenes });
      break;

    case "ZERO_SOUND":
      // scenes.speech.data[e._id].url = e.sound;
      // scenes.speech.data[e._id].duration = e.soundDuration;

      if ("EASYMODE" && scenes.haveEasyMode) {
        let nexts = defaultDurationLengthInSec;
        // let preMax = state.beforeMax || 5;
        let _n = (e) => Number(Number(e).toFixed(2));

        const { stayTime, enterDelay } =
          scenes.elements[`avatar${sceneNumber}`];
        let ratio = (nexts + enterDelay) / (stayTime + enterDelay);

        Object.keys(scenes.elements).forEach((e) => {
          let ele = scenes.elements[e];
          if (e) {
            let obj = getById(e);
            if (
              obj._Type !== "music" &&
              obj._Type !== "background" &&
              obj._Type !== "avatar"
            ) {
              if (
                _n(ele.stayTime + ele.enterDelay) === _n(stayTime + enterDelay)
              ) {
                // ele.enterDelay = _n(ele.enterDelay * ratio);
                // ele.stayTime = _n(ele.stayTime * ratio);
                ele.stayTime = _n(nexts + enterDelay - ele.enterDelay);
              } else {
              }
            }
          }
        });
      }
      scenes.elements[`avatar${sceneNumber}`].stayTime =
        defaultDurationLengthInSec;
      document.getElementById(`avatar${sceneNumber}`).src = undefined;
      state.config.speechLoad = false;

      break;

    case "SWITCH_SOUND":
      scenes.speech.type = data;
      if (data === "type") {
        scenes.speech.sound = {};
      }
      break;
    case "SET_SOUND":
      scenes.speech.sound = data;
      document.getElementById(`avatar${sceneNumber}`).src = data.media_file;
      scenes.elements[`avatar${sceneNumber}`] = {
        ...scenes.elements[`avatar${sceneNumber}`],
        stayTime: data.fileInfo.duration,
        duration: data.fileInfo.duration,
      };
      break;

    case "ADD_SCENE":
      k = JSON.parse(state.scenes.currentScene.sceneArr);

      let _len = k.length;
      if (k.find((e) => e === _len)) {
        _len = _len + 1;
      }
      if (data.next) {
        _len = data.next;
      }

      if (1) {
        let kk = JSON.parse(state.scenes.currentScene.arr);
        kk.push(_len);
        state.scenes.currentScene.arr = JSON.stringify(kk);
        document.sceneNumberFromIndex = kk.reduce(
          (a, b, i) => ({ ...a, [b]: i }),
          {}
        );
      }
      // document.oxx[_len] = {}
      state.scenes[_len] = {
        background: {
          type: "color",
          current: {
            id: "#ffffff",
          },
        },
        jsonData: {},

        speech: {
          /**
           * type: type, upload, record
           */
          type: "type",
          data: {
            [id]: {
              text: "",
              haveDelay: false,
              delay: 0,
              duration: 0,
              url: "",
            },
          },
          sound: {},
          arr: JSON.stringify([id]),
        },
        elements: {},
        elementList: "[]",
        media: {
          video: `[]`,
          music: `[]`,
        },
        haveEasyMode: true,
        // mixedAvatar: false,
        mode: state.scenes.mode ? 2 : 0,
        sanimation: {},
      };

      // k.splice(data.index, 0, _len);
      k.push(_len);
      state.scenes.currentScene.sceneArr = JSON.stringify(k);

      break;

    case "COPYDOM":
      document.dom = JSON.stringify(state);
      break;

    case "SMODE":
      if (scenes) {
        scenes.mode = data;
      }
      break;

    case "SCENE_ACTIVE":
      state.sceneNumber = data;
      document.index = data;
      document.preId = "";
      // updateTimeLen(false, state, data);
      // state.config.offSetArr = updateOffsetArr(state);

      break;

    case "PARTIAL_SCENE_ACTIVE":
      state.sceneNumber = data;
      break;

    case "SORT_OBJECT":
      if (1) {
        // const
        let nonAv = (e) => (scenes.mode === 2 ? e._Type !== "avatar" : true);
        let sorted = arrayMoveImmutable(
          document.cxx[sceneNumber]._objects
            .map((e) => e)
            .filter(nonAv)
            .reverse(),
          data.oldIndex,
          data.newIndex
        );
        if (data.video) {
          document.cxx[sceneNumber]._objects = arrayMoveImmutable(
            document.cxx[sceneNumber]._objects.map((e) => e).filter(nonAv),
            data.oldIndex,
            data.newIndex
          );
        } else {
          let back = sorted.find((e) => e._Type === "background");
          sorted = sorted.filter((e) => e._Type !== "background");
          // console.log(sorted);
          // sorted.push(back);
          sorted.push(back);
          // console.log(sorted);
          document.cxx[sceneNumber]._objects = sorted.reverse();
        }

        document.cxx[sceneNumber].renderAll();

        scenes.elementList = JSON.stringify(sorted.map((e) => e.id).reverse());
        // document.cxx[sceneNumber].fire("object:modified");
      }

      break;

    case "ADD_SORT_OBJECT":
      scenes.elementList = JSON.stringify(data);
      break;

    case "UPDATE_ELEMENT_LIST":
      // if (1) {
      //   let elist = document.cxx[data.index]
      //     .getObjects()
      //     .map((e) => e.id)
      //     .reverse();
      //   state.scenes[data.index].elementList = JSON.stringify(elist);
      //   let newScene = {};
      //   elist.forEach((e) => {
      //     newScene[e] = state.scenes[data.index].elements[e];
      //   });
      //   state.scenes[data.index].elements = newScene;
      // }

      break;

    case "ADD_SIZE":
      state.scenes.size = data;
      break;

    case "ADD_OBJECT":
      ADD_OBJECT({ state, data, scenes, sceneNumber });

      break;

    case "DELETE_OBJECT":
      if (data.index === undefined) {
        data.index = sceneNumber;
      }
      if (document.txx.tweens[data.index]?.[data.id]) {
        delete document.txx.tweens[data.index][data.id];
      }
      if (state.scenes[data.index]?.elements?.[data.id]) {
        delete state.scenes[data.index].elements[data.id];
      }
      state.scenes[data.index].elementList = JSON.stringify(
        document.cxx[data.index]
          .getObjects()
          .map((e) => e.id)
          .reverse()
      );
      if (!data.isUpdateRoute) {
        if (data.type === "video" || data.type === "music") {
          setTimeout(() => {
            document.hist.push(
              `/${document.editorType}/${Vid()}/${sceneNumber}/${document._TabName
              }`
            );
          }, 500);
        }
      }

      break;

    case "SET_FRAME":
      state.frameActive = data;

      break;

    case "UPDATE_TIME_LINE":
      state.per = data.value;
      let old = document.per;
      document.per = data.value;
      document._Max = (document._Max * document.per) / old;
      // updateTimeLen();
      document.setWx((e) => document._Max);
      updateTimeLen(false, state, null, document._Max);

      state.config.offSetArr = updateOffsetArr(state);
      break;

    case "TAB_CHANGE":
      state.tabName = data;
      break;

    case "DRAG_RESIZE":
      tryMe(() => {
        scenes.elements[data.id] = {
          ...scenes.elements[data.id],
          ...data.data,
        };
        const {
          width,
          enter: enter1,
          exit: exit1,
          stayTime: stayTime1,
        } = scenes.elements[data.id];

        scenes.elements[data.id] = {
          ...scenes.elements[data.id],
          width: (enter1 + exit1 + stayTime1) * state.per,
        };

        updateTimeLen(false, state);

        state.config.offSetArr = updateOffsetArr(state);

        // console.log("dragResize");
        if (!scenes.elements[data.id].chheda) {
          // if (getById(data.id)._Type === "")
          // scenes.elements[data.id].adjustLength = 4;
          scenes.elements[data.id].chheda = true;
        }
      }, "DragResize");

      break;

    case "SET_ACTIVE_MUSIC":
      state.active.music = data;
      break;

    case "SET_ACTIVE":
      // state.active.music = data;

      state.scenes[data.type] = data;
      break;

    case "TOGGLE_LOADING":
      state.config.ScreenLoading = !state.config.ScreenLoading;
      document
        .querySelectorAll(".ScreenLoading")
        .forEach(
          (e) =>
            (e.style.display = state.config.ScreenLoading ? "flex" : "none")
        );
      break;

    case "LOADING":
      state.config.ScreenLoading = !state.config.ScreenLoading;
      state.config.loadingText = data;
      break;

    case "LLOAD":
      state.active.leftLoading = !state.active.leftLoading;

      break;

    case "ADD_SCENE_JSON_FILL":
      state.scenes[data.index] = data.json;
      break;

    case "DELETE_SCENE":
      tryMe(() => {
        let _xp = JSON.parse(state.scenes.currentScene.sceneArr);
        // _xp[data.number] = -1;
        let index = _xp.findIndex((e) => Number(e) === Number(data.number));
        _xp[index] = -1;

        state.scenes.currentScene.sceneArr = JSON.stringify(_xp);

        // _xp.splice(index, 1);
        // delete state.scenes[data.number];

        //jump to other
        document.objActive = "";
        state.active.objActive = "";
        document.hist.push(
          Jump({
            obj: {},
            def: true,
            _mode: state.scenes.mode,
          })
        );

        // time delte
        let p = document.txx.timeLine[data.number]?.kill();
        delete document.txx.timeLine[data.number];

        if (1) {
          let GG = JSON.parse(state.scenes.currentScene.arr).filter(
            (e) => Number(e) !== Number(data.number)
          );

          state.scenes.currentScene.arr = JSON.stringify(GG);
          document.sceneNumberFromIndex = GG.reduce(
            (a, b, i) => ({ ...a, [b]: i }),
            {}
          );
        }
      }, "DELETE_SCENE");

      break;

    case "EDIT_ELEMENT":
      tryMe(() => {
        if (getById(data.id)?._Type === "music") {
          JSON.parse(state.scenes.currentScene.arr).forEach((e) => {
            if (state.scenes[e].elements[data.id]) {
              const _maxTime = document._Max / document.per;
              state.scenes[e].elements[data.id] = {
                ...state.scenes[e].elements[data.id],
                ...data.data,
                stayTime: _maxTime,
              };
            }
          });
        } else {
          let dex = data.index !== undefined ? data.index : sceneNumber;
          state.scenes[dex].elements[data.id] = {
            ...state.scenes[dex].elements[data.id],
            ...data.data,
          };
          updateTimeLen(false, state);
        }

        // updateTimeLen();

        state.config.offSetArr = updateOffsetArr(state);
      }, "DeitEle");

      break;

    case "ADD_ANIMATION":
      if (!data.onlyDuration) {
        scenes.elements[data.id].animation = {
          ...scenes.elements[data.id].animation,
          [data.animation.type]: data.animation,
        };
      }
      scenes.elements[data.id][data.animation.type] = data.animation.duration;
      if (data.animation.id === 5) {
        scenes.elements[data.id][data.animation.type] = 0;
      }

      break;

    case "SANIMATION_ADD":
      state.scenes[data.index].sanimation = data;
      break;
    case "SANIMATION_COLOR":
      state.scenes[data.index].sanimation.colors[data.colorIndex] = data.color;
      break;

    case "HAVE_VID":
      if (OFFLINEID) {
        return;
      }
      state.active.haveVid = data;
      break;

    case "SCENE_SORT":
      if (1) {
        let list = JSON.parse(state.scenes.currentScene.arr);
        let list2 = arrayMove(list, data.oldIndex, data.newIndex);
        state.scenes.currentScene.arr = JSON.stringify(list2);
        document.sceneNumberFromIndex = list2.reduce(
          (a, b, i) => ({ ...a, [b]: i }),
          {}
        );

        // console.log(list2);
      }
      break;

    case "ANIMATION_RESIZE":
      scenes.elements[data.id] = {
        ...scenes.elements[data.id],
        [data.type]: data.duration,
      };

      break;

    case "DELETE_MEDIA":
      let _x001 = state.media[data.type].list.filter((e) => e.id !== data.id);
      state.media[data.type].list = _x001;

    case "RENAME_MEDIA":
      if (1) {
        let x = state.media[data.type].list.findIndex((e) => e.id === data.id);
        console.log({
          x,
          type: data.type,
          id: data.id,
        });
        if (x !== -1) {
          state.media[data.type].list[x].name = data.name;
        }
      }

      break;
    default:
      return state;
  }
});

const store = createStore(
  reducer /* preloadedState, */,
  composeWithDevTools({
    trace: true,
    traceLimit: 25,
  })()
);
// const Store = store();
export default store;
