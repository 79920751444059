/* eslint-disable */
import store from "../store/store";
import gsap from "gsap";
import { getById, Index, updateTimeLen } from "../utils";
import { SIZES } from "./config";
import { fitDimensionFromAngle } from "./useFullFunctions/geomtery";
import { convertOrigin, convertOriginToDecimal } from "./cropyThings";
document.convertOrigin = convertOrigin;
export function storeAnimatationToStore(id) {
  return (e) => {
    store.dispatch({
      type: "ADD_ANIMATION",
      data: {
        id,
        animation: e,
      },
    });
    // updateTimeLen();
  };
}
export function saveAllCanvas() {
  document.saved = {};
  Object.keys(document.cxx).forEach((e, i) => {
    let cxx = document.cxx[e];
    if (cxx) {
      const _wrapper = document.getElementById(`Wrapper${e}`);
      if (_wrapper) {
        _wrapper.style.display = "block";
      }
      document.saved[e] = cxx.toJSON(["id"]);
      document.vxx[e] = [];
      cxx.getObjects().forEach((obj) => {
        // obj.set({ selectable: !obj.selectable });
        if (
          obj._Type === "video" ||
          (obj._Type === "background" && obj._Data.tab === 2)
        ) {
          let a = obj._originalElement;
          a.type = obj._Type;
          document.vxx[e].push(a);
        } else {
          if (obj._Type == "music" || obj._Type === "avatar") {
            let a = document.getElementById(obj.id);
            if (a) {
              a.type = obj._Type;
              document.vxx[e].push(a);
              if (a.readyState !== 0 && a.getAttribute("src") !== "undefined") {
                // console.log(a.getAttribute("src"), a.readyState);
                document.vxx[e].push(a);
                // }
              }
            }
          }
        }
      });
    }
  });
}
export async function makeTimeLine() {
  let _s = store.getState().scenes;
  document.scenes = _s;
  await JSON.parse(_s.currentScene.arr).map((e) => {
    let scene = _s[e];

    if (document.txx.timeLine[e]) {
      document.txx.timeLine[e].clear();
    }

    JSON.parse(scene.elementList).forEach((eid) => {
      // console.log(e, eid);
      const { enter, exit, enterDelay, stayTime, animation } =
        scene.elements[eid];

      let obj = getById(eid);

      document.txx.add({
        obj,
        enterDuration: enter,
        exitDuration: exit,
        enterDelay: enterDelay,
        stayTime: stayTime,
        where: e,
        animationEnter: animation?.enter?.animationData?.start,
        animationExit: animation?.exit?.animationData?.end || {},
        animationPlace: animation?.place?.animationData?.start || {},
      });
    });
  });
}

export function restoreCanvas() {
  Object.keys(document.saved).forEach((e) => {
    document.saved[e].objects.forEach((objj) => {
      const _wrapper = document.getElementById(`Wrapper${e}`);
      if (_wrapper) {
        _wrapper.style.display = "none";
      }
      let obj = getById(objj.id);
      obj.set(objProMapper(objj));

      if (obj.canvas) {
        obj.canvas.renderAll();
      }
    });
  });
}

export const objProMapper = (obj, data = {}) => {
  // let data = {};
  // if (obj.clipPath) {
  //   data = { ...data, clipPath: fabric.util.object.clone(obj.clipPath) };
  // }
  // if(obj)
  return {
    left: obj.left,
    originX: obj.originX,
    originY: obj.originY,
    top: obj.top,
    width: obj.width,
    height: obj.height,
    scaleX: obj.scaleX,
    scaleY: obj.scaleY,
    flipX: obj.flipX,
    flipY: obj.flipY,
    angle: obj.angle,
    opacity: obj.opacity,
    cropX: obj.cropX,
    cropY: obj.cropY,
    skewX: obj.skewX,
    skewY: obj.skewY,
    id: obj.id,
    // filters: obj.filters,
    ...data,
  };
};

export const intialSceneSetup = (commands, mainData) => {
  commands.forEach((commd) => {
    if (commd.command === "REVERSE_OBJECT") {
      mainData.canvas?._objects?.reverse();
    } else if (commd.command.slice(0, 13) === "CHANGE_ORIGIN") {
      const _commandData = commd.command.slice(14);
      const _Origin = {
        CENTER: {
          originX: 0.5,
          originY: 0.5,
        },
        "0_0": {
          originX: 0,
          originY: 0,
        },
        "0_0.5": {
          originX: 0,
          originY: 0.5,
        },
        "0_1": {
          originX: 0,
          originY: 1,
        },
        "1_0": {
          originX: 1,
          originY: 0,
        },
        "1_0.5": {
          originX: 1,
          originY: 0.5,
        },
        "1_1": {
          originX: 1,
          originY: 1,
        },
      };
      if (mainData.object) {
        convertOrigin(mainData.object, _Origin[_commandData]);
      }
    }
  });
};

const parseAnimationData = (_OBJ, _data) => {
  let _newFrom = {};
  if (_data) {
    Object.keys(_data).forEach((key, indx) => {
      let _crntEqn = `${_data[key]}`;
      Object.keys(_OBJ).forEach((rk, id) => {
        _crntEqn = _crntEqn.replaceAll(rk, _OBJ[rk]);
      });
      if (`${_data[key]}` !== _crntEqn) {
        // eslint-disable-next-line no-new-func
        _newFrom[key] = Function(`'use strict'; return (${_crntEqn})`)();
      } else {
        _newFrom[key] = _data[key];
      }
    });
    return _newFrom;
  } else {
    return _data;
  }
};

export const parseSceneData = (obj, data) => {
  let _OBJ = {
    CANVAS_HEIGHT: SIZES[document?._size || 0].height,
    CANVAS_WIDTH: SIZES[document?._size || 0].width,
    OBJECT_left: obj.left,
    OBJECT_top: obj.top,
    OBJECT_opacity: parseFloat(obj.opacity),
    OBJECT_scaleX: obj.scaleX,
    OBJECT_scaleY: obj.scaleY,
    OBJECT_cropX: obj.cropX,
    OBJECT_cropY: obj.cropY,
    OBJECT_height: obj.height,
    OBJECT_width: obj.width,
    OBJECT_originX: convertOriginToDecimal(obj.originX),
    OBJECT_originY: convertOriginToDecimal(obj.originY),
    OBJECT_HEIGHT: obj.height * obj.scaleY,
    OBJECT_WIDTH: obj.width * obj.scaleX,
  };
  if (isNaN(_OBJ.OBJECT_opacity)) {
    _OBJ.OBJECT_opacity = 1;
  }
  const _stroke = Number(obj.strokeWidth);
  if (!isNaN(_stroke) && _stroke > 0) {
    const _increaseSize = {
      x: obj.strokeWidth * obj.scaleX,
      y: obj.strokeWidth * obj.scaleY,
    };
    _OBJ["OBJECT_WIDTH"] += _increaseSize.x;
    _OBJ["OBJECT_HEIGHT"] += _increaseSize.y;
  }

  return {
    from: parseAnimationData(_OBJ, data.from),
    to: parseAnimationData(_OBJ, data.to),
  };
};

function createCanvas(height, width, zoom) {
  let _newTextCanvas = new fabric.StaticCanvas();
  _newTextCanvas.setHeight(height);
  _newTextCanvas.setWidth(width);
  _newTextCanvas.setZoom(zoom);
  return _newTextCanvas;
}

export const copyTextbox = (fabText) => {
  let _angle = fabText.angle;
  fabText.rotate(0);
  convertOrigin(fabText, {
    originX: 0,
    originY: 0,
  });
  // assume there is no scaling
  let _charBounds = fabText.__charBounds,
    _textLines = fabText._textLines,
    _fabLeft = fabText.left,
    _fabTop = fabText.top,
    _fabWidth = fabText.width * fabText.scaleX,
    _fabHeight = fabText.height * fabText.scaleY;

  document.cccxa.current.style.display = "block";
  let _canvas = document.ccxa;
  const padd = 50,
    _fitScale = 0.0666;
  let _newTextCanvas = createCanvas(
    fabText.height + padd,
    fabText.width + padd,
    1
  );
  let _mainFabTextCanvas = createCanvas(
    fabText.canvas.getHeight() / fabText.canvas.getZoom(),
    fabText.canvas.getWidth() / fabText.canvas.getZoom(),
    1
  );
  const _mainFabTextCanvasCenter = {
    x: (_mainFabTextCanvas.width - _fabWidth) / 2,
    y: (_mainFabTextCanvas.height - _fabHeight) / 2,
  };

  let _newFabObj = new fabric.EffectTextBox(fabText.text, fabText.toJSON());

  _newFabObj.set({
    angle: 0,
    left: 0,
    top: 0,
    scaleX: _newFabObj.scaleX + _fitScale,
    scaleY: _newFabObj.scaleY + _fitScale,
  });
  _newTextCanvas.add(_newFabObj);
  _newTextCanvas.renderAll();
  fabText.visible = false;

  let _crntHeight = 0;
  let allTextObj = [];
  let enterAnimationData = [];
  let exitAnimationData = [];
  let _an = {
    init: [
      {
        command: "CHANGE_ORIGIN_CENTER",
      },
    ],
    start: {
      from: {
        top: "-OBJECT_HEIGHT/2",
      },
      to: {
        top: "OBJECT_top",
        duration: 1,
        ease: "bounce.out",
      },
    },
    end: {
      from: {
        top: "OBJECT_top",
      },
      to: {
        top: "-OBJECT_HEIGHT/2",
        duration: 1,
        ease: "bounce.out",
      },
    },
  };
  //let _an = {"start":{"from":{"opacity":0},"to":{"opacity":"OBJECT_opacity","duration": 1}},"end":{"from":{"opacity":"OBJECT_opacity"},"to":{"opacity":0,"duration": 1}}}

  let _cAnimation = gsap.timeline({
    paused: true,
    onUpdate: () => {
      _canvas.renderAll();
      _mainFabTextCanvas.renderAll();
    },
  });

  let _delay = 0;
  let _objAD = 0.2;
  let _stayTime = 4;
  for (let lineIndex = 0; lineIndex < _textLines.length; lineIndex++) {
    let _crntLineHeight = fabText.getHeightOfLine(lineIndex);
    for (
      let charIndex = 0;
      charIndex < _textLines[lineIndex].length;
      charIndex++
    ) {
      let _charLeft = _charBounds[lineIndex][charIndex].left;
      let _charWidth = _charBounds[lineIndex][charIndex].width;
      if (charIndex + 1 === _textLines[lineIndex].length) {
        _charWidth += 5;
      }

      //let _afterRotate = {x: fabText.left + _charLeft,y: fabText.top+_crntHeight}//rotateAroundPoint( ,_fabTextCenter,_angle)
      let _textboxObj = new fabric.Image(_newTextCanvas.getElement(), {
        cropX: _charLeft,
        cropY: _crntHeight,
        height: _crntLineHeight,
        width: _charWidth,
        left: _mainFabTextCanvasCenter.x + _charLeft,
        top: _mainFabTextCanvasCenter.y + _crntHeight,
        //opacity: 0
      });
      _mainFabTextCanvas.add(_textboxObj);
      allTextObj.push(_textboxObj);
      // animation
      intialSceneSetup(_an?.init || [], {
        object: _textboxObj,
      });
      let animationEnter = _an.start;
      let animationExit = _an.end;
      let _enterAnimation = parseSceneData(_textboxObj, {
        from: animationEnter.from,
        to: animationEnter.to,
      });
      intialSceneSetup(_an?.init || [], {
        object: _textboxObj,
      });
      let _exitAnimation = parseSceneData(_textboxObj, {
        from: animationExit.from,
        to: animationExit.to,
      });
      enterAnimationData.push(_enterAnimation);
      exitAnimationData.push(_exitAnimation);

      _cAnimation.fromTo(
        _textboxObj,
        _enterAnimation.from,
        {
          ..._enterAnimation.to,
          duration: _objAD,
        },
        _delay
      );
      _cAnimation.fromTo(
        _textboxObj,
        _exitAnimation.from,
        {
          ..._exitAnimation.to,
          duration: _objAD,
        },
        _delay + _stayTime
      );
      _delay += _objAD; //-0.2;
    }
    _crntHeight += _crntLineHeight;
  }
  _mainFabTextCanvas.renderAll();
  fabText.rotate(_angle);
  let _actualHeight = _mainFabTextCanvas.height;
  let _actualWidth = _mainFabTextCanvas.width;

  let _incH =
    (_actualHeight / 2) * Math.sin(fabric.util.degreesToRadians(_angle)); //Math.max((_actualHeight/2)*Math.cos(fabric.util.degreesToRadians(_angle)),(_actualHeight/2)*Math.sin(fabric.util.degreesToRadians(_angle)));
  let _incW =
    (_actualWidth / 2) * Math.sin(fabric.util.degreesToRadians(_angle)); //Math.max((_actualWidth/2)*Math.cos(fabric.util.degreesToRadians(_angle)),(_actualWidth/2)*Math.sin(fabric.util.degreesToRadians(_angle)));

  console.log({
    _actualHeight,
    _incH,
    _actualWidth,
    _incW,
  });

  let _modifiedHeight = _mainFabTextCanvas.getElement().height;
  let _scale = _actualHeight / _modifiedHeight;
  let _mainPL = _fabLeft + _fabWidth / 2,
    _mainPT = _fabTop + _fabHeight / 2;
  let _extraT = Math.abs(_mainFabTextCanvas.height / 2 - _mainPT),
    _extraL = Math.abs(_mainFabTextCanvas.width / 2 - _mainPL);
  console.log(
    _mainFabTextCanvas.height / 2 - _mainPT,
    _mainFabTextCanvas.width / 2 - _mainPL
  );
  let { width, height } = fitDimensionFromAngle(_angle);
  let _mainT = new fabric.Image(_mainFabTextCanvas.getElement(), {
    left: _mainPL,
    top: _mainPT,
    scaleX: _scale,
    scaleY: _scale,
    originX: 0.5,
    originY: 0.5,
    // cropX: 0,
    // cropY: 0,
    // height: _actualHeight +_extraT,
    // width: _actualWidth + _extraL
    height: height,
    width: width,
  });
  console.log(_actualHeight, _modifiedHeight);
  _mainT.rotate(_angle);
  _canvas.add(_mainT);

  console.log(width, height);
  _canvas.renderAll();
  let _boundingRect = _mainT.getBoundingRect();
  let _bHeight = _boundingRect.height / _canvas.getZoom(),
    _bWidth = _boundingRect.width / _canvas.getZoom();
  //_mainT.set({height: _bHeight,width: _bWidth,left: 1920/2,top:1080/2})
  console.log(_boundingRect, _bHeight, _bWidth);
  // allTextObj.forEach((_obj)=>{
  //   _cAnimation.fromTo(_obj,{opacity: 0},{opacity: 1,duration: _objAD},_delay);
  //   _delay+=_objAD;
  // })
  // allTextObj.reverse()
  // allTextObj.forEach((_obj)=>{
  //   _cAnimation.fromTo(_obj,{opacity: 1},{opacity: 0,duration: _objAD},_delay+_stayTime);
  //   _delay+=_objAD;
  // })

  // console.log(exitAnimationData,enterAnimationData)
  // //allTextObj.reverse()
  // allTextObj.forEach((_obj)=>{
  //   _cAnimation.fromTo(_obj,{opacity: 1},{opacity: 0,duration: _objAD},_delay+_stayTime);
  //   _delay+=_objAD-0.2;
  // })
  // allTextObj.forEach((_obj)=>{
  //   _obj.opacity = 0
  // })
  _cAnimation.play();
  return _cAnimation;
};

const objectToImage = (objCanvas) => {
  let image = new Image();
  image.src = objCanvas.toDataURL();
  image.crossOrigin = "anonymous";
  document.body.appendChild(image);
  return image;
};
document.tmp = {
  copyTextbox,
  parseSceneData,
  objectToImage,
};
export default () => { };
