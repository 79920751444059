/* eslint-disable */
import { getById, Vid, Index } from "../utils";
import { fabric } from "fabric";
import uuid from "uuid-random";
import { cxxtoJSON } from "./config";
import { InitAddObjectEvents } from "./InitAddObjectEvents";
import fabricInit from "./fabricInit";
import { objProMapper } from "./shortcutFunction";
import { display } from "./videoPlayThings/addVideoToCanvasAndStore";
import { customVideoSeek } from "./videoPlayThings";
import { deleteFromAll } from "./deleteCloneOpeationObj";
import { loadVideo } from "../components/LeftPanel/components/Video/functions/createVideoElement";

const objectCloner = async ({
  _id,
  where = Index(),
  haveCopy = true,
  from = Index(),
  clone = false,
  pos = null
}) => {
  let id = haveCopy ? uuid() : _id;
  let timeLineData = {};
  //   ? {}
  //   : store.getState().scenes[from].elements[_id];
  let _obj = getById(_id);
  let oldClip = _obj.clipPath;

  let data = {
    media: _obj._Media,
    timeLineData,
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  let cxx = document.cxx?.[where] || _obj.canvas;
  if (_obj._Type === "video") {
    id = `_${id}`;
    let vidEle = await loadVideo(_obj._Video.media_file, _obj._Video.id, id);
    if (vidEle) {
      let _backupProperties = {
        clipPath: _obj.clipPath,

        filters: _obj.filters,
        _Hidden: _obj._Hidden,
        objectCaching: true,
        statefullCache: true,
        cacheProperties: ["videoTime"],
      };
      let pro = objProMapper(_obj);
      let obj = new fabric.Image(vidEle, {
        ...pro,
        ..._backupProperties,
      });
      cxx.add(obj);
      vidEle._fabObj = obj;
      obj._Video = _obj._Video;
      obj.shadow = _obj.shadow;

      if (pos) {
        let zo = cxx.getZoom();
        obj.set("top", pos.y / zo);
        obj.set("left", pos.x / zo);
      } else {
        obj.set("top", obj.top + (where === from ? 15 : 0));
        obj.set("left", obj.left + (where === from ? 15 : 0));
      }

      InitAddObjectEvents({
        obj,
        _id: id,
        __id: _id,

        type: _obj._Type,
        name: document.duplicateName.getName({
          type: _obj._Type,
          name: _obj._Name,
        }),
        data: {
          ..._obj._Data,
        },
        returnToUrl: false,

        index: where,
        clone,
        from,
        ...data,
      });
    };
  } else if (_obj._Type === "group") {
    _obj._objects.forEach((obj) => {
      objectCloner({ _id: obj.id, where, from, clone: true });
    });
  } else {
    fabric[capitalizeFirstLetter(_obj.type)].fromObject(
      _obj.toJSON(cxxtoJSON),
      (obj) => {
        let _returnToUrl = false;
        let _Name = obj._Name;
        if (obj._Type !== "music") {
          _Name = document.duplicateName.getName({
            type: obj._Type,
            name: obj._Name,
          });
          _returnToUrl = true;
        }

        if (obj._Type === "avatar") {
          // deleteFromAll()
          cxx.remove(getById(`avatar${where}`));
          cxx.renderAll();
          id = `avatar${where}`;

          clone = true;
          from = where;
          _Name = `Avatar ${where}`;
          _id = id;
        } else {
          if (pos) {
            let zo = cxx.getZoom();
            obj.set("top", pos.y / zo);
            obj.set("left", pos.x / zo);
          } else {
            obj.set("top", obj.top + (where === from ? 15 : 0));
            obj.set("left", obj.left + (where === from ? 15 : 0));
          }
        }

        obj.set("id", id);

        cxx.add(obj);
        cxx.renderAll();

        InitAddObjectEvents({
          obj,
          _id: id,
          __id: _id,

          type: obj._Type,
          name: _Name,
          data: {
            ...obj._Data,
          },
          returnToUrl: false,
          index: where,
          clone: clone,
          from,
          ...data,
        });
        // console.log("Msuc", 3);

        // cxx.setActiveObject(obj);
      }
    );
  }
};


export default objectCloner;
