import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "uuid-random";
import store from "../../../store/store";
import { fetcher, functionListen, url } from "../../../utils";
// import DragUpload from "../Atom/DragUpload";
import HoverImageCard from "../Atom/HoverImageCard";
import InfiniteWrapper from "../Atom/Infinite";
import InputIcon from "../Atom/InputIcon";
import "./_Compound.scss";

function ImageScroll({
  video,
  stock,
  upload,
  type = "stock",
  onSelect = () => { },

  up = () => { },
  down = <></>,

  isTitle = false,
  search,
  upFunction = null,
  onPreview,
  height = null
}) {
  const id = uuid();
  const list = {
    video,
    stock,
    upload,
  };
  const handleSelect = useCallback((e) => {
    return (k) => {
      onSelect({ ...e, type: "image" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const [search, setArr] = useState([]);
  const [isSearched, setSearch] = useState(false);

  useEffect(() => {
    functionListen("#searchInput", handleChange);
  }, []);
  const handleChange = () => {
    let searchQ = { stock: `bgclip/search`, upload: "userlibrary/upload" };
    // let d = e.target.value;
    let ele = document.querySelector("#searchInput");

    if (ele.value.length >= 1) {
      fetcher({
        method: "GET",
        url: `${url}/api/${searchQ[type]}/?type=image&query=${ele.value}`,
        res: (res) => {
          store.dispatch({
            type: "MEDIA_WITH_NEW",
            data: {
              next: res.data.next,
              list: res.data.results,
              type: "search",
            },
          });
          setSearch(true);
          //console.log(res.data.results);
          // setArr(res.data.results);
        },
        err: (err) => {
          setSearch(false);
        },
      });
    } else {
      setSearch(false);
    }
  };

  return (
    <>
      {/* <DragUpload /> */}
      <InputIcon
        // onBlur={handleChange}
        src={`${document.PUBLIC_URL}/icons/search.svg`}
        placeholder="Search Images"
        id={"searchInput"}
      />

      <div
        className="scrollableDiv"
        id={`imgaeScroll${type}`}
        style={{
          height: height || "83vh",
          overflowY: "scroll",
          overflowX: "hidden",

          display: "flex",
          // flexDirection: "column-reverse",
        }}
      >
        <InfiniteWrapper type={isSearched ? "search" : type} target={`imgaeScroll${type}`}>
          {(isSearched ? search.list : list[type].list).map((e, i) => (
            <HoverImageCard
              onSelect={handleSelect(e)}
              src={type === "upload" ? e.media_thumbnail : e.media_file}
              // key={e.id}
              key={`${i}${e.id}`}
              id={e.id}
              upElement={up({ id: e.id })}
              downElement={down}
              title={isTitle && e.name}
              UpFunction={upFunction}
              onPreview={onPreview}
              data={e}
            // isActive={e.id === .id}
            />
          ))}
        </InfiniteWrapper>
      </div>
    </>
  );
}

const mstop = (state) => {
  const {
    media: { video, stock, upload, search },
  } = state;
  return {
    video,
    stock,
    upload,
    search,
  };
};

export default connect(mstop)(ImageScroll);
