import React, { memo, useCallback, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import ButtonIcon from "../../../../../Chemistry/Atom/ButtonIcon";
import LabelInput from "../../../../../Chemistry/Atom/LabelInput";
import TitleRow from "../../../../../Chemistry/Atom/TitleRow";
import TitleWrapper from "../../../../../Chemistry/Atom/TitleWrapper";
import Vr from "../../../../../Chemistry/Atom/Vr";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import "./_BackgroundSettings.scss";
import { getById, Index, Jump, url, Vid } from "../../../../../../utils";
import NewColor from "../../../../../Chemistry/Atom/NewColor";
import { connect } from "react-redux";
import { addBackground } from "../../../Background/functions/changeBackground";
import ArrowWrapper from "../../../../../Chemistry/Atom/ArrowWrapper";
import Mask from "../../../../../Chemistry/One/Mask";
import ImageFilters from "../../../../../../utils/fabricFilters";
import InputRange from "../../../../../Chemistry/Atom/InputRange";
import EditSelect from "../../../../../Chemistry/Atom/EditSelect";
import store from "../../../../../../store/store";
import { TrimContainer } from "../../../../../Chemistry/One/Trim";
import Tooltip from "../../../../../Chemistry/Atom/Tooltip";
export default connect(
  (state) => {
    const {
      media: { video, stock, upload, gradient },
      scenes,
      currentScene,
    } = state;
    return {
      video,
      stock,
      upload,
      scenes,
      currentScene,
      gradient,
      colorArr: scenes.colorArr,
    };
  },
  (dispatch) => {
    return {
      changeBackground: (selected) => {
        dispatch({ type: "CHANGE_BACKGROUND", data: selected });
      },
      EditColor: (colorArr) => dispatch({ type: "EDIT_COLOR", data: colorArr }),
    };
  }
)(function BackgroundSettings({
  colorArr,
  changeBackground,
  EditColor,
  gradient,
}) {
  const history = useHistory();
  const { state } = useLocation();
  const handleGoChange = () => {
    let obj = getById(state.id || `background${Index()}`, Index());
    document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/background`, {
      tab: obj._Data.tab,
    });
  };
  const handleColorChange = (color) => {
    const { cxx, index } = document;
    addBackground.changeColor(color, state.id);
    // cxx.fire("object:modified");

    // cxx[index].renderAll();
  };
  const handelBackgroundChange = (type) => {
    return (e) => {
      addBackground.insertImage({ ...e, type }, () => {
        changeBackground({
          type: type,
          current: e,
        });
      });
    };
  };
  const handleLastChange = () => {
    document.cxx[Index()].fire("object:modified");
  };
  return (
    <div className="BackgroundSettings">
      <TitleRow title="Background Settings" />
      <ControlsBackground
        on_1={handleGoChange}
        on_3={() => {
          let cxx = document.cxx[Index()];
          let obj = getById(state.id || `background${Index()}`, Index());
          cxx.remove(obj).renderAll();
          addBackground.insert(cxx);
          cxx.fire("object:modified");
          history.push(Jump({ obj, def: true }));
        }}
        on_2={(set) => {
          set((e) => {
            let obj = getById(state.id || `background${Index()}`, Index());
            // obj.set({ selectable: !e });
            obj.set({ lockMovementY: e, lockMovementX: e });
            if (e) {
              obj.canvas.discardActiveObject();
            } else {
              obj.canvas.setActiveObject(obj);
            }
            obj.canvas.renderAll();
            return !e;
          });
        }}
      />
      <Vr m="15px" />
      <Switch>
        <Route path={`/${document.editorType}/:vid/:id/settings/background/color`}>
          <NewColor
            arr={colorArr}
            onChange={handleColorChange}
            onSelect={handleColorChange}
            gradientList={gradient.list}
            setDefault={
              getById(state.id || `background${Index()}`, Index())?.fill ||
              "#ffffff"
            }
            onAdd={EditColor}
            onDelete={EditColor}
            onGradientSelect={handelBackgroundChange("color")}
            handleLastChange={handleLastChange}
          />
        </Route>
        <Route path={`/${document.editorType}/:vid/:id/settings/background/video`}>
          <VideoPanel id={state.id || `background${Index()}`} />
        </Route>
        <Route path={`/${document.editorType}/:vid/:id/settings/background/upload`}>
          <ImagePanel />
        </Route>
        <Route path={`/${document.editorType}/:vid/:id/settings/background/image`}>
          <ImagePanel />
        </Route>
        <Route path={`/${document.editorType}/:vid/:id/settings/background/filter`}>
          <Mask
            title="< Filter"
            type="filter"
            obj={getById(state.id || `background${Index()}`, Index())}
            onSelect={(mask) => {
              // mask : {src, name}
              // obj: fabric object
              store.dispatch({ type: "LOADING", data: "Filter is Loading ..." });
              console.time("filter");
              let obj = getById(state.id || `background${Index()}`, Index());
              obj._Filter = mask;
              if (obj._originalElement.crossOrigin !== "anonymous") {
                console.log("FILTER IF");
                obj._originalElement.crossOrigin = "anonymous";
                obj._originalElement.onload = () => {
                  obj.canvas.renderAll();
                  let _filterName = mask.name;
                  obj.filters = ImageFilters[_filterName];

                  obj.applyFilters();
                  obj.canvas.renderAll();
                };
              } else {
                console.log("FILTER ELSE", mask.name);

                let _filterName = mask.name;
                obj.filters = ImageFilters[_filterName];
                obj.applyFilters();
                obj.canvas.renderAll();
              }
              console.timeEnd("filter");

              store.dispatch({ type: "LOADING", data: "Filter is Loading ..." });
            }}
          />
        </Route>
      </Switch>
    </div>
  );
});

const ImagePanel = () => {
  const { cxx, index } = document;
  const { state } = useLocation();
  const handleChange = useCallback((e) => {
    let obj = getById(state.id || `background${Index()}`, Index());
    obj.set({ opacity: e / 100 });
    obj.canvas.renderAll();
  }, []);
  const handleFull = () => {
    document.cxx[Index()].fire("object:modified");
  };

  const handleSetVariable = (e) => {
    // let obj = getById(state.id);
    let obj = getById(state.id || `background${Index()}`, Index());

    if (e) {
      obj.setSrc("https://api.autogenerate.ai/media/ImageMergeTagDefault.webp");
    } else {
      obj.setSrc(obj._Src);
    }
  };

  return (
    <div className="ImagePanel">
      <TitleWrapper title="Opacity">
        <InputRangeWithTitle
          value={
            getById(state.id || `background${Index()}`, Index()).opacity * 100
          }
          className="ImagePanel_range"
          onFullChange={handleFull}
          onChange={handleChange}
        />
      </TitleWrapper>
      <Vr m="10px"></Vr>
      <MakeVariableButton
        obj={getById(state.id || `background${Index()}`, Index())}
        onSelect={handleSetVariable}
      />
      <ArrowWrapper
        m="15px"
        p="10px 0px"
        to={`filter`}
        data={{ id: state.id || `background${Index()}` }}
        ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/animation.svg`} />}
      >
        Filter
      </ArrowWrapper>
    </div>
  );
};

const VideoPanel = ({ obj, id }) => {
  const { url } = useRouteMatch();
  const handleChange = (e) => {
    const { cxx } = document;
    let obj = getById(id);

    if (obj) {
      obj.set({ opacity: e / 100 });
      obj.canvas.renderAll();
    }
  };
  return (
    <div className="VideoPanel">
      <TitleWrapper title="Opacity">
        <InputRangeWithTitle
          value={Math.round(getById(id).opacity * 100)}
          className="ImagePanel_range"
          // onFullChange={(e) => alert(e.target.value)}
          onChange={handleChange}
        />
      </TitleWrapper>
      <Vr m="10px"></Vr>
      <div className="MusicSettings_loop">
        Adjust video length{" "}
        <EditSelect
          selectedIndex={
            store.getState().scenes[Index()].elements[id]?.adjustLength || 1
          }
          disabled={true}
          selected={null}
          list={[null, "Loop", "Freeze at Last Frame", "Adjust as Scene"]}
          onSelect={(e, b) => {
            let vidEle = document.getElementById(id);
            let data = {};
            if (b === 1) {
              // data = { resize: false, drag: false };
              vidEle.playbackRate = 1;
            } else if (b === 0) {
              // data = { resize: true, drag: false };
              vidEle.playbackRate = 1;
            } else if (b === 2) {
              // data = {
              //   // stayTime: timeLine - ele.enter - ele.exit,
              //   resize: false,
              //   drag: false,
              // };
              vidEle.playbackRate = 1;
            } else if (b === 3) {
              // data = {
              //   resize: true,
              //   drag: true,
              // };
            }

            store.dispatch({
              type: "EDIT_ELEMENT",
              data: {
                id: id,
                data: {
                  ...data,
                  adjustLength: b,
                  // stayTime: ele.trimEnd - ele.trimStart,
                  // enterDelay: 0,
                },
              },
            });
          }}
        />
      </div>
      <Vr m="10px"></Vr>
      <TrimContainer id={id} element={document.getElementById(id)} />
      <Vr m="10px"></Vr>
      <ArrowWrapper
        m="15px"
        p="10px 0px"
        to={`/${document.editorType}/${Vid()}/${Index()}/settings/background/filter`}
        data={{ id }}
        ele={<ButtonIcon value="" src={`${document.PUBLIC_URL}/icons/animation.svg`} />}
      >
        Filter
      </ArrowWrapper>
    </div>
  );
};

export const MakeVariableButton = ({
  onSelect = () => { },
  onClose = () => { },
  onClick = () => { },
  obj,
  title = "Make Image Variable",
}) => {
  const [isShow, setShow] = useState(!obj?._Variable || false);
  let [variable, setVariable] = useState(obj?._Variable || document.duplicateName.getName({ type: "image", name: "Variable" }));
  const handleSet = (e) => {
    setVariable((K) => {
      obj._Variable = e.target.value;
      obj._Src = obj.src;
      obj._haveMerge = true;
      // obj._RealVariable=true
      obj.canvas.fire("object:modified");

      return e.target.value;
    });
  };
  const handleOK = (e) => {
    setShow(!isShow);
    obj._Variable = "";
    obj._haveMerge = false;
    obj.canvas.fire("object:modified");

    onSelect(false);
  };
  const handleShow = () => {
    setShow((k) => !k);
    obj._Src = obj.src;
    obj.canvas.fire("object:modified");

    onSelect(true);
  };

  return (
    <div className="MakeVariableButton" onClick={onClick}>
      {isShow ? (
        <div className="MakeVariableButton_button" onClick={handleShow}>
          {title}
        </div>
      ) : (
        <div className="row align-items-center">
          <LabelInput
            value={variable}
            onChange={handleSet}
            label="Variable Name:"
            width="60%"
            type="text"
          // onBlur={handleOK}
          />
          {!obj._RealVariable && <div className="pointer" onClick={handleOK}>
            <img src={`${document.PUBLIC_URL}/ico/cross.svg`} alt="" />
          </div>}
        </div>
      )}
    </div>
  );
};

export const InputRangeWithTitle = ({
  className = "",
  value,
  max = 100,
  min = 0,
  step = 1,
  postfix = "%",
  onChange = () => { },
  onFullChange = () => { },
  objActive = null,
  style = {}
}) => {
  const [_value, setValue] = useState(value);
  const handleChangel = (e) => {
    setValue(e.x);
    onChange(e.x);
  }

  useEffect(() => {
    setValue(value)
  }, [objActive])
  const handelFullChange = () => {
    onFullChange(_value);
    document.cxx[Index()].fire("object:modified");
  };

  return <div className={`InputRangeWithTitle ${className}`} style={style}>
    <InputRange
      max={max}
      min={min}
      step={step}
      className="InputRangeWithTitle_input"
      value={_value}
      onChange={handleChangel}
      onMouseUp={handelFullChange}
      objActive={objActive}
    />
    <div className="InputRangeWithTitle_value">
      {_value}
      {postfix}
    </div>
  </div>

};

export const InputRangeWithOutTitle = ({
  className = "",
  value,
  max = 100,
  min = 0,
  step = 1,
  postfix = "%",
  onChange = () => { },
  onFullChange = () => { },
  objActive = null,
  style = {},
  label = "X"
}) => {
  const [_value, setValue] = useState(value);
  const handleChangel = (e) => {
    setValue(e.x);
    onChange(e.x);
  }

  useEffect(() => {
    setValue(value)
  }, [objActive])
  const handelFullChange = () => {
    onFullChange(_value);
    document.cxx[Index()].fire("object:modified");
  };

  return <div className={`InputRangeWithTitle ${className}`} style={style}>
    <label for="" class="Label" style={{ "paddingRight": "5px" }}>{label}</label>
    <InputRange
      max={max}
      min={min}
      step={step}
      className="InputRangeWithTitle_input"
      value={_value}
      onChange={handleChangel}
      onMouseUp={handelFullChange}
      objActive={objActive}
      style={{ marginRight: "5px" }}
    />
    <div className="InputRangeWithTitle_value">
      {_value}
      {postfix}
    </div>
  </div>

};


export const ControlsBackground = memo(
  ({
    on_1 = () => { },
    on_2 = () => { },
    on_3 = () => { },
    on_4 = () => { },
    on_5 = () => { },
    on_6 = () => { },
    _1_src = `${document.PUBLIC_URL}/ico/replace.svg`,
    _2_src = `${document.PUBLIC_URL}/ico/crop.svg`,
    _3_src = `${document.PUBLIC_URL}/ico/delete.svg`,
    _4_src,
    _5_src,
    _6_src,
    text = "Change Background",
  }) => {
    const [_2, set2] = useState(false);
    const handleSet = useCallback(() => {
      on_2(set2);
    }, [on_2]);
    return (
      <div className="ControlsBackground">
        <div className="ControlsBackground_full">
          <ButtonIcon value={text} src={_1_src} onClick={on_1} />
        </div>
        {_6_src && (
          <div className="ControlsBackground_m" id="FullSCREEN" onClick={on_6}>
            <ButtonIcon value="" src={_6_src} />
          </div>
        )}
        {_5_src && (
          <div className="ControlsBackground_m" onClick={on_5}>
            <ButtonIcon value="" src={_5_src} />
          </div>
        )}

        {_2_src && (
          <div
            className={`ControlsBackground_m ${_2 ? "ControlsBackground_active" : ""
              }`}
          // onClick={() => on_2(set2)}
          >
            <ButtonIcon value="" src={_2_src} onClick={handleSet} />
          </div>
        )}
        {_4_src && (
          <div className="ControlsBackground_m" onClick={on_4}>
            <ButtonIcon value="" src={_4_src} />
          </div>
        )}

        <div className="ControlsBackground_m" onClick={on_3}>
          <Tooltip text={"Delete"}>

            <ButtonIcon value="" src={_3_src} />
          </Tooltip>
        </div>
      </div>
    );
  }
);
