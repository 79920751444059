import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import SlideContainer from "../Atom/SlideContainer";
import TitleRow from "../Atom/TitleRow";
import "./_One.scss";
import { Rnd } from "react-rnd";
import store from "../../../store/store";
import { getById, Index } from "../../../utils";
import { fancyTimeFormat } from "../Atom/MusicCard";
import { connect } from "react-redux";
import { per, UpdateBackground } from "./Draggy";
import { customVideoSeek } from "../../../utils/videoPlayThings";

export default function Trim() {
  const { state } = useLocation();
  const handlerBack = useCallback(() => {
    document.hist.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SlideContainer>
      <div className="Trim">
        <TitleRow cursor="pointer" title="< Trim" onClick={handlerBack} />
        <TrimContainer
          id={state.id}
          element={document.getElementById(state.id)}
        />
      </div>
    </SlideContainer>
  );
}

export const TrimContainer = connect((state) => ({
  elements: state.scenes[Index()].elements,
}))(({ id, elements, element }) => {
  const [st, setState] = useState({
    perSec: 1,
    width: 10,
    from: 0, //s
  });
  useEffect(() => {
    if (elements[id]) {
      const { duration, stayTime, enter, exit, trimStart, trimEnd } =
        elements[id];
      let add = elements[id];
      let width = document.querySelector(".Trim_bound").clientWidth;
      // setState((s) => ({ ...s, perSec: }));
      let perSec = width / duration;

      console.log({ perSec, width, duration, id });
      console.log(add);
      setState((s) => ({
        ...s,
        from: trimStart,
        width: (trimEnd - trimStart) * perSec,
        perSec,
      }));
    }
  }, [elements[id]]);

  const handleResize = (a, b, c, d) => {
    let vidEle = element;
    let p = d.width;
    let k = p;
    if (b === "left") {
      p = -1 * p;
      k = p;
    } else {
      p = -1 * p;
      // k = -1 * p;
      k = 0;
    }
    // pVidEle.pause();

    setState((s) => {
      let ele = store.getState().scenes[Index()].elements[id];
      let width = s.width - p;
      let data = {};

      if (ele.adjustLength === 0) {
        data = {
          stayTime: width / s.perSec,
        };
      } else if (ele.adjustLength === 1) {
      }

      store.dispatch({
        type: "DRAG_RESIZE",
        data: {
          id: id,
          data: {
            ...data,
            trimStart: st.from + k / s.perSec,
            trimEnd: st.from + k / s.perSec + width / s.perSec,
          },
        },
      });

      vidEle.customVideoSeek(st.from);

      return {
        ...s,
        // width: (st.from + k / s.perSec + d.width / s.perSec)-(st.from + k / s.perSec),
        width,
        from: st.from + k / s.perSec,
      };
    });
    setTimeout(() => {
      UpdateBackground();
    }, 0);
  };

  const handleDrag = (e, data) => {
    // let ele = store.getState().scenes[Index()].elements[id]
    let vidEle = element;
    setState((s) => {
      let from = data.x / s.perSec;
      store.dispatch({
        type: "DRAG_RESIZE",
        data: {
          id: id,
          // width: (width / s.perSec) * document.per,
          data: {
            // stayTime: width / s.perSec,
            trimStart: from,
            trimEnd: from + s.width / s.perSec,
          },
        },
      });
      let pVidEle = document.querySelector(".Trim_Container_Video");
      pVidEle.pause();
      vidEle.customVideoSeek(from);
      pVidEle.customVideoSeek = customVideoSeek;
      pVidEle.currentTime = from;

      return { ...s, from };
    });
  };

  const trimHandle = (e) => {
    let vidEle = e.target;
    if (!vidEle.customVideoSeek) {
      vidEle.customVideoSeek = customVideoSeek;
    }
    let trimStart = st.from;
    let time = vidEle.currentTime;
    let trimEnd = st.from + st.width / st.perSec;
    if (trimStart > time) {
      vidEle.customVideoSeek(trimStart);
    }
    if (trimEnd < time) {
      vidEle.pause();
      vidEle.customVideoSeek(trimStart);
    }
  };

  const [isPlay, setPlay] = useState(false);
  const handlePlayer = (e) => {
    let vidEle = e.target;
    if (vidEle.paused) {
      vidEle.play();
      // setPlay(true);
    } else {
      vidEle.pause();
      // setPlay(false);
    }
  };

  useEffect(() => {
    let vid = document.getElementById("trimVideo");
    if (vid) {
      vid.onplay = () => {
        setPlay(true);
      };
      vid.onpause = () => {
        setPlay(false);
      };
    }
  }, []);

  return (
    <>
      <div className="Trim_Container">
        <img
          src={!isPlay ? `${document.PUBLIC_URL}/ico4/aplay.svg` : `${document.PUBLIC_URL}/ico4/apause.svg`}
          alt="p;au paus "
          className="Trim_Container_btn"
        />
        <video
          onTimeUpdate={trimHandle}
          onClick={handlePlayer}
          className="Trim_Container_Video pointer"
          src={element.src}
          id="trimVideo"
        ></video>
      </div>

      <div className="Trim_bound">
        <Rnd
          bounds=".Trim_bound"
          size={{ height: 32, width: st.width }}
          position={{ x: st.from * st.perSec, y: 0 }}
          minWidth={1}
          onResizeStop={handleResize}
          onDragStop={handleDrag}
          className="Trim_bound_drag"
          // dragAxis="x"
          enableResizing={{ left: true, right: true }}
        ></Rnd>
      </div>
      <div className="flex row j-between Trim_Container_time">
        <div className="flex column">
          From:
          <div className="Trim_Container_time_real">
            {fancyTimeFormat(st.from)}
          </div>
        </div>
        <div className="flex column">
          To:
          <div className="Trim_Container_time_real">
            {fancyTimeFormat(st.from + st.width / st.perSec)}
          </div>
        </div>
      </div>
    </>
  );
});
