import React from "react";
import { connect } from "react-redux";

export default connect((state) => ({
  l: state.config.ScreenLoading,
  t: state.config.loadingText,
}))(function Lazzer({ l, t }) {
  return (
    <div className="ScreenLoading" style={{ display: l ? "flex" : "none" }}>
      <img src={`${document.PUBLIC_URL}/icons/Loading.svg`} alt="hey"></img>
      <div className="Text">{t}</div>
    </div>
  );
});
