/* eslint-disable */
import React, { useRef, useState, useEffect } from "react";
// import { url, token } from "../../config/config";
// import axios from "axios";
import Tick from "./tick.svg";
// import moment from "moment";


const CONTACT_US_CATEGORY = [
	{
		title: "Video Creation",
		selected: <img src={Tick} alt="" />,
		command: 0,
	},
	{
		title: "Sales Page Creation",
		selected: <img src={Tick} alt="" />,
		command: 1,

	},
	{
		title: "Image Personalization",
		selected: <img src={Tick} alt="" />,
		command: 6,

	},
	{
		title: "Video Personalization",
		selected: <img src={Tick} alt="" />,
		command: 7,
	},
	{
		title: "Thumbnail Personalization",
		selected: <img src={Tick} alt="" />,
		command: 8,
	},
	{
		title: "Salespage Personalization",
		selected: <img src={Tick} alt="" />,
		command: 9,
	},
	{
		title: "Subscription",
		selected: <img src={Tick} alt="" />,
		command: 4,

	},
	{
		title: "Other",
		selected: <img src={Tick} alt="" />,
		command: 5,

	},

]

export default function TickOption({ ...props }) {
	const dropdownRef = useRef(null);
	const [isActive, setIsActive] = useState(false);
	const onClick = () => setIsActive(!isActive);
	const [Selected, setSelected] = useState(props.old);

	useEffect(() => {
		setSelected(props.old)
	}, [JSON.stringify(props.old)])



	const handleStatus = async (d) => {
		const obj = {
			command: d
		}
		props.onChangee(obj)
	};


	const handler = (ele) => {
		if (ele.currentTarget.contains(ele.relatedTarget)) {
		} else {
			setIsActive(false);
		}
	};

	return (
		<div
			className="container-select"
			onBlur={(e) => {
				handler(e);
			}}
		>
			<div className="menu-container">

				<button
					onClick={() => {
						onClick();
					}}
					// onBlur={(e)=>{handler(e); console.log("ksksk")}}
					className={`menu-trigger my-upload-select-camp Timeline-select-trigger _tg ${props.className ? "" : ""
						} `}
				>
					{Selected == null ? "Select a Category" : CONTACT_US_CATEGORY.find(e => e.command === Selected).title}
				</button>
				<nav
					onBlur={(e) => {
						handler(e);
					}}
					tabIndex="1"
					ref={dropdownRef}
					className={`menu Timeline-menu Analytixs-timeline-menue ${isActive ? "active" : "inactive"} _tg2`}
				>
					<ul className="mainList">
						{CONTACT_US_CATEGORY.map((e) => {
							return (
								<li
									className="main-list-li pointer prospect-menuList"
									onClick={() => {
										handleStatus(e.command);
										setSelected(e.command);
										setIsActive(false);
									}}
								>
									{e.title}
									{e.command === Selected ? <img src={Tick} alt="" /> : ""}
								</li>
							);
						})}
					</ul>
				</nav>
			</div>
		</div>
	);
}
