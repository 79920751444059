import { fitImageToRect } from "../utils";
import { SIZES } from "./config";
import { getObjectCoords } from "./cropyThings";


export const replaceObj = (obj, src) => {
    return new Promise((resolve, reject) => {
        let _isMax = false;
        let pre = getObjectCoords(obj);
        if (pre.left <= 0 && pre.top <= 0 && pre.height >= SIZES[document?._size || 0].height - 1 && pre.width >= SIZES[document?._size || 0].width - 1) {
            _isMax = true;
        }
        const _prevObjOrgCoord = {
            height: obj._originalElement.height,
            width: obj._originalElement.width,
            angle: obj.angle,
        };

        let _backupProperties = {
            clipPath: obj.clipPath,
            height: obj.height,
            width: obj.width,
            cropX: obj.cropX,
            cropY: obj.cropY,
        };
        obj.set({ clipPath: null, cropX: 0, cropY: 0 });
        obj.setSrc(src, () => {
            const _newObjOrgCoord = {
                height: obj._originalElement.height,
                width: obj._originalElement.width,
            };

            const _ratio = {
                ratioX: _newObjOrgCoord.width / _prevObjOrgCoord.width,
                ratioY: _newObjOrgCoord.height / _prevObjOrgCoord.height,
            };

            obj.set({ angle: 0 });

            _backupProperties.cropX *= _ratio.ratioX;
            _backupProperties.cropY *= _ratio.ratioY;
            _backupProperties.width *= _ratio.ratioX;
            _backupProperties.height *= _ratio.ratioY;

            if (_backupProperties.clipPath) {
                _backupProperties.clipPath.left *= _ratio.ratioX;
                _backupProperties.clipPath.top *= _ratio.ratioY;
                _backupProperties.clipPath.scaleX *= _ratio.ratioX;
                _backupProperties.clipPath.scaleY *= _ratio.ratioY;
            }

            obj.set({ ..._backupProperties });
            fitImageToRect(obj, pre, _isMax);
            obj.set({ angle: _prevObjOrgCoord.angle });
            resolve(obj)
        }, { crossOrigin: "anonymous" })
    });
}