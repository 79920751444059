import React, { useEffect, useState } from "react";
import Popup from "../Popup";
import "./_EEE.scss";
import Modal from "reactjs-popup";
import Button from "../Button";
// import MainPopUp from "../../ImagePersonalised/MainPopup";
import { PerImage } from "./LinkPopup";

import mystore from "../../appStore";
import { connect } from "react-redux";
import { fetcher, url } from "../../../utils";

export default connect((state) => ({
  mailclient: state.media.mailclient,
  mailC: state.mydetail.mailClient,
}))(function MailPopup({ trigger, mailclient, mailC, isVideo = true }) {
  useEffect(() => {
    if (mailclient.next) {
      fetcher({
        method: "GET",
        url: `${url}/api/campaign/emailclient/`,
        res: (res) =>
          mystore.dispatch({
            type: "MEDIA_WITH_NEXT",
            data: { list: res.data.results, next: false, type: "mailclient" },
          }),
        err: (err) => console.log(err),
      });
    }
  }, []);

  const [mailClient, setClient] = useState(mailC);
  const chhoseMail = (mail) => {
    return () => {
      setClient(mail);
    };
  };

  const onSaveMail = (close) => {
    return () => {
      const { id } = mystore.getState().mydetail;

      fetcher({
        method: "PUT",
        // url: `${url}/api/campaign/emailclient/`,
        url: isVideo ? `${url}/api/newvideo/video/details/${id}/` : `${url}/api/newimage/image/details/${id}/`,
        data: { mailClient: mailClient.id },

        res: (res) => {
          mystore.dispatch({
            type: "EDIT_DETAIL",
            data: {
              mailClient,
            },
          });
          close();
        },
        err: (err) => console.log(err),
      });
    };
  };

  return (
    <Modal modal trigger={trigger}>
      {(close) => (
        <Popup
          name="Choose your Mail Client"
          close={() => { close(); setClient(mailC) }}
          //   haveButton={false}
          footer={
            <div className="MailPopup_footer flex row1">
              <Button className="grey" value="Cancel" onClick={() => { close(); setClient(mailC) }}></Button>
              <Button
                value={
                  <>
                    Save <img alt="save" src={`${document.PUBLIC_URL}/icons/save.svg`} />
                  </>
                }
                onClick={onSaveMail(close)}
              ></Button>
            </div>
          }
        >
          <div className="MailPopup" id="MailPopup">
            {mailclient.list.map((e) => {
              return (
                <div className="MailPopup_card flex column">
                  <PerImage
                    name={e}
                    have={false}
                    src={e.src || `${document.PUBLIC_URL}/app/mail.svg`}
                    isActive={e?.id === mailClient?.id}
                    onClick={chhoseMail(e)}
                  />
                  <div className="_name">{e.name}</div>
                </div>
              );
            })}
          </div>
        </Popup>
      )}
    </Modal>
  );
});
