import { useState, useCallback, useMemo } from "react";
import TabContainer from "./components/TabContainer/TabContainer";
import Tabs from "./components/Tabs/Tabs";
// import I from "./components/Tabs/Icons/";
// import { BrowserRouter as Router } from "react-router-dom";
import "./_LeftPanel.scss";
import Avatar from "./components/Avatar/Avatar";
import Templates from "./components/Templates/Templates";
import Background from "./components/Background/Background";
import Music from "./components/Music/Music";
import Settings from "./components/Settings/Settings";
import Text from "./components/Text/Text";
// import ImageScroll from "../Chemistry/Compound/ImageScroll";
import Image from "./components/Image/Image";
import { connect } from "react-redux";
import { getById, Index, Vid } from "../../utils";
import Elements from "./components/Elements/Elements";
import Video from "./components/Video/Video";
import OpacityContainer from "../Chemistry/Atom/OpacityContainer";
import store from "../../store/store";
import UploadFeedBack from "../Chemistry/Compound/UploadFeedBack";
import I from "./icons/I"
import { discardCurrentWork } from "../../utils/useFullFunctions/discardWork";



const mstp = (state) => {
  return {

    frameActive: state.frameActive,
    tabDisabled: state?.disable?.tab,
    leftDisabled: state?.disable?.left,
    mode: state.scenes.mode
  };
};

export default connect(mstp)(function LeftPanel({

  frameActive,
  tabDisabled,
  leftDisabled,
  mode
}) {
  ////console.log("LeftPanel Renders");
  let [currentTab, setCurrentTab] = useState(() => "avatar");

  const handleCurrentTab = (tabName) => {
    const _index = Index();
    document.cxx[_index].discardActiveObject();
    store.dispatch({
      type: "SET_ACTIVE_OBJECT",
      data: "",
    });

    document.disableoutPlayMode = false;
    discardCurrentWork()
    let a = "/" + Vid() + "/" + _index + "/" + tabName;
    setCurrentTab(a);
    if (tabName !== "settings") document._TabName = tabName;
    // document._History = "/video" + a;
    store.dispatch({ type: "TAB_CHANGE", data: tabName });
  };
  const tabIconNameList = useMemo(() => {
    return ([
      {
        src: I.avatar,
        asrc: I.aavatar,
        name: "avatar",
        ele: (
          <OpacityContainer>
            <Avatar />
          </OpacityContainer>
        ),
      },
      {
        src: I.templates,
        asrc: I.atemplates,
        name: "templates",
        ele: (
          <OpacityContainer>
            <Templates />
          </OpacityContainer>
        ),
      },
      {
        src: I.background,
        asrc: I.abackground,
        name: "background",
        ele: (
          <OpacityContainer>
            <Background />
          </OpacityContainer>
        ),
      },
      {
        src: I.text,
        asrc: I.atext,
        name: "text",
        ele: (
          <OpacityContainer>
            <Text />
          </OpacityContainer>
        ),
      },
      {
        src: I.image,
        asrc: I.aimage,
        name: "image",
        ele: (
          <OpacityContainer>
            <Image />
          </OpacityContainer>
        ),
      },
      {
        src: I.video,
        asrc: I.avideo,
        name: "video",
        ele: (
          <OpacityContainer>
            <Video />
          </OpacityContainer>
        ),
      },
      {
        src: I.music,
        asrc: I.amusic,
        name: "music",
        ele: (
          <OpacityContainer>
            <Music />
          </OpacityContainer>
        ),
      },
      {
        src: I.elements,
        asrc: I.aelements,
        name: "elements",
        ele: (
          <OpacityContainer>
            <Elements />
          </OpacityContainer>
        ),
      },
      {
        src: I.settings,
        asrc: I.asettings,
        name: "settings",
        ele: (
          <OpacityContainer>
            <Settings />
          </OpacityContainer>
        ),
        onClick: () => {
          // alert("WOW");
          let obj = getById(document.objActive);
          // console.log(obj);
          if (obj) {
            setTimeout(() => {
              obj.canvas.setActiveObject(obj);
            }, 0);
            // console.log(obj);
          }
        },
      },
    ].filter(e => {
      // console.log(e.name);
      if (mode) {
        if (e.name === "avatar") return false

      }
      if (document.editorType === "image") {
        if (e.name === "templates" || e.name === "music" || e.name === "video") return false;
      }
      return true
    }))
  }, []);

  const disablePlayMode = () => {
    document.outPlayMode()
  }

  return (
    <div className="LeftPanel" onClick={disablePlayMode}>
      <UploadFeedBack />
      {/* <DisabledWrapper isDisable={frameActive || tabDisabled}> */}
      <Tabs
        tabIconNameList={tabIconNameList}
        currentTab={currentTab}
        handleCurrentTab={handleCurrentTab}
      />
      {/* </DisabledWrapper> */}
      {/* <DisabledWrapper isDisable={leftDisabled}> */}
      <TabContainer tabList={tabIconNameList} currentTab={currentTab} />
      {/* </DisabledWrapper> */}
    </div>
  );
});
