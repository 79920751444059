import { realNumber } from "../../utils";
import { getPadding, SIZES } from "../config";


const getWidth = ({ str }) => {
    return parseInt(/(\d+)/.exec(getComputedStyle(document.body).getPropertyValue(`--${str}`))[0])
}

export const firstSize = async ({ cxx, sRef, p = null, index, size = false }) => {
    if (document.fullscreenElement) return;

    try {
        const { clientWidth, clientHeight } = document.body;
        let height, width;
        if (size) {
            width = window.screen.width
            height = window.screen.height
        } else {
            width = clientWidth - getWidth({ str: `leftWidth` }) - getWidth({ str: `rigthWidth` });
            height = clientHeight - getWidth({ str: `headerHeight` }) - getWidth({ str: `timelineHeight` });


        }

        const paddingV = p || getPadding();
        width *= paddingV;
        height *= paddingV;

        let canvasNewHeight,
            canvasNewWidth,
            canvasNewScale,
            // currentVideoScale = 16 / 9;
            currentVideoScale = SIZES[document?._size || 0].ratio;
        if (width / height > currentVideoScale) {
            canvasNewHeight = height;
            canvasNewWidth = parseInt(height * currentVideoScale);
        } else {
            canvasNewHeight = parseInt(width / currentVideoScale);
            canvasNewWidth = width;
        }

        // canvasNewScale = canvasNewWidth / 1920;
        canvasNewScale = canvasNewWidth / SIZES[document?._size || 0].width;
        cxx.setDimensions({
            width: canvasNewWidth,
            height: canvasNewHeight,
        });
        cxx.setZoom(canvasNewScale);
        cxx.renderAll();

        document.canvasNewWidth = canvasNewWidth;
        document.canvasNewHeight = canvasNewHeight;
        document.currentVideoScale = currentVideoScale;
        document.canvasNewScale = canvasNewScale;

    } catch (err) {
        console.log(err);
    }
};


export const resizeAll = async () => {
    if (document.fullscreenElement) return;
    const { clientWidth, clientHeight } = document.body;
    let width = clientWidth - getWidth({ str: `leftWidth` }) - getWidth({ str: `rigthWidth` });
    let height = clientHeight - getWidth({ str: `headerHeight` }) - getWidth({ str: `timelineHeight` });
    const paddingV = getPadding();
    width *= paddingV;
    height *= paddingV;
    let canvasNewHeight, canvasNewWidth, canvasNewScale, currentVideoScale = SIZES[document?._size || 0].ratio;

    if (width / height > currentVideoScale) {
        canvasNewHeight = height;
        canvasNewWidth = parseInt(height * currentVideoScale);
    } else {
        canvasNewHeight = parseInt(width / currentVideoScale);
        canvasNewWidth = width;
    }

    canvasNewScale = canvasNewWidth / SIZES[document?._size || 0].width;
    document.canvasNewWidth = canvasNewWidth;
    document.canvasNewHeight = canvasNewHeight;
    document.currentVideoScale = currentVideoScale;
    document.canvasNewScale = canvasNewScale;

    Object.keys(document.cxx).forEach((e) => {
        let cxx = document.cxx[e];
        try {
            cxx.setDimensions({
                width: canvasNewWidth,
                height: canvasNewHeight,
            });
            cxx.setZoom(canvasNewScale);
            cxx.renderAll();
        } catch (err) {
            console.log(err);
        }
    })
    cropResize();
};


export const cropResize = () => {
    let { canvasNewWidth, canvasNewHeight, canvasNewScale } = document

    let next = realNumber();
    const _crntCanvas = document.cxx[next];
    if (!_crntCanvas) {
        return null;
    }
    const { width: width2, height: height2 } = _crntCanvas;

    const { top, left } = _crntCanvas.upperCanvasEl.getBoundingClientRect();
    let cccx = document.querySelector(".CropCanvas");
    if (cccx) {
        cccx.style.width = width2 + "px";
        cccx.style.height = height2 + "px";
        cccx.style.top = top + "px";
        cccx.style.left = left + "px";
    }

    let cccxa = document.querySelector(".AvatarCropCanvas");
    if (cccxa) {
        cccxa.style.width = width2 + "px";
        cccxa.style.height = height2 + "px";
        cccxa.style.top = top + "px";
        cccxa.style.left = left + "px";
    }

    if (document.ccx) {
        document.ccx.setDimensions({
            width: canvasNewWidth,
            height: canvasNewHeight,
        });
        document.ccx.setZoom(canvasNewScale);
        document.ccx.renderAll();
    }

    if (document.ccxa) {
        document.ccxa.setDimensions({
            width: canvasNewWidth,
            height: canvasNewHeight,
        });
        document.ccxa.setZoom(canvasNewScale);
        document.ccxa.renderAll();

    }

}

document.cropResize = cropResize;
