/* eslint-disable */
import React, { useState, useEffect } from 'react'
import "./index.css"
// import { isLoading as loadingFn } from "../../actions/loading"
// import { url, token } from "../../config/config";
import axios from "axios"
import daa from "./ico/Dashboard & Analytics active.svg"
import dan from "./ico/Dashboard & Analytics normal.svg"
import ga from "./ico/General active.svg"
import gn from "./ico/General normal.svg"
import spca from "./ico/Sales Page Creation active.svg"
import spcn from "./ico/Sales Page Creation normal.svg"
import sca from "./ico/Sharing Campaign active.svg"
import scn from "./ico/Sharing Campaign normal.svg"
import vca from "./ico/Video Creation active.svg"
import vcn from "./ico/Video Creation normal.svg"
import play from "./ico/play.svg"
import Popup from 'reactjs-popup';

import { connect } from 'react-redux';
import { url } from '../../../utils'

const { log, table } = console;

const FAQ = (props) => {
    const [currentTabIndex, setTabIndex] = useState(0);
    const [videos, setVideos] = useState([])
    const [isOpen, setOpen] = useState(false);
    const [currentVideoUrl, setUrl] = useState("")
    const [ComingSoon, setComingSoon] = useState(true)
    // useEffect(() => {
    //     document
    //         .getElementById("bootstrap")
    //         .setAttribute("href", "/bootstrap.min.css");
    //     return () => {
    //         document.getElementById("bootstrap").removeAttribute("href");
    //     };
    // }, []);
    React.useEffect(() => {
        tabHandler({ tab: 0, target: document.querySelector(".faq .tabs .tab") });

    }, [])
    React.useEffect(() => {
        document
            .getElementById("bootstrap")
            .setAttribute("href", "/bootstrap.min.css");
        return () => {
            document.getElementById("bootstrap").removeAttribute("href");
        };
    }, []);

    const tabHandler = ({ tab, target, e }) => {
        // e.stopPropagation()
        // e.stopPropagation()
        setTabIndex(tab);
        // log(e.target)
        let a = url

        axios.get(`${url}/api/accounts/faqs/?faqCategory=${tab}`).then(d => {
            const { data } = d;
            setVideos(data);

        }).catch(error => {
            console.log(error?.response?.status);
            // if (error?.response?.status === 401) {
            //     window.location.href = "/login"
            // }
        })



    }
    // useEffect(() => {
    //     props.loadingFn(false)
    // }, [])

    const tabs = [
        { activeIcon: vca, normalIcon: vcn, text: "Video Creation" },
        { activeIcon: spca, normalIcon: spcn, text: "Sales Page Creation" },
        { activeIcon: sca, normalIcon: scn, text: "Sharing Campaign" },
        { activeIcon: daa, normalIcon: dan, text: "Dashboard & Analytics" },
        { activeIcon: ga, normalIcon: gn, text: "General" }
    ]

    return (
        <div className="d-flex flex-column justify-content-center align-items-center faq w-100">
            <div className="heading">FAQs</div>

            {ComingSoon ? <>

                <div className='d-flex flex-column justify-content-center align-items-center faq' style={{ height: "70vh" }}>

                    <h1 className='my-3'> COMING SOON</h1>

                </div>

            </> :
                <>        <div className="d-flex justify-content-center flex-wrap tabs">
                    {/* <div className="outline" style={{top:XY.y, left:XY.x}}></div> */}
                    {tabs.map((e, i) => {
                        return <div className={`tab ${i == currentTabIndex ? "tab_active" : ""} d-flex align-items-center flex-column`} key={e.text}>
                            <div className="ico"><img src={i == currentTabIndex ? e.activeIcon : e.normalIcon}></img></div>
                            <div className="title" >{e.text}</div>
                            <div className="big" onClick={(k) => tabHandler({ tab: i, target: k.target.parentNode })}></div>
                        </div>
                    })}
                </div>
                    <div className="d-flex flex-row justify-content-start flex-wrap videos">
                        {videos.map((e, i) => {
                            return (
                                <div className="video" key={e.id}>
                                    <div className="title">{e.description}</div>
                                    <div className="_teg">
                                        <img className="thumb" onClick={() => { setUrl(e.video); setOpen(!isOpen) }} src={e.videoThumbnail} alt={e.description} />
                                        <img className="_play" src={play} alt="" onClick={() => { setUrl(e.video); setOpen(!isOpen) }} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Popup open={isOpen} closeOnDocumentClick onClose={() => setOpen(false)}>

                        <video controls className="MainVideo" src={currentVideoUrl}></video>
                        {/* <ReactJWPlayer

id={"lllkjnss"}      
playerId="jw-player1"
playerScript="https://cdn.jwplayer.com/libraries/hGc8n0rJ.js"
file={currentVideoUrl}


/>  */}

                    </Popup>
                </>}


        </div>
    )
}

export default (FAQ);