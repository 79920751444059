import React from 'react'
import { useHistory } from 'react-router'
import EditSelect from '../../components/Chemistry/Atom/EditSelect'
import SelectOption from '../../components/Chemistry/Atom/SelectOption'
import mystore from '../appStore'
import { connect } from 'react-redux'
export default connect(s => ({ s: s.isVideo }))(function Selector({ isVideo }) {
    let hist = useHistory()
    const handleSelect = (e, b, c) => {
        switch (b) {
            case 0:
                mystore.dispatch({ type: "SETTYPE", data: true })
                break;
            case 1:
                mystore.dispatch({ type: "SETTYPE", data: false })

                break;
            default:
                break
        }
        hist.push("/")

    }
    return (
        <div className='Selector newDropDown'>
            <EditSelect
                selected={isVideo ? "Video" : "Image"}
                disabled={true}
                onSelect={handleSelect}
                arr={[{ name: "Video", id: 0 }, { name: "Image", id: 1 }]}
            />
        </div>
    )
})

