import React, { useEffect, useState } from "react";
import Popup from "../Popup";
import "./_EEE.scss";
import Modal from "reactjs-popup";
import Button from "../Button";
// import MainPopUp from "../../ImagePersonalised/MainPopup";
import { PerImage } from "./LinkPopup";
import InfiniteWrapper from "../../../components/Chemistry/Atom/Infinite";
import mystore from "../../appStore";
import { connect } from "react-redux";
import SelectOption from "../../../components/Chemistry/Atom/SelectOption";
import IFrame from "./IFrame/IFrame";
import { FirstMyVideoCard } from "../MyVideo";
import TemplatePopup from "./TemplatePopup";
import { EllipseMe, fetcher, HideAndShowHandler, token, url } from "../../../utils";
import { salespageURL } from "../../../utils/config";
import { DeletePopup } from "../../../components/TimeLine/Components/SceneBar/DeletePopup";
// import { is } from "immer/dist/internal";

export function sha512(str) {
  return crypto.subtle
    .digest("SHA-512", new TextEncoder("utf-8").encode(str))
    .then((buf) => {
      return Array.prototype.map
        .call(new Uint8Array(buf), (x) => ("00" + x.toString(16)).slice(-2))
        .join("");
    });
}

export default connect((state) => ({
  list: state.media.salespage.list,
  cur: state.mydetail.sharingPage,
  my: state.mydetail,
}))(function SharingPopup({ trigger, onOpenHide, onCloseShow, list, cur, my }) {




  let [_cur, setCur] = useState(cur);
  useEffect(() => {
    if (document._ptab) {
      const _pageId = document._ptab?.pageId;
      if (list.length) {
        const _sData = list.find((e) => e.id === _pageId)
        if (_sData) {
          setCur(_sData)
        }
        document._ptab = null;
      }
    }
  }, [list])

  const handleSet = (k) => {
    return () => {
      setCur(k);
    }
  }
  const handleSave = () => {
    // return
    return (close) => {
      if (cur?.id !== _cur.id) {
        const { id } = mystore.getState().mydetail;

        fetcher({
          method: "PUT",
          url: `${url}/api/newvideo/video/details/${id}/`,
          data: {
            sharingPageId: _cur.id,
          },
          res: (res) => {
            close()
            mystore.dispatch({
              type: "EDIT_DETAIL",
              data: {
                allMergeTag: res.data.allMergeTag,
                sharingPage: _cur
              },
            });
            // setNow()
          },
          err: (err) => { close() },
        });

      } else {
        close();
      }
    };
  };

  // const [_cur, setCurr]=useState(_cur)
  const [isPerview, setPerview] = useState({ open: false, });
  const [isConfirm, setConfirm] = useState({
    status: false,
    data: null
  });

  const handleSelect = (k) => {

    return (a) => {
      switch (a.key) {

        case 0:
          setPerview({ open: true, id: k.id })
          break;
        case 1:
          fetcher({
            method: "GET",
            url: `${url}/api/salespage/${k.id}/copy/?video_id=${my.id}&appType=1`,
            res: (res) => {
              let { id } = res.data.result;
              const _ptab = btoa(JSON.stringify({ videoId: my.id, tab: !document.getElementById("_linkPopupBtn") }))
              window.open(`${salespageURL}/${id}/?ptab=${_ptab}`);
            },
            err: (err) => {
              if (err?.response?.status === 401) {
                window.location.href = "/login"
              } else {
                console.error("CANT DUPLICATE", err);
              }
            },
          });

          break;
        case 2:

          setConfirm({
            status: true,
            data: k
          })



          break;

        default:
          break;
      }
    };
  };

  return (
    <Modal modal trigger={trigger} onClose={() => {
      onCloseShow()

    }} onOpen={() => {
      onOpenHide()
    }} closeOnDocumentClick={false}>
      {(close) => (
        <Popup
          UpperId="salespageChoose-1x1"
          name="Choose Sharing Page"
          close={close}
          //   haveButton={false}
          footer={
            <div className="SharingPopup_footer flex row1">
              <Button className="grey" value="Cancel" onClick={() => {
                setCur(cur);
                close();
              }}></Button>
              <Button value="Set Sharing Page" onClick={() => {
                handleSave()(close)

              }} ></Button>
            </div>
          }
        >
          <div className="SharingPopup flex" id="SharingPopup">
            <Modal

              closeOnDocumentClick={false}
              open={isPerview.open}
              onClose={() => {
                setPerview({ open: false })
              }}
            >
              {(close) => (
                <IFrame
                  type="salespage"
                  isSaved={true}
                  id={isPerview.id}
                  close={close}
                />
              )}
            </Modal>
            <InfiniteWrapper
              max={10}
              type={"salespage"}
              target={"SharingPopup"}
              mystore={mystore}
            >

              <DeletePopup
                isConfirm={isConfirm.status}
                setConfirm={(d) => { setConfirm({ status: d, data: null }) }}
                text={"Salespage"}
                onDelete={async () => {
                  fetcher({
                    method: "DELETE",
                    url: `${url}/api/salespage/${isConfirm.data.id}/`,

                    res: (res) => {
                      mystore.dispatch({
                        type: "DELETE_MEDIA",
                        data: {
                          id: isConfirm.data.id,
                          // detail: data.data.result,
                          type: "salespage",
                        },
                      });
                    },
                    err: (err) => { },
                  });

                }}
              />


              {[
                <FirstMyVideoCard
                  have={false}
                  className="SharingPopup_first"
                  ele={
                    <TemplatePopup
                      onOpenHide={() => {
                        HideAndShowHandler("salespageChoose-1x1", "none")

                      }}
                      onCloseShow={() => {
                        HideAndShowHandler("salespageChoose-1x1", "block")

                      }}
                      trigger={<Button value="Choose From Templates"></Button>}

                    />
                  }
                />,

                ...[cur || {}, ...list.filter((k) => k.id !== cur?.id)].map(
                  (e) => {
                    if (Object.keys(e).length > 0)
                      return (
                        <div key={e.id} className="SharingPopup_card flex column">
                          <PerImage
                            isActive={_cur?.id === e.id}
                            className={"SharingPopup_card_img"}
                            name={e.name}
                            have={e.isPersonalized}
                            src={e.previewImage}
                            onClick={handleSet(e)}
                            haveOption={
                              <SelectOption
                                onSelect={handleSelect(e)}
                                ele={
                                  <img alt="option" src={`${document.PUBLIC_URL}/ico/fileOption.svg`} />
                                }
                                list={[
                                  {
                                    name: "Preview",
                                    key: 0,

                                    src: `${document.PUBLIC_URL}/time/unhide.svg`,
                                  },
                                  {
                                    name: "Duplicate & Edit",
                                    src: `${document.PUBLIC_URL}/time/clone.svg`,
                                    key: 1,
                                  },
                                  {
                                    name: "Delete",
                                    src: `${document.PUBLIC_URL}/time/delete.svg`,
                                    key: 2,
                                    isDefault: cur?.id === e.id || e.isDefault
                                  },
                                ]}
                              />
                            }
                          />
                          <div className="_name" title={e.name}>{EllipseMe(e.name, 40)}</div>
                        </div>
                      );
                  }
                ),
              ]}
            </InfiniteWrapper>
          </div>
        </Popup>
      )}
    </Modal>
  );
});

