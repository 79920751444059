/* eslint-disable */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import SelectOption from "../../Chemistry/Atom/SelectOption";
import "./_MergeSpeech.scss";
// import { getCaretPosition, setCaretPosition } from "../../../utils";
import { position } from "caret-pos";
import ContentEditable from "react-contenteditable";
import { MAX_TEXT_LENGTH } from "../../../utils/config";
import { Index } from "../../../utils";
import store from "../../../store/store";
import { connect } from "react-redux";
import LabelInput from "../../Chemistry/Atom/LabelInput";
import EditSelect from "../../Chemistry/Atom/EditSelect";

export default memo(function MergeSpeech({
  text,
  isActive,
  onAdd = () => { },
  onDelete = () => { },
  onPlay = () => { },
  onBlur = () => { },
  id,
  url = "",
  length,
}) {
  const inputRef = useRef();
  // const [words, setWords] = useState(
  //   MAX_WORDS - inputRef?.current?.innerText.trim().length || 0
  // );

  const handleBlur = (k) => {
    document.lastMergeTagCursor = position(k.target);
    document.isCanActive = true
    onBlur(k);
  };
  const handleFocus = (k) => {
    document.isCanActive = false

  }
  const [ablePlay, setAblePlay] = useState(true);
  const [isPlay, setPlay] = useState(false);
  const handleDelete = useCallback(() => {
    if (isActive) onDelete();
  }, [isActive, onDelete]);
  const handlePlay = useCallback(
    (e) => {
      document.hitSpeechAPI({
        cb: () => {
          if (ablePlay) {
            if (audRef) {
              if (audRef.current.paused) {
                audRef.current.play();
              } else {
                audRef.current.pause();
              }
              setPlay(true);
              audRef.current.onended = () => {
                setPlay(false);
              };
              audRef.current.onplay = () => {
                setPlay(true);
              };
              audRef.current.onpause = () => {
                setPlay(false);
              };
            }
            // });
          }
        }
      })

    },
    [ablePlay, onPlay]
  );

  // const [currntTextLength, setcurrntTextLength] = useState(0);
  const [html, setHtml] = useState({ html: "", text, currntTextLength: 0 });
  const convertTextToHtml = (_t) => {
    if (!_t) {
      return { html: "", text: "" };
    }
    const _tt = _t.slice(0, MAX_TEXT_LENGTH);
    _t = _tt;
    var keywords = _t.match(/{{(.*?)}}/g) || [];
    keywords = new Set(keywords);
    if (keywords) {
      keywords.forEach((e) => {
        _t = _t.replaceAll(
          e,
          `<span class="statement" style="color: #e7416a">${e}</span>`
        );
      });
    }
    return { html: _tt, text: _t };
  };
  const [_text, setText] = useState(text);
  useEffect(() => {
    document.lastMergeTagCursor = undefined;
    const handle = async () => {
      await setHtml({
        html: convertTextToHtml(text).text,
        text,
        currntTextLength: text.length,
      });

    };

    handle();

  }, []);

  useEffect(() => {
    let _te = document.getElementById(`_${id}`);
    if (document.lastMergeTagCursor !== undefined && _te) {
      position(_te, document.lastMergeTagCursor);
    }

  }, [html]);

  const handleChange = async (evt) => {
    if (evt.nativeEvent.inputType) {
      try {
        let _te = evt.currentTarget;
        let _event = evt.nativeEvent;
        const pos = position(_te);
        let currentPos = pos.pos;
        let _tm = _te.innerText.slice(0, MAX_TEXT_LENGTH);
        if (_tm.slice(-1) !== '\n') {
          _tm += '\n';
          if (_tm.length - 1 === pos) {
            currentPos += 1;
          }
        }
        if (_event.inputType === "insertParagraph") {
          currentPos += 1;
          if (html.currntTextLength + 1 !== _tm.length) {
            // remove last character
            _tm = _tm.substr(0, currentPos) + _tm.substr(currentPos + 1);
          }
        }
        let _t = _tm;
        var keywords = _t.match(/{{(.*?)}}/g) || [];
        keywords = new Set(keywords);
        if (keywords) {
          keywords.forEach((e) => {
            _t = _t.replaceAll(
              e,
              `<span class="statement" style="color: #e7416a">${e}</span>`
            );
          });
        }
        setHtml({ html: _t, text: _tm, currntTextLength: _tm.length });
        document.lastMergeTagCursor = currentPos;
      } catch (err) {
        console.log(`mergetag`, err);
      }
    }
  };

  const addMergeTag = (mergeTag, len) => {
    let _te = document.getElementById(`_${id}`);
    let currentPos;

    currentPos = len;

    let _tm = _te.innerText;
    _tm = [_tm.slice(0, currentPos), mergeTag, _tm.slice(currentPos)]
      .join("")
      .slice(0, MAX_TEXT_LENGTH);
    let _t = _tm;
    var keywords = _t.match(/{{(.*?)}}/g) || [];
    keywords = new Set(keywords);
    if (keywords) {
      keywords.forEach((e) => {
        _t = _t.replaceAll(
          e,
          `<span class="statement" style="color: #e7416a">${e}</span>`
        );
      });
    }
    currentPos += mergeTag.length + 1;

    setHtml({ html: _t, text: _tm, currntTextLength: _tm.length });
    position(_te, currentPos);
    _te.focus();
  };

  const [showImg, setShowImg] = useState(false);

  const audRef = useRef();

  const handleMergeTag = (e) => {
    addMergeTag(e.name, document.lastMergeTagCursor?.pos || 0);
  };

  const variableFocus = (e) => {
    setMerge(`{{variable}}`)
    setTimeout(() => {

      e.target.setSelectionRange(2, 10)
    }, 0)
  }

  const variableChange = e => {
    setMerge(e)

  }

  const onEnter = e => {
    handleMergeTag({ name: e.target.value })
  }
  const [merge, setMerge] = useState("Insert Variable")
  return (
    <div className="MergeSpeech">
      <audio
        className={`MergeSpeech_audio`}
        ref={audRef}
        id={`audio_${id}`}
        preload="auto"
        src={url}
      />

      <ContentEditable
        tagName="pre"
        tabIndex="1"
        onChange={handleChange}
        html={html.html}
        // onFocus={handleSendSpeechLoad}
        className="MergeSpeech_textbox"
        onBlur={handleBlur}
        id={`_${id}`}
        onFocus={handleFocus}
      />
      <div className="MergeSpeech_row">
        <div className="MergeSpeech_row_lang">English - US</div>
        <div className="MergeSpeech_row_less">{Number(html.text.length)}</div>
      </div>
      <div className="MergeSpeech_function">
        <div className="MergeSpeech_function_row">
          <div className="MergeSpeech_function_row_select">
            <SelectOption
              list={document._mtag}
              onShow={setShowImg}
              onSelect={handleMergeTag}
              ele={
                <div className="MergeSpeech_insert">
                  Insert Variable{" "}
                  <img
                    alt="text"
                    src={`${document.PUBLIC_URL}/ico/insertVariable.svg`}
                    style={{ transform: `rotateZ(${showImg ? 0 : 180}deg)` }}
                  />
                </div>
              }
            />

            {/* <EditSelect
              className="MergeSpeech_function_row_select_edit newDropDown"
              selectedIndex={0}
              onSelect={e => handleMergeTag({ name: e })}
              // list={["{{name}}", "{{location}}"]}

              // onSelect={handleAddVariable}
              onInputFocus={variableFocus}
              hasFont={true}
              list={["{{name}}", "{{location}}"]}
              haveVariable={true}
              title={merge}
              onInputEnter={onEnter}
              onInputChange={variableChange}
            /> */}
          </div>
          <BottomButtons
            onAdd={onAdd}
            handlePlay={handlePlay}
            handleDelete={handleDelete}
            html={html}
            isActive={isActive}
            isPlay={isPlay}
          />
        </div>
      </div>
    </div>
  );
});

const BottomButtons = connect((state) => ({
  speechLoad: false,
}))(
  ({ onAdd, handlePlay, handleDelete, html, isActive, speechLoad, isPlay }) => {
    return (
      <div className="MergeSpeech_function_row_icons">
        {/* <img></img> */}
        {Number(html.text.length) > 0 && (
          <img alt="img" src={`${document.PUBLIC_URL}/ico/addScript.svg`} onClick={onAdd} />
        )}

        {!speechLoad ? (
          !isPlay ? (
            <img
              src={`${document.PUBLIC_URL}/ico/${Number(html.text.length) > 0 ? "a" : ""}playsrtipt2.svg`}
              alt=""
              onClick={handlePlay}
            />
          ) : (
            <img src={`${document.PUBLIC_URL}/ico/pause.svg`} alt="" onClick={handlePlay} />
          )
        ) : (
          <img
            className="MergeSpeech_function_row_icons_loading"
            src={`${document.PUBLIC_URL}/icons/Loading.svg`}
            alt=""

          />
        )}
        <img
          src={`${document.PUBLIC_URL}/ico/${isActive ? "a" : ""}deleteScript.svg`}
          alt=""
          onClick={handleDelete}
        />
      </div>
    );
  }
);

export let speechToArrTime = (onTimeLine = false) => {
  let scene = store.getState().scenes[Index()];
  let speech = scene.speech;
  let av = scene.elements[`avatar${Index()}`];

  let arr = JSON.parse(speech.arr);
  let sum = onTimeLine ? av.enterDelay + av.enter : 0;
  let newArr = arr.map((e, i) => {
    let { duration } = speech.data[e];
    if (arr[i + 1]) {
      let { delay: delay1 } = speech.data[arr[i + 1]];
      sum += duration + delay1;
    }

    return sum;
  });
  newArr.splice(0, 0, onTimeLine ? av.enterDelay + av.enter : 0);
  newArr.splice(newArr.length - 1, 1);

  return newArr.map((e, i) => {
    return { time: e, id: arr[i] };
  });
};

document.ap = speechToArrTime;
export const Delay = ({ time, onChange = () => { } }) => {
  const [_time, setTime] = useState(time);
  const handle = (e) => {
    let d = Number(e.target.value);
    if (d >= 0 && d <= 10) {

      setTime(d);
    }

  };
  useEffect(() => {
    setTime(Number(time));
  }, [time]);
  return (
    <div className="Delay">
      <div className="Delay_wrapper">
        Delay{" "}
        <LabelInput
          value={String(_time)}
          min={0}
          max={10}
          step={0.5}
          onChange={handle}
          type="number"
          onBlur={onChange}
          haveInc={true}
          innerWidth={"30px"}
          width={"70px"}
        />{" "}
        <div style={{ marginLeft: "5px", fontSize: "12px" }}>S</div>

      </div>
    </div>
  );
};
