import { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "../_Avatar.scss";
import ThreeCard from "../../../../Chemistry/Atom/ThreeCard";
import TitleRow from "../../../../Chemistry/Atom/TitleRow";
import Tabium from "../../../../Chemistry/Compound/Tabium";
import { useHistory } from "react-router-dom";
import store from "../../../../../store/store";
import { connect } from "react-redux";
import SlideContainer from "../../../../Chemistry/Atom/SlideContainer";
import EditSelect from "../../../../Chemistry/Atom/EditSelect";
import { fetcher, url, getById, arrFromStore } from "../../../../../utils";

function ArrowRight({ onClick }) {
  return (
    <img onClick={onClick} src={`${document.PUBLIC_URL}/icons/sliderRight.svg`} alt={"arrowRight"} />
  );
}
function ArrowLeft({ onClick }) {
  return (
    <img onClick={onClick} src={`${document.PUBLIC_URL}/icons/sliderLeft.svg`} alt={"arrowLeft"} />
  );
}


const getAvatarSoundList = (avatarId, languageId) => {
  return new Promise((res, rej) => {
    fetcher({
      method: "POST",
      url: `${url}/api/avatars/avatar_voices/`,
      data: { languageId, avatarId },
      res: (r) => {
        res(r.data.results);
      },
      err: (err) => {
        rej(err)
      },
    });

  })
};

const CustomVideoLoad = ({ avatar, audID, i, mute }) => {
  const [load, setLoad] = useState(false);
  const [play, setPlay] = useState(false);
  useEffect(() => {
    let vid = document.querySelector(`#avaterShow${i}`);
    let pp = document.querySelectorAll(".customVideoLoad_load");
    vid.onpause = function () {
      setLoad(false)
      setPlay(false)
      console.log("Pause");
    }
    vid.onwaiting = function () {
      setLoad((k) => {
        pp.forEach((g) => {
          g.style.display = "flex";
        });
        // console.log("Waiting", load, pp.style.display);
        // console.log(pp);
        return true;
      });
    };
    vid.onplaying = function () {
      setLoad((k) => {
        // pp.style.display = "none";
        pp.forEach((g) => {
          g.style.display = "none";
        });
        // console.log("Plaing", load, pp.style.display);
        // console.log(pp);

        return false;
      });
    };
  }, [setLoad]);
  const handleEnd = () => {
    let vid = document.querySelectorAll(`#avaterShow${i}`);

    setPlay(false);
  };
  const handlePlay = () => {
    let vid = document.querySelectorAll(`#avaterShow${i}`);
    vid.forEach((e) => (e.muted = true));
    vid[0].muted = mute || false;
    setPlay((k) => {
      if (!k) {
        // vid.play();

        vid.forEach((g) => {
          // g.mute = true;
          g.play();
        });
      } else {
        // vid.pause();
        let pp = document.querySelectorAll(".customVideoLoad_load");
        pp.forEach((g) => {
          g.style.display = "none";
        });
        // setLoad(false);

        vid.forEach((g) => {
          // g.mute = true;
          g.pause();
        });
      }
      return !k;
    });
  };
  return (
    <div className="customVideoLoad">
      <div className="customVideoLoad_load">
        <img src={`${document.PUBLIC_URL}/icons/Loading.svg`} alt="" />
      </div>

      {!load && (
        <div className="customVideoLoad_play">
          <img
            className="customVideoLoad_play_img"
            src={`${document.PUBLIC_URL}/ico4/a${!play ? "play" : "pause"}.svg`}
            alt=""
            onClick={handlePlay}
          />
        </div>
      )}

      <video
        key={avatar.id}
        id={`avaterShow${i}`}
        // loop
        preload="none"
        poster={avatar.largeImg}
        className="avaterShow"
        src={`https://api.autogenerate.ai/media/avatar_sounds/samples/${avatar.id}_${audID}.mp4`}
        muted
        onEnded={handleEnd}
        onPlay={() => {
          setPlay(true);
        }}
      // onPlaying={() => {
      //   setLoad(false);
      // }}

      // controls
      />
    </div>
  );
};

function AvatarSettings({ changeCurrentAvatar, currentAvatar, avatarList }) {
  const history = useHistory();
  // const { avatar,currentAvatar } = store.getState();
  const {
    video: { id: vidID },
    audio: { id: audID },
  } = currentAvatar;

  const slideRef = useRef();
  const [a, setSl] = useState(0);

  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    beforeChange: (current, next) => {
      //console.log(current, next);
      setSl(next);
      handleClickActiveImage({
        image: avatarList.avatarImageList[next],
        type: "image",
      })()

      document.querySelectorAll(".avaterShow").forEach((vid) => vid.pause());
    },
  };

  const handlerBack = useCallback(() => {
    history.goBack();
  }, []);

  const handleClickActiveImage = (i, index) => {
    return async () => {
      // if geneder change update sound
      await store.dispatch({ type: "LOADING", data: "Avatar is Loading ..." });
      if (currentAvatar.image.gender !== i.image.gender) {
        try {
          let soundList = await getAvatarSoundList(i.image.id, tempLang.id);
          if (soundList.length > 0) {
            // change sound
            setLanguageList(soundList);
            const _index = avatarList.avatarLanguages.findIndex((e) => e.id === tempLang.id);
            setLang({ id: tempLang.id, i: _index });
            await changeCurrentAvatar({ image: i.image, audio: soundList[0], type: 'both' }, false);
          } else {
            await changeCurrentAvatar(i, false);
          }

        } catch (err) {
          await changeCurrentAvatar(i, false);
        }
      } else {
        await changeCurrentAvatar(i, false);
      }
      await store.dispatch({ type: "LOADING", data: "Avatar is Loading ..." });
      if (index !== undefined) {
        slideRef.current.slickGoTo(index);
      }

    };
  };

  const updateVoiceList = async (e, i) => {
    let languageId = avatarList.avatarLanguages[i]?.id;
    let avatarId = currentAvatar.image.id;

    document.querySelectorAll(".avaterShow").forEach((vid) => vid.pause());
    let soundList = await getAvatarSoundList(avatarId, languageId);
    setLanguageList(soundList);
    setLang({ id: languageId, i });
  }

  useEffect(() => {
    slideRef.current.slickGoTo(
      avatarList.avatarImageList.findIndex(
        (e) => e.id === currentAvatar.image.id
      )
    );

  }, []);

  const handleClickActiveAudio = (i, index) => {
    return () => {
      changeCurrentAvatar(i);
      store.dispatch({ type: "SET_LANG", data: tempLang });
      slideRef.current.slickGoTo(index);
      // document.hitSpeechAPI({ cb: () => { }, err: () => { }, con: true, fullWorkspace: true })


    };
  };

  const [languageList, setLanguageList] = useState([]);
  const [tempLang, setLang] = useState(currentAvatar.lang || {});
  const [nowLang, setNowLang] = useState(avatarList.avatarLanguages.find(e => e.id === currentAvatar.lang.id));

  const handleChangeLanguage = async (avId) => {
    let languageId = currentAvatar.lang.id;
    let avatarId = avId || currentAvatar.image.id;
    let soundList = await getAvatarSoundList(avatarId, languageId);
    setLanguageList(soundList);
    const _index = avatarList.avatarLanguages.findIndex((e) => e.id === languageId);
    setLang({ id: languageId, i: _index });

  };

  useEffect(() => {
    handleChangeLanguage();
  }, []);

  const [uniMute, setUniMute] = useState(false);

  return (
    <SlideContainer>
      <div className="Avatar__Settings">
        <TitleRow
          cursor="pointer"
          title="< Avatar Settings"
          onClick={handlerBack}
        />
        <div className="Avatar__Settings__video">
          <Slider ref={slideRef} {...settings}>
            {/* {Object.keys(avatarList.avatarVideoWithVoice)
              .filter((e) => parseInt(e.split("_")[1]) === audID)
              .map((e) => avatarList.avatarVideoWithVoice[e])
              .map((e) => {
                return <video key={e.video} src={e.video} muted />;
              })} */}

            {avatarList.avatarImageList.map((avatar, i) => {
              return (
                <CustomVideoLoad
                  key={avatar.id}
                  avatar={avatar}
                  i={i}
                  audID={currentAvatar.audio.id}
                  mute={uniMute}
                />
              );
            })}
          </Slider>

        </div>

        {document.editorType === "video" && <Tabium
          arrTab={[
            { name: "Face", src: `${document.PUBLIC_URL}/icons/face.svg`, asrc: `${document.PUBLIC_URL}/icons/aface.svg` },
            {
              name: "Voice",
              src: `${document.PUBLIC_URL}/icons/voice.svg`,
              asrc: `${document.PUBLIC_URL}/icons/avoice.svg`,
            },
          ]}
        >
          <div className="row justify-content-between wrap">
            {avatarList.avatarImageList.map((e, i) => {
              return (
                <ThreeCard
                  title={e.name}
                  index={i}
                  key={e.id + "image"}
                  isActive={currentAvatar.image.id === e.id}
                  src={e.img}
                  asrc={e.img}
                  onClick={handleClickActiveImage(
                    { image: e, type: "image" },
                    i
                  )}
                />
              );
            })}
          </div>
          <div className="column justify-content-between wrap">
            <EditSelect
              className="myLang"
              disabled={true}
              arr={avatarList.avatarLanguages.map((k) => ({
                ...k,
                src: k.image,
              }))}
              selectedIndex={tempLang.i}

              haveIcon={true}
              // title={}
              selected={null}
              onSelect={updateVoiceList}
            />
            <div className="threeGrid ">
              {languageList.map((e, i) => {
                return (
                  <ThreeCard
                    title={e.name}
                    key={e.id}
                    index={i}
                    isActive={currentAvatar.audio.id === e.id}
                    className="_THREEICONS"
                    src={`${document.PUBLIC_URL}/icons/voiceUnselected.svg`}
                    asrc={`${document.PUBLIC_URL}/icons/voiceSelected.svg`}
                    onClick={handleClickActiveAudio({
                      audio: { ...e, lang: nowLang },
                      type: "audio",
                    })}
                  />
                );
              })}
            </div>

            {/* </div> */}
          </div>
        </Tabium>}

        {document.editorType === "image" && <div className="row justify-content-between wrap AVATARVIEW">
          {avatarList.avatarImageList.map((e, i) => {
            return (
              <ThreeCard
                title={e.name}
                index={i}
                key={e.id + "image"}
                isActive={currentAvatar.image.id === e.id}
                src={e.img}
                asrc={e.img}
                onClick={handleClickActiveImage(
                  { image: e, type: "image" },
                  i
                )}
              />
            );
          })}
        </div>}
      </div>
    </SlideContainer>
  );
}

export const replaceAvatar = ({ index, url }) => {
  return new Promise((res) => {
    const obj = getById(`avatar${index}`, index);
    if (obj) {
      if (obj?.type === "image") {
        obj.setSrc(url, function (img) {
          document.cxx[index].fire("object:modified");
          document.cxx[index].renderAll();
          res()
        }, { crossOrigin: "anonymous" });
      } else {
        obj.set({ statefullCache: true, cacheProperties: ["urlChanged"] });
        if (obj._objects.length > 1) {
          obj._objects[obj._objects.length - 1].setSrc(url, function (img) {
            obj.urlChanged = Math.random() + Math.random();
            document.cxx[index].fire("object:modified");
            document.cxx[index].renderAll();
            res()
          }, { crossOrigin: "anonymous" });
        }
      }
    }
  })
}

const mstp = (state) => {
  return {
    currentAvatar: state.scenes.currentAvatar,
    avatarList: state.avatar,
  };
};
const mftp = (dispatch) => {
  return {
    changeCurrentAvatar: async (data, isLoading = true) => {

      let state = store.getState();

      await dispatch({ type: "CURRENT_AVATAR", data: { ...data } });
      await dispatch({ type: "SPEECH_DISABLE", data: true })
      document.fromVoiceSpeech = true
      if (data.image) {
        let urlImg = data[Object.keys(data)[0]].transparentImage;
        isLoading && await dispatch({ type: "LOADING", data: "Avatar is Loading ..." });

        let arr = arrFromStore(state);
        for (let i = 0; i < arr.length; i++) {
          await replaceAvatar({ index: arr[i], url: urlImg })
        }

        isLoading && await dispatch({ type: "LOADING", data: "Avatar is Loading ..." });

      }
    },
  };
};
export default connect(mstp, mftp)(AvatarSettings);
