export const checkBufferVideoEventHelper = (time, vidEle, _scene) => {
  if (_scene) {
    const { adjustLength, trimEnd, trimStart, stayTime, enterDelay } = _scene.elements[vidEle.id];
    const _videoTime = vidEle.currentTime;
    vidEle._fabObj.videoTime = _videoTime;
    if (adjustLength === 0) {
      if (trimStart > _videoTime) {
        vidEle.customVideoSeek(trimStart);
      }
      if (trimEnd < _videoTime) {
        vidEle.pause();
        vidEle.customVideoSeek(trimEnd);
      }
    }
    if (adjustLength === 1) {
      if (trimStart >= _videoTime) {
        vidEle.customVideoSeek(trimStart);
        //console.log("loop start");
      }
      if (trimEnd <= _videoTime) {
        vidEle.customVideoSeek(trimStart);
        vidEle.play();
        //console.log("loop end");
        // //console.log("Loop");
      }
      if (enterDelay + stayTime < time) {
        // vidEle.customVideoSeek( trimEnd);
        //console.log("loop payse");
        vidEle.pause();
      }
    }
    if (adjustLength === 2) {
      if (trimStart > _videoTime) {
        vidEle.customVideoSeek(trimStart);

        //console.log("Freze start");
      }
      if (trimEnd < _videoTime) {
        vidEle.customVideoSeek(trimEnd);

        vidEle.pause();
        //console.log("Freze end");
      }
      if (enterDelay + trimEnd < time) {
        vidEle.customVideoSeek(trimEnd);

        vidEle.pause();
        //console.log("Freze pause");
      }
    }
    if (adjustLength === 3) {
      let max = document._Max / document.per;
      let speed = (trimEnd - trimStart) / max;
      //console.log({ speed, trimEnd, trimStart, max });
      vidEle.playbackRate = speed;
      //console.log({ speed });
      if (stayTime < time - enterDelay) {
        vidEle.pause();
      }
    }
    // videoPlayUpdateFilter(vidEle._fabObj);
  }
};

export const checkBufferVideoEvent = (vidEle) => {
  //console.log("checkBufferVideoEvent");
  vidEle.onreadystatechange = (e) => {
    ////console.log(e);
  };
  vidEle.onplay = (e) => {
    //console.log(vidEle.id, "play");
    // store.dispatch({ type: "PASS", data: "PLAY" });
  };
  vidEle.onpause = (e) => {
    //console.log(vidEle.id, "pause");
    // store.dispatch({type:"PASS"})
    // store.dispatch({type:"PASS"})
    // store.dispatch({ type: "PASS", data: "PAUSE" });
  };
  vidEle.ontimeupdate = (e) => {
    // return;
    // //console.log(vidEle.readyState);
    // //console.log();
    // console.log(vidEle.currentTisme);
    // if (_storeSceneData[Index()].elements[vidEle.id]) {
    //   const { trimStart, trimEnd, adjustLength, stayTime, enter, enterDelay } =
    //     _storeSceneData[Index()].elements[vidEle.id];
    //   const timeLine = document.txx.timeLine[Index()].time();
    // const timeEnd = document.txx.timeLine[Index()].duration();
    //   ////console.log(time);
    //   vidEle._fabObj.videoTime = time;
    //   videoPlayUpdateFilter(vidEle._fabObj);
    //   if (adjustLength === 0) {
    //     if (trimStart > time) {
    //       vidEle.currentTime = trimStart;
    //     }
    //     if (trimEnd < time) {
    //       vidEle.pause();
    //       vidEle.currentTime = trimStart;
    //     }
    //   }
    //   if (adjustLength === 1) {
    //     if (trimStart > time) {
    //       vidEle.currentTime = trimStart;
    //     }
    //     if (trimEnd < time) {
    //       // vidEle.pause();
    //       vidEle.currentTime = trimStart;
    //       if (stayTime < timeLine - enterDelay - enter) {
    //         vidEle.pause();
    //       }
    //     }
    //   }
    //   if (adjustLength === 2) {
    //     if (trimStart > time) {
    //       vidEle.currentTime = trimStart;
    //     }
    //     if (trimEnd < time) {
    //       // vidEle.pause();
    //       // vidEle.currentTime = trimStart;
    //       vidEle.pause();
    //     }
    //   }
    //   if (adjustLength === 3) {
    //     // playbackRate
    //     const {
    //       trimStart,
    //       trimEnd,
    //       adjustLength,
    //       stayTime,
    //       enter,
    //       enterDelay,
    //     } = _storeSceneData[Index()].elements[vidEle.id];
    //     let elements = _storeSceneData[Index()].elements;
    //     let max = Math.max(
    //       ...Object.keys(elements).map((e) => {
    //         const { adjustLength, stayTime, enter, exit, enterDelay } =
    //           elements[e];
    //         if (adjustLength !== 3) {
    //           return enterDelay + stayTime + enter + exit;
    //         } else {
    //           return 0;
    //         }
    //       })
    //     );
    //     let speed = (trimEnd - trimStart) / max;
    //     ////console.log({ speed, trimEnd, trimStart, max });
    //     vidEle.playbackRate = speed;
    //   }
    // }
  };
};
