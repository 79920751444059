import store from "../store/store";
import { Index } from "../utils";
import { discardCurrentWork } from "./useFullFunctions/discardWork";
import { cropResize } from "./useFullFunctions/resizeCanvas";

export const openFullscreen = async (e) => {
  discardCurrentWork({ _goB: true });

  e.stopPropagation();
  let index = Index();
  var elem = document.getElementById(`screen${index}`);
  //   elem.style.position = "fixed";
  store.dispatch({ type: "MUSICC_REMOVE", data: true });

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
  document.firstResize[index](1, true);
};

export const closeFullScreen = async ({ }) => {
  if (document.fullscreenElement) {
    // open
  } else {
    let index = Index();
    document.firstResize[index]();
    store.dispatch({ type: "MUSICC_REMOVE", data: false });
    cropResize();
  }
};
