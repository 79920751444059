import React, { useState } from "react";
import { useHistory } from "react-router";
import Button from "./Button";
import "./_CSS.scss";
export default function VideoCreator() {
  const hist = useHistory();

  return (
    <div className="VideoCreator flex column center">
      <Ratio />
      <Button
        onClick={() => {
          window.open(`/video?size=${document._size}`, "_blank");
        }}
        value={<>Create Video</>}
      />
    </div>
  );
}

export const Ratio = ({ onChange = () => { } }) => {
  const [tab, setTab] = useState(document._size);
  const handleTab = (_) => {
    return () => {
      document._size = _;
      onChange(_);
      setTab(_);
    };
  };
  return (
    <div className="Ratio flex">
      {[0, 1, 2].map((e) => (
        <div
          onClick={handleTab(e)}
          className={`Ratio_button flex center  ${e === tab ? "Ratio_button_active" : ""
            }`}
        >
          <img src={`${document.PUBLIC_URL}/app/ratio${e}.svg`} alt={"ratio"} />
        </div>
      ))}
    </div>
  );
};
