/* eslint-disable */

import React, { useState, useEffect } from "react";
// import { login } from "./actions/auth";
import { connect } from "react-redux";
import { Link, Redirect, useParams, useRouteMatch } from "react-router-dom";
import axios from "axios";
// import { url } from "../../config/config";

import { ReactComponent as VerifyEmailIcon } from "../App/extras/misc/mailverify.svg";
import { ReactComponent as Autogenerate } from "../App/extras/aiLogo.svg";
import { ReactComponent as CrossError } from "../App/extras/misc/loginerrorcross.svg";
import LoadingComp from "../App/JohnDoe/common/loading";
import { ReactComponent as PinkTick } from "../App/extras/analytics/tickAnalyticSm.svg";
import { ReactComponent as WhiteTick } from "../App/extras/misc/tickWhite.svg";
import { url } from "../utils";
import mystore from "../App/appStore";
function Login(props) {
	let { id } = useParams();
	const [Details, setDetails] = useState({
		email:
			props.location && props.location.email
				? props.location.email
				: "HelpFrom@backend.com",
		code: id,
		password: "",
		password2: "",
	});
	const [InsideErrors, setInsideErrors] = useState({
		isError: false,
		error: [],
	});
	const [Step, setStep] = useState(-1);
	const [Errors, setErrors] = useState({ isError: false, message: "" });
	const [Redirectt, setRedirectt] = useState(false);

	useEffect(async () => {
		const messages = {
			code: {
				0: "Code not valid",
				1: "Code is expired.",
			},
		};
		const res = await axios.get(
			`${url}/api/accounts/password/reset/verify/?code=${id}`
		);
		console.log(res);
		if (res.data.isError) {
			setStep(0);
			setErrors({
				isError: true,
				message: messages.code[res.data.message.code.status],
			});
		} else {
			setStep(1);
			setDetails({ email: res.data.email });
		}
	}, []);

	const handlerSubmit = (e) => {
		var password = /[!@#$%^&*(),.?":{}|<>-_;:|]/g;

		if (Details.password.length >= 8 && Details.password.match(password)) {
			if (
				document.getElementById("password-input").value ===
				document.getElementById("password2-input").value
			) {
				handleReset();
			} else {
				document.getElementById("password2-input").classList.add("errorInput");
			}
		} else {
			var arr = [];
			var error = false;

			if (Details.password.length <= 8) {
				document.getElementById("password-input").classList.add("errorInput");
				error = true;
				arr.push({
					status: false,
					message: "8 or more characters",
				});
			} else {
				arr.push({
					status: true,
					message: "8 or more characters",
				});
			}

			if (Details.password.match(password)) {
				document.getElementById("password-input").classList.add("errorInput");
				error = true;
				arr.push({
					status: true,
					message: "At least 1 symbol",
				});
			} else {
				arr.push({
					status: false,
					message: "At least 1 symbol",
				});
			}
		}

		if (arr && arr.length > 0 && error) {
			setInsideErrors({
				isError: true,
				error: arr,
			});
		} else {
			setInsideErrors({
				isError: false,
				error: [],
			});
		}
	};

	const handleReset = async (e) => {
		const res = await axios.post(
			`${url}/api/accounts/password/reset/verified/`,
			{ code: id, password: Details.password }
		);
		const messages = {
			code: {
				0: "Code not valid",
				1: "Code is expired.",
			},
		};
		console.log(res);
		if (res.data.isError) {
			var obj;

			Object.keys(res.data.message).map((d) => {
				obj = {
					isError: true,
					...obj,
					message: messages[d][res.data.message[d].status],
				};
			});

			setErrors(obj);
		} else {
			// setRedirectt(true);
			setErrors({
				isError: false,
			});
		}
	};
	const handleChange = (e) => {
		e.target.classList.remove("errorInput");
		setErrors({ isError: false });
		setDetails({ ...Details, [e.target.name]: e.target.value });
	};

	const al = () => {
		if (Step === 0) {
			return (
				<div
					key={1}
					className={`login-wrapper ${Step === 1 ? "MoveLeft" : ""}`}
					style={{ maxWidth: "513px" }}
				>
					<Autogenerate style={{ width: "240px" }} />

					<h1 className="login-header">Reset Password Failed</h1>

					<div className="login-inner-wrapper">
						<div className="login-gp-1">
							<label className="login-main-label">{Errors.message}</label>
							{/* <label className="login-verification-label">{Details.email}</label> */}

							{/* <GoogleAuth /> */}
							{/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
						</div>

						<div className="login-form">
							<VerifyEmailIcon />
						</div>

						<div className="login-footer">
							<Link
								to={{
									pathname: "/login",
									// email:Details.email
								}}
								className="login-main-button link-custom"
								style={{ margin: "50px 0 0 0", width: "200px" }}
							>
								Login
							</Link>

							{/* <Link to="/signup" className="link-custom forget-login-main" >Forgot Password?</Link> */}
						</div>
					</div>
				</div>
			);
		}

		if (Step === -1) {
			return <LoadingComp style={{ width: "30vw" }} />;
		}

		if (Step === 1) {
			return (
				<div className="login-wrapper">
					<Autogenerate style={{ width: "240px" }} />

					<h1 className="login-header">Change Password.</h1>

					<div className="login-inner-wrapper">
						<div className="login-gp-1">
							<label className="login-main-label">
								Change password for account linked to{" "}
							</label>
							<label
								className="login-verification-label"
								style={{ margin: "0 0 40px 0" }}
							>
								{Details.email}
							</label>

							{/* <GoogleAuth /> */}
							{/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
						</div>

						<div className="login-form">
							{Errors.isError ? (
								<div className="error-login-info">
									<label htmlFor="" className="error-login-info-heading">
										Error !
									</label>
									<label htmlFor="" className="error-login-info-label">
										{Errors.message}
									</label>
									<CrossError
										className="pointer"
										onClick={() => {
											setErrors({
												...Errors,
												isError: false,
											});
										}}
										style={{ position: "absolute", right: "16px", top: "35%" }}
									/>
								</div>
							) : null}

							<span style={{ width: "100%", position: "relative" }}>
								<input
									id="password-input"
									type="password"
									className="login-item"
									placeholder="Enter New Password"
									name="password"
									onChange={(e) => {
										handleChange(e);
									}}
								/>
								{InsideErrors.isError ? (
									<div className="error-info-tooltip-tool">
										<label htmlFor="" className="error-info-tooltip-head">
											Password must have:
										</label>
										{InsideErrors.error.map((d) => (
											<label htmlFor="" className="error-info-item">
												{" "}
												{d.status ? <PinkTick /> : <WhiteTick />} &nbsp;{" "}
												{d.message}
											</label>
										))}
									</div>
								) : null}
							</span>
							<input
								id="password2-input"
								type="password"
								className="login-item"
								placeholder="Re-enter New Password"
								name="password2"
								onChange={(e) => {
									handleChange(e);
								}}
							/>
						</div>

						<div className="login-footer">
							<button
								className="login-main-button"
								onClick={(e) => {
									handlerSubmit(e);
								}}
							>
								Save Password
							</button>

							<label className="login-main-label">
								Go back to{" "}
								<Link to="/login" className="link-custom">
									Log in
								</Link>
							</label>
						</div>
					</div>
				</div>
			);
		}
	};

	if (Redirectt) {
		return null;
		// <Redirect
		// 	to={{
		// 		pathname: "/login",
		// 		email: Details.email,
		// 		passwordChange: true,
		// 	}}
		// />
	} else return <div className="login-container">{al()}</div>;
}
const mapStateToProps = () => {
	var state = mystore.getState();
	return {
		isAuthenticated: state.auth.isAuthenticated,
		state: state.auth,
	};
};
export default connect(mapStateToProps)(Login);
