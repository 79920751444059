import React from "react";
import { useEffect } from "react";
import { gsap } from "gsap";
import { useRef } from "react";
import { connect } from "react-redux";

export default connect((state) => ({ tabName: state.tabName }))(
  function OpacityContainer({ children, tabName }) {
    const ref = useRef();
    useEffect(() => {
      gsap.fromTo(ref.current, { opacity: "0" }, { opacity: "1", duration: 1 });
    }, [tabName]);

    return (
      <div
        ref={ref}
        className="OpacityContainer"
        style={{ overflow: "hidden" }}
      >
        {children}
      </div>
    );
  }
);
