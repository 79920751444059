import { useEffect, useRef } from "react";
import { selectAll } from "../../../utils";
import "./_Atom.scss";

function LabelInput({
  ml,
  type = "number",
  min,
  max,
  onChange = () => { },
  onBlur = () => { },
  onFocus = () => {

  },
  width,
  value = "",
  label,
  ele,
  disable = false,
  onEnter = () => {

  },
  step = 1,
  haveInc = false,
  innerWidth,
  haveOnChange = true,
  extraFunction = () => {

  },
  inputClassName = "",
  onCleanup = () => { },
  labelAfter = null,
  labelStyle = {},
  wrapperStyle = {}
}) {
  useEffect(() => {
    return () => {
      if (value !== "") {
        // console.log("Lable Cleanu", haveInc, value);
        onCleanup(value)
      }


    }
  }, [value])

  useEffect(() => {
    return () => {
      extraFunction()
    }
  }, [])

  const ref = useRef();
  const customF = (what) => {
    return (e) => {
      document.isCanActive = what;
      if (what) {
        onBlur(e);
      } else {
        selectAll(e)
        // e.target.setSelectionRange(0, e.target.value.length)
        onFocus(e)

      }
    };
  };

  const handleKeyDown = (e) => {
    // console.log(e);
    if (e.key === "Enter") {
      e.preventDefault()
      console.log("ENTER");
      onEnter(e);

    }
  }
  const updater = (k) => {
    return (e) => {

      setTimeout(() => {

        extraFunction()
        onChange({ target: { value: Number(ref.current.value) + k } });
        onBlur({ target: { value: Number(ref.current.value) + k } });
        // onBlur
        ref.current.select();
        ref.current.blur();
      }, 0)


    }

  }
  return (

    <div className={`LabelInput ${haveInc ? "HaveInc" : ""}`}>
      {label && <label htmlFor="" className="Label" style={labelStyle}>
        {label}
      </label>}
      <div
        className="LabelInput_Input"
        style={{ marginLeft: ml || "15px", width: width || "60px" }}
      >
        {haveInc && <div onClick={updater(-step)} className="LabelInput_Input_button">-</div>}
        {ele || (
          <input
            ref={ref}
            type={type || "text"}
            style={{ width: innerWidth || "100%", textAlign: haveInc ? "center" : "left" }}
            min={min}
            max={max}
            disabled={disable}
            value={value}
            onBlur={customF(true)}
            onFocus={customF(false)}
            onChange={onChange}
            step={step}
            onKeyDown={handleKeyDown}
            className={inputClassName}
          />
        )}
        {haveInc && <div onClick={updater(step)} className="LabelInput_Input_button">+</div>}

      </div>
      <div className="labelAfter">
        {labelAfter}</div>

    </div>



  );
}

export default LabelInput;
