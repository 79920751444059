const avatar = `${document.PUBLIC_URL}/tabs/avatar.svg`;
const aavatar = `${document.PUBLIC_URL}/tabs/aavatar.svg`;
const templates = `${document.PUBLIC_URL}/tabs/templates.svg`;
const atemplates = `${document.PUBLIC_URL}/tabs/atemplates.svg`;
const background = `${document.PUBLIC_URL}/tabs/background.svg`;
const abackground = `${document.PUBLIC_URL}/tabs/abackground.svg`;
const text = `${document.PUBLIC_URL}/tabs/text.svg`;
const atext = `${document.PUBLIC_URL}/tabs/atext.svg`;
const image = `${document.PUBLIC_URL}/tabs/image.svg`;
const aimage = `${document.PUBLIC_URL}/tabs/aimage.svg`;
const video = `${document.PUBLIC_URL}/tabs/video.svg`;
const avideo = `${document.PUBLIC_URL}/tabs/avideo.svg`;
const music = `${document.PUBLIC_URL}/tabs/music.svg`;
const amusic = `${document.PUBLIC_URL}/tabs/amusic.svg`;
const aelements = `${document.PUBLIC_URL}/tabs/aelements.svg`;
const settings = `${document.PUBLIC_URL}/tabs/settings.svg`;
const asettings = `${document.PUBLIC_URL}/tabs/asettings.svg`;
const elements = `${document.PUBLIC_URL}/tabs/elements.svg`;

let I = {
  aavatar,
  templates,
  atemplates,
  background,
  abackground,
  text,
  atext,
  image,
  aimage,
  video,
  avideo,
  music,
  amusic,
  aelements,
  settings,
  asettings,
  elements,
  avatar,
};

export default I;
