import React, { useEffect, useState } from "react";
import HoverImageCard from "./HoverImageCard";
import HoverVideoCard from "./HoverVideoCard";
import InfiniteWrapper from "./Infinite";
import uuid from "uuid-random";
import InputIcon from "./InputIcon";
import { url, fetcher, functionListen } from "../../../utils";
import store from "../../../store/store";
import { connect } from "react-redux";
import { fancyTimeFormat } from "./MusicCard";
import { DeletePopup } from "../../TimeLine/Components/SceneBar/DeletePopup";
import { VideoRecordingPopup } from "./popup/VideoRecording";
// import { result } from "lodash";

function saveFile(recordedChunks) {

  const blob = new Blob(recordedChunks, {
    type: 'video/webm'
  });
  let filename = window.prompt('Enter file name'),
    downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `${filename}.webm`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  URL.revokeObjectURL(blob); // clear from memory
  document.body.removeChild(downloadLink);
}


function createRecorder(stream, mimeType) {
  // the stream data is stored in this array
  let recordedChunks = [];

  const mediaRecorder = new MediaRecorder(stream);

  mediaRecorder.ondataavailable = function (e) {
    if (e.data.size > 0) {
      recordedChunks.push(e.data);
    }
  };
  mediaRecorder.onstop = function () {
    saveFile(recordedChunks);
    recordedChunks = [];
  };
  mediaRecorder.start(200); // For every 200ms the stream data will be stored in a separate chunk.
  return mediaRecorder;
}

export default connect((state) => ({ searchList: state.media.search.list }))(
  function VideoWrapper({
    searchList,
    list,
    type,
    onSelect = () => { },
    UploadOption,
    height = "83vh",
    onPreview
  }) {
    const [isRecordingPopup, setRecordingPopup] = useState(false);
    const [isSearched, setSearch] = useState(false);

    useEffect(() => {
      functionListen("#videoSearch", handleChange);
    }, []);

    const handleChange = (e) => {
      let searchQ = {
        video: `bgclip/search`,
        video_upload: "userlibrary/upload",
      };
      let ele = document.querySelector("#videoSearch");
      let d = ele.value;
      if (d.length >= 1) {
        fetcher({
          method: "GET",
          url: `${url}/api/${searchQ[type]}/?type=video&query=${d}`,
          res: (res) => {
            store.dispatch({
              type: "MEDIA_WITH_NEW",
              data: {
                next: res.data.next,
                list: res.data.results,
                type: "search",
              },
            });
            setSearch(true);
          },
          err: (err) => {
            setSearch(false);
          },
        });
      } else {
        setSearch(false);
      }
    };

    const videoRecordingOnSelect = async () => {
      let stream = await navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: { mediaSource: "screen", frameRate: { ideal: 30, max: 30 } }
      });

      //let mediaRecorder = createRecorder(stream, mimeType);

      document._videoRecording = { stream, mediaRecorder: null, chunks: [] }
      setRecordingPopup(true)
    }

    return (
      <div>
        <div>
          <InputIcon
            // onBlur={handleChange}
            src={`${document.PUBLIC_URL}/icons/search.svg`}
            placeholder="Search Video"
            id="videoSearch"
          />
          {/* <button ></button> */}
        </div>

        <div
          id="scrollableDiv12"
          className="scrollableDiv"
          style={{
            height: height,
            overflowY: "scroll",
            overflowX: "hidden",

            display: "flex",

            // flexDirection: "column-reverse",
          }}
        >

          <VideoRecordingPopup
            isConfirm={isRecordingPopup}
            setConfirm={setRecordingPopup}
            onDelete={async () => {
            }}
          />

          <InfiniteWrapper
            type={isSearched ? "search" : type}
            target="scrollableDiv12"
            // stop={isSearched}
            isSearched={isSearched}
          >
            {(!isSearched && type === 'video_upload') && <HoverImageCard
              key={`screen_recording_k`}
              src={`${document.PUBLIC_URL}/app/screen_recording.jpeg`}
              onSelect={videoRecordingOnSelect}
              id={"video_recording"}
              onPreview={() => { }}
              type={type}
              UpFunction={null}
              upElement={<></>}
              downElement={<></>}
              title={'Screen Recording'}
            />}
            {(isSearched ? searchList : list).map((e, i) => (
              // <HoverImageCard key={uuid()} src={e.media_thumbnail} />
              <HoverImageCard
                key={`${i}${e.id}`}
                src={e.media_thumbnail}
                vidSrc={e.media_file}
                onSelect={onSelect(e)}
                id={e.id}
                downElement={<div className="HoverVideoCard_frame_down">{sec2time(e.fileInfo?.duration || 0)}</div>}
                UpFunction={UploadOption}
                type={type}
                title={e.name}
                ctype="video"
                onPreview={onPreview}
                data={e}
              />
            ))}
          </InfiniteWrapper>
        </div>
      </div>
    );
  }
);
export function sec2time(timeInSeconds) {
  var pad = function (num, size) {
    return ("000" + num).slice(size * -1);
  },
    time = parseFloat(timeInSeconds).toFixed(2),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-2);

  return pad(minutes, 2) + ":" + pad(seconds, 2) + ":" + pad(milliseconds, 2);
}
