import React from 'react'
import Popup from 'reactjs-popup'
export default function Tooltip({ children, text }) {

    return <Popup
        trigger={children}
        position="top center"
        className='Tooltip'
        on={['hover', 'focus']}

    >
        <span className='Tooltip_text'>{text}</span>
    </Popup>


}
