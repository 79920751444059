import React from "react";
import store from "../../../store/store";
import HoverVideoCard from "../Atom/HoverVideoCard";
import ThreeCard from "../Atom/ThreeCard";
import "./_One.scss";

export default function ThreeVideo({
  onClick = () => {},
  asrc,
  src,
  title,
  isActive,
  sample,
  colors,
  index,
  poster,
}) {
  return (
    <ThreeCard
      className="VideoCard_parent"
      isActive={isActive}
      title={title}
      onClick={onClick}
      colors={store.getState().scenes[index].sanimation?.colors}
      ele={<VideoCard src={sample} poster={src} />}
      sindex={index}
    />
  );
}

const VideoCard = ({ src, poster }) => {
  const handleHover = (e) => {
    e.target.play();
  };
  const handleOut = (e) => {
    e.target.pause();

    e.target.currentTime = 0;
    e.target.load();
  };
  return (
    <div className="videoCard">
      <video
        poster={poster}
        src={src}
        onMouseEnter={handleHover}
        onMouseOut={handleOut}
        loop
      ></video>
    </div>
  );
};
