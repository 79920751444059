import React from "react";
import { Switch, Route, useHistory } from "react-router";
import Popup from "reactjs-popup";

import LoginForm from "../accounts/Login";
import SignupForm from "../accounts/SignUp";
import ForgotPassForm from "../accounts/ForgetPassword";
import { connect } from "react-redux";
import ResetPassword from "../accounts/resetPassword";
import SignupVerify from "../accounts/signUpVerify";
import { isMobile } from "mobile-device-detect";
import { useEffect } from "react";
import { useState } from "react";
import mystore from "../App/appStore";
import { fetcher, url } from "../utils";

function Model({ isAuthenticated }) {
  let hist = useHistory();
  let [open, setOpen] = useState(true);

  // React.useEffect(() => {

  //   fetcher({
  //     method: "GET",
  //     url: `${url}/api/accounts/user/`,
  //     res: (res) =>
  //       mystore.dispatch({
  //         type: "USER_DATA",
  //         data: { ...res.data, isAuthenticated: true },
  //       }),
  //     err: (err) => {
  //       console.log(err);
  //       hist.push("/login")
  //       mystore.dispatch({
  //         type: "USER_DATA",
  //         data: { isAuthenticated: false },
  //       })

  //     },
  //   });

  // }, []);

  let Nav = [
    {
      path: "/login",
      main: (dd) => {
        console.log(dd);
        return (
          <div>
            <LoginForm {...dd} />
          </div>
        )
      },

    },
    {
      path: "/forgotpassword",


      main: (dd) => (
        <div>
          <ForgotPassForm {...dd} />
        </div>
      ),
    },
    {
      path: "/reset-password/:id",

      main: (dd) => (
        <div>
          <ResetPassword {...dd} />
        </div>
      ),
    },
    {
      path: "/signup-verify/:id",
      main: (dd) => (
        <div>
          <SignupVerify {...dd} />
        </div>
      ),
    },
    {
      path: "/register",
      main: () => (
        <div>
          <SignupForm />
        </div>
      ),
    },
  ];


  useEffect(() => {

    setOpen(true)
    return () => {
      setOpen(false)

    }
  }, [])
  return (
    <Switch>
      <Popup className="pointer-none" modal open={open} closeOnEscape={false} closeOnDocumentClick={false} onClose={() => { hist.goBack(); setOpen(true) }}>
        <div className="flex LOOGIN ">
          {!isMobile &&

            <img className="LOOGIN_img" src={`${document.PUBLIC_URL}/app/loginimg.svg`} alt="logs" />

          }
          {Nav.map((route, index) => (
            // Render more <Route>s with the same paths as
            // above, but different components this time.

            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => route.main(props)}
            />
          ))}


        </div>

      </Popup>
    </Switch>
  );
}



const mapStateToProps = () => {

  return ({
    isAuthenticated: false
  })
};

export default connect(mapStateToProps)(Model);
