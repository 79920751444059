import gsap from "gsap";
import { fabric } from "fabric";
import { UpdateBackground } from "../../components/Chemistry/One/Draggy";
import store from "../../store/store";
import { arrFromStore, getById, getNumber, getTimelineCenterPos, Index, Jump, MoveSeekTo, updateTimeLen, Vid } from "../../utils";
import { SIZES } from "../config";
import { intialSceneSetup, parseSceneData, restoreCanvas, saveAllCanvas } from "../shortcutFunction";
import { addEventsInAudioHelper, customSeekObj } from "../videoPlayThings";
import { checkBufferVideoEventHelper } from "../videoPlayThings/checkBufferVideoEvent";
import { setAudioAvater } from "../videoPlayThings/updateVideosTimeHelper";
import { getObjectCoords } from "../cropyThings";


//gsap.ticker.add(cx);
class Player {
    FPS = 25;

    _store = {};
    scenes = {};

    // scene index
    index = 0;
    // current index scene
    arrIndex = 0;
    // start index for load data
    _startArrIndex = 0;
    // scene index array
    arr = [];

    //scene duration dict
    sceneDuration = {};

    time = 0;

    //status {0: not init,1: play,2: paused}
    status = 0;

    //scene wise video and music
    musicObjects = {};
    videoObjects = {};
    avatarObject = {};

    canvasScale = null;
    renderCanvas = null;
    sceneData = {};


    constructor() {
        gsap.ticker.fps(this.FPS);

        this.setupData = this.setupData.bind(this);
        this.init = this.init.bind(this);
        this.nextIndex = this.nextIndex.bind(this);
        this.prevIndex = this.prevIndex.bind(this);
        this.play = this.play.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.seek = this.seek.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onChangeScene = this.onChangeScene.bind(this);
        this.createSceneTimeLine = this.createSceneTimeLine.bind(this);
        this.addMediaElements = this.addMediaElements.bind(this);
        this.mediaElementPlay = this.mediaElementPlay.bind(this);
        this.restoreVideoElementToObj = this.restoreVideoElementToObj.bind(this);
        this.convertObjToVideoElement = this.convertObjToVideoElement.bind(this);
        this.commonTaskAtSceneStart = this.commonTaskAtSceneStart.bind(this);
        this.addSceneAnimation = this.addSceneAnimation.bind(this);
        this.onSceneAnimationActive = this.onSceneAnimationActive.bind(this);
        this.seekSceneAnimation = this.seekSceneAnimation.bind(this);
        this.handleSceneLayer = this.handleSceneLayer.bind(this);
        this.createSceneAnimationTimeline = this.createSceneAnimationTimeline.bind(this);
        this.beforePlayCommonTaskForAllScene = this.beforePlayCommonTaskForAllScene.bind(this);
        this.showVideoElement = this.showVideoElement.bind(this);
        this.hideVideoElement = this.hideVideoElement.bind(this);
        this.handleSceneLayerWithSceneAnimationActive = this.handleSceneLayerWithSceneAnimationActive.bind(this);
    }

    nextIndex() {
        return this.arr[this.arrIndex + 1];
    }

    prevIndex() {
        return this.arr[this.arrIndex - 1];
    }

    customSeek(time, index) {
        document.cxx[index]._objects.forEach((obj) => {
            const elmt = this.scenes[index].elements[obj.id];
            customSeekObj(time, obj, elmt)
        })
    }

    setupData() {
        // get data from store
        this._store = store.getState();
        this.scenes = this._store.scenes;
        document.scenes = this.scenes;

        // set scene array
        this.arr = arrFromStore(this._store);

        // set index
        this.index = Index();
        this.arrIndex = this.arr.indexOf(this.index);

        this._startArrIndex = 0;
        if (this.arrIndex) {
            this._startArrIndex = this.arrIndex - 1;
        }

        // set video id
        this.videoId = Vid();
    }

    addMediaElements(index) {
        // handle duration
        const _videoObjs = [];
        const _musicObjs = [];

        document.cxx[index]._objects.forEach((obj) => {
            if (obj._Type === 'video') {
                let _crntElement = obj._originalElement;
                if (!_crntElement?.duration) {
                    _crntElement = null;
                }
                const { enterDelay: _enterDelay, stayTime: _stayTime } = this.scenes[index].elements[obj.id] || { enterDelay: 0, stayTime: 0 };
                if (this.sceneDuration[index] < _enterDelay + _stayTime) {
                    _videoObjs.push([obj, _crntElement, { st: _enterDelay, et: this.sceneDuration[index] }, false]);
                } else {
                    _videoObjs.push([obj, _crntElement, { st: _enterDelay, et: _enterDelay + _stayTime }, false]);
                }
            } else if (obj._Type === 'music') {
                let _crntElement = document.querySelector(`#${obj.id}`);
                if (!_crntElement?.duration) {
                    _crntElement = null;
                }
                const { adjustLength: _adjustLength } = this.scenes[index].elements[obj.id] || { adjustLength: 4 };
                _musicObjs.push([obj, _crntElement, { st: 0, ad: _adjustLength }, false])
            } else if (obj._Type === 'avatar') {
                let _crntElement = document.querySelector(`#${obj.id}`);
                if (!_crntElement?.duration) {
                    _crntElement = null;
                } else if (this.scenes[index].mode === 2) {
                    _crntElement = null;
                }
                const { enterDelay: _enterDelay, stayTime: _stayTime } = this.scenes[index].elements[obj.id] || { enterDelay: 0, stayTime: 0 };
                if (this.sceneDuration[index] < _enterDelay + _stayTime) {
                    this.avatarObject[index] = [obj, _crntElement, { st: _enterDelay, et: this.sceneDuration[index] }, false];
                } else {
                    this.avatarObject[index] = [obj, _crntElement, { st: _enterDelay, et: _enterDelay + _stayTime }, false];
                }
            }
        })
        this.videoObjects[index] = _videoObjs;
        this.musicObjects[index] = _musicObjs;

        // add video elements
        document.cxx[index].wrapperEl.style.overflow = "hidden";
        this.videoObjects[index].forEach((obj, _indx) => {
            document.initVideoWrapper(obj[0], -1000);
        })
    }

    hideVideoElement(index) {
        this.videoObjects[index].forEach((obj) => {
            if (obj[1]) {
                obj[1].style.display = 'none';
            }
        })
    }

    showVideoElement(index, _zIndex) {
        this.videoObjects[index].forEach((obj, _indx) => {
            if (obj[1]) {
                if (obj[0].visible) {
                    obj[1].style.display = 'block';
                }
                if (_zIndex !== undefined && obj[0]._videoWrapper) {
                    obj[0]._videoWrapper.style.zIndex = `${_zIndex + _indx}`;
                }
            }
        })
    }

    convertObjToVideoElement(index) {
        // hide all canvas
        //document.hideCanvasWrapperVideo(index);
        const _zIndex = getNumber(this.sceneData[this.index]._screenDiv.style.zIndex) - this.videoObjects[index].length;
        this.showVideoElement(index, _zIndex)
    }

    restoreVideoElementToObj(index) {
        const cxx = document.cxx[index];
        this.videoObjects[index].forEach((_obj) => {
            let obj = _obj[0];
            if (obj?._videoWrapper && cxx) {
                const _media = document.querySelector('.Media');
                _media.appendChild(obj._originalElement);
                cxx.wrapperEl.removeChild(obj._videoWrapper);
                obj._videoWrapper = null;
            }
            obj.globalCompositeOperation = 'source-over'
            obj._element = obj?._originalElement;
            obj.backgroundColor = "";
            obj.videoTime = Math.random();
        })
    }

    restoreCanvasTransform() {
        Object.keys(this.sceneData).forEach((index) => {
            document.cxx[index].wrapperEl.style.transform = '';
            document.cxx[index].wrapperEl.style.opacity = '';
            this.restoreVideoElementToObj(index)
        })
    }

    getFirstFrameCanvasElement(index) {
        const _canvas = document.cxx[index].getElement();

        let _newCanvas = document.createElement("canvas");
        let _newCtx = _newCanvas.getContext("2d");
        _newCanvas.height = _canvas.height;
        _newCanvas.width = _canvas.width;
        _newCtx.drawImage(_canvas, 0, 0);
        return _newCanvas;
    }

    addSceneAnimation(index, _arrIndex) {
        // add canvas to fabObj
        this.sceneData[index] = { isActive: false, _screenDiv: document.getElementById(`screen${index}`) };


        this.sceneData[index].canvas = document.cxx[index].getElement();//this.getFirstFrameCanvasElement(index);//
        this.sceneData[index].fabObj = new fabric.Image(this.sceneData[index].canvas, {
            left: 0,
            top: 0,
            scaleX: this.canvasScale,
            scaleY: this.canvasScale,
            visible: false,
            selectable: false,
            evented: false,
            id: `s_${index}`
        });
        this.renderCanvas.add(this.sceneData[index].fabObj);
        this.sceneData[index].fabObj._element = null;
        this.sceneData[index].fabObj.backgroundColor = "#ffffff";
        this.sceneData[index].fabObj.globalCompositeOperation = "destination-out";

        document.cxx[index].wrapperEl.style.transformOrigin = `left top`;
        this.sceneData[index].fabObj._videoWrapper = document.cxx[index].wrapperEl;


        // add scene animation data
        const _sanimation = this.scenes[index].sanimation;
        if (_sanimation?.animationData && _sanimation.name !== 'None' && this.arr[_arrIndex + 1] !== undefined) {
            this.sceneData[index].sADuration = (_sanimation?.animationData?.start?.to?.duration || 0);
            this.sceneData[index].sAHDuration = this.sceneData[index].sADuration / 2;

            if (this.sceneData[index].sADuration) {
                this.sceneData[index].sStartTime = this.sceneDuration[index] - this.sceneData[index].sAHDuration;
                this.sceneData[index].isSceneAnimation = true;
                this.sceneData[index].stimeline = gsap.timeline({
                    paused: true,
                })

            }
        }
    }

    createSceneAnimationTimeline() {

        for (let _indx = this._startArrIndex; _indx < this.arr.length; _indx++) {
            const _index = this.arr[_indx];
            const _nextIndex = this.arr[_indx + 1];

            const _sanimation = this.scenes[_index].sanimation;
            if (this.sceneData[_index].isSceneAnimation) {
                // create scene animation timeline
                let parseAnimation = {
                    start: parseSceneData(
                        this.sceneData[_index].fabObj,
                        _sanimation.animationData.start
                    ),
                    end: parseSceneData(
                        this.sceneData[_nextIndex].fabObj,
                        _sanimation.animationData.end
                    ),
                };

                // scene animation intial setup
                if (_sanimation.animationData.init) {
                    intialSceneSetup(_sanimation.animationData.init, {
                        canvas: this.renderCanvas,
                    });
                }
                this.sceneData[_index].stimeline.fromTo(
                    this.sceneData[_index].fabObj,
                    parseAnimation.start.from,
                    parseAnimation.start.to,
                    0
                );
                this.sceneData[_index].stimeline.fromTo(
                    this.sceneData[_nextIndex].fabObj,
                    parseAnimation.end.from,
                    parseAnimation.end.to,
                    0
                );

            }

        }
    }

    async updateSingleVideoOrAvatarTime(index, obj, time) {
        if (obj && obj[1] && this.scenes[index].elements[obj[0].id]) {
            const { trimStart, trimEnd, adjustLength, stayTime, enterDelay } = this.scenes[index].elements[obj[0].id];
            let vidEle = obj[1];
            if (vidEle) {
                if (adjustLength === 0) {
                    let toBeSeek = trimStart + time - enterDelay;
                    if (trimStart <= toBeSeek && toBeSeek <= trimEnd) {
                        await vidEle.customVideoSeek(toBeSeek);
                        obj.videoTime = toBeSeek;
                    } else if (trimStart > toBeSeek) {
                        await vidEle.customVideoSeek(trimStart);
                        obj.videoTime = trimStart;
                    } else if (toBeSeek > trimEnd) {
                        await vidEle.customVideoSeek(trimEnd);
                        obj.videoTime = trimEnd;
                    }

                } else if (adjustLength === 1) {
                    let startTime = enterDelay;
                    let endTime = startTime + stayTime;
                    let trimDiff = trimEnd - trimStart;
                    if (startTime <= time && endTime >= time) {
                        let toBeSeek = trimStart + ((time - startTime) % trimDiff);
                        await vidEle.customVideoSeek(toBeSeek);
                        obj.videoTime = toBeSeek;
                    } else if (startTime > time) {
                        await vidEle.customVideoSeek(trimStart);
                        obj.videoTime = trimStart;
                    } else if (time > endTime) {
                        await vidEle.customVideoSeek(trimEnd);
                        obj.videoTime = trimEnd;
                    }
                } else if (adjustLength === 2) {
                    let toBeSeek = trimStart + time - enterDelay;
                    if (trimStart <= toBeSeek && toBeSeek <= trimEnd) {
                        await vidEle.customVideoSeek(toBeSeek);
                        obj.videoTime = toBeSeek;
                    } else if (trimStart > toBeSeek) {
                        await vidEle.customVideoSeek(trimStart);
                        obj.videoTime = trimStart;
                    } else if (toBeSeek > trimEnd) {
                        await vidEle.customVideoSeek(trimEnd);
                        obj.videoTime = trimEnd;
                    }
                } else if (adjustLength === 3) {
                    const toBeSeek = time * vidEle.playbackRate;
                    await vidEle.customVideoSeek(toBeSeek);
                    obj.videoTime = toBeSeek;
                }
            }
        }
    }

    async updateSingleMusicTime(index, obj, time) {
        if (obj && obj[1] && this.scenes[index].elements[obj[0].id]) {
            const { trimStart, trimEnd, adjustLength, stayTime } = this.scenes[index].elements[obj[0].id];
            let vidEle = obj[1];

            if (vidEle) {
                if (adjustLength === 1) {
                    // adjust as scene
                    let startTime = 0;
                    let trimDiff = trimEnd - trimStart;
                    if (startTime <= time && stayTime >= time) {
                        let toBeSeek = time % trimDiff;
                        await vidEle.customVideoSeek(trimStart + toBeSeek);
                    }
                } else {
                    // loop in all scene
                    const _arrIndex = this.arr.indexOf(index);
                    let startTime = 0;
                    for (let _ii = 0; _ii < _arrIndex; _ii++) {
                        startTime += this.sceneDuration[this.arr[_ii]];
                    }
                    const _totalDurations = startTime + time;
                    let endTime = startTime + stayTime;
                    let trimDiff = trimEnd - trimStart;
                    if (startTime <= _totalDurations && endTime >= _totalDurations) {
                        let toBeSeek = _totalDurations % trimDiff;
                        await vidEle.customVideoSeek(trimStart + toBeSeek);
                    }
                }
            }
        }
    }

    async updateSceneMediaTime(index, time, updateMusic = false) {
        let _allP = [];
        this.videoObjects[index].forEach((_obj) => {
            _allP.push(this.updateSingleVideoOrAvatarTime(index, _obj, time));
        })

        if (updateMusic) {
            this.musicObjects[index].forEach((_obj) => {
                _allP.push(this.updateSingleMusicTime(index, _obj, time));
            })

        }

        _allP.push(this.updateSingleVideoOrAvatarTime(index, this.avatarObject[index], time));

        (await Promise.all(_allP));
    }


    async beforePlayCommonTaskForAllScene() {
        let _canvas = document.cxx[this.index];
        this.canvasScale = SIZES[document?._size || 0].height / (_canvas.height / _canvas.getZoom());

        if (document.screenChheda) {

            // draw first small scene
            await document.smallScene.draw(this.index, true);

            const _mediaSeekPromise = [];
            for (let _indx = 0; _indx < this.arr.length; _indx++) {
                const _crntIndex = this.arr[_indx];

                // save small scene
                // const _updateSmallS = new Promise((res) => {
                //     document.smallScene.draw(_crntIndex, true);
                //     res();
                // });
                // _allSamllSceneUpdateP.push(_updateSmallS)

                // create timeline 
                this.createSceneTimeLine(_crntIndex);
                // store scene duration
                this.sceneDuration[_crntIndex] = document.txx.timeLine[_crntIndex].duration();

                // discard active object
                document.cxx[_crntIndex].discardActiveObject();

                // parse video music and avatar 
                this.addMediaElements(_crntIndex);


                // for updating media element
                _mediaSeekPromise.push(this.updateSceneMediaTime(_crntIndex, 0, this.index === _crntIndex));


            }


            // wait until video seek
            (await Promise.all(_mediaSeekPromise));
            for (let _indx = 0; _indx < this.arr.length; _indx++) {
                const _crntIndex = this.arr[_indx];
                //add scene animation
                this.customSeek(0, _crntIndex);
                document.txx.timeLine[_crntIndex].seek(0.01);
                document.cxx[_crntIndex].renderAll();
                this.addSceneAnimation(_crntIndex, _indx);
            }
            // create scene animation timeline
            this.createSceneAnimationTimeline();


            //(await Promise.all(_allSamllSceneUpdateP));

            // show video element
            const _zIndex = getNumber(this.sceneData[this.index]._screenDiv.style.zIndex) - this.videoObjects[this.index].length;
            this.showVideoElement(this.index, _zIndex)




            document.screenChheda = false;

            document.hist.push(Jump({ obj: {}, def: true }))
            // discard active object
            store.dispatch({
                type: "SET_ACTIVE_OBJECT",
                data: "",
            });

            store.dispatch({
                type: "ALL_LOAD",
                data: {
                    tab: true,
                    left: true,
                    right: true,
                },
            });
        }


    }

    commonTaskAtSceneStart() {
        //this.convertObjToVideoElement(this.index);
    }

    createSceneTimeLine(index) {

        if (document.txx.timeLine[index]) {
            document.txx.timeLine[index].clear();
        }

        document.cxx[index].getObjects().forEach((obj) => {
            if (!(this.scenes[index].mode === 2 && obj._Type === 'avatar')) {
                const { enter, exit, enterDelay, stayTime, animation } = this.scenes[index].elements[obj.id];
                document.txx.add({
                    obj,
                    enterDuration: enter,
                    exitDuration: exit,
                    enterDelay: enterDelay,
                    stayTime: stayTime,
                    where: index,
                    animationEnter: animation?.enter?.animationData?.start,
                    animationExit: animation?.exit?.animationData?.end || {},
                    animationPlace: animation?.place?.animationData?.start || {},
                });
            }

        })
    }


    async init() {
        document.Save.save = false;
        this.time = 0;
        // render canvas clear for scene animation
        this.renderCanvas = document.ccx;
        this.renderCanvas.clear();

        // get sppech data and modify timeline
        console.log('Play hitSpeechAPI')
        await document.hitSpeechAPI({});
        console.log('Play hitSpeechAPI Completed')

        document.setPlay("load");

        console.log('Play Before setupData')
        this.setupData();
        console.log('Play setupData Completed')


        // save all canvas object data 
        saveAllCanvas();
        console.log('Play before beforePlayCommonTaskForAllScene')

        await this.beforePlayCommonTaskForAllScene();
        console.log('Play beforePlayCommonTaskForAllScene Completed')

        document.cccx.current.style.display = 'block';
        this.renderCanvas.backgroundColor = "#00000000"

    }

    mediaElementPlay(play = true) {
        if (this.status) {
            this.videoObjects[this.index].forEach((_obj) => {
                document.updateVideoElementCoords(_obj[0]);
                if (play) {
                    checkBufferVideoEventHelper(this.time, _obj[1], this.scenes[this.index])
                    if (!_obj[3] && _obj[1]) {
                        if (this.time >= _obj[2].st) {
                            _obj[1].play();
                            _obj[3] = true;
                        }
                    }
                }
            })
            if (play) {
                this.musicObjects[this.index].forEach((_obj, _index) => {
                    addEventsInAudioHelper(this.time, _obj[1], this.scenes[this.index]);
                    if (!_obj[3] && _obj[1]) {
                        if (this.time >= _obj[2].st) {
                            _obj[1].play();
                            _obj[3] = true;
                        }
                    }
                })
                if (this.avatarObject[this.index] && !this.avatarObject[this.index][3] && this.avatarObject[this.index][1]) {
                    addEventsInAudioHelper(this.time, this.avatarObject[this.index][1], this.scenes[this.index]);
                    if (this.time >= this.avatarObject[this.index][2].st) {
                        this.avatarObject[this.index][1].play();
                        this.avatarObject[this.index][3] = true;
                    }
                }
            }
        }
    }

    mediaElementPause(userPause) {
        this.videoObjects[this.index].forEach((_obj) => {
            if (_obj[3] && _obj[1]) {
                _obj[1].pause();
                _obj[3] = false;
            }
        })

        this.musicObjects[this.index].forEach((_obj) => {
            if (_obj[3] && _obj[1]) {
                if (!(!userPause && _obj[2].ad === 4)) {
                    _obj[1].pause();
                    _obj[3] = false;
                }
            }
        })
        if (this.avatarObject[this.index] && this.avatarObject[this.index][3] && this.avatarObject[this.index][1]) {
            this.avatarObject[this.index][1].pause();
            this.avatarObject[this.index][3] = false;
        }

    }

    sceneOnTop(index) {
        this.arr.forEach((_ind) => {
            if (index === _ind) {
                this.sceneData[_ind]._screenDiv.style.zIndex = 0;
                this.sceneData[_ind]._screenDiv.style.opacity = 1;
            } else {
                this.sceneData[_ind]._screenDiv.style.zIndex = -100;
                this.sceneData[_ind]._screenDiv.style.opacity = 0;
            }
        })
    }

    updateSceneCoords(obj) {
        const _marginDist = 0.5;
        if (obj?.canvas && obj?._videoWrapper) {
            //const _cropX = getNumber(obj.cropX), _cropY = getNumber(obj.cropY);
            const _coords = getObjectCoords(obj);
            const _zM = obj.canvas.getZoom();
            const _ff = obj.calcTransformMatrix().map((e) => e);
            // increase video area
            const _increaseScle = (2 * _marginDist) / obj.width;
            const _newMarginY = obj.height * (_increaseScle / 2);
            obj._videoWrapper.style.transform = `matrix(${_ff[0] + _increaseScle},${_ff[1]},${_ff[2]},${_ff[3] + _increaseScle},${(_coords.left * _zM) - _marginDist},${(_coords.top * _zM) - _newMarginY})`;
            obj._videoWrapper.style.opacity = obj.opacity;
            // if crop applied
            // obj._videoWrapper.style.height = `${obj.height}px`;
            // obj._videoWrapper.style.width = `${obj.width}px`;
            // obj._videoWrapper.parentElement.style.transform = `translate(${-1 * _cropX}px, ${-1 * _cropY}px)`;
        }
    }

    onSceneAnimationActive(type) {
        if (type) {
            // handle in first
            const _prevIndex = this.arr[this.arrIndex - 1];
            if (_prevIndex !== undefined && !this.sceneData[_prevIndex].isActive) {
                this.sceneData[_prevIndex].isActive = true;
                //update scene zindex and video element 
                this.handleSceneLayerWithSceneAnimationActive(this.index, _prevIndex);

                const _nexObj = this.sceneData[this.index].fabObj, _prevObj = this.sceneData[_prevIndex].fabObj;
                this.sceneData[_prevIndex]._crntSceneObjActive = [_prevObj, _nexObj];

                this.sceneData[_prevIndex]._crntSceneObjActive.map((e) => e.set({ visible: true }));


                if (this.sceneData[_prevIndex].isReverseObj) {
                    this.sceneData[this.arr[this.sceneArrIndex]].fabObj.sendBackwards();
                }
            }
        } else {


            if (!this.sceneData[this.index].isActive) {
                this.sceneData[this.index].isActive = true;
                //update scene zindex and video element 
                this.handleSceneLayerWithSceneAnimationActive(this.arr[this.arrIndex + 1], this.index)

                const _prevObj = this.sceneData[this.index].fabObj, _nexObj = this.sceneData[this.arr[this.arrIndex + 1]].fabObj;
                this.sceneData[this.index]._crntSceneObjActive = [_prevObj, _nexObj]
                _prevObj.set({ visible: true });
                _nexObj.set({ visible: true });

                if (this.sceneData[this.index].isReverseObj) {
                    this.sceneData[this.arr[this.arrIndex + 1]].fabObj.sendBackwards();
                }
            }
        }
    }

    onSceneAnimationDeactive(type) {
        if (type) {
            //handle first
            const _prevIndex = this.arr[this.arrIndex - 1];
            if (_prevIndex !== undefined && this.sceneData[_prevIndex].isActive) {
                this.sceneData[_prevIndex].isActive = false;

                this.sceneData[_prevIndex]._crntSceneObjActive.map((e) => e.set({ visible: false }));
                this.handleSceneLayerWithSceneAnimationDeactive(_prevIndex, this.index);


                if (!this.sceneData[_prevIndex].stimeline.paused()) {
                    this.sceneData[_prevIndex].stimeline.pause();
                }
                if (this.sceneData[_prevIndex].isReverseObj) {
                    this.sceneData[_prevIndex].fabObj.sendBackwards();
                }

                this.sceneData[_prevIndex].stimeline.seek(this.sceneData[_prevIndex].sADuration);
                this.sceneData[_prevIndex]._crntSceneObjActive.map((e) => this.updateSceneCoords(e));
                this.renderCanvas.renderAll();
            }
        } else {
            // handle last
            if (this.sceneData[this.index].isActive) {
                this.sceneData[this.index].isActive = false;

                this.sceneData[this.index]._crntSceneObjActive.map((e) => e.set({ visible: false }));

                this.handleSceneLayerWithSceneAnimationDeactive(this.arr[this.arrIndex + 1], this.index)

                if (!this.sceneData[this.index].stimeline.paused()) {
                    this.sceneData[this.index].stimeline.pause();
                }
                if (this.sceneData[this.index].isReverseObj) {
                    this.sceneData[this.arr[this.arrIndex]].fabObj.sendBackwards();
                }
                this.sceneData[this.index].stimeline.seek(0);
                this.sceneData[this.index]._crntSceneObjActive.map((e) => this.updateSceneCoords(e));
                this.renderCanvas.renderAll();
            }
        }
    }

    handlePlaySanimationTimeline(time) {
        // handle in last
        if (this.sceneData[this.index].isSceneAnimation) {
            if (time >= this.sceneData[this.index].sStartTime) {
                if (!this.sceneData[this.index].isActive) {
                    this.onSceneAnimationActive(0);
                }
                if (this.sceneData[this.index].stimeline.paused()) {
                    const _sceneSeekTime = time - this.sceneData[this.index].sStartTime;
                    this.sceneData[this.index].stimeline.seek(_sceneSeekTime);
                    this.sceneData[this.index].stimeline.play();
                }

                this.sceneData[this.index]._crntSceneObjActive.map((e) => this.updateSceneCoords(e));
                this.renderCanvas.renderAll();

            } else {
                if (this.sceneData[this.index].isActive) {
                    this.onSceneAnimationDeactive(0);
                }
            }
        }

        // handle in first
        const _prevIndex = this.arr[this.arrIndex - 1];
        if (_prevIndex !== undefined && this.sceneData[_prevIndex]?.isSceneAnimation) {

            if (time <= this.sceneData[_prevIndex].sAHDuration) {
                if (!this.sceneData[_prevIndex].isActive) {
                    this.onSceneAnimationActive(1);
                }
                if (this.sceneData[_prevIndex].stimeline.paused()) {
                    const _sceneSeekTime = time + this.sceneData[_prevIndex].sAHDuration;
                    this.sceneData[_prevIndex].stimeline.seek(_sceneSeekTime);
                    this.sceneData[_prevIndex].stimeline.play();
                }

                this.sceneData[_prevIndex]._crntSceneObjActive.map((e) => this.updateSceneCoords(e));

                this.renderCanvas.renderAll()
            } else {
                if (this.sceneData[_prevIndex].isActive) {
                    this.onSceneAnimationDeactive(1);
                }
            }
        }
    }

    pauseSanimationTimeline() {
        // pause  last
        if (this.sceneData[this.index]?.isSceneAnimation) {
            if (!this.sceneData[this.index].stimeline.paused()) {
                this.sceneData[this.index].stimeline.pause();
            }
            // pause overlay video
        }

        // pause first
        const _prevIndex = this.arr[this.arrIndex - 1];
        if (this.sceneData[_prevIndex]?.isSceneAnimation) {
            if (!this.sceneData[_prevIndex].stimeline.paused()) {
                this.sceneData[_prevIndex].stimeline.pause();
            }
            // pause overlay video
        }
    }


    seekSceneAnimation(time) {
        // handle in last
        if (this.sceneData[this.index]?.isSceneAnimation) {
            if (time >= this.sceneData[this.index].sStartTime) {
                const _sceneSeekTime = time - this.sceneData[this.index].sStartTime;
                if (!this.sceneData[this.index].isActive) {
                    this.onSceneAnimationActive(0);
                }
                this.sceneData[this.index].stimeline.seek(_sceneSeekTime);
                this.sceneData[this.index]._crntSceneObjActive.map((e) => this.updateSceneCoords(e));
                this.renderCanvas.renderAll();
            } else {
                if (this.sceneData[this.index].isActive) {
                    this.onSceneAnimationDeactive(0);
                    this.renderCanvas.renderAll();
                }
            }
        }

        // handle in first
        const _prevIndex = this.arr[this.arrIndex - 1];
        if (
            _prevIndex !== undefined &&
            this.sceneData[_prevIndex].isSceneAnimation
        ) {
            if (time <= this.sceneData[_prevIndex].sAHDuration) {
                const _sceneSeekTime = time + this.sceneData[_prevIndex].sAHDuration;
                if (!this.sceneData[_prevIndex].isActive) {
                    this.onSceneAnimationActive(1);
                }

                this.sceneData[_prevIndex].stimeline.seek(_sceneSeekTime);
                this.sceneData[_prevIndex]._crntSceneObjActive.map((e) => this.updateSceneCoords(e));
                this.renderCanvas.renderAll();
            } else {
                if (this.sceneData[_prevIndex].isActive) {
                    this.onSceneAnimationDeactive(1);
                    this.renderCanvas.renderAll();
                }
            }
        }
    }


    async onUpdate() {
        this.time = document.txx.timeLine[this.index].time();
        if (this.time >= this.sceneDuration[this.index]) {
            // handle next scene or exit
            const _nextSceneIndex = this.nextIndex();
            if (_nextSceneIndex !== undefined) {
                // next scene exist
                await gsap.ticker.remove(this.onUpdate);
                this.onChangeScene(_nextSceneIndex);
            } else {
                this.exit();
            }
        }
        this.customSeek(this.time, this.index);
        // handle html media play
        this.mediaElementPlay();

        MoveSeekTo({ position: "seek", seek: this.time, index: this.index, updateCS: false })
        document.cxx[this.index].renderAll();

        // handle scene animation
        this.handlePlaySanimationTimeline(this.time);
        //this.seekSceneAnimation(this.time);


    }

    handleSceneLayerWithSceneAnimationActive(_nextIndex, _prevIndex) {
        if (this.sceneData[_prevIndex].isSceneAnimation) {
            this.sceneData[_nextIndex]._screenDiv.style.zIndex = 0;
            this.sceneData[_nextIndex]._screenDiv.style.opacity = 1;
            // show video element
            this.showVideoElement(_nextIndex, - this.videoObjects[_nextIndex].length)


            this.sceneData[_prevIndex]._screenDiv.style.zIndex = -100;
            this.sceneData[_prevIndex]._screenDiv.style.opacity = 1;
            this.showVideoElement(_prevIndex, -100 - this.videoObjects[_nextIndex].length)
        }
    }

    handleSceneLayerWithSceneAnimationDeactive(_nextIndex, _prevIndex) {
        if (this.sceneData[_prevIndex].isSceneAnimation) {
            this.sceneData[_nextIndex]._screenDiv.style.zIndex = -100;
            this.sceneData[_nextIndex]._screenDiv.style.opacity = 1;
            // show video element
            this.showVideoElement(_nextIndex, -100 - this.videoObjects[_nextIndex].length)


            this.sceneData[_prevIndex]._screenDiv.style.zIndex = 0;
            this.sceneData[_prevIndex]._screenDiv.style.opacity = 1;
            this.showVideoElement(_prevIndex, - this.videoObjects[_prevIndex].length)
        }
    }

    handleSceneLayer(prevArrIndex) {
        const _nextIndex = this.arr[prevArrIndex + 1], _prevIndex = this.arr[prevArrIndex];
        if (!this.sceneData[_prevIndex].isSceneAnimation) {
            this.restoreVideoElementToObj(this.index);

            this.sceneData[_nextIndex]._screenDiv.style.zIndex = 0;
            this.sceneData[_nextIndex]._screenDiv.style.opacity = 1;
            // show video element
            const _zIndex = getNumber(this.sceneData[_nextIndex]._screenDiv.style.zIndex) - this.videoObjects[_nextIndex].length;
            this.showVideoElement(_nextIndex, _zIndex)


            this.sceneData[_prevIndex]._screenDiv.style.zIndex = -100;
            this.sceneData[_prevIndex]._screenDiv.style.opacity = 0;
            // hide video element
            this.hideVideoElement(_prevIndex)
        } else {
            const _pPrevIndex = this.arr[prevArrIndex - 1];
            if (_pPrevIndex !== undefined) {
                this.restoreVideoElementToObj(_pPrevIndex);
            }
        }
    }

    async onChangeScene(nextIndex) {
        this.time = 0;
        this.onPause(false);
        setAudioAvater(nextIndex);

        //MoveSeekTo({ position: "center", index: this.index });

        // change layer without scene animation
        this.handleSceneLayer(this.arrIndex);

        this.index = nextIndex;
        this.arrIndex += 1;
        MoveSeekTo({ position: "left", index: this.index });

        document.txx.timeLine[this.index].seek(0.01);
        document.hist.push(`/${document.editorType}/${this.videoId}/${this.index}/${document._TabName}`);

        store.dispatch({ type: "SCENE_ACTIVE", data: this.index });
        updateTimeLen(null, null, this.index);
        UpdateBackground();

        this.commonTaskAtSceneStart();
        document.setS[this.index](Number(document._Max / document.per).toFixed(2));
        gsap.ticker.add(this.onUpdate);
        document.txx.timeLine[this.index].play();

    }

    async seek(time, updateVideo = false) {
        if (this.status === 1) {
            this.pause();
        } else if (this.status === 0) {
            await this.init();
        }
        // seek timeline
        this.time = time;
        document.txx.timeLine[this.index].seek(this.time);
        this.customSeek(this.time, this.index);


        if (updateVideo) {
            MoveSeekTo({ position: "seek", seek: this.time, updateVideo: false, updateCS: false });
            await this.updateSceneMediaTime(this.index, this.time);
        }
        document.cxx[this.index].renderAll();
        this.mediaElementPlay(false);

        // seek scenen animation
        this.seekSceneAnimation(this.time);


    }

    async play() {
        this.status = 1;
        //await this.updateSceneMediaTime(this.index,this.time )

        this.commonTaskAtSceneStart();
        document.setPlay("play");

        gsap.ticker.add(this.onUpdate);
        document.txx.timeLine[this.index]?.play();
    }


    onPause(userPause) {
        document.txx.timeLine[this.index]?.pause();
        this.mediaElementPause(userPause);
        this.pauseSanimationTimeline();

    }

    pause(userPause = true) {
        if (this.status === 1) {

            this.status = 2;
            document.setPlay('pause');

            this.onPause(userPause);
            gsap.ticker.remove(this.onUpdate);
            //document.cxx[this.index].renderAll();
        }
    }

    async exit() {
        this.status = 0;
        document.txx.timeLine[this.index]?.pause();
        await gsap.ticker.remove(this.onUpdate);

        document.setPlay('pause');
        this.mediaElementPause(true);
        this.restoreVideoElementToObj(this.index);
        this.restoreCanvasTransform();
        this.sceneOnTop(this.index);

        restoreCanvas();

        store.dispatch({
            type: "ALL_LOAD",
            data: {
                tab: false,
                left: false,
                right: false,
            },
        });

        document.cccx.current.style.display = 'none';

        document.render = false;
        document.screenChheda = true;
        document.Save.save = true;

        MoveSeekTo({ position: "center", index: this.index, updateVideo: true });

    }

    async onPlay() {
        if (this.status === 0) {
            await this.init();
            this.play();
        } else if (this.status === 2) {
            this.play();
        }
    }

}

document.Player = new Player();