import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";
import { Link } from "react-router-dom";
import Hr from "../../components/Chemistry/Atom/Hr";
import Vr from "../../components/Chemistry/Atom/Vr";
import { fetcher } from "../../utils";
import mystore from "../appStore";
import Button from "./Button";
import LinkPopup from "./PopUp/LinkPopup";
import { url } from "../../utils";
import axios from "axios";
import MailShare from "./PopUp/MailShare";
import DownloadPopup from "./PopUp/DownloadPopup";
// import store from "../ImagePersonalised/store";
import RenderWrapper from "./PopUp/RenderWrapper";
import TablePopup from "./PopUp/TablePopup";
import { DeletePopup } from "../../components/TimeLine/Components/SceneBar/DeletePopup";
import PurePopup from "./PurePopup";
import MapPopup from "./PopUp/MapPopup";
import DuplicatePopup from "./PopUp/DuplciatePopup";
import { connect } from "react-redux";
import "./_Custom.scss"

const EditableLabel = ({ value, onRename = () => { } }) => {
  const [isA, setA] = useState(true);
  const [_val, setVal] = useState(value);
  useEffect(() => {
    setVal(value);
  }, [value]);
  const handle = () => {
    setA((e) => !e);
  };
  return (
    <div className="EditableLabel">
      <input
        value={_val}
        onChange={(e) => setVal(e.target.value)}
        onBlur={onRename}
        disabled={isA}
        className="EditableLabel_input"
      />
      <img
        className="EditableLabel_img"
        alt="Here I am"
        src={`${document.PUBLIC_URL}/ico/pencil.svg`}
        onClick={handle}
      ></img>
    </div>
  );
};

function VideoDetail({ dataA }) {
  const { vid } = useParams();
  const rm = useRouteMatch();
  const hist = useHistory();
  // const { state } = useLocation();
  const [data, setData] = useState({
    name: "LOADING",
    thumbnail: "",
    report: {
      linkClicked: 0,
      videoPlayed: 0,
      views: 0,
    },
  });


  const handleBack = () => {
    // hist.goBack();
    hist.push("/myvideos")
    // document.location.href = "/myvideos"
  };


  const handleRename = (e) => {
    fetcher({
      method: "PUT",
      url: `${url}/api/newvideo/video/${data.id}/`,
      data: { name: e.target.value },
      res: (k) => {
        // alert(`${data.id} is rename!`);
        mystore.dispatch({
          type: "EDIT_DETAIL",
          data: {
            name: e.target.value,
          },
        });
      },
      err: (err) => {
        // alert(`${Vid()} is Error on rename!`);
      },
    });
  };

  const handleDuplicate = () => {
    _onOpenD(true)
  };

  useEffect(() => {
    setIsLoading(true)
    fetcher({
      url: `${url}/api/newvideo/video/details/${vid}/`,
      method: "GET",
      res: (data) => {
        // console.log(data.data.result);
        setData(data.data.result);

        mystore.dispatch({
          type: "ADD_ID",
          data: {
            id: vid,
            detail: data.data.result,
          },
        });
        setIsLoading(false)
        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        });
        let _ptab = params.ptab;
        const _orgUrl = window.location.href.split('?')[0];

        if (_ptab) {
          try {
            const _parseData = JSON.parse(atob(_ptab));
            window.history.replaceState({}, document.title, _orgUrl);
            let _autoClickBtn = null, _autoPopupBtn = null;
            if (_parseData.tab) {
              _autoClickBtn = document.getElementById("_shareMailBtn");
            } else {
              _autoClickBtn = document.getElementById("_shareLinkBtn");
            }
            if (_autoClickBtn && Number(_parseData?.pageId)) {
              document._ptab = _parseData;

              _autoClickBtn.click();
              if (_parseData.tab) {
                _autoPopupBtn = document.getElementById("_mailPopupBtn");
              } else {
                _autoPopupBtn = document.getElementById("_linkPopupBtn");
              }

              if (_autoPopupBtn) {
                _autoPopupBtn.click();
              }

            } else if (_autoClickBtn && Number(_parseData?.imageId)) {
              document._ptab = { ..._parseData, imageId: Number(_parseData?.imageId), _isClickThumbnail: true };

              _autoClickBtn.click();
              let _tickInterval = setInterval(() => {
                if (_parseData.tab) {
                  _autoPopupBtn = document.getElementById("_mailPopupBtnT");
                } else {
                  _autoPopupBtn = document.getElementById("_linkPopupBtnT");
                }
                if (_autoPopupBtn) {
                  _autoPopupBtn.click();
                  clearInterval(_tickInterval);
                  _tickInterval = null;
                }

              }, 35);
              setTimeout(() => {
                clearInterval(_tickInterval);
                _tickInterval = null;
              }, 20000)
            }

          } catch (err) {
            window.history.replaceState({}, document.title, _orgUrl);
          }

        }
      },
      err: (err) => {
        // console.log(err);
        // handle it with some kind of error 
        if (localStorage.getItem("token"))
          hist.push("/myvideos")
      },
    });
    // console.log(a);
    // setData(mystore.getState().media.generated.list[state.index]);
  }, []);

  const handleDelete = () => {
    _onOpen(true)
  };
  const [isOpen, setOpen] = useState(false);
  const handleDownload = () => {
    if (dataA.mydetail?.isPersonalized) {
      setOpen(true);
    } else {
      window.open(dataA.mydetail?.mainVideoGenerate?.video + `?download=1&filename=${dataA.mydetail.name}.mp4`, "_blank");
    }
  };

  useEffect(() => {
    document
      .getElementById("bootstrap")
      .setAttribute("href", "/bootstrap.min.css");
    return () => {
      document.getElementById("bootstrap").removeAttribute("href");
    };
  }, []);


  const [_open, _onOpen] = useState(false)
  const [_openD, _onOpenD] = useState(false)
  const [playPopup, setPlayPopup] = useState(false)
  const [isThumbnailLoaded, setIsThumbnailLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  if (isLoading) {
    return (
      <div className="VideoDetail_detail_thumb_render" style={{ background: "#242424" }}>
        <div className="INFINITELOAD">
          <img alt="Loading" src={`${document.PUBLIC_URL}/icons/loading.gif`} />
        </div>
      </div>
    )
  } else {
    return (
      <div className="VideoDetail">
        {/* <MapPopup /> */}
        <div className="VideoDetail_header flex">
          <div
            style={{ cursor: "pointer" }}
            className="VideoDetail_header_img flex center"
            onClick={handleBack}
          >
            <img className="" src={`${document.PUBLIC_URL}/app/goBack.svg`} alt="goBack" />
            My Videos
            <Hr m="20px" h="60px" />
          </div>

          <div className="VideoDetail_header_name">
            <EditableLabel onRename={handleRename} value={data.name} />
          </div>
          <div className="VideoDetail_header_icons flex center">
            <Hr m="0px" h="60px" />
            <img src={`${document.PUBLIC_URL}/app/clone.svg`} alt="" onClick={handleDuplicate} />
            <DuplicatePopup
              text={"video"}
              isConfirm={_openD}
              onOpen={_onOpenD}
              onClickk={() => {
                fetcher({
                  url: `${url}/api/newvideo/video/copy/${data.id}/`,
                  res: (res) => {
                    window.open(`/video/${res.data.result.id}/`, "_blank");
                  },
                });
              }}

            />
            <Hr m="0px" h="60px" />
            <img src={`${document.PUBLIC_URL}/app/delete.svg`} alt="" onClick={handleDelete} />
            <DeletePopup text={data.name} isConfirm={_open} setConfirm={_onOpen} onDelete={() => {
              fetcher({
                method: "DELETE",
                url: `${url}/api/newvideo/video/${data.id}/`,
                res: (k) => {
                  // alert(`${data.id} is deleted!`);
                  mystore.dispatch({
                    type: "DELETE_MEDIA",
                    data: {
                      id: data.id,
                      // detail: data.data.result,
                      type: "generated",
                    },
                  });
                  handleBack();
                },
                err: (err) => {
                  mystore.dispatch({
                    type: "DELETE_MEDIA",
                    data: {
                      id: data.id,
                      // detail: data.data.result,
                      type: data.type,
                    },
                  });
                  handleBack();
                  alert(`${data.id} is Error on Deleted!`);
                },
              });
            }} />
          </div>
        </div>
        <div className="VideoDetail_detail flex">
          <div className="VideoDetail_detail_thumb">
            {data.isPersonalized && < div className="VideoDetail_detail_thumb_per">
              Personalized
            </div>}

            {dataA.mydetail?.thumbnail ? <>
              {dataA.mydetail?.mainVideoGenerate?.status === 1 ? <img className="playbutton-video-details" src={`${document.PUBLIC_URL}/app/RectanglePlay.svg`} alt="play" onClick={() => {
                setPlayPopup(true)
              }} /> : <></>}
              <img src={dataA.mydetail.thumbnail} alt="wall3.ong" onClick={() => {
                setPlayPopup(true)
              }} onLoad={() => {
                setIsThumbnailLoaded(true)
              }} />

            </> : <></>}
            {dataA.mydetail?.mainVideoGenerate?.status === 1 ?
              <PurePopup footer={<></>} customOpenFunction={() => { }} open={playPopup} onOpen={setPlayPopup} onClose={() => {

                //  NOT WORKING
                var vd = document.getElementById(`_preview-video`);
                if (vd) {
                  vd.pause();
                  vd.removeAttribute('src'); // empty source
                  vd.load();
                }

              }}>
                <video id="_preview-video" autoPlay src={dataA.mydetail?.mainVideoGenerate?.video} className="PreviewVideo" controls controlsList="nodownload" ></video>

              </PurePopup> : <></>}
            {/* <RenderWrapper progress={dataA.mydetail?.mainVideoGenerate?.progress} /> */}
            <RenderWrapper progress={dataA.mydetail?.mainVideoGenerate?.progress} status={dataA.mydetail?.mainVideoGenerate?.status} isLoading={!isThumbnailLoaded} />

          </div>
          <div className="VideoDetail_detail_about flex column">
            <div className="VideoDetail_detail_about_download">
              <div className="VideoDetail_detail_about_heading">
                Download {"&"} Share
                <TablePopup trigger={<button>TablePopup</button>} />
              </div>
              <div className="button">
                <div className={`button_download  ${dataA.mydetail?.mainVideoGenerate?.status !== 1 ? "Disabled-button-generate-invarPopup" : ""}`} onClick={handleDownload}>
                  Download{" "}
                  <img
                    className="button_download_img"
                    src={`${document.PUBLIC_URL}/app/download.svg`}
                    alt="button"
                  />
                </div>
                <Vr m="30px" />
                <div className="button_share flex">
                  {dataA.mydetail?.thumbnailInst && <LinkPopup />}
                  {dataA.mydetail?.thumbnailInst && <MailShare />}
                  {dataA.mydetail?.thumbnailInst && <DownloadPopup isOpen={isOpen} setOpen={setOpen} />}


                  {/* <Button
                  value={
                    <>
                      Share via Mail <img src={`${document.PUBLIC_URL}/app/mail.svg`} alt="good"></img>
                    </>
                  }
                /> */}
                </div>
              </div>
            </div>
            {/* <div className="VideoDetail_detail_about_report">
            <div className="VideoDetail_detail_about_heading">Report</div>
            <div className="VideoDetail_detail_about_report_flex flex">
              {[
                {
                  src: `${document.PUBLIC_URL}/app/r_link.svg`,
                  title: "Links Clicked",
                  value: data.report.linkClicked,
                },
                {
                  src: `${document.PUBLIC_URL}/app/r_play.svg`,
                  title: "Video Played",
                  value: data.report.videoPlayed,
                },
                {
                  src: `${document.PUBLIC_URL}/app/r_eye.svg`,
                  title: "Views",
                  value: data.report.views,
                },
              ].map((e) => (
                <IconTitle key={e.src} {...e} />
              ))}
            </div>
          </div> */}
          </div>
        </div>
      </div >

    )
  }

}




const IconTitle = ({ src, title, value }) => {
  return (
    <div className="IconTitle flex column">
      <div className="IconTitle_icon flex center">
        <img src={src} alt={src} />
      </div>
      <div className="IconTitle_title">{title}</div>
      <div className="IconTitle_value">{value}</div>
    </div>
  );
};


const mstp = state => {
  return ({
    dataA: mystore.getState()
  })
}

export default connect(mstp)(VideoDetail)