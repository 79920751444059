import store from "./store/store";

export default async function makeImgEditor() {
  if (document.editorType !== "image") {
    document.editorType = "image";

    await store.dispatch({ type: "IMG_EDITOR" });

    // console.log();
  }
}
