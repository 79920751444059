import { memo } from "react";

import "./_Atom.scss";
function DisabledWrapper({ children, isDisable, setOnDisable = null }) {
  ////console.log("DisabledWrapper is rendered");

  return (
    <div
      className={`DisabledWrapper ${isDisable ? "DisabledWrapper_active" : ""}`}
    >
      <div className={`${"DisabledWrapper_child"}`}>{children}</div>
      {isDisable && <div className="DisabledWrapper_black">{setOnDisable}</div>}
    </div>
  );
}

export default memo(DisabledWrapper);
