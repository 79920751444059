/* eslint-disable */
import React, { useRef, useState, useEffect } from "react";
// import { url, token } from "../../../config/config";
import axios from "axios";
import SignalsSelDots from "../../../extras/analytics/InpopupDots.svg";
import SignalsDots from "../../../extras/analytics/signalsDots.svg";

import { ReactComponent as AdminMake } from "../../../extras/account/admin.svg";
import { ReactComponent as DeleteMemberI } from "../../../extras/account/deletememeber.svg";
import { ReactComponent as VsDel } from "../../../extras/account/vs-delete.svg";
import { url, fetcher } from "../../../../utils";
export default function Signals(props) {
	const dropdownRef = useRef(null);
	const [isActive, setIsActive] = useState(false);
	const [ComponentType, setComponentType] = useState(0)
	const onClick = () => setIsActive(!isActive);

	const handler = (ele) => {
		if (ele.currentTarget.contains(ele.relatedTarget)) {
		} else {
			setIsActive(false);
			setComponentType(0)
		}
	};


	const handleAdmin = async () => {
		// const Token = token();
		// const options = { headers: { Authorization: `Token ${Token}` } };
		var obj = {
			email: props.email,
			is_admin: !props.org_is_admin
		}
		// await axios.post(`${url}/api/accounts/user/organization/adminchange/`,obj,options)
		fetcher({
			url: `${url}/api/accounts/user/organization/adminchange/`,
			method: "POST",
			data: obj,
			res: res => {

			}

		})
		setComponentType(0)
		props.adminRemoved(props.email, !props.org_is_admin)
		setIsActive(false);
	}
	const handleDelete = async () => {

		var obj = {
			email: props.email,
		}
		setComponentType(0)
		// await axios.post(`${url}/api/accounts/user/organization/remove/`,obj,options)
		fetcher({
			url: `${url}/api/accounts/user/organization/remove/`,
			method: "POST",
			data: obj,
			res: res => {

			}

		})
		setComponentType(0)
		props.memberRemoved(props.email)
		setIsActive(false);
	}


	var arr = [
		{
			title: !props.org_is_admin ? "Make Admin" : "Remove Admin",
			icon: <AdminMake />,
			func: async (k) => {
				setComponentType(2)

			},
		},
		{
			title: "Delete Memeber",
			icon: <DeleteMemberI />,
			func: async (k) => {
				setComponentType(1)




			},
		},
	];

	return (
		<div
			onBlur={(e) => {
				handler(e);
			}}
		>
			<div className="menu-container ">
				<button
					onClick={() => {
						onClick();
					}}
					className="menu-trigger signal-DOts"
				>
					{isActive ? (
						<img src={SignalsSelDots} alt="" />
					) : (
						<img src={SignalsDots} alt="" />
					)}
				</button>
				<nav
					onBlur={(e) => {
						handler(e);
					}}
					tabIndex="1"
					ref={dropdownRef}
					className={`menu menu-signals ${isActive ? "active" : "inactive"}`}
				>
					{ComponentType === 0 ? <ul className="mainList mainList-signals">
						{arr.map((k) => {
							return (
								<li
									className="main-list-li pointer"
									onClick={(e) => {
										k.func();

									}}
								>
									{k.icon} &nbsp; &nbsp; {k.title}
								</li>
							);
						})}
					</ul> : null
					}
					{ComponentType === 1 ? <div className="mainList menu-submenu-manageteam-container">
						{<label htmlFor="">Are you sure you want to delete this
							member?</label>}
						<div className="manage-button-content">
							<button onClick={() => {
								setComponentType(0)
								setIsActive(false);
							}} className="cancel-content-remove-make">Cancel</button>
							<button onClick={() => {
								handleDelete()
								setIsActive(false);
							}} className="do-content-remove-make">Delete &nbsp; <VsDel /></button>
						</div>
					</div> : null
					}
					{ComponentType === 2 ? <div className="mainList menu-submenu-manageteam-container">
						{props.org_is_admin ? <label htmlFor="">Are you sure you want to dismiss this user as Admin?</label> :
							<label htmlFor="">Are you sure you want to make this
								member an admin?</label>}
						<div className="manage-button-content">
							<button onClick={() => {
								setComponentType(0)
								setIsActive(false);
							}} className="cancel-content-remove-make">No</button>
							<button onClick={() => {
								handleAdmin()
								setIsActive(false);
							}} className="do-content-remove-make">Yes</button>
						</div>
					</div> : null
					}

				</nav>
			</div>
		</div>
	);
}
