import React, { memo, useState, useCallback } from "react";
import Color from "./Color";
import "./_Atom.scss";
import { gsap } from "gsap";
import { useEffect } from "react";
import { connect } from "react-redux";

export default function NewColor({
  setDefault = "#999777",
  onSelect = () => { },
  onChange = () => { },
  onAdd = () => { },
  onDelete = () => { },
  gradientList = [],
  onGradientSelect = () => { },
  arr,
  handleLastChange = () => { },
}) {
  const [_arr, setArr] = useState(() => arr);
  useEffect(() => {
    setArr(arr)
  }, [arr])
  const [now, setNow] = useState(setDefault);
  // const [current, setCurrent]= useState()
  const handleColor = useCallback((col) => {
    // //console.log(col);
    setNow(col);
    onChange(col);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handelDelete = (colArr) => {
    onDelete(colArr);
    setArr(colArr);
  };
  const handelAdd = (colors) => {
    setArr(colors);
    onAdd(colors);
  };

  const handleSelect = (col) => {
    onSelect(col);
    setNow(col);
    // onSelect(col);
  };
  // if(now!==setDefault){
  //     setNow(setDefault);
  // }
  return (
    <div className="NewColor">
      <div className></div>

      <div className="NewColor_color">
        <Color
          color={now}
          onChange={handleColor}
          onLastChange={handleLastChange}
          goTo={(e) => {
            e(now);
          }}
        />
      </div>
      <ColorCircles
        onDelete={handelDelete}
        arr={_arr}
        onColorAdd={handelAdd}
        color={now}
        onSelect={handleSelect}

      />
      <div className="NewColor_list">
        {gradientList.map((e) => (
          <img
            src={e.media_thumbnail}
            alt={e.name}
            onClick={(k) => onGradientSelect(e)}
            className="NewColor_list_color"
          ></img>
        ))}
      </div>
    </div>
  );
}
const ColorCircles = connect(s => ({
  arr: s.scenes.colorArr
}))(({
  color,
  onDelete = () => { },
  arr,
  onColorAdd = () => { },
  onSelect = () => { },
}) => {
  const [_arr, setArr] = useState(arr);
  const handleNewColor = useCallback(
    (e) => {
      // //console.log(_arr, color);
      if (_arr.indexOf(color) === -1) {
        gsap.from(e.target, { x: -40 });
        setArr((a) => {
          onColorAdd([...a, color]);
          return [...a, color];
        });
      }
    },
    [_arr, color, onColorAdd]
  );

  useEffect(() => {
    setArr(arr);
    console.log("colorhna");
  }, [arr])
  const [edit, setEdit] = useState(false);
  const handleSelect = (col) => (e) => onSelect(col, e);
  const handleOK = () => {
    setEdit(false);
    onDelete(_arr);
  };
  const handleCancel = () => {
    setArr(arr);
    setEdit(false);
  };

  const handleDelete = (col) => (e) => {
    setArr(_arr.filter((k) => k !== col));
  };
  return (
    <>
      <div className="colorCircles_head">
        <div className="colorCircles_title">My Colors</div>
        <div className="colorCircles_control">
          {!edit ? (
            <img
              alt="img"
              src={`${document.PUBLIC_URL}/ico/pencil.svg`}
              onClick={() => setEdit(true)}
            />
          ) : (
            <>
              <img alt="img" src={`${document.PUBLIC_URL}/ico/ok.svg`} onClick={handleOK} />
              <img alt="img" src={`${document.PUBLIC_URL}/ico/cancel.svg`} onClick={handleCancel} />
            </>
          )}
        </div>
      </div>
      <div className="colorCircles">
        {_arr.map((e) => (
          <ColorCircle
            onDelete={handleDelete(e)}
            isEdit={edit}
            onClick={handleSelect(e)}
            color={e}
            isActive={color === e}
          />
        ))}
        {/* <div className="_colorCircle" style={}></div> */}
        {/* <ColorCircle isActive={true}/> */}
        <ColorCircle
          onClick={handleNewColor}
          ele={<img alt="img" src={`${document.PUBLIC_URL}/ico/plus.svg`} />}
          color="#ffffff"
        />
      </div>
    </>
  );
});
const ColorCircle = memo(
  ({
    isEdit,
    onDelete = () => { },
    color = "white",
    ele,
    isActive,
    onClick = () => { },
  }) => {
    return (
      <div className="_colorCircle_wrapper">
        <div
          onClick={onClick}
          className={"_colorCircle"}
          style={{ background: color }}
        >
          {ele || (isActive && <img alt="img" src={`${document.PUBLIC_URL}/ico/tick.svg`} />)}
        </div>
        {isEdit && (
          <div className="_colorCircle_close">
            <img alt="img" src={`${document.PUBLIC_URL}/ico/cross.svg`} onClick={onDelete} />
          </div>
        )}
      </div>
    );
  }
);
