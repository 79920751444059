import React from "react";
import { connect } from "react-redux";
import NewColor from "../Atom/NewColor";

export default connect(
  (state) => ({
    colorArr: state.scenes.colorArr,
  }),
  (dispatch) => {
    return {
      EditColor: (colorArr) => {
        dispatch({ type: "EDIT_COLOR", data: colorArr });
      },
    };
  }
)(function NewColorWrapper({
  colorArr,
  EditColor,
  value,
  onChange = () => { },
  onLastChange = () => { },
  onSelect = () => { },
}) {
  return (
    <NewColor
      arr={colorArr}
      setDefault={value}
      onDelete={EditColor}
      onAdd={EditColor}
      onChange={onChange}
      onSelect={onSelect}
      handleLastChange={onLastChange}
    />
  );
});
