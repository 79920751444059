import { useState, memo, useCallback } from "react";
import "./_Atom.scss";
function InputIcon({
  value = "",
  placeholder = "",
  type = "text",
  disabled = false,
  onChange = () => { },
  icon = null,
  src = null,
  position = "left",
  onBlur = () => { },
  onFocus = () => { },
  id = "",
  style = {}
}) {
  ////console.log("%c InputIcon ", "color:hotpink;");
  const [_value, setValue] = useState(value);
  const handlerChange = useCallback((e) => {
    setValue(e.target.value);
    onChange(e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onBlurC = (e) => {
    document.isCanActive = true;
    onBlur(e);
  }
  const onFocusC = (e) => {
    document.isCanActive = false;
    onFocus(e);
  }
  return (
    <div className="InputIcon">
      {position === "left" &&
        (icon || (src && <img className="Img" src={src} alt={"img"} />))}{" "}
      <input
        id={id}
        className="Input"
        type={type}
        value={_value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handlerChange}
        onBlur={onBlurC}
        onFocus={onFocusC}
        style={style}
      />
      {position === "right" &&
        (icon || <img className="Img" src={src} alt={"img"} />)}{" "}
    </div>
  );
}

export default memo(InputIcon);
