/* eslint-disable */
import React, { Component } from 'react'

import { connect } from "react-redux";
import { ReactComponent as Back } from "../../../App/extras/misc/salespagebackarrow.svg";
import { ReactComponent as Autogenerate } from "../../../App/extras/aiLogo.svg";
import { ReactComponent as Desktop } from "../../../App/extras/misc/desktop.svg";
import Popup from "reactjs-popup";
import { ReactComponent as Mobile } from "../../../App/extras/misc/phone.svg";
import { ReactComponent as Cross } from "../../../App/extras/crossSm.svg";

class PreviewPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.props.State,

            width: {
                width: "100%",
                type: 0,
            },
        }
    }







    changeWidth() {
        var c = document.getElementById("mobile-width-container")

        var frameCon = document.getElementById("mobile-frame-con-wrapper")
        // var frame = document.getElementById("mobile-frame-container")

        // // console.log(frameCon.clientWidth-(frameCon.clientWidth-frame.getBoundingClientRect().width-20));

        // // console.log(frame.getBoundingClientRect().width);
        // console.log(frameCon.clientWidth);
        // console.log(`${frameCon.clientHeight-(frameCon.clientHeight-frame.getBoundingClientRect().height+20) }px`);



        // c.style.width = `${ frameCon.clientWidth-(frameCon.clientWidth-frame.getBoundingClientRect().width+25)}px`
        // c.style.height = `${frameCon.clientHeight-(frameCon.clientHeight-frame.getBoundingClientRect().height+20) }px`
        // c.style.top = `${(frameCon.clientHeight-frame.getBoundingClientRect().height+23) }px`
        // c.style.left = `${((frameCon.clientWidth-frame.getBoundingClientRect().width)/2)+10}px`


    }





    render() {
        console.log(this.props);

        return (
            <Popup
                className="preview-popup-salespage"
                trigger={this.props.children}
                modal
                nested

            >
                {(close) => (<div className="preview-container-wrapper">
                    <div className="salespage-preview">
                        <div className="gp-preview">
                            <Back
                                className=" pointer back-btn-preview"
                                onClick={() => close()}
                            />
                            <Autogenerate />
                        </div>
                        <div className="gp-preview">
                            <div
                                className="select-size-icon-container pointer"
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        width: {
                                            width: "100%",
                                            type: 0,
                                        },
                                    });
                                }}
                            >
                                <Desktop />
                                <span
                                    className={`selected-size-bd ${this.state.width.type === 0 ? "true" : ""
                                        }`}
                                ></span>
                            </div>
                            <div
                                className="select-size-icon-container pointer"
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        width: {
                                            width: "1000px",
                                            type: 1,
                                        },
                                    });
                                    this.changeWidth()
                                }}
                            >
                                <Mobile />
                                <span
                                    className={`selected-size-bd x99 ${this.state.width.type === 1 ? "true" : ""
                                        }`}
                                ></span>
                            </div>
                        </div>
                        <div className="gp-preview">

                            <button className="closesalespagebtn" onClick={() => close()}>
                                Close Preview &nbsp; <Cross />
                            </button>
                        </div>
                    </div>

                    <div className="preview-page-container mobile" style={{ display: this.state.width.type === 1 ? "block" : "none" }}>


                        <div id="mobile-width-container" className="PreviewInSalespageTemplate" style={{ display: this.state.width.type === 1 ? "block" : "none" }} >
                            <svg id="mobile-frame-con-wrapper" className="PreviewInSalespageTemplate-content" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560.804 1038.996">
                                <defs>
                                    <filter id="52wksrypja" x="0" y="0" filterUnits="userSpaceOnUse">
                                        <feOffset dx="5" dy="5" />
                                        <feGaussianBlur result="blur" stdDeviation="15" />
                                        <feFlood flood-opacity=".6" />
                                        <feComposite in2="blur" operator="in" />
                                        <feComposite in="SourceGraphic" />
                                    </filter>
                                </defs>
                                <g filter="url(#52wksrypja)" transform="translate(-1021.048 -281.807) matrix(1, 0, 0, 1, 1021.05, 281.81)">
                                    <path id="mobile-frame-container" fill="#222" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" d="M462.461 149.907h-.893v-93.67A56.235 56.235 0 0 0 405.333 0H64.469A56.235 56.235 0 0 0 8.234 56.237v93.669h-.893A7.342 7.342 0 0 0 0 157.248v64.794a7.342 7.342 0 0 0 7.342 7.342h.893v15.454h-.894A7.342 7.342 0 0 0 0 252.18v64.794a7.342 7.342 0 0 0 7.342 7.342h.893v567.448A56.235 56.235 0 0 0 64.469 948h340.864a56.235 56.235 0 0 0 56.235-56.235V269.124h.893a7.341 7.341 0 0 0 7.342-7.342V157.248a7.341 7.341 0 0 0-7.342-7.341z" transform="translate(40.5 40.5)" />
                                </g>
                            </svg>
                            {/* <img className={"PreviewInSalespageTemplate-content"} src={MobileIcon} alt="" style={{width:"100%"}} /> */}

                            <div className={"PreviewInSalespageTemplate-image-div"}> <img className={"PreviewInSalespageTemplate-image"} src={this.props.mobile} alt="" />
                            </div>

                        </div>



                    </div>

                    <div className="preview-page-container " style={{ display: this.state.width.type === 1 ? "none" : "block" }}>
                        <img className="image-of-templates" src={this.props.desktop} alt="" style={{ width: "100%", minHeight: "100%", display: this.state.width.type === 1 ? "none" : "block" }} />

                    </div>


                </div>

                )}
            </Popup>



        )
    }
}



const mapStateToProps = (state) => {

    return {
        state: state
    }

};



export default connect(mapStateToProps, null)(PreviewPage);


