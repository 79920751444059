import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch, useLocation } from "react-router";
import ArrowWrapper from "../Atom/ArrowWrapper";
import LabelInput from "../Atom/LabelInput";
import SlideContainer from "../Atom/SlideContainer";
import ThreeCard from "../Atom/ThreeCard";
import TitleRow from "../Atom/TitleRow";
import Tabium from "../Compound/Tabium";
import "../One/_One.scss";
import gsap from "gsap";
import { cxxtoJSON } from "../../../utils/config";
import {
  intialSceneSetup,
  objProMapper,
  parseSceneData,
} from "../../../utils/shortcutFunction";
import SelectOption from "../Atom/SelectOption";
import EditSelect from "../Atom/EditSelect";
import { fetchAndSave, Index, MoveSeekTo } from "../../../utils";
import { UpdateBackground } from "../One/Draggy";
import store from "../../../store/store";
const Type = {
  0: "enter",
  1: "place",
  2: "exit",
};
export default connect(
  (state) => ({ media: state.media }),
  (dispatch) => ({
    setActive: (data) => dispatch({ type: "DRAG_RESIZE", data }),
  })
)(function Animation({
  to,
  type = "animation",
  media,
  title = "Animation",
  selectedIndex = 0,
  onSelect = () => { },
  selected = {},
  selected1 = {},
  onBack = () => { },
  setActive,
  obj,
}) {
  const { state } = useLocation();
  const [list, setList] = useState(
    media[type === "tanimation" ? "animation" : type].list
  );
  const { url } = useRouteMatch();
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [objPro, setPro] = useState({});
  const [duration, setDuration] = useState(
    obj._Animation[Type[tab]]?.duration || "1.0"
  );
  const [activeSelect, setSelect] = useState({
    enter: obj._Animation.enter?.id,
    exit: obj._Animation.exit?.id,
    place: obj._Animation.place?.id,
  });

  const [nowAnimation, setAnimation] = useState(obj._Animation);
  const handlerBack = useCallback(() => {
    document.disableoutPlayMode = false;

    store.dispatch({ type: "SET_FRAME", data: false });

    history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAndSave({ type });
    if (type !== "animation") {
      fetchAndSave({ type: "animation" });
    }
    store.dispatch({ type: "SET_FRAME", data: true });
    // document.disableRender = true;
    document.disableoutPlayMode = true;
  }, []);

  useEffect(() => {
    setList(media[type === "tanimation" ? "animation" : type].list);
  }, [media]);

  const handleSelect = (_obj) => {
    return () => {
      document.screenChheda = true;
      let _du = Number(duration);
      let con = Type[tab];

      if (_obj.id === 5) {
        _du = 0;
        // setDuration(0);
      }
      onSelect(_obj);

      setSelect((e) => ({ ...e, [_obj.type]: _obj.id }));
      setActive({
        id: obj.id,
        data: { [con]: Number(_du) },
      });
      obj._Animation = { ...obj._Animation, [_obj.type]: _obj };

      // document.txx.setX((x) => {
      //   return 0;
      // });
      // MoveSeekTo({ position: "left" });
    };
  };

  const handleBlurDuration = (e) => {
    let d = Number(e.target.value);
  };

  const handleMLeave = (type) => {
    return () => {
      if (document._crntAnimation) {
        document._crntAnimation.pause();
        if (
          document._crntAnimation._backupProperties &&
          document._crntAnimation._obj
        ) {
          document._crntAnimation._obj.set({
            ...document._crntAnimation._backupProperties,
          });
          //document.removeVideoWrapper(document._crntAnimation._obj)
          document._crntAnimation._obj.canvas?.renderAll();
        }
        document._crntAnimation.clear();
        document._crntAnimation = null;
      }
    };
  };

  const handleMHover = (obj, e, type) => {
    return () => {
      //backupdata
      const _backupData = objProMapper(obj);

      const k = e.animationData;
      intialSceneSetup(k.init || [], {
        object: obj,
      });
      let _crnAnimation = parseSceneData(obj, {
        from: k[type].from,
        to: k[type].to,
      });
      //document.initVideoWrapper(obj)
      document._crntAnimation = gsap.timeline({
        paused: true, onUpdate: () => {
          //document.updateVideoElementCoords(obj)
          obj.canvas?.renderAll();
        }
      });
      document._crntAnimation._backupProperties = _backupData;
      document._crntAnimation._obj = obj;
      document._crntAnimation.fromTo(obj, _crnAnimation.from, {
        ..._crnAnimation.to,
        duration,
      });
      document._crntAnimation.play();
    };
  };

  const handleTAnimator = (t) => {
    if (t === "Paragraph") {
      setList(media["animation"].list);
    } else if (t === "Line") {
      setList(media[type].list.line);
    } else if (t === "Word") {
      setList(media[type].list.word);
    } else if (t === "Character") {
      setList(media[type].list.char);
    }
  };
  useEffect(() => {
    setDuration(obj._Animation[Type[tab]]?.duration || 1.0);
  }, [tab]);

  const [alert, setAlert] = useState({ has: false, say: "" });
  let _maxDuration = Number(
    document._Elements[obj.id].stayTime -
    document._Elements[obj.id][Type[tab] === "enter" ? "exit" : "enter"]
  ).toFixed(2);
  const handleChangeDuration = (e) => {
    console.log({ id: state.id });
    let half = _maxDuration;
    let d = Number(e.target.value);
    let handle = () => {
      setAlert({ has: false, say: "" });
    };
    if (d >= 0 && d <= half) {
      clearTimeout(handle);
      setDuration(d);
      // setDuration(e.target.value);
      let type = Type[tab];
      obj._Animation = {
        ...obj._Animation,
        [type]: {
          ...obj._Animation[type],
          duration: Number(e.target.value),
        },
      };
      // console.log(JSON.stringify(obj._Animation));
      onSelect({
        type,
        ...obj._Animation[type],
      });

      // document.txx.setX((x) => {
      //   return 0;
      // });
      // MoveSeekTo({ position: "left" });

      // UpdateBackground();
      // setDelay(d);
    }
    else if (d < 0) {
      setAlert({ say: ` Can't be less than ${0} !`, has: true });
    }
    else {
      setAlert({ say: ` Can't be more than ${_maxDuration} !`, has: true });
      setTimeout(handle, 2000);
      // setDelay(delay);
      setDuration(duration);
    }
  };
  return (
    <SlideContainer>
      <div className={`Mask Animation Animation_${type}`}>
        <TitleRow cursor="pointer" title={title} onClick={handlerBack} />

        <Tabium
          onTabChange={(e) => {
            setTab(e);
          }}
          arrTab={[
            {
              name: "Enter",
              src: `${document.PUBLIC_URL}/ico3/enterAnimationInactive.svg`,
              asrc: `${document.PUBLIC_URL}/ico3/enterAnimationActive.svg`,
            },
            {
              name: "In Place",
              src: `${document.PUBLIC_URL}/ico3/enterAnimationInactive.svg`,
              asrc: `${document.PUBLIC_URL}/ico3/enterAnimationActive.svg`,
            },
            {
              name: "Exit",
              src: `${document.PUBLIC_URL}/ico3/exitAnimationInactive.svg`,
              asrc: `${document.PUBLIC_URL}/ico3/exitAnimationActive.svg`,
            },
          ]}
        >
          <>
            {/* {type === "tanimation" && (
              <EditSelect
                disabled={true}
                // title=""
                className="tanimation_list"
                selectedIndex={0}
                onSelect={handleTAnimator}
                list={["Paragraph", "Line", "Word", "Character"]}
              />
            )} */}
            <div className="Mask_list">
              {list.map((e) => (
                <div
                  onMouseEnter={handleMHover(obj, e, "start")}
                  onMouseLeave={handleMLeave("start")}
                >
                  <ThreeCard
                    onClick={handleSelect({ ...e, type: "enter", duration })}
                    asrc={e.src}
                    src={e.src}
                    title={e.name}
                    isActive={activeSelect.enter === e.id}
                  // onMouseOver={handleHover(obj, e, "start")}
                  // onMouseOut={handleLeave}
                  />
                </div>
              ))}
            </div>
          </>
          {type !== "tanimation" &&
            <div className="Mask_list">
              {media["place"].list.map((e) => (
                <div
                  onMouseEnter={handleMHover(obj, e, "start")}
                  onMouseLeave={handleMLeave("start")}
                >
                  <ThreeCard
                    onClick={handleSelect({ ...e, type: "place", duration })}
                    asrc={e.src}
                    src={e.src}
                    title={e.name}
                    isActive={activeSelect.place === e.id}
                  // onMouseOver={handleHover(obj, e, "end")}
                  // onMouseOut={handleLeave}
                  />
                </div>
              ))}
            </div>
          }
          <>
            {/* {type === "tanimation" && (
              <EditSelect
                disabled={true}
                // title=""
                className="tanimation_list"
                selected="Paragraph"
                onSelect={handleTAnimator}
                list={["Paragraph", "Line", "Word", "Character"]}
              />
            )} */}
            <div className="Mask_list">
              {list.map((e) => {
                let _src = e.src;
                if (e.exitSrc) {
                  _src = e.exitSrc;
                }
                return (
                  <div
                    onMouseEnter={handleMHover(obj, e, "end")}
                    onMouseLeave={handleMLeave("end")}
                  >
                    <ThreeCard
                      onClick={handleSelect({ ...e, type: "exit", duration })}
                      asrc={_src}
                      src={_src}
                      title={e.name}
                      isActive={activeSelect.exit === e.id}
                    // onMouseOver={handleHover(obj, e, "end")}
                    // onMouseOut={handleLeave}
                    />
                  </div>
                )
              })}
            </div>
          </>
        </Tabium>
      </div>
      {Type[tab] !== "place" && <div className="flex row  Animation_duration">
        <LabelInput
          label="Duration"
          type="number"
          value={String(duration)}
          onChange={handleChangeDuration}
          onBlur={handleBlurDuration}
          onEnter={handleBlurDuration}
          step={0.25}
          width="84px"
          innerWidth={"40px"}
          haveInc={true}
        // min={0}
        />
        <div style={{ marginLeft: "5px", fontSize: "12px" }}>S</div>
        {alert.has && (
          <div className="DelaySettingsInput_alert">
            {alert.say}
          </div>
        )}
      </div>}
    </SlideContainer>
  );
});
