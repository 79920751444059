import {
  fetcher,
  fitCenterToCXX,
  fitImageToRect,
  Index,
  MoveSeekTo,
  Vid,
} from "../../utils";
import uuid from "uuid-random";
import store from "../../store/store";
import { fabric } from "fabric";
import { API_URL, SIZES } from "../config";
import { checkBufferVideoEvent } from "./checkBufferVideoEvent";
import { InitAddObjectEvents } from "../InitAddObjectEvents";
import { UpdateBackground } from "../../components/Chemistry/One/Draggy";
// import axios from "axios";
import { customVideoSeek } from "../videoPlayThings";
// import initState from "../../store/initState";
// import { API_URL } from "../../config";

let _media = {
  video: {
    next: `${API_URL}/api/bgclip/search/?type=video`,
    list: [],
  },

  stock: {
    next: `${API_URL}/api/bgclip/search/?type=image`,
    list: [],
  },
  upload: {
    // next: `${API_URL}/api/userlibrary/upload/?type=image",
    next: `${API_URL}/api/userlibrary/upload/?type=image&category=autovid_image`,
    list: [],
  },

  video_upload: {
    next: `${API_URL}/api/userlibrary/upload/?type=video&category=autovid_video`,
    list: [],
  },
};

function createObjectURL(object) {
  return window.URL
    ? window.URL.createObjectURL(object)
    : window.webkitURL.createObjectURL(object);
}

export async function display(videoStream) {
  // var video = document.getElementById("_video");

  if (document.blobMedia.getBlobUrl(videoStream)) {
    return document.blobMedia.getBlobUrl(videoStream);
  }
  let blob = await fetch(videoStream).then((r) => r.blob());
  let blobURL = createObjectURL(blob);
  document.blobMedia.setBlobUrl(videoStream, blobURL);
  return blobURL;
  // video.src = videoUrl;
}
export const askConvertedVideo = (
  videoID,
  ctg,
  cb,
  type = "video",
  media = {}
) => {
  return new Promise((r, rj) => {
    if (videoID === 0) {
      r({
        media_file: media.media_file,
        res: media,
      });
    }
    fetcher({
      url: `${API_URL}/api/bgclip/process/${type}/${videoID}/?ctg=${ctg}`,
      method: "GET",
      res: async (res) => {
        let { isError } = res.data;
        if (isError) {
          console.log("I am Running");
          store.dispatch({
            type: "MEDIA_WITH_NEW",
            data: {
              ..._media[media._Type],
              type: media._Type,
            },
          });

          r({
            res: null,
          });
          cb({ res: null });
          // store.dispatch({ type: "LOADING" });
        } else {
          let { id, media_file } = res.data.result;
          let blob = media_file;
          // document.blobMedia[id] = res.data.result;
          if (type === "video") {
            blob = await display(media_file, id);
          }
          // localStorage.setItem(id, blob);

          cb({
            media_file: blob,
            id,
            res: res.data.result,
          });
          r({
            media_file: blob,
            id,
            res: res.data.result,
          });
        }
      },
      err: (err) => {
        console.error(err);
      },
    });
  });
};

export const addVideoToCanvasAndStore = (
  video,
  p = {
    type: "video", //overflow
    link: `/ video / ${Vid()} /${Index()}/settings / video`,
    name: "Video",
    _id: null,
    data: {},
    cb: () => { },
  }
) => {
  return () => {
    // for sake for saivng data i used media_file
    // console.log(video);
    store.dispatch({
      type: "LOADING",
      data: "Video is Loading ...",
    });
    store.dispatch({
      type: "LLOAD",
      data: "Video",
    });
    store.dispatch({
      type: "SCENE_DISABLED",
      data: true,
    });

    askConvertedVideo(
      video.id,
      video.ctg,
      (_video) => {
        // console.log(e);

        let { media_file, id, res } = _video;
        if (!res) {
          store.dispatch({
            type: "LOADING",
            data: "Video is Loading ...",
          });
          store.dispatch({
            type: "LLOAD",
            data: "Video",
          });
          return;
        }
        id = `_${uuid()}`;
        if (p._id) {
          id = p._id;
        }
        const Media = document.querySelector(".Media");
        const vidEle = document.createElement("video");

        vidEle.customVideoSeek = customVideoSeek;

        let realEle = document.getElementById(id);
        if (realEle) {
          // vidEle = realEle;
          realEle.remove();
        }
        vidEle.preload = "auto";
        vidEle.crossOrigin = "anonymous";
        vidEle.src = media_file;
        vidEle.id = id;
        Media.appendChild(vidEle);

        vidEle.onloadeddata = (e) => {
          e.target.height = e.target.videoHeight;
          e.target.width = e.target.videoWidth;
          // here add the fabric + store at media
          const cxx = document.cxx[Index()];

          const { videoWidth, videoHeight } = e.target;

          let vidObj = new fabric.Image(e.target, {
            left: 0,
            top: 0,
            width: videoWidth,
            height: videoHeight,

            objectCaching: true,
            statefullCache: true,
            cacheProperties: ["videoTime"],
          });

          vidObj._Volume = 1;
          if (p.type === "video") {
            vidObj.set({
              fill: "#ffffff",
            });
            fitCenterToCXX({
              obj: vidObj,
              posx: 0.2,
              cxx,
            });
          }

          cxx.add(vidObj);
          p.cb(vidObj);
          vidObj._Video = res;
          vidEle._fabObj = vidObj;

          if (p.type === "background") {
            fitImageToRect(vidObj, {
              top: 0,
              left: 0,
              height: SIZES[document?._size || 0].height,
              width: SIZES[document?._size || 0].width,
              selectable: false,
            });
            cxx.sendToBack(vidObj);
          }

          // document.txx.setX((x) => {
          //   return x;
          // });

          InitAddObjectEvents({
            obj: vidObj,
            link: p.link,
            // name: p.name,
            name: document.duplicateName.getName({
              type: p.type,
              name: p.name,
            }),

            _id: p._id || id,
            type: p.type,
            media: video,

            src: media_file,
            data: p.data,
            returnToUrl: false,
            onAddActive: false,
            // returnToUrl:true
          });

          cxx.renderAll();
          store.dispatch({
            type: "TOGGLE_LOADING",
            data: {},
          });
          store.dispatch({
            type: "LLOAD",
            data: "Video",
          });
          store.dispatch({
            type: "SCENE_DISABLED",
            data: false,
          });
          UpdateBackground();
          MoveSeekTo({
            position: "same",
            cb: ({ x }) => {
              vidEle.customVideoSeek(x / document.per);
            },
          });
        };
        checkBufferVideoEvent(vidEle);
        // if(p.type==="music"){
      },
      "video",
      video
    );

    // }
  };
};
