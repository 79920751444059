import React from "react";
import { connect } from "react-redux";
import store from "../../../store/store";
import { Index, Vid } from "../../../utils";
import "./_One.scss";
export default connect(s => ({ sanimation: s.sanimation }))(function TwoSceneAnimation({ index, sanimation }) {
  const handleOpen = () => {
    document.hist.push(`/${document.editorType}/${Vid()}/${Index()}/animation`, { id: index });
    store.dispatch({ type: "NOWSANIMATION", data: { index } })
  };
  return (
    <div className={`TwoSceneAnimation ${sanimation === index ? "TwoSceneAnimation_active" : ""}`} onClick={handleOpen}>
      <img src={`${document.PUBLIC_URL}/ico1/sceneTransition.svg`} alt="asd"></img>
    </div>
  );
})
