import { useEffect } from "react";
import { memo } from "react";

export const SceneInside = memo(({ number }) => {
  let index = number - 1;

  let W = 90 - 2 - 2,
    H = 50 - 2 - 2;


  useEffect(() => {
    try {
      if (document.smallScene.s[index]) {
        let ctx = document.getElementById(`scene${index}`).getContext("2d");
        let cxv = document.smallScene.s[index];
        ctx.drawImage(cxv, 0, 0, cxv.width, cxv.height, 0, 0, W, H);
        // console.log("Making");
      }

      // let handle = () => {
      //   if (document.screenChheda) {
      //     document.smallScene.make(index);
      //     document.smallScene.update(index);
      //   }
      // };

      // setTimeout(handle, 15000);
      // return () => {
      //   clearTimeout(handle);
      // };
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <div className="SceneBar_Scene_inside">
      <canvas width={W} height={H} id={`scene${index}`} alt="abc" />
    </div>
  );
});
