import { fabric } from "fabric";
import { getSVGObjColor, setSVGObjColor } from "../../../../../utils";

export const loadElement = async (url) => {
    return new Promise((resolve, reject) => {
        if (url) {
            fabric.loadSVGFromURL(url, function (objects, options) {
                let obj = fabric.util.groupSVGElements(objects, options);
                obj._DefaultColor = getSVGObjColor(obj);
                obj._CurrentColor = obj._DefaultColor[0];
                obj.setColor = setSVGObjColor;
                resolve(obj)
            })
        } else {
            resolve(null);
        }
    });
};