import store from "../../store/store";
import { TIME_PER_SEC } from "../config";

// document._Max = TIME_PER_SEC * 6;

document.txx = {};
document._TabName = "avatar";

document.setToggle = () => { };
document.setS = {};
document.signalHover = 0;
document._maxEnterDelayObj = {};
document._setDelay = {};
document._Elements = {};
document.vxx = {};
document.musicLoop = {};
document.playByU = false;
document.isCanActive = true;
document.cxx = {};
document.pxx = {};
document.axx = {};

document.oxx = { ...Array.from({ length: 20 }).map((e, i) => ({})) };
document.save = true;
document.screenChheda = true;
document.saved = {};
document.lock = {};
document.scenes = {};
document.firstResize = {};
document.missingAvatar = [];
document.setBigPlay = () => { };
document.hitSpeechAPI = () => { };
document.sceneNumberFromIndex = { 0: 1 };

document._media_unique = {};
document._videoRecording = {};

document.currentObj = () => ({
  have: false,
  id: "",
});
document.selectionSettings = {
  cornerColor: "#ffffff",
  borderColor: "#e7416a",
  cornerStrokeColor: "#e7416a",
  cornerStyle: "circle",
  transparentCorners: false,
  cornerSize: 12,
};

document.saveFull = {
  one: () => { },
  two: () => { },
  three: () => { },
};

const makeStore = () => {
  store.dispatch({ type: "COPYDOM" });
};
document.makeStore = makeStore;

document.render = false;
document.timec = {};

document.editorType = "video";
document.disableoutPlayMode = false;
document.dontMoveHover = false;

document.fromVoiceSpeech = false;
// document._.elementFirstTimeFetched = {};
