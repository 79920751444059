import React from "react";
import Po from "reactjs-popup";
import Button from "../Button";
import "./_EEE.scss";
import { fetcher, url } from "../../../utils";
import mystore from "../../appStore";
import { useState, useEffect } from "react";
import { connect } from "react-redux";





function GeneratePopup({ csvPayload, trigger, onOpenHide, onCloseShow, open, onOpenCus, close, res }) {

  const [start, setstart] = useState(false)
  useEffect(() => {


    if (start && res?.totalCount === res?.generatedCount)
      onOpenCus(false)
  })

  const handleGenerate = () => {

    fetcher({
      url: `${url}/api/newvideo/video/batch/generate/${csvPayload.id}/`,
      method: "GET",
      res: (res) => {
        let data = res.data;
        const { result } = data;
        console.log({ result });
        mystore.dispatch({
          type: "ADD_MEDIA_IN_LIST",
          data: { result, type: "batch_history" },
        });
        setstart(true)
        // close()
      },
      err: err => {
        console.log(err);
      }
    })

  }
  return (
    <Po modal open={open} onOpen={() => {
      handleGenerate()
      onOpenHide()
    }} onClose={() => {
      onOpenCus(false)
      setstart(false)
      onCloseShow()
    }} >
      <div className="GeneratePopup flex column">
        <img className="GeneratePopup_img" src={`${document.PUBLIC_URL}/icons/loading.gif`}></img>
        <div className="GeneratePopup_text">Generating...</div>
        <div className="GeneratePopup_progress">
          {res?.generatedCount} of {res?.totalCount} Contacts Completed
        </div>
        <div className="GeneratePopup_info">
          You can minimise this view and work on something else.
        </div>
        <Button value="Minimise" className="grey" onClick={() => {
          onOpenCus(false)
        }} />
      </div>
    </Po>
  );
}
const mapStateToProps = (state) => {
  return ({
    res: mystore.getState().media.batch_history.list[0]
  })
}

export default connect(mapStateToProps)(GeneratePopup)