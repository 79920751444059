export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const GET_ERRORS = "GET_ERRORS ";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const REST_ERROR_STATE = "REST_ERROR_STATE";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const SIGNUP_VERIFY_SUCCESS = "SIGNUP_VERIFY_SUCCESS";
export const SIGNUP_VERIFY_FAIL = "SIGNUP_VERIFY_FAIL";

export const UNDO_STATE = "UNDO_STATE";
export const REDO_STATE = "REDO_STATE";
export const SAVE_CURRENT_STATE = "CURRENT_STATE";
export const COPY_SALES_PAGE = "COPY_SALES_PAGE";
export const SAVE_FIRST_STATE = "SAVE_FIRST_STATE";
export const IS_LOADING = "IS_LOADING";
export const RESET_STATE = "RESET_STATE";
