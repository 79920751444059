/* eslint-disable */
import React from 'react';
import Popup from 'reactjs-popup';
// import { ReactComponent as DeleteBtnPopup } from "../../../extras/Delete.svg";
import { ReactComponent as LargeTick } from "../../../extras/account/tickBig.svg";
import { ReactComponent as Cross } from "../../../extras/account/cross.svg";
// import { ReactComponent as Cross } from "../../../extras/account/";

export default (props) => (
  <Popup
    trigger={
      props.children
    }
    modal
    nested
  >
    {close => (

      <div className="modal-dialog modal-dialog-centered deletePop-detail-popup-video">
        <div className="modal-content vd-modal-content">
          <div className="modal-header vd-modal-header del-popup-heading">
            <Cross
              className="pointer"
              onClick={() => {
                props.closee()
                close();
              }}

            />
          </div>
          <div className="modal-body modal-avatar-select">


            <div className="deletecamp-in-popup">
              <div className="deletecamp-image">
                <LargeTick />
              </div>

              <label className="deletecamp-lable-lg">{props.request}</label>
              <label className="deletecamp-lable-sm">We’ll get back to you as soon as possible.</label>

            </div>

            <div>

            </div>





          </div>
          <div className="modal-footer vd-modal-footer">
            <span>
              <button
                type="button"
                className="vd-avatar-select-btn"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.closee();
                  close();
                }}
              >
                Close
              </button>
            </span>
          </div>
        </div>
      </div>

    )}
  </Popup>
);