import { fitImageToRect } from "../../../../../utils";

export function fitAvatarBackgroundToCrop(img, _cropClipMaskInst) {
  let _centerP = _cropClipMaskInst.getCenterPoint();
  fitImageToRect(img, {
    left: _centerP.x - (_cropClipMaskInst.width * _cropClipMaskInst.scaleX) / 2,
    top: _centerP.y - (_cropClipMaskInst.height * _cropClipMaskInst.scaleY) / 2,
    height: _cropClipMaskInst.height * _cropClipMaskInst.scaleY,
    width: _cropClipMaskInst.width * _cropClipMaskInst.scaleX,
    angle: _cropClipMaskInst.angle,
  });
}
