/* eslint-disable */
import { connect } from "react-redux";
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";
import Button from "../../../../App/Components/Button";
import Popup from "../../../../App/Components/Popup";

import DisabledWrapper from "../../../Chemistry/Atom/DisabledWrapper";
import HoverImageCard from "../../../Chemistry/Atom/HoverImageCard";
import InputIcon from "../../../Chemistry/Atom/InputIcon";
import TitleRow from "../../../Chemistry/Atom/TitleRow";
import TitleWrapper from "../../../Chemistry/Atom/TitleWrapper";
import "./_Templates.scss";
import Modal from "reactjs-popup";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import store from "../../../../store/store";
import {
  fetchAndSave,
  fetcher,
  functionListen,
  nextSceneNumber,
  Index,
  url,
} from "../../../../utils";
import { idConversion } from "../../../../utils/idConversion";
import addAvatarToScreenFirstTime from "../Avatar/functions/addAvatar";
import {
  giveAllEventAndTimeLine,
  addAllMusicFromJSON,
  removeExtraMusicLoopId,

} from "../../../../utils/loadFromServer";
import ButtonIcon from "../../../Chemistry/Atom/ButtonIcon";
import { display } from "../../../../utils/videoPlayThings/addVideoToCanvasAndStore";
import InfiniteWrapper from "../../../Chemistry/Atom/Infinite";
import { API_URL, SCENE_LIMIT, SIZES } from "../../../../utils/config";

import {
  addMusicToAllCxx,
} from "../../../../utils/videoPlayThings";
import { loadVideo } from "../Video/functions/createVideoElement";
import { replaceAvatar } from "../Avatar/components/AvatarSettings";

const Templates = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/list`}>
        <TemplateScene />
      </Route>
      <Route path={`${url}`} exact>
        <DefaultPage />
      </Route>
    </Switch>
  );
};

export const sceneAdderFromJSON = async (arr, cb) => {
  const _index = Index();
  const _addAllM = {};
  const _crntAvatarUrl = store.getState().scenes.currentAvatar.image?.transparentImage;
  for (let ii = 0; ii < arr.length; ii++) {
    let _json = arr[ii];
    let { arr: _arr } = store.getState().scenes.currentScene;
    let arrLen = JSON.parse(_arr).length;
    if (arrLen < SCENE_LIMIT) {
      let next = nextSceneNumber();
      let json = idConversion({ json: _json, to: next, from: 0, type: "template" });
      document.Save.save = false;

      store.dispatch({
        type: "ADD_SCENE",
        data: { next: next },
      });

      store.dispatch({
        type: "ADD_SCENE_JSON_FILL",
        data: { index: next, json },
      });

      let _allVideoData = json.jsonData.objects.filter(
        (obj) =>
          obj._Type === "video" ||
          (obj._Type === "background" && obj._Data.tab === 2)
      );
      let _allVideo = _allVideoData.map((e) => loadVideo(e._Video.media_file, e._Video.id, e.id));
      await Promise.all(_allVideo);
      await new Promise((res) => document.cxx[next].loadFromJSON(
        JSON.stringify(json.jsonData),
        async (a) => {
          if (document.editorType === "video") {
            await addAllMusicFromJSON(
              json.jsonData,
              document.cxx[next],
              next
            );
          }
          await giveAllEventAndTimeLine({ index: next, json: json });
          let _allMusic = document.cxx[next]._objects.filter((obj) => obj._Type === "music" && obj._adjustLength === 4);
          _allMusic.forEach((obj) => {
            if (_addAllM[obj.id] === undefined) {
              _addAllM[obj.id] = next;
            }
          })
          await replaceAvatar({ index: next, url: _crntAvatarUrl })
          document.smallScene.draw(next, true, 'no');
          store.dispatch({ type: "RANDOM" })
          res(true)
        }
      ))


    }
  };
  store.dispatch({ type: "SPEECH_DISABLE", data: true })
  removeExtraMusicLoopId();

  // let _allMusic = document.cxx[_index]._objects.filter((obj) => obj._Type === "music");
  // _allMusic.forEach((obj) => {
  //   if (document.musicLoop[obj.id] && _addAllM[obj.id] === undefined) {
  //     _addAllM[obj.id] = _index;
  //   }
  // });

  // Object.keys(_addAllM).forEach((_id) => {
  //   addMusicToAllCxx({ id: _id, index: _addAllM[_id] });
  // })

  cb();
};

const TemplateScene = () => {
  let { state } = useLocation();
  // const c
  const [list, setList] = useState([]);
  const [sel, setSel] = useState({});
  const [l, setL] = useState("All");

  const handlerBack = () => {
    document.hist.goBack();
  };
  const handleAddScene =
    ({ sceneIndex }) =>
      () => {
        store.dispatch({
          type: "LOADING",
          data: "Scene is Loading ...",
        });

        fetcher({
          method: "GET",
          url: `${url}/api/newvideo/video/scene/${state.id}/?scene=${sceneIndex}`,
          res: (res) => {
            sceneAdderFromJSON(res.data.results, () => {
              store.dispatch({
                type: "LOADING",
                data: "Scene is Loading ...",
              });
            });
          },
          err: (err) => {
            console.log(err);
          },
        });
      };

  const handleAddAll = ({ id, str }) => {
    store.dispatch({
      type: "LOADING",
      data: "Scene is Loading ...",
    });
    console.log(id, sel, str)
    let scene = (str) => {
      let cstr = Object.keys(sel)
        .filter((e) => sel[e] === true)
        .join("_");
      return cstr ? `?scene=${str || cstr}` : "";
    };
    fetcher({
      method: "GET",
      url: `${url}/api/newvideo/video/scene/${state.id}/${scene(str)}`,
      res: async (res) => {
        sceneAdderFromJSON(res.data.results, () => {
          store.dispatch({
            type: "LOADING",
            data: "Scene is Loading ...",
          });
        });
        document.handleScroll({
          target: document?.querySelector(".SceneBar_list"),
        });
        document.activeScroll();
      },
      err: (err) => {
        console.log(err);
      },
    });
  };

  const handleSelect = (index) => {
    return () => {
      setSel((k) => {
        let g = { ...k, [index]: !k[index] };
        let p = Object.keys(g).filter((e) => g[e] === true);
        setL(
          p.length > 0
            ? p.length === state.list.length
              ? "All"
              : p.length
            : "All"
        );
        return g;
      });
    };
  };
  return (
    <div className="TemplateScene">
      <TitleRow
        cursor="pointer"
        title={`<  ${state.name}`}
        onClick={handlerBack}
      />
      <ButtonIcon
        className="TemplateScene_all"
        value={`Add ${l} Scenes`}
        icon={<></>}
        onClick={() => handleAddAll({ id: state.id })}
      />

      <div className="flex row wrap j-between TemplateScene_list">
        {state.list.map((e, i) => (
          <HoverImageCard
            src={e.thumbnail}
            // count={e.scenes.length}
            onSelect={handleSelect(e.sceneIndex)}
            downElement={<></>}
            title={e.name}
            checkIcon={sel[e.sceneIndex]}
            isActive={sel[e.sceneIndex]}
            upElement={
              <Modal
                modal
                trigger={
                  <button className="BadButton">
                    <img
                      // onClick={onExpand}
                      className="pointer"
                      src={`${document.PUBLIC_URL}/ico/expandPreview.svg`}
                      alt={"avc"}
                    />
                  </button>
                }
              >
                {(close) => (
                  <Popup
                    footer={
                      <Button
                        value="Add to Video"
                        onClick={() => {
                          // setSel({})
                          // handleSelect(i);
                          console.log(i);
                          handleAddScene({ sceneIndex: i })();
                          close();
                        }}
                      ></Button>
                    }
                    name={e.name}
                    haveButton={true}
                    close={close}
                  >
                    <video
                      controlsList="nodownload"
                      className="Templatess_video"
                      src={e.video}
                      poster={e.thumbnail}
                      controls
                      autoPlay={true}
                    ></video>
                  </Popup>
                )}
              </Modal>
            }
          />
        ))}
      </div>
    </div>
  );
};

const DefaultPage = connect((state) => ({
  list: state.media.template.list,
  searchList: state.media.search.list,
}))(({ list, searchList }) => {
  const handleAddAll = ({ id }) => {
    store.dispatch({
      type: "LOADING",
      data: "Scene is Loading ...",
    });
    fetcher({
      method: "GET",
      url: `${url}/api/newvideo/video/scene/${id}/`,
      res: (res) => {
        sceneAdderFromJSON(res.data.results, () => {
          store.dispatch({
            type: "LOADING",
            data: "Scene is Loading ...",
          });
        });
        console.log(res.data);
      },
      err: (err) => {
        console.log(err);
      },
    });
  };
  const [isSearched, setSearch] = useState(false);
  useEffect(() => {
    functionListen("#tempSearch", handleChange);
  }, []);
  const handleChange = (e) => {
    let ele = document.querySelector("#tempSearch");
    let d = ele.value;
    if (d.length >= 1) {
      fetcher({
        method: "GET",
        url: `${url}/api/newvideo/video_template/?size=16:9&&q=${d}`,
        res: (res) => {
          store.dispatch({
            type: "MEDIA_WITH_NEW",
            data: {
              next: res.data.next,
              list: res.data.results,
              type: "search",
            },
          });
          setSearch(true);
        },
        err: (err) => {
          setSearch(false);
        },
      });
    } else {
      setSearch(false);
    }
  };


  // useEffect(() => {
  //   fetchAndSave({ type: "template" });
  // }, []);
  return (
    <div className="Templatess">
      {/* <TitleRow title="Templates"/> */}
      <InputIcon
        src={`${document.PUBLIC_URL}/icons/search.svg`}
        placeholder="Search Template"
        id="tempSearch"
      />
      {/* <DisabledWrapper isDisable={!true}> */}

      <div
        id={`tempalate${""}`}
        className="scrollableDiv"

        style={{
          height: "83vh",
          overflowY: "scroll",
          overflowX: "hidden",
          display: "flex",
        }}
      // className="TemplateScene_mainList"
      >
        <InfiniteWrapper
          target={`tempalate${""}`}
          type={isSearched ? "search" : "template"}
          url={`${API_URL}/api/newvideo/video_template/?size=${SIZES[document._size || 0].str_ratio
            }&human=${document._mode === 2 ? 0 : 1}`}


        >
          {(isSearched ? searchList : list).map((e) => (
            // <Link to={{ pathname: `templates/list`, query: e.scenes }}>
            <HoverImageCard
              src={e.thumbnail}
              count={e.scenes.length}
              title={e.name}
              onSelect={() => {
                document.hist.push("templates/list", {
                  list: e.scenes,
                  name: e.name,
                  id: e.id,
                });
              }}
              upElement={
                <Modal
                  modal
                  trigger={
                    <button
                      onClick={(e) => e.stopPropagation()}
                      className="BadButton"
                    >
                      <img
                        // onClick={onExpand}
                        className="pointer"
                        src={`${document.PUBLIC_URL}/ico/expandPreview.svg`}
                        alt={"avc"}
                      />
                    </button>
                  }
                >
                  {(close) => (
                    <Popup
                      footer={
                        <Button
                          value="Add to Video"
                          onClick={() => {
                            handleAddAll({ id: e.id });
                            close();
                          }}
                        ></Button>
                      }
                      name={e.name}
                      haveButton={true}
                      close={close}
                    >
                      <video
                        controlsList="nodownload"
                        className="Templatess_video"
                        src={e.video}
                        poster={e.thumbnail}
                        controls
                        autoPlay={true}
                      ></video>
                    </Popup>
                  )}
                </Modal>
              }
            />
            // </Link>
          ))}
        </InfiniteWrapper>

      </div>
      {/* </DisabledWrapper> */}
    </div >
  );
});

const InTemplate = () => {
  return (
    <div>
      <TitleWrapper></TitleWrapper>
    </div>
  );
};

export default Templates;
