import React from "react";
import "./_CSS.scss";
export default function Button({
  value = "Button",
  onClick = () => { },
  isActive = true,
  className = "",
  disabled = false,
  id,
  style = {}
}) {
  return (
    <button
      style={style}
      id={id ? id : null}
      disabled={disabled}
      className={`Button ${isActive ? "" : "Button_active"} ${className}`}
      onClick={onClick}
    >
      {value}
    </button>
  );
}
