import { getById } from "../utils";
import {
  onNoneMask,
  resetImageMask,
  startImageCropProcess,
} from "./cropyThings";

export default function MaskApplier({ id }) {
  return (mask) => {
    let obj = getById(id);
    if (mask.name !== "None") {
      startImageCropProcess(obj, document.cccx.current, mask, false);
    } else {
      onNoneMask(mask);
      if (obj.clipPath) {
        resetImageMask(obj);
      }
    }
  };
}
