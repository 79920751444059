import axios from "axios";
import { url } from "../../utils";
import { returnErrors } from "./message";
import {
	USER_LOADED,
	USER_LOADING,
	AUTH_ERROR,
	LOGOUT_SUCCESS,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	RESET_PASSWORD_FAIL,
	RESET_PASSWORD_SUCCESS,
	FORGET_PASSWORD_FAIL,
	FORGET_PASSWORD_SUCCESS,
	SIGNUP_VERIFY_FAIL,
	SIGNUP_VERIFY_SUCCESS,
	RESET_STATE,
} from "./types";
// import { url } from "../config/config";

// CHECK TOKEN & LOAD USE
export const loadUser = () => (dispatch, getState) => {
	// User Loading
	dispatch({ type: USER_LOADING });
	console.log(tokenConfig(getState));
	axios
		.get(`${url}/api/accounts/user/`, tokenConfig(getState))
		.then((res) => {
			// console.log(res);
			dispatch({
				type: USER_LOADED,
				payload: res.data,
			});
		})
		.catch((err) => {
			console.log(err);
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: AUTH_ERROR,
			});

			console.log(window.location.pathname);

			if (
				window.location.pathname === "/login" ||
				window.location.pathname.includes("reset-password") ||
				window.location.pathname === "/forgotpassword" ||
				window.location.pathname.includes("/signup-verify") ||
				window.location.pathname === "/register"
			) {
			} else {
				// window.location.href = "/login";
			}
		});
};

// LOGIN USER
// type "social" or "email"
export const login =
	(email, password, type = "email") =>
		(dispatch) => {
			// Headers

			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			};

			if (type === "email") {
				// Request Body
				const body = JSON.stringify({ email, password });

				axios
					.post(`${url}/api/accounts/login/`, body, config)
					.then((res) => {
						console.log(res);
						dispatch({
							type: LOGIN_SUCCESS,
							payload: res.data,
						});
					})
					.catch((err) => {
						console.log(err);
						dispatch({
							type: LOGIN_FAIL,
							payload: {
								response: err.response.data,
								status: err.response.status,
							},
						});
					});
			} else {
				// Request Body
				const body = JSON.stringify({ password: password });

				axios
					.post(`${url}/api/accounts/social-auth/google/`, body, config)
					.then((res) => {
						let _redirectTo = res.data.redirectTo;
						if (_redirectTo) {
							document.location.href = _redirectTo;
						} else {
							dispatch({
								type: LOGIN_SUCCESS,
								payload: res.data,
							});
						}
					})
					.catch((err) => {
						dispatch({
							type: LOGIN_FAIL,
							payload: {
								response: err.response.data,
								status: err.response.status,
							},
						});
					});
			}
		};

// forget password
export const forgetPassword = (email) => (dispatch) => {
	// Headers
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	// Request Body
	const body = JSON.stringify({ email });

	axios
		.post(`${url}/api/accounts/password/reset/`, body, config)
		.then((res) => {
			dispatch({
				type: FORGET_PASSWORD_SUCCESS,
			});
		})
		.catch((err) => {
			dispatch({
				type: FORGET_PASSWORD_FAIL,
				payload: {
					response: err.response.data,
					status: err.response.status,
				},
			});
		});
};

// password reset
export const resetPassword = (code, password) => (dispatch) => {
	// Headers
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	// Request Body
	const body = JSON.stringify({ code, password });

	axios
		.post(`${url}/api/accounts/password/reset/verified/`, body, config)
		.then((res) => {
			dispatch({
				type: RESET_PASSWORD_SUCCESS,
			});
		})
		.catch((err) => {
			dispatch({
				type: RESET_PASSWORD_FAIL,
				payload: {
					response: err.response.data,
					status: err.response.status,
				},
			});
		});
};

export const resetState = () => (dispatch) => {
	// Headers

	dispatch({
		type: RESET_STATE,
		payload: null,
	});
};

// Register User with org
export const registerUserWithOrg =
	(first_name, code, email, password) => (dispatch) => {
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};

		// Request Body
		const body = JSON.stringify({ first_name, code, email, password });

		axios
			.post(`${url}/api/accounts/signup/`, body, config)
			.then((res) => {
				let _redirectTo = res.data.redirectTo;
				if (_redirectTo) {
					document.location.href = _redirectTo;
				} else {
					dispatch({
						type: REGISTER_SUCCESS,
						payload: res.data,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				dispatch({
					type: REGISTER_FAIL,
					payload: {
						response: err.response.data,
						status: err.response.status,
					},
				});
			});
	};
export const register = (first_name, email, password) => (dispatch) => {
	// Headers
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	// Request Body
	const body = JSON.stringify({ first_name, last_name: "", email, password });

	axios
		.post(`${url}/api/accounts/signup/`, body, config)
		.then((res) => {
			let _redirectTo = res.data.redirectTo;
			if (_redirectTo) {
				document.location.href = _redirectTo;
			} else {
				dispatch({
					type: REGISTER_SUCCESS,
					payload: res.data,
				});
			}
		})
		.catch((err) => {
			console.log(err);
			dispatch({
				type: REGISTER_FAIL,
				payload: {
					response: err.response.data,
					status: err.response.status,
				},
			});
		});
};

// SIGNUP VERIFY
export const signupVerify = (code) => (dispatch) => {
	// Headers
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	axios
		.get(`${url}/api/accounts/signup/verify/?code=` + code, config)
		.then((res) => {
			dispatch({
				type: SIGNUP_VERIFY_SUCCESS,
			});
		})
		.catch((err) => {
			dispatch({
				type: SIGNUP_VERIFY_FAIL,
				payload: {
					response: err.response.data,
					status: err.response.status,
				},
			});
		});
};

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
	axios
		.get(`${url}/api/accounts/logout/`, tokenConfig(getState))
		.then((res) => {
			dispatch({
				type: LOGOUT_SUCCESS,
			});
		})
		.then(() => {
			window.location.href = "/login";
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
			window.location.href = "/login";
		});

	localStorage.removeItem("token");
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
	// Get token from state
	const token = getState().auth.token || localStorage.getItem("token");
	console.log(token);
	// Headers
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	// If token, add to headers config
	if (token) {
		config.headers["Authorization"] = `Token ${token}`;
	}
	console.log(config);
	return config;
};
