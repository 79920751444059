import { fabric } from "fabric";
import store from "../store/store";
import { getNumber } from "../utils";
import { addObjectOutsideEvent, removeObjectOutsideEvent } from "./fabricInit";
import { InitAddObjectEvents } from "./InitAddObjectEvents";
import {
  getFabricCancelControl,
  getFabricCropControl,
  getFabricResetControl,
} from "./inlineSVG";

export function restoreObject(main, childrens) {
  childrens.forEach((o) => {
    if (!o._relationship) {
      return;
    }
    let newTransform = fabric.util.multiplyTransformMatrices(
      main.calcTransformMatrix(),
      o._relationship
    );
    let opt = fabric.util.qrDecompose(newTransform);
    o.set({
      flipX: false,
      flipY: false,
    });
    o.setPositionByOrigin(
      {
        x: opt.translateX,
        y: opt.translateY,
      },
      "center",
      "center"
    );
    o.set(opt);
    o.setCoords();
  });
}

export function getObjectCoords(obj, options = {}) {

  let _newData = {
    left: obj.left,
    top: obj.top,
    scaleX: obj.scaleX,
    scaleY: obj.scaleY,
    height: obj.height,
    width: obj.width,
  };
  _newData = {
    ..._newData,
    ...options,
  };
  const height = _newData.height * _newData.scaleY,
    width = _newData.width * _newData.scaleX;
  let { x: left, y: top } = obj.getPointByOrigin(0, 0);
  // let _originalOriginX = convertOriginToDecimal(obj.originX),
  //   _originalOriginY = convertOriginToDecimal(obj.originY);
  // let left = _newData.left - width * _originalOriginX;
  // let top = _newData.top - height * _originalOriginY;
  return {
    left,
    top,
    height,
    width,
  };
}


async function cropObject(eventData, transform) {
  let target = transform?.target;
  if (!target) {
    return;
  }
  let canvas = target.canvas;

  document.cccx.current.style.display = "none";
  let _clipPath = canvas._objects.find((obj) => obj.clipImage);
  if (_clipPath) {
    let _originalImage = await cropImageObjectFinal(_clipPath);

    if (_originalImage.backupData) {
      _originalImage.set({
        opacity: _originalImage.backupData.opacity,
        flipX: _originalImage.backupData.flipX,
        flipY: _originalImage.backupData.flipY,
      });
      _originalImage.backupData = null;
    } else {
      _originalImage.set({
        opacity: 1,
      });
    }
    let _canvas = _originalImage.canvas;
    _originalImage.setSel && _originalImage.setSel(_originalImage._Mask);
    _canvas.renderAll();
  }
  canvas.clear();
  store.dispatch({
    type: "SET_FRAME",
    data: false,
  });
}

async function cropImageObjectFinal(_clipPath) {
  let obj = _clipPath.refImage;
  let _angle = _clipPath.angle;

  let _otherObject = _clipPath.canvas._objects.find((obj) => !obj.clipImage);
  if (_angle && _angle > 0) {
    freezeObject(obj, [_clipPath]);

    obj.rotate(0);
    _clipPath.rotate(0);
    _clipPath.setCoords();
    obj.setCoords();
    restoreObject(obj, [_clipPath]);
    _otherObject && _otherObject.rotate(0);
  }
  let { height: objHeight, width: objWidth } = getObjectCoords(obj);
  let { left, top, height, width } = getObjectCoords(_clipPath);

  let _relativeLeft = left - obj.left;
  let _relativeTop = top - obj.top;

  let _newObj = {
    left: obj.left,
    top: obj.top,
    height: 0,
    width: 0,
    cropX: 0,
    cropY: 0,
  };
  let _clipObj = {
    leftPadd: 0,
    topPadd: 0,
    height: height / _clipPath.scaleY,
    width: width / _clipPath.scaleX,
    scaleX: _clipPath.scaleX / obj.scaleX,
    scaleY: _clipPath.scaleY / obj.scaleY,
  };

  if (_relativeLeft >= 0) {
    _newObj.cropX = _relativeLeft / obj.scaleX;
    _newObj.left += _relativeLeft;
    let _tmp = objWidth - (_relativeLeft + width);
    if (_tmp < 0) {
      _newObj.width = (width + _tmp) / obj.scaleX;
    } else {
      _newObj.width = width / obj.scaleX;
    }
  } else {
    _newObj.width = (width + _relativeLeft) / obj.scaleX;
    _clipObj.leftPadd = _relativeLeft;
    let _tmp = objWidth - (_relativeLeft + width);
    if (_tmp < 0) {
      _newObj.width = _newObj.width + _tmp / obj.scaleX;
    }
  }
  if (_relativeTop >= 0) {
    _newObj.cropY = _relativeTop / obj.scaleY;
    _newObj.top += _relativeTop;
    let _tmp = objHeight - (_relativeTop + height);
    if (_tmp < 0) {
      _newObj.height = (height + _tmp) / obj.scaleY;
    } else {
      _newObj.height = height / obj.scaleY;
    }
  } else {
    _newObj.height = (height + _relativeTop) / obj.scaleY;
    _clipObj.topPadd = _relativeTop;

    let _tmp = objHeight - (_relativeTop + height);
    if (_tmp < 0) {
      _newObj.height = _newObj.height + _tmp / obj.scaleY;
    }
  }

  obj.set({
    ..._newObj,
  });

  _clipPath.set({
    left: -obj.width / 2 + _clipObj.leftPadd / obj.scaleX,
    top: -obj.height / 2 + _clipObj.topPadd / obj.scaleY,
    height: _clipObj.height,
    width: _clipObj.width,
    scaleX: _clipPath.scaleX / obj.scaleX,
    scaleY: _clipPath.scaleY / obj.scaleY,
  });
  obj.clipPath = _clipPath;

  if (_angle && _angle > 0) {
    obj.setCoords();
    _otherObject.setCoords();

    let _tmpObj = new fabric.Rect({
      left: obj.left,
      top: obj.top,
      height: obj.height,
      width: obj.width,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      fill: null,
    });
    _clipPath.canvas.add(_tmpObj);
    _clipPath.canvas.renderAll();
    if (_otherObject) {
      freezeObject(_otherObject, [_tmpObj]);
      _otherObject.rotate(_angle);
      _otherObject.setCoords();
      restoreObject(_otherObject, [_tmpObj]);
      obj.set({
        left: _tmpObj.left,
        top: _tmpObj.top,
      });
      obj.rotate(_angle);
    }
  }
  return obj;
}

export function freezeObject(main, childrens) {
  let bossTransform = main.calcTransformMatrix();
  let invertedBossTransform = fabric.util.invertTransform(bossTransform);
  childrens.forEach((o) => {
    let desiredTransform = fabric.util.multiplyTransformMatrices(
      invertedBossTransform,
      o.calcTransformMatrix()
    );
    // save the desired relation here.
    o._relationship = desiredTransform;
  });
}

export function convertOriginToDecimal(_origin) {
  if (0 <= _origin && _origin <= 1) {
    return _origin;
  } else {
    let originOffset = {
      left: 0,
      center: 0.5,
      right: 1,
      top: 0,
      bottom: 1,
    };
    let _decmialOV = originOffset[_origin];
    if (_decmialOV !== undefined) {
      return _decmialOV;
    }
    return 0.5;
  }
}

export function onNoneMask(mask, enableLoad) {
  let canvas = document.ccx;
  document.cccx.current.style.display = "none";
  document.cccxa.current.style.display = "none";
  let _clipPath = canvas._objects.find((obj) => obj.clipImage);
  if (_clipPath) {
    removeObjectOutsideEvent(_clipPath);
    let _backupdata = {
      opacity: 1,
    };
    let _mObj = _clipPath.refImage;
    if (_mObj) {
      if (_mObj.backupData) {
        _backupdata = {
          opacity: _mObj.backupData.opacity,
          flipX: _mObj.backupData.flipX,
          filpY: _mObj.backupData.flipY,
        };
        if (mask) {
          _mObj.setSel && _mObj.setSel(mask);
        }
      }
      _mObj.set({
        ..._backupdata,
      });
      _mObj.backupData = null;
      _mObj.canvas?.renderAll();
    }
  }

  canvas.clear();
  if (enableLoad)
    store.dispatch({
      type: "SET_FRAME",
      data: false,
    });
}

export function onCancelClick(eventData, transform, enableLoad) {
  //let canvas = transform.target.canvas;

  let canvas = document.ccx;

  document.cccx.current.style.display = "none";
  document.cccxa.current.style.display = "none";
  let _clipPath = canvas._objects.find((obj) => obj.clipImage);
  if (_clipPath) {
    removeObjectOutsideEvent(_clipPath);
    let _backupdata = {
      opacity: 1,
    };
    let _mObj = _clipPath.refImage;
    if (_mObj) {
      if (_mObj.backupData) {
        _backupdata = _mObj.backupData;
        _mObj.setSel && _mObj.setSel(_backupdata._Mask);
      }
      _mObj.set({
        ..._backupdata,
      });
      _mObj.backupData = null;
      _mObj.canvas.renderAll();
    }
  }

  canvas.clear();
  if (enableLoad)
    store.dispatch({
      type: "SET_FRAME",
      data: false,
    });
}


function getDefaultMask() {
  let _maskList = store.getState().media.mask.list;
  if (_maskList) {
    let _rects = _maskList.filter((e) => e.name === "Rectangle");
    if (_rects.length > 0) {
      return _rects[0];
    } else {
      let _rects = _maskList.filter((e) => e.name !== "None");
      if (_rects.length > 0) {
        return _rects[0];
      }
    }
  }
  return false;
}

export function resetImageMask(obj) {
  convertOrigin(obj, {
    originX: 0,
    originY: 0,
  });
  // change left top
  let _objLeft = obj.left,
    _objTop = obj.top;
  let _newLeft = _objLeft - obj.cropX * obj.scaleX;
  let _newTop = _objTop - obj.cropY * obj.scaleY;
  let newObj = obj.clipPath;
  let _clipData = {
    left: _objLeft,
    top: _objTop,
    scaleX: (obj.scaleX * obj.width) / newObj.width,
    scaleY: (obj.scaleY * obj.height) / newObj.height,
  };

  obj.set({
    left: _newLeft,
    top: _newTop,
    cropX: 0,
    cropY: 0,
    clipPath: null,
  });
  obj._resetWidthHeight();
  return _clipData;
}

function getMaxScale(
  { mainHeight, mainWidth, childHeight, childWidth },
  scaleDown = 0.85
) {
  let _scale = Math.min(
    (mainHeight * scaleDown) / childHeight,
    (mainWidth * scaleDown) / childWidth
  );
  return {
    scale: _scale,
    height: childHeight * _scale,
    width: childWidth * _scale,
  };
}

const resetImageObject = (eventData, transform) => {
  onNoneMask();
  let target = transform?.target;
  if (!target) {
    target = document.ccx._objects.find((e) => e.id === "cropClipMask");
    if (!target) {
      return;
    }
  }
  let _obj = target.refImage;
  if (_obj) {
    if (_obj.clipPath) {
      resetImageMask(_obj);
    }
  }
};

const _fabCancelControl = getFabricCancelControl(onCancelClick);
const _fabCropControl = getFabricCropControl(cropObject);
const _fabResetControl = getFabricResetControl(resetImageObject);

export async function startImageCropProcess(
  img,
  canCover,
  mask,
  enableLoad = true,
  cb = () => { }
) {
  // 1 if clip canvas present restart it.
  convertOrigin(img, {
    originX: 0,
    originY: 0,
  });
  store.dispatch({
    type: "SET_FRAME",
    data: true,
  });

  onCancelClick(null, null, null);
  let _angle = parseInt(img.angle);
  // eslint-disable-next-line use-isnan
  if (_angle === NaN) {
    _angle = 0;
  }

  let selectedMask,
    _isCrop = false;
  if (mask) {
    selectedMask = mask;
  } else {
    selectedMask = false;//getDefaultMask();
    _isCrop = true;
  }

  const clipCanvas = document.ccx;
  clipCanvas.cb = cb;

  let backgroundC = img.canvas;
  // prev center

  let clipMask, _clipData;

  if (img.backupData) {
    img.set({
      ...img.backupData,
    });
  } else {
    img.backupData = {
      left: img.left,
      top: img.top,
      cropX: img.cropX,
      cropY: img.cropY,
      opacity: img.opacity,
      height: img.height,
      width: img.width,
      scaleX: img.scaleX,
      scaleY: img.scaleY,
      clipPath: img.clipPath,
      flipX: img.flipX,
      flipY: img.flipY,
      angle: img.angle,
      _Mask: img._Mask,
    };
  }
  // handle flip
  img.set({
    flipX: false,
    flipY: false,
    _Mask: selectedMask,
  });

  let _tmpObj;
  if (img.clipPath) {
    if (_angle !== 0) {
      img.rotate(0);
      _tmpObj = new fabric.Rect({
        left: img.left,
        top: img.top,
        height: img.height,
        width: img.width,
        scaleX: img.scaleX,
        scaleY: img.scaleY,
        fill: "blue",
      });
      clipCanvas.add(_tmpObj);
      clipCanvas.renderAll();
    }
    //backup if crop cancel
    //clipMask = img.clipPath;
    _clipData = resetImageMask(img);

    if (_angle !== 0) {
      freezeObject(_tmpObj, [img]);
      _tmpObj.rotate(_angle);
      restoreObject(_tmpObj, [img]);
      restoreObject(_tmpObj, [img]);
      clipCanvas.remove(_tmpObj);
    }
  }

  let _objCenter = img.getCenterPoint();
  let _imgHeight = img.height * img.scaleY,
    _imgWidth = img.width * img.scaleX;
  if (selectedMask) {
    try {
      if (selectedMask?.src) {
        clipMask = await asyncFabricSVGLoader(selectedMask.src);
        let _getPos = getMaxScale({
          mainHeight: _imgHeight,
          mainWidth: _imgWidth,
          childHeight: clipMask.height * clipMask.scaleY,
          childWidth: clipMask.width * clipMask.scaleX,
        });
        if (!_clipData || true) {
          _clipData = {
            left: _objCenter.x - _getPos.width / 2,
            top: _objCenter.y - _getPos.height / 2,
            scaleX: _getPos.scale,
            scaleY: _getPos.scale,
          };
        }
      }
    } catch (err) {
      //console.log("Error while loading svg mask", err);
    }
  }

  if (!clipMask) {
    let _defaultRectSize = { width: 160, height: 90 };
    clipMask = new fabric.Rect({
      fill: "rgba(255,255,255,1)",
      ..._defaultRectSize,
    });
    let _getPos = getMaxScale({
      mainHeight: _imgHeight,
      mainWidth: _imgWidth,
      childHeight: clipMask.height,
      childWidth: clipMask.width,
    });
    _clipData = {
      left: _objCenter.x - _getPos.width / 2,
      top: _objCenter.y - _getPos.height / 2,
      scaleX: _getPos.scale,
      scaleY: _getPos.scale,
    };
  }

  let _copyImage = fabric.util.object.clone(img);
  img.set({
    opacity: 0.4,
  });

  clipMask.set({
    ..._clipData,
    id: "cropClipMask",
    strokeWidth: 0,
    clipImage: true,
    refImage: img,
    lockScalingFlip: true,
    globalCompositeOperation: "destination-in",
    _controlsVisibility: {
      mtr: false,
    },
  });
  clipMask.rotate(_angle);

  _copyImage.set({
    selectable: false,
    evented: false,
  });

  canCover.style.display = "block";

  clipMask.controls = {
    ...clipMask.controls,
    cancelControl: _fabCancelControl,
    cropControl: _fabCropControl,
  };
  // controls padd
  const _paddX = 10;
  const _crntZoom = clipCanvas.getZoom();
  if (_isCrop) {
    clipMask.controls = {
      ...clipMask.controls,
      resetControl: _fabResetControl,
    };
    clipMask.controlMaxSize = (clipMask.controls.resetControl.offsetX + clipMask.controls.resetControl.cornerSize) / _crntZoom;
  } else {
    clipMask.controlMaxSize = (clipMask.controls.cancelControl.offsetX + clipMask.controls.cancelControl.cornerSize) / _crntZoom;
  }
  clipCanvas.add(_copyImage);
  clipMask.set({
    ...document.selectionSettings,
  });
  clipCanvas.add(clipMask);
  clipCanvas.setActiveObject(clipMask);

  backgroundC.renderAll();
  clipCanvas.renderAll();

  addObjectOutsideEvent(clipMask);
}



export function renderIcon(icon) {
  return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    ctx.save();
    if (!this._noROffset) {
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -this.cornerSize / 2, -this.cornerSize / 2, this.cornerSize, this.cornerSize);
    } else {
      if (this._absPos?.x !== undefined && this._absPos?.y !== undefined) {
        ctx.translate(this._absPos?.x, this._absPos?.y)
      } else {
        ctx.translate(0, 0)
      }
      ctx.drawImage(icon, -this.cornerSize / 2, -this.cornerSize / 2, this.cornerSize, this.cornerSize);
    }
    ctx.restore();


    // var size = this.cornerSize;
    // ctx.save();
    // ctx.translate(left, top);
    // ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    // ctx.drawImage(icon, -size / 2, -size / 2, size, size);
    // ctx.restore();
  };
}

var maxX, minX, minY, maxY;

function asyncFabricSVGLoader(url) {
  return new Promise((resolve, reject) => {
    try {
      fabric.loadSVGFromURL(url, function (objects, options) {
        var obj = fabric.util.groupSVGElements(objects, options);
        if (obj._objects) {
          obj._objects.forEach((e) => {
            e.set({
              fill: "#fff",
              stroke: null,
            });
          });
        }
        obj.set({
          fill: "#fff",
          stroke: null,
        });
        resolve(obj);
      });
    } catch (err) {
      reject(new Error("could not load image"));
    }
  });
}
export function convertOrigin(
  obj,
  origin = {
    originX: 0.5,
    originY: 0.5,
  }
) {
  let _originX = convertOriginToDecimal(obj.originX),
    _originY = convertOriginToDecimal(obj.originY);
  const _angle = obj.angle;
  obj.rotate(0);

  let _stroke = Number(obj.strokeWidth);
  if (!(!isNaN(_stroke) && _stroke > 0)) {
    _stroke = 0
  }

  obj.set({
    originX: origin.originX,
    originY: origin.originY,
    left: obj.left + (obj.width + _stroke) * obj.scaleX * (origin.originX - _originX),
    top: obj.top + (obj.height + _stroke) * obj.scaleY * (origin.originY - _originY),
  });
  obj.rotate(_angle);
}
