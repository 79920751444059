import { Index } from "../../../../../utils";
import { SIZES } from "../../../../../utils/config";

export default function intiBackground({ obj }) {
  if (obj._Type === "background") {
    obj.set({
      selectable: false,
      evented: false,
    });

  }
}
