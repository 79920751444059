import React, { useRef, useState } from "react";
import "./_One.scss";
import Draggy from "./Draggy";
import uuid from "uuid-random";
import { getById, Index, Jump } from "../../../utils";
import { sortableHandle } from "react-sortable-hoc";
import { deleteFromAll } from "../../../utils/deleteCloneOpeationObj";
import { connect } from "react-redux";
import all from "../../../utils/timeLineOperation";
import objectCloner from "../../../utils/objectCloner";
import store from "../../../store/store";
import I from "./time/I";
import { useEffect } from "react";

let TypeIcons = {
  text: {
    drag: true,
    icon: I.text,
    del: true,
    clone: true,
    hide: true,
    mute: false,
  },
  avatar: {
    drag: true,
    icon: I.avatar,
    mute: false,
    hide: true,
  },
  video: {
    drag: true,
    icon: I.video,
    del: true,
    clone: true,
    hide: true,
    mute: true,
  },
  image: {
    drag: true,
    icon: `${document.PUBLIC_URL}/ico4/imageTimeline.svg`,
    del: true,
    hide: true,
    clone: true,
  },
  background: {
    icon: `${document.PUBLIC_URL}/ico2/backgroundLayer.svg`,
  },
  elements: {
    drag: true,
    del: true,
    clone: true,
    hide: true,
    icon: `${document.PUBLIC_URL}/ico1/elementTimeline.svg`,
  },
  music: {
    icon: `${document.PUBLIC_URL}/ico1/musicTimeline.svg`,
    drag: true,
    mute: true,
    del: true,
  },
};

function TimeElement({ id, objActive, hoverObjActive, beforeMax }) {
  let obj = getById(id) || {}
  const [name, setName] = useState(obj?._Name || "Undefined");
  const [isName, setIsName] = useState(true);
  let tool = TypeIcons?.[obj?._Type] || {};
  const ref = useRef();


  const handleSet = (toolType) => {
    return (e) => {
      e.stopPropagation();

      try {
        let a = all[obj?._Type][toolType].get(obj);
        all[obj?._Type][toolType].set(obj, !a);
        store.dispatch({ type: "RANDOM" })
      } catch (err) {
        console.log(err);
      }
    };
  };
  const getMe = (what) => {
    return all[obj?._Type]?.[what]?.get(obj);
  };
  const DragHandle = sortableHandle(() => (
    <img
      style={{ cursor: "row-resize" }}
      draggable={false}
      src={I.drag}
      alt="drag"
    // alt={"mgdd"}
    />
  ));

  const handleClone = (e) => {
    e.stopPropagation();

    objectCloner({ _id: id, from: Index(), clone: true });
  };

  const handleDelete = (e) => {
    e.stopPropagation();

    deleteFromAll(id);
  };

  const onMouseEnter = () => {
    store.dispatch({ type: "SET_HOVER_ACTIVE_OBJECT", data: obj?.id });
  };
  const onMouseLeave = () => {
    store.dispatch({ type: "SET_HOVER_ACTIVE_OBJECT", data: "" });
  };



  return (
    <div
      className="TimeElement"
      // onMouseEnter={onMouseEnter}
      // onMouseLeave={onMouseLeave}
      style={{ width: `${document._Max + 2 * 500}px` }}
    >
      <div
        className={`TimeElement_obj ${obj?.id === objActive || obj?.id === hoverObjActive
          ? "TimeElement_obj_active"
          : ""
          }`}
        onClick={(e) => {
          // if (document.screenChheda && !document.Disable) {
          document.outPlayMode();

          document.hist.push(Jump({ obj }), { id: obj.id });
          obj.canvas.setActiveObject(obj);
          setTimeout(function CxxEventsActive() {
            store.dispatch({
              type: "SET_ACTIVE_OBJECT",
              data: obj.id,
            });
          }, 0);
          obj.canvas.renderAll()

          // }
        }}
      >
        <div className="TimeElement_obj_drag">
          {tool.drag && <DragHandle />}
        </div>
        {/* {Math.random()} */}
        <div className="TimeElement_obj_icon">
          <img src={tool.icon} alt="text"></img>
        </div>
        <div
          className="TimeElement_obj_name"
          onDoubleClick={() => {
            setIsName(false);
            setTimeout(() => {
              ref.current.focus();
            }, 0);
          }}
        >
          <input
            className={`pointer ${isName
              ? "TimeElement_obj_name_disabled"
              : "TimeElement_obj_name_input"
              }`}
            ref={ref}
            disabled={isName}
            value={name}
            autoFocus
            onChange={(e) => {
              obj._Name = e.target.value;
              setName(obj._Name);
            }}
            onFocus={() => {
              document.isCanActive = false;
            }}
            onBlur={() => {
              setIsName(true);
              document.isCanActive = true;

              // obj.can
              obj.canvas.fire("object:modified");
            }}
          />
        </div>
        <div className="TimeElement_obj_tool">
          <div className="TimeElement_obj_tool_img">
            {tool.mute && (
              <img
                src={getMe("mute") ? I.mute : I.unmute}
                alt="unmute"
                onClick={handleSet("mute")}
              ></img>
            )}
          </div>
          <div className="TimeElement_obj_tool_img">
            {tool.hide && (
              <img
                // src={`${document.PUBLIC_URL}/time/${!getMe("hide") ? "" : "un"}hide.svg`}
                src={!getMe("hide") ? I.hide : I.unhide}
                alt="unhide"
                onClick={handleSet("hide")}
              ></img>
            )}
          </div>
          <div className="TimeElement_obj_tool_img">
            {tool.del && (
              <img src={I._delete} alt="delete" onClick={handleDelete}></img>
            )}
          </div>
          <div className="TimeElement_obj_tool_img">
            {tool.clone && (
              <img src={I.clone} alt="clone" onClick={handleClone}></img>
            )}
          </div>
        </div>
      </div>
      <div className="TimeElement_time" id={`_${id}`}>
        <Draggy obj={obj} bounds={`#_${id}`} id={id} />
      </div>
    </div>
  );
}

export default connect((state) => ({
  objActive: state.active.objActive,
  hoverObjActive: state.active.hoverObjActive,
  beforeMax: state.beforeMax
}))(TimeElement);
