import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import SelectOption from '../../components/Chemistry/Atom/SelectOption';
import BlankProfile from "../extras/misc/blankprofile.svg";
import { connect } from "react-redux";
import LogoutConfirm from "../JohnDoe/common/component/logoutConfirm";
import { logout } from "../../accounts/actions/auth";
import mystore from '../appStore';
function Profile(props) {
    let hist = useHistory()
    const [User, setUser] = useState({
        isAuth: false,
        name: "",
        profile_image: BlankProfile
    });
    const [OpenLog, setOpenLog] = useState(false)


    useEffect(() => {
        setUser({
            isAuth: Object.keys(props.auth).length > 0 ? true : false,
            name: Object.keys(props.auth).length > 0 ? `${props.auth.first_name} ${props.auth.last_name}` : "",
            profile_image: Object.keys(props.auth).length > 0 ? props.auth.profile_image ? props.auth.profile_image : BlankProfile : ""
        })

        return 0
    }, [props.auth])


    const handle = (a) => {
        // console.log(a);
        document.setWho(Math.random())
        switch (a.id) {
            case 0:
                hist.push("/faq");
                break;
            case 1:
                hist.push("/contactus");
                break;
            case 2:
                hist.push("/subscription");
                break;
            case 3:
                hist.push("/settings");
                break;
            case 4:
                setOpenLog(true)

            default:
                break;
        }
    }


    const LogoutPopup = () => (<LogoutConfirm
        open={OpenLog}
        setOpen={setOpenLog}
        onClickk={() => {

            props.logout()

            // window.location.href = "/login"

        }}
    >
    </LogoutConfirm>)



    return <div className='Profile'>
        <LogoutPopup />
        <SelectOption onSelect={handle} ele={<div className='Profile_name flex acenter'>



            <div className='Profile_name_image'> <img className='profile_pic_in_navbar_new' src={User.profile_image} alt="" /> </div> {User.name}
            <img

                className="pointer "

                src={`${document.PUBLIC_URL}/app/downArrow.svg`}
                alt="downArrow"
            /></div>} list={[
                { id: 2, name: "Subscription", src: `${document.PUBLIC_URL}/site/subscription.svg` },
                { id: 1, name: "Contact Us", src: `${document.PUBLIC_URL}/site/help.svg` },
                { id: 3, name: "Account Settings", src: `${document.PUBLIC_URL}/site/settings.svg` },
                { id: 0, name: "FAQs", src: `${document.PUBLIC_URL}/site/help.svg` },
                { id: 4, name: "Logout", src: `${document.PUBLIC_URL}/site/logout.svg` },
            ]} />
    </div>;
}


const mapStateToProps = () => {
    var state = mystore.getState()
    return {
        auth: state.user,
    };
};

export default connect(mapStateToProps, { logout })(Profile);