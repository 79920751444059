import React, { useRef, useState, useEffect } from "react";
import "./_One.scss";
import { Rnd } from "react-rnd";
import { connect } from "react-redux";
import {
  defaultDurationLengthInSec,
  getById,
  Index,
  Jump,
  MoveSeekTo,
  updateTimeLen,
} from "../../../utils";
import { MIN_STAYTIME, TIME_PER_SEC } from "../../../utils/config";
import { customSeek } from "../../../utils/videoPlayThings";
import store from "../../../store/store";






export const MaxSuper = (state = null, index = Index(), ignoreBackground = false) => {
  let scenes = document.screenChheda
    ? (state || store.getState()).scenes[index]
    : document.Player.scenes[index];
  let elements = scenes.elements;
  document._maxEnterDelay = 0;
  document._maxEnterDelayObj = {};
  const _objects = scenes?.jsonData?.objects || [];

  let maxArr = _objects.map((obj, i) => {
    //let obj = getById(e) || {};
    const e = obj?.id;
    if (elements[e] && obj) {
      const { adjustLength, stayTime, enter, exit, enterDelay } = elements[e];
      document._maxEnterDelay = Math.max(enterDelay, document._maxEnterDelay);
      document._maxEnterDelayObj[e] = enterDelay + stayTime;
      document._Elements[e] = { ...elements[e] };
      if (
        adjustLength !== 3 ||
        adjustLength === 3 ||
        obj._Type === "avatar" ||
        (obj._Type === "background" && obj._Data.tab === 2)
      ) {
        if (obj._Type === "background") {
          if (ignoreBackground) return 0
          // const { trimStart, trimEnd } = elements[e];
          // if (adjustLength === 3) return 0;
          // if (scenes.haveEasyMode || true) {
          //   return 0;
          // }

          return Number(stayTime);
        }
        if (obj._Type === "video") {
          const { trimStart, trimEnd } = elements[e];
          if (scenes.haveEasyMode) {
            return 0;
          }
          if (adjustLength === 3) return 0;

          return Number(enterDelay + stayTime);
        }
        if (obj._Type === "music") {
          return 0;
        }
        if (obj._Type === "avatar") {
          if (scenes.mode === 2) {
            return 0;
          }
        }

        return Number(enterDelay + stayTime);
      } else {
        return 0;
      }
    }
  });
  // console.log(maxArr);
  let _n = (e) => Number(Number(e).toFixed(2));

  let allMax = Math.max(...maxArr)
  document._Max = allMax * document.per;

  let a = document.setS?.[Index()]?.(_n(allMax));
  let b = document.setWx?.(_n(allMax * document.per));
  // updateTimeLen();
  return allMax;
};
const backgroundReFormer = (p = { id: "abc", what: "NULL", before: 0 }) => {
  // let elements = store.getState().scenes[Index()].elements;

  let max = MaxSuper(null, Index(), p.what !== "NULL");
  let a = document.setS?.[Index()]?.(max)
  if (max * document.per !== -Infinity) {
    document._Max = max * document.per;

  }

  store.dispatch({
    type: "ADJUST",
    data: { max, id: p.id, what: p.what, before: p.before },
  });
};
export const UpdateBackground = () => {
  backgroundReFormer();

};

export const per = () =>
  ((document.timePerPix % 40) + 10) /
  (2 * Math.pow(5, 1 - Math.floor(document.timePerPix / 40)));

export default connect(
  (state) => ({
    elements: state.scenes[state.sceneNumber].elements,
    per: state.per,
    objActive: state.active.objActive,
    hoverObjActive: state.active.hoverObjActive,
  }),
  (dispatch) => ({
    dragSize: (data) => dispatch({ type: "DRAG_RESIZE", data }),
  })
)(function Draggy({
  h = 30,
  obj,
  bounds,
  elements,
  id,
  dragSize,
  per

}) {
  const [ele, selEle] = useState({
    x: 100,
    enterDelay: 0,
    width: defaultDurationLengthInSec * TIME_PER_SEC,
    enter: 1,
    exit: 1,
    per: TIME_PER_SEC,
    adjustLength: 0,
  });
  const [type, setType] = useState("normal");
  const [eele, setEele] = useState({});

  useEffect(() => {
    let p = elements[id];
    if (obj) {
      if (obj._Type === "video") {
      }
      setType(obj._Type);
    }

    if (p) selEle(p);
    if (p) setEele(p);
  }, [elements[id]]);

  // useEffect(() => {
  //   backgroundReFormer();
  // }, []);
  let handler = () => {
    document.outPlayMode();
  };
  let _n = (e) => Number(Number(e).toFixed(3));

  const handleDrag = async (e, data) => {
    // document.outPlayMode()
    // if (document.screenChheda) {
    handler();
    handleCursor("default");
    let _data = {
      x: data.lastX,
      enterDelay: _n(data.x / per),
      stayTime: _n(ele.stayTime),
    };
    await dragSize({
      id,
      data: _data,
    });
    setTimeout(() => {

      let a = document._setDelay[id]?.();
    }, 0);

    await backgroundReFormer({ id, what: "DRAG", before: ele });
    MoveSeekTo({ position: "same" })

    // }
  };

  const handleResize = async (a, b, c, d) => {
    // if (document.screenChheda) {
    handler();

    let p = d.width;
    let k = p;
    if (b === "left") {
      p = -1 * p;
      k = p;
    } else {
      p = -1 * p;
      k = 0;
    }
    let max = Math.max(ele.enter + ele.exit, MIN_STAYTIME);
    let data = {
      width: _n(ele.width + d.width),
      enterDelay: _n(ele.enterDelay + k / per),
      stayTime: Math.max(_n((ele.stayTime * per - p) / per), _n(max)),
    };
    if (document.Save.save)
      await dragSize({
        id,
        data,
      });
    await backgroundReFormer({
      id,
      what: b === "left" ? "RESIZE_LEFT" : "RESIZE_RIGHT",
      before: ele,
    });
    setTimeout(() => {
      // document.txx.setX((x) => {
      //   customSeek(x / per);
      //   return x;
      // });
      let a = document._setDelay[id]?.();
    }, 0);
    MoveSeekTo({ position: "same" })
    // }

  };

  const handleResizeNon = async (a, b, c, d) => {
    // if (document.screenChheda) {
    let p = a.movementX;
    let k = p;
    if (b === "left") {
      p = -1 * p;
      k = p;
    } else {
      p = 1 * p;
      k = 0;
    }
    // if (document.save)
    // console.log(d);
    // console.log(d.width);

    setEele((kk) => {
      // console.log(kk.width);
      let oldLimit = kk.enter + kk.exit;
      let newStayTime = (kk.stayTime * per + p) / per;
      // console.log({ oldLimit, newStayTime });
      return {
        ...kk,
        width: kk.width + a.movementX,
        enterDelay: kk.enterDelay + k / per,
        stayTime: newStayTime,
      };
    });
    // }
  };



  const handleCursor = (st) => {
    return (e) => {
      // console.log(e.target);
      e.target.style.cursor = st;
    };
  };

  return (
    <Rnd
      bounds={bounds}
      position={{ x: ele.enterDelay * per, y: 2.5 }}
      size={{
        width: Math.max(eele.stayTime, eele.enter + eele.exit) * per,
      }}
      minWidth={1}
      className="Draggy"
      dragAxis="x"
      enableResizing={{
        left: !ele.resize,
        right: !ele.resize,
      }}
      onResize={handleResizeNon}
      dragGrid={[1, 10]}
      resizeGrid={[1, 10]}
      disableDragging={ele.drag}
      onDragStart={handleCursor("grabbing")}
      onDrag={(e, d) => {
        selEle((k) => ({ ...k, x: d.x }));
      }}
      onClick={(e) => {
        //console.log(e);
      }}
      allowAnyClick={true}
      onDragStop={handleDrag}
      onResizeStop={handleResize}
      resizeHandleComponent={{
        left: <div className="Draggy_LEFT"></div>,
        right: <div className="Draggy_RIGHT"></div>,
      }}
    >
      {/* <div
        className={`Draggy_center ${
          id === objActive || id === hoverObjActive
            ? "Draggy_center_active"
            : ""
        }`}
        style={{ width: `${eele.stayTime * document.per}px` }}
      > */}
      <div
        className="Draggy_left"
        onClick={(e) => {
          alert(e);
        }}
        style={{ width: `${ele.enter * per}px` }}
      >
        {ele.enter * per > 35 && (
          <img
            src={`${document.PUBLIC_URL}/ico/enterAnimation.svg`}
            alt="enterAnimation"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              document.hist.push(Jump({ obj }) + "/animation", { id: obj.id });
            }}
          />
        )}
      </div>
      <EleType
        type={type}
        adjustLength={elements[id]?.adjustLength}
        id={id}
        ele={eele}
      />
      <div
        className="Draggy_right"
        onClick={(e) => {
          alert(e);
        }}
        style={{ width: `${ele.exit * per}px` }}
      >
        {ele.exit * per > 35 && (
          <img
            src={`${document.PUBLIC_URL}/ico/exitAnimation.svg`}
            alt="exitAnimation"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              //console.log("push");
              document.hist.push(Jump({ obj }) + "/animation", { id: obj.id });
            }}
          />
        )}
      </div>
      {/* </div> */}
    </Rnd>
  );
});

const EleType = ({ type, adjustLength, id, ele }) => {
  if (ele)
    if (type === "video" || type === "background" || type === "music") {
      let haveEasyMode = store.getState().scenes[Index()].haveEasyMode;
      let trim = ele.trimEnd - ele.trimStart;

      if (adjustLength === 0) {
        return <div className="Draggy_center_none"></div>;
      } else if (adjustLength === 1) {
        let loop = Math.floor(ele.stayTime / trim);

        let loopArr = [];
        while (loop--) {
          loopArr.push(trim);
        }
        if (ele.stayTime % trim > 0) {
          loopArr.push(ele.stayTime % trim);
        }

        if (trim - ele.stayTime > 10 / document.per) {
          if (type !== "music" && false)
            store.dispatch({
              type: "EDIT_ELEMENT",
              data: {
                id: id,
                data: {
                  stayTime: trim,
                },
              },
            });
        }

        return (
          <>
            {loopArr.map((e) => (
              <div
                className="Draggy_center_loop"
                style={{ width: `${e * document.per}px` }}
              >
                <img src={`${document.PUBLIC_URL}/ico1/Loop.svg`} alt="freeze" />
              </div>
            ))}
          </>
        );
      } else if (adjustLength === 2) {
        if (trim - ele.stayTIme > 10 / document.per && false) {
          store.dispatch({
            type: "EDIT_ELEMENT",
            data: {
              id: id,
              data: {
                // adjustLength: 0,
                // resize: true,
                stayTime: trim,
                // enterDelay: 0,
              },
            },
          });
        }
        return (
          <div
            className="Draggy_center_none"
            style={{ width: `${trim * document.per}px` }}
          >
            <img src={`${document.PUBLIC_URL}/ico1/freeze.svg`} alt="freeze" />
          </div>
        );
      }
    } else if (type === "music") {
      let timeLine = document.txx.timeLine[Index()].duration();
      // //console.log(timeLine);
      let elements = store.getState().scenes[Index()].elements;
      let max = Math.max(
        ...Object.keys(elements).map((e) => {
          const { adjustLength, stayTime, enter, exit, enterDelay } =
            elements[e];
          if (adjustLength !== 3) {
            return enterDelay + stayTime + enter + exit;
          } else {
            return 0;
          }
        })
      );
      // //console.log(max);
      const trim = ele.trimEnd - ele.trimStart;
      // const stayTime = max - ele.enter - ele.exit;
      // let p = trimDef > stayTime ? stayTime : trimDef;
      if (max - ele.enterDelay !== ele.stayTime) {
        // if (trimEnd - trimStart > timeLine)
        if (ele.stayTime)
          store.dispatch({
            type: "EDIT_ELEMENT",
            data: {
              id: id,
              data: {
                stayTime: max - ele.enterDelay,
                enterDelay: ele.enterDelay,
              },
            },
          });
      }
      return (
        <div
          className="Draggy_center_none"
          style={{ width: `${trim * document.per}px` }}
        >
          <img src={`${document.PUBLIC_URL}/ico1/freeze.svg`} alt="freeze" />
        </div>
      );
    }
  return <div className="Draggy_center_none"></div>;
};
