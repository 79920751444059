// import { memo } from "react";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect } from "react";
import TitleRow from "../../../../Chemistry/Atom/TitleRow";
import "./_Frame.scss";
import { useState } from "react";
import Tabium from "../../../../Chemistry/Compound/Tabium";
import DisabledWrapper from "../../../../Chemistry/Atom/DisabledWrapper";
import { connect } from "react-redux";
import ImageScroll from "../../../../Chemistry/Compound/ImageScroll";
import UploadScroll from "../../../../Chemistry/Compound/UploadScroll";
import {
  getById,
  Index,
} from "../../../../../utils";
import { fabric } from "fabric";
import store from "../../../../../store/store";
import SlideContainer from "../../../../Chemistry/Atom/SlideContainer";
import NewColor from "../../../../Chemistry/Atom/NewColor";
import { askConvertedVideo } from "../../../../../utils/videoPlayThings/addVideoToCanvasAndStore";
import { fitAvatarBackgroundToCrop } from "../functions/fitAvatarBackgroundToCrop";
import { addAvatarFabricControls, addAvatarToCrop, applyAvatarCrop } from "../functions/addAvatarToCrop";
import { handleCancelOnAvatarFrame } from "../functions/handleCancelOnAvatarFrame";
import { getAvatarCropInfo } from "../functions/getAvatarCropInfo";
import { addObjectOutsideEvent } from "../../../../../utils/fabricInit";


const FrameTab = ({
  current = 1,
  arr = [],
  onChange = () => { },
  onClick = () => { },
}) => {
  const handleCall = (i) => {
    onChange(i);
  };
  return (
    <div className="FrameTab">
      {arr.map((e, i) => {
        return (
          <div
            key={i}
            className={`FrameTab_tab ${current === i ? "FrameTab_tab_active" : ""
              }`}
            onClick={() => {
              handleCall(i);
              onClick(e.key);
            }}
          >
            <img className="FrameTab_tab_img" src={e.src} alt="ppp" />
          </div>
        );
      })}
    </div>
  );
};
function Frame({
  video,
  stock,
  upload,
  frameActive,
  gradient,
  EditColor,
  colorArr,
}) {
  const history = useHistory();
  const [dis, setDis] = useState(0);
  let [frameInfo, setInfo] = useState({ frame: 0, tab: 0, color: "#ff0000" });
  ////console.log("Frame is rendered");
  const handlerBack = () => {
    history.goBack();
    if (frameInfo.frame === 0) {
      document.cccx.current.style.display = "none";
      document.cccxa.current.style.display = "none";
      let _crntAvatar = getById(`avatar${Index()}`, Index());
      applyAvatarCrop(_crntAvatar, frameInfo.frame);

    } else {
      handleCancelOnAvatarFrame()
    }
  };
  const handleChange = useCallback((i) => {
    setDis(i);

    setInfo((e) => {
      let p = { ...e, frame: i };
      //console.log(p);
      return p;
    });
    //
  }, []);

  useEffect(() => {
    let _crntAvatar = getById(`avatar${Index()}`, Index());
    let p = getAvatarCropInfo(_crntAvatar);

    addAvatarToCrop(_crntAvatar);

    let data = { frame: 0, tab: 0, color: "#ff0000" };
    if (p.clip) {
      setDis(true);
      if (p.clipType === "rect") {
        data.frame = 1;
      } else if (p.clipType === "circle") {
        data.frame = 2;
      }

      if (p.bgType === "color") {
        data.tab = 0;
        data.color = p.bgData;
      } else if (p.bgType === "image") {
        data.tab = 1;
      }
    }
    setInfo((e) => {
      let p = { ...e, tab: data.tab, frame: data.frame, color: data.color };
      //console.log(p)
      return p;
    });
    //setInfo(data);
  }, []);

  const changeColor = (color) => {
    let _object = document.ccx
      .getObjects()
      .find((e) => e.id === "cropAvatarBackground");
    if (_object) {
      if (_object.opacity > 0) {
        _object.set({ opacity: 0 });
      }
      document.ccx.backgroundColor = color;
      document.ccx.renderAll();
    } else {
      document.ccx.backgroundColor = color;
      document.ccx.renderAll();
    }
    setInfo((e) => {
      let p = { ...e, color };
      return p;
    });
  };

  const changeCropBGImage = async (_o) => {
    let o = await askConvertedVideo(
      _o.id,
      _o.ctg,
      () => { },
      "image",
      {
        _Type: "image"
      }
    )
    let _url = o.media_file;
    let _objectInst = document.ccx
      .getObjects()
      .find((e) => e.id === "cropAvatarBackground");
    let _cropClipMaskInst = document.ccx
      .getObjects()
      .find((e) => e.id === "cropClipMask");
    if (_objectInst?.type === "image") {
      _objectInst.set({ opacity: 1 });
      store.dispatch({ type: "LLOAD", data: "Frame" });

      _objectInst.setSrc(
        _url,
        function (img) {
          store.dispatch({ type: "LLOAD", data: "Frame" });

          img.canvas.renderAll();
          fitAvatarBackgroundToCrop(img, _cropClipMaskInst);
        },
        { crossOrigin: "anonymous" }
      );
    } else {
      store.dispatch({ type: "LLOAD", data: "Frame" });

      fabric.Image.fromURL(
        _url,
        function (img) {
          store.dispatch({ type: "LLOAD", data: "Frame" });

          img.set({
            id: `cropAvatarBackground`,
            selectable: false,
            evented: false,
            type: "image",
          });
          _cropClipMaskInst.canvas.remove(_objectInst);
          _cropClipMaskInst.canvas.add(img);
          img.sendToBack();
          fitAvatarBackgroundToCrop(img, _cropClipMaskInst);
          _cropClipMaskInst.on("moving", (e) => {
            fitAvatarBackgroundToCrop(img, _cropClipMaskInst);
          });
          _cropClipMaskInst.on("scaling", (e) => {
            fitAvatarBackgroundToCrop(img, _cropClipMaskInst);
          });

          _cropClipMaskInst.on("rotating", (e) => {
            fitAvatarBackgroundToCrop(img, _cropClipMaskInst);
          });
        },
        { crossOrigin: "anonymous" }
      );
    }
  };

  const handleFrameApply = (type = 2) => {
    //0 ,1, 2

    let avatarObj = getById(`avatar${Index()}`, Index());

    let a = { 2: 0, 0: 1, 1: 2 };
    if (a[type] === 0) {
      document.cccx.current.style.display = "none";
      document.ccxa._objects[0].opacity = 1;
      document.ccxa.renderAll();
    } else {
      document.cccx.current.style.display = "block";
      document.ccxa._objects[0].opacity = 0.4;
      document.ccxa.renderAll();
      document.ccx.backgroundColor = frameInfo.color;
      // add crop object
      let _cropClipMaskInst = document.ccx._objects.find(
        (e) => e.id === "cropClipMask"
      );
      const _avatarObj = document.ccx._objects.find(
        (e) => e.isAvatar
      );
      let _objCenter = avatarObj.getCenterPoint();
      let _clipData, _bottomPaddFromFace = 45;
      if (!_cropClipMaskInst) {
        let defaultObjectSize = 700 * document.ccxa._objects[0].scaleX;
        const _faceCenterPoint =
          store.getState()?.scenes?.currentAvatar?.image?.avatarConfig
            ?.faceCenterPoint;
        let _left = _objCenter.x,
          _top = _objCenter.y;
        if (_faceCenterPoint && _faceCenterPoint.x && _faceCenterPoint.y) {
          _left =
            _faceCenterPoint.x * document.ccxa._objects[0].scaleX +
            document.ccxa._objects[0].left;
          _top =
            _faceCenterPoint.y * document.ccxa._objects[0].scaleY +
            document.ccxa._objects[0].top + _bottomPaddFromFace;
        } else {
          //approx face point
          _left =
            520 * document.ccxa._objects[0].scaleX +
            document.ccxa._objects[0].left;
          _top =
            290 * document.ccxa._objects[0].scaleY +
            document.ccxa._objects[0].top;
        }
        // crop not applied
        _clipData = {
          left: _left - defaultObjectSize / 2,
          top: _top - defaultObjectSize / 2,
          height: defaultObjectSize,
          width: defaultObjectSize,
        };
      } else {
        _clipData = {
          left: _cropClipMaskInst.left,
          top: _cropClipMaskInst.top,
          height: _cropClipMaskInst.height,
          width: _cropClipMaskInst.height,
          scaleX: _cropClipMaskInst.scaleX,
          scaleY: _cropClipMaskInst.scaleY,
        };
      }
      if (a[type] === 2) {
        // add circle
        _clipData.radius = _clipData.height / 2;
        delete _clipData.height;
        delete _clipData.width;
        let clipMask = new fabric.Circle({
          ..._clipData,
          fill: "#ffffff",
          id: "cropClipMask",
          clipImage: true,
          refImage: _avatarObj,
          globalCompositeOperation: "destination-in",
          lockScalingFlip: true,
          _controlsVisibility: { mtr: false },
        });
        clipMask.set({ ...document.selectionSettings });
        addAvatarFabricControls(clipMask);
        document.ccx.remove(_cropClipMaskInst);
        document.ccx.add(clipMask);
        document.ccx.setActiveObject(clipMask);
        addObjectOutsideEvent(clipMask);
        document.ccx.renderAll();
      } else {
        let clipMask = new fabric.Rect({
          ..._clipData,
          fill: "#ffffff",
          rx: 50,
          ry: 50,
          id: "cropClipMask",
          clipImage: true,
          refImage: _avatarObj,
          globalCompositeOperation: "destination-in",
          lockScalingFlip: true,
          _controlsVisibility: { mtr: false },
        });
        clipMask.set({ ...document.selectionSettings });
        addAvatarFabricControls(clipMask);
        document.ccx.remove(_cropClipMaskInst);
        document.ccx.add(clipMask);
        document.ccx.setActiveObject(clipMask);
        addObjectOutsideEvent(clipMask);
        document.ccx.renderAll();
      }
    }
    //setInfo({...frameInfo,frame: a[type]})
    setInfo((e) => {
      let p = { ...e, frame: a[type] };
      return p;
    });

    store.dispatch({ type: "SET_AVATAR_FRAME", data: a[type] });
  };
  const handleLastChange = () => {
    document.cxx[Index()].fire("object:modified");
  };
  const defaultColor = () => {
    let obj = getById(`avatar${Index()}`, Index());
    let p = getAvatarCropInfo(obj);
    if (p.bgType == "color") {
      return p.bgData;
    }
    return "#ffffff";
    // getAvatarCropInfo())
    // ?.bgData ||
  };

  return (
    <SlideContainer>
      <div className="Frame">
        <TitleRow cursor="pointer" title="< Frame" onClick={handlerBack} />
        <DisabledWrapper isDisable={false}>
          <FrameTab
            arr={[
              { src: `${document.PUBLIC_URL}/icons/noFrame.svg`, key: 2 },
              { src: `${document.PUBLIC_URL}/icons/squareFrame.svg`, key: 0 },
              { src: `${document.PUBLIC_URL}/icons/circleFrame.svg`, key: 1 },
            ]}
            onChange={handleChange}
            onClick={handleFrameApply}
            current={frameInfo.frame}
          />
        </DisabledWrapper>

        <DisabledWrapper isDisable={dis === 0}>
          <Tabium
            arrTab={[{ name: "Color" }, { name: "Image" }, { name: "Uploads" }]}
            current={frameInfo.tab}
          >
            {/* <Color color={frameInfo.color} onChange={changeColor} /> */}
            <NewColor
              onChange={changeColor}
              onSelect={changeColor}
              onAdd={EditColor}
              onDelete={EditColor}
              handleLastChange={handleLastChange}
              arr={colorArr}
              gradientList={gradient.list}

              // onChange={changeColor}
              onGradientSelect={(img) => {
                changeCropBGImage(img);
              }}
              setDefault={defaultColor()}
            />
            <ImageScroll
              onSelect={(image) => {
                changeCropBGImage(image);
              }}
            />
            <UploadScroll
              onSelect={(image) => {
                changeCropBGImage(image);
              }}
            />
          </Tabium>
        </DisabledWrapper>
      </div>
    </SlideContainer>
  );
}
// const mstop = (state) => {
//   const {
//     media: { video, stock, upload },
//   } = state;
//   return {
//     video,
//     stock,
//     upload,
//   };
// };
export default connect(
  (state) => ({
    frameActive: state.frameActive,
    gradient: state.media.gradient,
    colorArr: state.scenes.colorArr,
  }),
  (dispatch) => {
    return {
      EditColor: (colorArr) => dispatch({ type: "EDIT_COLOR", data: colorArr }),
    };
  }
)(Frame);
