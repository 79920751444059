import React from "react";
import Popup from "../Popup";
import Modal from "reactjs-popup";
import Button from "../Button";
import { fetcher, url } from "../../../utils";
import GeneratePopup from "./GeneratePopup";
import { useState } from "react";

export default function ErrorPopup({ trigger, closeMePlease, onOpenHide, onCloseShow, csvChangeErrorClose, onGenerate, err, open, setOpen, csvPayload, noError }) {
  const handleDeleteNow = () => {
    // console.log(err);
    fetcher({
      method: "DELETE",
      url: `${url}/api/newvideo/video/batch/generate/${err.id}/`,
      res: () => {
        setOpen(false)
      }
    })
  }


  const handleGenerate = (close) => {

    onGenerate(true)


    // return () => {

    //   fetcher({
    //     url: `${url}/api/newvideo/video/batch/generate/${csvPayload.id}/`,
    //     method: "GET",
    //     res: (res) => {
    //       let data = res.data;
    //       console.log(data);
    //       // setGen(true)
    //       // close()
    //     },
    //     err: err => {
    //       console.log(err);
    //     }
    //   })
    // }

  }

  if (noError) {
    return (<Modal open={open} onOpen={onOpenHide} onClose={onCloseShow} modal>
      {(close) => (
        <Popup
          UpperId="errorPopup-1x1"
          name={`${err.totalContacts} Contacts`}
          close={() => {
            close()
            closeMePlease(false)
          }}
          footer={
            <div className="VariablePopup_footer flex row1">

              <Button
                className={` ${err.totalContacts === (err.totalSkipped) ? "Disabled-button-generate-invarPopup" : ""}`}
                onClick={() => handleGenerate()}

                value={
                  <div className={`VariablePopup_footer_img flex row1`}>
                    Generate Code <img src={`${document.PUBLIC_URL}/app/code.svg`} alt="ago"></img>
                  </div>
                }
              ></Button>
            </div>
          }
        >
          <div className="ErrorPopup">
            <div className="ErrorPopup_heading_main">No Errors found. click "Generate code" to continue.</div>

          </div>
        </Popup>
      )
      }
    </Modal >)
  }
  else {
    return (
      // <Modal open={open} onClose={handleDeleteNow} modal>
      <Modal open={open} onOpen={onOpenHide} modal>
        {(close) => (
          <Popup
            UpperId="errorPopup-1x1"
            name={`${err.totalContacts} Contacts`}
            close={() => {
              close()
              closeMePlease(false)
            }}
            footer={
              <div className="VariablePopup_footer flex row1">
                <Button
                  className="grey"
                  onClick={() => {
                    setOpen(false)
                    onCloseShow()
                    csvChangeErrorClose()
                  }}
                  value={
                    <div className="VariablePopup_footer_img flex row1">
                      <img src={`${document.PUBLIC_URL}/app/arrowLeft.svg`} alt="ago"></img>
                      Change CSV
                    </div>
                  }
                ></Button>

                <Button
                  className={` ${err.totalContacts === (err.totalSkipped) ? "Disabled-button-generate-invarPopup" : ""}`}
                  onClick={() => handleGenerate()}

                  value={
                    <div className={`VariablePopup_footer_img flex row1`}>
                      Generate Code <img src={`${document.PUBLIC_URL}/app/code.svg`} alt="ago"></img>
                    </div>
                  }
                ></Button>
              </div>
            }
          >
            <div className="ErrorPopup">
              <div className="ErrorPopup_heading">
                <div className="ErrorPopup_heading_main">Errors Found!</div>
                <div className="ErrorPopup_heading_info">
                  Warning: {err.totalErrors} Error{err.totalErrors == 1 ? "" : "s"} in {err.totalSkipped} Contact{err.totalSkipped == 1 ? "" : "s"}, these contact{err.totalSkipped == 1 ? "" : "s"} will be skipped.
                </div>
              </div>
              <div className="ErrorPopup_list flex column scroll_custom">
                {/* {[
                {
                  name: "Contact 4 :",
                  erros: [
                    { col: "A4", err: "{EmailID} cannot be empty." },
                    {
                      col: "F4",
                      err: "{Snapshot Background} should be a valid URL.",
                    },
                  ],
                },
                {
                  name: "Contact 6 (johndoe@gmail.com) :",
                  erros: [
                    //   { col: "A4", err: "{EmailID} cannot be empty." },
                    {
                      col: "F6",
                      err: "{Snapshot Background} should be a valid URL.",
                    },
                  ],
                },
                {
                  name: "Contact 7 (johndoe@gmail.com) :",
                  erros: [
                    { col: "A7", err: "{EmailID} cannot be empty." },
                    //   {
                    //     col: "F6",
                    //     err: "{Snapshot Background} should be a valid URL.",
                    //   },
                  ],
                },
              ] */}
                {Object.keys(err?.errors || {}).map((e, i) => {
                  let r = err?.errors[e];

                  return (
                    <div key={e} className="ErrorPopup_row flex row1">
                      <div className="ErrorPopup_row_title">{`Contact ${e} (${r.email}):`}</div>
                      <div className="ErrorPopup_row_list flex column">
                        {r.data.map((k) => {
                          return (
                            <div className="ErrorPopup_row_list_item flex row1">
                              <div className="ErrorPopup_row_list_item_button">
                                {k.cellIndex}
                              </div>
                              <img
                                src={`${document.PUBLIC_URL}/app/error.svg`}
                                className="ErrorPopup_row_list_item_img"
                                alt="abc"
                              />
                              <div className="ErrorPopup_row_list_item_err">
                                {k.message}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Popup>
        )
        }
      </Modal >
    );
  }
}
