import Popup from "../../../../App/Components/Popup";

import Model from "reactjs-popup";
import Button from "../../../../App/Components/Button";

export const DeletePopup = ({
  onCancel = () => { },
  onDelete = () => { },
  isConfirm,
  setConfirm = () => { },
  text = null,
  haveFooter = null,
  customText = null,
}) => {
  // const [isConfirm, setConfirm] = useState(isConfirm);
  const handleCancel = () => {
    // close();
    setConfirm(false);

    onCancel();
  };
  const handleDelete = () => {
    // close();
    setConfirm(false);
    onDelete();
  };
  return (
    <Model
      open={isConfirm}
      modal
      onClose={() => setConfirm(false)}
      closeOnDocumentClick={false}


    >
      {(close) => {
        return (
          <Popup
            close={close}
            footer={
              haveFooter || (
                <div className="flex DeletePopup_footer">
                  <Button
                    onClick={handleCancel}
                    value="Cancel"
                    className="grey"
                  ></Button>
                  <Button onClick={handleDelete} value="Delete"></Button>
                </div>
              )
            }
          >
            <div className="DeletePopup">
              <img
                src={`${document.PUBLIC_URL}/ico/deleteIconPopup.svg`}
                className="DeletePopup_icon"
                alt=""
              />
              <div className="DeletePopup_warning">
                {customText || `Are you sure you want to delete this ${text}?`}
              </div>
            </div>
          </Popup>
        );
      }}
    </Model>
  );
};
